import React from "react";
import TeamHero from "../components/TeamLanding/TeamHero";
import Captains from "../components/TeamLanding/Captains";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
import Footer from "../components/Footer";
import Header from "../components/HomePage/Navbar";
// import OurTeam from "../components/TeamLanding/OurTeam";
import CEO from "../components/TeamLanding/CEO";
import MissionAndVission from "../components/TeamLanding/MissionAndVission";

const TeamPage = () => {
  return (
    <div className="overflow-x-hidden sm:overflow-x-auto">
      <Header />
      <TeamHero />
      {/* <TeamMessage /> */}
      <CEO />
      <MissionAndVission />
      <Captains />
      {/* <OurTeam /> */}
      <NeedFromUs />
      <Footer />
    </div>
  );
};

export default TeamPage;
