import AnalyzeData from "../components/AiPage/AnalyzeData";
import Comparison from "../components/AiPage/Comparison";
import GraphSection from "../components/AiPage/GraphSection";
import Hero from "../components/AiPage/Hero";
import MapSection from "../components/AiPage/MapSection";
import Projections from "../components/AiPage/Projections";
import ThreeTakeAways from "../components/AiPage/ThreeTakeAways";
import Footer from "../components/Footer";
import Header from "../components/HomePage/Navbar";

function AiPage() {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <Hero />
      <ThreeTakeAways />
      <MapSection />
      <AnalyzeData />
      <Comparison />
      <Projections />
      <GraphSection />
      <Footer />
    </div>
  );
}

export default AiPage;
