import React from "react";
import { testimonialBg} from "../assets";
import { Link } from 'react-router-dom';


const backgroundImageUrl = testimonialBg;

const HomeEquity = () => {
  return (
    <div
      className="bg-cover bg-center text-center p-10 h-screen md:p-20 "
      style={{
         backgroundImage: `url(${backgroundImageUrl})`
         }}
    >
      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl md:text-6xl font-semibold text-white mt-32 mb-3">
          Cashout Upto <span className="text-yellow-300">$500,000</span>
        </h1>
        <p className="text-4xl md:text-6xl text-white font-semibold mb-16">From Your Home</p>
        <p className="text-white text-xl mb-16">
          In a world where unexpected expenses can arise at any moment, finding
          the right solution is essential. At Home LLC, we understand the need
          for quick access to cash when life throws you a curveball.
        </p>
        <Link to="/" className="border border-white text-white rounded-full px-8 py-2 hover:bg-pink-700 transition">
          Let's get you started
        </Link>
      </div>
    </div>
  );
};

export default HomeEquity;
