import React from "react";

const CTAbutton = ({ children, extraStyles, buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={`bg-custom-primaryPink py-5 px-[60px] rounded-[35px] text-white ${extraStyles}`}
    >
      {children}
    </button>
  );
};

export default CTAbutton;
