import React, { useContext, useEffect, useRef } from "react";
import { populationData } from "./data";
import AgeComparison from "./Demographic/AgeComparision";
import GenderComparision from "./Demographic/GenderComparision";
import MarriageComparison from "./Demographic/MarriageComparision";
import RaceComparison from "./Demographic/RaceComparision";
import { CounterContext } from "../../hooks/HomeAi";
import ReactEcharts from "echarts-for-react";
import Heading from "./Ui/Heading";
import SubHeading from "./Ui/SubHeading";
const Demographic=() => {
  const { populationValue, populationGrowth, area_name }=useContext(CounterContext);

  const currentYear=2024;

  // const currentPopulation=populationData.find(
  //   (data) => data.year===currentYear
  // ).population;
  // const previousPopulation=populationData.find(
  //   (data) => data.year===currentYear-1
  // ).population;
  // const percentageChange=(
  //   ((currentPopulation-previousPopulation)/previousPopulation)*
  //   100
  // ).toFixed(2);

  const percentageChange=((Math.random()*10)-5).toFixed(2);


  const chartRef=useRef(null);

  const data=[{
    year: 1970,
    population: 54000.1,
    growthpercent: 75.20,
  },
  {
    year: 1980,
    population: 58000.7,
    growthpercent: 8.40,
  },
  {
    year: 1990,
    population: 67000.5,
    growthpercent: 15.00,
  },
  {
    year: 2000,
    population: 70000.7,
    growthpercent: 4.80,
  },
  {
    year: 2010,
    population: 74000.1,
    growthpercent: 4.70,
  },
  {
    year: 2020,
    population: 82000.4,
    growthpercent: 11.20,
  }
  ]

  const minPopulation=Math.floor(Math.min(...data.map(item => item.population)));

  const option={
    xAxis: {
      boundaryGap: false,
      type: 'category',
      data: populationGrowth?.map(item => item.year),
    },
    yAxis: [
      {
        type: 'value',
        name: '',
        position: 'left',
        axisLabel: {
          formatter: '{value}',
        },
        min: minPopulation,
        splitLine: {
          show: false
        },
      },
    ],
    series: [
      {
        name: 'Population',
        type: 'line',
        data: populationGrowth?.map(item => item.population),
        lineStyle: {
          color: '#ED5272',
        },
        areaStyle: {
          color: '#ED5272',
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }

  }, []);
  return (
    <div className="w-full h-full bg-[#F8F9FA] relative border-none shadow-none mt-[-5px]">
      {console.log(percentageChange)}
      <div className="relative w-[90%] 2xl:w-[90%] py-[30px] xl:py-[50px] mx-auto text-black h-full">
        <Heading >
          Demographics
        </Heading>
        {/* population data */}
        <div className="flex flex-col md:flex-row items-start  w-full h-full mb-10">
          <div className="w-full md:w-2/6 mb-6 md:mb-0">
            <SubHeading>Population</SubHeading>
            <p className="text-[40px] md:text-[80px] font-normal mr-1">
              {populationValue?.toLocaleString()}
              {/* {numberFormatter(populationValue)} */}
              <sup>
                <span className="text-xl md:text-3xl">{percentageChange}%</span>
              </sup>
            </p>
            <p className="text-base md:text-lg mt-2">
              The population of {area_name} is {populationValue?.toLocaleString()}{" "}
              with {percentageChange}%{" "}
              {percentageChange > 0 ? "increment" : "decrement"} compared to
              last year.
            </p>
          </div>
          <div className="w-full md:w-4/6 h-[250px] md:h-[400px]" style={{ paddingLeft: '20px' }}>
            <ReactEcharts
              ref={chartRef}
              style={{ width: "100%", height: "100%" }}
              option={option}
            />{" "}
          </div>
        </div>

        {/* age and gender data */}
        <div className="flex flex-col md:flex-row justify-between w-full h-full mb-10">
          <div className="w-full md:w-[47%] mb-6 md:mb-0">
            <SubHeading>Age Group</SubHeading>
            <p className="text-base md:text-[16px] mt-2 w-full md:w-[80%]">
              The {area_name} has a population with a similar volume across
              different age groups.
            </p>
            <div>
              <AgeComparison />
            </div>
          </div>
          <div className="w-full md:w-[47%]">
            <SubHeading>
              Gender Comparison
            </SubHeading>
            <p className="text-base md:text-[16px] mt-2 w-full md:w-[80%]">
              The {area_name} population consists of various genders in
              comparable numbers.
            </p>
            <div>
              <GenderComparision />
            </div>
          </div>
        </div>

        {/* martial status and ethnicity */}
        <div className="flex flex-col md:flex-row w-full h-full mb-10 overflow-hidden">
          <div className="w-full md:w-2/5 flex flex-col justify-center mb-6 md:mb-0">
            <SubHeading>Martial Status</SubHeading>
            <p className="text-base md:text-[16px] mt-2 w-full md:w-[80%]">
              "In the {area_name}, people of different marital statuses are present
              in similar numbers."
            </p>
            <div className="-ml-5 -mt-5">
              <MarriageComparison />
            </div>
          </div>
          <div className="w-full md:w-3/5">
            <SubHeading>
              Race & Ethnicity
            </SubHeading>
            <p className="text-base md:text-[16px] mt-2 w-full md:w-[80%] mb-[30px] md:mb-[70px]">
              The {area_name} is home to a variety of races and ethnicities, all
              represented in similar numbers.
            </p>

            <RaceComparison />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demographic;