import { GoogleMap } from "@react-google-maps/api";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

export default function StreetViewComponent({ longitude, lattitude }) {
  const center = {
    lat: parseFloat(lattitude),
    lng: parseFloat(longitude),
  };

  const handleMapLoad = (map) => {
    // Ensure the mapTypeId is set to satellite
    map.setMapTypeId("satellite");
  };

  return (
    <div className="w-full h-full">
      <GoogleMap
        id="street-view-map"
        onLoad={handleMapLoad}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={30}
        mapTypeId="satellite"
        options={{
          streetViewControl: true,
        }}
      />
    </div>
  );
}
