import React, { useState, useEffect, useContext } from "react";
import aboutMap from "../../assets/about-map.png";
import search from "../../assets/search.svg";
import areaicon from "../../assets/Area.svg";
import group from "../../assets/Group.svg";
import infoSvg from "../../assets/info.svg";
import MapBoxComponent from "./MapBoxComponent";
import { CounterContext } from "../../hooks/HomeAi";
import HomeMapBoxComponent from "./HomeMap";

const HeroSection = () => {
  const { pinCode, setPinCode, area_name, area, populationValue, about, rating } =
    useContext(CounterContext);


  const [pin, setpin] = useState(pinCode);
  const [score, setScore] = useState(0);
  const [animatedScore, setAnimatedScore] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const homeLLCScore = Math.floor(Math.random() * 3) + 7;

  const getOrdinalSuffix = (rank) => {
    const j = rank % 10,
      k = rank % 100;
    if (j==1&&k!=11) {
      return (
        <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-normal mb-4">
          {rank}<sup>st</sup>
        </h3>
      );
    }
    if (j==2&&k!=12) {
      return (
        <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-normal mb-4">
          {rank}<sup>nd</sup>
        </h3>
      );
    }
    if (j == 3 && k != 13) {
      return (
        <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-normal mb-4">
          {rank}<sup>rd</sup>
        </h3>
      );
    }
    return (
      <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-normal mb-4">
        {rank}<sup>th</sup>
      </h3>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setScore((prevScore) => {
        if (prevScore < homeLLCScore) {
          setIsAnimating(true);
          setAnimatedScore(prevScore + 1);
          return prevScore + 1;
        } else {
          clearInterval(interval);
          return prevScore;
        }
      });
    }, 600);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []); // Depend on rating?.homellcscore

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300); // Match the duration of the CSS animation
      return () => clearTimeout(timer);
    }
  }, [animatedScore, isAnimating]);

  return (
    <div className="w-full h-full bg-homeAi-hero bg-no-repeat bg-cover bg-center relative  fixed top-0 left-1/2 transform -translate-x-1/2 w-[95%] 2xl:w-4/5 z-50">
      <div className="absolute inset-0 bg-[#212529] bg-opacity-90"></div>
      <div className="relative w-[95%] 2xl:w-[90%] pt-16 mx-auto text-white h-full">
        <div className="absolute right-0 flex items-center justify-centre w-[238px] justify-between border-b pb-2 mr-[75px]">
          <input
            type="number"
            value={pin}
            className="bg-transparent w-[120px] px-4 font-quickSand text-lg font-normal outline-none"
            onChange={(e) => setpin(e.target.value)}
          />
          <button
            className="font-quickSand border-none outline-none bg-transparent text-xl font-semibold flex items-center gap-2"
            onClick={(e) => setPinCode(pin)}
          >
            <img src={search} alt="search" />
            Check
          </button>
        </div>
        <div className="w-full 2xl:w-[85%] mx-auto mb-[30px] pt-6">
          <div className="w-full flex justify-center items-center flex-col">
            <h1 className="text-[30px] leading-[40px] w-[314px] sm:w-auto lg:w-[1006px] mx-auto sm:mx-0  mt-[50px] text-center sm:text-5xl md:text-[64px] sm:leading-[82.4px] font-light ">
              {area_name}
            </h1>
            <div className="flex items-center justify-between mb-8 w-[370px]">
              <div className="flex items-center gap-4 ml-2">
                <img src={areaicon} alt="area" />
                <p className="text-xl font-normal">
                  {area} Km<sup>2</sup>
                </p>
              </div>
              <div className="flex items-center gap-4 mr-2">
                <img src={group} alt="group" />
                <p className="text-xl font-normal">{populationValue}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col relative overflow-hidden">
            <svg
              width="265"
              height="162"
              viewBox="0 0 315 212"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <mask id="mask" x="0" y="0" width="100%" height="100%">
                  <rect
                    x="0"
                    y="0"
                    width={"80%"}
                    height="100%"
                    fill="white"
                    className="score-fill"
                  />
                </mask>
              </defs>
              <path
                d="M289.393 209.435C297.487 212.622 306.714 208.658 309.077 200.287C314.607 180.695 316.301 160.165 314.006 139.837C311.122 114.278 302.022 89.8142 287.501 68.5837C272.98 47.3533 253.48 30.0022 230.706 18.0469C207.932 6.09165 182.576 -0.104037 156.855 0.0013213C131.134 0.10668 105.83 6.50988 83.1542 18.6513C60.4787 30.7928 41.1219 48.303 26.7754 69.6517C12.4288 91.0004 3.52914 115.538 0.854089 141.12C-1.27349 161.466 0.58794 181.981 6.27874 201.527C8.71029 209.879 17.9695 213.767 26.0368 210.514C34.104 207.261 37.9024 198.097 35.6804 189.687C31.7915 174.968 30.591 159.623 32.1833 144.396C34.3233 123.93 41.4431 104.3 52.9203 87.2214C64.3975 70.1424 79.883 56.1342 98.0234 46.4211C116.164 36.7079 136.407 31.5853 156.984 31.5011C177.561 31.4168 197.845 36.3733 216.065 45.9375C234.284 55.5018 249.884 69.3826 261.5 86.367C273.117 103.351 280.398 122.922 282.705 143.37C284.422 158.583 283.347 173.938 279.579 188.688C277.426 197.115 281.299 206.248 289.393 209.435Z"
                fill="#F8F9FA"
              />
              <path
                d="M289.393 209.435C297.487 212.622 306.714 208.658 309.077 200.287C314.607 180.695 316.301 160.165 314.006 139.837C311.122 114.278 302.022 89.8142 287.501 68.5837C272.98 47.3533 253.48 30.0022 230.706 18.0469C207.932 6.09165 182.576 -0.104037 156.855 0.0013213C131.134 0.10668 105.83 6.50988 83.1542 18.6513C60.4787 30.7928 41.1219 48.303 26.7754 69.6517C12.4288 91.0004 3.52914 115.538 0.854089 141.12C-1.27349 161.466 0.58794 181.981 6.27874 201.527C8.71029 209.879 17.9695 213.767 26.0368 210.514C34.104 207.261 37.9024 198.097 35.6804 189.687C31.7915 174.968 30.591 159.623 32.1833 144.396C34.3233 123.93 41.4431 104.3 52.9203 87.2214C64.3975 70.1424 79.883 56.1342 98.0234 46.4211C116.164 36.7079 136.407 31.5853 156.984 31.5011C177.561 31.4168 197.845 36.3733 216.065 45.9375C234.284 55.5018 249.884 69.3826 261.5 86.367C273.117 103.351 280.398 122.922 282.705 143.37C284.422 158.583 283.347 173.938 279.579 188.688C277.426 197.115 281.299 206.248 289.393 209.435Z"
                fill="#ED5272"
                mask="url(#mask)"
              />
            </svg>

            <h2
              className={`absolute text-[90px] font-semibold text-center  ${isAnimating ? "score-animation" : ""
                }`}
            >
              {animatedScore}
            </h2>
            <div className="flex items-center gap-2 mt-4">
              <h3 className="text-xl font-medium">Home LLC Score</h3>
              <img src={infoSvg} alt="infoSvg" />
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-10 gap-4">
            <div className="w-[319px] text-center">
              {getOrdinalSuffix(rating?.ranking1.rank)}
              <p className="text-md font-normal">
                {rating?.ranking1.quote}
              </p>
            </div>
            <div className="w-[319px] text-center">
              <h3 className="text-6xl font-normal mb-4">
                {getOrdinalSuffix(rating?.ranking2.rank)}
              </h3>
              <p className="text-md font-normal">
                {rating?.ranking2.quote}
              </p>
            </div>
            <div className="w-[319px] text-center">
              <h3 className="text-6xl font-normal mb-4">
                {getOrdinalSuffix(rating?.ranking3.rank)}
              </h3>
              <p className="text-md font-normal">
                {rating?.ranking3.quote}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start justify-between gap-5 w-full pb-5">
          <div className="w-full md:w-3/4">
            <h3 className="text-4xl 2xl:text-[80px] 2xl:leading-[120px] mb-10 font-normal">
              About
            </h3>
            <p className="text-lg leading-[30px] text-[#F8F9FA] font-light">
              {about}
            </p>
          </div>
          <div className="w-full md:w-1/4 flex items-end">
            <HomeMapBoxComponent
            // styleUrl={"mapbox://styles/jayambalani/cly90df9j00iy01qv8uh427i3"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;