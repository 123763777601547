import React from "react";

const HeroSection = () => {
  return (
    <div className="w-full h-[1030px] sm:h-[900px] bg-hero-borrow-mobile sm:bg-hero-borrow bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-hero-section-overlay bg-cover">
        <div className="flex flex-col items-center h-full pt-[60px] sm:py-0 sm:pt-40">
          <h2 className="text-[30px] leading-[40px] w-[314px] sm:w-auto mx-auto sm:mx-0 mt-[150px] text-center sm:text-5xl md:text-[64px] sm:leading-[82.4px] font-light capitalize mb-[100px] sm:mb-8 text-white">
            Get upto <span className="font-bold text-[#ED5272]">$500,000</span> from your
            home equity
          </h2>

          {/* row items */}
          <div className="flex flex-col md:flex-row gap-2 sm:gap-8">
            <div className="border-r-0 sm:border-r flex flex-col items-center justify-center border-white sm:h-[64px]">
              <p
                className="text-white mx-14 mb-2 sm:my-8 text-[16px] leading-[24px]  sm:text-[22px] 
              sm:leading-[33px] font-bold capitalize whitespace-nowrap">
                No monthly payments
              </p>
              <svg
                className="sm:hidden my-4"
                width="37"
                height="2"
                viewBox="0 0 37 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="0.0106793"
                  y1="0.500076"
                  x2="36.0052"
                  y2="1.12836"
                  stroke="white"
                />
              </svg>
            </div>
            <div className="border-r-0 sm:border-r flex flex-col items-center justify-center border-white sm:h-[64px]">
              <p
                className="text-white mx-14 mb-2 sm:my-8 text-[16px] leading-[24px]  sm:text-[22px] 
              sm:leading-[33px] font-bold capitalize whitespace-nowrap">
                No income requirements
              </p>
              <svg
                className="sm:hidden my-4"
                width="37"
                height="2"
                viewBox="0 0 37 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <line
                  x1="0.0106793"
                  y1="0.500076"
                  x2="36.0052"
                  y2="1.12836"
                  stroke="white"
                />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center sm:h-[64px]">
              <p
                className="text-white mx-14 mb-2 sm:my-8 text-[16px] leading-[24px]  sm:text-[22px] 
              sm:leading-[33px] font-bold capitalize whitespace-nowrap">
                No need to sell, borrow, or stress
              </p>
            </div>
          </div>

          {/* hero buttons */}
          <div className="w-[328px] sm:w-[710px] h-auto sm:h-[60px] flex flex-col sm:flex-row gap-5 sm:gap-8 mt-[100px] px-4 mb-[240px] sm:mb-0">
            <button
              className="rounded-[50px] w-full sm:h-auto sm:w-[310px] text-white  py-3
            sm:py-0 sm:px-[14px] text-center text-[14px] leading-[22px] sm:text-[22px] sm:leading-[32px] bg-[#ED5272]">
              <a href="#schedule-call"> Schedule a call</a>
            </button>

            <button
              className="border border-white rounded-[50px] w-full sm:h-auto sm:w-[310px] text-white py-3
        sm:py-0 sm:px-[14px] text-center text-[14px] leading-[22px] sm:text-[22px] sm:leading-[32px]
        hover:bg-white hover:text-black transition duration-300 ease-in-out">
              <a href="/cashout">Check if you qualify </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
