export const transistData = [
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Borregas",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0180394, 37.4116674] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java and Geneva",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0140955, 37.4108065] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java and Bordeaux",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.022175, 37.4128109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Crossman and Java",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0105573, 37.4098707] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Crossman and Baltic",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0092956, 37.4137002] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Lockheed Martin Transit Center",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0278772, 37.4105095] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Mathilda and Ross",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0270432, 37.4016019] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Caribbean & Moffett Park",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0006068, 37.412285] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Vienna",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9995848, 37.403377] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Borregas",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.017707, 37.411398899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Borregas",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0183909, 37.411915500000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Borregas",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "light_rail_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0170496, 37.4114093] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Borregas Station (1)",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "light_rail_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0170279, 37.4113975] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Borregas",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0190374, 37.4119302] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Bordeaux",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0215866, 37.412284] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Geneva",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0136574, 37.4105259] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Bordeaux",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0227049, 37.4133431] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Crossman",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0118723, 37.4092824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Mathilda",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0240174, 37.413825800000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Crossman",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0115509, 37.4095099] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Borregas",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.017707, 37.411398899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Java & Crossman",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0118723, 37.4092824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Crossman & Baltic",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0089954, 37.4141732] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Fair Oaks & Tasman",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.010781, 37.4024908] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94085, USA",
      name: "Fair Oaks & San Conrado",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0143032, 37.3916895] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Lakehaven & Lawrence",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9969446, 37.3959665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94086, USA",
      name: "Mathilda & California",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0323981, 37.382533] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Santa Clara, CA 95054, USA",
      name: "Old Ironsides & Tasman",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95054",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9802473, 37.402672499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 94089, USA",
      name: "America Center Terminal",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.978863, 37.417603] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "499 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Alliance",
      rating: "4.2",
      formatted_phone_number: "(408) 735-7181",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0310074, 37.3894515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "498 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "3.2",
      formatted_phone_number: "(408) 245-2122",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.030183, 37.3891607] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "296 Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Chevron",
      rating: "3.8",
      formatted_phone_number: "(408) 245-9676",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0178385, 37.3812747] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "2.4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9964558, 37.3872331] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      name: "Loop Neighborhood Market",
      rating: "2.4",
      formatted_phone_number: "(408) 982-9040",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/great-america-shell/?utm_source=GBP&utm_campaign=5390%20Great%20America%20Parkway&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.978386, 37.4100366] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "776 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Shell",
      rating: "3.8",
      formatted_phone_number: "(408) 733-3352",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008387-776-n-mathilda-ave/en_US",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0283065, 37.3953243] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1101 Lawrence Expy, Sunnyvale, CA 94089, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(408) 737-1971",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008386-1101-n-lawrence-expy/en_US",
      first_type: "gas_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9962287, 37.3963591] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1265 Lawrence Station Rd, Sunnyvale, CA 94089, USA",
      name: "Chevron",
      rating: "2.5",
      formatted_phone_number: "(408) 744-0725",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9961679, 37.405942800000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "698 Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "3.6",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "http://valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0141558, 37.3909081] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "724 N Mathilda Ave, Sunnyvale, CA 94086, USA",
      name: "Chevron Station Sunnyvale",
      rating: "2.4",
      formatted_phone_number: "(408) 830-0600",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 7:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      website: "http://chevronwithtechron.com/",
      first_type: "gas_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0288274, 37.3940182] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "117 W Maude Ave, Sunnyvale, CA 94085, USA",
      name: "World Gasoline",
      rating: "4.2",
      formatted_phone_number: "(408) 736-7816",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0263786, 37.3887248] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "801 N Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Poppy Pop By Say Hi",
      rating: "2.3",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0141504, 37.3931055] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "499 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Yas gas",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0309793, 37.389528] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "499 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Great Gas",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0310074, 37.3894515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "499 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Great Gas Food Mart",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0310287, 37.3893853] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "76",
      rating: "1",
      formatted_phone_number: "(408) 736-9664",
      opening_hours: "missing",
      website:
        "https://www.76.com/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.996494, 37.387313] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      name: "Shell",
      rating: "3.8",
      formatted_phone_number: "(408) 982-9040",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10013534-5390-great-america-pkwy/en_US",
      first_type: "gas_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9781368, 37.4100811] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1265 Birchwood Dr, Sunnyvale, CA 94089, USA",
      name: "BigWheels.net",
      rating: "4.9",
      formatted_phone_number: "(408) 744-9433",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 5:30 PM",
          "Tuesday: 9:30 AM – 5:30 PM",
          "Wednesday: 9:30 AM – 5:30 PM",
          "Thursday: 9:30 AM – 5:30 PM",
          "Friday: 9:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://bigwheels.net/",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9930038, 37.4058837] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "691 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Dave's Mobile Crane Services",
      rating: "missing",
      formatted_phone_number: "(408) 735-0547",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0300802, 37.3935235] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1292 Forgewood Ave, Sunnyvale, CA 94089, USA",
      name: "Made In Japan / USA / Europe",
      rating: "4.7",
      formatted_phone_number: "(408) 720-9000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.mij-usa.com/",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9916439, 37.409945] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94089, USA",
      name: "Sector Security-Communications",
      rating: "missing",
      formatted_phone_number: "(408) 734-0109",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.004179, 37.4298335] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1292 Forgewood Ave, Sunnyvale, CA 94089, USA",
      name: "B W's German-Japanese Auto Services",
      rating: "4.5",
      formatted_phone_number: "(650) 967-8889",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "point_of_interest",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9916246, 37.409961200000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1249 Birchwood Dr, Sunnyvale, CA 94089, USA",
      name: "Maaco Auto Body Shop & Painting",
      rating: "4.5",
      formatted_phone_number: "(408) 214-0853",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.maaco.com/locations/ca/sunnyvale-12107/?utm_source=google&utm_medium=organic&utm_campaign=gbp_website",
      first_type: "point_of_interest",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9928288, 37.4046864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "438 W Maude Ave, Sunnyvale, CA 94085, USA",
      name: "Caliber Collision",
      rating: "4.7",
      formatted_phone_number: "(408) 736-8300",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.caliber.com/find-a-location/sunnyvale?utm_source=google&utm_medium=gbp-profile&utm_campaign=collision",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0318663, 37.389808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "105 E Maude Ave, Sunnyvale, CA 94085, USA",
      name: "American European Auto Clinic",
      rating: "4.3",
      formatted_phone_number: "(408) 733-7075",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:30 PM",
          "Tuesday: 8:00 AM – 5:30 PM",
          "Wednesday: 8:00 AM – 5:30 PM",
          "Thursday: 8:00 AM – 5:30 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0254375, 37.3882113] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "771 N Mathilda Ave A, Sunnyvale, CA 94085, USA",
      name: "A-1 Performance Auto Repair",
      rating: "4.4",
      formatted_phone_number: "(408) 530-0089",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1530" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: 8:30 AM – 3:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://a1performanceautorepair.com/",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0293046, 37.3951453] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "120 N Murphy Ave, Sunnyvale, CA 94086, USA",
      name: "Performance European",
      rating: "5",
      formatted_phone_number: "(650) 968-4559",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 6:00 PM",
          "Tuesday: 9:30 AM – 6:00 PM",
          "Wednesday: 9:30 AM – 6:00 PM",
          "Thursday: 9:30 AM – 6:00 PM",
          "Friday: 9:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "point_of_interest",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0292841, 37.3787838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "771 N Mathilda Ave #4, Sunnyvale, CA 94085, USA",
      name: "Hank Auto Repair",
      rating: "4.8",
      formatted_phone_number: "(408) 730-6917",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0299888, 37.3952308] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "771 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Rich's Tires Of Sunnyvale",
      rating: "4.7",
      formatted_phone_number: "(650) 495-2945",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website: "http://richstiressunnyvale.com/",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0296705, 37.3951733] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "283 N Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Godinez Tires",
      rating: "4.5",
      formatted_phone_number: "(408) 730-2396",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0188554, 37.3813586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1281 Forgewood Ave, Sunnyvale, CA 94089, USA",
      name: "Active Auto Body",
      rating: "4.9",
      formatted_phone_number: "(408) 734-3400",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://activeautobodyshop.com/?utm_campaign=gmb",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9922539, 37.4091615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1265 Lawrence Station Rd, Sunnyvale, CA 94089, USA",
      name: "Chevron",
      rating: "2.5",
      formatted_phone_number: "(408) 744-0725",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9961679, 37.405942800000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "698 Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "3.6",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "http://valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0141558, 37.3909081] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "801 N Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      name: "Fair Oaks Auto Care‎",
      rating: "4.7",
      formatted_phone_number: "(408) 773-1783",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0143278, 37.3931384] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "105 W Weddell Dr, Sunnyvale, CA 94089, USA",
      name: "Michaux Automotive",
      rating: "4.8",
      formatted_phone_number: "(408) 745-7660",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:30 PM",
          "Tuesday: 10:00 AM – 6:30 PM",
          "Wednesday: 10:00 AM – 6:30 PM",
          "Thursday: 10:00 AM – 6:30 PM",
          "Friday: 10:00 AM – 6:30 PM",
          "Saturday: 10:00 AM – 6:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.michauxautomotive.com/",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0234519, 37.3985092] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94085, USA",
      name: "OK AUTO BODY REPAIR",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_repair",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0246533, 37.3975321] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "121 Lawrence Station Rd, Sunnyvale, CA 94089, USA",
      name: "ColorFittings",
      rating: "5",
      formatted_phone_number: "(312) 380-6435",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.colorfittings.com/",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.996109, 37.407723] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Sunnyvale Car Spa",
      rating: "3.3",
      formatted_phone_number: "(408) 736-9562",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      website: "https://www.carspasunnyvale.com/",
      first_type: "car_wash",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9965947, 37.3870008] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "2.4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9964558, 37.3872331] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      name: "Loop Neighborhood Market",
      rating: "2.4",
      formatted_phone_number: "(408) 982-9040",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/great-america-shell/?utm_source=GBP&utm_campaign=5390%20Great%20America%20Parkway&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.978386, 37.4100366] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "724 N Mathilda Ave, Sunnyvale, CA 94086, USA",
      name: "Chevron Station Sunnyvale",
      rating: "2.4",
      formatted_phone_number: "(408) 830-0600",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 7:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      website: "http://chevronwithtechron.com/",
      first_type: "gas_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0288274, 37.3940182] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94085, USA",
      name: "CAR WASH",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0288246, 37.3941618] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "616 Morse Ave, Sunnyvale, CA 94085, USA",
      name: "VIP Detailing",
      rating: "4.7",
      formatted_phone_number: "(650) 283-3591",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://vipdetailing-ap.com/",
      first_type: "car_wash",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0189665, 37.389878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94085, USA",
      name: "Chevron Car wash",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0177895, 37.3812979] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View -Alviso Rd, Santa Clara, CA 95054, USA",
      name: "CAR WASH",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9784958, 37.4100763] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "5099 Stars and Stripes Dr, Santa Clara, CA 95054, USA",
      name: "Santa Clara, CA/Great America",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website:
        "https://www.amtrak.com/servlet/ContentServer?pagename=am/am2Station/Station_Page&code=GAC",
      first_type: "transit_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9670929, 37.4068503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "VTA Greatmall Light Rail station, Milpitas, CA 95035, USA",
      name: "Great Mall",
      rating: "4.2",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "http://www.vta.org/",
      first_type: "light_rail_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9018978, 37.4142224] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "780 County Hwy G4 #304, San Jose, CA 95131, USA",
      name: "Child's Turn",
      rating: "missing",
      formatted_phone_number: "(877) 909-3748",
      opening_hours: "missing",
      website: "http://www.childsturn.com/",
      first_type: "transit_station",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9140207, 37.3962175] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "N First and Tony P. Santos",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9667364, 37.4236222] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Holger and Zanker",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.940924, 37.4189632] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Vista Montana and 1st",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9536842, 37.4151675] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "1st and Nicholson",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9471427, 37.410965] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Baypointe Light Rail Station",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9412704, 37.4109778] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman and Zanker",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9370959, 37.4125309] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman and 1st",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9447706, 37.4092792] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman and Rio Robles",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.949132, 37.4078774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "McCarthy and Ranch",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9240877, 37.4263568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman and Morgridge",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.932302, 37.4134178] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Technology and McCarthy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9230908, 37.42052] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Murphy Ranch and Sandisk",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9250587, 37.4163919] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman & Cisco",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9285032, 37.412436199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Santa Clara, CA 95054, USA",
      name: "Old Mtn View-Alviso and Great America",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9788155, 37.4104916] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Murphy Ranch and McCarthy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.922445, 37.413692] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "River Oaks and Zanker",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9353474, 37.4042277] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "1st and River Oaks",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9395666, 37.4020774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95002, USA",
      name: "1st & Liberty",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95002",
    },
    geometry: { type: "Point", coordinates: [-121.974391, 37.425924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Liberty St, Alviso, CA 95002, USA",
      name: "Liberty & Taylor",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95002",
    },
    geometry: { type: "Point", coordinates: [-121.9749307, 37.4262558] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman & Champion",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.95309, 37.4098921] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman & 1st",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9440185, 37.409434] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 94089, USA",
      name: "America Center Terminal",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.978863, 37.417603] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA 95134, USA",
      name: "Tasman & Cisco",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9285351, 37.4122027] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Tasman & Alder",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9175409, 37.4136583] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Tasman & Alder",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9160865, 37.4140685] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Abel & Penitencia",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9100939, 37.4349095] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Santa Clara, CA 95054, USA",
      name: "Old Ironsides & Tasman",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95054",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9802473, 37.402672499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "N Milpitas Blvd & Town Center Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9007308, 37.4353976] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Milpitas & Dixon",
      rating: "3.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.911461, 37.456584] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "Great Mall & Abel",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.904212, 37.415196] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Santa Clara, CA 95054, USA",
      name: "Great America & Mission College",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9769257, 37.3935307] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "S Milpitas Blvd & Calaveras Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8995709, 37.4318443] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "N Milpitas Blvd & Calaveras Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8995912, 37.4328927] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "S Milpitas Blvd & Los Coches St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8978579, 37.4301764] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "S Milpitas Blvd & Los Coches St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8977552, 37.429854899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "S Milpitas Blvd & Turquoise St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8958188, 37.4273812] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Milpitas, CA 95035, USA",
      name: "S Milpitas Blvd & Turquoise St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8957387, 37.4267469] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1755 S Milpitas Blvd, Milpitas, CA 95035, USA",
      name: "Milpitas",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "http://www.bart.gov/",
      first_type: "transit_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8910816, 37.4102758] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 Montague Expy, San Jose, CA 95131, USA",
      name: "Chevron",
      rating: "3.8",
      formatted_phone_number: "(408) 944-9258",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station/",
      first_type: "gas_station",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9128757, 37.397655] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "190 W Calaveras Blvd, Milpitas, CA 95035, USA",
      name: "76",
      rating: "3.8",
      formatted_phone_number: "(408) 262-7324",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-milpitas-union-76-0000855720/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9098023, 37.4278109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "27 S Park Victoria Dr, Milpitas, CA 95035, USA",
      name: "76",
      rating: "4.1",
      formatted_phone_number: "(408) 263-6188",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-phung-petroleum-corp-0000871301/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8844124, 37.4352461] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "367 Cypress Dr, Milpitas, CA 95035, USA",
      name: "Chevron",
      rating: "3.4",
      formatted_phone_number: "(408) 428-0999",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9216901, 37.4214998] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1780 S Main St, Milpitas, CA 95035, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(408) 946-2971",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008041-1780-s-main-st/en_US",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9025497, 37.4047974] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      name: "Loop Neighborhood Market",
      rating: "2.4",
      formatted_phone_number: "(408) 982-9040",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/great-america-shell/?utm_source=GBP&utm_campaign=5390%20Great%20America%20Parkway&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.978386, 37.4100366] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "43 S Abbott Ave, Milpitas, CA 95035, USA",
      name: "Arco Smog Pros Milpitas",
      rating: "4.3",
      formatted_phone_number: "(408) 262-7063",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9141712, 37.4279379] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "97 S Abbott Ave, Milpitas, CA 95035, USA",
      name: "76",
      rating: "3.3",
      formatted_phone_number: "(408) 946-8229",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-abbot-fuel-n-food-0000900902/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9134001, 37.4270442] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1640 N Milpitas Blvd, Milpitas, CA 95035, USA",
      name: "76",
      rating: "3.9",
      formatted_phone_number: "(408) 946-1642",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.91059, 37.454548] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 N Milpitas Blvd, Milpitas, CA 95035, USA",
      name: "Speedway",
      rating: "4",
      formatted_phone_number: "(408) 942-1669",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website:
        "https://www.speedway.com/locations/CA/Milpitas/1885-North-Milpitas-Boulevard",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9118647, 37.4577266] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 Montague Expy, San Jose, CA 95131, USA",
      name: "Propel Fuels",
      rating: "4.2",
      formatted_phone_number: "(800) 871-0773",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://propelfuels.com/",
      first_type: "gas_station",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9128682, 37.3976036] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1249 Great Mall Dr, Milpitas, CA 95035, USA",
      name: "Chevron",
      rating: "2.9",
      formatted_phone_number: "(408) 941-9309",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8980064, 37.412847199999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3751 Lafayette St, Santa Clara, CA 95054, USA",
      name: "Shell",
      rating: "1.6",
      formatted_phone_number: "(408) 988-4771",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008333-3751-lafayette-st/en_US",
      first_type: "gas_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9534862, 37.3876615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2300 Kruse Dr, San Jose, CA 95131, USA",
      name: "Pacific PrideSJ",
      rating: "3.7",
      formatted_phone_number: "(800) 743-6950",
      opening_hours: "missing",
      website: "http://pacificpride.com/",
      first_type: "gas_station",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9127995, 37.3927729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "990 Jacklin Rd, Milpitas, CA 95035, USA",
      name: "Loop Neighborhood Market",
      rating: "3.1",
      formatted_phone_number: "(408) 263-5359",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/jacklin-shell/?utm_source=GBP&utm_campaign=990%20Jacklin%20Road&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8925593, 37.446556] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Augustine Dr, Santa Clara, CA 95054, USA",
      name: "Shell",
      rating: "3.3",
      formatted_phone_number: "(408) 496-0695",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008334-2800-augustine-drive/en_US",
      first_type: "gas_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9783966, 37.3821011] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "461 S Milpitas Blvd, Milpitas, CA 95035, USA",
      name: "APCA",
      rating: "missing",
      formatted_phone_number: "(408) 385-2020",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 4:00 PM",
          "Tuesday: 10:00 AM – 4:00 PM",
          "Wednesday: 10:00 AM – 4:00 PM",
          "Thursday: 10:00 AM – 4:00 PM",
          "Friday: 10:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.apca.us/about-us/",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8970114, 37.4247317] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12 N Park Victoria Dr, Milpitas, CA 95035, USA",
      name: "Shell",
      rating: "2.7",
      formatted_phone_number: "(408) 262-1251",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008042-12-n-park-victoria-dr/en_US",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8842269, 37.4359881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3471 Lafayette St, Santa Clara, CA 95054, USA",
      name: "Rotten Robbie",
      rating: "4.2",
      formatted_phone_number: "(408) 988-5407",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.rottenrobbie.com/",
      first_type: "gas_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9505955, 37.3835934] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "2.4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9964558, 37.3872331] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "48370 Kato Rd, Fremont, CA 94538, USA",
      name: "Tesla Service Center",
      rating: "4.4",
      formatted_phone_number: "(510) 249-3650",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      website: "https://www.tesla.com/",
      first_type: "car_repair",
      zip_code: "48370",
    },
    geometry: { type: "Point", coordinates: [-121.9273068, 37.4672957] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "301 Ranch Dr, Milpitas, CA 95035, USA",
      name: "Walmart Auto Care Centers",
      rating: "2.3",
      formatted_phone_number: "(408) 934-0403",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.walmart.com/store/2119-milpitas-ca/auto-care-center",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9211774, 37.431568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "100 S Main St, Milpitas, CA 95035, USA",
      name: "Big O Tires",
      rating: "4.3",
      formatted_phone_number: "(408) 883-3485",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 7:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.bigotires.com/location/ca/milpitas/100-s-main-st-95035/005300?utm_source=google&utm_medium=maps&utm_campaign=google+maps&y_source=1_MTU1OTQ3NzMtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9056632, 37.4292128] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "92 Serra Way, Milpitas, CA 95035, USA",
      name: "Quality Tune Up Car Care Center",
      rating: "4.7",
      formatted_phone_number: "(408) 262-3211",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.qtucarcare.com/",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.907864, 37.427594] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Aldo Ave, Santa Clara, CA 95054, USA",
      name: "Crash Champions Collision Repair Santa Clara Aldo",
      rating: "4.7",
      formatted_phone_number: "(408) 988-4011",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://crashchampions.com/locations/crash-champions-santa-clara-aldo",
      first_type: "car_repair",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9477911, 37.3836447] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "907 Hanson Ct, Milpitas, CA 95035, USA",
      name: "Exact Motorsports",
      rating: "4.9",
      formatted_phone_number: "(408) 263-8100",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 6:00 PM",
          "Tuesday: 9:30 AM – 6:00 PM",
          "Wednesday: 9:30 AM – 6:00 PM",
          "Thursday: 9:30 AM – 6:00 PM",
          "Friday: 9:30 AM – 6:00 PM",
          "Saturday: 9:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.exactmotorsports.com/",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9090521, 37.4445133] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 Dado St, San Jose, CA 95131, USA",
      name: "American Tire Distributors",
      rating: "4.2",
      formatted_phone_number: "(408) 435-3340",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://www.atd.com/",
      first_type: "point_of_interest",
      zip_code: "95131",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9135374, 37.389758099999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 Montague Expy, San Jose, CA 95131, USA",
      name: "Mr. Tint, Inc.",
      rating: "4.2",
      formatted_phone_number: "(408) 340-6800",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.mistertint.com/",
      first_type: "car_repair",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9128447, 37.3974278] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 Montague Expy, San Jose, CA 95131, USA",
      name: "Chevron",
      rating: "3.8",
      formatted_phone_number: "(408) 944-9258",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station/",
      first_type: "gas_station",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9128757, 37.397655] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "46 S Abbott Ave, Milpitas, CA 95035, USA",
      name: "O'Reilly Auto Parts",
      rating: "4.1",
      formatted_phone_number: "(408) 263-5660",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 9:00 PM",
          "Tuesday: 7:30 AM – 9:00 PM",
          "Wednesday: 7:30 AM – 9:00 PM",
          "Thursday: 7:30 AM – 9:00 PM",
          "Friday: 7:30 AM – 9:00 PM",
          "Saturday: 7:30 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      website:
        "https://locations.oreillyauto.com/ca/milpitas/autoparts-2558.html",
      first_type: "electronics_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9126649, 37.4276153] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "490 Perry Ct, Santa Clara, CA 95054, USA",
      name: "Foreign Affair Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(408) 727-4884",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:00 PM",
          "Tuesday: 7:30 AM – 5:00 PM",
          "Wednesday: 7:30 AM – 5:00 PM",
          "Thursday: 7:30 AM – 5:00 PM",
          "Friday: 7:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9437594, 37.3832757] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3610 Bassett St, Santa Clara, CA 95054, USA",
      name: "J Speed",
      rating: "4.8",
      formatted_phone_number: "(408) 988-0076",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: Closed",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: 8:30 AM – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "95054",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.953258, 37.385405899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1638 S Main St, Milpitas, CA 95035, USA",
      name: "Milpitas Smog Test Only",
      rating: "4.7",
      formatted_phone_number: "(408) 262-9885",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1530" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: 10:00 AM – 3:30 PM",
        ],
      },
      website: "http://www.milpitassmogtestonly.com/",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9022743, 37.4071624] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "328 Sango Ct, Milpitas, CA 95035, USA",
      name: "Olson Brother's Body Shop",
      rating: "3.9",
      formatted_phone_number: "(408) 263-8833",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://olsonbrothersbodyshop.com/",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8939019, 37.4063853] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1630 S Main St, Milpitas, CA 95035, USA",
      name: "Precision Tune Auto Care",
      rating: "4.4",
      formatted_phone_number: "(408) 582-0120",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.precisiontune.com/MilpitasCA",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9025853, 37.4072155] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1265 Birchwood Dr, Sunnyvale, CA 94089, USA",
      name: "BigWheels.net",
      rating: "4.9",
      formatted_phone_number: "(408) 744-9433",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 5:30 PM",
          "Tuesday: 9:30 AM – 5:30 PM",
          "Wednesday: 9:30 AM – 5:30 PM",
          "Thursday: 9:30 AM – 5:30 PM",
          "Friday: 9:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://bigwheels.net/",
      first_type: "car_repair",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9930038, 37.4058837] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1335 Minnis Cir, Milpitas, CA 95035, USA",
      name: "Yip's Auto Repair",
      rating: "4.6",
      formatted_phone_number: "(408) 262-8878",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9120831, 37.4493119] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 S Main St, Milpitas, CA 95035, USA",
      name: "Joe's Tune Up & Auto Services Center",
      rating: "3.8",
      formatted_phone_number: "(408) 262-6203",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9052424, 37.424996099999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1345 Minnis Cir, Milpitas, CA 95035, USA",
      name: "Exclusively German",
      rating: "3",
      formatted_phone_number: "(408) 956-8371",
      opening_hours: "missing",
      website: "http://exclusively-german.blogspot.in/",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9121001, 37.4495096] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "142 N Milpitas Blvd, Milpitas, CA 95035, USA",
      name: "Bay Area Fluid Dynamics, Corporation.",
      rating: "5",
      formatted_phone_number: "(408) 250-4758",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_repair",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8988389, 37.4351479] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1298 County Hwy G4, San Jose, CA 95131, USA",
      name: "Valero",
      rating: "3.7",
      formatted_phone_number: "(408) 955-0238",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      website: "http://www.montaguevalero.com/",
      first_type: "car_wash",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9052162, 37.4039181] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 Montague Expy, San Jose, CA 95131, USA",
      name: "Montague Premier Car Wash & Chevron",
      rating: "3.5",
      formatted_phone_number: "(408) 944-9258",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      website: "http://premiercarwashes.com/",
      first_type: "car_wash",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9128585, 37.3976129] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "190 W Calaveras Blvd, Milpitas, CA 95035, USA",
      name: "76",
      rating: "3.8",
      formatted_phone_number: "(408) 262-7324",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-milpitas-union-76-0000855720/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9098023, 37.4278109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Sunnyvale Car Spa",
      rating: "3.3",
      formatted_phone_number: "(408) 736-9562",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      website: "https://www.carspasunnyvale.com/",
      first_type: "car_wash",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9965947, 37.3870008] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1780 S Main St, Milpitas, CA 95035, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(408) 946-2971",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008041-1780-s-main-st/en_US",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9025497, 37.4047974] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      name: "Loop Neighborhood Market",
      rating: "2.4",
      formatted_phone_number: "(408) 982-9040",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/great-america-shell/?utm_source=GBP&utm_campaign=5390%20Great%20America%20Parkway&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.978386, 37.4100366] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "190 W Calaveras Blvd, Milpitas, CA 95035, USA",
      name: "Milpitas Auto Spa",
      rating: "3.4",
      formatted_phone_number: "(408) 262-7324",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      website: "http://milpitasautospa.com/",
      first_type: "car_wash",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.909799, 37.427994] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "990 Jacklin Rd, Milpitas, CA 95035, USA",
      name: "Loop Neighborhood Market",
      rating: "3.1",
      formatted_phone_number: "(408) 263-5359",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/jacklin-shell/?utm_source=GBP&utm_campaign=990%20Jacklin%20Road&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8925593, 37.446556] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2001 Agnew Rd, Santa Clara, CA 95054, USA",
      name: "Lafayette Car Wash",
      rating: "3.7",
      formatted_phone_number: "(408) 644-2531",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9594404, 37.3948154] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1097 E Duane Ave, Sunnyvale, CA 94085, USA",
      name: "Valero",
      rating: "2.4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9964558, 37.3872331] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2181 Laurelwood Rd, Santa Clara, CA 95054, USA",
      name: "Valero",
      rating: "3.2",
      formatted_phone_number: "(408) 988-6658",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "http://www.valero.com/",
      first_type: "gas_station",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9620028, 37.3842667] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1551 California Cir, Milpitas, CA 95035, USA",
      name: "Chevron",
      rating: "4",
      formatted_phone_number: "(408) 935-9752",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9206167, 37.4520416] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "990 Jacklin Rd, Milpitas, CA 95035, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(408) 263-5359",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008044-990-jacklin-road/en_US",
      first_type: "gas_station",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8929034, 37.4466417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2181 Laurelwood Rd, Santa Clara, CA 95054, USA",
      name: "New Bay Car Wash",
      rating: "3.2",
      formatted_phone_number: "(408) 244-2161",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:30 PM",
          "Tuesday: 9:00 AM – 4:30 PM",
          "Wednesday: 9:00 AM – 4:30 PM",
          "Thursday: 9:00 AM – 4:30 PM",
          "Friday: 9:00 AM – 4:30 PM",
          "Saturday: 9:00 AM – 4:30 PM",
          "Sunday: 9:00 AM – 4:30 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9620418, 37.3843094] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "367 Cypress Dr suite 100, Milpitas, CA 95035, USA",
      name: "McCarthy Ranch Car Wash",
      rating: "3.3",
      formatted_phone_number: "(408) 428-9363",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9216664, 37.4214] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View -Alviso Rd, Santa Clara, CA 95054, USA",
      name: "CAR WASH",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9784958, 37.4100763] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1625 N McCarthy Blvd, Milpitas, CA 95035, USA",
      name: "Chevron Car Wash",
      rating: "3.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9236276, 37.450795600000014],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "531 Bellwood Dr, Santa Clara, CA 95054, USA",
      name: "Sharp Auto Detailing Service",
      rating: "4.9",
      formatted_phone_number: "(408) 816-9030",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://sharpautodetail.com/",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9447656, 37.393489] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Aldo Ave, Santa Clara, CA 95054, USA",
      name: "Touchless Car Wash",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9493724, 37.3839175] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2181 Laurelwood Rd, Santa Clara, CA 95054, USA",
      name: "Santa Clara Valero car wash",
      rating: "3.5",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 6:00 PM",
          "Tuesday: 8:30 AM – 6:00 PM",
          "Wednesday: 8:30 AM – 6:00 PM",
          "Thursday: 8:30 AM – 6:00 PM",
          "Friday: 8:30 AM – 6:00 PM",
          "Saturday: 8:30 AM – 6:00 PM",
          "Sunday: 8:30 AM – 6:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9620418, 37.3843094] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd:Aughinbaugh Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.251199, 37.73796] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Aughinbaugh Way:Mecartney Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.25205, 37.738604] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd:Sharon Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.254467, 37.7379555] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Harbor Bay Ferry Terminal",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.256755, 37.7365015] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Aughinbaugh Way at The Lagoon",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2511135, 37.7407275] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd:Island Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2394694, 37.7355913] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Aughinbaugh Way:Robert Davey Jr Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.250035, 37.742687] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Robert Davey Jr Dr:Oyster Pond Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2451875, 37.7425465] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Marcuse St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2489317, 37.7371554] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Robert Davey Jr Dr:Channing Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2478595, 37.742997] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Baywalk Rd",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2490344, 37.7375659] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Ironwood Rd",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2451708, 37.7370701] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Verdemar Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2445097, 37.7366023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Aughinbaugh Way",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2505428, 37.7378193] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Aughinbaugh Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2518554, 37.738101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Aughinbaugh Way & Mecartney Rd",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.25196, 37.7384631] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Belmont Place",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2419679, 37.735999] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Aughinbaugh Way & Mecartney Rd",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2521401, 37.7387449] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Auburn Dr",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2413885, 37.7361775] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA 94502, USA",
      name: "Mecartney Rd & Sharon Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2543718, 37.737884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3255 Mecartney Rd, Alameda, CA 94502, USA",
      name: "76",
      rating: "3.8",
      formatted_phone_number: "(510) 523-7408",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.76.com/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2406835, 37.7363068] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1910 N Loop Rd, Alameda, CA 94502, USA",
      name: "Westesco",
      rating: "missing",
      formatted_phone_number: "(510) 865-6784",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2417206, 37.7290036] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883 Island Dr Ste B, Alameda, CA 94502, USA",
      name: "TJ Auto Glass Repair",
      rating: "5",
      formatted_phone_number: "(510) 328-4650",
      opening_hours: "missing",
      website: "http://tjglassrepair.com/",
      first_type: "car_repair",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2404705, 37.7374325] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Alta Mesa Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1258075, 37.8375825] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "1500 St Mary's Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1221055, 37.841514] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary's Rd/Carter Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1181155, 37.8424605] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Moraga Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.127296, 37.834123] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Corliss Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1260985, 37.8467055] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Devin Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125271, 37.8509585] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Ascot Dr",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1249295, 37.854505499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd/Kendall Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124231, 37.8576915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Alta Mesa Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125692, 37.83774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Moraga Library",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123049, 37.841046] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Carter Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.117904, 37.842488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Alta Mesa Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.126053, 37.837761] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Moraga Library",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123069, 37.841161] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Carter Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.117942, 37.8426] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Moraga Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1271709, 37.8345372] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Moraga Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1271379, 37.8341148] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S College",
      rating: "4.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.110135, 37.842095] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Way And Viader Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.129427, 37.83484] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Way And School St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.130002, 37.835271] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Corliss Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12593, 37.846848] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Alta Mesa Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125692, 37.83774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Moraga Library",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123049, 37.841046] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Carter Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.117904, 37.842488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Alta Mesa Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.126053, 37.837761] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Moraga Library",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123069, 37.841161] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S Rd And Carter Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.117942, 37.8426] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "St Mary'S College",
      rating: "4.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.110135, 37.842095] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Corliss Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12593, 37.846848] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Way And Camino Ricardo",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.134514, 37.837171] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Devin Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125466, 37.850704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Devin Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125175, 37.851215] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Ascot Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124857, 37.854798] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Way And Miramonte Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.142211, 37.8409972] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Ascot Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125015, 37.85491] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Kendall Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12414, 37.857716] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Kendall Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124222, 37.858045] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Eastwood Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.146275, 37.84341] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Rheem Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125075, 37.861076] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Buckingham Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12426, 37.864716] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Buckingham Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124082, 37.864931] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 Canyon Rd, Moraga, CA 94556, USA",
      name: "Moraga Service Center",
      rating: "4.3",
      formatted_phone_number: "(925) 376-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.mscsmog.com/",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1279975, 37.8338471] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "425 Moraga Rd, Moraga, CA 94556, USA",
      name: "Arco",
      rating: "3.2",
      formatted_phone_number: "(925) 376-9910",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1235193, 37.8590355] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1455 Moraga Way, Moraga, CA 94556, USA",
      name: "Chevron Moraga",
      rating: "4.5",
      formatted_phone_number: "(925) 376-3991",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1455-Moraga-Way-Moraga-CA-94556-id98791",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1275908, 37.8344449] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1410 Moraga Rd, Moraga, CA 94556, USA",
      name: "Nick's Auto Services",
      rating: "4.7",
      formatted_phone_number: "(925) 377-6425",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1262761, 37.8372198] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1410 Moraga Rd, Moraga, CA 94556, USA",
      name: "Valero",
      rating: "4.8",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.126047, 37.8371816] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Moraga Way, Moraga, CA 94556, USA",
      name: "Marathon gas & Mini Mart",
      rating: "3.5",
      formatted_phone_number: "(510) 309-9338",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2301" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0000" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 11:01 PM",
          "Tuesday: 7:00 AM – 11:00 PM",
          "Wednesday: Open 24 hours",
          "Thursday: 7:00 AM – 11:00 PM",
          "Friday: 7:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      website: "https://www.marathonfuel.com/stations",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1345159, 37.8373759] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "398 Rheem Blvd, Moraga, CA 94556, USA",
      name: "Moraga Star",
      rating: "3.9",
      formatted_phone_number: "(925) 377-1700",
      opening_hours: "missing",
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1247109, 37.859816599999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 Canyon Rd, Moraga, CA 94556, USA",
      name: "Moraga Service Center",
      rating: "4.3",
      formatted_phone_number: "(925) 376-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.mscsmog.com/",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1279975, 37.8338471] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "455 Center St, Moraga, CA 94556, USA",
      name: "Rheem Valley Automotive",
      rating: "4.4",
      formatted_phone_number: "(925) 377-6020",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.rheemauto.com/",
      first_type: "point_of_interest",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1249207, 37.8592597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1455 Moraga Way, Moraga, CA 94556, USA",
      name: "Chevron Moraga",
      rating: "4.5",
      formatted_phone_number: "(925) 376-3991",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1455-Moraga-Way-Moraga-CA-94556-id98791",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1275908, 37.8344449] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "530 Moraga Rd, Moraga, CA 94556, USA",
      name: "Moraga Motors",
      rating: "4.9",
      formatted_phone_number: "(925) 376-0692",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://moragamotors.com/",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124867, 37.856942] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1455 Moraga Way, Moraga, CA 94556, USA",
      name: "Lamorinda Auto Care",
      rating: "4.3",
      formatted_phone_number: "(925) 631-9644",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1275908, 37.8344449] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1410 Moraga Rd, Moraga, CA 94556, USA",
      name: "Nick's Auto Services",
      rating: "4.7",
      formatted_phone_number: "(925) 377-6425",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1262761, 37.8372198] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 Canyon Rd ste a, Moraga, CA 94556, USA",
      name: "E'S AUTO SERVICE AND REPAIR",
      rating: "4.6",
      formatted_phone_number: "(925) 794-6000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1279723, 37.8338212] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Moraga Way, Moraga, CA 94556, USA",
      name: "Moraga Smog",
      rating: "4.8",
      formatted_phone_number: "(925) 377-7664",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://www.moragasmog.com/",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1343971, 37.8373317] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Moraga Way, Moraga, CA 94556, USA",
      name: "Moraga Auto Clinic",
      rating: "5",
      formatted_phone_number: "(925) 235-4884",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1345159, 37.8373759] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "383 Rheem Blvd, Moraga, CA 94556, USA",
      name: "Autohaus Moraga",
      rating: "4.8",
      formatted_phone_number: "(925) 376-4430",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1250317, 37.860651] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "425 Moraga Rd, Moraga, CA 94556, USA",
      name: "Arco",
      rating: "3.2",
      formatted_phone_number: "(925) 376-9910",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1235193, 37.8590355] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Moraga Way, Moraga, CA 94556, USA",
      name: "Hand car wash and details",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://hand-car-wash-and-details.business.site/",
      first_type: "car_wash",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1343971, 37.8373317] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA, USA",
      name: "Mountain View Station",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.076816, 37.3947805] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Downtown Mountain View",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.vta.org/go/routes/orange-line",
      first_type: "light_rail_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0773143, 37.3951602] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "Mountain View Transit Center",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0771066, 37.3946194] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 W Evelyn Ave, Mountain View, CA 94041, USA",
      name: "Mountain View",
      rating: "4.3",
      formatted_phone_number: "(800) 660-4287",
      opening_hours: "missing",
      website: "http://www.caltrain.com/stations/mountainviewstation.html",
      first_type: "train_station",
      zip_code: "94041",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0760376, 37.394552299999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Whisman",
      rating: "4.5",
      formatted_phone_number: "(408) 321-2300",
      opening_hours: "missing",
      website: "missing",
      first_type: "light_rail_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0582346, 37.3922076] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Yuba",
      rating: "3.4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "http://www.vta.org/",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0714718, 37.3795388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "MIDDLEFIELD and SAN PIERRE",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0812095, 37.407739] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "MIDDLEFIELD and SAN VERON",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0750518, 37.4036903] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "SHORELINE and WRIGHT",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0803378, 37.4003917] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "MONTECITO and BURGOYNE",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0862158, 37.4038494] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94043, USA",
      name: "MOFFETT and MIDDLEFIELD",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0728096, 37.4005326] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Moffett & Cypress Point",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.074942, 37.3989827] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "Villa & Shoreline",
      rating: "1",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94041",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0832698, 37.395598199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "Villa & Mariposa",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0877684, 37.3972881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "Villa/Franklin",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.081013, 37.3946154] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Middlefield and Rengstorff",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0928618, 37.4115838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Rengstorff/Montecito",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0953406, 37.4067062] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "Senior/Teen Center",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.093049, 37.3985371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "CALIFORNIA and PALO ALTO",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0871242, 37.3939005] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "CALIFORNIA and SHORELINE",
      rating: "1",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0849233, 37.3930318] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "Mountain View Transit Center",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0771066, 37.3946194] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Yuba",
      rating: "3.4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "http://www.vta.org/",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0714718, 37.3795388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Rengstorff/Middlefield",
      rating: "2.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.092906, 37.4111681] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Whisman Station",
      rating: "1",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.061904, 37.392074] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Whisman Station",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.061657, 37.391914] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & El Monte",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0952187, 37.3915223] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Castro",
      rating: "3.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0844846, 37.3862065] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Castro",
      rating: "1",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0830556, 37.3850932] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "Castro & El Camino",
      rating: "4.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0844383, 37.3851831] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Rengstorff",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.10276, 37.3963007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Mountain View-Alviso",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0735353, 37.3807717] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & Showers",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1104735, 37.399738] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Los Altos, CA 94022, USA",
      name: "El Camino & Showers",
      rating: "3.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1103897, 37.399323] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Los Altos, CA 94022, USA",
      name: "El Camino & San Antonio",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1133541, 37.4009406] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94040, USA",
      name: "El Camino & San Antonio",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1144992, 37.4019093] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94041, USA",
      name: "El Camino & Sylvan",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.06216, 37.3763] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA 94086, USA",
      name: "El Camino & Mary",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0489227, 37.3721155] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "807 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(650) 969-2024",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008062-807-north-shoreline-blvd/en_US",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0777285, 37.4065531] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "495 Moffett Blvd, Mountain View, CA 94043, USA",
      name: "Valero",
      rating: "3.7",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 9:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "http://valero.com/",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0729973, 37.3999382] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "310 N Whisman Rd, Mountain View, CA 94043, USA",
      name: "ATM (Rotten Robbie)",
      rating: "3.2",
      formatted_phone_number: "(650) 965-2609",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      website: "https://www.rottenrobbie.com/locations/",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0599226, 37.397496499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "808 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "Chevron",
      rating: "3.1",
      formatted_phone_number: "(650) 965-8506",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0786426, 37.406932] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "310 N Whisman Rd, Mountain View, CA 94043, USA",
      name: "Rotten Robbie",
      rating: "4",
      formatted_phone_number: "(650) 965-2609",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      website: "http://www.rottenrobbie.com/",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0598977, 37.397489] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "110 N Rengstorff Ave, Mountain View, CA 94043, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(650) 964-8529",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008066-110-n-rengstorff-ave/en_US",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0976204, 37.4036383] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "840 San Antonio Rd, Palo Alto, CA 94303, USA",
      name: "ARCO",
      rating: "3.5",
      formatted_phone_number: "(650) 494-9894",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1014157, 37.4215279] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "988 N San Antonio Rd, Los Altos, CA 94022, USA",
      name: "ARCO",
      rating: "3.4",
      formatted_phone_number: "(650) 947-9172",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1145725, 37.3998509] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 E El Camino Real, Mountain View, CA 94040, USA",
      name: "Loop Neighborhood Market",
      rating: "3.8",
      formatted_phone_number: "(650) 968-2009",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/americana-shell/?utm_source=GBP&utm_campaign=790%20East%20El%20Camino%20Real&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0639483, 37.3771468] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1708 Miramonte Ave, Mountain View, CA 94040, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(650) 965-9772",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008063-1708-miramonte-ave/en_US",
      first_type: "gas_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.087403, 37.373138] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "996 W Evelyn Ave, Sunnyvale, CA 94086, USA",
      name: "Valero",
      rating: "3.7",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0443806, 37.3827128] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 El Camino Real, Los Altos, CA 94022, USA",
      name: "Los altos gas",
      rating: "3.9",
      formatted_phone_number: "(650) 941-0244",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.118222, 37.404117] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "334 San Antonio Rd, Mountain View, CA 94040, USA",
      name: "Valero",
      rating: "4.1",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 11:00 PM",
          "Tuesday: 5:30 AM – 11:00 PM",
          "Wednesday: 5:30 AM – 11:00 PM",
          "Thursday: 5:30 AM – 11:00 PM",
          "Friday: 5:30 AM – 11:00 PM",
          "Saturday: 5:30 AM – 11:00 PM",
          "Sunday: 5:30 AM – 11:00 PM",
        ],
      },
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94040",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1115113, 37.405768099999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "499 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      name: "Alliance",
      rating: "4.2",
      formatted_phone_number: "(408) 735-7181",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0310074, 37.3894515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 E El Camino Real, Mountain View, CA 94040, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(650) 964-2013",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008064-790-e-el-camino-real/en_US",
      first_type: "gas_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.063631, 37.3770128] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1288 W El Camino Real, Mountain View, CA 94040, USA",
      name: "Shell",
      rating: "3.6",
      formatted_phone_number: "(650) 964-0603",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008065-1288-w-el-camino-real/en_US",
      first_type: "gas_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0887846, 37.3884962] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3972 El Camino Real, Palo Alto, CA 94306, USA",
      name: "Valero",
      rating: "3.6",
      formatted_phone_number: "(866) 297-6093",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1293287, 37.4160262] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "401 Main St, Los Altos, CA 94022, USA",
      name: "Chevron",
      rating: "3.6",
      formatted_phone_number: "(650) 948-9837",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      website: "https://www.chevronwithtechron.com/",
      first_type: "gas_station",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1178666, 37.377423] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "ARCO",
      rating: "3.7",
      formatted_phone_number: "(650) 424-8952",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0783838, 37.405848600000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "996 W Evelyn Ave, Sunnyvale, CA 94086, USA",
      name: "George's Fuel & Auto Repair",
      rating: "4.8",
      formatted_phone_number: "(408) 733-2221",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0444582, 37.382562] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "690 San Antonio Rd, Palo Alto, CA 94306, USA",
      name: "Magnussen's Toyota of Palo Alto",
      rating: "4.3",
      formatted_phone_number: "(650) 494-2200",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website: "https://www.toyotapaloalto.com/",
      first_type: "car_repair",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.102217, 37.41491] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "150 E El Camino Real, Mountain View, CA 94040, USA",
      name: "BMW of Mountain View",
      rating: "4.4",
      formatted_phone_number: "(650) 200-0149",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      website:
        "https://www.bmwofmountainview.com/?utm_source=local&utm_medium=organic&utm_campaign=*000012247*GMB",
      first_type: "car_repair",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0715201, 37.3806247] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "32 W El Camino Real, Mountain View, CA 94040, USA",
      name: "America's Tire",
      rating: "4.4",
      formatted_phone_number: "(650) 988-9611",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://www.americastire.com/store/ca/mountain-view/s/1063",
      first_type: "car_repair",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0734414, 37.3810387] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2423 Old Middlefield Way STE G, Mountain View, CA 94043, USA",
      name: "Level Up Auto Styling / Griffin’s Auto Repair",
      rating: "4.8",
      formatted_phone_number: "(650) 961-8657",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 4:30 PM",
          "Tuesday: 8:30 AM – 4:30 PM",
          "Wednesday: 8:30 AM – 4:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 8:30 AM – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.levelupautostyling.com/",
      first_type: "car_repair",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0981458, 37.4142831] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "177 E Evelyn Ave, Mountain View, CA 94041, USA",
      name: "Caliber Collision",
      rating: "4.6",
      formatted_phone_number: "(650) 965-1440",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.caliber.com/find-a-location/mountain-view?utm_source=google&utm_medium=gbp-profile&utm_campaign=collision",
      first_type: "car_repair",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0639081, 37.3890352] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1297 W El Camino Real # A, Sunnyvale, CA 94087, USA",
      name: "Ace Tire and Wheel",
      rating: "4.7",
      formatted_phone_number: "(650) 567-9200",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1530" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: 9:00 AM – 5:30 PM",
          "Sunday: 9:00 AM – 3:30 PM",
        ],
      },
      website: "http://www.acetireshop.com/",
      first_type: "car_repair",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0600981, 37.3757762] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "81 Pioneer Way, Mountain View, CA 94041, USA",
      name: "Yarnell Service Center",
      rating: "4.8",
      formatted_phone_number: "(650) 969-9018",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://yarnellsservicecenter.com/",
      first_type: "car_repair",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.065936, 37.3894993] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1968 Leghorn St, Mountain View, CA 94043, USA",
      name: "Kevin's Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(650) 965-7112",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://kevinsautorepairinc.com/",
      first_type: "point_of_interest",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.091625, 37.419161] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "170 E El Camino Real, Mountain View, CA 94040, USA",
      name: "O'Reilly Auto Parts",
      rating: "4.2",
      formatted_phone_number: "(650) 967-2584",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 9:00 PM",
          "Tuesday: 7:30 AM – 9:00 PM",
          "Wednesday: 7:30 AM – 9:00 PM",
          "Thursday: 7:30 AM – 9:00 PM",
          "Friday: 7:30 AM – 9:00 PM",
          "Saturday: 7:30 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      website:
        "https://locations.oreillyauto.com/ca/mountainview/autoparts-2591.html",
      first_type: "car_repair",
      zip_code: "94040",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.070571, 37.379889199999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1932 Old Middlefield Way, Mountain View, CA 94043, USA",
      name: "Mountain View Body Shop",
      rating: "4.4",
      formatted_phone_number: "(650) 399-0775",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:30 PM",
          "Tuesday: 8:00 AM – 5:30 PM",
          "Wednesday: 8:00 AM – 5:30 PM",
          "Thursday: 8:00 AM – 5:30 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.08885, 37.4147171] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1905 Old Middlefield Way, Mountain View, CA 94043, USA",
      name: "Mountain View Radiator",
      rating: "4.4",
      formatted_phone_number: "(650) 968-9146",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:30 PM",
          "Tuesday: 8:00 AM – 6:30 PM",
          "Wednesday: 8:00 AM – 6:30 PM",
          "Thursday: 8:00 AM – 6:30 PM",
          "Friday: 8:00 AM – 6:30 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://mountainviewradiator.com/",
      first_type: "point_of_interest",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0872007, 37.4136187] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "15 Pioneer Way, Mountain View, CA 94041, USA",
      name: "Sunnyvale Foreign Car Service Inc",
      rating: "4.8",
      formatted_phone_number: "(650) 960-6988",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.sunnyvaleforeigncar.com/",
      first_type: "car_repair",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0654559, 37.3898609] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "808 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "Chevron",
      rating: "3.1",
      formatted_phone_number: "(650) 965-8506",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0786426, 37.406932] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "762 San Antonio Rd, Palo Alto, CA 94303, USA",
      name: "Hengehold Truck Sales + Rentals",
      rating: "4.4",
      formatted_phone_number: "(650) 494-2444",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.htrucks.com/",
      first_type: "car_repair",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.101694, 37.41861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2520 Wyandotte St # G, Mountain View, CA 94043, USA",
      name: "Helming's Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(650) 988-0460",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:00 PM",
          "Tuesday: 7:30 AM – 5:00 PM",
          "Wednesday: 7:30 AM – 5:00 PM",
          "Thursday: 7:30 AM – 5:00 PM",
          "Friday: 7:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.helmings.com/",
      first_type: "car_repair",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0994789, 37.4179764] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1026 W Evelyn Ave #A, Sunnyvale, CA 94086, USA",
      name: "European Car Repair-DriveNow",
      rating: "4.5",
      formatted_phone_number: "(650) 630-5111",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://www.europeancarrepairs.com/",
      first_type: "point_of_interest",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0460806, 37.3830966] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1900 Old Middlefield Way Suite f, Mountain View, CA 94043, USA",
      name: "All Automotive",
      rating: "4.7",
      formatted_phone_number: "(650) 969-1300",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:30 PM",
          "Tuesday: 8:00 AM – 4:30 PM",
          "Wednesday: 8:00 AM – 4:30 PM",
          "Thursday: 8:00 AM – 4:30 PM",
          "Friday: 8:00 AM – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://facebook.com/allautomotivemtnview",
      first_type: "car_repair",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0871027, 37.414723599999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2037 Old Middlefield Way, Mountain View, CA 94043, USA",
      name: "Dean's Automotive",
      rating: "4.8",
      formatted_phone_number: "(650) 961-0302",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.deansautomotive.com/",
      first_type: "point_of_interest",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0915222, 37.4142112] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1141 W El Camino Real, Mountain View, CA 94040, USA",
      name: "Jiffy Lube",
      rating: "4.2",
      formatted_phone_number: "(650) 965-2582",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://www.jiffylube.com/locations/ca/mountain-view/2342",
      first_type: "car_repair",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0879975, 37.3873453] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1240 W El Camino Real, Sunnyvale, CA 94087, USA",
      name: "Quik Smog - STAR Station",
      rating: "4.4",
      formatted_phone_number: "(408) 773-1878",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://www.smogtestsunnyvale.com/",
      first_type: "car_repair",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0584269, 37.3743068] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "495 Moffett Blvd, Mountain View, CA 94043, USA",
      name: "Valero",
      rating: "3.7",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 9:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "http://valero.com/",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0729973, 37.3999382] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "808 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "Chevron",
      rating: "3.1",
      formatted_phone_number: "(650) 965-8506",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0786426, 37.406932] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "110 N Rengstorff Ave, Mountain View, CA 94043, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(650) 964-8529",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008066-110-n-rengstorff-ave/en_US",
      first_type: "gas_station",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0976204, 37.4036383] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2080 W El Camino Real, Mountain View, CA 94040, USA",
      name: "Mountain View Auto Detail",
      rating: "4.7",
      formatted_phone_number: "(650) 557-8351",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: 8:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1008055, 37.395633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "610 Grape Ave, Sunnyvale, CA 94087, USA",
      name: "Lozano Car Wash",
      rating: "3.6",
      formatted_phone_number: "(408) 245-7737",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 4:30 PM",
          "Tuesday: 8:30 AM – 4:30 PM",
          "Wednesday: 8:30 AM – 4:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 8:30 AM – 4:30 PM",
          "Saturday: 8:30 AM – 4:30 PM",
          "Sunday: 8:30 AM – 4:30 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0524606, 37.3723407] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 E El Camino Real, Mountain View, CA 94040, USA",
      name: "Loop Neighborhood Market",
      rating: "3.8",
      formatted_phone_number: "(650) 968-2009",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.loopneighborhood.com/location/americana-shell/?utm_source=GBP&utm_campaign=790%20East%20El%20Camino%20Real&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0639483, 37.3771468] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2080 W El Camino Real, Mountain View, CA 94040, USA",
      name: "Thrifty Car Wash",
      rating: "3.7",
      formatted_phone_number: "(408) 761-3852",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://familythriftycw.com/",
      first_type: "car_wash",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1007405, 37.3957894] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1905 Old Middlefield Way suite c, Mountain View, CA 94043, USA",
      name: "Perfection Auto Detail",
      rating: "3.9",
      formatted_phone_number: "(650) 968-7555",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://perfectionautodetail.com/",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0872266, 37.4136881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1005 W El Camino Real, Sunnyvale, CA 94087, USA",
      name: "Sunnyvale Auto Spa",
      rating: "3.1",
      formatted_phone_number: "(408) 720-0224",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 7:00 PM",
          "Sunday: 8:00 AM – 7:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0487724, 37.3726057] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "790 E El Camino Real, Mountain View, CA 94040, USA",
      name: "Shell",
      rating: "3.7",
      formatted_phone_number: "(650) 964-2013",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008064-790-e-el-camino-real/en_US",
      first_type: "gas_station",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.063631, 37.3770128] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "975 Bay St, Mountain View, CA 94040, USA",
      name: "Family Thrifty Car Wash",
      rating: "3.7",
      formatted_phone_number: "(408) 761-3852",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.familythriftycw.com/",
      first_type: "car_wash",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0748466, 37.3805009] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1005 W El Camino Real, Sunnyvale, CA 94087, USA",
      name: "Chevron Sunnyvale",
      rating: "2.1",
      formatted_phone_number: "(408) 720-0224",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0488461, 37.3722448] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA 94043, USA",
      name: "Car Wash",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0775291, 37.406429] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "466 N Shoreline Blvd, Mountain View, CA 94043, USA",
      name: "Clear Water Car Wash",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0787426, 37.4013005] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "466 Stierlin Rd, Mountain View, CA 94043, USA",
      name: "Self car wash Mountain View",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0786677, 37.4012869] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "466 Stierlin Rd, Mountain View, CA 94043, USA",
      name: "Stierlin Auto Wash",
      rating: "4.4",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0786677, 37.4012869] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "495 Moffett Blvd, Mountain View, CA 94043, USA",
      name: "Car Wash",
      rating: "3.6",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0730551, 37.3999269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2378 Old Middlefield Way, Mountain View, CA 94043, USA",
      name: "SV Express Car Wash",
      rating: "3.9",
      formatted_phone_number: "(650) 669-8080",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0970433, 37.4150812] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "622 El Ranchito Way, Mountain View, CA 94041, USA",
      name: "Super Car Wash Detailing",
      rating: "4.6",
      formatted_phone_number: "(650) 439-5498",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:30 PM",
          "Tuesday: 8:00 AM – 5:30 PM",
          "Wednesday: 8:00 AM – 5:30 PM",
          "Thursday: 8:00 AM – 5:30 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: 8:00 AM – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://supercarwashdetailing.com/v2/#book",
      first_type: "car_wash",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.073244, 37.3829881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2690 W El Camino Real, Mountain View, CA 94040, USA",
      name: "Kaady Car Washes",
      rating: "4.6",
      formatted_phone_number: "(650) 668-6844",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website: "https://www.kaady.com/",
      first_type: "car_wash",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1162551, 37.4035343] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11 Camino Pablo, Orinda, CA 94563, USA",
      name: "Orinda",
      rating: "4.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/orin",
      first_type: "train_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1836847, 37.8784774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2160 Shattuck Ave., Berkeley, CA 94704, USA",
      name: "Downtown Berkeley",
      rating: "3.9",
      formatted_phone_number: "(510) 465-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dbrk",
      first_type: "subway_station",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2681007, 37.8701083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5660 College Ave, Oakland, CA 94618, USA",
      name: "Rockridge",
      rating: "4.3",
      formatted_phone_number: "(510) 464-6000",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/rock",
      first_type: "subway_station",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.251235, 37.844755] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3601 Deer Hill Rd, Lafayette, CA 94549, USA",
      name: "Lafayette",
      rating: "4.1",
      formatted_phone_number: "(925) 676-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/lafy",
      first_type: "transit_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.124679, 37.893179] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way/Orchard Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.177369, 37.871074] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Oakland, CA 94705, USA",
      name: "UC Botanical Garden",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.23866, 37.875445] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way/Ivy Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1526385, 37.854485499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orindawoods Dr And Woodhall-Orindawoods Clubhouse",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.18005, 37.889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Miner Rd And Camino Lenada",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.19411, 37.8905] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orindawoods Dr And Ironbark Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.17777, 37.89014] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orindawoods Dr And Village Gate Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.18242, 37.8853] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Camino Pablo And Miner Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.19432, 37.88845] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Miner Rd And Camino Don Miguel",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.19207, 37.897] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orinda Way And Avenida De Orinda",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.191794, 37.88538] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orinda Way And Irwin Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.190005, 37.884215] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orindawoods Dr And Ironbark Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.17552, 37.89303] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Altarinda Rd And Orinda Masonic Center",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1846, 37.88249] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orinda Way And Orinda Community Center",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.187899, 37.882383] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orinda Way And Orinda Library",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1867789, 37.8815613] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Miner Rd And Camino Sobrante",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1909, 37.90078] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Orinda BART",
      rating: "4.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.184319, 37.87796] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Los Cerros",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.165547, 37.860433] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Holy Shepherd Lutheran Church",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.165209, 37.86041] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Woodland Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.155215, 37.85619] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Southwaite Ct",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.151977, 37.851603] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA 94549, USA",
      name: "Lafayette BART",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.125128, 37.893571] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And El Camino Moraga",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.152514, 37.847836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Via Granada Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123982, 37.872378] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA 94549, USA",
      name: "Mt Diablo Blvd And Moraga Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.119492, 37.891888] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Via Granada Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.123824, 37.872361] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA 94549, USA",
      name: "Mt Diablo Blvd And Moraga Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.119254, 37.892088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA 94549, USA",
      name: "Moraga Rd And Mt Diablo Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.118729, 37.89112] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA 94549, USA",
      name: "Moraga Rd And Silver Springs Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.11981, 37.881788] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Campolindo Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125274, 37.868015] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Buckingham Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12426, 37.864716] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Buckingham Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124082, 37.864931] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Berkeley, CA 94720, USA",
      name: "Piedmont Av & Bancroft Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2524102, 37.8697676] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Rheem Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125075, 37.861076] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Orinda, CA 94563, USA",
      name: "Moraga Way And Eastwood Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.146275, 37.84341] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      name: "Moraga Rd And Kendall Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.124222, 37.858045] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11 Camino Pablo, Orinda, CA 94563, USA",
      name: "Orinda",
      rating: "4.3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/orin",
      first_type: "train_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1836847, 37.8784774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2160 Shattuck Ave., Berkeley, CA 94704, USA",
      name: "Downtown Berkeley",
      rating: "3.9",
      formatted_phone_number: "(510) 465-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dbrk",
      first_type: "subway_station",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2681007, 37.8701083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5660 College Ave, Oakland, CA 94618, USA",
      name: "Rockridge",
      rating: "4.3",
      formatted_phone_number: "(510) 464-6000",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/rock",
      first_type: "subway_station",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.251235, 37.844755] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3601 Deer Hill Rd, Lafayette, CA 94549, USA",
      name: "Lafayette",
      rating: "4.1",
      formatted_phone_number: "(925) 676-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/lafy",
      first_type: "transit_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.124679, 37.893179] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "67 Moraga Way, Orinda, CA 94563, USA",
      name: "76",
      rating: "4",
      formatted_phone_number: "(925) 253-8594",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-76-orinda-0000881832/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1807025, 37.878355] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "76",
      rating: "3.7",
      formatted_phone_number: "(925) 962-0760",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.76.com/station/u76-mt-diablo-76-0000848427/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1168473, 37.8925247] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "425 Moraga Rd, Moraga, CA 94556, USA",
      name: "Arco",
      rating: "3.2",
      formatted_phone_number: "(925) 376-9910",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1235193, 37.8590355] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5865 Broadway Terrace, Oakland, CA 94618, USA",
      name: "Valero",
      rating: "3.1",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2373022, 37.8407718] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5725 Thornhill Dr, Oakland, CA 94611, USA",
      name: "Montclair Gas & Autocare",
      rating: "4.3",
      formatted_phone_number: "(510) 338-0999",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2127397, 37.8344488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6550 Moraga Ave, Oakland, CA 94611, USA",
      name: "Chevron",
      rating: "3.1",
      formatted_phone_number: "(510) 339-1064",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/en_us/home/find-a-station.html?/station/6550-MORAGA-AVE-OAKLAND-CA-94611-id91740",
      first_type: "gas_station",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2097316, 37.8255755] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9 Orinda Way, Orinda, CA 94563, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 254-5264",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008111-9-orinda-way/en_US",
      first_type: "gas_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.186239, 37.880589] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3255 Stanley Blvd, Lafayette, CA 94549, USA",
      name: "Shell",
      rating: "2.9",
      formatted_phone_number: "(925) 280-0444",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007960-3255-stanley-blvd/en_US",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.0974875, 37.9022028] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11 Orinda Way, Orinda, CA 94563, USA",
      name: "Chevron",
      rating: "3.9",
      formatted_phone_number: "(925) 254-6860",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1866445, 37.8809733] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6407 Telegraph Ave, Oakland, CA 94609, USA",
      name: "Arco",
      rating: "3.7",
      formatted_phone_number: "(510) 658-7508",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "94609",
    },
    geometry: { type: "Point", coordinates: [-122.260742, 37.8504078] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3048 Ashby Ave, Berkeley, CA 94705, USA",
      name: "Chevron",
      rating: "4.1",
      formatted_phone_number: "(510) 841-3939",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 10:00 PM",
          "Tuesday: 6:30 AM – 10:00 PM",
          "Wednesday: 6:30 AM – 10:00 PM",
          "Thursday: 6:30 AM – 10:00 PM",
          "Friday: 6:30 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2441005, 37.8579106] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3356 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Shell",
      rating: "2.6",
      formatted_phone_number: "(925) 283-6626",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007959-3356-mt-diablo-blvd/en_US",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1061356, 37.8951979] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "22 Bryant Way, Orinda, CA 94563, USA",
      name: "Mash Gas & Food",
      rating: "4.1",
      formatted_phone_number: "(925) 258-9788",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2230" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2230" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2230" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2230" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2230" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:30 PM",
          "Tuesday: 5:00 AM – 10:30 PM",
          "Wednesday: 5:00 AM – 10:30 PM",
          "Thursday: 5:00 AM – 10:30 PM",
          "Friday: 5:00 AM – 10:30 PM",
          "Saturday: 5:00 AM – 10:30 PM",
          "Sunday: 6:00 AM – 9:30 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1819068, 37.8797665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3669 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Gawfco Enterprises, Inc.",
      rating: "4.6",
      formatted_phone_number: "(925) 979-0560",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://gawfco.com/",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1285854, 37.8902864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3669 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "California Fuel Supply",
      rating: "1",
      formatted_phone_number: "(925) 961-7518",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://cafuelsupply.com/",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1285854, 37.8902864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3632 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Chevron Lafayette",
      rating: "3.6",
      formatted_phone_number: "(925) 284-1452",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/en_us/home/find-a-station.html",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1260768, 37.8914049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3523 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Lafayette Chevron",
      rating: "missing",
      formatted_phone_number: "(925) 299-0545",
      opening_hours: "missing",
      website:
        "https://www.chevronwithtechron.com/en_us/home/gas-station-near-me.html?/station/3523-MT-DIABLO-BLVD-LAFAYETTE-CA-945493831-id388076",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1175821, 37.8918845] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3523 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Lafayette 76 Repair Shop",
      rating: "3",
      formatted_phone_number: "(925) 284-1069",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1175821, 37.8918845] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Parking lot, 3500 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Gawfco Enterprises Inc",
      rating: "5",
      formatted_phone_number: "(925) 962-0760",
      opening_hours: "missing",
      website: "https://gawfco.com/",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1168722, 37.8925474] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2996 Telegraph Ave, Berkeley, CA 94705, USA",
      name: "Chevron Berkeley",
      rating: "2",
      formatted_phone_number: "(510) 666-0802",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.260018, 37.8567524] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "63 Orinda Way, Orinda, CA 94563, USA",
      name: "Orinda Motors, Inc.",
      rating: "4.7",
      formatted_phone_number: "(925) 254-2012",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "1330" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "1330" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "1330" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "1330" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "1330" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 12:30 PM, 1:30 – 4:30 PM",
          "Tuesday: 7:30 AM – 12:30 PM, 1:30 – 4:30 PM",
          "Wednesday: 7:30 AM – 12:30 PM, 1:30 – 4:30 PM",
          "Thursday: 7:30 AM – 12:30 PM, 1:30 – 4:30 PM",
          "Friday: 7:30 AM – 12:30 PM, 1:30 – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.orindamotors.com/",
      first_type: "car_rental",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1914367, 37.8847808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Shattuck Ave., Berkeley, CA 94704, USA",
      name: "Toyota of Berkeley",
      rating: "3.9",
      formatted_phone_number: "(510) 845-2530",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 7:00 PM",
          "Tuesday: 7:30 AM – 7:00 PM",
          "Wednesday: 7:30 AM – 7:00 PM",
          "Thursday: 7:30 AM – 7:00 PM",
          "Friday: 7:30 AM – 7:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      website:
        "http://www.toyotaofberkeley.com/?utm_source=GMB%20listing&utm_medium=organic&utm_campaign=GMB%20organic",
      first_type: "car_repair",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.267856, 37.865656] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3434 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Crash Champions Collision Repair Lafayette",
      rating: "4",
      formatted_phone_number: "(925) 455-1739",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://crashchampions.com/locations/crash-champions-lafayette",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1120305, 37.8933049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2734 Telegraph Ave, Berkeley, CA 94705, USA",
      name: "Berkeley Auto Care",
      rating: "4.5",
      formatted_phone_number: "(510) 540-0690",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2596062, 37.8601024] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5930 College Ave, Oakland, CA 94618, USA",
      name: "Sheaff's Service Garage",
      rating: "4.5",
      formatted_phone_number: "(510) 658-3070",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: 8:30 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://sheaffs.com/",
      first_type: "car_repair",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2518736, 37.8477812] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3328 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Big O Tires",
      rating: "4.4",
      formatted_phone_number: "(925) 283-2258",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:30 PM",
          "Tuesday: 8:00 AM – 5:30 PM",
          "Wednesday: 8:00 AM – 5:30 PM",
          "Thursday: 8:00 AM – 5:30 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.bigotires.com/location/ca/lafayette/3328-mt-diablo-blvd-94549/005321?utm_source=google&utm_medium=maps&utm_campaign=google+maps&y_source=1_ODY2OTU1NC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1038291, 37.896090199999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2935 Claremont Ave, Berkeley, CA 94705, USA",
      name: "Caliber Collision",
      rating: "4.7",
      formatted_phone_number: "(510) 848-2702",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.caliber.com/find-a-location/berkeley?utm_source=google&utm_medium=gbp-profile&utm_campaign=collision",
      first_type: "car_repair",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2452163, 37.8579177] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "455 Center St, Moraga, CA 94556, USA",
      name: "Rheem Valley Automotive",
      rating: "4.4",
      formatted_phone_number: "(925) 377-6020",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.rheemauto.com/",
      first_type: "point_of_interest",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1249207, 37.8592597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3344 Mount Diablo Blvd STE D, Lafayette, CA 94549, USA",
      name: "California Star",
      rating: "5",
      formatted_phone_number: "(925) 284-2367",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.californiastar.net/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1057887, 37.895719500000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Max Auto Center LLC.",
      rating: "5",
      formatted_phone_number: "(925) 451-1835",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.116733, 37.8927076] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3470 Golden Gate Way, Lafayette, CA 94549, USA",
      name: "Lafayette Motors",
      rating: "5",
      formatted_phone_number: "(925) 284-4852",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1141596, 37.8919484] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3430 Mount Diablo Blvd B, Lafayette, CA 94549, USA",
      name: "Ace Auto Care",
      rating: "4.9",
      formatted_phone_number: "(925) 284-5550",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://aceautocare.net/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.111623, 37.8934072] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3360 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Toyota Independent Services",
      rating: "missing",
      formatted_phone_number: "(925) 299-1434",
      opening_hours: "missing",
      website: "http://www.theserviceoutlet.com/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.106574, 37.895141] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3363 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Jiffy Lube",
      rating: "3.8",
      formatted_phone_number: "(925) 284-2288",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://www.jiffylube.com/locations/ca/lafayette/2321",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1067153, 37.8945011] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3360 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "The Service Outlet",
      rating: "4.8",
      formatted_phone_number: "(925) 283-3133",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:30 PM",
          "Tuesday: 8:00 AM – 5:30 PM",
          "Wednesday: 8:00 AM – 5:30 PM",
          "Thursday: 8:00 AM – 5:30 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://theserviceoutlet.com/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1065151, 37.8950581] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3356 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "CarTech",
      rating: "4.8",
      formatted_phone_number: "(925) 385-7116",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: 8:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.cartechrepair.com/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1061356, 37.8951979] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "798 Rancho La Boca Rd, Briones, CA 94553, USA",
      name: "DG Cycle Services",
      rating: "4.9",
      formatted_phone_number: "(925) 335-6401",
      opening_hours: "missing",
      website: "http://dgcycleservices.com/",
      first_type: "point_of_interest",
      zip_code: "94553",
    },
    geometry: { type: "Point", coordinates: [-122.1650193, 37.9538433] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5725 Thornhill Dr, Oakland, CA 94611, USA",
      name: "Montclair Gas & Autocare",
      rating: "4.3",
      formatted_phone_number: "(510) 338-0999",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2127397, 37.8344488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6550 Moraga Ave, Oakland, CA 94611, USA",
      name: "Chevron",
      rating: "3.1",
      formatted_phone_number: "(510) 339-1064",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/en_us/home/find-a-station.html?/station/6550-MORAGA-AVE-OAKLAND-CA-94611-id91740",
      first_type: "gas_station",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2097316, 37.8255755] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "14 Juniper Dr, Lafayette, CA 94549, USA",
      name: "I-O Port Racing Supplies",
      rating: "4.9",
      formatted_phone_number: "(925) 254-7223",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.ioportracing.com/",
      first_type: "car_repair",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1655064, 37.8918665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "425 Moraga Rd, Moraga, CA 94556, USA",
      name: "Arco",
      rating: "3.2",
      formatted_phone_number: "(925) 376-9910",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1235193, 37.8590355] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9 Orinda Way, Orinda, CA 94563, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 254-5264",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008111-9-orinda-way/en_US",
      first_type: "gas_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.186239, 37.880589] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3319 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Lafayette Car Wash And Detail Center",
      rating: "3.9",
      formatted_phone_number: "(925) 283-1190",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.lafayettecarwash.com/",
      first_type: "car_wash",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1031494, 37.8956336] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3356 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      name: "Shell",
      rating: "2.6",
      formatted_phone_number: "(925) 283-6626",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007959-3356-mt-diablo-blvd/en_US",
      first_type: "gas_station",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1061356, 37.8951979] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9 Orinda Way, Orinda, CA 94563, USA",
      name: "REBEL Wash",
      rating: "3",
      formatted_phone_number: "(925) 254-5264",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.rebelstores.com/",
      first_type: "car_wash",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1862454, 37.880611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Moraga Way, Moraga, CA 94556, USA",
      name: "Hand car wash and details",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://hand-car-wash-and-details.business.site/",
      first_type: "car_wash",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1343971, 37.8373317] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6550 Moraga Ave, Oakland, CA 94611, USA",
      name: "Ken Betts Services Center",
      rating: "3.7",
      formatted_phone_number: "(510) 339-1064",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.kenbettscompany.com/",
      first_type: "gas_station",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2096761, 37.8256527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6360 Telegraph Ave, Berkeley, CA 94705, USA",
      name: "Solar Car Wash",
      rating: "4.2",
      formatted_phone_number: "(888) 614-6664",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 7:00 PM",
          "Sunday: 8:00 AM – 7:00 PM",
        ],
      },
      website: "http://www.thesolarcarwash.com/",
      first_type: "car_wash",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2602433, 37.8496969] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "28601 Dixon St, Hayward, CA 94544, USA",
      name: "South Hayward",
      rating: "3.6",
      formatted_phone_number: "(510) 464-7134",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/shay",
      first_type: "subway_station",
      zip_code: "28601",
    },
    geometry: { type: "Point", coordinates: [-122.057189, 37.634375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 Union Square, Union City, CA 94587, USA",
      name: "Union City",
      rating: "3.9",
      formatted_phone_number: "(510) 464-7134",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/ucty",
      first_type: "subway_station",
      zip_code: "94587",
    },
    geometry: { type: "Point", coordinates: [-122.0173518, 37.5906463] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California, USA",
      name: "West Dublin/Pleasanton Station",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9282403, 37.6997562] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California, USA",
      name: "Dublin/Pleasanton BART",
      rating: "4.7",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8991813, 37.7016504] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "West Pleasanton BART Station",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.927223, 37.698053] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6501 Golden Gate Dr, Dublin, CA 94568, USA",
      name: "West Dublin / Pleasanton",
      rating: "4",
      formatted_phone_number: "(510) 441-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/wdub",
      first_type: "subway_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9283202, 37.6996967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "West Dublin BART Station",
      rating: "3.6",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.927184, 37.700702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin / Pleasanton BART Station",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dubl",
      first_type: "subway_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992171, 37.7016477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin/Pleasanton Bart",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website:
        "https://www.amtrak.com/servlet/ContentServer?pagename=am/am2Station/Station_Page&code=DBP",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8976075, 37.7028431] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Pleasanton",
      rating: "4.3",
      formatted_phone_number: "(800) 411-7245",
      opening_hours: "missing",
      website: "https://acerail.com/stations/",
      first_type: "train_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8824899, 37.6582251] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2500 Railroad Ave, Livermore, CA 94550, USA",
      name: "Livermore Transit Center",
      rating: "4.6",
      formatted_phone_number: "(925) 455-7500",
      opening_hours: "missing",
      website: "http://www.wheelsbus.com/",
      first_type: "transit_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7669212, 37.6850149] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Castro Valley, CA 94546, USA",
      name: "Castro Valley Station",
      rating: "2",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94546",
    },
    geometry: { type: "Point", coordinates: [-122.0751713, 37.6907611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3301 Norbridge Ave, Castro Valley, CA 94546, USA",
      name: "Castro Valley",
      rating: "4.5",
      formatted_phone_number: "(888) 540-0546",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94546",
    },
    geometry: { type: "Point", coordinates: [-122.07487, 37.69157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3301 Norbridge Ave, Castro Valley, CA 94546, USA",
      name: "Castro Valley",
      rating: "4.2",
      formatted_phone_number: "(510) 465-2278",
      opening_hours: "missing",
      website: "http://www.bart.gov/stations/cast",
      first_type: "subway_station",
      zip_code: "94546",
    },
    geometry: { type: "Point", coordinates: [-122.075602, 37.690746] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Stoneridge Dr & Franklin Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9093775, 37.6907695] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Hopyard Rd & Stoneridge Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9040245, 37.691941] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Las Positas Blvd & Hopyard Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9031845, 37.6820765] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Las Positas Blvd & Willow Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.898347, 37.684015] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Gibralter Dr & Willow Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8981595, 37.695579] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Las Positas Blvd & Hacienda Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.894568, 37.6850885] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "West Pleasanton BART Station",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.927223, 37.698053] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "West Dublin BART Station",
      rating: "3.6",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.927184, 37.700702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2500 Railroad Ave, Livermore, CA 94550, USA",
      name: "Livermore Transit Center",
      rating: "4.6",
      formatted_phone_number: "(925) 455-7500",
      opening_hours: "missing",
      website: "http://www.wheelsbus.com/",
      first_type: "transit_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7669212, 37.6850149] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3301 Norbridge Ave, Castro Valley, CA 94546, USA",
      name: "Castro Valley",
      rating: "4.5",
      formatted_phone_number: "(888) 540-0546",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94546",
    },
    geometry: { type: "Point", coordinates: [-122.07487, 37.69157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Dublin Pleasanton BART Unloading",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.89803, 37.701623] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Sierra Ln",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91009, 37.705973] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Pleasanton BART",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.89944, 37.702901] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "East Pleasanton BART Station",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.896382, 37.7008875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Iron Horse Trail Apts",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91017, 37.708751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Park Sierra",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910389, 37.711771] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Mariposa Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910369, 37.714711] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Camp Parks Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910086, 37.715729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Brigadoon Wy WB",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.957415, 37.698945] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Marshall Canyon Dr WB",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.975924, 37.700918] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94582, USA",
      name: "Dougherty Rd And Old Ranch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.913745, 37.739893] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Dougherty Rd And Old Ranch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.914044, 37.739956] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94582, USA",
      name: "Dougherty Rd So And Dougherty Rd No",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.914442, 37.749163] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94582, USA",
      name: "Bollinger Canyon Rd And Dougherty Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.913449, 37.74972] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "E Branch Pkwy And Windemere Pkwy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.89042, 37.749211] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Windemere Pkwy And E Branch Pkwy",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.889605, 37.749633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "28601 Dixon St, Hayward, CA 94544, USA",
      name: "South Hayward",
      rating: "3.6",
      formatted_phone_number: "(510) 464-7134",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/shay",
      first_type: "subway_station",
      zip_code: "28601",
    },
    geometry: { type: "Point", coordinates: [-122.057189, 37.634375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 Union Square, Union City, CA 94587, USA",
      name: "Union City",
      rating: "3.9",
      formatted_phone_number: "(510) 464-7134",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/ucty",
      first_type: "subway_station",
      zip_code: "94587",
    },
    geometry: { type: "Point", coordinates: [-122.0173518, 37.5906463] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6501 Golden Gate Dr, Dublin, CA 94568, USA",
      name: "West Dublin / Pleasanton",
      rating: "4",
      formatted_phone_number: "(510) 441-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/wdub",
      first_type: "subway_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9283202, 37.6996967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin / Pleasanton BART Station",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dubl",
      first_type: "subway_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992171, 37.7016477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3301 Norbridge Ave, Castro Valley, CA 94546, USA",
      name: "Castro Valley",
      rating: "4.2",
      formatted_phone_number: "(510) 465-2278",
      opening_hours: "missing",
      website: "http://www.bart.gov/stations/cast",
      first_type: "subway_station",
      zip_code: "94546",
    },
    geometry: { type: "Point", coordinates: [-122.075602, 37.690746] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7486 Brighton Ct, Dublin, CA 94568, USA",
      name: "Yellow Bayarea Cab - Airport shuttle, Taxi service in Dublin",
      rating: "5",
      formatted_phone_number: "(510) 714-3000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0230" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0230" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0230" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0230" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0230" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0230" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0230" },
          },
        ],
        weekday_text: [
          "Monday: 2:30 AM – 10:00 PM",
          "Tuesday: 2:30 AM – 10:00 PM",
          "Wednesday: 2:30 AM – 10:00 PM",
          "Thursday: 2:30 AM – 10:00 PM",
          "Friday: 2:30 AM – 10:00 PM",
          "Saturday: 2:30 AM – 10:00 PM",
          "Sunday: 2:30 AM – 10:00 PM",
        ],
      },
      website: "https://yellowbayareacab.com/",
      first_type: "taxi_stand",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9313783, 37.7183783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2478 Armstrong St, Livermore, CA 94551, USA",
      name: "DeSoto Cab Livermore",
      rating: "2.3",
      formatted_phone_number: "(925) 449-9999",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://desotocablivermore.com/",
      first_type: "taxi_stand",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.812657, 37.699912] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1175 Catalina Dr, Livermore, CA 94550, USA",
      name: "Global Petroleum Corporation",
      rating: "1",
      formatted_phone_number: "(925) 245-0100",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 4:30 AM – 11:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94550",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.7806701, 37.662583700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7100 Dougherty Rd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.5",
      formatted_phone_number: "(925) 829-6267",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9108643, 37.718834599999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "21320 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      name: "Chevron",
      rating: "4.1",
      formatted_phone_number: "(925) 829-2112",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "21320",
    },
    geometry: { type: "Point", coordinates: [-121.9420944, 37.7234242] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.2",
      formatted_phone_number: "(925) 485-3691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.87441, 37.67712] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5251 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 594-1058",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008154-5251-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9035647, 37.698359] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6400 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "4.1",
      formatted_phone_number: "(925) 828-2947",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9097699, 37.7044322] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2991 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Valero",
      rating: "3.8",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/en-us",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8963963, 37.6758988] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6401 Dublin Blvd, Dublin, CA 94568, USA",
      name: "76",
      rating: "3.6",
      formatted_phone_number: "(925) 556-6790",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91034, 37.70487] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7497 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Safeway Fuel Station",
      rating: "4.4",
      formatted_phone_number: "(925) 556-4034",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://local.fuel.safeway.com/safeway/ca/dublin/7497-dublin-blvd.html",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9264354, 37.7055061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3192 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Valero",
      rating: "4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 1:00 AM",
          "Tuesday: 5:00 AM – 1:00 AM",
          "Wednesday: 5:00 AM – 1:00 AM",
          "Thursday: 5:00 AM – 1:00 AM",
          "Friday: 5:00 AM – 1:00 AM",
          "Saturday: 5:00 AM – 1:00 AM",
          "Sunday: 5:00 AM – 1:00 AM",
        ],
      },
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8780078, 37.6915088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8998 Alcosta Blvd, San Ramon, CA 94583, USA",
      name: "76",
      rating: "3.4",
      formatted_phone_number: "(925) 828-2365",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website:
        "https://www.76.com/station/u76-cf-united-apro-llc-2704143-0000880483/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.935059, 37.7247702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.1",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.76.com/station/u76-delong-oil-inc-0000846862/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699814, 37.6634971] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "Circle K",
      rating: "1.5",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.circlek.com/store-locator/US/pleasanton/4191-1st-st/2655643?utm_source=google&utm_medium=local&utm_campaign=google-local",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8696871, 37.6636959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8999 San Ramon Rd, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 828-4820",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007871-8999-san-ramon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.941795, 37.722861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4212 First St, Pleasanton, CA 94566, USA",
      name: "Shell",
      rating: "2.8",
      formatted_phone_number: "(925) 249-9301",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008151-4212-1st-st/en_US",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699476, 37.6628282] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2760 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.9",
      formatted_phone_number: "(925) 248-2098",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8525087, 37.706023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1051 Airway Blvd, Livermore, CA 94550, USA",
      name: "Chevron",
      rating: "2.7",
      formatted_phone_number: "(925) 606-0104",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0400" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0400" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 12:00 AM",
          "Tuesday: 4:00 AM – 12:00 AM",
          "Wednesday: 4:00 AM – 12:00 AM",
          "Thursday: 4:00 AM – 12:00 AM",
          "Friday: 4:00 AM – 12:00 AM",
          "Saturday: 4:00 AM – 12:00 AM",
          "Sunday: 4:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8182354, 37.7034936] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11060 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      name: "Safeway Fuel Station",
      rating: "4.3",
      formatted_phone_number: "(925) 359-2001",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://local.fuel.safeway.com/safeway/ca/san-ramon/11060-bollinger-canyon-rd.html",
      first_type: "gas_station",
      zip_code: "11060",
    },
    geometry: { type: "Point", coordinates: [-121.9218948, 37.7741621] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay BMW",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8570",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaybmw.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8766058, 37.6997143] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4321 Toyota Dr, Dublin, CA 94568, USA",
      name: "Dublin Toyota",
      rating: "3.8",
      formatted_phone_number: "(925) 829-7700",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "https://www.dublintoyota.com/",
      first_type: "car_dealer",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.88067, 37.7042] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6300 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Ken Harvey's Dublin Honda",
      rating: "4.1",
      formatted_phone_number: "(925) 828-8030",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "http://www.dublinhonda.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb%20listing",
      first_type: "car_dealer",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9080111, 37.7049611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4200 John Monego Ct, Dublin, CA 94568, USA",
      name: "Dublin Chevrolet",
      rating: "4.1",
      formatted_phone_number: "(925) 452-7906",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinchevrolet.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8775431, 37.7024207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2700 Stoneridge Dr, Pleasanton, CA 94588, USA",
      name: "Stoneridge Chrysler Jeep Dodge Ram",
      rating: "3.9",
      formatted_phone_number: "(925) 248-4975",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "http://www.stoneridgecjd.com/",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8506469, 37.6992835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4355 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "Acura of Pleasanton",
      rating: "4.8",
      formatted_phone_number: "(925) 918-6996",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.acuraofpleasanton.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8758499, 37.7008586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5745 Johnson Dr, Pleasanton, CA 94588, USA",
      name: "Cycle Gear",
      rating: "4.8",
      formatted_phone_number: "(925) 251-1110",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.cyclegear.com/stores/cycle-gear-pleasanton-california-store-29",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9070756, 37.6991606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4345 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "Lexus of Pleasanton",
      rating: "4.8",
      formatted_phone_number: "(925) 918-6989",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:30 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.lexusofpleasanton.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875055, 37.7005799] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3800 Kaiser Rd, Dublin, CA 94568, USA",
      name: "Dublin Nissan",
      rating: "4.3",
      formatted_phone_number: "(925) 307-6500",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinnissan.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "car_dealer",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.860654, 37.7021079] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3724 Stanley Blvd Ste A, Pleasanton, CA 94566, USA",
      name: "NAPA Auto Parts - Pleasanton Auto Parts",
      rating: "4.3",
      formatted_phone_number: "(925) 462-6500",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: 7:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.napaonline.com/en/CA/Pleasanton/store/37280?cid=pl_DAC_jobber_PleasantonAutoParts_37280",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.863065, 37.66809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5885 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Mercedes-Benz of Pleasanton",
      rating: "4.1",
      formatted_phone_number: "(855) 822-9526",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 8:00 PM",
          "Tuesday: 7:30 AM – 8:00 PM",
          "Wednesday: 7:30 AM – 8:00 PM",
          "Thursday: 7:30 AM – 8:00 PM",
          "Friday: 7:30 AM – 8:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      website: "http://www.mbofpleasanton.com/",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9020517, 37.7004543] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay MINI Service Center",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8906",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaymini.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8765452, 37.699612] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3687 Old Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Mechanic King Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(925) 463-0650",
      opening_hours: "missing",
      website: "http://www.mechanickingauto.com/",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8799705, 37.6980194] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "31 Beta Ct STE G, San Ramon, CA 94583, USA",
      name: "San Ramon Valley Import Center",
      rating: "4.8",
      formatted_phone_number: "(925) 838-0991",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0715" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0715" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0715" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0715" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0715" },
          },
        ],
        weekday_text: [
          "Monday: 7:15 AM – 5:30 PM",
          "Tuesday: 7:15 AM – 5:30 PM",
          "Wednesday: 7:15 AM – 5:30 PM",
          "Thursday: 7:15 AM – 5:30 PM",
          "Friday: 7:15 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.valleyimportcenter.com/",
      first_type: "car_repair",
      zip_code: "94583",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9815581, 37.780570399999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4400 John Monego Ct, Pleasanton, CA 94588, USA",
      name: "Dublin Buick GMC",
      rating: "4.3",
      formatted_phone_number: "(925) 452-7908",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinbuickgmc.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8775187, 37.7047849] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6085 Scarlett Ct, Dublin, CA 94568, USA",
      name: "Dublin Volkswagen",
      rating: "4.2",
      formatted_phone_number: "(925) 829-0800",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "https://www.dublinvolkswagen.com/",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.903864, 37.702549] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25715 Mission Blvd, Hayward, CA 94544, USA",
      name: "Hayward Honda",
      rating: "4.5",
      formatted_phone_number: "(510) 582-1300",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.haywardhonda.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb%20listing",
      first_type: "car_dealer",
      zip_code: "25715",
    },
    geometry: { type: "Point", coordinates: [-122.0696818, 37.6532186] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3800B Kaiser Rd, Dublin, CA 94568, USA",
      name: "Dublin INFINITI",
      rating: "4.5",
      formatted_phone_number: "(925) 556-3200",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublininfiniti.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8615862, 37.7020865] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "38 Beta Ct a3, San Ramon, CA 94583, USA",
      name: "Mendelson Auto Body Inc",
      rating: "4.8",
      formatted_phone_number: "(925) 838-2343",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.tmautobody.com/",
      first_type: "car_repair",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9832019, 37.7809037] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2089 Camino Ramon, San Ramon, CA 94583, USA",
      name: "Big O Tires",
      rating: "4.3",
      formatted_phone_number: "(925) 394-4254",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.bigotires.com/location/ca/san-ramon/2089-camino-ramon-94583/005108?utm_source=google&utm_medium=maps&utm_campaign=google+maps&y_source=1_ODY2OTU4MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "car_repair",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9697455, 37.7814719] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7194 Village Pkwy, Dublin, CA 94568, USA",
      name: "Oil Changers & Car Wash",
      rating: "4.4",
      formatted_phone_number: "(925) 828-8691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://oilchangers.com/",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9271821, 37.7097342] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7840 Amador Valley Blvd, Dublin, CA 94568, USA",
      name: "A&A Gas & Food Mart & Car Wash",
      rating: "4.3",
      formatted_phone_number: "(925) 829-7218",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9344861, 37.705996899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5251 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 594-1058",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008154-5251-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9035647, 37.698359] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6401 Dublin Blvd, Dublin, CA 94568, USA",
      name: "76",
      rating: "3.6",
      formatted_phone_number: "(925) 556-6790",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91034, 37.70487] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4895 Hacienda Dr, Dublin, CA 94568, USA",
      name: "Shell Car Wash",
      rating: "3.2",
      formatted_phone_number: "(925) 875-9970",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "http://www.shell.ca/en_ca/motorists/inside-our-stations/shell-canada-car-wash.html",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.887078, 37.7049582] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8999 San Ramon Rd, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 828-4820",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007871-8999-san-ramon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.941795, 37.722861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2760 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.9",
      formatted_phone_number: "(925) 248-2098",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8525087, 37.706023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Camino Tassajara, Danville, CA 94506, USA",
      name: "Texaco",
      rating: "3.8",
      formatted_phone_number: "(925) 648-2555",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.texaco.com/",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9168657, 37.7982814] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7777 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Shell",
      rating: "2.7",
      formatted_phone_number: "(925) 736-9072",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2145" },
            open: { day: 0, time: "0545" },
          },
          {
            close: { day: 1, time: "2145" },
            open: { day: 1, time: "0545" },
          },
          {
            close: { day: 2, time: "2145" },
            open: { day: 2, time: "0545" },
          },
          {
            close: { day: 3, time: "2145" },
            open: { day: 3, time: "0545" },
          },
          {
            close: { day: 4, time: "2145" },
            open: { day: 4, time: "0545" },
          },
          {
            close: { day: 5, time: "2145" },
            open: { day: 5, time: "0545" },
          },
          {
            close: { day: 6, time: "2145" },
            open: { day: 6, time: "0545" },
          },
        ],
        weekday_text: [
          "Monday: 5:45 AM – 9:45 PM",
          "Tuesday: 5:45 AM – 9:45 PM",
          "Wednesday: 5:45 AM – 9:45 PM",
          "Thursday: 5:45 AM – 9:45 PM",
          "Friday: 5:45 AM – 9:45 PM",
          "Saturday: 5:45 AM – 9:45 PM",
          "Sunday: 5:45 AM – 9:45 PM",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007864-7777-crow-canyon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9213027, 37.797219299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "809 E Stanley Blvd, Livermore, CA 94550, USA",
      name: "Shell",
      rating: "2.6",
      formatted_phone_number: "(925) 371-6930",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007984-809-e-stanley-blvd/en_US",
      first_type: "gas_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7869836, 37.6778835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2186 Las Positas Ct #7301, Livermore, CA 94551, USA",
      name: "Splashes Car Wash",
      rating: "3.9",
      formatted_phone_number: "(925) 960-1000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 4:30 PM",
          "Tuesday: 8:30 AM – 4:30 PM",
          "Wednesday: 8:30 AM – 4:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 8:00 AM – 4:30 PM",
          "Saturday: 8:00 AM – 4:30 PM",
          "Sunday: 8:00 AM – 4:00 PM",
        ],
      },
      website: "http://www.splashes.net/",
      first_type: "car_wash",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7741517, 37.6977945] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7090 Johnson Dr, Pleasanton, CA 94588, USA",
      name: "California Shine Co. Carwash",
      rating: "3.4",
      formatted_phone_number: "(925) 734-6644",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: 7:30 AM – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://californiashineco.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9186717, 37.6989223] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "31235 Mission Blvd, Hayward, CA 94544, USA",
      name: "Shell",
      rating: "3.3",
      formatted_phone_number: "(510) 487-2231",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007936-31235-mission-blvd/en_US",
      first_type: "gas_station",
      zip_code: "31235",
    },
    geometry: { type: "Point", coordinates: [-122.0349182, 37.6187321] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4005 Pimlico Dr, Pleasanton, CA 94588, USA",
      name: "Pleasanton Hand Car Wash",
      rating: "2.1",
      formatted_phone_number: "(925) 225-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.pleasantonhandcarwash.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8692998, 37.7006503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6973 Village Pkwy, Dublin, CA 94568, USA",
      name: "Corwood Car Wash",
      rating: "4.1",
      formatted_phone_number: "(925) 828-5151",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9249942, 37.7081258] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3587 First St suite B, Livermore, CA 94551, USA",
      name: "Bluemax Window Tint",
      rating: "4.8",
      formatted_phone_number: "(925) 339-1049",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://www.bluemaxautotint.com/",
      first_type: "car_wash",
      zip_code: "94551",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.7563922, 37.687535800000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8716338, 37.6997884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Magnolia Square, 2061 Camino Ramon, San Ramon, CA 94583, USA",
      name: "Sponges Car Wash & Detail Center",
      rating: "3.1",
      formatted_phone_number: "(925) 901-1400",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9699832, 37.7821353] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Blackhawk, CA 94506, USA",
      name: "Camino Tassajara And Tassajara Ranch Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91782, 37.79892] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Tassajara Village",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91981, 37.79883] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Jasmine Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.90304, 37.79959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Blackhawk, CA 94506, USA",
      name: "Camino Tassajara And Oak Gate Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.89944, 37.79986] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Center Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9173, 37.79734] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Zenith Ridge Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9174, 37.79708] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Lawrence Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.89817, 37.79961] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Wood Ranch Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.93272, 37.80085] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Blackstone Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91865, 37.79539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Mountain Ridge Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91867, 37.79513] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Blackhawk, CA 94506, USA",
      name: "Camino Tassajara And Shadow Creek Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.89346, 37.79981] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Tassajara, CA 94506, USA",
      name: "Camino Tassajara And Monterosso St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.89225, 37.79953] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Blackstone Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.92074, 37.79316] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Tassajara Ranch Dr And Blackstone Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.92116, 37.79316] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Blackhawk, CA 94506, USA",
      name: "Camino Tassajara And Knollview Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.88897, 37.79922] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Creekside Ave",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.94486, 37.80496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Tassajara, CA 94506, USA",
      name: "Camino Tassajara And Creekview Pl",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.88804, 37.79877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA 94506, USA",
      name: "Camino Tassajara And Sherburne Hills Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.94947, 37.80733] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Blackhawk, CA 94506, USA",
      name: "Camino Tassajara And Blackhawk Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.88269, 37.79948] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Tassajara, CA 94506, USA",
      name: "Camino Tassajara And Charbray St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.88142, 37.79925] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Briar Oaks Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.918714, 37.773868] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And N Wedgewood Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.922369, 37.773856] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And S Wedgewood Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9207, 37.773403] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Stoneleaf Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.91752, 37.773088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Monarch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.912215, 37.771733] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And S Monarch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.911402, 37.770685] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Gale Ridge Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.930792, 37.772298] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Bluehart Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.909355, 37.77024] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Gale Ridge Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.932342, 37.772393] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Main Branch Rd",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.908699, 37.769218] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Chanterella Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.93868, 37.771801] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Chanterella Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.94017, 37.772178] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Albion Rd",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9055, 37.76714] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Albion Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.905024, 37.765956] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Canyon View Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.945099, 37.771468] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Bollinger Canyon Rd And Canyon Lakes Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.945654, 37.771598] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Camino Tassajara, Danville, CA 94506, USA",
      name: "Texaco",
      rating: "3.8",
      formatted_phone_number: "(925) 648-2555",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.texaco.com/",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9168657, 37.7982814] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7777 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Shell",
      rating: "2.7",
      formatted_phone_number: "(925) 736-9072",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2145" },
            open: { day: 0, time: "0545" },
          },
          {
            close: { day: 1, time: "2145" },
            open: { day: 1, time: "0545" },
          },
          {
            close: { day: 2, time: "2145" },
            open: { day: 2, time: "0545" },
          },
          {
            close: { day: 3, time: "2145" },
            open: { day: 3, time: "0545" },
          },
          {
            close: { day: 4, time: "2145" },
            open: { day: 4, time: "0545" },
          },
          {
            close: { day: 5, time: "2145" },
            open: { day: 5, time: "0545" },
          },
          {
            close: { day: 6, time: "2145" },
            open: { day: 6, time: "0545" },
          },
        ],
        weekday_text: [
          "Monday: 5:45 AM – 9:45 PM",
          "Tuesday: 5:45 AM – 9:45 PM",
          "Wednesday: 5:45 AM – 9:45 PM",
          "Thursday: 5:45 AM – 9:45 PM",
          "Friday: 5:45 AM – 9:45 PM",
          "Saturday: 5:45 AM – 9:45 PM",
          "Sunday: 5:45 AM – 9:45 PM",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007864-7777-crow-canyon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9213027, 37.797219299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11060 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      name: "Safeway Fuel Station",
      rating: "4.3",
      formatted_phone_number: "(925) 359-2001",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://local.fuel.safeway.com/safeway/ca/san-ramon/11060-bollinger-canyon-rd.html",
      first_type: "gas_station",
      zip_code: "11060",
    },
    geometry: { type: "Point", coordinates: [-121.9218948, 37.7741621] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8000 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 736-8903",
      opening_hours: "missing",
      website:
        "http://www.chevronwithtechron.com/station/8000-Crow-Canyon-Rd-Danville-CA-94506-id92075",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9222229, 37.7978654] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Camino Tassajara, Danville, CA 94506, USA",
      name: "Texaco",
      rating: "3.8",
      formatted_phone_number: "(925) 648-2555",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.texaco.com/",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9168657, 37.7982814] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3600 Camino Tassajara, Danville, CA 94506, USA",
      name: "autoTECH Blackhawk",
      rating: "4.9",
      formatted_phone_number: "(925) 736-3100",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://autotechblackhawk.com/",
      first_type: "car_repair",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91482, 37.79814] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8000 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 736-8903",
      opening_hours: "missing",
      website:
        "http://www.chevronwithtechron.com/station/8000-Crow-Canyon-Rd-Danville-CA-94506-id92075",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9222229, 37.7978654] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2001 Alameda Diablo, Diablo, CA 94528, USA",
      name: "Alameda Auto services",
      rating: "missing",
      formatted_phone_number: "(925) 690-5372",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_repair",
      zip_code: "94528",
    },
    geometry: { type: "Point", coordinates: [-121.9558138, 37.835037] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11040 Bollinger Canyon Rd Suite E-186, San Ramon, CA 94582, USA",
      name: "Vintage Auto Garage LLC",
      rating: "5",
      formatted_phone_number: "(800) 516-4461",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://www.vintageautogarage.com/",
      first_type: "car_repair",
      zip_code: "11040",
    },
    geometry: { type: "Point", coordinates: [-121.9221744, 37.7748552] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "S Gale Ridge Rd, San Ramon, CA 94582, USA",
      name: "Superbike Performance",
      rating: "missing",
      formatted_phone_number: "(925) 395-3687",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://superbikeperformance.com/",
      first_type: "car_repair",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9310504, 37.7763675] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7612 Stellaria Ln, San Ramon, CA 94582, USA",
      name: "Johnson Racing USA",
      rating: "5",
      formatted_phone_number: "(925) 395-3687",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94582",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9296394, 37.775713499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1291 Ustilago Dr, San Ramon, CA 94582, USA",
      name: "Affordable Auto Glass",
      rating: "4.7",
      formatted_phone_number: "(925) 365-1049",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9379518, 37.7687008] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Camino Tassajara, Danville, CA 94506, USA",
      name: "Texaco",
      rating: "3.8",
      formatted_phone_number: "(925) 648-2555",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.texaco.com/",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9168657, 37.7982814] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7777 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Shell",
      rating: "2.7",
      formatted_phone_number: "(925) 736-9072",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2145" },
            open: { day: 0, time: "0545" },
          },
          {
            close: { day: 1, time: "2145" },
            open: { day: 1, time: "0545" },
          },
          {
            close: { day: 2, time: "2145" },
            open: { day: 2, time: "0545" },
          },
          {
            close: { day: 3, time: "2145" },
            open: { day: 3, time: "0545" },
          },
          {
            close: { day: 4, time: "2145" },
            open: { day: 4, time: "0545" },
          },
          {
            close: { day: 5, time: "2145" },
            open: { day: 5, time: "0545" },
          },
          {
            close: { day: 6, time: "2145" },
            open: { day: 6, time: "0545" },
          },
        ],
        weekday_text: [
          "Monday: 5:45 AM – 9:45 PM",
          "Tuesday: 5:45 AM – 9:45 PM",
          "Wednesday: 5:45 AM – 9:45 PM",
          "Thursday: 5:45 AM – 9:45 PM",
          "Friday: 5:45 AM – 9:45 PM",
          "Saturday: 5:45 AM – 9:45 PM",
          "Sunday: 5:45 AM – 9:45 PM",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007864-7777-crow-canyon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9213027, 37.797219299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7711 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Chamois Car Wash",
      rating: "3.9",
      formatted_phone_number: "(925) 648-2250",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.chamoiscarwash.com/",
      first_type: "car_wash",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9213104, 37.7967224] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8000 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "Chevron Car Wash",
      rating: "3.2",
      formatted_phone_number: "(925) 736-8903",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/8000-Crow-Canyon-Rd-Danville-CA-94506-id92075",
      first_type: "car_wash",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9226137, 37.7979257] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7777 Crow Canyon Rd, Danville, CA 94506, USA",
      name: "REBEL Wash",
      rating: "missing",
      formatted_phone_number: "(925) 736-9072",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.rebelstores.com/",
      first_type: "car_wash",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9212663, 37.7971406] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "108 Merano St, Danville, CA 94526, USA",
      name: "Caleb's Car Detailing",
      rating: "missing",
      formatted_phone_number: "(925) 856-0367",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      website: "https://calebscardetailing.simplybook.me/",
      first_type: "car_wash",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9675525, 37.827838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1026 S Monarch Rd, San Ramon, CA 94582, USA",
      name: "PROVEN MOBILE CAR DETAIL",
      rating: "missing",
      formatted_phone_number: "(925) 329-1036",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9105905, 37.764714] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California, USA",
      name: "Dublin/Pleasanton BART",
      rating: "4.7",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8991813, 37.7016504] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin/Pleasanton Bart",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website:
        "https://www.amtrak.com/servlet/ContentServer?pagename=am/am2Station/Station_Page&code=DBP",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8976075, 37.7028431] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin / Pleasanton BART Station",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dubl",
      first_type: "subway_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992171, 37.7016477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California, USA",
      name: "West Dublin/Pleasanton Station",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9282403, 37.6997562] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "West Dublin BART Station",
      rating: "3.6",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.927184, 37.700702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "West Pleasanton BART Station",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.927223, 37.698053] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6501 Golden Gate Dr, Dublin, CA 94568, USA",
      name: "West Dublin / Pleasanton",
      rating: "4",
      formatted_phone_number: "(510) 441-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/wdub",
      first_type: "subway_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9283202, 37.6996967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Pleasanton",
      rating: "4.3",
      formatted_phone_number: "(800) 411-7245",
      opening_hours: "missing",
      website: "https://acerail.com/stations/",
      first_type: "train_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8824899, 37.6582251] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Fallon Rd & Antone WY",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8593425, 37.7196035] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Fallon Rd & Signal Hill Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8583385, 37.7261175] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Fallon Rd & Silvera Ranch Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.866113, 37.7322445] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Central Pkwy & Glynnis Rose Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.874885, 37.7095425] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Grafton St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8659005, 37.705314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Hacienda Dr & Gleason Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8886685, 37.7137045] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Myrtle Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8819025, 37.706054] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Fallon Gateway",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8527755, 37.7062835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Hacienda Dr & Central Pkwy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8878485, 37.7090065] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Blvd & Hacienda Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.887874, 37.706258] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Owens Dr & Rosewood Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8861495, 37.6960225] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Owens Dr & Hacienda Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8890965, 37.6970105] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "West Dublin BART Station",
      rating: "3.6",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.927184, 37.700702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "West Pleasanton BART Station",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.927223, 37.698053] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "East Pleasanton BART Station",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.896382, 37.7008875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      name: "Dublin Pleasanton BART Unloading",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.89803, 37.701623] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dublin Pleasanton BART",
      rating: "3",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.89944, 37.702901] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Camp Parks Blvd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910086, 37.715729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "E Branch Pkwy And Windemere Pkwy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.89042, 37.749211] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Windemere Pkwy And E Branch Pkwy",
      rating: "4",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.889605, 37.749633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Mariposa Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910369, 37.714711] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Park Sierra",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.910389, 37.711771] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Iron Horse Trail Apts",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91017, 37.708751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      name: "Dougherty Rd And Sierra Ln",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91009, 37.705973] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "E Branch Pkwy And Sherwood Way",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.89797, 37.751433] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Windemere Pkwy And Longleaf Cir",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.886269, 37.758088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94582, USA",
      name: "Dougherty Rd And Old Ranch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.913745, 37.739893] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Dougherty Rd And Old Ranch Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.914044, 37.739956] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Windemere Pkwy And Bethany Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.88742, 37.760476] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "Windemere Pkwy And Albion Rd",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.888275, 37.761191] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "E Branch Pkwy And Windemere Ranch School",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.905004, 37.753463] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA 94582, USA",
      name: "E Branch Pkwy And Windemere Ranch School",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "bus_station",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.905302, 37.753415] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Dublin / Pleasanton BART Station",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/dubl",
      first_type: "subway_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992171, 37.7016477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6501 Golden Gate Dr, Dublin, CA 94568, USA",
      name: "West Dublin / Pleasanton",
      rating: "4",
      formatted_phone_number: "(510) 441-2278",
      opening_hours: "missing",
      website: "https://www.bart.gov/stations/wdub",
      first_type: "subway_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9283202, 37.6996967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2478 Armstrong St, Livermore, CA 94551, USA",
      name: "DeSoto Cab Livermore",
      rating: "2.3",
      formatted_phone_number: "(925) 449-9999",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://desotocablivermore.com/",
      first_type: "taxi_stand",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.812657, 37.699912] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7486 Brighton Ct, Dublin, CA 94568, USA",
      name: "Yellow Bayarea Cab - Airport shuttle, Taxi service in Dublin",
      rating: "5",
      formatted_phone_number: "(510) 714-3000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0230" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0230" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0230" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0230" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0230" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0230" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0230" },
          },
        ],
        weekday_text: [
          "Monday: 2:30 AM – 10:00 PM",
          "Tuesday: 2:30 AM – 10:00 PM",
          "Wednesday: 2:30 AM – 10:00 PM",
          "Thursday: 2:30 AM – 10:00 PM",
          "Friday: 2:30 AM – 10:00 PM",
          "Saturday: 2:30 AM – 10:00 PM",
          "Sunday: 2:30 AM – 10:00 PM",
        ],
      },
      website: "https://yellowbayareacab.com/",
      first_type: "taxi_stand",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9313783, 37.7183783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7100 Dougherty Rd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.5",
      formatted_phone_number: "(925) 829-6267",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9108643, 37.718834599999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2760 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.9",
      formatted_phone_number: "(925) 248-2098",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8525087, 37.706023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3192 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Valero",
      rating: "4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 1:00 AM",
          "Tuesday: 5:00 AM – 1:00 AM",
          "Wednesday: 5:00 AM – 1:00 AM",
          "Thursday: 5:00 AM – 1:00 AM",
          "Friday: 5:00 AM – 1:00 AM",
          "Saturday: 5:00 AM – 1:00 AM",
          "Sunday: 5:00 AM – 1:00 AM",
        ],
      },
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8780078, 37.6915088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5251 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 594-1058",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008154-5251-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9035647, 37.698359] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6400 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "4.1",
      formatted_phone_number: "(925) 828-2947",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9097699, 37.7044322] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6401 Dublin Blvd, Dublin, CA 94568, USA",
      name: "76",
      rating: "3.6",
      formatted_phone_number: "(925) 556-6790",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91034, 37.70487] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.2",
      formatted_phone_number: "(925) 485-3691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.87441, 37.67712] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1051 Airway Blvd, Livermore, CA 94550, USA",
      name: "Chevron",
      rating: "2.7",
      formatted_phone_number: "(925) 606-0104",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0400" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0400" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 12:00 AM",
          "Tuesday: 4:00 AM – 12:00 AM",
          "Wednesday: 4:00 AM – 12:00 AM",
          "Thursday: 4:00 AM – 12:00 AM",
          "Friday: 4:00 AM – 12:00 AM",
          "Saturday: 4:00 AM – 12:00 AM",
          "Sunday: 4:00 AM – 12:00 AM",
        ],
      },
      website: "https://www.chevronwithtechron.com/station",
      first_type: "gas_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8182354, 37.7034936] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7497 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Safeway Fuel Station",
      rating: "4.4",
      formatted_phone_number: "(925) 556-4034",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://local.fuel.safeway.com/safeway/ca/dublin/7497-dublin-blvd.html",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9264354, 37.7055061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2991 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Valero",
      rating: "3.8",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/en-us",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8963963, 37.6758988] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8998 Alcosta Blvd, San Ramon, CA 94583, USA",
      name: "76",
      rating: "3.4",
      formatted_phone_number: "(925) 828-2365",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website:
        "https://www.76.com/station/u76-cf-united-apro-llc-2704143-0000880483/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.935059, 37.7247702] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "Circle K",
      rating: "1.5",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.circlek.com/store-locator/US/pleasanton/4191-1st-st/2655643?utm_source=google&utm_medium=local&utm_campaign=google-local",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8696871, 37.6636959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.1",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.76.com/station/u76-delong-oil-inc-0000846862/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699814, 37.6634971] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4212 First St, Pleasanton, CA 94566, USA",
      name: "Shell",
      rating: "2.8",
      formatted_phone_number: "(925) 249-9301",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008151-4212-1st-st/en_US",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699476, 37.6628282] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "21320 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      name: "Chevron",
      rating: "4.1",
      formatted_phone_number: "(925) 829-2112",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.chevronwithtechron.com/stations/station-finder.aspx",
      first_type: "gas_station",
      zip_code: "21320",
    },
    geometry: { type: "Point", coordinates: [-121.9420944, 37.7234242] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8999 San Ramon Rd, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 828-4820",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007871-8999-san-ramon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.941795, 37.722861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      name: "Costco Gas Station",
      rating: "4.5",
      formatted_phone_number: "(925) 443-6306",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:30 PM",
          "Tuesday: 6:00 AM – 9:30 PM",
          "Wednesday: 6:00 AM – 9:30 PM",
          "Thursday: 6:00 AM – 9:30 PM",
          "Friday: 6:00 AM – 9:30 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.costco.com/warehouse-locations/livermore-ca-146.html",
      first_type: "gas_station",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8109262, 37.7020019] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4005 Pimlico Dr, Pleasanton, CA 94588, USA",
      name: "Pleasanton Hand Car Wash",
      rating: "2.1",
      formatted_phone_number: "(925) 225-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.pleasantonhandcarwash.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8692998, 37.7006503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay BMW",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8570",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaybmw.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8766058, 37.6997143] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4321 Toyota Dr, Dublin, CA 94568, USA",
      name: "Dublin Toyota",
      rating: "3.8",
      formatted_phone_number: "(925) 829-7700",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "https://www.dublintoyota.com/",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.88067, 37.7042] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6300 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Ken Harvey's Dublin Honda",
      rating: "4.1",
      formatted_phone_number: "(925) 828-8030",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "http://www.dublinhonda.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb%20listing",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9080111, 37.7049611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4200 John Monego Ct, Dublin, CA 94568, USA",
      name: "Dublin Chevrolet",
      rating: "4.1",
      formatted_phone_number: "(925) 452-7906",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinchevrolet.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8775431, 37.7024207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2700 Stoneridge Dr, Pleasanton, CA 94588, USA",
      name: "Stoneridge Chrysler Jeep Dodge Ram",
      rating: "3.9",
      formatted_phone_number: "(925) 248-4975",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "http://www.stoneridgecjd.com/",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8506469, 37.6992835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4355 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "Acura of Pleasanton",
      rating: "4.8",
      formatted_phone_number: "(925) 918-6996",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.acuraofpleasanton.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8758499, 37.7008586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5745 Johnson Dr, Pleasanton, CA 94588, USA",
      name: "Cycle Gear",
      rating: "4.8",
      formatted_phone_number: "(925) 251-1110",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.cyclegear.com/stores/cycle-gear-pleasanton-california-store-29",
      first_type: "parking",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9070756, 37.6991606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4345 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "Lexus of Pleasanton",
      rating: "4.8",
      formatted_phone_number: "(925) 918-6989",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:30 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.lexusofpleasanton.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "point_of_interest",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875055, 37.7005799] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3800 Kaiser Rd, Dublin, CA 94568, USA",
      name: "Dublin Nissan",
      rating: "4.3",
      formatted_phone_number: "(925) 307-6500",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinnissan.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.860654, 37.7021079] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5885 Owens Dr, Pleasanton, CA 94588, USA",
      name: "Mercedes-Benz of Pleasanton",
      rating: "4.1",
      formatted_phone_number: "(855) 822-9526",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 8:00 PM",
          "Tuesday: 7:30 AM – 8:00 PM",
          "Wednesday: 7:30 AM – 8:00 PM",
          "Thursday: 7:30 AM – 8:00 PM",
          "Friday: 7:30 AM – 8:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      website: "http://www.mbofpleasanton.com/",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9020517, 37.7004543] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3724 Stanley Blvd Ste A, Pleasanton, CA 94566, USA",
      name: "NAPA Auto Parts - Pleasanton Auto Parts",
      rating: "4.3",
      formatted_phone_number: "(925) 462-6500",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: 7:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.napaonline.com/en/CA/Pleasanton/store/37280?cid=pl_DAC_jobber_PleasantonAutoParts_37280",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.863065, 37.66809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay MINI Service Center",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8906",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaymini.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8765452, 37.699612] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3687 Old Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Mechanic King Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(925) 463-0650",
      opening_hours: "missing",
      website: "http://www.mechanickingauto.com/",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8799705, 37.6980194] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4400 John Monego Ct, Pleasanton, CA 94588, USA",
      name: "Dublin Buick GMC",
      rating: "4.3",
      formatted_phone_number: "(925) 452-7908",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublinbuickgmc.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8775187, 37.7047849] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6085 Scarlett Ct, Dublin, CA 94568, USA",
      name: "Dublin Volkswagen",
      rating: "4.2",
      formatted_phone_number: "(925) 829-0800",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "https://www.dublinvolkswagen.com/",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.903864, 37.702549] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3800B Kaiser Rd, Dublin, CA 94568, USA",
      name: "Dublin INFINITI",
      rating: "4.5",
      formatted_phone_number: "(925) 556-3200",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      website:
        "https://www.dublininfiniti.com/?utm_source=google&utm_medium=organic&utm_content=profile&utm_campaign=google_business_profile",
      first_type: "finance",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8615862, 37.7020865] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7121 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Ccm Partnership",
      rating: "missing",
      formatted_phone_number: "(925) 829-6046",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.922159, 37.706477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6955 Village Pkwy, Dublin, CA 94568, USA",
      name: "Midas",
      rating: "4.4",
      formatted_phone_number: "(925) 364-4220",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website:
        "https://www.midas.com/store/ca/dublin/6955-village-parkway-94568?shopnum=1865&utm_source=google&utm_medium=maps&utm_campaign=google+maps&y_source=1_MTE1ODYwNjYtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9247654, 37.7080808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6117 Dougherty Rd, Dublin, CA 94568, USA",
      name: "Kahler's Werkstatt",
      rating: "4.9",
      formatted_phone_number: "(925) 829-2050",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.kahlers.com/",
      first_type: "car_repair",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.909765, 37.707405] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "164 Wyoming St Ste. A, Pleasanton, CA 94566, USA",
      name: "Precision Auto Repair, Inc.",
      rating: "4.9",
      formatted_phone_number: "(925) 462-7440",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 5:00 PM",
          "Tuesday: 7:00 AM – 5:00 PM",
          "Wednesday: 7:00 AM – 5:00 PM",
          "Thursday: 7:00 AM – 5:00 PM",
          "Friday: 7:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.precisionautorepair.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8587962, 37.667653] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7194 Village Pkwy, Dublin, CA 94568, USA",
      name: "Oil Changers & Car Wash",
      rating: "4.4",
      formatted_phone_number: "(925) 828-8691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "https://oilchangers.com/",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9271821, 37.7097342] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7840 Amador Valley Blvd, Dublin, CA 94568, USA",
      name: "A&A Gas & Food Mart & Car Wash",
      rating: "4.3",
      formatted_phone_number: "(925) 829-7218",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9344861, 37.705996899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2760 Dublin Blvd, Dublin, CA 94568, USA",
      name: "Chevron",
      rating: "3.9",
      formatted_phone_number: "(925) 248-2098",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevron.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8525087, 37.706023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4895 Hacienda Dr, Dublin, CA 94568, USA",
      name: "Shell Car Wash",
      rating: "3.2",
      formatted_phone_number: "(925) 875-9970",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "http://www.shell.ca/en_ca/motorists/inside-our-stations/shell-canada-car-wash.html",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.887078, 37.7049582] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5251 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 594-1058",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008154-5251-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9035647, 37.698359] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6401 Dublin Blvd, Dublin, CA 94568, USA",
      name: "76",
      rating: "3.6",
      formatted_phone_number: "(925) 556-6790",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.91034, 37.70487] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8999 San Ramon Rd, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 828-4820",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007871-8999-san-ramon-rd/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.941795, 37.722861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7090 Johnson Dr, Pleasanton, CA 94588, USA",
      name: "California Shine Co. Carwash",
      rating: "3.4",
      formatted_phone_number: "(925) 734-6644",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: 7:30 AM – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      website: "https://californiashineco.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9186717, 37.6989223] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4005 Pimlico Dr, Pleasanton, CA 94588, USA",
      name: "Pleasanton Hand Car Wash",
      rating: "2.1",
      formatted_phone_number: "(925) 225-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.pleasantonhandcarwash.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8692998, 37.7006503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6973 Village Pkwy, Dublin, CA 94568, USA",
      name: "Corwood Car Wash",
      rating: "4.1",
      formatted_phone_number: "(925) 828-5151",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9249942, 37.7081258] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8716338, 37.6997884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5933 Dougherty Rd, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 829-0101",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10007872-5933-dougherty-rd/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.909575, 37.705486] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4895 Hacienda Dr, Dublin, CA 94568, USA",
      name: "Shell",
      rating: "3.9",
      formatted_phone_number: "(925) 875-9970",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10013516-4895-hacienda-dr/en_US",
      first_type: "gas_station",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8875224, 37.7049102] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell Car Wash",
      rating: "2.2",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: "missing",
      website: "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8712761, 37.699736] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3192 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Car wash",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8777809, 37.691573] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5251 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell Car Wash",
      rating: "3.4",
      formatted_phone_number: "(925) 594-1058",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://support.shell.com/hc/en-gb/articles/115005908365-What-car-wash-facilities-do-you-provide-at-Shell-Petrol-Filling-Stations-",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9033444, 37.6983586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5933 Dougherty Rd, Dublin, CA 94568, USA",
      name: "Shell Car Wash",
      rating: "2.8",
      formatted_phone_number: "(925) 829-0101",
      opening_hours: "missing",
      website: "https://find.shell.com/us/fuel/10007872-5933-dougherty-rd",
      first_type: "car_wash",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9096284, 37.705477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5280 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Chevron Car Wash",
      rating: "2.8",
      formatted_phone_number: "(925) 463-9650",
      opening_hours: "missing",
      website: "https://www.chevronwithtechron.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9044623, 37.6980672] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Pleasanton",
      rating: "4.3",
      formatted_phone_number: "(800) 411-7245",
      opening_hours: "missing",
      website: "https://acerail.com/stations/",
      first_type: "train_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8824899, 37.6582251] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "First St & Neal St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8739625, 37.6595615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Stanley Blvd & California Ave",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.862547, 37.6689665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "California 94566, USA",
      name: "Stanley Blvd & Shadow Cliffs",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.844574, 37.671897] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Santa Rita Rd & Valley Ave",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.874292, 37.677077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Valley Ave & Hansen Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899051, 37.669529] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Bernal Ave & Independence St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.86924, 37.652405] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Bernal Ave & Independence St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.869949, 37.652483] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Bernal Ave & Angela St",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.861893, 37.65375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Bernal Ave & Kottinger Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.859622, 37.654873] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Sunol Blvd & Mission Pkwy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.88037, 37.65228] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Sunol Blvd & Mission Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.879849, 37.652995] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Bernal Ave & Kottinger Dr",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.859368, 37.655951] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Kottinger Dr & Bernal Ave",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.859721, 37.656832] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Concord St & Bordeaux NB",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.853492, 37.653879] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Valley Ave & Case EB",
      rating: "5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.88486, 37.65044] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Kottinger Dr & Sharab Ct",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.861977, 37.658482] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Valley Ave & Case WB",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.88534, 37.65052] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Kottinger & Salvador",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.860432, 37.658297] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Kottinger Dr & Adams Wy",
      rating: "missing",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "transit_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.864164, 37.659319] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4212 First St, Pleasanton, CA 94566, USA",
      name: "Shell",
      rating: "2.8",
      formatted_phone_number: "(925) 249-9301",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008151-4212-1st-st/en_US",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699476, 37.6628282] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.1",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.76.com/station/u76-delong-oil-inc-0000846862/?utm_source=G&utm_medium=local&utm_campaign=google-local&utm_source=G&utm_medium=local&utm_campaign=google-local",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8699814, 37.6634971] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      name: "Circle K",
      rating: "1.5",
      formatted_phone_number: "(510) 759-2384",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://www.circlek.com/store-locator/US/pleasanton/4191-1st-st/2655643?utm_source=google&utm_medium=local&utm_campaign=google-local",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8696871, 37.6636959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "76",
      rating: "4.2",
      formatted_phone_number: "(925) 485-3691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.87441, 37.67712] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2991 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Valero",
      rating: "3.8",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: "missing",
      website: "https://www.valero.com/en-us",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8963963, 37.6758988] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3192 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Valero",
      rating: "4",
      formatted_phone_number: "(210) 345-2000",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 1:00 AM",
          "Tuesday: 5:00 AM – 1:00 AM",
          "Wednesday: 5:00 AM – 1:00 AM",
          "Thursday: 5:00 AM – 1:00 AM",
          "Friday: 5:00 AM – 1:00 AM",
          "Saturday: 5:00 AM – 1:00 AM",
          "Sunday: 5:00 AM – 1:00 AM",
        ],
      },
      website: "https://www.valero.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8780078, 37.6915088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "39 E Vineyard Ave, Livermore, CA 94550, USA",
      name: "Ruby Hills Chevron",
      rating: "4.3",
      formatted_phone_number: "(925) 249-0825",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "http://www.coxfamilystores.com/",
      first_type: "gas_station",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8045353, 37.6513708] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1797 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6258",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.chevron.com/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8741722, 37.6763828] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6782 Bernal Ave, Pleasanton, CA 94566, USA",
      name: "Safeway Fuel Station",
      rating: "4",
      formatted_phone_number: "(925) 846-8644",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      website:
        "https://local.fuel.safeway.com/safeway/ca/pleasanton/6782-bernal-ave.html",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8996486, 37.65687] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "707 Main St, Pleasanton, CA 94566, USA",
      name: "Pleasanton Gas Station",
      rating: "4.3",
      formatted_phone_number: "(925) 846-0434",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 8:30 PM",
          "Tuesday: 7:30 AM – 8:30 PM",
          "Wednesday: 7:30 AM – 8:30 PM",
          "Thursday: 7:30 AM – 8:30 PM",
          "Friday: 7:30 AM – 8:30 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website: "missing",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8750632, 37.6627695] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3121 Bernal Ave, Pleasanton, CA 94566, USA",
      name: "ampm",
      rating: "2.6",
      formatted_phone_number: "(925) 846-1083",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      website: "https://www.ampm.com/",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8586053, 37.6690808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3121 Bernal Ave, Pleasanton, CA 94566, USA",
      name: "Arco",
      rating: "3.8",
      formatted_phone_number: "(925) 846-1083",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://www.arco.com/en-us/northwest/",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.858203, 37.6691041] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Rocket",
      rating: "missing",
      formatted_phone_number: "(925) 485-3691",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      website: "https://rocketstores.com/",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8746289, 37.6771067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4005 Pimlico Dr, Pleasanton, CA 94588, USA",
      name: "Pleasanton Hand Car Wash",
      rating: "2.1",
      formatted_phone_number: "(925) 225-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.pleasantonhandcarwash.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8692998, 37.7006503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8716338, 37.6997884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell Car Wash",
      rating: "2.2",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: "missing",
      website: "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8712761, 37.699736] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3724 Stanley Blvd Ste A, Pleasanton, CA 94566, USA",
      name: "NAPA Auto Parts - Pleasanton Auto Parts",
      rating: "4.3",
      formatted_phone_number: "(925) 462-6500",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: 7:30 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.napaonline.com/en/CA/Pleasanton/store/37280?cid=pl_DAC_jobber_PleasantonAutoParts_37280",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.863065, 37.66809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay BMW",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8570",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaybmw.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8766058, 37.6997143] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2700 Stoneridge Dr, Pleasanton, CA 94588, USA",
      name: "Stoneridge Chrysler Jeep Dodge Ram",
      rating: "3.9",
      formatted_phone_number: "(925) 248-4975",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      website: "http://www.stoneridgecjd.com/",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8506469, 37.6992835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4345 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "Lexus of Pleasanton",
      rating: "4.8",
      formatted_phone_number: "(925) 918-6989",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:30 AM – 6:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      website:
        "https://www.lexusofpleasanton.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875055, 37.7005799] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "164 Wyoming St Ste. A, Pleasanton, CA 94566, USA",
      name: "Precision Auto Repair, Inc.",
      rating: "4.9",
      formatted_phone_number: "(925) 462-7440",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 5:00 PM",
          "Tuesday: 7:00 AM – 5:00 PM",
          "Wednesday: 7:00 AM – 5:00 PM",
          "Thursday: 7:00 AM – 5:00 PM",
          "Friday: 7:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://www.precisionautorepair.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8587962, 37.667653] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3687 Old Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Mechanic King Auto Repair",
      rating: "4.9",
      formatted_phone_number: "(925) 463-0650",
      opening_hours: "missing",
      website: "http://www.mechanickingauto.com/",
      first_type: "point_of_interest",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8799705, 37.6980194] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "57 California Ave H, Pleasanton, CA 94566, USA",
      name: "Ted's Dent Works",
      rating: "5",
      formatted_phone_number: "(925) 922-2023",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://teddent.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8618774, 37.6665916] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4350 Rosewood Dr, Pleasanton, CA 94588, USA",
      name: "East Bay MINI Service Center",
      rating: "4.7",
      formatted_phone_number: "(925) 264-8906",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      website:
        "https://www.eastbaymini.com/?utm_source=weblistings&utm_medium=organic&utm_campaign=hendricklocallistings",
      first_type: "car_dealer",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8765452, 37.699612] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3688 Washington St suite d, Pleasanton, CA 94566, USA",
      name: "Tri Valley Auto Glass",
      rating: "4.8",
      formatted_phone_number: "(925) 462-6459",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "http://www.trivalleyautoglass.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8608098, 37.668965] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3597 Nevada St # A, Pleasanton, CA 94566, USA",
      name: "Beratlis Automotive",
      rating: "5",
      formatted_phone_number: "(925) 846-5700",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8588816, 37.666875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3901 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "AutoZone Auto Parts",
      rating: "4.2",
      formatted_phone_number: "(925) 469-0056",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 9:00 PM",
          "Tuesday: 7:30 AM – 9:00 PM",
          "Wednesday: 7:30 AM – 9:00 PM",
          "Thursday: 7:30 AM – 9:00 PM",
          "Friday: 7:30 AM – 9:00 PM",
          "Saturday: 7:30 AM – 9:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      website:
        "https://www.autozone.com/locations/ca/pleasanton/3901-santa-rita-rd.html?cmpid=LOC:US:EN:AD:NL:1000000:GEN:4090",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8765508, 37.6965325] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3573 Vineyard Ave, Pleasanton, CA 94566, USA",
      name: "Edwards and Sons Automotive Equipment, Inc.",
      rating: "4.8",
      formatted_phone_number: "(510) 774-2108",
      opening_hours: "missing",
      website: "http://edwardsandsonsautoequipment.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8580053, 37.663109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4262 Stanley Blvd b, Pleasanton, CA 94566, USA",
      name: "B&T Motors",
      rating: "4.3",
      formatted_phone_number: "(925) 249-9300",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "http://www.bandtmotors.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8727015, 37.665459899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3295 Bernal Ave Ste B, Pleasanton, CA 94566, USA",
      name: "Caliber Collision",
      rating: "4.7",
      formatted_phone_number: "(925) 426-0120",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.caliber.com/find-a-location/pleasanton?utm_source=google&utm_medium=gbp-profile&utm_campaign=collision",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8575509, 37.6672039] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1061 Serpentine Ln STE C, Pleasanton, CA 94566, USA",
      name: "Apex Wheels",
      rating: "4.3",
      formatted_phone_number: "(925) 245-0773",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      website: "https://apexwheels.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8679132, 37.6690658] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3688A Washington St, Pleasanton, CA 94566, USA",
      name: "Big O Tires",
      rating: "4.4",
      formatted_phone_number: "(925) 400-9335",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      website:
        "https://www.bigotires.com/location/ca/pleasanton/3688a-washington-st-94566/005024?utm_source=google&utm_medium=maps&utm_campaign=google+maps&y_source=1_ODY2OTU2OS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8609242, 37.6689345] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1043 Serpentine Ln, Pleasanton, CA 94566, USA",
      name: "Edwards & Sons Automotive Equipment Inc.",
      rating: "5",
      formatted_phone_number: "(510) 774-2108",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "0000" },
            open: { day: 1, time: "0000" },
          },
        ],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Closed",
        ],
      },
      website: "http://www.edwardsandsonsautoequipment.com/",
      first_type: "car_repair",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8677927, 37.6724798] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1011 Kilkare Rd, Sunol, CA 94586, USA",
      name: "Warren's Body Shop",
      rating: "missing",
      formatted_phone_number: "(925) 862-2103",
      opening_hours: "missing",
      website: "missing",
      first_type: "point_of_interest",
      zip_code: "94586",
    },
    geometry: { type: "Point", coordinates: [-121.8964019, 37.610763] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2991 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Hopyard Auto Repair",
      rating: "4.1",
      formatted_phone_number: "(925) 339-7783",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 5:00 PM",
          "Tuesday: 9:30 AM – 5:00 PM",
          "Wednesday: 9:30 AM – 5:00 PM",
          "Thursday: 9:30 AM – 5:00 PM",
          "Friday: 9:30 AM – 5:00 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      website: "missing",
      first_type: "car_repair",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.896238, 37.676071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1875 Valley Ave, Pleasanton, CA 94566, USA",
      name: "Chevron",
      rating: "3",
      formatted_phone_number: "(925) 846-6130",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://www.chevronwithtechron.com/station/1875-Valley-Avenue-Pleasanton-CA-94566-id358727",
      first_type: "gas_station",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964903, 37.6579477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3790 Hopyard Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.2",
      formatted_phone_number: "(925) 846-3223",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "https://find.shell.com/us/fuel/10008153-3790-hopyard-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9043133, 37.6812915] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4257 Vervais Ave, Pleasanton, CA 94566, USA",
      name: "Downtown Pleasanton Carwash",
      rating: "3.8",
      formatted_phone_number: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website: "missing",
      first_type: "car_wash",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8729606, 37.6655458] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3598 Stanley Blvd, Pleasanton, CA 94566, USA",
      name: "Surf Thru Express Car Wash",
      rating: "4.3",
      formatted_phone_number: "(925) 357-3047",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      website: "https://surfthruexpress.com/",
      first_type: "car_wash",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.859431, 37.669498] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      name: "Car Wash",
      rating: "1",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8964729, 37.6576226] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3192 Santa Rita Rd, Pleasanton, CA 94566, USA",
      name: "Car wash",
      rating: "4.5",
      formatted_phone_number: "missing",
      opening_hours: "missing",
      website: "missing",
      first_type: "car_wash",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8777809, 37.691573] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4005 Pimlico Dr, Pleasanton, CA 94588, USA",
      name: "Pleasanton Hand Car Wash",
      rating: "2.1",
      formatted_phone_number: "(925) 225-1777",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      website: "http://www.pleasantonhandcarwash.com/",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8692998, 37.7006503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell",
      rating: "3.5",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      website:
        "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd/en_US",
      first_type: "gas_station",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8716338, 37.6997884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Santa Rita Rd, Pleasanton, CA 94588, USA",
      name: "Shell Car Wash",
      rating: "2.2",
      formatted_phone_number: "(925) 460-8061",
      opening_hours: "missing",
      website: "https://find.shell.com/us/fuel/10008155-6750-santa-rita-rd",
      first_type: "car_wash",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8712761, 37.699736] },
  },
];
