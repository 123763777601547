import React from 'react';

const ElectronicsAndCommunication = () => {
  return (
    <div className="p-4 text-white">
      <h1 className="text-3xl font-bold mb-4">Consent to Electronic Communications and Disclosures</h1>
      <p className="mb-6">Welcome to Home LLC! To utilize our electronic services, you must agree to the terms outlined in this Consent to Electronic Communications and Disclosures ("Consent"). This Consent governs your use of Home LLC's electronic services, enabling you to initiate transactions and communicate with us electronically. We may also be required by law to provide you with certain written information, notices, or disclosures, which with your consent, we will deliver to you electronically.</p>

      <h2 className="text-xl font-bold mb-2">1. Definitions</h2>
      <p className="mb-4">In this Consent, "you" and "your" refer to the individual giving consent and any authorized representatives. "Home," "we," "us," or "our" refers to Home LLC and its related companies. "Website" encompasses home.llc and its subdomains.</p>

      <h2 className="text-xl font-bold mb-2">2. Scope of Your Consent</h2>
      <p className="mb-4">This Consent applies to all communications, disclosures, notices, terms, conditions, and other documents related to Home LLC's products. Your consent remains effective until expressly withdrawn.</p>

      <h2 className="text-xl font-bold mb-2">3. Consent to Method of Electronic Communication</h2>
      <p className="mb-4">All communications provided to you electronically will be sent either directly to the email address provided on your application or through our Website. Documents may be delivered in PDF format. By affirmatively consenting, you agree that electronic documents or communications have the same legal effect as written and signed paper documents.</p>

      <h2 className="text-xl font-bold mb-2">4. Receiving a Paper Copy</h2>
      <p className="mb-4">You have the right to request a paper copy of any electronic document or communication, including this Consent. You may request a paper copy at no charge by emailing notice@home.com or calling (855)-223-3144.</p>

      <h2 className="text-xl font-bold mb-2">5. Hardware and Software Requirements</h2>
      <p className="mb-4">By affirmatively consenting, you acknowledge that you have the necessary hardware and software to access, view, and retain electronic communications from Home LLC. We will notify you of any changes to these requirements.</p>

      <h2 className="text-xl font-bold mb-2">6. Electronic Signatures</h2>
      <p className="mb-4">By affirmatively consenting, you acknowledge that you are voluntarily signing certain Home LLC documents electronically. Your electronic signature binds you to the terms and conditions to the same extent as a signature on paper. Certain real estate transaction documents may require a paper signature in front of a notary public.</p>

      <h2 className="text-xl font-bold mb-2">7. Withdrawing Your Consent</h2>
      <p className="mb-4">You may withdraw your consent to receive electronic notices by contacting us as described in Section 4. Please note that withdrawal of consent may result in termination of your access to our electronic services.</p>

      <h2 className="text-xl font-bold mb-2">8. Updating Your Contact Information</h2>
      <p className="mb-4">You must notify us of any change in your email address by emailing notice@home.com.</p>

      <h2 className="text-xl font-bold mb-2">9. Acknowledgement</h2>
      <p className="mb-4">By affirmatively consenting, you acknowledge your consent to receive electronic communications and agree to comply with the provisions of this Consent until or unless you notify Home LLC of withdrawal.</p>
    </div>
  );
};

export default ElectronicsAndCommunication;
