import React, { useEffect, useState } from "react";
import { ficoScoreCategories } from "./constant";
import FicoScoreQualifiedResult from "./FicoScoreQualifiedResult";
import Disqualified from "./overlay-popups/Disqualified";
import { useSelector } from "react-redux";

const FicoScoreQualification = ({ propertyRes }) => {
  const [selectedFicoScore, setSelectedFicoScore] = useState("");
  const [showUnQaulify, setShowUnQualify] = useState(false);
  const ownerData = useSelector((state) => state.cashout.owner);
  const propertyData = propertyRes ? propertyRes[0] : {};
  const errorState = useSelector((state) => state.cashout.errorState);

  const handleFicoScoreTabChange = (score) => {
    if (score.label === "Poor (<600)") {
      setShowUnQualify(true);
      setSelectedFicoScore(score.label);
    } else {
      setSelectedFicoScore(score.label);
    }
  };

  useEffect(() => {
    setSelectedFicoScore("");
  }, [ownerData]);

  if (ownerData) {
    if (Object.keys(ownerData).length === 0 && selectedFicoScore === "") {
      return null;
    }
  }

  if (errorState !== "") return null;

  if (Object.keys(propertyData).length === 0) {
    return null;
  }

  return (
    <div className="w-full h-full bg-custom-lightWhiteBG">
      <div className="p-10 w-full">
        <h2 className="text-[48px] mb-5 text-center font-normal">
          Please select your approximate FICO Score
        </h2>

        <div className="max-w-[90%] mx-auto flex gap-5 items-center justify-center">
          {ficoScoreCategories.map((score) => (
            <button
              key={score.label}
              onClick={() => handleFicoScoreTabChange(score)}
              className={`py-5 px-16 border rounded-[35px] border-custom-primaryPink text-custom-primaryPink text-xl
                ${
                  selectedFicoScore === score.label
                    ? "bg-custom-primaryPink text-white"
                    : "bg-transparent text-custom-primaryPink"
                }`}
            >
              {score.label}
            </button>
          ))}
        </div>
      </div>
      {selectedFicoScore === "Poor (<600)" ||
      selectedFicoScore === "" ? null : (
        <FicoScoreQualifiedResult propertyData={propertyData} />
      )}

      {selectedFicoScore === "Poor (<600)" && (
        <Disqualified
          isVisible={showUnQaulify}
          onClose={() => setShowUnQualify(false)}
        />
      )}
    </div>
  );
};

export default FicoScoreQualification;
