import React, { useEffect, useState } from "react";
import SliderComponentWithValue from "./SliderWithValues";
import LOIDemoScreenshot from "../../assets/cashout/LOI.png";
import { formFields } from "./constant";
import CTAbutton from "./CTAbutton";
import Hurray from "./overlay-popups/Hurray";
import { useDispatch, useSelector } from "react-redux";
import { calculateMinCashout, calculateMaxCashout } from "./CashoutCalculator";
import { Tooltip } from "@mui/material";
import { updateCashoutState } from "../../store/slices/cashoutCalcSlice";
import { useNavigate } from "react-router-dom";
import { ReplayOutlined } from "@mui/icons-material";

const FicoScoreQualifiedResult = ({ propertyData }) => {
  const dispatch = useDispatch();
  const calculatedField = useSelector((state) => state.cashoutCalc);
  const { cashoutValue, homeValue: sendingHomeValue } = calculatedField;
  const [isChecked, setIsChecked] = useState(false);
  const userEmail = useSelector((state) => state.cashout.userEmail);
  const userPhone = useSelector((state) => state.cashout.userPhone);
  const navigate = useNavigate();
  const ownerData = useSelector((state) => state.cashout.owner);
  const { owner } = ownerData;

  console.log(cashoutValue);

  // pass the userInfo and use it as initial staet
  const [formData, setFormData] = useState({
    firstName: owner?.FirstName || "",
    lastName: owner?.LastName || "",
    email: owner?.Email
      ? owner?.Email[0]["linktext"]
        ? owner?.Email[0]["linktext"]
        : ""
      : userEmail !== ""
      ? userEmail
      : "",
    mobile: owner?.Phone
      ? owner?.Phone[0]["linktext"]
        ? owner?.Phone[0]["linktext"]
        : ""
      : userPhone !== ""
      ? userPhone
      : "",
    source: "",
    otherSource: "",
  });

  const address = useSelector((state) => state.address);
  const [homeValue, setHomeValue] = useState(calculatedField.homeValue);
  const [loanValue, setLoanValue] = useState(calculatedField.loanValue);

  const homeEquity = homeValue - loanValue;

  const minimumCashoutAmount = calculateMinCashout(homeValue, homeEquity);
  const maximumCashoutAmount = calculateMaxCashout(homeValue, homeEquity);

  const [cashOutAmount, setCashOutAmount] = useState(
    minimumCashoutAmount + maximumCashoutAmount / 2
  );

  // Update cashout amount when min/max cashout changes
  useEffect(() => {
    const updatedCashOut = (minimumCashoutAmount + maximumCashoutAmount) / 2;
    setCashOutAmount(updatedCashOut);
  }, [minimumCashoutAmount, maximumCashoutAmount, dispatch]);

  // Sync local home/loan values with Redux
  useEffect(() => {
    setHomeValue(calculatedField.homeValue);
    setLoanValue(calculatedField.loanValue);
    setCashOutAmount(calculatedField.cashoutValue);
  }, [calculatedField]);

  const handleSubmit = async () => {
    let url =
      "https://api.sheety.co/9caacc695551317c504d67f1268dc58e/customerInformation/sheet1";
    let body = {
      sheet1: {
        address: address,
        firstName: formData.firstName,
        lastName: formData.lastName,
        homeValue: sendingHomeValue,
        cashoutValue,
      },
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        navigate("/");
        // Do something with object
      });
  };

  // handle refetch owner if email and anything is not there
  const handleRefreshOwnerData = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-full bg-custom-gray10 py-10" key={propertyData}>
      <h2 className="text-[64px] font-medium text-center text-custom-primaryPink">
        Congratulations!!
      </h2>

      <div className="max-w-[90%] w-full mx-auto h-full">
        <div className="w-full">
          <p className="text-2xl font-light text-white font-poppins mb-2">
            Address
          </p>

          <div className="flex relative mb-8">
            <input
              type="text"
              value={address}
              className="w-full bg-transparent text-white border-b outline-none items-center py-2 text-2xl"
            />
          </div>

          <div className="flex justify-between items-center w-full mb-6">
            <div>
              <h2 className="text-2xl font-poppins font-normal capitalize mb-1 text-white text-center">
                Home Value
              </h2>
              <SliderComponentWithValue
                min={250000}
                max={3000000}
                initialValue={homeValue}
                sliderValueStyle={"text-[40px] font-normal text-center"}
                sliderInputStyle={"w-[350px]"}
                onValueChange={(newValue) => {
                  setHomeValue(newValue);
                }}
                disabled={true}
                editable={false}
              />
            </div>
            <div>
              <h2 className="text-2xl font-poppins font-normal capitalize mb-1 text-white text-center">
                Mortgage Balance
              </h2>
              <SliderComponentWithValue
                min={0}
                max={homeValue}
                initialValue={loanValue}
                sliderValueStyle={"text-[40px] font-normal text-center"}
                sliderInputStyle={"w-[350px]"}
                onValueChange={(newValue) => {
                  setLoanValue(newValue);
                }}
                disabled={true}
                editable={false}
              />
            </div>
            <div>
              <h2 className="text-2xl font-poppins font-normal capitalize mb-1 text-white text-center">
                Cashout Amount
              </h2>
              <SliderComponentWithValue
                min={minimumCashoutAmount}
                max={maximumCashoutAmount}
                initialValue={cashOutAmount}
                sliderValueStyle={"text-[40px] font-normal text-center"}
                sliderInputStyle={"w-[350px]"}
                onValueChange={(newValue) => {
                  setCashOutAmount(newValue);
                }}
                disabled={true}
                editable={false}
              />
            </div>
          </div>

          <div className="w-full mb-10">
            <p className="text-[56px] font-medium text-center text-custom-primaryPink">
              Good News! <br />{" "}
              <span className="text-white">
                We're investing in properties like yours.
              </span>
            </p>
            <p className="text-center text-2xl text-white">
              If you'd like to get a Non-binding Pre Approval Offer Letter for
              <br /> "<span className="font-bold my-2">{address}</span>", <br />{" "}
              we just need a little information about the owner of the property.
            </p>
          </div>

          {/* form and LOI section */}
          <div className="flex w-full relative pt-20">
            <div className="flex-[3]">
              <FormComponent
                formData={formData}
                setFormData={setFormData}
                handleRefreshOwnerData={handleRefreshOwnerData}
              />

              <Tooltip
                placement="bottom-start"
                title="We need to confirm your identity before we can prequalify you for a home.llc investment. 
                Your social security number lets us verify that you're you."
              >
                <h4 className="mt-4 underline font-medium text-xl text-[#ED5272]">
                  Why do we need this information?
                </h4>
              </Tooltip>
            </div>
            <div className="flex-[1]">
              <img src={LOIDemoScreenshot} alt="letter of intent demo" />
            </div>

            <button
              className="absolute left-0 bg-custom-primaryPink border-none outline-none top-0 flex gap-2
       items-center z-50 text-white border p-2 rounded-md"
              onClick={handleRefreshOwnerData}
            >
              Autofill and Reload
              <ReplayOutlined sx={{ color: "white", cursor: "pointer" }} />
            </button>
          </div>

          {/* consent form */}
          <ConsentForm
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>
    </div>
  );
};

export default FicoScoreQualifiedResult;

const FormComponent = ({ formData, setFormData, handleRefreshOwnerData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSourceChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      source: value,
      otherSource: value === "Others/Referrals" ? prev.otherSource : "", // Reset otherSource if not selected
    }));
  };

  return (
    <div className="flex flex-col gap-5 w-[95%] relative" key={"owner"}>
      {formFields.map((field) => {
        if (field.name === "source") {
          return (
            <div key={field.name} className="relative h-[80px]">
              <label className="absolute left-0 top-[-20px] text-sm text-[#ACB5BD]">
                {field.placeholder}
              </label>
              <select
                name={field.name}
                value={formData.source}
                onChange={handleSourceChange}
                className="w-full bg-custom-gray10 text-white border-b border-b-[#F8F9FA] outline-none py-2 text-2xl cursor-pointer"
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option className="text-xl p-1" value="Google">
                  Google
                </option>
                <option value="Facebook" className="text-xl p-1">
                  Facebook
                </option>
                <option value="LinkedIn" className="text-xl p-1">
                  LinkedIn
                </option>
                <option value="Instagram" className="text-xl p-1">
                  Instagram
                </option>
                <option value="Twitter" className="text-xl p-1">
                  Twitter
                </option>
                <option value="YouTube" className="text-xl p-1">
                  YouTube
                </option>
                <option value="Others/Referrals" className="text-xl p-1">
                  Others/Referrals
                </option>
              </select>
            </div>
          );
        }

        return (
          <FormInput
            key={field.name}
            type={field.type}
            value={formData[field.name]}
            placeholder={field.placeholder}
            onChangeHandler={handleChange}
            name={field.name}
          />
        );
      })}

      {formData.source === "Others/Referrals" && (
        <div className="relative h-[100px]">
          <label className="absolute left-0 top-[-20px] text-sm text-[#ACB5BD]">
            Please specify
          </label>
          <textarea
            name="otherSource"
            value={formData.otherSource}
            onChange={handleChange}
            className="w-full bg-transparent text-white border-b border-b-[#F8F9FA] outline-none py-2 text-2xl"
            placeholder="Enter details"
          />
        </div>
      )}
    </div>
  );
};

const FormInput = ({
  type = "text",
  placeholder,
  value,
  onChangeHandler,
  name,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const formatValue = (input) => {
    if (!input) return "";
    return input
      .split("")
      .map((char, index) => (index % 2 !== 0 ? "*" : char)) // Replace characters at odd indexes (1-based)
      .join("");
  };

  const displayValue = isFocused ? inputValue : formatValue(inputValue || "");

  return (
    <div className="relative h-[80px]" key={placeholder}>
      {/* Label */}
      <label
        className={`absolute left-0 transition-all duration-300 ease-in-out ${
          isFocused || inputValue
            ? "top-[-20px] text-sm text-[#ACB5BD]" // Raised position
            : "top-[10px] text-2xl text-[#ACB5BD]" // Default position
        }`}
      >
        {placeholder}
      </label>

      <input
        name={name}
        type={type}
        value={isFocused ? inputValue : displayValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChangeHandler(e);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="w-full bg-transparent text-white border-b border-b-[#F8F9FA]
       outline-none items-center py-2 text-2xl placeholder:text-[#ACB5BD] h-[40px]"
      />
    </div>
  );
};

const ConsentForm = ({
  isChecked,
  onSubmit,
  setIsChecked,
  formData,
  setFormData,
}) => {
  const [showHurrayPopUp, setShowHurrayPopup] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isChecked) {
      onSubmit();
      setShowHurrayPopup(true);
    }
  };

  const consentItems = [
    <span key="item-1">
      I understand this is not an application for credit and will not impact my
      credit score.
    </span>,
    <span key="item-2">
      I authorize Hoom Inc to obtain information from credit bureaus and other
      information consistent with Hoom Inc's{" "}
      <a
        href="/legal?tab=credit-authorization"
        className="text-gray-100 underline"
      >
        Credit Authorization Policy.
      </a>
    </span>,
    <span key="item-3">
      I have read and agreed to Hoom Inc's{" "}
      <a href="/legal?terms-of-use" className="text-gray-100 underline">
        Terms of Use,
      </a>{" "}
      <a href="/legal?terms-of-use" className="text-gray-100 underline">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a href="/legal?terms-of-use" className="text-gray-100 underline">
        Consent to Electronic Communications and Disclosures{" "}
      </a>
    </span>,
    <span key="item-4">
      By submitting this information, I agree that Hoom Inc's may send me
      automated texts regarding my response and that data rates may apply.
    </span>,
  ];

  return (
    <div className="w-full mt-10">
      <form onSubmit={handleSubmit} className="space-y-6 h-full">
        <div className="flex items-start space-x-3">
          <input
            type="checkbox"
            id="consent"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mt-1 h-4 w-4 rounded border-gray-300 text-pink-500 focus:ring-pink-500"
          />
          <div className="space-y-6">
            <label
              htmlFor="consent"
              className="block text-sm font-medium text-white"
            >
              By checking this box, I agree to the following:
            </label>
            <ul className="space-y-5">
              {consentItems.map((item, index) => (
                <li
                  key={index}
                  className="text-sm text-gray-300 flex items-start space-x-2"
                >
                  <span className="mr-2">•</span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex justify-center">
          <CTAbutton
            type="submit"
            buttonProps={{
              disabled: !isChecked,
            }}
            extraStyles={`${!isChecked ? "bg-gray-400" : null} mt-10`}
          >
            Submit
          </CTAbutton>
        </div>

        <Hurray
          isVisible={showHurrayPopUp}
          onClose={() => setShowHurrayPopup(false)}
          formData={formData}
        />
      </form>
    </div>
  );
};
