import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import HomeEquity from "./pages/HomeEquity";
import CalculatorForm from "./pages/CalculatorForm";
import CashOutRedirect from "./pages/CashOutRedirect";

import EquityLandingPage from "./pages/EquityLandingPage";
import HomeAnalytics from "./pages/HomeAnalytics";
import BuyerLandingPage from "./pages/BuyerLandingPage";
import FaqPage from "./pages/FaqPage";
import BlogsPage from "./pages/BlogsPage";
import DetailsPage from "./pages/DetailsPage";
import Seller from "./pages/Seller";
import Consult from "./pages/Consult";
import HomeEquityLanding from "./pages/HomeEquityLanding";
import PressPage from "./pages/PressPage";
import ApprovalDocument from "./pages/PdfCashOut";
import CulturePage from "./pages/CulturePage";
import TeamPage from "./pages/TeamPage";
import Legal from "./pages/Legal";
import CalculatorFormBuyer from "./components/BuyerLanding/QualifyBuyer";
import CalculatorForSeller from "./components/SellerLanding/QualifySeller";
import Invest from "./pages/Invest";
import AiPage from "./pages/AiPage";
import ScheduleCallEquity from "./pages/ScheduleCallEquity";
import ScheduleCallBuy from "./pages/ScheduleCallBuy";
import HomeAi from "./pages/HomeAi";
import InvestorsDashboard from "../src/pages/Investors_Dashboard";
import LoginInv from "../src/components/Investors/Login";
import CashoutPage from "./pages/CashOutPage";

function App() {
  return (
    <div>
      {/* Your navigation and other components can go here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="HomeEquity" element={<HomeEquity />} />
        <Route path="cashoutform" element={<CalculatorForm />} />
        <Route path="Redirect" element={<CashOutRedirect />} />
        <Route path="/finance" element={<EquityLandingPage />} />
        <Route path="/home-analytics-landing" element={<HomeAnalytics />} />
        <Route path="/buyer" element={<BuyerLandingPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route exact path="/details/:id" element={<DetailsPage />} />
        <Route exact path="/sell" element={<Seller />} />
        <Route exact path="/analyze" element={<Consult />} />
        <Route
          exact
          path="/home-equity-qualify"
          element={<HomeEquityLanding />}
        />
        <Route exact path="/press" element={<PressPage />} />
        <Route exact path="/pdf" element={<ApprovalDocument />} />
        <Route exact path="/culture" element={<CulturePage />} />
        <Route exact path="/team" element={<TeamPage />} />
        <Route exact path="/legal" element={<Legal />} />
        <Route exact path="/buy" element={<CalculatorFormBuyer />} />
        <Route exact path="/seller-form" element={<CalculatorForSeller />} />
        <Route exact path="/invest" element={<Invest />} />
        <Route exact path="/ai" element={<AiPage />} />
        <Route exact path="/schedule-call" element={<ScheduleCallEquity />} />
        <Route exact path="/schedule-call-buy" element={<ScheduleCallBuy />} />
        <Route exact path="/home-ai" element={<HomeAi />} />
        <Route exact path="/HomeDashboard" element={<InvestorsDashboard />} />
        <Route exact path="/login" element={<LoginInv />} />
        <Route exact path="/cashout" element={<CashoutPage />} />
      </Routes>
    </div>
  );
}

export default App;
