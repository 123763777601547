import React, { useState } from "react";
import Homedetail from "../../assets/Investors/Homedetail_inv2.png";
import Airport from "../../assets/Investors/Intrest.png";
import Amenities from "../../assets/Investors/Amenities.png";
import Intrest from "../../assets/Investors/Airport.png";
import Risk_Range from "../../assets/Investors/Risk_range.png";
import { X, Instagram, Facebook, Linkedin } from "lucide-react";
import realtorLogo from "../../assets/Investors/realtor.png";
import redfinLogo from "../../assets/Investors/Redfin.png";
import zillowLogo from "../../assets/Investors/Zillow.png";
import { motion, AnimatePresence } from 'framer-motion';

const MainComponent = () => {
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const options = {
    noticeOptions: [
      "Intention to Sell",
      "Acceptance of Offer",
      "Early Settlement",
      "Home.LLC Settlement",
      "Homeowner Transfer",
      "Default and Cure Payments",
      "Taking Out Loans",
      "Property Damage",
      "Subsequent Loans",
      "Early Settlement Loans",
      "Prohibited Loans/Transactions",
      "Cooperation and Information",
      "Property Maintenance",
      "Default and Home.LLC Settlement",
      "Environmental Issues",
      "Legal Actions",
      "Events Leading to Default",
      "Death or Divorce of Homeowner",
      "Change in Trustees or Trustors",
    ],
  };
  
  const noticeDetails = {
    "Intention to Sell": { timeline: "At least 30 days before listing", description: "Notify Home.LLC of the intention to sell" },
    "Acceptance of Offer": { timeline: "Within 7 days of acceptance", description: "Notify Home.LLC upon accepting an offer" },
    "Early Settlement": { timeline: "With Notice of Intention to Sell or Offer", description: "Indicate Early Settlement or acknowledge Home.LLC Settlement" },
    "Home.LLC Settlement": { timeline: "With any sale/transfer", description: "Home.LLC may settle if no Early Settlement indicated" },
    "Homeowner Transfer": { timeline: "Not specified", description: "Notify Home.LLC of any non-permitted transfer" },
    "Default and Cure Payments": { timeline: "5 days or as required by law", description: "Home.LLC will notify before making Cure Payments" },
    "Taking Out Loans": { timeline: "Before taking out new loans", description: "Inform Home.LLC and provide documentation" },
    "Property Damage": { timeline: "Within 30 days of damage", description: "Notify Home.LLC of significant damage" },
    "Subsequent Loans": { timeline: "Before and after closing", description: "Provide request documentation and executed documentation post-closing" },
    "Early Settlement Loans": { timeline: "At closing", description: "Ensure direct payment to Home.LLC" },
    "Prohibited Loans/Transactions": { timeline: "Not specified", description: "Do not encumber the property without consent" },
    "Cooperation and Information": { timeline: "Immediately upon specific events", description: "Notify Home.LLC of significant events affecting the property" },
    "Property Maintenance": { timeline: "Ongoing", description: "Perform necessary maintenance and repairs" },
    "Default and Home.LLC Settlement": { timeline: "Not specified", description: "Notify Rush of initiation and completion of Home.LLC Settlement" },
    "Environmental Issues": { timeline: "Not specified", description: "Notify Home.LLC of environmental issues" },
    "Legal Actions": { timeline: "Not specified", description: "Notify Home.LLC of legal actions involving the property" },
    "Events Leading to Default": { timeline: "Immediately upon occurrence", description: "Notify Home.LLC of potential default events" },
    "Death or Divorce of Homeowner": { timeline: "Immediately upon occurrence", description: "Notify Home.LLC of death or divorce of any homeowner" },
    "Change in Trustees or Trustors": { timeline: "Immediately upon occurrence", description: "Notify Home.LLC of changes in trustees or trustors, and the appointment of substitutes" },
  };
  
  const [selectedDesirabilityTab, setSelectedDesirabilityTab] =
    useState("Amenities");

  const handleCardClick = (label) => {
    const links = {
      "Investment Agreement":
        "https://drive.google.com/file/d/1G_tLv-6oXe6cnUSGaRkLS8N3h9HCpSW7/view",
      "Settlement Calculator":
        "https://docs.google.com/spreadsheets/d/1wdFFicYl0bSHWTFaqzO8x2hdddIrTl9Ze077PNumuYQ/edit?gid=1974268456#gid=1974268456",
      "Appraisal Report":
        "https://drive.google.com/file/d/1SnCvR9ks-eFYbdB4wul4F4gB_zyAgWr_/view",
      "Credit Report": "https://drive.google.com/drive/folders/18nsSbsOr-ua9PUi7KhFXHOBPfWh1JjGF?usp=sharing",
    };

    if (label === "Home Insights") {
      setSelectedInsight("homeInsights");
    } else if (links[label]) {
      window.open(links[label], "_blank");
    } else {
      alert(`You clicked on ${label}`);
    }
  };

  const MotionAlert = ({ message, isOpen, handleClose }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}
          >
            <p>{message}</p>
            <button onClick={handleClose} style={{ marginTop: '10px' }}>Close</button>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };


  const handleNotifyClick = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const IconHome = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-10 h-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
  );

  const IconHandshake = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-10 h-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  );

  const IconCalculator = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-10 h-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
      />
    </svg>
  );

  const IconFileText = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-10 h-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  );

  const IconFileCheck = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-10 h-10"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
      />
    </svg>
  );

  const InfoButton = ({ icon: Icon, label, isActive, onClick }) => (
    <button
      className={`flex flex-col items-center justify-center p-6 rounded-lg border ${
        isActive ? "border-red-500" : "border-gray-300"
      } hover:bg-white-100 transition-colors duration-200 w-full min-h-[146px]`}
      onClick={() => onClick(label)}
    >
      <div className={isActive ? "text-red-500" : "text-gray-700"}>
        <Icon />
      </div>
      <span className="text-lg text-gray-700 mt-2 font-poppins">{label}</span>
    </button>
  );

  const ImportantInformation = () => (
    <div className="bg-white-100 p-8 rounded-lg  font-poppins mx-12">
      <h2
        className="mb-6 font-poppins font-normal text-6xl 2xl:text-7xl"
      >
        Important Information
      </h2>
      <div className="flex space-x-6">
        <InfoButton
          icon={IconHome}
          label="Home Insights"
          isActive={selectedInsight === "homeInsights"}
          onClick={handleCardClick}
        />
        <InfoButton
          icon={IconHandshake}
          label="Investment Agreement"
          onClick={handleCardClick}
        />
        <InfoButton
          icon={IconCalculator}
          label="Settlement Calculator"
          onClick={handleCardClick}
        />
        <InfoButton
          icon={IconFileText}
          label="Appraisal Report"
          onClick={handleCardClick}
        />
        <InfoButton
          icon={IconFileCheck}
          label="Credit Report"
          onClick={handleCardClick}
        />
      </div>
    </div>
  );

  const HomeDetails = () => (
    <div className="bg-white p-8 rounded-lg font-poppins mx-12 mt-10">
      <h2
        className="mb-6 font-poppins font-normal text-3xl md:text-5xl 3xl:text-6xl"
      >
        Home Details
      </h2>
      <div className="flex">
        <img
          src={Homedetail}
          alt="House"
          className="w-1/2 h-[600px] object-cover rounded-lg mr-8"
        />
        <div className="flex-1 flex flex-col justify-between">
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">Property Type :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">Single Family</p>
          </div>
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">Year Built :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">1966</p>
          </div>
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">Property Area in Sq. Footage :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">1,628 sqft</p>
          </div>
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">City :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">Newbury Park</p>
          </div>
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">County :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">Ventura County</p>
          </div>
          <div className="flex items-baseline mb-6">
            <p className="text-xl mr-2">MSA :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">
            Oxnard–Thousand Oaks–Ventura, CA
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-xl mr-2">State :</p>
            <p className="text-2xl 2xl:text-3xl font-bold">CA</p>
          </div>
        </div>
      </div>
    </div>
  );

  const ListPricesEstimates = () => (
    <div className=" p-6 rounded-lg  font-poppins mx-12 mt-10">
      <h2
        className="mb-6 font-poppins font-normal"
        style={{ fontSize: "60px" }}
      >
        List Prices & Estimates
      </h2>
      <div className="relative w-full h-[400px] mb-6">
        
        <div className="absolute left-[-30px] top-0 h-full flex flex-col justify-between items-end pr-2 text-gray-600">
          <p>$1,100,000</p>
          <p>$1,000,000</p>
          <p>$9,00,000</p>
          <p>$8,00,000</p>
          <p>$7,00,000</p>
          <p>$6,00,000</p>
        </div>

        <div
          className="absolute left-12 top-0 h-full border-l border-gray-300"
          style={{ marginLeft: "20px" }}
        ></div>

        <div
          className="absolute left-12 w-[calc(100%-2rem)] h-full flex flex-col justify-between"
          style={{ marginTop: "-10px" }}
        ></div>

        <div className="absolute left-12 w-[calc(100%-3rem)] h-full flex justify-around items-end">

          <div className="flex flex-col items-center">
            <p className="text-2xl font-bold mb-2">$996,600</p>
            <div className="bg-pink-500 w-[60px] h-[270px] rounded-t-lg"></div>
          </div>

 
          <div className="flex flex-col items-center">
            <p className="text-2xl font-bold mb-2">$1,041,000</p>
            <div className="bg-pink-500 w-[60px] h-[290px] rounded-t-lg"></div>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-2xl font-bold mb-2">$977,945</p>
            <div className="bg-pink-500 w-[60px] h-[275px] rounded-t-lg"></div>
          </div>
        </div>

        <div
          className="absolute left-12 w-[calc(100%-3rem)] top-[calc(61%-28%)] border-t border-gray-400"
          style={{ marginLeft: "-100px" }}
        >
          <p className="absolute right-0 transform translate-y-[-50%] translate-x-[100%] text-gray-600">
            $1,041,000 <span className="italic">Average</span>
          </p>
        </div>

        <div
          className="absolute left-12 bottom-0 w-[calc(100%-3rem)] border-t border-gray-300 mt-4"
          style={{ marginLeft: "-10px" }}
        ></div>
      </div>

      <div className="flex justify-around mt-4">
        <img src={zillowLogo} alt="Zillow" className="h-[40px]" />
        <img src={realtorLogo} alt="Realtor" className="h-[40px]" />
        <img src={redfinLogo} alt="Redfin" className="h-[40px]" />
      </div>


      <p className="text-center text-lg font-semibold mt-4">
        “Your Property Value Crossed 1 million Mark.”
      </p>
    </div>
  );



 const Desirability = () => {
  const tabs = [
    "Amenities",
    "Risk Range",
    "Nearest Interstate",
    "Nearest Airport",
  ];
  const images = {
    Amenities: Amenities,
    "Risk Range": Risk_Range,
    "Nearest Interstate": Intrest,
    "Nearest Airport": Airport,
  };

  const handleTabClick = (tab) => {
    setSelectedDesirabilityTab(tab);
  };

  return (
    <div
      className="bg-white p-6 rounded-lg shadow-md font-poppins mx-12 mt-20 border border-gray-300"
      style={{ maxWidth: "100%", boxSizing: "border-box" }}
    >
      <h2 className="mb-6 font-poppins font-normal text-6xl 2xl:text-7xl text-center">
        Desirability
      </h2>

      <div className="flex flex-col h-full">
        <div className="flex mb-4 border-b-2 border-gray-200 justify-center">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 mx-2 font-poppins transition-all text-2xl 2xl:text-4xl ${
                selectedDesirabilityTab === tab
                  ? "text-black border-b-4 border-black"
                  : "text-gray-500"
              }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="flex-grow bg-white flex items-center justify-center">
          <img
            src={images[selectedDesirabilityTab]}
            alt={selectedDesirabilityTab}
            className="rounded-lg w-[1000px] h-[600px] 2xl:w-[1728px] 2xl:h-[760px] mt-6"
            style={{ borderRadius: "12px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      </div>
    </div>
  );
};

  
  
  

  const Notices = () => {
    const [selectedNotice, setSelectedNotice] = useState({
      timeline: '',
      description: '',
    });
  
    const handleSelectChange = (event) => {
      const notice = event.target.value;
      const details = noticeDetails[notice] || { timeline: '', description: '' };
      setSelectedNotice(details);
    };
  
    return (
      <div className="bg-white mt-4 p-8 pt-16 rounded-lg font-poppins mx-12" style={{ marginTop: selectedInsight === "homeInsights" ? "100px" : "20px" }}>
        <h2 className="mb-6 font-poppins font-normal" style={{ fontSize: "60px" }}>
          Notices
        </h2>
        <div className="flex gap-4 mb-4">
          <select
            className="p-4 rounded-lg border border-gray-300 w-1/2 bg-white"
            onChange={handleSelectChange}
          >
            <option>Select Notice</option>
            {options.noticeOptions.map((notice, index) => (
              <option key={index} value={notice}>
                {notice}
              </option>
            ))}
          </select>
          <input
            type="text"
            className="p-4 rounded-lg border border-gray-300 w-1/2 placeholder-black"
            placeholder="Timeline"
            readOnly
            value={selectedNotice.timeline}
          />
        </div>
        <input
          type="text"
          className="p-4 w-full rounded-lg border border-gray-300 mb-6 placeholder-black"
          placeholder="Description"
          readOnly
          value={selectedNotice.description}
        />
        <div className="bg-white mt-4 p-8 rounded-lg font-poppins mx-12">
      <MotionAlert
        isOpen={showAlert}
        message="Message successfully sent"
        handleClose={() => setShowAlert(false)}
      />
      <div className="flex justify-center mb-6">
        <button
          className="bg-[#ED5272] text-white py-3 px-6 rounded-lg"
          onClick={handleNotifyClick}
        >
          Notify Us
        </button>
      </div>
    </div>
      </div>
    );
  };
  
 
  
  return (
    <div>
      <ImportantInformation />
      {selectedInsight === "homeInsights" && (
        <>
          <HomeDetails />
          <ListPricesEstimates />
          <Desirability />
        </>
      )}
      <Notices />
    </div>
  );
};

export default MainComponent;
