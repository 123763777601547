import { useContext, useState } from "react";
import { usersData } from "../data";
import { CounterContext } from "../../../hooks/HomeAi";

// Define age groups
const ageGroups=[
  { label: "Under 18", min: 0, max: 18, count: 0 },
  { label: "18 - 29", min: 18, max: 35, count: 0 },
  { label: "30 - 44", min: 35, max: 50, count: 0 },
  { label: "45 - 59", min: 50, max: 65, count: 0 },
  { label: "60+", min: 65, max: Infinity, count: 0 },
];

function convertAgeGroupToArray(ageGroup) {
  const ageGroupArray=[];

  for (const key in ageGroup) {
    ageGroupArray.push({
      label: key,
      count: ageGroup[key],
    });
  }

  return ageGroupArray;
}

// Sort age groups by user count to determine the background color ranking

// Define background colors based on the rank
const bgColors=[
  "bg-[#0F5DDD]",
  "bg-[#1F75FE]",
  "bg-[#5090F6]",
  "bg-[#8BB3F4]",
  "bg-[#B6C8E5]",
];

// Assign background colors based on sorted order

const AgeComparison=() => {
  const [hoveredIndex, setHoveredIndex]=useState(null);
  const { age_group }=useContext(CounterContext);

  const sortedAgeGroups=[...convertAgeGroupToArray(age_group)]

  const total=sortedAgeGroups.reduce((sum, group) => sum+group.count, 0);

  const ageGroupsWithPercentages=sortedAgeGroups.map(group => ({
    label: group.label,
    count: group.count,
    percentage: ((group.count/total)*100).toFixed(2)+'%'
  }));

  return (
    <div className="flex mt-8">
      {ageGroupsWithPercentages?.map((group, index) => (
        <div
          key={index}
          className="h-[130px] w-1/5 border-x border-gray-400"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className={`h-[100px] p-4 mt-4 ${bgColors[index]} flex items-center justify-center cursor-pointer`}>
            {hoveredIndex===index&&<p className="text-md font-semibold text-white">{group.percentage}</p>}
          </div>
          <p className="text-center text-black">{group.label}</p>
        </div>
      ))}
    </div>
  );
};

export default AgeComparison;
