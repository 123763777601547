import React, { useContext } from "react";
import { usersData, demoArray } from "../data";
import { CounterContext } from "../../../hooks/HomeAi";

const GenderComparison=() => {
  const { genderComp }=useContext(CounterContext);

  const totalUsers=genderComp?.Total;

  const genderCounts=usersData.reduce(
    (acc, user) => {
      acc[user.gender]=(acc[user.gender]||0)+1;
      return acc;
    },
    { male: 0, female: 0, other: 0 }
  );

  const malePercentage=((genderComp?.Male/totalUsers)*100).toFixed(2);
  const femalePercentage=((genderComp?.Female/totalUsers)*100).toFixed(2);
  const otherPercentage=((0/totalUsers)*100).toFixed(2);

  // Calculate number of circles for each gender based on demoArray length
  const totalCircles=demoArray.length;
  const maleCircles=Math.round(
    (genderComp?.Male/totalUsers)*totalCircles
  );
  const femaleCircles=Math.round(
    (genderComp?.Female/totalUsers)*totalCircles
  );
  const otherCircles=totalCircles-maleCircles-femaleCircles;

  const circles=[];
  for (let i=0; i<maleCircles; i++) {
    circles.push("male");
  }
  for (let i=0; i<femaleCircles; i++) {
    circles.push("female");
  }
  for (let i=0; i<otherCircles; i++) {
    circles.push("other");
  }

  return (
    <div className="w-full flex items-start mt-8">
      <div className="w-[80%] flex flex-wrap gap-y-7 gap-x-4 mt-4 mr-1">
        {circles?.map((gender, index) => {
          const bgColor=
            gender==="male"
              ? "bg-[#379DFC]"
              :gender==="female"
                ? "bg-[#FF5B7D]"
                :"bg-[#A9A9A9]";
          return (
            <div
              key={index}
              className={`h-8 w-8 rounded-full ${bgColor}`}
            ></div>
          );
        })}
      </div>
      <div className="w-[20%] flex flex-col gap-y-4 mt-2">
        <div className="border-l-4 pl-4 border-[#379DFC]">
          <p className="mt-0 mb-0">Male</p>
          <span>{malePercentage}%</span>
        </div>
        <div className="border-l-4 pl-4 border-[#FF5B7D]">
          <p className="mt-0 mb-0">Female</p>
          <span>{femalePercentage}%</span>
        </div>
        <div className="border-l-4 pl-4 border-[#A9A9A9]">
          <p className="mt-0 mb-0">Other</p>
          <span>{otherPercentage}%</span>
        </div>
      </div>
    </div>
  );
};

export default GenderComparison;
