import React from 'react'
import bar from '../../assets/barGraph.png'
import poll from '../../assets/poll.png'
import seperator from '../../assets/seperator.png'

const GraphSection = () => {
  return (
   <div className='w-full h-full pb-10 px-10 bg-gradient-to-tr from-[#22262A] via-[#22262A] to-[#495057] '>
    <div className='flex flex-col sm:flex-row justify-start gap-20 sm:gap-0 sm:justify-around items-start'>
     <img src={bar} alt="" className='mt-20 w-[500px] 2xl:w-auto' />
     <img src={seperator} alt="" className='hidden sm:block' />
     <img src={poll} alt="" className='sm:mt-28 w-[500px] 2xl:w-auto' />
    </div>
    </div>
  )
}

export default GraphSection