import React from "react";
import ComfortScore from "../Cards/ComfortScore";
import ComfortRating from "../Cards/ComfortRating";
import Cloud from "../../../../assets/HomeAi/cloud.svg";
import Hitemp from "../../../../assets/HomeAi/Hitemp.svg";
import NaturalDisasterRisk from "../Cards/NaturalDisasterRisk";
import Forecast from "../Cards/Forecast";

const WeatherAndClimate=() => {
  return (
    <div className="flex gap-14 flex-col">
      <div className="flex gap-4 flex-col">
        <div className="grid grid-cols-1 md:grid-cols-10 gap-4 ">
          <div className="md:col-span-4">
            <ComfortScore />
          </div>
          <div className="md:col-span-6">
            <ComfortRating />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 w-full">
          <div className="flex p-5 rounded-full md:rounded-tl-full md:rounded-bl-full items-center gap-5 w-full bg-[#FFEFEF]">
            <div className="p-4 md:p-6 bg-white rounded-full">
              <img src={Cloud} alt="Star" className="w-12 h-12" />
            </div>
            <div className="flex flex-col gap-2 md:gap-3">
              <div className="text-lg md:text-2xl">Rainfall History </div>
              <div className="text-sm md:text-base">
                On an Average there are 117 Rainy days per year.{" "}
              </div>
            </div>
          </div>
          <div className="flex p-5 rounded-full md:rounded-tr-full md:rounded-br-full flex-row-reverse items-center gap-5 w-full bg-[#FFEFEF]">
            <div className="p-4 md:p-6 bg-white rounded-full">
              <img src={Hitemp} alt="Star" className="w-12 h-12" />
            </div>
            <div className="flex flex-col items-end gap-2 md:gap-3 text-right">
              <div className="text-lg md:text-2xl">High Temperature History</div>
              <div className="text-sm md:text-base">
                9 days are recorded with high temperature.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-0">
        <div className="w-full md:w-[45%]">
          <NaturalDisasterRisk />
        </div>
        <div className="w-full md:w-[55%]">
          <Forecast />
        </div>
      </div>
    </div>
  );
};

export default WeatherAndClimate;
