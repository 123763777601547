import React from "react";

import linkedin from "../../assets/linkedin.svg";
import mail from "../../assets/gmail.svg";
import { captains } from "./data";

const Captains = () => {
  return (
    <div className="w-full h-auto bg-white py-8 px-2 2xl:p-[67px] overflow-hidden border-b">
      <h2 className="text-2xl sm:text-[48px] sm:leading-[72px] text-black w-full font-medium text-center uppercase mb-[75px]">
        our captains
      </h2>

      <div className="flex flex-row items-center justify-center flex-wrap gap-[70px]">
        {captains.map((captain) => (
          <div
            className="w-full sm:w-[350px] 2xl:w-[400px] h-[500px] rounded-sm 2xl:h-[550px] cursor-pointer relative overflow-hidden"
            key={captain.id}>
            <img
              src={captain.headshot}
              alt=""
              className="h-full w-full object-cover rounded-sm bg-transparent"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-end items-center transition-opacity duration-300">
              <div className="flex items-center justify-between bg-black bg-opacity-50 w-full py-2 px-[20px]">
                <div>
                  <h2 className="text-white text-lg font-medium mb-2 text-left uppercase tracking-wide">
                    {captain.name}
                  </h2>
                  <p className="text-white text-sm">{captain.designation}</p>
                </div>

                <div className="flex items-center gap-8">
                  <a
                    href={captain.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2">
                    <img src={linkedin} alt="LinkedIn" className="w-6 h-6" />
                  </a>
                  <a
                    href={captain.email}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2">
                    <img src={mail} alt="LinkedIn" className="w-6 h-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Captains;
