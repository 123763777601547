import React from "react";
import { terms } from "./constant";

const TermsConditions = () => {
  return (
    <div className="w-full bg-custom-gray4">
      <div className="py-10 max-w-[75%] mx-auto">
        <div className="text-xs text-gray-700">
          Home.LLC Agreements are provided exclusively by Home Inc. Home.LLC - all
          of which are wholly-owned subsidiaries of Home.LLC (collectively,
          "Home.LLC"). Home.LLC holds the following Real Estate Broker licenses:
          NMLS# -{" "}
          <a
            href="https://drive.google.com/file/d/1ohmt12BLWvkXZuubiukqg1vnjdVnxu4O/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            className="underline text-custom-gray10"
          >
            California DRE #0223037
          </a>
        </div>
        <div className="text-xs text-gray-700">
          The applicable Home.LLC entity enters into the Home.LLC Agreement
          directly with consumers and does not act as an agent of any
          third-party. No agency relationship shall be formed between any
          Home.LLC entity and a consumer pursuant to or in connection with an
          Home.LLC Agreement. All Rights Reserved. Other terms and restrictions
          apply.
        </div>
        <p className="mb-3">-------</p>
        <div className="space-y-1">
          {terms.map((term, index) => (
            <div key={index} className="flex gap-1 text-xs font-normal">
              <span className="flex-shrink-0">{index + 1}.</span>
              <span>{term}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
