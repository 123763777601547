


export const HomeAiData={
  94043: {
    cordinats: [-122.0919524, 37.4168363],
    populationValue: 32700,
    area_name: "Mountain View, CA",
    about: "Mountain View is a vibrant city located in the heart of Silicon Valley. Known for being the headquarters of tech giant Google, it offers a mix of high-tech industry and a suburban lifestyle. The city features numerous parks, a thriving downtown area, and close proximity to major highways and public transportation.",
    area: 31.2,
    Poverty: 3.86,
    county: "Santa Clara County",
    pincode: 94043,
    employment_earnings_byIndustry: [

    ],
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    populationGrowth: [{
      year: 1970,
      population: 54000.1,
      growthpercent: 75.20,
    },
    {
      year: 1980,
      population: 58000.7,
      growthpercent: 8.40,
    },
    {
      year: 1990,
      population: 67000.5,
      growthpercent: 15.00,
    },
    {
      year: 2000,
      population: 70000.7,
      growthpercent: 4.80,
    },
    {
      year: 2010,
      population: 74000.1,
      growthpercent: 4.70,
    },
    {
      year: 2020,
      population: 82000.4,
      growthpercent: 11.20,
    }
    ],
    age_group: {
      "Under 18": 6500,
      "18 - 29": 5400,
      "30 - 44": 9700,
      "45 - 59": 5500,
      "60+": 5500,
    },
    genderComp: {
      Total: 32700,
      Male: 16600,
      Female: 16100,
    },
    marital: {
      Married: 28300,
      Unmarried: 18800,
    },
    mediumIncom: 174416,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 3,
      },
      {
        name: "Previous Month Rate",
        value: 2.8,
      },
      {
        name: "6 Months Ago",
        value: 2,
      },
      {
        name: "1 Year Ago",
        value: 1.3,
      },
    ],
    costOfLiving: 144282,
    totalCostOfLiving: 144282,

    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 3.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income in Mountain View is $159,898, with an average income of $85,862 per year. The unemployment rate is 3.5%, and future job growth is predicted to be 40.5% over the next ten years.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 231, which is 131% higher than the U.S. average. Housing is a significant factor, with the median home cost being $1,445,400​​.",
    },
    {
      heading: "Crime Rate",
      content: "The crime rate includes a history of both property and violent crimes, with recent years showing lower rates of violent crime compared to earlier years​."
    },
    {
      heading: "Climate",

      content: "Mountain View has a mild climate with an average of 260 sunny days per year. The average July high temperature is 79.4°F, and the average January low is 41.1°F​."
    },
    {
      heading: "Housing",
      content: "Renting a two-bedroom apartment costs about $3,300 per month, which is higher than both the state and national averages​."
    },
    {
      heading: "Employment ",
      content: "Significant employment sectors include professional, scientific, and technical services (23.0%), information (14.8%), and manufacturing (12.7%)​."
    },],
    incomeDistributionOfHouseHolds: [{
      label: "<$10k",
      percent: 1.91,

    },
    {
      label: "$10k to $50k",
      percent: 689.21,
    },
    {
      label: "$50k to $100k",
      percent: 17.21,
    },
    {
      label: "$100k to $150k",
      percent: 12.65,
    },
    {
      label: "$150k to $200k",
      percent: 11.50,
    },
    {
      label: ">$200k",
      percent: 44.73,
    },
    ],
    rating: {
      homellcscore: 8.5,
      ranking1: {
        rank: 15,
        quote: "Best ZIP Code to Live in California"
      },
      ranking2: {
        rank: 27,
        quote: "Best ZIP Code to Live in SF Bay Area"
      },
      ranking3: {
        rank: 16,
        quote: "Best ZIP Code to Raise a Family in SF Bay Area"
      },
    }
  },
  94089: {
    cordinats: [-122.009, 37.4189],
    area_name: "Sunnyvale, CA",
    county: "Santa Clara County",
    about: "Sunnyvale is another key city in Silicon Valley, famous for its strong tech presence with companies like LinkedIn and Yahoo. It has a diverse community and offers excellent schools, a variety of dining options, and extensive parks and recreational areas. Sunnyvale also has a well-developed infrastructure, making it convenient for commuters.",
    populationValue: 23200,
    Poverty: 5.56,
    pincode: 94089,
    area: 8.8,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    populationGrowth: [{
      year: 1970,
      population: 59000.9,
      growthpercent: 509.40,
    },
    {
      year: 1980,
      population: 96000.0,
      growthpercent: 60.20,
    },
    {
      year: 1990,
      population: 106000.6,
      growthpercent: 11.10,
    },
    {
      year: 2000,
      population: 117000.2,
      growthpercent: 10.00,
    },
    {
      year: 2010,
      population: 131000.8,
      growthpercent: 12.40,
    },
    {
      year: 2020,
      population: 140000.1,
      growthpercent: 6.30,
    }
    ],
    age_group: {
      "Under 18": 3300,
      "18 - 29": 5200,
      "30 - 44": 6000,
      "45 - 59": 3800,
      "60+": 5200,
    },
    genderComp: {
      Total: 23400,
      Male: 12100,
      Female: 11400,
    },
    // marital: {
    //   Divorced: 1400,
    // Married: 21400,
    // Single: 7500,
    //   Other: 1200,
    //   Separated: 310,
    //   Widowed: 962,
    //   Total: 20600,
    // },
    marital: {
      Married: 21400,
      Unmarried: 7500,
    },
    mediumIncom: 149270,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 3.3,
      },
      {
        name: "Previous Month Rate",
        value: 3.4,
      },
      {
        name: "6 Months Ago",
        value: 3.4,
      },
      {
        name: "1 Year Ago",
        value: 2.8,
      },

    ],
    costOfLiving: 2704,
    totalCostOfLiving: 4800,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 3.30
    },
    highlights: [{
      heading: "Economy",
      content: "The unemployment rate is 2.7%, and future job growth is expected to be 39.0% over the next ten years.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 258, significantly higher than the U.S. average.",
    },
    {
      heading: "Crime Rate",
      content: "Sunnyvale has lower crime rates compared to the national average, particularly in violent crimes."
    },
    {
      heading: "Climate",
      content: "Sunnyvale enjoys 256 sunny days per year, with an average July high of 82°F and a January low of 39°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,606,800, and the average rent for a two-bedroom apartment is around $3,400 per month."
    },
    {
      heading: "Employment ",
      content: "Major employment sectors include technology and engineering due to the proximity to major tech companies."
    },],
    incomeDistributionOfHouseHolds: [{
      label: "<$10k",
      percent: 2.42,

    },
    {
      label: "$10k to $50k",
      percent: 28.09,
    },
    {
      label: "$50k to $100k",
      percent: 19.75,
    },
    {
      label: "$100k to $150k",
      percent: 18.03,
    },
    {
      label: "$150k to $200k",
      percent: 12.62,
    },
    {
      label: ">$200k",
      percent: 36.89,
    },
    ],
    rating: {
      homellcscore: 7.5,
      ranking1: {
        rank: 13,
        quote: "Best ZIP Code to Live in SF Bay Area"
      },
      ranking2: {
        rank: 23,
        quote: "Best ZIP Code for Young Professionals in California"
      },
      ranking3: {
        rank: 23,
        quote: "Best ZIP Code to Raise a Family in California"
      },
    }
  },
  94502: {
    cordinats: [-122.242081, 37.733341],
    area_name: "Alameda, CA",
    Poverty: 2.5,
    county: "Alameda County",
    about: "Alameda, located on an island in the San Francisco Bay, boasts a charming small-town feel with Victorian homes and tree-lined streets. The city is known for its historic architecture, excellent schools, and beautiful shoreline parks. Alameda's Webster Street and Park Street offer a variety of shops, restaurants, and entertainment options.",
    area: 12.6,
    pincode: 94502,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    populationValue: 14200,
    populationGrowth: [{
      year: 1970,
      population: 71000.0,
      growthpercent: 11.10,
    },
    {
      year: 1980,
      population: 63000.9,
      growthpercent: -10.0,
    },
    {
      year: 1990,
      population: 76000.5,
      growthpercent: 19.70,
    },
    {
      year: 2000,
      population: 72000.3,
      growthpercent: -5.5,
    },
    {
      year: 2010,
      population: 73000.8,
      growthpercent: 2.10,
    },
    {
      year: 2020,
      population: 78000.3,
      growthpercent: 6.10,
    }
    ],
    age_group: {
      "Under 18": 3100,
      "18 - 29": 1300,
      "30 - 44": 2900,
      "45 - 59": 2900,
      "60+": 4000,
    },
    genderComp: {
      Total: 14200,
      Male: 7300,
      Female: 6900,
    },
    // marital: {
    //   Divorced: 716,
    //   Married: 15100,
    //   Single: 3000,
    //   Other: 356,
    //   Separated: 100,
    //   Widowed: 451,
    //   Total: 11700,
    // },
    marital: {
      Married: 28300,
      Unmarried: 24800,
    },
    mediumIncom: 207823,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4.4,
      },
      {
        name: "Previous Month Rate",
        value: 4.7,
      },
      {
        name: "6 Months Ago",
        value: 4.5,
      },
      {
        name: "1 Year Ago",
        value: 3.5,
      },
    ],
    costOfLiving: 2923,
    totalCostOfLiving: 4100,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.40
    },
    highlights: [{
      heading: "Economy",
      content: "Alameda has a median household income of $108,407, with an unemployment rate of 4.2%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 201, indicating a higher cost of living compared to the national average.",
    },
    {
      heading: "Crime Rate",
      content: "Alameda experiences lower crime rates compared to national averages, especially in violent crimes."
    },
    {
      heading: "Climate",
      content: "The city experiences mild weather with an average of 265 sunny days per year."
    },
    {
      heading: "Housing",
      content: "The median home cost is $987,900, and the average rent for a two-bedroom apartment is about $3,100 per month."
    },
    {
      heading: "Employment ",
      content: "Major employment sectors include healthcare, education, and public administration."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 3.92,
      },
      {
        label: "$10k to $50k",
        percent: 25.22,
      },
      {
        label: "$50k to $100k",
        percent: 11.44,
      },
      {
        label: "$100k to $150k",
        percent: 15.04,
      },
      {
        label: "$150k to $200k",
        percent: 12.00,
      },
      {
        label: ">$200k",
        percent: 51.55,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 29,
        quote: "Best ZIP Code to Live in SF Bay Area"
      },
      ranking2: {
        rank: 45,
        quote: "Best ZIP Code to Raise a Family in California"
      },
      ranking3: {
        rank: 30,
        quote: "Best ZIP Code for Young Professionals in SF Bay Area"
      },
    }
  },
  94506: {
    cordinats: [-121.914619, 37.802311],
    area_name: "Danville, CA",
    Poverty: 5.53,
    county: "Contra Costa County",
    about: "Danville is an affluent suburban town known for its upscale living and family-friendly environment. It features top-rated schools, luxury homes, and a quaint downtown area with boutiques, cafes, and restaurants. The town is surrounded by picturesque hills and open spaces, making it ideal for outdoor activities like hiking and biking.",
    area: 46.9,
    pincode: 94506,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    populationValue: 26900,
    populationGrowth: [{
      year: 1970,
      population: 14000.1,
      growthpercent: 292.20,
    },
    {
      year: 1980,
      population: 26000.4,
      growthpercent: 88.10,
    },
    {
      year: 1990,
      population: 31000.3,
      growthpercent: 18.40,
    },
    {
      year: 2000,
      population: 41000.7,
      growthpercent: 33.20,
    },
    {
      year: 2010,
      population: 42000,
      growthpercent: 0.80,
    },
    {
      year: 2020,
      population: 43000.6,
      growthpercent: 3.70,
    }
    ],
    age_group: {
      "Under 18": 6600,
      "18 - 29": 2300,
      "30 - 44": 4100,
      "45 - 59": 6800,
      "60+": 7200,
    },
    genderComp: {
      Total: 26900,
      Male: 13300,
      Female: 13600,
    },
    // marital: {
    //   Divorced: 1200,
    //   Married: 30400,
    //   Single: 4500,
    //   Other: 572,
    //   Separated: 132,
    //   Widowed: 916,
    //   Total: 21800,
    // },
    marital: {
      Married: 18300,
      Unmarried: 28800,
    },
    mediumIncom: 246132,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.3,
      },
      {
        name: "6 Months Ago",
        value: 4.2,
      },
      {
        name: "1 Year Ago",
        value: 3.5,
      },
    ],
    costOfLiving: 4001,
    totalCostOfLiving: 8100,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $204,377, with an unemployment rate of 2.9%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 260, significantly higher than the national average.",
    },
    {
      heading: "Crime Rate",
      content: "Danville has very low crime rates, particularly in violent crimes."
    },
    {
      heading: "Climate",
      content: "Danville enjoys 262 sunny days per year, with an average July high of 88°F and a January low of 39°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,632,800, and the average rent for a two-bedroom apartment is around $3,600 per month."
    },
    {
      heading: "Employment ",
      content: "Key sectors include professional services, healthcare, and retail."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 2.86,
      },
      {
        label: "$10k to $50k",
        percent: 10.67,
      },
      {
        label: "$50k to $100k",
        percent: 8.08,
      },
      {
        label: "$100k to $150k",
        percent: 11.57,
      },
      {
        label: "$150k to $200k",
        percent: 12.60,
      },
      {
        label: ">$200k",
        percent: 57.40,
      },
    ],
    rating: {
      homellcscore: 8.5,
      ranking1: {
        rank: 16,
        quote: "Best ZIP Code to Live in California"
      },
      ranking2: {
        rank: 20,
        quote: "Best ZIP Code to Raise a Family in California"
      },
      ranking3: {
        rank: 21,
        quote: "Best ZIP Code for Public Schools in California"
      },
    }
  },
  94556: {
    cordinats: [-122.125271, 37.8509585],
    populationValue: 16000,
    Poverty: 7.25,
    area: 9.3,
    pincode: 94556,
    about: "Moraga is a serene, suburban town located in the East Bay. It is known for its strong sense of community, excellent schools, and beautiful natural surroundings. The town offers a quiet, rural atmosphere with easy access to hiking trails and parks. Saint Mary's College of California, a private liberal arts college, is also located here.",
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    area_name: "Moraga, CA",
    race: {
      "Not Hispanic or Latino": 14700,
      "Black or African American alone": 82,
      "Asian alone": 3500,
      "Some other race alone": 64,
      "Two races including Some other race": 81,
      "Hispanic or Latino": 1200,
      "Black or African American alone": 10,
      "Asian alone": 53,
      "Some other race alone": 220,
      "Two races including Some other race": 309,
    },
    county: "Contra Costa County",
    populationGrowth: [{
      year: 1970,
      population: 14000.2,
      growthpercent: 0,
    },
    {
      year: 1980,
      population: 15000,
      growthpercent: 5.70,
    },
    {
      year: 1990,
      population: 15000.9,
      growthpercent: 5.60,
    },
    {
      year: 2000,
      population: 16000.3,
      growthpercent: 2.80,
    },
    {
      year: 2010,
      population: 16000,
      growthpercent: -1.7,
    },
    {
      year: 2020,
      population: 16000.9,
      growthpercent: 5.30,
    }
    ],

    age_group: {
      "Under 18": 3500,
      "18 - 29": 2400,
      "30 - 44": 2300,
      "45 - 59": 3300,
      "60+": 4500,
    },
    genderComp: {
      Total: 16000,
      Male: 7700,
      Female: 8200,
    },
    // marital: {
    //   Divorced: 840,
    //   Married: 16000,
    //   Single: 3600,
    //   Other: 413,
    //   Separated: 43,
    //   Widowed: 669,
    //   Total: 13100,
    // },
    marital: {
      Married: 28300,
      Unmarried: 18800,
    },
    mediumIncom: 193750,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.3,
      },
      {
        name: "6 Months Ago",
        value: 4.2,
      },
      {
        name: "1 Year Ago",
        value: 3.5,
      },
    ],
    costOfLiving: 3219,
    totalCostOfLiving: 4800,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $141,792, with an unemployment rate of 3.1%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 246, well above the national average.",
    },
    {
      heading: "Crime Rate",
      content: "Moraga has low crime rates, with significantly lower violent crime rates."
    },
    {
      heading: "Climate",
      content: "Moraga enjoys 259 sunny days per year, with an average July high of 84°F and a January low of 40°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,310,600, and the average rent for a two-bedroom apartment is about $3,200 per month."
    },
    {
      heading: "Employment ",
      content: "Important sectors include education, healthcare, and professional services."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 2.63,
      },
      {
        label: "$10k to $50k",
        percent: 22.84,
      },
      {
        label: "$50k to $100k",
        percent: 12.71,
      },
      {
        label: "$100k to $150k",
        percent: 13.35,
      },
      {
        label: "$150k to $200k",
        percent: 11.54,
      },
      {
        label: ">$200k",
        percent: 49.11,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 13,
        quote: "Best ZIP Code to Live in California"
      },
      ranking2: {
        rank: 17,
        quote: "Best ZIP Code to Raise a Family in California"
      },
      ranking3: {
        rank: 25,
        quote: "Best ZIP Code for Public Schools in California"
      },
    }
  },
  94563: {
    cordinats: [-122.124679, 37.893179],
    populationValue: 19400,
    area_name: "Orinda, CA",
    about: "Orinda is a wealthy suburb known for its picturesque setting, top-notch schools, and luxurious homes. It offers a semi-rural atmosphere with plenty of parks and recreational areas, including the popular Briones Regional Park. The city's charming downtown area has a variety of dining and shopping options, and it hosts cultural events throughout the year.",
    Poverty: 2.08,
    pincode: 94563,
    area: 31.1,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    county: "Contra Costa County",
    populationGrowth: [{
      year: 1970,
      population: 6000.8,
      growthpercent: 21.90,
    },
    {
      year: 1980,
      population: 16000.8,
      growthpercent: 147.80,
    },
    {
      year: 1990,
      population: 16000.6,
      growthpercent: -1.1,
    },
    {
      year: 2000,
      population: 17000.6,
      growthpercent: 5.80,
    },
    {
      year: 2010,
      population: 17000.6,
      growthpercent: 0.30,
    },
    {
      year: 2020,
      population: 19000.5,
      growthpercent: 10.60,
    }
    ],

    age_group: {
      "Under 18": 4800,
      "18 - 29": 1200,
      "30 - 44": 2500,
      "45 - 59": 5200,
      "60+": 5700,
    },
    genderComp: {
      Total: 19400,
      Male: 9600,
      Female: 9800,
    },
    // marital: {
    //   Divorced: 915,
    //   Married: 20800,
    //   Single: 3400,
    //   Other: 155,
    //   Separated: 226,
    //   Widowed: 1000,
    //   Total: 15800,
    // },
    marital: {
      Married: 28300,
      Unmarried: 14800,
    },
    mediumIncom: 250001,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.3,
      },
      {
        name: "6 Months Ago",
        value: 4.2,
      },
      {
        name: "1 Year Ago",
        value: 3.5,
      },
    ],
    costOfLiving: 4001,
    totalCostOfLiving: 6900,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $187,106, with an unemployment rate of 2.8%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 274, indicating a very high cost of living.",
    },
    {
      heading: "Crime Rate",
      content: "Orinda has low crime rates, particularly in violent crimes."
    },
    {
      heading: "Climate",
      content: "Orinda experiences 255 sunny days per year, with an average July high of 85°F and a January low of 41°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,789,500, and the average rent for a two-bedroom apartment is around $3,800 per month."
    },
    {
      heading: "Employment ",
      content: "Key employment sectors include professional services, healthcare, and education."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 1.61,
      },
      {
        label: "$10k to $50k",
        percent: 17.39,
      },
      {
        label: "$50k to $100k",
        percent: 8.66,
      },
      {
        label: "$100k to $150k",
        percent: 11.43,
      },
      {
        label: "$150k to $200k",
        percent: 7.99,
      },
      {
        label: ">$200k",
        percent: 61.03,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 14,
        quote: "Best ZIP Codes to Live in California"
      },
      ranking2: {
        rank: 18,
        quote: "Best ZIP Codes to Raise a Family in California"
      },
      ranking3: {
        rank: 20,
        quote: "Best ZIP Codes for Public Schools in California"
      },
    }
  },
  94566: {
    cordinats: [-121.8964903, 37.6579477],
    Poverty: 4.36,
    populationValue: 45100,
    about: "Pleasanton is a vibrant city known for its excellent quality of life, top-rated schools, and strong community spirit. The historic downtown area is a hub of activity with restaurants, shops, and regular events like farmers' markets and street fairs. Pleasanton also offers beautiful parks and recreational facilities, making it ideal for families.",
    area: 58.9,
    pincode: 94566,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    area_name: "Pleasanton, CA",
    county: "Alameda County",
    populationGrowth: [{
      year: 1970,
      population: 18000.3,
      growthpercent: 336.10,
    },
    {
      year: 1980,
      population: 35000.2,
      growthpercent: 91.80,
    },
    {
      year: 1990,
      population: 50000.5,
      growthpercent: 43.70,
    },
    {
      year: 2000,
      population: 63000.7,
      growthpercent: 26.00,
    },
    {
      year: 2010,
      population: 70000.3,
      growthpercent: 10.40,

    },
    {
      year: 2020,
      population: 79000.9,
      growthpercent: 13.60,
    }
    ],
    age_group: {
      "Under 18": 10400,
      "18 - 29": 4600,
      "30 - 44": 8400,
      "45 - 59": 10800,
      "60+": 10900,
    },
    genderComp: {
      Total: 45100,
      Male: 22000,
      Female: 23100,
    },
    // marital: {
    //   Divorced: 2700,
    //   Married: 47800,
    //   Single: 8500,
    //   Other: 1100,
    //   Separated: 460,
    //   Widowed: 1800,
    //   Total: 36900,
    // },
    marital: {
      Married: 38300,
      Unmarried: 28800,
    },
    mediumIncom: 187357,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.3,
      },
      {
        name: "6 Months Ago",
        value: 4.2,
      },
      {
        name: "1 Year Ago",
        value: 3.5,
      },
    ],
    costOfLiving: 3149,
    totalCostOfLiving: 11500,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $156,400, with an unemployment rate of 3.0%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 230, significantly higher than the national average.",
    },
    {
      heading: "Crime Rate",
      content: "Pleasanton has relatively low crime rates, especially in violent crimes."
    },
    {
      heading: "Climate",
      content: "Pleasanton enjoys 261 sunny days per year, with an average July high of 89°F and a January low of 39°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,344,000, and the average rent for a two-bedroom apartment is about $3,500 per month."
    },
    {
      heading: "Employment ",
      content: "Major employment sectors include technology, healthcare, and retail."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 1.93,
      },
      {
        label: "$10k to $50k",
        percent: 17.00,
      },
      {
        label: "$50k to $100k",
        percent: 13.71,
      },
      {
        label: "$100k to $150k",
        percent: 11.30,
      },
      {
        label: "$150k to $200k",
        percent: 14.52,
      },
      {
        label: ">$200k",
        percent: 45.85,
      },
    ],
    rating: {
      homellcscore: 8.5,
      ranking1: {
        rank: 24,
        quote: "Best ZIP Code to Live in SF Bay Area"
      },
      ranking2: {
        rank: 27,
        quote: "Best ZIP Code to Raise a Family in California"
      },
      ranking3: {
        rank: 32,
        quote: "Best ZIP Code for Young Professionals in California"
      },
    }
  },
  94568: {
    cordinats: [-121.91017, 37.708751],
    populationValue: 71100,
    area_name: "Dublin, CA",
    about: "Dublin is a rapidly growing city known for its excellent schools, modern amenities, and family-friendly environment. It features a mix of residential, commercial, and industrial areas, with a variety of shopping centers, restaurants, and parks. Dublin's convenient location near major highways and BART stations makes it a popular choice for commuters.",
    Poverty: 3.91,
    area: 40.9,
    pincode: 94568,
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    county: "Alameda County",
    populationGrowth: [{
      year: 1970,
      population: 13000.6,
      growthpercent: 0,
    },
    {
      year: 1980,
      population: 13000.5,
      growthpercent: -1.1,
    },
    {
      year: 1990,
      population: 23000.2,
      growthpercent: 72.10,
    },
    {
      year: 2000,
      population: 30000.0,
      growthpercent: 29.00,
    },
    {
      year: 2010,
      population: 46000,
      growthpercent: 53.60,

    },
    {
      year: 2020,
      population: 72000.6,
      growthpercent: 57.70,
    }
    ],
    age_group: {
      "Under 18": 18500,
      "18 - 29": 8400,
      "30 - 44": 20700,
      "45 - 59": 13700,
      "60+": 9700,
    },
    genderComp: {
      Total: 71100,
      Male: 35600,
      Female: 35500,
    },
    // marital: {
    //   Divorced: 4300,
    //   Married: 69000,
    //   Single: 14400,
    //   Other: 1400,
    //   Separated: 579,
    //   Widowed: 1900,
    //   Total: 55100,
    // },
    marital: {
      Married: 32300,
      Unmarried: 28800,
    },
    mediumIncom: 191039,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4.3,
      },
      {
        name: "Previous Month Rate",
        value: 4.5,
      },
      {
        name: "6 Months Ago",
        value: 4.4,
      },
      {
        name: "1 Year Ago",
        value: 3.4,
      },
    ],
    costOfLiving: 3520,
    totalCostOfLiving: 15200,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.30
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $151,283, with an unemployment rate of 2.9%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 236, indicating a high cost of living.",
    },
    {
      heading: "Crime Rate",
      content: "Dublin has lower crime rates compared to national averages."
    },
    {
      heading: "Climate",
      content: "Dublin experiences 260 sunny days per year, with an average July high of 88°F and a January low of 38°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,229,800, and the average rent for a two-bedroom apartment is around $3,300 per month."
    },
    {
      heading: "Employment ",
      content: "Significant sectors include technology, education, and healthcare."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 2.36,
      },
      {
        label: "$10k to $50k",
        percent: 15.38,
      },
      {
        label: "$50k to $100k",
        percent: 14.10,
      },
      {
        label: "$100k to $150k",
        percent: 15.13,
      },
      {
        label: "$150k to $200k",
        percent: 13.52,
      },
      {
        label: ">$200k",
        percent: 48.16,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 19,
        quote: "Best ZIP Codes to Live in California"
      },
      ranking2: {
        rank: 22,
        quote: "Best ZIP Codes to Raise a Family in California"
      },
      ranking3: {
        rank: 31,
        quote: "Best ZIP Codes for Public Schools in California"
      },
    }
  },
  94588: {
    cordinats: [-121.8976075, 37.7028431],
    Poverty: 6.32,
    area: 18.2,
    about: "Similar to the other Pleasanton zip code, 94588 shares the same high quality of life with access to top schools, beautiful parks, and a lively downtown area. The city is known for its well-planned neighborhoods and community-oriented events, offering a safe and welcoming environment for residents.",
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    pincode: 94588,
    area_name: "Pleasanton, CA",
    county: "Alameda County",
    populationValue: 35200,
    populationGrowth: [{
      year: 1970,
      population: 18000.3,
      growthpercent: 336.10,
    },
    {
      year: 1980,
      population: 35000.2,
      growthpercent: 91.80,
    },
    {
      year: 1990,
      population: 50000.5,
      growthpercent: 43.70,
    },
    {
      year: 2000,
      population: 63000.7,
      growthpercent: 26.00,
    },
    {
      year: 2010,
      population: 70000.3,
      growthpercent: 10.40,

    },
    {
      year: 2020,
      population: 79000.9,
      growthpercent: 13.60,
    }
    ],
    age_group: {
      "Under 18": 8400,
      "18 - 29": 3900,
      "30 - 44": 8600,
      "45 - 59": 7200,
      "60+": 7100,
    },
    genderComp: {
      Total: 35200,
      Male: 17600,
      Female: 17500,
    },
    // marital: {
    //   Divorced: 1600,
    //   Married: 36700,
    //   Single: 7300,
    //   Other: 816,
    //   Separated: 409,
    //   Widowed: 1100,
    //   Total: 28300,
    // },
    marital: {
      Married: 28300,
      Unmarried: 18800,
    },
    mediumIncom: 177016,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.3,
      },
      {
        name: "6 Months Ago",
        value: 4.2,
      },
      {
        name: "1 Year Ago",
        value: 3.3,
      },
    ],
    costOfLiving: 3191,
    totalCostOfLiving: 8000,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 4.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $156,400, with an unemployment rate of 3.0%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 230, which is high.",
    },
    {
      heading: "Crime Rate",
      content: "The crime rates are low, particularly in violent crimes."
    },
    {
      heading: "Climate",
      content: "Pleasanton enjoys 261 sunny days per year, with mild temperatures."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,344,000, and the average rent is about $3,500 per month."
    },
    {
      heading: "Employment ",
      content: "Key sectors include technology, healthcare, and professional services."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 2.03,
      },
      {
        label: "$10k to $50k",
        percent: 20.76,
      },
      {
        label: "$50k to $100k",
        percent: 15.24,
      },
      {
        label: "$100k to $150k",
        percent: 13.94,
      },
      {
        label: "$150k to $200k",
        percent: 16.81,
      },
      {
        label: ">$200k",
        percent: 44.14,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 28,
        quote: "Best ZIP Codes to Live in SF Bay Area"
      },
      ranking2: {
        rank: 35,
        quote: "Best ZIP Codes to Raise a Family in California"
      },
      ranking3: {
        rank: 34,
        quote: "Best ZIP Codes for Young Professionals in California"
      },
    }
  },
  95134: {
    cordinats: [-121.9667364, 37.4236222],
    area_name: "San Jose, CA",
    county: "Santa Clara County",
    Poverty: 6.06,
    area: 14.2,
    pincode: 95134,
    about: "an Jose, the largest city in Silicon Valley, is a major tech hub with a diverse population and vibrant cultural scene. The 95134 area, located in the North San Jose Innovation District, is home to many tech companies and offers modern residential developments. The area features various amenities, including parks, shopping centers, and easy access to public transportation.",
    race: {
      White: 18200,
      Black: 11000,
      Native: 2000,
      Asian: 1019,
      Islander: 300,
      Other: 8500,
      Two: 2000,
      Hispanic: 4920,
    },
    populationValue: 30900,
    populationGrowth: [{
      year: 1970,
      population: 459000.9,
      growthpercent: 125.20,
    },
    {
      year: 1980,
      population: 629000.4,
      growthpercent: 36.90,
    },
    {
      year: 1990,
      population: 782000.2,
      growthpercent: 24.30,
    },
    {
      year: 2000,
      population: 894000.9,
      growthpercent: 14.40,
    },
    {
      year: 2010,
      population: 945000.9,
      growthpercent: 5.70,

    },
    {
      year: 2020,
      population: 1000000.01,
      growthpercent: 7.10,
    }
    ],
    age_group: {
      "Under 18": 4400,
      "18 - 29": 9800,
      "30 - 44": 11300,
      "45 - 59": 3500,
      "60+": 1900,
    },
    genderComp: {
      Total: 30900,
      Male: 16600,
      Female: 14300,
    },
    // marital: {
    //   Divorced: 1400,
    //   Married: 29600,
    //   Single: 10500,
    //   Other: 1700,
    //   Separated: 293,
    //   Widowed: 387,
    //   Total: 27000,
    // },
    marital: {
      Married: 21300,
      Unmarried: 28800,
    },
    mediumIncom: 182920,
    mediumIncomGraph: [
      {
        name: "Current Rate",
        value: 4,
      },
      {
        name: "Previous Month Rate",
        value: 4.2,
      },
      {
        name: "6 Months Ago",
        value: 4.1,
      },
      {
        name: "1 Year Ago",
        value: 3.2,
      },
    ],
    costOfLiving: 3202,
    totalCostOfLiving: 1800,
    constofLivingData: [
      { label: "Groceries & Food", value: 9105 },
      { label: "Utilities", value: 2904 },
      { label: "Transport", value: 16967 },
      { label: "Healthcare", value: 19565 },
      { label: "Mortgage", value: 19316 },
      { label: "Other Housing", value: 48640 },
      { label: "Entertainment", value: 4070 },
      { label: "Miscellaneous", value: 23715 },
    ],
    unemployementRate: {
      currentRate: 3.00
    },
    highlights: [{
      heading: "Economy",
      content: "The median household income is $124,696, with an unemployment rate of 3.3%.",
    },
    {
      heading: "Cost Of living",
      content: "The cost of living index is 222, which is significantly higher than the national average.",
    },
    {
      heading: "Crime Rate",
      content: "San Jose has moderate crime rates, with property crimes being more common than violent crimes."
    },
    {
      heading: "Climate",
      content: "San Jose experiences 257 sunny days per year, with an average July high of 82°F and a January low of 42°F."
    },
    {
      heading: "Housing",
      content: "The median home cost is $1,238,900, and the average rent for a two-bedroom apartment is around $3,200 per month."
    },
    {
      heading: "Employment ",
      content: "Major sectors include technology, manufacturing, and professional services."
    },],
    incomeDistributionOfHouseHolds: [
      {
        label: "<$10k",
        percent: 2.45,
      },
      {
        label: "$10k to $50k",
        percent: 13.61,
      },
      {
        label: "$50k to $100k",
        percent: 9.80,
      },
      {
        label: "$100k to $150k",
        percent: 17.07,
      },
      {
        label: "$150k to $200k",
        percent: 18.83,
      },
      {
        label: ">$200k",
        percent: 45.26,
      },
    ],
    rating: {
      homellcscore: 8,
      ranking1: {
        rank: 16,
        quote: "Best ZIP Code to Live in SF Bay Area"
      },
      ranking2: {
        rank: 25,
        quote: "Best ZIP Code to Raise a Family in California"
      },
      ranking3: {
        rank: 27,
        quote: "Best ZIP Code for Young Professionals in California"
      },
    }
  },

};

export const IndustryData=[
  {
    zip_code: "94043",
    industry: [
      {
        label: "management",
        value: 2537,
        earnings: 154904
      },
      {
        label: "utilities",
        value: 28,
        earnings: 143654
      },
      {
        label: "manufacturing",
        value: 1353,
        earnings: 137512
      },
      {
        label: "finance and insurance",
        value: 178,
        earnings: 138047
      },
      {
        label: "rental and leasing",
        value: 190,
        earnings: 67573
      },
    ],
  },
  {
    zip_code: "94089",
    industry: [
      {
        label: "management",
        value: 2014,
        earnings: 120814

      },
      {
        label: "utilities",
        value: 42,
        earnings: 67573

      },
      {
        label: "manufacturing",
        value: 1240,
        earnings: 108438

      },
      {
        label: "finance and insurance",
        value: 214,
        earnings: 85563

      },
      {
        label: "rental and leasing",
        value: 79,
        earnings: 48460

      },
    ],
  },
  {
    zip_code: "94502",
    industry: [
      {
        label: "management",
        value: 908,
        earnings: 59331
      },
      {
        label: "utilities",
        value: 42,
        earnings: 160962
      },
      {
        label: "manufacturing",
        value: 1240,
        earnings: 116603

      },
      {
        label: "finance and insurance",
        value: 214,
        earnings: 127032

      },
      {
        label: "rental and leasing",
        value: 79,
        earnings: 59331
      },
    ],
  },
  {
    zip_code: "94506",
    industry: [
      {
        label: "management",
        value: 1426,
        earnings: 148390
      },
      {
        label: "utilities",
        value: 44,
        earnings: 132303
      },
      {
        label: "manufacturing",
        value: 636,
        earnings: 208479
      },
      {
        label: "finance and insurance",
        value: 460,
        earnings: 196443
      },
      {
        label: "rental and leasing",
        value: 267,
        earnings: 97618
      },
    ],
  },
  {
    zip_code: "94556",
    industry: [
      {
        label: "management",
        value: 880,
        earnings: 184377
      },
      {
        label: "utilities",
        value: 21,
        earnings: 116288
      },
      {
        label: "manufacturing",
        value: 229,
        earnings: 149107
      },
      {
        label: "finance and insurance",
        value: 352,
        earnings: 209376
      },
      {
        label: "rental and leasing",
        value: 127,
        earnings: 101007
      },
    ],
  },
  {
    zip_code: "94563",
    industry: [
      {
        label: "management",
        value: 1405,
        earnings: 188751
      },
      {
        label: "utilities",
        value: 41,
        earnings: 208657
      },
      {
        label: "manufacturing",
        value: 279,
        earnings: 213505
      },
      {
        label: "finance and insurance",
        value: 445,
        earnings: 213048
      },
      {
        label: "rental and leasing",
        value: 184,
        earnings: 174711
      },
    ],
  },
  {
    zip_code: "94566",
    industry: [
      {
        label: "management",
        value: 2882,
        earnings: 149449
      },
      {
        label: "utilities",
        value: 100,
        earnings: 143849
      },
      {
        label: "manufacturing",
        value: 1481,
        earnings: 121314
      },
      {
        label: "finance and insurance",
        value: 589,
        earnings: 185327
      },
      {
        label: "rental and leasing",
        value: 221,
        earnings: 93542
      },
    ],
  },
  {
    zip_code: "94568",
    industry: [
      {
        label: "management",
        value: 5351,
        earnings: 134873
      },
      {
        label: "utilities",
        value: 150,
        earnings: 156090
      },
      {
        label: "manufacturing",
        value: 1686,
        earnings: 143142
      },
      {
        label: "finance and insurance",
        value: 1074,
        earnings: 151890
      },
      {
        label: "rental and leasing",
        value: 269,
        earnings: 84054
      },
    ],
  },
  {
    zip_code: "94588",
    industry: [
      {
        label: "management",
        value: 2185,
        earnings: 136689
      },
      {
        label: "utilities",
        value: 90,
        earnings: 137873
      },
      {
        label: "manufacturing",
        value: 1045,
        earnings: 150720
      },
      {
        label: "finance and insurance",
        value: 468,
        earnings: 193741
      },
      {
        label: "rental and leasing",
        value: 238,
        earnings: 62862
      },
    ],
  },
  {
    zip_code: "95134",
    industry: [
      {
        label: "management",
        value: 3934,
        earnings: 130591
      },
      {
        label: "utilities",
        value: 54,
        earnings: 117873
      },
      {
        label: "manufacturing",
        value: 2536,
        earnings: 125692
      },
      {
        label: "finance and insurance",
        value: 324,
        earnings: 116393
      },
      {
        label: "rental and leasing",
        value: 65,
        earnings: 52279
      },
    ],
  },
];

export const OcupationData=[
  {
    zip_code: "94043",
    occupation: [
      {
        label: "Sales",
        value: 1149,
        earnings: 56373
      },
      {
        label: "Computer & Maths",
        value: 2244,
        earnings: 188370
      },
      {
        label: "Legal",
        value: 93,
        earnings: 208618
      },
      {
        label: "Engineering & Science",
        value: 3348,
        earnings: 176049
      },
      {
        label: "Management",
        value: 1496,
        earnings: 172217
      },
    ],
  },
  {
    zip_code: "94089",
    occupation: [
      {
        label: "Sales",
        value: 1182,
        earnings: 50864
      },
      {
        label: "Computer & Maths",
        value: 1619,
        earnings: 166589
      },
      {
        label: "Legal",
        value: 37,
        earnings: 81691
      },
      {
        label: "Engineering & Science",
        value: 2326,
        earnings: 152261
      },
      {
        label: "Management",
        value: 700,
        earnings: 107324
      },
    ],
  },
  {
    zip_code: "94502",
    occupation: [
      {
        label: "Sales",
        value: 572,
        earnings: 86250
      },
      {
        label: "Computer & Maths",
        value: 143,
        earnings: 148558
      },
      {
        label: "Legal",
        value: 109,
        earnings: 171981
      },
      {
        label: "Engineering & Science",
        value: 534,
        earnings: 130083
      },
      {
        label: "Management",
        value: 820,
        earnings: 142424
      },
    ],
  },
  {
    zip_code: "94506",
    occupation: [
      {
        label: "Sales",
        value: 1038,
        earnings: 116900
      },
      {
        label: "Computer & Maths",
        value: 532,
        earnings: 159375
      },
      {
        label: "Legal",
        value: 98,
        earnings: 165583
      },
      {
        label: "Engineering & Science",
        value: 816,
        earnings: 153292
      },
      {
        label: "Management",
        value: 1873,
        earnings: 211115
      },
    ],
  },
  {
    zip_code: "94556",
    occupation: [
      {
        label: "Sales",
        value: 604,
        earnings: 61433
      },
      {
        label: "Computer & Maths",
        value: 174,
        earnings: 168472
      },
      {
        label: "Legal",
        value: 153,
        earnings: 226063
      },
      {
        label: "Engineering & Science",
        value: 421,
        earnings: 153906
      },
      {
        label: "Management",
        value: 755,
        earnings: 195500
      },
    ],
  },
  {
    zip_code: "94563",
    occupation: [
      {
        label: "Sales",
        value: 446,
        earnings: 130714
      },
      {
        label: "Computer & Maths",
        value: 220,
        earnings: 156115
      },
      {
        label: "Legal",
        value: 323,
        earnings: 250001
      },
      {
        label: "Engineering & Science",
        value: 578,
        earnings: 169861
      },
      {
        label: "Management",
        value: 1188,
        earnings: 250001
      },
    ],
  },
  {
    zip_code: "94566",
    occupation: [
      {
        label: "Sales",
        value: 1991,
        earnings: 59350
      },
      {
        label: "Computer & Maths",
        value: 1243,
        earnings: 176319
      },
      {
        label: "Legal",
        value: 159,
        earnings: 140313
      },
      {
        label: "Engineering & Science",
        value: 2270,
        earnings: 158829
      },
      {
        label: "Management",
        value: 2522,
        earnings: 182675
      },
    ],
  },
  {
    zip_code: "94568",
    occupation: [
      {
        label: "Sales",
        value: 2773,
        earnings: 59002
      },
      {
        label: "Computer & Maths",
        value: 3589,
        earnings: 167440
      },
      {
        label: "Legal",
        value: 197,
        earnings: 110114
      },
      {
        label: "Engineering & Science",
        value: 5122,
        earnings: 156127
      },
      {
        label: "Management",
        value: 3173,
        earnings: 169325
      },
    ],
  },
  {
    zip_code: "94588",
    occupation: [
      {
        label: "Sales",
        value: 1587,
        earnings: 56508
      },
      {
        label: "Computer & Maths",
        value: 1223,
        earnings: 161261
      },
      {
        label: "Legal",
        value: 109,
        earnings: 182583
      },
      {
        label: "Engineering & Science",
        value: 2136,
        earnings: 157273
      },
      {
        label: "Management",
        value: 1549,
        earnings: 156277
      },
    ],
  },
  {
    zip_code: "95134",
    occupation: [
      {
        label: "Sales",
        value: 983,
        earnings: 44526
      },
      {
        label: "Computer & Maths",
        value: 3927,
        earnings: 147782
      },
      {
        label: "Legal",
        value: 41,
        earnings: 108698
      },
      {
        label: "Engineering & Science",
        value: 5985,
        earnings: 144368
      },
      {
        label: "Management",
        value: 969,
        earnings: 137206
      },
    ],
  },
];
