import React from 'react'
import investors from '../../assets/invest/investors-img.png'

const PrefersUs = () => {
  return (
   <div className='w-full h-full py-[50px] sm:py-[90px]'>
    <h2 className='text-2xl leading-[36px] sm:text-5xl sm:leading-[72px] font-semibold text-center capitalize mb-[78px]'>why investors <span className='text-[#ED5272]'> prefer us </span>over competitors</h2>
    
    <div className='px-4 sm:px-[50px] 2xl:px-[100px] flex flex-col sm:flex-row justify-center  gap-[50px] 2xl:gap-[80px]'>
     <div className='h-full w-full sm:w-[943px] sm:h-[564px]'>
       <img src={investors} alt="investors" className='rounded-lg' />
     </div>
     <div className='w-[259px] mx-auto sm:mx-0 sm:w-[409px] sm:h-[522px] my-2 2xl:my-[20px] flex flex-col items-center justify-center sm:items-start sm:justify-start gap-8'>
      <div className='border-b border-[#9D9D9D] pb-4'>
       <h4 className='text-[16px] leading-[23.2px] sm:text-[26px] sm:leading-[38px] font-semibold mb-0.5 text-center sm:text-left'>Cheaper</h4>
       <p className='font-light text-sm sm:text-[22px] sm:leading-[32.8px] capitalize text-center sm:text-left font-quickSand'>Save on fees due to our global workforce model</p>
      </div>
      <div className='border-b border-[#9D9D9D] pb-4'>
       <h4 className='text-[16px] leading-[23.2px] sm:text-[26px] sm:leading-[38px] font-semibold mb-0.5 text-center sm:text-left'>Better</h4>
       <p className='font-light text-sm sm:text-[22px] sm:leading-[32.8px] capitalize text-center sm:text-left font-quickSand'>Invest in better homes using the best-in-class home analytics</p>
      </div>
      <div className='pb-4'>
       <h4 className='text-[16px] leading-[23.2px] sm:text-[26px] sm:leading-[38px] font-semibold mb-0.5 text-center sm:text-left'>Faster</h4>
       <p className='font-light text-sm sm:text-[22px] sm:leading-[32.8px] capitalize text-center sm:text-left font-quickSand'>Scale from $0 to $50M per month as quickly as you’d like</p>
      </div>
     </div>
    </div>
    </div>
  )
}

export default PrefersUs