import React from "react";
import Winter from "../../../../assets/HomeAi/Winter.svg";
import Spring from "../../../../assets/HomeAi/Spring.svg";
import Summer from "../../../../assets/HomeAi/Summer.svg";
import Fall from "../../../../assets/HomeAi/Fall.svg";
const data=[
  {
    icon: Spring,
    name: "Spring",
    conditions: "Raining times Extremely Windy",

    comfortScore: 8,
  },
  {
    icon: Summer,
    name: "Summer",
    conditions: "Hot and humid,Wind Storms ",
    comfortScore: 6,
  },
  {
    icon: Fall,
    name: "Fall",
    conditions: "Sunny, Cool and dry",
    comfortScore: 6,
  },
  {
    icon: Winter,
    name: "Winter",
    conditions: "Occasional snow, Ice storms ",
    comfortScore: 6,
  },
];
const ComfortRating=() => {
  return (
    <div className="flex flex-col gap-5">
      <div className="text-xl font-medium ml-5">Seasons Comfort Rating</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 px-5">
        {data.map((item) => (
          <div 
          key={item.name} 
          className={`flex items-center flex-col gap-2 ${
            item.name === "Spring"
              ? "order-1"
              : item.name === "Summer"
              ? "order-2"
              : "order-3"
          }`}>
            <img src={item.icon} alt="bus" className="w-9 h-9" />
            <div className="flex items-center flex-col gap-2">
              <div className="font-semibold text-md ">{item.name}</div>
              <div className=" text-md text-center h-14">
                {item.conditions}
              </div>
              <div className="">
                <span className="text-[50px] font-semibold">
                  {item.comfortScore}
                </span>
                /<span className="text-md">10</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComfortRating;
