// export const faqsDataHome = [
//   {
//     id: 1,
//     question: "Who are we?",
//     answer:"Established in 2019, Home.LLC is dedicated to addressing challenges in the housing market. Our mission is to alleviate the home ownership crisis in America by streamlining the process of buying, owning, and selling homes for greater efficiency and effectiveness.",
//        category: "owner",
//   },
//   {
//     id: 2,
//     question: "Why work with us?",
//     answer:"We're real people delivering practical solutions. With a dedicated, responsive relationship manager by your side, we'll address your inquiries promptly at every step. Our advice is clear, helpful, and personalized, allowing you to make informed decisions that suit your family's unique needs, whether it's buying your first home or accessing equity from your primary residence. Count on us for no sales tactics, no pressure, and absolutely no judgment—we're genuinely committed to your best interests",
//       category: "owner",
//   },
//   {
//     id: 3,
//     question: "What are Home Equity Investments (HEI)?",
//     answer: "Home equity investments offer homeowners debt-free cash in return for a share of the home's future value. This funding can be used for various purposes, such as debt repayment or essential renovations, with no interest or monthly payments required. We prioritize simplicity and clarity to ease the stress of homeownership.",
//     category: "owner",
//   },
//   {
//     id: 4,
//     question:
//       "How can Home Agent Help with buying and selling properties nationwide?",
//     answer:"Of course. Our services extend seamlessly across the entire country, ensuring that no matter where you're located, we're ready to assist you with your home-related needs. Whether you're situated in a bustling city or a remote town, our personalized and prompt service remains consistent. We take pride in catering to a diverse range of home requirements, whether you're seeking a small apartment or a sprawling estate. Rest assured, regardless of the size or complexity of your needs, we're fully committed to supporting you every step of the way.",
//     category: "owner",
//   },
//   {
//     id: 5,
//     question: "What makes Home Analytics a trustworthy source for housing market insights?",
//     answer:"The answer is straightforward. Home Analytics has been hailed as `The most accurate housing forecaster in America` by numerous reputable publications. Our market predictions have consistently outperformed those of industry leaders, allowing us to accurately anticipate and respond to market trends earlier than many others.",
//     category: "owner",
//   },
 
 
// ];

export const faqDataBuy = [
  {
    id: 1,
    question: "What services does Home.LLC provide?",
    answer: "Home.LLC specializes in providing analytical data for clients in the US mortgage market. We offer insights and information to help home buyers make informed decisions.",
    category: "buy",
  },
  {
    id: 2,
    question: "How does Home.LLC differ from other property agents?",
    answer: "Unlike traditional property agents who charge a standard 3% commission, Home.LLC charges only a 1% commission for our services. This significantly reduces costs for our clients without compromising on the quality of our service.",
    category: "buy",
  },
  {
    id: 3,
    question: "What kind of analytical data does Home.LLC provide?",
    answer: "We offer a comprehensive range of analytical data, including market trends, mortgage rates, property valuations, neighborhood insights, and more. Our goal is to empower our clients with the information they need to make confident decisions in the real estate market.",
    category: "buy",
  },
  {
    id: 4,
    question: "How can I access Home.LLC's analytical data?",
    answer: "Our team of experts is available to provide personalized insights and assistance as needed.",
    category: "buy",
  },
  {
    id: 5,
    question: "Is Home.LLC only available for clients in specific regions?",
    answer: "No, Home.LLC serves clients across the United States. Whether you're buying a home in New York City or Los Angeles, our analytical data and services are available to you.",
    category: "buy",
  },
  {
    id: 6,
    question: "Can Home.LLC assist with mortgage applications?",
    answer: "While we don't directly handle mortgage applications, we provide valuable insights and information that can help you navigate the mortgage process more effectively. We can also connect you with trusted partners in the industry who can assist with mortgage applications and approvals.",
    category: "buy",
  },
  {
    id: 7,
    question: "What sets Home.LLC apart in the mortgage industry?",
    answer: "Home.LLC stands out for its commitment to transparency, affordability, and client satisfaction. We believe in providing high-quality services at a fair price, and our 1% commission structure reflects that commitment.",
    category: "buy",
  },
  {
    id: 8,
    question: "How can I get started with Home.LLC's services?",
    answer: "Getting started with Home.LLC is easy! Simply reach out to us through our website or contact our team directly. We'll schedule a consultation to discuss your needs and how we can best assist you in achieving your home buying goals.",
    category: "buy",
  },
  {
    id: 9,
    question: "How can I contact Home.LLC for further inquiries?",
    answer: "You can reach us through our website's contact form, via email at support@home.llc, or by calling our toll-free number at 415-407-0976. Our team is standing by to assist you with any questions or concerns you may have.",
    category: "buy",
  },
];


export const faqDataSell = [
  {
    id: 1,
    question: "What services does Home.LLC offer for home sellers?",
    answer: "Home.LLC provides comprehensive services for home sellers, including market analysis, pricing guidance, and assistance throughout the selling process.",
    category: "sell",
  },
  {
    id: 2,
    question: "How does Home.LLC's approach benefit home sellers?",
    answer: "Unlike traditional real estate agents, Home.LLC offers competitive commission packages, reducing selling costs while providing high-quality service and expertise.",
    category: "sell",
  },
  {
    id: 3,
    question: "Does Home.LLC only work with sellers in certain areas?",
    answer: "No, Home.LLC serves home sellers across the United States. Whether you're selling a property in a bustling city or a suburban neighborhood, our services are available to help you achieve your selling goals.",
    category: "sell",
  },
  {
    id: 4,
    question: "How can I trust Home.LLC to sell my home effectively?",
    answer: "Home.LLC is committed to transparency, professionalism, and client satisfaction. Our experienced team will guide you through every step of the selling process, providing expert advice and support to ensure a smooth and successful sale.",
    category: "sell",
  },
  {
    id: 5,
    question: "Can Home.LLC help me determine the best listing price for my home?",
    answer: "Yes, Home.LLC offers comprehensive market analysis to help sellers determine the optimal listing price for their properties. We'll take into account market trends, comparable sales, and other factors to ensure your home is competitively priced.",
    category: "sell",
  },
  {
    id: 6,
    question: "How can I get started selling my home with Home.LLC?",
    answer: "Getting started is simple! Schedule a call with us here [include a link or contact method].",
    category: "sell",
  }
];

export const faqDataBorrow = [
  {
    id: 1,
    question: "What is a Home Equity Investment?",
    answer: "A Home Equity Investment by HOME.LLC is a smart financial solution enabling homeowners to access their home’s equity without taking on additional debt. With a HOME.LLC Investment, homeowners receive cash in exchange for a share of their home’s future value. This investment doesn't involve interest, monthly payments, or usage restrictions for up to 30 years.",
    category: "borrow",
  },
  {
    id: 2,
    question: "What does HOME.LLC do?",
    answer: "HOME.LLC is a fintech company dedicated to easing the burden of homeownership and enhancing accessibility through its three main products: HOME Equity Investment, HOME Agent, and HOME Analytics. The HOME Equity Investment offers homeowners a quick, straightforward way to access their home equity without loans or selling their homes. By partnering with homeowners, HOME.LLC provides debt-free cash in exchange for a share of their home’s future value, without any monthly payments or interest over the investment's lifespan.",
    category: "borrow",
  },
  {
    id: 3,
    question: "How does a HOME.LLC Investment differ from a loan?",
    answer: "A HOME.LLC Investment involves HOME.LLC investing in people’s homes without functioning as a lender. Unlike traditional lenders, HOME.LLC doesn't receive monthly payments or a guaranteed return on the invested money. Homeowners don't make monthly or interest payments during the investment period, and there's no guaranteed return for HOME.LLC. Opting for a home equity investment can be a prudent way to finance opportunities and needs in life without the stress of increased monthly payments.",
    category: "borrow",
  },
  {
    id: 4,
    question: "How long do I have to settle a HOME.LLC Investment?",
    answer: "The effective period of a HOME.LLC Investment extends up to 30 years. Settlement can occur by purchasing out the Investment with savings, securing a home equity or other loan, or selling the house within this timeframe.",
    category: "borrow",
  },
  {
    id: 5,
    question: "If HOME.LLC doesn't require payments, how does it earn money?",
    answer: "HOME.LLC earns through the 'HOME.LLC Share,' a percentage of the home's value obtained when the homeowner sells, refinances, or buys out the investment by the end of the effective period. Higher home values result in higher returns for HOME.LLC.",
    category: "borrow",
  },
  {
    id: 6,
    question: "How quickly can HOME.LLC make an Investment?",
    answer: "Upon completing the Application, HOME.LLC typically determines the home market value, finalizes the offer, structures the Investment, and signs within two to three weeks. However, due to increased homeowner interest, this process may take slightly longer.",
    category: "borrow",
  },
  {
    id: 7,
    question: "When might HOME.LLC not be the ideal choice?",
    answer: "The suitability of HOME.LLC varies based on individual homeowners' goals and circumstances. If the loan-to-value ratio is over 75% (indicating less than 25% equity), or if settlement options don't align with the homeowner's plans, HOME.LLC might not be the best fit.",
    category: "borrow",
  },
  {
    id: 8,
    question: "Who is eligible for a HOME.LLC Home Equity Investment?",
    answer: (
      <>
        <p className="mb-4">Determining eligibility for a HOME.LLC Investment involves various factors unique to each property.</p>
        <ul className="list-disc pl-8">
          <li className="mb-2">The property is a single-family home or condo in an operational state.</li>
          <li className="mb-2">A minimum FICO score of 600 is required, with most homeowners having scores of 650 or higher.</li>
          <li className="mb-2">There's a minimum of 25% equity in the home.</li>
          <li className="mb-2">The desired investment amount is 15% or less of the total home value or under $500,000, depending on the home's value and equity percentage.</li>
        </ul>
      </>
    ),
    category: "borrow",
  },
  {
    id: 9,
    question: "What is the process for obtaining a HOME.LLC Investment?",
    answer: (
      <>
        <p className="mb-4">Acquiring a HOME.LLC Investment involves these simple steps:</p>
        <ol className="list-decimal pl-8">
          <li className="mb-2">Fill out an Investment Inquiry.</li>
          <li className="mb-2">Consult with your Investment Manager.</li>
          <li className="mb-2">Submit your Application.</li>
          <li className="mb-2">Arrange for an Appraisal.</li>
          <li className="mb-2">Sign and receive your funds!</li>
        </ol>
      </>
    ),
    category: "borrow",
  }
]


export const faqsDataHome = [
  {
    id: 1,
    question: "What is Home.LLC and what do we do?",
    answer: "Home.LLC is a fintech company established in 2019, dedicated to easing the burden of homeownership and enhancing accessibility through its three main products: HOME Equity Investment, HOME Agent, and HOME Analytics. Our mission is to alleviate the home ownership crisis in America by streamlining the process of buying, owning, and selling homes for greater efficiency and effectiveness.",
  },
  {
    id: 2,
    question: "Why work with us?",
    answer: "We're real people delivering practical solutions. With a dedicated, responsive relationship manager by your side, we'll address your inquiries promptly at every step. Our advice is clear, helpful, and personalized, allowing you to make informed decisions that suit your family's unique needs, whether it's buying your first home or accessing equity from your primary residence. Count on us for no sales tactics, no pressure, and absolutely no judgment—we're genuinely committed to your best interests.",
  },
  {
    id: 3,
    question: "What are Home Equity Investments (HEI)?",
    answer: "Home equity investments offer homeowners debt-free cash in return for a share of the home's future value. This funding can be used for various purposes, such as debt repayment or essential renovations, with no interest or monthly payments required. We prioritize simplicity and clarity to ease the stress of homeownership.",
  },
  {
    id: 4,
    question: "How does Home Equity Investments (HEI) differ from a traditional loan?",
    answer: (
      <>
      <p>When you join forces with us, we co-invest alongside you. Instead of borrowing solely from a bank, you become a partner in the investment. In return, you gain access to cash through your equity while enjoying low, fixed monthly payments.</p>
      <p> Co-investing means you retain ownership of the equity you possess. Home.LLC invests in the portion of your equity that you don't own. This arrangement allows Home.LLC to share in both the appreciation and depreciation of your home, while you remain the sole owner on the title. As co-investors, our interests are aligned: we aim for your home to appreciate in value, just like you do.</p>
      </>
    ),
  },
  {
    id: 5,
    question: "Does Home Agent assist with buying and selling properties nationwide?",
    answer: "Of course. Our services extend seamlessly across the entire country, ensuring that no matter where you're located, we're ready to assist you with your home-related needs. Whether you're situated in a bustling city or a remote town, our personalized and prompt service remains consistent. We take pride in catering to a diverse range of home requirements, whether you're seeking a small apartment or a sprawling estate. Rest assured, regardless of the size or complexity of your needs, we're fully committed to supporting you every step of the way.",
  },
  {
    id: 6,
    question: "What makes Home Analytics a trustworthy source for housing market insights?",
    answer: `The answer is straightforward. Home Analytics has been hailed as "The most accurate housing forecaster in America" by numerous reputable publications. Our market predictions have consistently outperformed those of industry leaders, allowing us to accurately anticipate and respond to market trends earlier than many others.`,
  }
]

export const faqAll = [
  {
    id: 1,
    question: "Who are we?",
    answer:"Established in 2019, Home.LLC is dedicated to addressing challenges in the housing market. Our mission is to alleviate the home ownership crisis in America by streamlining the process of buying, owning, and selling homes for greater efficiency and effectiveness.",
    category: "owner",
  },
  {
    id: 2,
    question: "Why work with us?",
    answer:"We're real people delivering practical solutions. With a dedicated, responsive relationship manager by your side, we'll address your inquiries promptly at every step. Our advice is clear, helpful, and personalized, allowing you to make informed decisions that suit your family's unique needs, whether it's buying your first home or accessing equity from your primary residence. Count on us for no sales tactics, no pressure, and absolutely no judgment—we're genuinely committed to your best interests",
    category: "owner",
  },
  {
    id: 3,
    question: "What are Home Equity Investments (HEI)?",
    answer: "Home equity investments offer homeowners debt-free cash in return for a share of the home's future value. This funding can be used for various purposes, such as debt repayment or essential renovations, with no interest or monthly payments required. We prioritize simplicity and clarity to ease the stress of homeownership.",
    category: "owner",
  },
  {
    id: 4,
    question: "How can Home Agent Help with buying and selling properties nationwide?",
    answer:"Of course. Our services extend seamlessly across the entire country, ensuring that no matter where you're located, we're ready to assist you with your home-related needs. Whether you're situated in a bustling city or a remote town, our personalized and prompt service remains consistent. We take pride in catering to a diverse range of home requirements, whether you're seeking a small apartment or a sprawling estate. Rest assured, regardless of the size or complexity of your needs, we're fully committed to supporting you every step of the way.",
    category: "owner",
  },
  {
    id: 5,
    question: "What makes Home Analytics a trustworthy source for housing market insights?",
    answer:"The answer is straightforward. Home Analytics has been hailed as `The most accurate housing forecaster in America` by numerous reputable publications. Our market predictions have consistently outperformed those of industry leaders, allowing us to accurately anticipate and respond to market trends earlier than many others.",
    category: "owner",
  },
  {
    id: 11,
    question: "What services does Home.LLC provide?",
    answer: "Home.LLC specializes in providing analytical data for clients in the US mortgage market. We offer insights and information to help home buyers make informed decisions.",
    category: "buy",
  },
  {
    id: 12,
    question: "How does Home.LLC differ from other property agents?",
    answer: "Unlike traditional property agents who charge a standard 3% commission, Home.LLC charges only a 1% commission for our services. This significantly reduces costs for our clients without compromising on the quality of our service.",
    category: "buy",
  },
  {
    id: 13,
    question: "What kind of analytical data does Home.LLC provide?",
    answer: "We offer a comprehensive range of analytical data, including market trends, mortgage rates, property valuations, neighborhood insights, and more. Our goal is to empower our clients with the information they need to make confident decisions in the real estate market.",
    category: "buy",
  },
  {
    id: 14,
    question: "How can I access Home.LLC's analytical data?",
    answer: "Our team of experts is available to provide personalized insights and assistance as needed.",
    category: "buy",
  },
  {
    id: 15,
    question: "Is Home.LLC only available for clients in specific regions?",
    answer: "No, Home.LLC serves clients across the United States. Whether you're buying a home in New York City or Los Angeles, our analytical data and services are available to you.",
    category: "buy",
  },
  {
    id: 16,
    question: "Can Home.LLC assist with mortgage applications?",
    answer: "While we don't directly handle mortgage applications, we provide valuable insights and information that can help you navigate the mortgage process more effectively. We can also connect you with trusted partners in the industry who can assist with mortgage applications and approvals.",
    category: "buy",
  },
  {
    id: 17,
    question: "What sets Home.LLC apart in the mortgage industry?",
    answer: "Home.LLC stands out for its commitment to transparency, affordability, and client satisfaction. We believe in providing high-quality services at a fair price, and our 1% commission structure reflects that commitment.",
    category: "buy",
  },
  {
    id: 18,
    question: "How can I get started with Home.LLC's services?",
    answer: "Getting started with Home.LLC is easy! Simply reach out to us through our website or contact our team directly. We'll schedule a consultation to discuss your needs and how we can best assist you in achieving your home buying goals.",
    category: "buy",
  },
  {
    id: 19,
    question: "How can I contact Home.LLC for further inquiries?",
    answer: "You can reach us through our website's contact form, via email at support@home.llc, or by calling our toll-free number at 415-407-0976. Our team is standing by to assist you with any questions or concerns you may have.",
    category: "buy",
  },
  {
    id: 20,
    question: "What services does Home.LLC offer for home sellers?",
    answer: "Home.LLC provides comprehensive services for home sellers, including market analysis, pricing guidance, and assistance throughout the selling process.",
    category: "sell",
  },
  {
    id: 21,
    question: "How does Home.LLC's approach benefit home sellers?",
    answer: "Unlike traditional real estate agents, Home.LLC offers competitive commission packages, reducing selling costs while providing high-quality service and expertise.",
    category: "sell",
  },
  {
    id: 22,
    question: "Does Home.LLC only work with sellers in certain areas?",
    answer: "No, Home.LLC serves home sellers across the United States. Whether you're selling a property in a bustling city or a suburban neighborhood, our services are available to help you achieve your selling goals.",
    category: "sell",
  },
  {
    id: 23,
    question: "How can I trust Home.LLC to sell my home effectively?",
    answer: "Home.LLC is committed to transparency, professionalism, and client satisfaction. Our experienced team will guide you through every step of the selling process, providing expert advice and support to ensure a smooth and successful sale.",
    category: "sell",
  },
  {
    id: 24,
    question: "Can Home.LLC help me determine the best listing price for my home?",
    answer: "Yes, Home.LLC offers comprehensive market analysis to help sellers determine the optimal listing price for their properties. We'll take into account market trends, comparable sales, and other factors to ensure your home is competitively priced.",
    category: "sell",
  },
  {
    id: 25,
    question: "How can I get started selling my home with Home.LLC?",
    answer: "Getting started is simple! Schedule a call with us here [include a link or contact method].",
    category: "sell",
  },
  {
    id: 26,
    question: "What is a Home Equity Investment?",
    answer: "A Home Equity Investment by HOME.LLC is a smart financial solution enabling homeowners to access their home’s equity without taking on additional debt. With a HOME.LLC Investment, homeowners receive cash in exchange for a share of their home’s future value. This investment doesn't involve interest, monthly payments, or usage restrictions for up to 30 years.",
    category: "borrow",
  },
  {
    id: 27,
    question: "What does HOME.LLC do?",
    answer: "HOME.LLC is a fintech company dedicated to easing the burden of homeownership and enhancing accessibility through its three main products: HOME Equity Investment, HOME Agent, and HOME Analytics. The HOME Equity Investment offers homeowners a quick, straightforward way to access their home equity without loans or selling their homes. By partnering with homeowners, HOME.LLC provides debt-free cash in exchange for a share of their home’s future value, without any monthly payments or interest over the investment's lifespan.",
    category: "borrow",
  },
  {
    id: 28,
    question: "How does a HOME.LLC Investment differ from a loan?",
    answer: "A HOME.LLC Investment involves HOME.LLC investing in people’s homes without functioning as a lender. Unlike traditional lenders, HOME.LLC doesn't receive monthly payments or a guaranteed return on the invested money. Homeowners don't make monthly or interest payments during the investment period, and there's no guaranteed return for HOME.LLC. Opting for a home equity investment can be a prudent way to finance opportunities and needs in life without the stress of increased monthly payments.",
    category: "borrow",
  },
  {
    id: 29,
    question: "How long do I have to settle a HOME.LLC Investment?",
    answer: "The effective period of a HOME.LLC Investment extends up to 30 years. Settlement can occur by purchasing out the Investment with savings, securing a home equity or other loan, or selling the house within this timeframe.",
    category: "borrow",
  },
  {
    id: 30,
    question: "If HOME.LLC doesn't require payments, how does it earn money?",
    answer: "HOME.LLC earns through the 'HOME.LLC Share,' a percentage of the home's value obtained when the homeowner sells, refinances, or buys out the investment by the end of the effective period. Higher home values result in higher returns for HOME.LLC.",
    category: "borrow",
  },
  {
    id: 31,
    question: "How quickly can HOME.LLC make an Investment?",
    answer: "Upon completing the Application, HOME.LLC typically determines the home market value, finalizes the offer, structures the Investment, and signs within two to three weeks. However, due to increased homeowner interest, this process may take slightly longer.",
    category: "borrow",
  },
  {
    id: 32,
    question: "When might HOME.LLC not be the ideal choice?",
    answer: "The suitability of HOME.LLC varies based on individual homeowners' goals and circumstances. If the loan-to-value ratio is over 75% (indicating less than 25% equity), or if settlement options don't align with the homeowner's plans, HOME.LLC might not be the best fit.",
    category: "borrow",
  },
  {
    id: 33,
    question: "Who is eligible for a HOME.LLC Home Equity Investment?",
    answer: (
      <>
        <p className="mb-4">Determining eligibility for a HOME.LLC Investment involves various factors unique to each property.</p>
        <ul className="list-disc pl-8">
          <li className="mb-2">The property is a single-family home or condo in an operational state.</li>
          <li className="mb-2">A minimum FICO score of 600 is required, with most homeowners having scores of 650 or higher.</li>
          <li className="mb-2">There's a minimum of 25% equity in the home.</li>
          <li className="mb-2">The desired investment amount is 15% or less of the total home value or under $500,000, depending on the home's value and equity percentage.</li>
        </ul>
      </>
    ),
    category: "borrow",
  },
  {
    id: 34,
    question: "What is the process for obtaining a HOME.LLC Investment?",
    answer: (
      <>
        <p className="mb-4">Acquiring a HOME.LLC Investment involves these simple steps:</p>
        <ol className="list-decimal pl-8">
          <li className="mb-2">Fill out an Investment Inquiry.</li>
          <li className="mb-2">Consult with your Investment Manager.</li>
          <li className="mb-2">Submit your Application.</li>
          <li className="mb-2">Arrange for an Appraisal.</li>
          <li className="mb-2">Sign and receive your funds!</li>
        </ol>
      </>
    ),
    category: "borrow",
  }
]