import React from 'react';

const ProgressIndicator = ({ steps, currentStep }) => {
  
  return (
    <div className="ml-8 mt-16 flex mr-8 items-center">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={`w-8 h-8 ${index === 6 ? 'w-16 h-16' : ''} ${index <= currentStep ? 'bg-black' : 'bg-pink-300'} rounded-full flex items-center justify-center`}>
  {
    index === 6
? <span className="text-white text-sm">{index + 1}</span>
      // ? <img src="path-to-your-logo.png" alt="Logo" className="w-4 h-4" /> // Adjust the size as needed
      : <span className="text-white text-sm">{index + 1}</span>
  }
</div>

          {index < steps.length - 1 && (
            <div className={`flex-auto border-t-4 ${index < currentStep ? 'border-black' : 'border-pink-300'}`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressIndicator;