import React from 'react'
import MapBoxComponent from '../MapBoxComponent'
import ComparisonBox from './MarketHotness/ComparisonBox'
const marketData=[{
    area: "94043",
    percent: "99.2",

},
{
    area: "9401",
    percent: "102",
}, {
    area: "94011",
    percent: "105",
}, {
    area: "Bay Area",
    percent: "101",
}]
const SaleToListRatio=() => {
    return (
        <div className="flex flex-col md:flex-row gap-10 w-full">
  <div className="w-full md:w-3/5 rounded-xl overflow-hidden">
    <MapBoxComponent
      styleUrl={"mapbox://styles/jayambalani/cly946tpj00ig01nw1uop9383"}
      height={"450px"}
    />
  </div>
  <div className="w-full md:w-2/5 flex flex-col max-h-[450px] mt-0 md:mt-[-14px]">
    <div className='flex flex-col'>
      <div className='flex flex-col mb-6'>
        <h2 className='text-2xl md:text-3xl font-semibold'>Sale to List Ratio</h2>
        <p className='text-3xl md:text-5xl font-semibold -mt-3'>103%</p>
        <div className='w-full h-[10px] rounded-lg bg-[#ED5272] relative'>
          <div className='absolute w-[80%] h-full rounded-lg bg-[#5090F6]'></div>
        </div>
        <div className='flex items-center gap-x-10'>
          <div className='flex items-center gap-x-3'>
            <p className='text-sm md:text-base'>Listed Price</p>
            <div className='w-4 h-4 bg-[#5090F6]'></div>
          </div>
          <div className='flex items-center gap-x-3'>
            <p className='text-sm md:text-base'>Sale Price</p>
            <div className='w-4 h-4 bg-[#ED5272]'></div>
          </div>
        </div>
        <p className='text-xs md:text-sm -mt-2'>The average homes sell for about 6% above list price and go pending in around 10 days.</p>
      </div>
      <div className='flex flex-col'>
        <h2 className='text-2xl md:text-3xl font-semibold'>Comparison</h2>
        <div className='flex flex-col max-h-[200px] overflow-y-scroll'>
          {marketData.map((market, index) => (
            <ComparisonBox isBar={false} area={market.area} percent={market.percent} key={index} />
          ))}
        </div>
      </div>
    </div>
  </div>
</div>
    )
}

export default SaleToListRatio