import { createSlice } from '@reduxjs/toolkit';

const stringSlice = createSlice({
  name: 'address',
  initialState: '',
  reducers: {
    setAddress: (state, action) => action.payload,
  },
});

export const { setAddress } = stringSlice.actions;
export default stringSlice.reducer;