import React, { useEffect, useState } from "react";
import CTAbutton from "./CTAbutton";
import SliderComponentWithValue from "./SliderWithValues";
import { useDispatch, useSelector } from "react-redux";
import { updateCashoutState } from "../../store/slices/cashoutCalcSlice";

const CashoutCalculator = ({ propertyRes: propertyData = [] }) => {
  const errorState = useSelector((state) => state.cashout.errorState);
  const radarId = useSelector((state) => state.cashout.radarID);
  const propData = propertyData[0];
  const dispatch = useDispatch();
  const calculatedField = useSelector((state) => state.cashoutCalc);
  const [homeValue, setHomeValue] = useState(
    errorState !==
      "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976."
      ? radarId !== ""
        ? propData?.AVM
          ? propData?.AVM
          : 1000000
        : 1000000
      : 1000000
  );
  const [loanValue, setLoanValue] = useState(
    errorState !==
      "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976."
      ? radarId !== ""
        ? propData?.AvailableEquity
          ? propData?.AVM - propData?.AvailableEquity
          : 200000
        : 200000
      : 200000
  );

  const homeEquity = homeValue - loanValue;
  const minimumCashoutAmount = calculateMinCashout(homeValue, homeEquity);
  const maximumCashoutAmount = calculateMaxCashout(homeValue, homeEquity);
  const [annualChange, setAnnualChange] = useState(5);
  const [contractTerm, setContractTerm] = useState(5);
  const homeEquityPercentage = (homeEquity / homeValue) * 100;
  const LoanPercentage = (loanValue / homeValue) * 100;

  const [cashOutAmount, setCashOutAmount] = useState(
    minimumCashoutAmount + maximumCashoutAmount / 2
  );

  // Update cashOutAmount whenever minimum or maximum changes
  // Update cashout amount when min/max cashout changes
  useEffect(() => {
    const updatedCashOut = (minimumCashoutAmount + maximumCashoutAmount) / 2;
    setCashOutAmount(updatedCashOut);

    dispatch(
      updateCashoutState({
        cashoutValue: updatedCashOut,
      })
    );
  }, [minimumCashoutAmount, maximumCashoutAmount, dispatch]);

  const cashoutPercentage = (cashOutAmount / homeValue) * 100;

  const futureHomeValue =
    homeValue * Math.pow(1 + annualChange / 100, contractTerm);
  const value1 = cashOutAmount * Math.pow(1.2, contractTerm);
  const value2 = 2 * (futureHomeValue * (cashoutPercentage / 100));

  const homeLLCShareAmount = Math.min(value1, value2);
  const homeLLCSharePercentage = (homeLLCShareAmount / futureHomeValue) * 100;

  const customerShare = futureHomeValue - homeLLCShareAmount;
  const customerSharePercentage = (customerShare / futureHomeValue) * 100;

  useEffect(() => {
    if (propertyData) {
      dispatch(
        updateCashoutState({
          cashoutValue: cashOutAmount.toFixed(2),
          homeValue: homeValue,
          loanValue: loanValue,
          maximumCashout: maximumCashoutAmount.toFixed(2),
          minimumCashout: minimumCashoutAmount.toFixed(2),
        })
      );
    }
    dispatch(
      updateCashoutState({
        cashoutValue: cashOutAmount.toFixed(2),
        homeValue: homeValue,
        loanValue: loanValue,
        maximumCashout: maximumCashoutAmount.toFixed(2),
        minimumCashout: minimumCashoutAmount.toFixed(2),
      })
    );
  }, [
    cashOutAmount,
    homeValue,
    loanValue,
    maximumCashoutAmount,
    minimumCashoutAmount,
    dispatch,
    propertyData,
  ]);

  useEffect(() => {
    // Update the state when propertyData changes
    setHomeValue(
      errorState !==
        "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976."
        ? radarId !== ""
          ? propData?.AVM
            ? propData?.AVM
            : 1000000
          : 1000000
        : 1000000
    );
    setLoanValue(
      errorState !==
        "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976."
        ? radarId !== ""
          ? propData?.AvailableEquity
            ? propData?.AVM - propData?.AvailableEquity
            : 200000
          : 200000
        : 200000
    );
  }, [propData, errorState, radarId]);

  const decreaseAnnualChange = () => {
    if (annualChange > -5) {
      setAnnualChange(annualChange - 1);
    }
  };

  const increaseAnnualChange = () => {
    if (annualChange < 5) {
      setAnnualChange(annualChange + 1);
    }
  };

  // Handlers for Contract Term (Years until settlement)
  const decreaseContractTerm = () => {
    if (contractTerm > 1) {
      setContractTerm(contractTerm - 1);
    }
  };

  const increaseContractTerm = () => {
    if (contractTerm < 5) {
      setContractTerm(contractTerm + 1);
    }
  };

  return (
    <div className="w-full -mt-[200px]" key={"calculator"}>
      <div className="px-28 bg-custom-linear-gradient h-[250px]">
        <div className="flex gap-80 justify-center">
          <div>
            <h2 className="text-2xl font-poppins font-normal capitalize mb-1 text-white text-center">
              Home Value
            </h2>

            <SliderComponentWithValue
              label="Home Value"
              min={200000}
              max={3000000}
              initialValue={homeValue}
              onValueChange={(newValue) => {
                setHomeValue(newValue);
              }}
              sliderValueStyle={`text-[48px] font-poppins font-normal mb-2`}
              sliderInputStyle={"w-[400px]"}
            />
          </div>

          <div>
            <h2 className="text-2xl font-poppins font-normal capitalize mb-1 text-white text-center">
              Mortgage Balance
            </h2>
            <SliderComponentWithValue
              min={0}
              max={homeValue}
              initialValue={loanValue}
              onValueChange={(newValue) => {
                setLoanValue(newValue);
              }}
              sliderValueStyle={`text-[48px] font-poppins font-normal mb-2`}
              sliderInputStyle={"w-[400px]"}
            />
          </div>
        </div>
        <p className="text-center text-white mt-4 text-xs font-medium">
          *Your home’s value will be determined by an independent third-party
          appraiser or valuation provider.
        </p>
      </div>

      {/* Cash out value section */}
      <div className="bg-custom-gray10 mx-auto w-full h-[650px] text-white">
        <div className="max-w-[80%] mx-auto w-full shadow-md border border-[#495057] rounded-lg h-[600px] py-8 px-12">
          <div className="flex justify-center items-center flex-col">
            <h2 className="font-poppins leading-[50px] text-6xl font-normal">
              Cashout Amount
            </h2>

            <p className="font-light text-xl  leading-9 mb-6">
              You may qualify for up to {" "}
              <span className="font-medium">
                $
                {Math.floor(
                  calculateMaxCashout(homeValue, homeEquity)
                ).toLocaleString("en-US")}
              </span>{" "}
              How much do you want to cash out?
            </p>

            <SliderComponentWithValue
              min={minimumCashoutAmount}
              max={maximumCashoutAmount}
              initialValue={cashOutAmount}
              sliderValueStyle={`text-[80px] font-poppins font-normal`}
              sliderInputStyle={"w-[400px]"}
              onValueChange={(newValue) => {
                setCashOutAmount(newValue);
              }}
            />

            <p className="font-light text-lg leading-9 mt-8">
              Receive 
              <span className="font-medium">
                ${Math.floor(cashOutAmount).toLocaleString("en-US")}
              </span>
               in exchange for 
              <span className="font-medium">
                ${homeLLCSharePercentage.toFixed(2)}%{" "}
              </span>
              of your home’s future value, subject to an Annualized Cost Limit
              of 20%.
            </p>
            <p className="text-center text-xs font-medium mb-2">
              Amount you receive represents gross investment proceeds.
              Transaction expenses and an origination fee of 3% will be deducted
              at closing. Transaction expenses include appraisal, title, credit
              and escrow fees paid to third parties and may vary depending on
              property specific factors.
            </p>

            <p className="text-center text-xs font-medium">
              The above estimate is for informational purposes only.
            </p>
          </div>
        </div>
      </div>

      {/* what you wil owe and future homeValue and homeLLCS share calculations */}
      <div className="bg-custom-lightWhiteBG w-full py-12">
        <h3 className="text-center text-[3.5rem] leading-[4rem] mb-5 font-normal">
          But what will you owe us?
        </h3>
        <div className="max-w-[80%] bg-custom-gray2 mx-auto w-full h-[850px] py-12 px-12 rounded-[40px]">
          <div className="flex flex-col justify-center">
            <div className="flex gap-40 justify-center">
              <div>
                <h4 className="text-2xl 2xl:text-3xl font-normal text-center leading-8">
                  Annual Change In Home Value (%)
                </h4>
                <div className="flex justify-center items-center gap-8 mt-6">
                  <button
                    className="border border-custom-gray10 py-1 px-2.5 rounded-lg text-custom-gray10"
                    onClick={decreaseAnnualChange}
                  >
                    -
                  </button>
                  <span className="text-6xl">{annualChange}</span>
                  <button
                    className="border border-custom-gray10 py-1 px-2.5 rounded-lg text-custom-gray10"
                    onClick={increaseAnnualChange}
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                <h4 className="text-2xl 2xl:text-3xl text-center font-normal leading-8">
                  Years until settlement
                </h4>
                <div className="flex justify-center items-center gap-8 mt-6">
                  <button
                    className="border border-custom-gray10 py-1 px-2.5 rounded-lg text-custom-gray10"
                    onClick={decreaseContractTerm}
                  >
                    -
                  </button>
                  <span className="text-6xl">{contractTerm}</span>
                  <button
                    className="border border-custom-gray10 py-1 px-2.5 rounded-lg text-custom-gray10"
                    onClick={increaseContractTerm}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>

            {/* disclaimer */}
            <p className="text-center text-xs font-medium mb-10">
              This calculator is for informational purposes only.Terms and
              conditions apply.
            </p>

            <h4 className="text-[2.5rem] text-center mb-4">
              Future home value
            </h4>
            <h3 className="text-center text-[6.25rem] leading-[5rem] mb-10">
              <span className="text-[4rem] 3xl:text-[5rem]">$</span>
              {Math.round(futureHomeValue).toLocaleString("en-US")}
            </h3>

            <LinePercentage pinkPercentage={homeLLCSharePercentage} />

            <div className="flex justify-center gap-40 mt-4">
              <div className="w-full">
                <p className="text-center text-[1.75rem]">
                  Your Share ({customerSharePercentage.toFixed(0)}%)
                </p>
                <h3 className="text-[4rem] 3xl:text-[5rem]  font-normal text-center">
                  <span className="text-[4.5rem] mr-2">$</span>
                  {Math.round(customerShare).toLocaleString("en-US")}
                </h3>
                <p className="text-xs font-medium text-center">
                  Amount shown represents your gross share of the future home
                  value. You will be responsible for paying any mortgage loans
                  and selling costs from your share.
                </p>
              </div>
              <div className="w-full">
                <p className="font-normal text-center text-[1.75rem] text-custom-primaryPink">
                  Home.LLC share ({homeLLCSharePercentage.toFixed(0)}%)
                </p>
                <h3 className="text-[4rem] 3xl:text-[5rem] font-normal text-custom-primaryPink text-center">
                  <span className="text-[4.5rem] mr-2">$</span>
                  {Math.round(homeLLCShareAmount).toLocaleString("en-US")}
                </h3>
                <p className="text-xs font-medium text-center text-custom-primaryPink">
                  You will pay this amount to Home.LLC at settlement.
                </p>
              </div>
            </div>

            {!propertyData && (
              <CTAbutton extraStyles={"w-[240px] mx-auto mt-8"}>
                Contact us
              </CTAbutton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashoutCalculator;

const LinePercentage = ({ pinkPercentage, totalLines = 50 }) => {
  // Calculate the number of pink and black lines
  const pinkLinesCount = Math.round((pinkPercentage / 100) * totalLines);
  const blackLinesCount = totalLines - pinkLinesCount;

  return (
    <div className="flex gap-2 w-full justify-center">
      {/* Render black lines */}
      {Array.from({ length: blackLinesCount }).map((_, index) => (
        <div
          key={`black-${index}`}
          className="w-1 h-9 bg-[#212529] rounded-lg"
        ></div>
      ))}

      {/* Render pink lines */}
      {Array.from({ length: pinkLinesCount }).map((_, index) => (
        <div
          key={`pink-${index}`}
          className="w-1  h-9 bg-custom-primaryPink rounded-lg"
        ></div>
      ))}
    </div>
  );
};

// calculate max cashout

export function calculateMaxCashout(homeValue, homeEquity) {
  if (homeValue >= 200000 && homeEquity / homeValue >= 0.35) {
    return Math.max(0.15 * homeValue, 30000);
  } else if (homeValue >= 200000 && homeEquity / homeValue >= 0.2) {
    let value = (homeEquity / homeValue - 0.2) * homeValue;
    return value >= 30000 ? value : 0;
  } else {
    return 0;
  }
}

// calculate min cashout
export function calculateMinCashout(homeValue, homeEquity) {
  if (
    homeValue >= 200000 &&
    homeEquity / homeValue >= 0.2 &&
    (homeEquity / homeValue - 0.2) * homeValue >= 30000
  ) {
    return 30000;
  } else {
    return 0;
  }
}
