import React from "react";
import FaqHero from "../components/Faq/FaqHero";
import FaqSection from "../components/Faq/FaqSection";
import Header from "../components/HomePage/Navbar";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";

const FaqPage = () => {
  return (
    <div className="overflow-x-hidden">
      <Header/>
      <FaqHero />
      <FaqSection />
      <NeedFromUs />
      <Footer/>
    </div>
  );
};

export default FaqPage;
