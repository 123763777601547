import React, { useContext, useEffect, useRef } from "react";
import { populationData } from "./data";
import ReactEcharts from "echarts-for-react";
import { CounterContext } from "../../hooks/HomeAi";
import { numberFormatter } from "../../utils/common";
import { BiSolidRightArrow } from "react-icons/bi";
import Heading from "./Ui/Heading";
import SubHeading from "./Ui/SubHeading";

const Affordability=() => {
  const chartRef=useRef(null);
  const {
    mediumIncom,
    mediumIncomGraph,
    costOfLiving,
    constofLivingData,
    totalCostOfLiving,
    area_name
  }=useContext(CounterContext);

  const scaleFactor=totalCostOfLiving/360; // Using 230 as the maximum value for scaling

  // Calculate bar heights based on the scale factor
  const bars=constofLivingData?.map((item) => ({
    label: item.label,
    value: item.value,
    height: item.value*scaleFactor,
  }));

  const option={
    xAxis: {
      boundaryGap: false,
      type: "category",
      data: mediumIncomGraph?.map((data) => data.name),
    },
    yAxis: {
      type: "value",
      axisLabel: { show: false },
      axisLine: { show: true },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        data: mediumIncomGraph?.map((data) => data.value),
        type: "line",
        symbol: "none",
        lineStyle: {
          color: "#ED5272",
          width: 6,
        },
        markLine: {
          symbol: "none", // No symbols at the ends of the lines
          lineStyle: {
            type: "solid",
            color: "#000", // Customize color if needed
            width: 1, // Customize width if needed
          },
          label: {
            show: false, // Hide the label
          },
          data: mediumIncomGraph?.map((data) => {
            return {
              xAxis: data.name,
            };
          }),
        },
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);
  const data=[80, 60, 40, 50, 70, 50, 60];

  return (
    <div className="w-full bg-white relative border-none shadow-none mt-[-5px] px-6">
  <div className="relative w-full max-w-screen-xl py-[30px] xl:py-[72px] mx-auto text-black h-full">
    {/* Heading Section */}
    <Heading className="text-2xl md:text-[40px]">Affordability</Heading>

    {/* Population Data Section */}
    <div className="flex flex-col md:flex-row w-full h-auto mb-10">
      <div className="w-full md:w-2/6 py-5">
        <SubHeading>Median Income</SubHeading>
        <p className="text-[30px] md:text-[60px] font-normal mr-1">
          ${numberFormatter(mediumIncom)}
          <sup className="text-lg md:text-2xl">
            <span className="text-500">{-20}%</span>
          </sup>
        </p>
        <p className="text-sm md:text-[16px] mt-2 w-full md:w-[85%]">
          The median income of {area_name} is {numberFormatter(mediumIncom)}{" "}
          with 20% increment compared to last year.
        </p>
      </div>
      <div className="w-full md:w-5/6 mt-6 md:mt-0">
        <ReactEcharts
          ref={chartRef}
          style={{ width: "100%" }}
          option={option}
        />
      </div>
    </div>
     {/* population data */}
    <div className="flex flex-col md:flex-row w-full h-auto mb-10">
      <div className="w-full md:w-2/6">
        <SubHeading>Cost of Living</SubHeading>
        <h2 className="text-sm md:text-[16px] font-normal">
          Avg Annual Expenses
        </h2>
        <p className="text-[30px] md:text-[60px] font-normal mr-1 mt-[-20px]">
          ${numberFormatter(costOfLiving)}
        </p>
        <p className="text-sm md:text-[16px] mt-2 w-full md:w-[65%]">
          The Avg Annual Expenses of {area_name} is $
          {numberFormatter(costOfLiving)}.
        </p>
      </div>
      <div className="w-full md:w-4/6 flex flex-col items-center relative">
        <div className="flex flex-row w-full max-w-full hide-scrollbar items-center h-full overflow-x-auto gap-4">
          {bars?.map((i, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-1 relative"
            >
              <div className="h-[200px] md:h-[260px] rounded-full bg-[#D9D9D9] overflow-hidden items-end flex w-8 md:w-9 relative">
                <div
                  className={`w-full bg-[#379DFC] rounded-full`}
                  style={{
                    height: `${
                      Math.floor(Math.random() * (95 - 35 + 1)) + 35
                    }%`
                  }}
                >
                </div>
                <div
                  style={{
                    top:
                      index === 0
                        ? "1.5rem"
                        : `${(Math.random() * (14 - 6) + 1).toFixed(
                            2
                          )}rem`,
                  }}
                  className="w-[25px] md:w-[35px] h-[25px] md:h-[35px] rounded-full bg-[#ED5272] absolute top-0"
                ></div>
              </div>
              <div className="text-black font-semibold">$ {i.value}</div>
              <div className="text-black text-center text-[10px] md:text-[12px]">
                {i.label}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row items-center justify-center md:justify-start w-full md:w-auto mt-4 md:mt-0">
          <span className="text-sm md:text-md font-semibold flex items-center">
            National Average
            <BiSolidRightArrow />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Affordability;