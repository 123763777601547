import React from "react";
import homeLLCLogo from "../../assets/logoNav.png";

const Header = () => {
  return (
    <div
      className=" w-full h-[80px] pl-[60px] pt-[20px] 3xl:h-[120px]
     bg-custom-gray10 3xl:pl-[100px] 3xl:pt-[30px] fixed top-0 z-50"
    >
      <img
        src={homeLLCLogo}
        alt="logo-main"
        className="h-[40px] w-[140px] 3xl:h-[60px] 3xl:w-[180px]"
      />
    </div>
  );
};

export default Header;
