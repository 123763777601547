import React from 'react'
import HeroSection from '../components/Consult/Hero'
import Header from '../components/HomePage/Navbar'
import ConsultBanner from '../components/AnalyticsLanding/Consult'
import Footer from "../components/Footer";
import CalendyContact from '../components/Consult/CalendyLink'
import PressSection from '../components/Consult/PressSection'
import Testimonial from '../components/Consult/Testimonial';
import NeedFromUs from '../components/EquityLanding/NeedFromUs';

const Consult = () => {
  return (
    <div className='overflow-x-hidden sm:overflow-x-auto'>
        <Header/>
        <HeroSection />
       <PressSection />
      <ConsultBanner />
      <Testimonial />
        {/* <TestimonialSlider testimonials={testimonialsAnalyse}/> */}
        <CalendyContact/>
        {/* <ToolsResources/>
        <FAQSection/> */}
      <NeedFromUs />
        <Footer/>

    </div>
  )
}

export default Consult