import React, { useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";

const NaturalDisasterRisk=() => {
  const chartRef=useRef(null);

  const option={
    xAxis: {
      show: false,
    },
    yAxis: {
      type: "category",
      data: ["Storm", "Heat", "Drought", "Fire", "Flood"],
      inverse: true,
      animationDuration: 300,
      animationDurationUpdate: 300,
      axisTick: {
        length: 0,
      },
      axisLabel: {
        color: "black",
        fontWeight: "600",
      },
    },
    series: [
      {
        realtimeSort: true,
        type: "bar",
        data: [10, 55, 96, 75, 52],
        label: {
          show: true,
          position: "right",
          valueAnimation: true,
          color: "black",
          fontWeight: "bold",
        },
        barWidth: "40px",
        itemStyle: {
          color: '#0F5DDD'
        }
      },
    ],
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: "linear",
    animationEasingUpdate: "linear",
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="text-xl font-medium">Natural Disaster Risk</div>
      <ReactEcharts
        ref={chartRef}
        style={{ width: "100%", height: 500 }}
        option={option}
      />
    </div>
  );
};

export default NaturalDisasterRisk;
