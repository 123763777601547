import article1 from "../assets/press/article-1.png";
import article2 from "../assets/press/article-2.png";
import article3 from "../assets/press/article-3.png";
import article4 from "../assets/press/article-4.png";

import articleCompnay1 from "../assets/press/article-company-1.svg";
import articleCompnay2 from "../assets/press/article-company-2.svg";
import articleCompnay3 from "../assets/press/article-company-3.svg";

import pressCard1 from "../assets/press/press-card-1.png";
import pressCard2 from "../assets/press/press-card-2.png";
import pressCard3 from "../assets/press/press-card-3.png";
import pressCard4 from "../assets/press/press-card-4.png";
import pressCard5 from "../assets/press/press-card-5.png";
import pressCard6 from "../assets/press/press-card-6.png";
import bottomCard1 from "../assets/press/bottom-card-1.png";
import bottomCard2 from "../assets/press/bottom-card-2.png";
import bottomCard3 from "../assets/press/bottom-card-3.png";
import bottomCard4 from "../assets/press/bottom-card-4.webp";
import bottomCard5 from "../assets/press/bottom-card-5.webp";
import bottomCard6 from "../assets/press/bottom-card-6.png";
import bottomCard7 from "../assets/press/bottom-card-7.png";
import bottomCard8 from "../assets/press/bottom-card-8.png";

import pressCardLogo1 from "../assets/press/press-card-logo-1.svg";
import pressCardLogo2 from "../assets/press/press-card-logo-2.svg";
import pressCardLogo3 from "../assets/press/press-card-logo-3.svg";
import pressCardLogo5 from "../assets/press/press-card-logo-4.png";
import pressCardLogo4 from "../assets/press/press-card-logo-5.png";
import pressCardLogo6 from "../assets/press/press-card-logo-6.svg";
import pressCardLogo7 from "../assets/press/press-card-logo-7.png";
import goBankingLogo from "../assets/press/go_banking_logo.png";
import lockBoxLogo from "../assets/press/lock_box_logo.png";
import productLaunchLogo from "../assets/press/product_launch_logo.png";
import realtorLogo from "../assets/press/realtor_logo.png";
import geekEstate from "../assets/press/Geek-Estate_logo.png";
import MPALogo from "../assets/press/MPA_logo.png";
import insider from "../assets/press/Insider_Logo.png";
import MoneyLogo from "../assets/press/Money_logo.png";
import epochTimeLogo from "../assets/press/epoch_times.png";
import rbLogo from "../assets/press/Rb_logo.png";
import forbesLogo from "../assets/press/forbes.png";
import geeksLogo from "../assets/press/geeks_wealth.png";
import kslLogo from "../assets/press/ksl_logo.png";
import sfGateLogo from "../assets/press/sfGate_logo.png";
import bottomLogo1 from "../assets/press/bottom-card-logo-1.png";
import bottomLogo2 from "../assets/press/bottom-card-logo-2.png";
import bottomLogo3 from "../assets/press/bottom-card-logo-3.webp";
import bottomLogo4 from "../assets/press/bottom-card-logo-4.png";
import bottomLogo5 from "../assets/press/bottom-card-logo-5.png";
import bottomLogo6 from "../assets/press/bottom-card-logo-6.png";
import bottomLogo7 from "../assets/press/bottom-card-logo-7.png";
import bottomLogo8 from "../assets/press/bottom-card-logo-8.png";
import bottomLogo9 from "../assets/press/bottom-card-logo-9.png";

import principle1 from "../assets/culture/principle-1.svg";
import principle2 from "../assets/culture/principle-2.svg";
import principle3 from "../assets/culture/principle-3.svg";
import principle4 from "../assets/culture/principle-4.svg";
import principle5 from "../assets/culture/principle-5.svg";
import principle6 from "../assets/culture/principle-6.svg";
import principle7 from "../assets/culture/principle-7.svg";
import principle8 from "../assets/culture/principle-8.svg";
import principle9 from "../assets/culture/principle-9.svg";

import { Testimon1, Testimon2, Testimon3 } from "../assets";
import Testimon4 from "../assets/Testimon4.png";

import content1 from "../assets/home-equity/content-bg-1.png";
import content2 from "../assets/home-equity/content-bg-2.png";
import content3 from "../assets/home-equity/content-bg-3.png";

// seller cards hover images
import card1 from "../assets/seller-landing/card-1.png";
import card2 from "../assets/seller-landing/card-2.png";
import card3 from "../assets/seller-landing/card-3.png";
import card4 from "../assets/seller-landing/card-4.png";

// buyer cards hover images
import card1Image from "../assets/buyer-landing/card-1.png";
import card2Image from "../assets/buyer-landing/card-2.png";
import card3Image from "../assets/buyer-landing/card-3.png";
import card4Image from "../assets/buyer-landing/card-4.png";
import card5Image from "../assets/buyer-landing/card-5.png";

export const testimonialsHomePage = [
  {
    id: 1,
    name: "Ted",
    location: "San Diego, CA",
    status: "Senior Analyst at a REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon1,
  },
  {
    id: 2,
    name: "Nick Seforos",
    status: "Helped us lower our loan amount by $58,500",
    testimonial:
      "Hey, this is Nick and this is Denali. We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible. She helped us lower our loan amount by $58,500. I highly recommend Home.LLC team, thank you!",
    image: Testimon2,
  },
  {
    id: 3,
    name: "Idriss & Maria",
    status: "Home.LLC made this as easy as possible",
    testimonial:
      "We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible.",
    image: Testimon3,
  },
  // Add as many testimonials as you have...
];

export const testimonialsAnalyse = [
  {
    id: 1,
    name: "Ted",
    location: "San Diego, CA",
    status: "Senior Analyst at a REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon1,
  },

  {
    id: 2,
    name: "Nick Seforos",
    status: "Helped us lower our loan amount by $58,500",
    testimonial:
      "Hey, this is Nick and this is Denali. We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible. She helped us lower our loan amount by $58,500. I highly recommend Home.LLC team, thank you!",
    image: Testimon2,
  },

  {
    id: 3,
    name: "Idriss & Maria",
    status: "Home.LLC made this as easy as possible",
    testimonial:
      "We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible.",
    image: Testimon3,
  },
  {
    id: 4,
    name: "Adam Kilborne",
    status: "Senior analyst at REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon4,
  },

  // Add as many testimonials as you have...
];

export const testimonialsBuy = [
  {
    id: 1,
    name: "Idriss & Maria",
    status: "Home.LLC made this as easy as possible",
    testimonial:
      "We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible.",
    image: Testimon3,
  },

  {
    id: 2,
    name: "Nick Seforos",
    status: "Helped us lower our loan amount by $58,500",
    testimonial:
      "Hey, this is Nick and this is Denali. We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible. She helped us lower our loan amount by $58,500. I highly recommend Home.LLC team, thank you!",
    image: Testimon2,
  },
  {
    id: 3,
    name: "Ted",
    location: "San Diego, CA",
    status: "Senior Analyst at a REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon1,
  },

  {
    id: 4,
    name: "Adam Kilborne",
    status: "Senior analyst at REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon4,
  },

  // Add as many testimonials as you have...
];

export const testimonialsBorrow = [
  {
    id: 4,
    name: "Nick Seforos",
    status: "Helped us lower our loan amount by $58,500",
    testimonial:
      "Hey, this is Nick and this is Denali. We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible. She helped us lower our loan amount by $58,500. I highly recommend Home.LLC team, thank you!",
    image: Testimon2,
  },

  {
    id: 1,
    name: "Adam Kilborne",
    status: "Senior analyst at REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon4,
  },
  {
    id: 2,
    name: "Ted",
    location: "San Diego, CA",
    status: "Senior Analyst at a REIT",
    testimonial:
      "Nik is a strategic advisor to me and my leadership team. Sid is helping our customers and investors acquire real estate faster. Hank is building an analytics engine to target the right houses",
    image: Testimon1,
  },

  {
    id: 3,
    name: "Idriss & Maria",
    status: "Home.LLC made this as easy as possible",
    testimonial:
      "We want to thank Home(dot)LLC for helping us buy this beautiful home. This process is supposed to be very long and complicated but, Home.LLC made this as easy as possible.",
    image: Testimon3,
  },
  // Add as many testimonials as you have...
];

export const principlesData = [
  {
    id: 1,
    img: principle1,
    message: "Customer Obsession",
  },
  {
    id: 2,
    img: principle2,
    message: "Embrace the Discomfort",
  },
  {
    id: 3,
    img: principle3,
    message: "Be an Owner, Not a Renter",
  },
  {
    id: 4,
    img: principle4,
    message: "Have the Courage to be Disliked",
  },
  {
    id: 5,
    img: principle5,
    message: "Think Big. Start Smart. Scale Fast. Finish Strong",
  },
  {
    id: 6,
    img: principle6,
    message: "Captain your Fate. Get a Coach.",
  },
  {
    id: 7,
    img: principle7,
    message: "Stop Tolerating. Stop Expecting.",
  },
  {
    id: 8,
    img: principle8,
    message: "Don’t Give Up. Ever.",
  },
  {
    id: 9,
    img: principle9,
    message: "Only Focus on your Next Step",
  },
];

const images = [
  { id: "nberg", image: require("../assets/scroller/1.png") },
  { id: "layer1", image: require("../assets/scroller/2.png") },
  { id: "g14", image: require("../assets/scroller/3.png") },
  { id: "path20", image: require("../assets/scroller/4.png") },
  { id: "path50", image: require("../assets/scroller/5.png") },
  { id: "path40", image: require("../assets/scroller/6.png") },
  { id: "path70", image: require("../assets/scroller/7.png") },
  { id: "path80", image: require("../assets/scroller/8.png") },
];

export default images;

export const howitworks = [
  {
    title: "Get upto $500,000 at 0% interest",
    description: "In exchange, sell a part of your home to us",
    bg: content1,
  },
  {
    title: "It’s an investment, not a loan",
    description:
      "Enjoy financial flexibility. No monthly payments up to 30 years",
    bg: content2,
  },
  {
    title: "Settle whenever you're ready",
    description:
      "No prepayment penalties, no rush. Buy us out anytime with savings, a refinance, or sale of your home",
    bg: content3,
  },
];

export const pressData = [
  {
    id: 1,
    image: article1,
    title: "6 ways to get a mortgage even if you think you aren’t eligible.",
    companyLogo: articleCompnay1,
    date: "July 31, 2021",
    link: "https://finance.yahoo.com/news/6-ways-mortgage-even-think-210049100.html#new_tab",
  },
  {
    id: 2,
    image: article2,
    title: "Home.LLC, a down payment assistance provider.",
    companyLogo: articleCompnay2,
    date: "September 31, 2021",
    link: "https://www.wsj.com/articles/idaho-ski-homes-11633007685",
  },
  {
    id: 3,
    image: article3,
    title:
      "We expect prices to continue increasing in 2022 - Nik Shah, CEO of Home.LLC",
    companyLogo: articleCompnay2,
    date: "September 31, 2021",
    link: "https://themortgagereports.com/85263/housing-market-predictions-2022#new_tab",
  },
  {
    id: 4,
    image: article4,
    title:
      "“Home owners have more equity in their homes than at any time in the last 30 years”",
    companyLogo: articleCompnay3,
    date: "September 31, 2021",
    link: "https://www.bhg.com/home-improvement/moving/buying/housing-market-crash-2022/",
  },
];

export const pressCardsData = [
  {
    id: 5,
    text: "6 Ways To Get A Mortage Even If You Think You Aren't Eligible",
    image: pressCard1,
    companyLogo: pressCardLogo4,
    date: "jully 31, 2021",
    url: "https://finance.yahoo.com/news/6-ways-mortgage-even-think-210049100.html#new_tab",
  },
  {
    id: 7,
    text: `"Home.LLC, a down payment assistance provider" `,
    image: pressCard2,
    companyLogo: pressCardLogo5,
    date: "September 30, 2021",
    url: "https://www.wsj.com/articles/idaho-ski-homes-11633007685",
  },
  {
    id: 8,
    text: `"We expect the prices to continue increasing in 2022 - Nik Shah, CEO of Home.LLC" `,
    image: pressCard3,
    companyLogo: pressCardLogo6,
    date: "Oct 6, 2021",
    url: "https://themortgagereports.com/85263/housing-market-predictions-2022#new_tab",
  },
  {
    id: 9,
    text: `“Homeowners have more equity in their homes than at any time in the last 30 years,”`,
    image: pressCard4,
    companyLogo: pressCardLogo7,
    date: "Oct 24, 2021",
    url: "https://www.bhg.com/home-improvement/moving/buying/housing-market-crash-2022/",
  },
  {
    id: 6,
    text: `"Zillow's model does not work so they've stopped iBuying..." `,
    image: pressCard5,
    companyLogo: pressCardLogo2,
    date: "November 3, 2021",
    url: "https://fortune.com/2021/11/02/zillow-home-flipping-ibuyer-closes-layoffs-home-prices/",
  },
  {
    id: 10,
    text: `"All signs point toward million-dollar homes becoming the norm in the 2030s…" `,
    image: pressCard5,
    companyLogo: pressCardLogo2,
    date: "November 6, 2021",
    url: "https://fortune.com/2021/11/22/million-dollar-homes-nationwide-norm-mortgage-fannie-mae-freddie-mac/#new_tab",
  },
  {
    id: 11,
    text: `“Nik Shah, CEO of Home.LLC, says that home prices will continue to increase [in 2022], just at a decelerated rate.”`,
    image: pressCard1,
    companyLogo: pressCardLogo2,
    date: "December 24, 2021",
    url: "https://fortune.com/2021/12/27/housing-market-home-prices-2022-real-estate-rising-mortgage-rates-impact/",
  },
  {
    id: 12,
    text: `“We are projecting a 5%-6% rise in home prices for 2022, said Shah”`,
    image: pressCard6,
    companyLogo: goBankingLogo,
    date: "December 29, 2021",
    url: "https://www.gobankingrates.com/money/economy/experts-predict-economy-end-2021/",
  },
  {
    id: 13,
    text: `“Nik Shah, CEO of Home.LLC in San Francisco, cautions that…”`,
    image: pressCard3,
    companyLogo: pressCardLogo6,
    date: "January 24, 2022",
    url: "https://themortgagereports.com/87822/first-time-home-buyer-government-programs",
  },
  {
    id: 14,
    text: `“Helping Americans reach the dream of home ownership with Nik Shah”`,
    image: pressCard2,
    companyLogo: lockBoxLogo,
    date: "January 24, 2022",
    url: "https://podcasts.apple.com/us/podcast/lockbox/id1555068342?i=1000547747985",
  },
  {
    id: 15,
    text: `“Renting should always be an option. But it shouldn’t ever become a compulsion.`,
    image: pressCard4,
    companyLogo: productLaunchLogo,
    date: "Mar 3, 2022",
    url: "https://podcasts.apple.com/us/podcast/building-momentum-in-your-startup-with-home-llcs-nik-shah/id1478650695?i=1000552469562",
  },

  {
    id: 16,
    text: `“The 2022 housing market is skewed heavily in favor of sellers,” said Nik Shah, CEO at Home.LLC`,
    image: pressCard5,
    companyLogo: goBankingLogo,
    date: "Mar 24, 2022",
    url: "https://www.gobankingrates.com/investing/real-estate/us-housing-market-is-now-the-right-time-to-be-a-buyer/",
  },

  {
    id: 17,
    text: `“Get pre-approved for a mortgage,” says Nik Shah, CEO of Home.LLC`,
    image: pressCard6,
    companyLogo: realtorLogo,
    date: "Mar 24, 2022",
    url: "https://www.realtor.com/advice/buy/buyers-how-to-stay-positive-in-a-sellers-market/",
  },
  {
    id: 18,
    text: `Even if higher rates price out some buyers, Shah says, it wouldn’t be enough to alleviate the underlying supply and demand mismatch`,
    image: pressCard1,
    companyLogo: pressCardLogo2,
    date: "Mar 24, 2022",
    url: "https://fortune.com/2022/03/22/housing-market-uncharted-waters-fed-interest-rate-hike-mortgage/",
  },
  {
    id: 18,
    text: `Shah tells Fortune that his forecasting shows home prices continuing to rise “in 2022 and beyond”`,
    image: pressCard3,
    companyLogo: pressCardLogo2,
    date: "Mar 24, 2022",
    url: "https://fortune.com/2022/03/23/housing-market-interest-rate-economic-shock/",
  },
  {
    id: 19,
    text: `An analysis by down payment assistance provider Home.LLC looked at urban neighborhoods with a minimum population of 500`,
    image: pressCard2,
    companyLogo: pressCardLogo5,
    date: "Mar 24, 2022",
    url: "https://www.wsj.com/articles/upsizing-for-retirement-11648135790",
  },
  {
    id: 20,
    text: `“It’s never been harder to get a loan to buy a home, Shah says”`,
    image: pressCard4,
    companyLogo: insider,
    date: "April 24, 2022",
    url: "https://www.businessinsider.com/personal-finance/housing-bubble?IR=T",
  },
  {
    id: 21,
    text: `“Home.LLC — directly focused on increasing the population of qualified home buyers.”`,
    image: pressCard5,
    companyLogo: geekEstate,
    date: "May 24, 2022",
    url: "https://drive.google.com/file/d/1xUslqGC7bqXW-ybvkvI3C95TJL9W8Hd_/view?usp=sharing",
  },
  {
    id: 22,
    text: "One in three workers by the end of this decade will be on 1099s or running a small business,” said Nik Shah",
    image: pressCard2,
    companyLogo: pressCardLogo1,
    date: "june 6, 2022",
    url: "https://www.housingwire.com/articles/do-the-gses-need-to-start-thinking-about-non-qm-borrowers/#new_tab",
  },
  {
    id: 23,
    text: `“The most robust markets of today will also be the hardest hit of tomorrow if the housing market crashes, as observed by Fortune and a team of data scientists at Home.LLC.”`,
    image: pressCard6,
    companyLogo: MPALogo,
    date: "June 20, 2022",
    url: "https://www.mpamag.com/us/mortgage-industry/market-updates/real-estate-crash-which-markets-will-survive/408536",
  },
  {
    id: 24,
    text: "Most Florida markets face significant risk of oversupply of inventory,” Nik Shah, CEO of Home.LLC",
    date: "july 5, 2022",
    image: pressCard3,
    companyLogo: pressCardLogo2,
    url: "https://fortune.com/2022/07/15/housing-crash-would-sink-these-16-housing-marketswhile-these-23-markets-would-be-spared/#new_tab",
  },

  {
    id: 25,
    text: `"Most cities will see a deceleration in home prices — not a crash," Home.LLC founder Nik Shah`,
    image: pressCard6,
    companyLogo: pressCardLogo3,
    date: "july 11, 2022",
    url: "https://www.axios.com/2022/07/10/housing-prices-cities-fall#new_tab",
  },

  {
    id: 26,
    text: `Nik Shah, CEO of Home.LLC: “Buyers and sellers will both endure a tough fall housing market.”`,
    image: pressCard3,
    companyLogo: MoneyLogo,
    date: "Oct 20, 2022",
    url: "https://money.com/fall-housing-market-outlook-slowdown/",
  },

  {
    id: 27,
    text: "Fortune and Home.LLC found that many of the housing markets with the highest risk levels are in US.",
    image: pressCard3,
    companyLogo: pressCardLogo2,
    date: "june 5, 2022",
    url: "https://fortune.com/2022/06/05/37-housing-markets-that-will-fare-best-if-a-housing-crash-hits-and-11-that-would-do-the-worst/#new_tab",
  },
  {
    id: 28,
    text: "Shah predicts that the housing sector will peak by the end of this month before beginning to cool down.",
    image: pressCard1,
    companyLogo: pressCardLogo6,
    date: "june 20, 2022",
    url: "https://themortgagereports.com/92359/housing-market-peak-2022#new_tab",
  },
  {
    id: 29,
    text: `“Nik Shah, the CEO of Home LLC, a down payment assistance provider for homebuyers”`,
    image: pressCard4,
    companyLogo: epochTimeLogo,
    date: "May 9, 2022",
    url: "https://www.theepochtimes.com/housing-bubble-2-0-correction-fears-intensify-as-price-median-income-gap-widens_4455154.html#new_tab",
  },

  {
    id: 30,
    text: `Nik Shah: “US home prices are likely to continue rising in 2023, albeit at a much slower pace.”`,
    image: pressCard6,
    companyLogo: rbLogo,
    date: "October 24, 2022",
    url: "https://www.retirebetternow.com/blog/2023-housing-market-predictions/",
  },
  {
    id: 31,
    text: `CEO Nik Shah of Home.LLC. says that one fast way to bring down your mortgage costs is coming…`,
    image: pressCard5,
    companyLogo: forbesLogo,
    date: "May 6, 2022",
    url: "https://www.forbes.com/sites/robertfarrington/2022/05/06/will-higher-interest-rates-finally-cool-the-red-hot-housing-market/?sh=150b961458be#open-web-0#new_tab",
  },
  {
    id: 32,
    text: `“Data scientists at Home.LLC helped us build a forecast model…to identify the housing markets with the most growth upside.”`,
    image: pressCard4,
    companyLogo: pressCardLogo2,
    date: "April 6, 2023",
    url: "https://fortune.com/2022/04/14/us-housing-market-real-estate-prices-rise-most-by-2030/#new_tab",
  },
  {
    id: 33,
    text: `“Fortune teamed up with the data scientists at Home.LLC”`,
    image: pressCard3,
    companyLogo: pressCardLogo2,
    date: "April 14, 2022",
    url: "https://fortune.com/2022/04/14/real-estate-outlook-best-cities/#new_tab",
  },
  {
    id: 34,
    text: `Choose a broker who understands game theory and valuation comparables, says Nik Shah, CEO of San Francisco-based Home.LLC`,
    image: pressCard6,
    companyLogo: pressCardLogo6,
    date: "January 6, 2022",
    url: "https://themortgagereports.com/87819/creative-strategies-to-buy-a-house#new_tab",
  },
  {
    id: 35,
    text: `“Rising mortgage rates have served a body blow to overall refinance activity in 2022,” says Nik Shah, CEO of Home.LLC.”`,
    image: pressCard3,
    companyLogo: realtorLogo,
    date: "November 1, 2022",
    url: "https://www.realtor.com/advice/finance/the-new-rules-on-tapping-home-equity-today-and-some-common-mistakes-to-avoid/",
  },
  {
    id: 36,
    text: `“According to Nik Shah, CEO of Home. LLC, there is lots of room for both home prices and home ownership to grow.”`,
    image: pressCard1,
    companyLogo: geeksLogo,
    date: "November 21, 2022",
    url: "https://wealthofgeeks.com/real-estate-investment/",
  },
  {
    id: 37,
    text: `“We came across some really intriguing data from Home.LLC’s Nik Shah”`,
    image: pressCard5,
    companyLogo: kslLogo,
    date: "October 24, 2022",
    url: "https://omny.fm/shows/dave-and-dujanovic/is-the-utah-housing-market-at-risk-of-oversupplyin",
  },
  {
    id: 38,
    text: `“Nik Shah, CEO at Home.LLC., forecasts that the Fed will increase rates by 25 basis points in its March meeting.”`,
    image: pressCard2,
    companyLogo: pressCardLogo1,
    date: "January 1, 2023",
    url: "https://www.housingwire.com/articles/fed-delivers-the-expected-25-bps-rate-hike-whats-next/",
  },
  {
    id: 39,
    text: `Nik Shah, CEO at Home.LLC., explains that mortgage rates are tailing the treasury yields in the short term… “housing affordability is improving and supporting home prices,” Shah said`,
    image: pressCard5,
    companyLogo: pressCardLogo1,
    date: "March 20, 2023",
    url: "https://www.housingwire.com/articles/mortgage-rates-declined-amid-banks-failures-whats-next/",
  },
  {
    id: 40,
    text: `“Keen on cooling housing demand, the Fed is highly unlikely to cut federal fund rates in 2023,” says Nik Shah, CEO of Home.LLC –`,
    image: pressCard1,
    companyLogo: sfGateLogo,
    date: "April 10, 2023",
    url: "https://www.sfgate.com/personal-finance/article/housing-market-predictions-for-spring-17882937.php",
  },
  {
    id: 41,
    text: ` Nik Shah penciled in a 4 percent year-over-year home price growth for 2023, citing tight supplies, the Federal Reserve cutting interest rates, and low foreclosure risk`,
    image: pressCard4,
    companyLogo: epochTimeLogo,
    date: "April 20, 2023",
    url: "https://www.theepochtimes.com/mortgage-demand-slumps-despite-drop-in-mortgage-rates_5174550.html",
  },
  {
    id: 42,
    text: `Family Office Club Conference`,
    image: bottomCard1,
    companyLogo: bottomLogo1,
    date: "June 25, 2019",
    url: "https://home.llc/press/family-office-club-conference/",
  },
  {
    id: 43,
    text: `The Future of Home Buying – How Proptech Startups Will Disrupt Multifamily Industry`,
    image: bottomCard2,
    companyLogo: bottomLogo2,
    date: "October 1, 2020",
    url: "https://www.unissu.com/proptech-influencers/nik-shah#new_tab",
  },
  {
    id: 44,
    text: `The Jumbo Mortgage Loan Virtual Symposium`,
    image: bottomCard3,
    companyLogo: bottomLogo3,
    date: "April 29, 2019",
    url: "https://home.llc/press/the-jumbo-mortgage-loan-virtual-symposium/",
  },
  {
    id: 45,
    text: `Will Home Prices Crash or Keep Increasing? Investing Passively in Real Estate`,
    image: bottomCard4,
    companyLogo: bottomLogo4,
    date: "July 1, 2021",
    url: "https://www.linkedin.com/posts/nikhaar_will-home-prices-crash-or-keep-increasing-activity-6816357981677060096-825D/?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 46,
    text: `Mortgage Servicing Rights Conference`,
    image: bottomCard5,
    companyLogo: bottomLogo3,
    date: "October 28, 2021",
    url: "https://home.llc/press/mortgage-servicing-rights-conference/",
  },
  {
    id: 47,
    text: `The 3rd Annual Non-QM Forum`,
    image: bottomCard5,
    companyLogo: bottomLogo3,
    date: "June 3, 2022",
    url: "https://home.llc/press/the-3rd-annual-non-qm-forum/",
  },
  {
    id: 48,
    text: `Residential Real Estate Forum: Strategies and Innovations for Home Ownership Attainability`,
    image: pressCard4,
    companyLogo: bottomLogo5,
    date: "September 17, 2022",
    url: "https://www.atlantafed.org/news/conferences-and-events/webinars/2022/09/07/residential-real-estate-market-forum",
  },
  {
    id: 49,
    text: `8th Annual Residential Mortgage Servicing Rights Forum`,
    image: bottomCard5,
    companyLogo: bottomLogo3,
    date: "November 15, 2022",
    url: "https://home.llc/press/conference-speaker-on-post-covid-19-home-values-valuation/",
  },
  {
    id: 50,
    text: `Carnegie Mellon University: Tartans On the Rise`,
    image: bottomCard6,
    companyLogo: bottomLogo6,
    date: "April 25, 2023",
    url: "https://www.cmu.edu/engage/alumni/get-involved/tartansontherise/2023/shah.html",
  },
  {
    id: 51,
    text: `OnDeck: Fintech Fellow`,
    image: bottomCard7,
    companyLogo: bottomLogo7,
    date: "April 25, 2023",
    url: "https://home.llc/press/ondeck-fintech-fellow/",
  },
  {
    id: 52,
    text: `Welp Magazine: Top Real Estate Investment Companies in San Francisco`,
    image: bottomCard8,
    companyLogo: bottomLogo8,
    date: "April 25, 2021",
    url: "https://welpmagazine.com/these-are-the-top-real-estate-investment-companies-in-san-francisco-2021/#new_tab",
  },
  {
    id: 53,
    text: `New York VC Network: Top 20 Startups`,
    image: pressCard2,
    companyLogo: bottomLogo9,
    date: "January 25, 2021",
    url: "https://www.vcnet.nyc/nyc-top-50/january-2021",
  },
];

// Nik Shah: “US home prices are likely to continue rising in 2023, albeit at a much slower pace.”
// “Nik Shah, the CEO of Home LLC, a down payment assistance provider for homebuyers”
// CEO Nik Shah of Home.LLC. says that one fast way to bring down your mortgage costs is coming…

export const faqCategories = ["All", "Buy", "Borrow", "Sell"];

export const blog = [
  {
    id: 1,
    title: "How Long Does It Take To Close On A House In California?",
    desc: "Buying a house is a monumental step. It’s a blend of excitement, dreams, and, let’s be honest, a fair bit of anxiety. One of the pivotal moments in this journey is the closing process—the final lap in the race to your new home. But just how long does it take to close on a house.",
    category: "buyers",
    cover: "../images/blogs/b1.jpg",
    date: "APRIL 04, 2024",
  },
  {
    id: 2,
    title: "How To Pick A Real Estate Agent in San Diego?",
    desc: "When you decide to sell your house, one of the most crucial decisions you’ll make is choosing the right real estate agent. This choice can not only alleviate much of the stress associated with selling your home but can also significantly impact how quickly you sell and the profit you make. In this article, we […]",
    category: "owner",
    cover: "../images/blogs/b2.jpg",
    date: "APRIL 11, 2024",
  },
  {
    id: 3,
    title: "START YOU CUP ON TABLE OF THAT",
    desc: "Duis rutrum eu tortor et efficitur. Nulla ac consequat leo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut vestibulum mattis est eu volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae tristique nibh. Quisque vel lectus interdum lacus ultricies venenatis. In ex nisl, commodo vitae risus ac, efficitur imperdiet dolor. Maecenas dignissim luctus mi sit amet mollis. Maecenas sit amet bibendum dolor, a molestie tellus. Donec et varius risus. Duis tincidunt mi lorem, vitae luctus turpis rutrum sit amet. Aliquam aliquet malesuada odio, ut pellentesque nisi tempus in. Ut fermentum, augue id accumsan aliquet, dolor dui euismod elit, nec fringilla mi justo nec mauris. Duis tellus nisl, elementum et urna at, molestie condimentum eros. Sed mattis quis risus id tincidunt. Donec ultrices vestibulum vestibulum.",
    category: "investor",
    cover: "../images/blogs/b3.jpg",
    date: "MARCH 15, 2018",
  },
  {
    id: 4,
    title: "START YOU CUP ON TABLE OF THAT",
    desc: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition.",
    category: "buyers",
    cover: "../images/blogs/b4.jpg",
    date: "February 20, 2018",
  },
  {
    id: 5,
    title: "SMART TYPE OF GAMING WITH CO-OP",
    desc: "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition.",
    category: "owner",
    cover: "../images/blogs/b5.jpg",
    date: "February 20, 2018",
  },
  {
    id: 6,
    title: "TEACH YOUR DRAGON HOW TO FLY",
    desc: "Magnis modipsae que lib voloratati andigen daepedor quiate ut reporemni aut labor. Laceaque quiae sitiorem ut restibusaes es tumquam core posae volor remped modis volor. Doloreiur qui commolu ptatemp dolupta orem retibusam emnis et consent accullignis lomnus. We don't want to overload you with hundreds of styles you don't want, or need. We give you a strong base to express your own creativity.",
    category: "investor",
    cover: "../images/blogs/b6.jpg",
    date: "February 20, 2018",
  },
  {
    id: 7,
    title: "TEACH YOUR DRAGON HOW TO FLY",
    desc: "Magnis modipsae que lib voloratati andigen daepedor quiate ut reporemni aut labor. Laceaque quiae sitiorem ut restibusaes es tumquam core posae volor remped modis volor. Doloreiur qui commolu ptatemp dolupta orem retibusam emnis et consent accullignis lomnus. We don't want to overload you with hundreds of styles you don't want, or need. We give you a strong base to express your own creativity.",
    category: "buyers",
    cover: "../images/blogs/b7.jpg",
    date: "February 20, 2018",
  },
  {
    id: 8,
    title: "TEACH YOUR DRAGON HOW TO FLY",
    desc: "Magnis modipsae que lib voloratati andigen daepedor quiate ut reporemni aut labor. Laceaque quiae sitiorem ut restibusaes es tumquam core posae volor remped modis volor. Doloreiur qui commolu ptatemp dolupta orem retibusam emnis et consent accullignis lomnus. We don't want to overload you with hundreds of styles you don't want, or need. We give you a strong base to express your own creativity.",
    category: "owner",
    cover: "../images/blogs/b8.jpg",
    date: "February 20, 2018",
  },
  {
    id: 9,
    title: "TEACH YOUR DRAGON HOW TO FLY",
    desc: "Magnis modipsae que lib voloratati andigen daepedor quiate ut reporemni aut labor. Laceaque quiae sitiorem ut restibusaes es tumquam core posae volor remped modis volor. Doloreiur qui commolu ptatemp dolupta orem retibusam emnis et consent accullignis lomnus. We don't want to overload you with hundreds of styles you don't want, or need. We give you a strong base to express your own creativity.",
    category: "investor",
    cover: "../images/blogs/b9.jpg",
    date: "February 20, 2018",
  },
  {
    id: 10,
    title: "TEACH YOUR DRAGON HOW TO FLY",
    desc: "Magnis modipsae que lib voloratati andigen daepedor quiate ut reporemni aut labor. Laceaque quiae sitiorem ut restibusaes es tumquam core posae volor remped modis volor. Doloreiur qui commolu ptatemp dolupta orem retibusam emnis et consent accullignis lomnus. We don't want to overload you with hundreds of styles you don't want, or need. We give you a strong base to express your own creativity.",
    category: "buyers",
    cover: "../images/blogs/b10.jpg",
    date: "February 20, 2018",
  },
];

export const cardsData = [
  {
    id: 1,
    title: "Find The Right Home for You",
    desc: "Discover your ideal home effortlessly with our data-driven analytics that cover everything from climate to schools, addressing all your concerns.",
    img: card1Image,
  },
  {
    id: 2,
    title: "We Negotiate So You Don’t Have to",
    desc: "Our experienced agents analyze market trends, leverage industry insights, and advocate for your best interests to ensure you get the most out of every deal.",
    img: card2Image,
  },
  {
    id: 3,
    title: "With You, At Each Step",
    desc: "Benefit from personalized assistance and expert guidance every step of the way, ensuring a smooth and enjoyable home-search experience.",
    img: card3Image,
  },
  {
    id: 4,
    title: "Gain from Exclusive Partnerships",
    desc: "Get access to our network of exclusive partnerships designed to enhance your homebuying experience and make it even smoother.",
    img: card4Image,
  },

  {
    id: 5,
    title: "All at Only 1% Down Payment",
    desc: "You get to pave your way to homeownership without breaking the bank. Start your journey with Home.LLC's at just 1% down payment.",
    img: card5Image,
  },
];

// faqs data

// Seller how can we help you data
export const helpCards = [
  {
    id: 1,
    title: "Find The Right Home for You",
    description:
      "Maximize your property's value with informed sales timing guided by data-driven analytics. Sell at the perfect time for optimal results.",
    img: card1,
  },
  {
    id: 2,
    title: "We Negotiate So You Don’t Have to",
    description:
      "Our experienced agents navigate the negotiation process skillfully to achieve optimal results for you. We work to secure you the best offer.",
    img: card2,
  },
  {
    id: 3,
    title: "Gain from Exclusive Agent Partnership",
    description:
      "Maximize your property's value with informed sales timing guided by data-driven analytics. Sell at the perfect time for optimal results.",
    img: card3,
  },
  {
    id: 4,
    title: "Low Rates, Top Service",
    description:
      "Our low commission rates ensure you maximize your profit while receiving top-notch service.",
    img: card4,
  },
];

// cultureSummary
export const cultures = [
  {
    cultureTitle: "Change to Customer Obsession",
    cultureText:
      "Obsess on helping your end user improve their reality, instead of obsessing about your own perception of yourself. Happy customers lead to happy shareholders, the opposite isn't necessarily true.",
    id: 1,
  },
  {
    cultureTitle: "Embrace the Discomfort",
    cultureText:
      "Embracing discomfort will lead to comfort. Embracing comfort guarantees discomfort. You will never escape pain, uncertainty, or work so you might as well embrace them, as long as it's legal and ethical.",
    id: 2,
  },
  {
    cultureTitle: "Freedom & Responsibility",
    cultureText:
      "Use freedom to take decisions in exchange for accepting the responsibility to making them in the best interest of the company. The more responsibility you take, the more freedom you get. The less ownership you take, the more quickly our relationship will terminate. Be an owner, not a renter.",
    id: 3,
  },
  {
    cultureTitle: "Team, Not a Family",
    cultureText:
      "We are a team focused on winning championships by selecting the best players that work together. We are not your family as you already have a family of loved ones. We'll re-hire you every year only if you are the best player to help us win. Just because you are not a 'navy seal' in our team doesn't mean you can't be a great 'president' or 'general' somewhere else.",
    id: 4,
  },
  {
    cultureTitle: "The Opposite of Winning Isn't Losing, It's Quitting",
    cultureText:
      "Focus on collecting as many 'No's' as quickly as possible to get to an eventual 'Yes'. As long as you don't give up playing, you will eventually win. Anyone who's ever climbed a mountain or run a marathon just put one foot in front of the other. Just focus on putting down your next step. Actively ignore everything outside your direct control.",
    id: 5,
  },
];
