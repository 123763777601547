import React, { useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";

const ComfortScore=() => {
  const chartRef=useRef(null);

  const option={
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        center: ["50%", "75%"], // Adjusted to move the chart upwards
        radius: "100%", // Increased to utilize the full height
        min: 0,
        max: 1,
        axisLine: {
          lineStyle: {
            width: 60,
            color: [
              [0.33, "#B6C8E5"],
              [0.66, "#5090F6"],
              [1, "#0F5DDD"],
            ],
          },
        },
        pointer: {
          icon: "path://M5.016 0 0 .003 2.506 2.5 5.016 5l2.509-2.5L10.033.003 5.016 0z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-88%"],
          itemStyle: {
            color: "black",
          },
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: "auto",
            width: 0,
          },
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: "auto",
            width: 0,
          },
        },
        axisLabel: {
          show: false,
        },
        title: {
          offsetCenter: [0, "-10%"],
          fontSize: 20,
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, "-10%"],
          valueAnimation: true,
          formatter: function (value) {
            return `{value|${Math.round(value*10)}}{unit|/10}`;
          },
          rich: {
            value: {
              fontSize: 80,
              color: "black",
              fontWeight: "bold",
            },
            unit: {
              fontSize: 30,
              color: "black",
            },
          },
          color: "black",
        },
        data: [
          {
            value: 0.4,
            name: "",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);

  return (
    <div className="flex flex-col ">
      <div className="text-xl font-medium ml-5">Comfort Score</div>
      <div className="relative -mt-12  ml-14">
        <ReactEcharts
          ref={chartRef}
          style={{ width: 300, height: 310 }}
          option={option}
        />
        <div className="w-[340px] absolute bottom-9 -left-4 text-sm flex  justify-between">
          <span className="text-center">
            Uncomfortable <br /> Weather
          </span>
          <span className="text-center">
            Comfortable <br /> Weather
          </span>
        </div>
      </div>
    </div>
  );
};

export default ComfortScore;
