import React from "react";

const PinkSection = () => {
  const bgImageUrl = "https://media.istockphoto.com/id/1342468806/photo/3d-rendering-of-cozy-greenhouse-with-white-bed-and-illuminated-candles-at-night.jpg?s=612x612&w=0&k=20&c=RIinAnEXmSsdtl2EnI14-FD6ZT6WdswdSy1FwUsD5kQ=";

  return (
    <div
      className=" p-10 text-center text-white  bg-cover bg-center relative"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <div className="absolute inset-0 bg-black-100 opacity-75"></div> {/* Overlay if needed */}
      <h2 className="text-2xl sm:text-5xl mt-12 mb-4 sm:mb-12 relative">Ready To Get <span className="font-bold">Started?</span> </h2>
      <p className="mb-12 sm:mb-28 relative">Answer a few questions to see how we can help you!</p>
      <div className="flex flex-col sm:flex-row sm:justify-between gap-6 mb-16 relative">
        <a href="/analyze">
        <button className="border border-white rounded-full px-20 py-2 hover:bg-white hover:text-black transition">
          Analyse
        </button>
        </a>
        <a href="/buy">
        <button className="border border-white rounded-full px-20 py-2 hover:bg-white hover:text-black transition">
          Buy
        </button>
        </a>
        <a href="/invest">
        <button className="border border-white rounded-full px-20 py-2 hover:bg-white hover:text-black transition">
          Invest
        </button>
        </a>

        <a href="/cashback">
          <button className="border border-white rounded-full px-20 py-2 hover:bg-white hover:text-black transition">
          Finance
        </button>
        </a>

        <a href="/seller-form">
          <button className="border border-white rounded-full px-20 py-2 hover:bg-white hover:text-black transition">
          Sell
        </button>
        </a>
       
      </div>
    </div>
  );
};

export default PinkSection;
