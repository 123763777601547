import { CounterContext } from "../../../hooks/HomeAi";
import { useContext, useState } from "react";
// Define age groups
const inComeGroups=[
  { label: '<$10k', min: 0, max: 100000, count: 0 },
  { label: '$100k - $250k', min: 100000, max: 250000, count: 0 },
  { label: '$250k - $500k', min: 250000, max: 500000, count: 0 },
  { label: '$500k - $750k', min: 500000, max: 750000, count: 0 },
  { label: '$750k>', min: 750000, max: Infinity, count: 0 }
];

// Define background colors based on the rank
const bgColors=[
  'bg-[#0F5DDD]',
  'bg-[#1F75FE]',
  'bg-[#5090F6]',
  'bg-[#8BB3F4]',
  'bg-[#B6C8E5]'
];

const IncomeComparison=() => {
  const [hoveredIndex, setHoveredIndex]=useState(null);

  const { incomeDistributionOfHouseHolds }=useContext(CounterContext);

  return (
    <div
      className='flex mt-8 w-full min-w-[50px]'>
      {incomeDistributionOfHouseHolds?.map((group, index) => (
        index<=4&&(
          <div
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            key={group.label}
            className="h-[130px] w-1/5 border-x border-gray-400">
            <div className={`h-[100px] p-4 mt-4 ${bgColors[index]} flex items-center justify-center cursor-pointer`}>
              {hoveredIndex===index&&<p className="text-md font-semibold text-white">{group.percent}%</p>}
            </div>
            <p className="text-center text-black text-xs">{group.label}</p>
          </div>
        )
      ))}
    </div>
  );
};

export default IncomeComparison;
