import React from "react";
import SellerHero from "../components/SellerLanding/SellerHero";
// import HomeOwnerShip from "../components/SellerLanding/HomeOwnerShip";
// import WhyChooseAgent from "../components/SellerLanding/WhyChooseAgent";
// import CalendyContact from "../components/SellerLanding/CalendyContact";
// import CommisionCalculator from "../components/SellerLanding/CommisionCalculator";
import Header from "../components/HomePage/Navbar"
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
// import Testimonials from "../components/HomePage/TestimonialSlider";
import TidyCalContact from "../components/BuyerLanding/TidyCal";
import FAQSection from "../components/SellerLanding/FAQSection";
import HowCanWeHelp from "../components/SellerLanding/HowCanWeHelp";
import LogoSection from "../components/HomePage/LogoSection";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";

const Seller = () => {
  return (
    <div className="overflow-x-hidden">
      <Header/>
      <SellerHero />
      <LogoSection />
      <HowCanWeHelp />
      {/* <HomeOwnerShip /> */}
      {/* <WhyChooseAgent /> */}
      {/* <CalendyContact /> */}
      {/* <Testimonials/> */}
      {/* <CommisionCalculator /> */}
      <TidyCalContact operation={'Sell'}/>
      <FAQSection />
      <NeedFromUs />
      <Footer />
    </div>
  );
};

export default Seller;
