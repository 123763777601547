import React, { useContext, useEffect, useRef } from "react";
import { peopleTypes } from "../data";
import { CanvasJSChart } from "canvasjs-react-charts";
import ReactEcharts from "echarts-for-react";
import { CounterContext } from "../../../hooks/HomeAi";
import { XAxis } from "recharts";

const RaceComparison = () => {
  const chartRef = useRef(null);
  const { race } = useContext(CounterContext);

  const option = {
    xAxis: {
      type: "category",
      showGrid: false,
      data: [
        "White",
        "Black",
        "Native",
        "Asian",
        "Islander",
        "Other",
        "Two",
        "Hispanic",
      ],
      axisLabel: {
        interval: 0, // Display all labels
        margin: 10, // Adjust margin to prevent label cut-off
        formatter: function (value) {
          // Implement custom formatter if needed
          return value;
        },
        textStyle: {
          overflow: "break", // Enable text wrapping
          whiteSpace: "normal", // Allow normal wrapping behavior
        },
      },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    yAxis: {
      showGrid: false,
      type: "value",
      splitLine: {
        show: false,
      },
      axisLine: { show: false },
    },
    grid: {
      top: 20,
      show: false,
      bottom: 60,
      left: 50,
      right: 20,
    },
    series: [
      {
        data: Object.values(race || {}),
        type: "bar",
        itemStyle: {
          // Customize bar colors
          borderRadius: 5,
          color: "#ED5272",
        },
        label: {
          show: true,
          position: "top", // Display value on top of each bar
          formatter: function (params) {
            // Calculate percentage
            const total = Object.values(race).reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((params.data / total) * 100).toFixed(2) + "%";
            return percentage;
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);
  return (
    <div>
      <ReactEcharts ref={chartRef} style={{ width: "100%" }} option={option} />{" "}
    </div>
  );
};
export default RaceComparison;
