import { useEffect, useState } from "react";

const SliderComponentWithValue = ({
  label,
  min,
  max,
  initialValue,
  onValueChange,
  sliderValueStyle,
  sliderInputStyle,
  editable = true,
  ...sliderProps
}) => {
  const [sliderValue, setSliderValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleSliderChange = (event) => {
    const newValue = Math.round(Number(event.target.value) / 1000) * 1000;
    setSliderValue(newValue);

    // Call the callback function if provided
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const handleValueClick = () => {
    setIsEditing(true);
  };

  const handleInputBlur = (event) => {
    const inputValue = Number(event.target.value);
    if (!isNaN(inputValue) && inputValue >= min && inputValue <= max) {
      setSliderValue(Math.round(inputValue / 1000) * 1000);
      if (onValueChange) {
        onValueChange(inputValue);
      }
    }
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  useEffect(() => {
    setSliderValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {editable ? (
        <div className="flex flex-col items-center text-white font-poppins">
          {isEditing ? (
            <input
              type="text"
              autoFocus
              value={sliderValue}
              onChange={(e) => setSliderValue(Number(e.target.value))}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              className="w-[350px] text-white text-center bg-transparent border-none outline-none text-[48px] font-poppins font-normal mb-2"
            />
          ) : (
            <p
              className={sliderValueStyle}
              onClick={handleValueClick}
              role="button"
              tabIndex={0}
            >
              $
              {sliderValue
                ? Math.round(sliderValue).toLocaleString("en-US")
                : "0"}
            </p>
          )}

          <div className="w-full max-w-md flex flex-col items-start mt-4">
            <input
              type="range"
              min={min}
              max={max}
              value={sliderValue}
              onChange={handleSliderChange}
              className={`h-2 bg-white rounded-full accent-custom-primaryPink ${sliderInputStyle}`}
              {...sliderProps}
            />
          </div>

          <div className="flex justify-between items-center mt-2 w-full">
            <span className="text-lg mr-4">
              ${min?.toLocaleString("en-US")}
            </span>
            <span className="text-lg ml-4">
              ${max?.toLocaleString("en-US")}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center text-white font-poppins">
          <>
            <p
              className={sliderValueStyle}
              onClick={handleValueClick}
              role="button"
              tabIndex={0}
            >
              $
              {sliderValue
                ? Math.round(sliderValue).toLocaleString("en-US")
                : "0"}
            </p>
            <div className="w-full max-w-md flex flex-col items-start mt-4">
              <input
                type="range"
                min={min}
                max={max}
                className={`h-2 bg-white rounded-full accent-custom-primaryPink ${sliderInputStyle}`}
                value={sliderValue}
                onChange={() => {}}
                // {...sliderProps}
              />
            </div>

            <div className="flex justify-between items-center mt-2 w-full">
              <span className="text-lg mr-4">
                ${min?.toLocaleString("en-US")}
              </span>
              <span className="text-lg ml-4">
                ${max?.toLocaleString("en-US")}
              </span>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default SliderComponentWithValue;
