import React from 'react'
import MapBoxComponent from '../../MapBoxComponent'
import ComparisonBox from './ComparisonBox'

const marketData=[{
    area: "94043",
    percent: "82",

},
{
    area: "9401",
    percent: "79",
}, {
    area: "94011",
    percent: "88",
}, {
    area: "Bay Area",
    percent: "88",
},
]

const MarketHotness=() => {
    return (
      <div className="flex flex-col md:flex-row gap-10 w-full">
      <div className="w-full md:w-3/5 rounded-xl overflow-hidden">
        <MapBoxComponent
          styleUrl={"mapbox://styles/jayambalani/cly946tpj00ig01nw1uop9383"}
          height={"450px"}
        />
      </div>
      <div className="w-full md:w-2/5 flex flex-col max-h-[450px] mt-0 md:mt-[-14px]">
        <div className='flex flex-col'>
          <div className='flex flex-col mb-6'>
            <h2 className='text-2xl md:text-3xl font-semibold'>Market Hotness</h2>
            <p className='text-base md:text-sm'><span className='text-4xl md:text-6xl font-semibold'>93</span>/100</p>
            <div className='w-full h-[20px] rounded-lg bg-[#D5EBF8] relative'>
              <div className='absolute w-[80%] h-full rounded-lg bg-gradient-to-r from-[#0D48F9] via-[#8664A8] to-[#FF2525]'></div>
            </div>
            <p className='text-base md:text-sm'>Most homes get multiple offers, often with waived contingencies.</p>
          </div>
          <div className='flex flex-col'>
            <h2 className='text-2xl md:text-3xl font-semibold'>Comparison</h2>
            <div className='flex flex-col max-h-[170px] overflow-y-scroll scrollnon'>
              {marketData.map((market, index) => (
                <ComparisonBox isBar={true} area={market.area} percent={market.percent} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default MarketHotness