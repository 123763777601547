import React from "react";

import Header from "../components/HomePage/Navbar";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import LegalHero from "../components/Legal/LegalHero";
import LegalSection from "../components/Legal/LegalSection";

const Legal = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <LegalHero />
      <LegalSection />
      <Footer />
    </div>
  );
};

export default Legal;
