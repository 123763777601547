// export default HomeMapBoxComponent;
import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { CounterContext } from "../../hooks/HomeAi";

// Set your Mapbox access token (replace "YOUR_ACCESS_TOKEN" with your actual Mapbox access token)
mapboxgl.accessToken =
  "pk.eyJ1IjoiamF5YW1iYWxhbmkiLCJhIjoiY2x0M29sdWwwMDQxZjJxcDkwc2lpYWlueSJ9.9ZrrunddV5NRdIV8VeRNdw";

const HomeMapBoxComponent = ({ height }) => {
  const { boundrydata } = useContext(CounterContext);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  // Initialize map only once
  useEffect(() => {
    if (map.current) return; // Map already initialized

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/jayambalani/cly4kckyi00aa01pfby7o6mad",
      center: [-122.0919524, 37.4168363], // Default center coordinates
      zoom: 11,
    });

    map.current.on("load", () => {
      setIsMapLoaded(true);
    });

    // Clean up map on component unmount
    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, []);

  // Function to add map layers
  const addMapLayers = () => {
    if (!map.current || !isMapLoaded || !boundrydata?.geometry) return;

    // Check if layer exists before removing
    if (map.current.getLayer("maine")) {
      map.current.removeLayer("maine");
    }
    if (map.current.getLayer("outline")) {
      map.current.removeLayer("outline");
    }
    // Check if source exists before removing
    if (map.current.getSource("maine")) {
      map.current.removeSource("maine");
    }

    // Add new source and layers with updated data
    map.current.addSource("maine", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: boundrydata.geometry,
      },
    });

    map.current.addLayer({
      id: "maine",
      type: "fill",
      source: "maine",
      layout: {},
      paint: {
        "fill-color": "#fff",
        "fill-opacity": 0.4,
        "fill-opacity-transition": { duration: 500 },
      },
    });

    map.current.addLayer({
      id: "outline",
      type: "line",
      source: "maine",
      layout: {},
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    });
  };

  // Update map when boundrydata changes
  useEffect(() => {
    if (isMapLoaded && boundrydata?.geometry) {
      addMapLayers();
      if (boundrydata.geometry.type === "MultiPolygon") {
        map.current.setCenter(boundrydata.geometry.coordinates[0][0][0]);
      } else {
        map.current.setCenter(boundrydata.geometry.coordinates[0][0]);
      }
    }
  }, [boundrydata, isMapLoaded]);
  return (
    <div
      className="!rounded-xl"
      ref={mapContainer}
      style={{ width: "100%", height: "367px", borderRadius: "30px" }}
    />
  );
};

export default HomeMapBoxComponent;
