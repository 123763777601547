import React from 'react'


import { useEffect, useRef } from 'react';

const Videos = () => {
  const cloudinaryRef = useRef();
  const videoRef = useRef();
  

  useEffect(() => {
    if ( cloudinaryRef.current ) return;

    cloudinaryRef.current = window.cloudinary;
    cloudinaryRef.current.videoPlayer(videoRef.current, {
      cloud_name: 'drlajr8mr'
    })
  }, [cloudinaryRef]);

  return (
    <video
      style={{backgroundColor: 'transparent'}}
        ref={videoRef}
        data-cld-public-id="EQUITY_INTRO_VIDEO_bukcjt"
      controls
      className='w-full h-full'
      />  
  )
}

export default Videos
// import { Video, CloudinaryContext } from 'cloudinary-react'

// const Videos = () => {
//   return (
//     <div>
//     <CloudinaryContext cloudName="drlajr8mr">
//       <div>
//       <Video
//   publicId="EQUITY_INTRO_VIDEO_bukcjt"
//   controls
//   autoPlay
//   format="auto"
//   quality="auto"
//   muted  // Add this to enable autoplay in most browsers
// /> 
//       </div>
//     </CloudinaryContext>
//     </div>
//   )
// }

// export default Videos
