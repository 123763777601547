import React, { useContext, useEffect, useRef } from "react";
import { usersData } from "../data";
import ReactEcharts from "echarts-for-react";
import { CounterContext } from "../../../hooks/HomeAi";

const totalUsers=usersData.length;
const marriedCount=usersData.filter((user) => user.married).length;
const unmarriedCount=totalUsers-marriedCount;

const marriedPercentage=((marriedCount/totalUsers)*100).toFixed(2);
const unmarriedPercentage=((unmarriedCount/totalUsers)*100).toFixed(2);
function convertAgeGroupToArray(data) {
  const dataArray=[];

  for (const key in data) {
    dataArray.push({
      name: key,
      value: data[key],
    });
  }

  return dataArray;
}

const CircularCard=({ marriedPercentage, unmarriedPercentage }) => {
  const chartRef=useRef(null);
  const { marital }=useContext(CounterContext);

  const option={
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        type: "pie",
        radius: "62%",
        data: convertAgeGroupToArray(marital).map((item) => {
          if (item.name==="Married") {
            return {
              ...item,
              itemStyle: { color: "#FF5B7D" },
            };
          } else if (item.name==="Unmarried") {
            return {
              ...item,
              itemStyle: { color: "#379DFC" },
            };
          } else {
            return item;
          }
        }),
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return `{name|${params.name}}\n{value|${params.percent}%}`;
          },
          rich: {
            name: {
              fontSize: 12,
              lineHeight: 16,
              color: '#fff',
            },
            value: {
              fontSize: 20,
              fontWeight: 'bold',
              lineHeight: 35,
              color: '#fff',
            },
          },
          alignTo: 'edge',
          edgeDistance: '10%',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current) {
      // Trigger chart rendering when the modal is displayed
      chartRef.current.getEchartsInstance().resize();
    }
  }, []);
  return (
    <div className="w-[400px] h-[400px] overflow-hidden ">
      <ReactEcharts ref={chartRef} style={{ width: "100%", height: "100%" }} option={option} />{" "}
    </div>
  );
};

const MarriageComparision=() => {
  return (
    <div className="">
      <CircularCard
        marriedPercentage={marriedPercentage}
        unmarriedPercentage={unmarriedPercentage}
      />
    </div>
  );
};

export default MarriageComparision;
