import React, { useState } from "react";
import linkedin from "../assets/footer-linkedin.svg";
import facebook from "../assets/footer-facebook.svg";
import x from "../assets/footer-x.svg";
import instagram from "../assets/footer-instagram.svg";
import Popup from "./Popup";

const footerItems = [
  {
    title: "About Us",
    links: [
      { text: "Home", url: "/" },
      { text: "Our Team", url: "/team" },
      { text: "FAQs", url: "/FAQ" },
      { text: "Culture", url: "/culture" },
      { text: "Press", url: "/press" },
      { text: "Privacy", url: "/legal?tab=privacy-policy" },
      { text: "Legal", url: "/legal" },
    ],
  },
  {
    title: "Services",
    links: [
      { text: "Analyze", url: "/analyze" },
      { text: "Buy", url: "/buyer" },
      { text: "Finance", url: "/finance" },
      { text: "Invest", url: "/invest" },
      { text: "Sell", url: "/sell" },
    ],
  },
  {
    title: "Check if you qualify",
    links: [
      { text: "Finance", url: "/cashout" },
      { text: "Buy", url: "/buy" },
      { text: "Sell", url: "/seller-form" },
    ],
  },
];

const Footer = () => {
  const [userEmail, setUserEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(value);
  };

  const handleSubscribe = async () => {
    if (userEmail !== "") {
      if (validateEmail(userEmail)) {
        try {
          const response = await fetch(
            "https://hooks.zapier.com/hooks/catch/10869005/2y64sba/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: userEmail }),
              mode: "no-cors",
            }
          );

          console.log(response);
          console.log("Subscription successful");
          setUserEmail("");
          setShowPopup(true);
          setInvalidEmail(false);
        } catch (error) {
          console.error("Subscription failed:", error);
          alert("Failed to subscribe. Please try again later.");
        }
      } else {
        setInvalidEmail(true);
        alert("Enter a valid Email");
      }
    } else {
      alert("Enter Email to subscribe");
    }
  };

  return (
    <div className="w-full h-full sm:h-auto">
      <div
        className="px-4 sm:px-8 flex-wrap lg:flex-nowrap gap-y-10 sm:gap-y-0 gap-x-20 lg:gap-x-[40px] pt-[63px] mb-16 flex flex-row 
       items-start w-full 2xl:max-w-[1568px] xl:mx-auto xl:justify-between 2xl:gap-0"
      >
        {/* First row */}
        <div>
          <h3 className="text-xl sm:text-2xl font-medium text-black mb-[15px]">
            {footerItems[0].title}
          </h3>
          <ul className="font-quickSand">
            {footerItems[0].links.map((link, index) => (
              <li key={index} className="mb-[18px]">
                <a
                  className="text-lg sm:text-[16px] leading-[18px] font-normal text-[#000000]"
                  href={link.url}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Second row */}
        <div className="flex flex-col gap-[40px]">
          {footerItems.slice(1, 2).map((item, index) => (
            <div key={index}>
              <h3 className="text-xl sm:text-2xl font-medium text-black mb-[15px]">
                {item.title}
              </h3>
              <ul className="font-quickSand">
                {item.links.map((link, linkIndex) => (
                  <li className="mb-[18px]" key={linkIndex}>
                    <a
                      className="text-lg sm:text-[16px] leading-[18px] font-normal text-[#000000]"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Third row */}
        <div className="flex flex-row sm:flex-col">
          {footerItems.slice(2, 4).map((item, index) => (
            <div key={index}>
              <h3 className="text-xl sm:text-2xl font-medium text-black mb-[15px]">
                {item.title}
              </h3>
              <ul className="font-quickSand">
                {item.links.map((link, linkIndex) => (
                  <li className="mb-[18px]" key={linkIndex}>
                    <a
                      className="text-lg sm:text-[16px] leading-[18px] font-normal text-[#000000]"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="w-full xl:w-[542px]">
          <h3 className="text-lg sm:text-2xl font-medium text-black mb-[15px] capitalize">
            Get the Latest Insights
          </h3>
          <p className="text-sm sm:text-[17px] leading-[25px] mb-10 sm:mb-20 font-quickSand">
            Subscribe to our monthly newsletter for the latest homeownership
            insights, real stories from Home.LLC homeowners, and real estate
            updates delivered straight to your inbox.
          </p>

          <div className="relative w-full bg-white shadow-2xl py-3 flex items-center justify-between">
            <input
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Enter Email"
              className={`w-[210px] sm:w-auto text-black placeholder:text-[#21252980] px-8 py-1 focus:outline-none font-quickSand 
        text-xl font-medium ${
          invalidEmail ? "border-2 border-red-500 rounded-xl" : ""
        }`}
            />

            <button
              className="w-[136px] h-[40px] xl:w-[159px] xl:h-[50px] rounded-[40px]
        bg-[#212529] text-white text-sm sm:text-xl sm:px-4 sm:py-2"
              type="submit"
              onClick={handleSubscribe}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <a
        href="https://drive.google.com/file/d/1ohmt12BLWvkXZuubiukqg1vnjdVnxu4O/view?usp=sharing"
        rel="noreferrer"
        target="blank"
      >
        <p className="text-sm my-7 font-semibold text-center text-black cursor-pointer px-8">
          California DRE #02213037
        </p>
      </a>

      <div className="px-4 bg-[#212529] h-full flex-col sm:flex-row w-full sm:h-[90px] mt-1 hidden sm:flex sm:justify-between items-start sm:items-center gap-10 sm:gap-0">
        <div>
          <p className="text-white text-sm sm:text-[16px] 2xl:text-lg font-medium cursor-pointer">
            © 2024 Hoom Inc., which owns the trademark to Home.LLC™. All rights
            reserved.
          </p>
        </div>

        <div className="flex gap-8 items-center">
          <a
            href="https://www.linkedin.com/company/hoominc/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={linkedin}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>

          <a
            href="https://twitter.com/HomeDotLLC/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={x}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>
          <a
            href="https://www.facebook.com/HomeDotLLC/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={facebook}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>

          <a
            href="https://www.instagram.com/home.llc_"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={instagram}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>
        </div>

        <div className="flex gap-6 items-center">
          <a href="/legal?tab=terms-of-use">
            <p className="text-white text-sm sm:text-lg font-medium cursor-pointer">
              {" "}
              • Terms of Use{" "}
            </p>
          </a>
          <a href="/legal?tab=privacy-policy">
            <p className="text-white text-sm sm:text-lg font-medium cursor-pointer">
              {" "}
              • Privacy Policy{" "}
            </p>
          </a>
        </div>
      </div>

      {/* mobile version */}
      <div className="py-10 bg-[#212529] h-full flex-col sm:flex-row w-full sm:h-[90px] mt-1 flex sm:hidden justify-center items-center sm:items-center gap-6 sm:gap-0">
        <div className="flex gap-8 items-center">
          <a
            href="https://www.linkedin.com/company/hoominc/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={linkedin}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>

          <a
            href="https://twitter.com/HomeDotLLC/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={x}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>
          <a
            href="https://www.facebook.com/HomeDotLLC/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={facebook}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>

          <a
            href="https://www.instagram.com/home.llc_"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={instagram}
              className="h-[30px] w-[30px] object-cover cursor-pointer"
              alt="logo"
            />
          </a>
        </div>

        <div>
          <p className="text-white text-sm sm:text-[16px] 2xl:text-lg font-medium cursor-pointer">
            © 2024 Hoom Inc., which owns the trademark to Home.LLC (TM). All
            rights reserved.
          </p>
        </div>

        <div className="flex gap-6 items-center">
          <a href="/legal?tab=terms-of-use">
            <p className="text-white text-sm sm:text-lg font-medium cursor-pointer underline">
              {" "}
              • Terms of Use{" "}
            </p>
          </a>
          <a href="/legal?tab=privacy-policy">
            <p className="text-white text-sm sm:text-lg font-medium cursor-pointer underline">
              {" "}
              • Privacy Policy{" "}
            </p>
          </a>
        </div>
      </div>
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default Footer;
