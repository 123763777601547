import React from "react";
import { Close } from "@mui/icons-material";

const Hurray = ({ isVisible, onClose, formData }) => {
  if (!isVisible) return null; // Return null if the component is hidden

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      key={"hurray popup"}
    >
      <div
        className="max-w-[821px] w-full bg-custom-gray10 
        h-[480px] flex justify-center flex-col py-14 relative rounded-[30px]"
      >
        <div className="relative text-white">
          {/* Close Icon */}
          <Close
            fontSize="medium"
            className="absolute top-4 right-14 cursor-pointer text-custom-primaryPink"
            onClick={onClose}
          />

          {/* Title */}
          <h3 className="text-6xl font-bold text-custom-primaryPink text-center">
            Hurray!
          </h3>

          {/* Content */}
          <div className="mt-6 text-center">
            <p className="font-normal text-3xl mb-8">
              Time to check your email!
            </p>
            <p className="font-normal text-xl mb-4">
              We will be sending you a magic link to:
            </p>
            <p className="font-bold text-2xl mb-10">{formData?.email}</p>
          </div>

          {/* Footer */}
          {/* <div className="flex items-center justify-center gap-6 mt-8">
            <p className="text-xl font-normal">Didn’t get the link?</p>
            <button className="bg-transparent outline-none border-none text-custom-primaryPink underline text-xl">
              Resend magic link
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hurray;
