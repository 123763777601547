import React from 'react'
// import line from '../../assets/line.png'

const ThreeTakeAways = () => {
  return (
   <div className='w-full h-full bg-white pt-20'>
     <p className='text-lg lg:text-xl font-semibold font-quickSand text-black text-center mb-16 px-2'>Our AI analyzed 1M+ data points on America’s 100 biggest metropolitan areas. Its predictions will surprise you!</p>

    <h2 className='text-4xl 2xl:text-6xl font-light text-center mb-4 capitalize'>3 Big <span className='font-bold text-[#ED5272]'>takeaways</span></h2>
    <div className='w-full h-[330px] bg-cover bg-no-repeat flex flex-col gap-6 md:gap-0 md:flex-row sm:justify-between items-center px-4'> {/* style={{ backgroundImage: `url(${line})` }} */ }
     <div className='flex gap-4 items-center sm:items-start justify-start'>
      <div className='h-8 w-10 sm:h-16 sm:w-20 bg-[#F1F3F5] rounded-md'>
       <span className='text-xl lg:text-6xl font-bold text-center text-[#ED5272] px-2 pt-2 sm:px-8 sm:pt-4'>1</span>
       </div>
       <p className='lg:mt-5 w-full lg:w-60 2xl:w-80 text-lg 2xl:text-3xl font-light 2xl:leading-[45px]'>Home prices <span className='font-medium'>will rise</span> in most of the US. </p>
     </div>
     <div className='flex gap-4 sm:items-start items-center justify-start'>
      <div className='h-8 w-10 sm:h-16 sm:w-20 bg-[#F1F3F5] rounded-md'>
       <span className='text-xl lg:text-6xl font-bold text-center text-[#ED5272] px-2 pt-2 sm:px-8 sm:pt-4'>2</span>
       </div>
       <p className='lg:mt-5 w-full lg:w-72 2xl:w-[380px] text-lg 2xl:text-3xl font-light 2xl:leading-[45px]'> <span className='font-medium'>California</span> will continue its <span className='font-medium'>steady recovery.</span></p>
     </div>
     <div className='flex gap-4 items-center sm:items-start justify-start'>
      <div className='h-8 w-10 sm:h-16 sm:w-20 bg-[#F1F3F5] rounded-md'>
       <span className='text-xl lg:text-6xl font-bold text-center text-[#ED5272] px-2 pt-2 sm:px-8 sm:pt-4'>3</span>
       </div>
       <p className='lg:mt-5 w-full lg:w-64 2xl:w-80 text-lg 2xl:text-3xl font-light 2xl:leading-[45px]'> <span className='font-medium'>Florida’s</span> housing market is in <span className='font-medium'>trouble.</span></p>
     </div>
    </div>
    </div>
  )
}

export default ThreeTakeAways