import React from "react";
import InvestHero from "../components/Invest/InvestHero";
import Header from "../components/HomePage/Navbar";
// import VideoSection from "../components/Invest/VideoSection";
// import Calculator from "../components/Invest/Calculator";
import MapSection from "../components/Invest/MapSection";
import GraphSection from "../components/Invest/GraphSection";
import PrefersUs from "../components/Invest/PrefersUs";
import HowCanWeHelp from "../components/Invest/HowCanWeHelp";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
import Footer from "../components/Footer";
import LogoSection from "../components/HomePage/LogoSection";
import Caraousel from "../components/Invest/Caraousel/Caraousel";


const Invest = () => {
  return (
    <div className="overflow-x-hidden sm:overflow-x-auto">
      <Header />
      <InvestHero />
      <LogoSection />
      {/* <VideoSection /> */}
      {/* <Calculator /> */}
      <MapSection />
      <Caraousel />
      <GraphSection />
      <PrefersUs />
      <HowCanWeHelp />
      <NeedFromUs />
      <Footer />
    </div>
  );
};

export default Invest;
