import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative bg-black text-white">
      <div className="absolute top-0 right-0 p-4">
        <button className="text-xs">X</button>
      </div>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold">Feeling Dicey?</h2>
        <p className="mt-4 text-lg">Well Here’s What The News Says About Us</p>
        <div className="mt-8">
          <p className="italic">"Most accurate housing forecaster in America"</p>
          <span className="block mt-2 text-red-600 font-bold">— FAST COMPANY</span>
        </div>
        <div className="flex justify-between items-center mt-8">
          <span className="opacity-50">Bloomberg</span>
          <span className="opacity-50">The New York Times</span>
          <span className="opacity-50">FORTUNE</span>
          <span className="opacity-50">THE WALL STREET JOURNAL</span>
          <span className="opacity-50">THE EPOCH TIMES</span>
          <span className="opacity-50">AXIOS</span>
        </div>
        <div className="mt-8">
          <button className="border border-white py-2 px-8 hover:bg-white hover:text-black transition ease-in duration-200">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
