import React from 'react'

const ComparisonBox = ({ area, percent, isBar }) => {
    return (
        <div className="flex items-center justify-between">
        <p className="text-base md:text-2xl">{area}</p>
        <div className="flex gap-x-3 md:gap-x-5 items-center">
          <p className="text-xs md:text-sm">
            <span className="text-3xl md:text-5xl font-semibold">{percent}</span>/100
          </p>
          {isBar && (
            <div className="min-w-[150px] md:min-w-[238px] h-[20px] rounded-lg bg-[#D5EBF8] relative">
              <div
                style={{ width: `${percent}%` }}
                className="absolute h-full rounded-lg bg-gradient-to-r from-[#0D48F9] via-[#8664A8] to-[#FF2525]"
              ></div>
            </div>
          )}
        </div>
      </div>
    )
}

export default ComparisonBox