import React, { useState, useEffect } from "react";
import { faqCategories } from "../../utils";
import { faqAll } from "./faqData";

const FaqSection = () => {
  const [selectedFilter, setSelectedFilter] = useState(faqCategories[0]);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const getCategorizedData = (selectedFilter, faqsData) => {
    if (selectedFilter.toLowerCase() === "all") {
      return faqsData;
    } else {
      return faqsData.filter(
        (faq) => faq.category.toLowerCase() === selectedFilter.toLowerCase()
      );
    }
  };

  const filteredFaqsData = getCategorizedData(selectedFilter, faqAll);

  useEffect(() => {
    setCurrentQuestion(filteredFaqsData[0]);
  }, [selectedFilter]);

  const handleQuestionClick = (faq) => {
    setCurrentQuestion(faq);
  };

  return (
    <div className="w-full bg-[#fff] h-full py-10 lg:pt-[100px] border-b pb-[30px]">
      <div className="w-[400px] lg:w-full overflow-x-auto pb-[20px] hide-scrollbar px-4 xl:px-[40px]">
        <div className="w-full flex gap-[34px] items-center justify-start sm:px-2 mb-[30px] sm:mb-10">
          {faqCategories.map((category) => (
            <button
              onClick={() => setSelectedFilter(category)}
              className={`py-[10px] px-[44px] rounded-[50px] border whitespace-nowrap text-black border-black font-medium ${
                selectedFilter === category
                  ? "bg-[#ED5272] text-white border-0"
                  : ""
              }`}
              key={category}>
              {category}
            </button>
          ))}
        </div>
      </div>

      <div className="px-4 xl:px-[40px] flex flex-col lg:flex-row items-start gap-[20px] sm:gap-20 3xl:gap-40">
        <div className="overflow-y-auto h-[400px] sm:h-[500px] border-2 border-white w-full bg-[#212529] sm:w-[817px] px-4 sm:px-[34px] pt-[30px] rounded-xl custom-scrollbar">
          <div>
            {filteredFaqsData.length === 0 ? (
              <p className="text-white font-medium text-2xl text-center">
                No Question found
              </p>
            ) : (
                filteredFaqsData.map((faq) => (
                <>      
                <p
                  key={faq.id}
                  onClick={() => handleQuestionClick(faq)}
                  className={`text-[18px] sm:text-xl sm:leading-8 font-semibold text-white cursor-pointer w-full mb-[20px] ${
                    currentQuestion?.id === faq.id
                      ? "bg-[#ED5272] rounded-xl sm:rounded-[50px]"
                      : ""
                  } py-[20px] pl-[29px]`}>
                  {faq.question}
                    </p>

                    {
                      currentQuestion?.id === faq.id ?   <p className="text-[16px] leading-[32px] font-normal p-2 border-b text-white sm:hidden">
              {currentQuestion.answer}
            </p> : null
                    }
                    </>
              ))
            )}
          </div>
        </div>
        <div className="w-full sm:w-[675px] hidden sm:block h-auto">
          {currentQuestion && (
            <div className="text-[20px] leading-[42px] font-normal text-black">
              {currentQuestion.answer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
