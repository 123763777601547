import React from "react";
import HeroSection from "../components/HomeAi/HeroSection";
import HighLights from "../components/HomeAi/HighLights";
import Demographic from "../components/HomeAi/Demographic";
import { CounterProvider } from "../hooks/HomeAi";
import Affordability from "../components/HomeAi/Affordability";
import Desirability from "../components/HomeAi/Desirability/Desirability";
import Economy from "../components/HomeAi/Economy";
import Housing from "../components/HomeAi/Housing/Housing";
import ChatWidget from "../components/HomeAi/ChatWidget";
const HomeAi=() => {
  return (
    <div className = "Home overflow-x-hidden">
      <CounterProvider>
        <HeroSection />
        <HighLights />
        <Demographic />
        <Economy />
        <Affordability />
        <Desirability />
        <Housing />
        {/* <ChatWidget/>  */}
      </CounterProvider>
    </div>
  );
};

export default HomeAi;
