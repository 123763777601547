import React from "react";
import HomeEquityHero from "../components/HomeEquityLanding/HomeEquityHero";
import WhatWillYouOweUs from "../components/HomeEquityLanding/WhatWillYouOweUs";
import FAQSection from "../components/HomePage/FAQSection";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import StandsOut from "../components/EquityLanding/StandsOut";

const HomeEquityLanding = () => {
  return (
    <div>
      <HomeEquityHero />
      <WhatWillYouOweUs />
      <StandsOut />
      <FAQSection/>
      <Footer/>
    </div>
  );
};

export default HomeEquityLanding;
