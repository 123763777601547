import React from 'react';
import upIcon from '../../assets/invest/upIcon.svg'
import graphImage from '../../assets/invest/graph-img.png'

const GraphSection = () => {
  return (
    <div className='w-full h-[511px] sm:h-[950px] bg-graph-section bg-no-repeat bg-cover bg-center relative backdrop-blur-lg text-white'>
      {/* Overlay div for blur */}
    {/* Your content */}
    <div className='h-full w-full absolute top-0 left-0 backdrop-blur-md py-[50px] sm:py-[90px] px-4 sm:px-0'>
     <h2 className='text-2xl sm:text-4xl leading-[72px] font-semibold text-center flex items-center justify-center gap-8 mb-[60px] sm:mb-[80px]'>
      INVESTMENTS SO FAR <span><img src={upIcon} className='h-[28px] w-[28px] sm:w-auto sm:h-auto' alt="upicon" /></span></h2>
     
     <div className='flex justify-center'>
        <img src={graphImage} alt="graph" className='' />
     </div>
    </div>
    </div>
  );
};

export default GraphSection;
