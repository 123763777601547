import React from "react";
import "./Legal.css";

const PrivacyPolicy = () => {
  return (
    <div className="rich-text-block w-richtext text-white w-full" id="#privacy-policy">
      <p className="text-lg mb-4">
        <em>Effective as of January 1, 2023.</em>
      </p>
      <p className="text-lg mb-8">
        This Privacy Policy describes how Hoom Inc. (hereafter, “<strong>Hoom</strong>”) and our subsidiaries and affiliates (collectively, &quot;<strong>Hoom R&amp;D Private Limited</strong>
        ,&quot; &quot;<strong>we</strong>&quot;, “<strong>us</strong>,” or
        &quot;<strong>our</strong>&quot;) process personal information that we
        collect through our website at home.llc and other online services that
        link to this Privacy Policy (collectively, the “<strong>Service</strong>
        ”), our social media pages, marketing activities, and other activities
        described in this Privacy Policy. You also have certain rights under the
        Fair Credit Reporting Act.
      </p>
      <p className="text-[16px] mb-4">
        California Residents: See the California privacy notice below for
        important information about our collection, use, and disclosure of your
        personal information as well as your California privacy rights.
      </p>
      <h2 className="text-xl">
        <strong className="font-bold">Personal information we collect</strong>
      </h2>
      <p>
        Information you provide to us.  Personal information you may provide to
        us through the Service or otherwise includes:
      </p>
      <ul>
        <li>
          <strong>Contact data</strong>, such as your first and last name and
          email address.
        </li>
        <li>
          <strong>Application data</strong>, such as your first and last name,
          email address, mailing address, phone number, home address, estimated
          home valuation, and other information you provide when you submit a
          prequalification request or application for one of our financial
          products.
        </li>
        <li>
          <strong>Communications data</strong> about our communications with
          you, including when you contact us through the Service, social media,
          email, text messages, or otherwise, including the date, time, and
          contents of the message, and the email addresses, phone numbers (and
          carrier name), and social media handles from which your messages are
          sent.  
        </li>
        <li>
          <strong>Transactional data</strong> pertaining to your transactions
          with us, including information about any financial products we provide
          you.{" "}
        </li>
        <li>
          <strong>Relationship data</strong>, such as the personal information
          of relatives or third parties that you provide to us.
        </li>
        <li>
          <strong>Financial data</strong>, such as your stated income, credit
          score and credit history, and financial account numbers.
        </li>
        <li>
          <strong>Government identification data</strong>, such as national
          identification number (e.g., Social Security Number, tax
          identification number, passport number), state or local identification
          number (e.g., driver’s license or state ID number), and an image of
          the relevant identification card.
        </li>
        <li>
          <strong>Survey data</strong>, including information you share when you
          choose to participate in a survey. We recommend that you read any
          instructions, terms, or rules applicable to the survey before
          participating.
        </li>
      </ul>
      <p>
        We may also collect other data not specifically listed here and will use
        it as described in this Privacy Policy or as otherwise disclosed at the
        time of collection.
      </p>
      <p>
        <strong>Third-party sources</strong>. We may combine personal
        information we receive from you with personal information that we obtain
        from other sources, such as:
      </p>
      <ul>
        <li>
          <strong>Public sources</strong>, such as government agencies, public
          records, and other publicly available sources.
        </li>
        <li>
          <strong>Private sources</strong>, such as data licensors.{" "}
        </li>
        <li>
          <strong>Marketing partners</strong>, such as joint marketing partners.
        </li>
        <li>
          <strong>Third-party services,</strong> such as financial institutions
          from which you authorize us to retrieve your information for use in
          connection with our review of your application for our products or
          services,  which may include your address and Social Security Number,
          account balances, transaction history, and length of relationship with
          the third party.
        </li>
      </ul>
      <p>
        <strong>Automatic data collection. </strong>
      </p>
      <p>
        We, our service providers, and our business partners may automatically
        log information about you, your computer, or mobile device, and your
        interaction over time with the Service, our communications, and other
        online services, such as:
      </p>
      <ul>
        <li>
          <strong>Device data</strong>, such as your computer or mobile device’s
          operating system type and version, manufacturer and model, browser
          type, screen resolution, RAM and disk size, CPU usage, device type
          (e.g., phone, tablet), IP address, unique identifiers (including
          identifiers used for advertising purposes), language settings, mobile
          device carrier, radio/network information (e.g., Wi-Fi, Bluetooth,
          LTE, 3G), and location data that can be derived from it, general
          location information such as city, state, or geographic area that can
          be derived from your IP address, and precise geolocation of your
          device when you authorize our mobile application to access it in your
          device’s settings.
        </li>
        <li>
          <strong>Online activity data</strong>, such as pages or screens you
          viewed, how long you spent on a page or screen, the website you
          visited before browsing to the Service, navigation paths between pages
          or screens, information about your activity on a page or screen,
          access times and duration of access, and whether you have opened our
          emails, forwarded them, or clicked links within them.
        </li>
        <li>
          <strong>Location data</strong>, including the general location
          associated with your IP address or, when you authorize the Service to
          access it, your device’s precise location.
        </li>
      </ul>
      <p>
        <strong>Cookies and similar technologies.</strong> Some of the automatic
        collection described above is facilitated by the following technologies:
      </p>
      <ul>
        <li>
          <strong>Cookies</strong>, which are small text files that websites
          store on user devices and that allow web servers to record users’ web
          browsing activities and remember their submissions, preferences, and
          login status as they navigate a site. Cookies used on our sites
          include both “session cookies” that are deleted when a session ends,
          “persistent cookies” that remain longer, “first party” cookies that we
          place and “third party” cookies that our third-party business partners
          and service providers place.{" "}
        </li>
        <li>
          <strong>Local storage technologies</strong>, like HTML5, that provide
          cookie-equivalent functionality but can store larger amounts of data
          on your device outside of your browser in connection with specific
          applications.{" "}
        </li>
        <li>
          <strong>Web beacons</strong>, also known as pixel tags or clear GIFs,
          which are used to demonstrate that a webpage or email address was
          accessed or opened or that certain content was viewed or clicked.{" "}
        </li>
      </ul>
      <p>
        <strong>Session replay services</strong>, which employ software code to
        record users’ interactions with the Services in a manner that allows us
        to watch DVR-like replays of those user sessions. The replays include
        users’ clicks, mouse movements, scrolls, and keystrokes/key touches
        during those sessions. These replays help us diagnose usability problems
        and identify areas for improvement. For example, we use session replay
        services provided by Hotjar. You can learn more about Hotjar at
        https://www.hotjar.com/legal/policies/privacy/ and how to tell Hotjar
        not to collect your data at
        https://www.hotjar.com/policies/do-not-track/.
      </p>
      <h2>
        <strong className="bold-text-6">
          How we use your personal information
        </strong>
      </h2>
      <p>
        We may use your personal information for the following purposes or as
        otherwise described at the time of collection:
      </p>
      <p>
        ‍<strong>Service delivery and operations.</strong>  We may use your
        personal information to:
      </p>
      <ul>
        <li>provide, operate, and improve the Service and our business;</li>
        <li>
          communicate with you about the Service, including by sending
          Service-related announcements, updates, security alerts, and support
          and administrative messages;
        </li>
        <li>
          administer and communicate with you about surveys in which you choose
          to participate;
        </li>
        <li>
          understand your needs and interests, and personalize your experience
          with the Service and our communications; and
        </li>
        <li>
          provide support for the Service, and respond to your requests,
          questions, and feedback.
        </li>
      </ul>
      <p>
        <strong>Marketing and advertising.</strong>  We, our service providers,
        and our third-party advertising partners may collect and use your
        personal information for marketing and advertising purposes:
      </p>
      <ul>
        <li>
          <strong>Direct marketing.</strong> We may send you direct marketing
          communications via mail, email, or (with your consent) text messages
          and may personalize these messages based on your needs and interests.
           You may opt-out of our marketing communications as described in the
          Opt-out of marketing section below.  
        </li>
        <li>
          <strong>Interest-based advertising.</strong>  Our third-party
          advertising partners may use cookies and similar technologies to
          collect information about your interaction (including the data
          described in the Automatic data collection section above) with the
          Service, our communications, and other online services over time and
          with different browsers and devices, and use that information to serve
          online ads that they think will interest you.  This is called
          interest-based advertising. We may also share contact data about our
          users with these companies to facilitate interest-based advertising to
          those or similar users on other online platforms.{" "}
        </li>
      </ul>
      <p>
        <strong>Research and development. </strong> We may use your personal
        information for research and development purposes, including to analyze
        and improve the Service and our business and to develop new products and
        services.  As part of these activities, we may create aggregated,
        de-identified, and/or anonymized data from personal information we
        collect.  We make personal information into de-identified or anonymized
        data by removing information that makes the data personally identifiable
        to you.  We may use this aggregated, de-identified, or otherwise
        anonymized data and share it with third parties for our lawful business
        purposes, including to analyze and improve the Service and promote our
        business.
      </p>
      <p>
        <strong>Compliance and protection.</strong>  We may use your personal
        information to:
      </p>
      <ul>
        <li>
          comply with applicable laws, lawful requests, and legal process, such
          as to respond to subpoenas or requests from government authorities;
        </li>
        <li>
          protect our, your, or others’ rights, privacy, safety, or property
          (including by making and defending legal claims);{" "}
        </li>
        <li>
          audit our internal processes for compliance with legal and contractual
          requirements or our internal policies;{" "}
        </li>
        <li>enforce the terms and conditions that govern the Service; and </li>
        <li>
          prevent, identify, investigate, and deter fraudulent, harmful,
          unauthorized, unethical, or illegal activity, including cyberattacks
          and identity theft.  
        </li>
      </ul>
      <p>
        <strong>Cookies and similar technologies.</strong> The Service employs
        cookies and similar technologies to facilitate the purposes for which we
        use personal information:
      </p>
      <ul>
        <li>
          <strong>Service delivery and operation.</strong> To allow the
          technical operation of the Service, such as by remembering your
          selections and preferences as you navigate the site, and
          authenticating you into the Service, and to enhance the performance
          and functionality of our services.
        </li>
        <li>
          <strong>Interest-based advertising.</strong> To facilitate
          interest-based advertising as described above.{" "}
        </li>
        <li>
          <strong>Research and development.</strong> To help us understand user
          activity on the Service, including which pages are most and least
          visited and how visitors move around the Service, as well as user
          interactions with our emails and advertisements. For example, we use
          Google Analytics cookies for this purpose. You can learn more about
          Google Analytics and how to prevent the use of Google Analytics
          relating to your use of our sites here:
          https://tools.google.com/dlpage/gaoptout?hl=en.
        </li>
      </ul>
      <h2>
        <strong className="bold-text-7">
          How we share your personal information
        </strong>
      </h2>
      <p>
        We may share your personal information with the following parties and as
        otherwise described in this Privacy Policy or at the time of collection.
         
      </p>
      <ul>
        <li>
          <strong>Affiliates. </strong> Our corporate parent, subsidiaries, and
          affiliates.
        </li>
        <li>
          <strong>Service providers. </strong> Third parties that provide
          services on our behalf or help us operate the Service or our business
          (such as mail delivery, hosting, information technology, customer
          support, email delivery, marketing, consumer research, and website
          analytics).{" "}
        </li>
        <li>
          <strong>Advertising partners.</strong>  Third-party advertising
          companies for the interest-based advertising purposes described above.{" "}
        </li>
        <li>
          <strong>Professional advisors.</strong> Professional advisors, such as
          lawyers, auditors, bankers and insurers, where necessary in the course
          of the professional services that they render to us.
        </li>
        <li>
          <strong>Authorities and others.</strong> Law enforcement, government
          authorities, and private parties, as we believe in good faith to be
          necessary or appropriate for the Compliance and protection purposes
          described above or as otherwise permitted by law.{" "}
        </li>
        <li>
          <strong>Business transferees.</strong> Parties (and their respective
          advisors) to business transactions or proposed transactions involving
          a corporate divestiture, merger, consolidation, acquisition,
          reorganization, sale or other disposition of all or any portion of the
          business or assets of, or equity interests in, Hoom (including, in
          connection with insolvency, bankruptcy or similar proceedings).
           Personal information may be also transferred to such parties in
          connection with due diligence preceding such transactions or as part
          of the transfer of assets/interests that occur when the transactions
          close.{" "}
        </li>
      </ul>
      <h2>
        <strong className="bold-text-8">Your choices</strong>{" "}
      </h2>
      <p>
        In this section, we describe the rights and choices available to all
        users. California residents can find additional information about their
        rights below.
      </p>
      <p>
        <strong>Opt-out of communications.</strong>  You may opt-out of
        marketing-related emails by following the opt-out or unsubscribe
        instructions at the bottom of the email or by emailing us at
        support@home.llc.  Please note that changes requested of us may not be
        effective immediately, and until such changes take effect, you may still
        receive or not receive promotional communications from us. If you choose
        to opt-out of marketing-related emails, you may continue to receive
        service-related and other non-marketing emails.  If you receive text
        messages from us, you may opt out of receiving further text messages
        from us by replying STOP to our message.  
      </p>
      <p>
        <strong>Cookies. </strong>Most browsers let you remove or reject
        cookies.  To do this, follow the instructions in your browser settings.
         Many browsers accept cookies by default until you change your settings.
         Please note that if you set your browser to disable cookies, certain
        features of the Service may not work properly.  For more information
        about cookies, including how to see what cookies have been set on your
        browser and how to manage and delete them, visit
        www.allaboutcookies.org. You can also configure your device to prevent
        images from loading to prevent web beacons from functioning.
      </p>
      <p>
        <strong>Blocking web beacons. </strong>Most browsers allow you to
        configure your device to prevent images that would include web
        beacons/clear GIFs from loading. To do this, follow the instructions in
        your particular browser settings.
      </p>
      <p>
        <strong>Advertising choices. </strong>  You may be able to limit use of
        your information for interest-based advertising through the following
        settings/options/tools:
      </p>
      <ul>
        <li>
          <strong>Browser settings.</strong>  Changing your internet web browser
          settings to block third-party cookies.
        </li>
        <li>
          <strong>Privacy browsers/plug-ins.</strong> Using privacy browsers
          and/or ad-blocking browser plug-ins that let you block tracking
          technologies.{" "}
        </li>
        <li>
          <strong>Platform settings.</strong> Google offers opt-out features
          that let you opt-out of use of your information for interest-based
          advertising.  You may be able to exercise that option at the following
          websites:
        </li>
        <li>
          <strong>Google</strong>: Opt-out (https://adssettings.google.com/){" "}
        </li>
        <li>
          <strong>Ad industry tools.</strong>  Opting out of interest-based ads
          from companies that participate in the following industry opt-out
          programs:{" "}
        </li>
        <li>
          <strong>Network Advertising Initiative:</strong>{" "}
          http://www.networkadvertising.org/managing/opt_out.asp{" "}
        </li>
        <li>
          <strong>Digital Advertising Alliance:</strong> optout.aboutads.info.  {" "}
        </li>
      </ul>
      <p>
        You will need to apply these opt-out settings on each device and browser
        from which you wish to limit the use of your information for
        interest-based advertising purposes.  We cannot offer any assurances as
        to whether the companies we work with participate in the opt-out
        programs described above.{" "}
      </p>
      <p>
        <strong>Do not track.</strong>  Some Internet browsers may be configured
        to send “Do Not Track” signals to the online services that you visit.
         We currently do not respond to “Do Not Track” or similar signals unless
        we explicitly indicate otherwise.  To find out more about “Do Not
        Track,” please visit http://www.allaboutdnt.com.
      </p>
      <p>
        <strong>Declining to provide information.</strong> We need to collect
        personal information to provide certain services. If you do not provide
        the information we identify as required or mandatory, we may not be able
        to provide those services.
      </p>
      <h2>
        <strong className="bold-text-9">Other sites and services</strong>
      </h2>
      <p>
        The Service may contain links to websites, mobile applications, and
        other online services operated by third parties.  In addition, our
        content may be integrated into web pages or other online services that
        are not associated with us.  These links and integrations are not an
        endorsement of, or representation that we are affiliated with, any third
        party.  We do not control websites, mobile applications, or online
        services operated by third parties, and we are not responsible for their
        actions. We encourage you to read the privacy policies of the other
        websites, mobile applications, and online services you use.
      </p>
      <p>
        <strong>Security</strong>{" "}
      </p>
      <p>
        We employ a number of technical, organizational, and physical safeguards
        designed to protect the personal information we collect.  However,
        security risk is inherent in all internet and information technologies,
        and we cannot guarantee the security of your personal information.
      </p>
      <p>
        <strong>International data transfer</strong>
      </p>
      <p>
        We are headquartered in the United States and may use service providers
        that operate in other countries. Your personal information may be
        transferred to the United States or other locations where privacy laws
        may not be as protective as those in your state, province, or country.{" "}
      </p>
      <p>
        <strong>Children</strong>  
      </p>
      <p>
        The Service is not intended for use by anyone under 18 years of age. If
        you are a parent or guardian of a child from whom you believe we have
        collected personal information in a manner prohibited by law, please
        email us at support@home.llc.  If we learn that we have collected
        personal information through the Service from a child without the
        consent of the child’s parent or guardian as required by law, we will
        comply with applicable legal requirements to delete the information.
      </p>
      <p>
        <strong>Changes to this Privacy Policy</strong>{" "}
      </p>
      <p>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        updating the date of this Privacy Policy and posting it on the Service
        or other appropriate means.  Any modifications to this Privacy Policy
        will be effective upon our posting the modified version (or as otherwise
        indicated at the time of posting). In all cases, your use of the Service
        after the effective date of any modified Privacy Policy indicates your
        acknowledging that the modified Privacy Policy applies to your
        interactions with the Service and our business.
      </p>
      <p>
        <strong>How to contact us</strong>
      </p>
      <p>
        <strong>Email:</strong> support@home.llc
      </p>
      <p>
        <strong>Mail:</strong> Hoom Inc., 1325 Denlyn St., Novato, CA 94947  
      </p>
      <p>Phone: 415-707-0976</p>
      <h2>
        <strong className="bold-text-10">California Privacy Notice</strong>{" "}
      </h2>
      <p>
        This notice describes our collection, use, and disclosure of personal
        information of California residents in our capacity as a “business”
        under the California Consumer Privacy Act (“CCPA”) and their rights with
        respect to their personal information.  For purposes of this notice,
        “personal information” has the meaning given in the CCPA but does not
        include information exempted from the scope of the CCPA.  For example,
        the CCPA exempts information covered by our U.S. Consumer Privacy Notice
        or subject to the Fair Credit Reporting Act.{" "}
      </p>
      <p>
        Your privacy rights. If you’re a California resident, you have the
        following rights under the CCPA:
      </p>
      <ul>
        <li>
          <strong>Right to know.</strong> You can request information about the
          categories of personal information that we have collected; the
          categories of sources from which we collected personal information;
          the business or commercial purpose for collecting, sharing and/or
          selling personal information; the categories of any personal
          information that we sold or disclosed for a business purpose; and the
          categories of any third parties with whom personal information was
          sold, shared, or disclosed for a business purpose.
        </li>
        <li>
          <strong>Right to access.</strong> You can request a copy of certain
          personal information that we have collected about you.
        </li>
        <li>
          <strong>Right to deletion.</strong> You can request that we delete
          personal information that we collected from you.
        </li>
        <li>
          <strong>Right to correction.</strong> You can request that we correct
          inaccurate personal information that we have collected about you.
        </li>
        <li>
          <strong>Right to opt-out.</strong> You can request to opt-out of
          disclosures of your personal information that constitute “selling” or
          “sharing” of your personal information as defined in the CCPA.
        </li>
        <li>
          <strong>Nondiscrimination.</strong> You are entitled to exercise the
          rights described above free from discrimination as prohibited by the
          CCPA.
        </li>
      </ul>
      <p>
        You may submit requests to exercise your right to know, access,
        deletion, and correction by submitting an online request here or calling
        us at 415-707-0976.  The rights described above are not absolute, and in
        certain cases, we may decline your request as permitted by law.  We
        cannot process your request if you do not provide us with sufficient
        detail to allow us to understand and respond to it.  
      </p>
      <p>
        <strong>Identity verification.</strong> We need to verify your identity
        to process your requests to exercise your rights to know, access,
        deletion, and correction, and we reserve the right to confirm your
        California residency. To verify your identity, we may require you to log
        into an online account if you have one, provide identifiers we can match
        against information we may have collected from you previously, confirm
        your request using the email, or telephone account stated in the
        request, provide government identification, or provide a declaration
        under penalty of perjury, where permitted by law.{" "}
      </p>
      <p>
        <strong>Authorized agents.</strong>  Your authorized agent may make a
        request on your behalf upon our verification of the agent’s identity and
        our receipt of a copy of a valid power of attorney given to your
        authorized agent pursuant to applicable state law. If you have not
        provided your agent with such a power of attorney, we may ask you and/or
        your agent to take additional steps permitted by law to verify that your
        request is authorized, such as information required to verify your
        identity and that you have given the authorized agent permission to
        submit the request.{" "}
      </p>
      <p>
        <strong>Information practices.</strong> The following describes our
        practices currently and during the past 12 months:
      </p>
      <ul>
        <li>
          <strong>Sources and purposes.</strong> We collect all categories of
          personal information from the sources and use them for the
          business/commercial purposes described above in the Privacy Policy.{" "}
        </li>
        <li>
          <strong>Sales and sharing of personal information.</strong> Our use of
          the interest-based advertising services described above may constitute
          “sharing” of your personal information from which you have the right
          to opt-out.  That is because these services use our users’ personal
          information (specifically, the contact data, device data and online
          activity data described above in Personal information we collect) to
          show you ads they think may interest you on other online services.  
          You can request to opt-out of this “sharing” of your personal
          information by clicking “Do Not Share My Personal Information” at the
          bottom of our webpage.  Your request to opt-out will apply only to the
          browser and the device from which you submit the request.  You can
          also enable the Global Privacy Control (GPC) to opt out of the
          “sharing” of your personal information for each participating browser
          system that you use.  Learn more at the Global Privacy Control
          website: https://globalprivacycontrol.org. We do not “sell” personal
          information as defined by the CCPA and have no actual knowledge that
          we have sold or shared the personal information of California
          residents under 16 years of age.
        </li>
        <li>
          <strong>Sensitive personal information.</strong> We do not use or
          disclose sensitive personal information for purposes that California
          residents have a right to limit under the CCPA.
        </li>
        <li>
          <strong>Retention.</strong>  We generally retain personal information
          to fulfill the purposes for which we collected it, including for the
          purposes of satisfying any legal, accounting, or reporting
          requirements, to establish or defend legal claims, or for fraud
          prevention purposes.  The length of time we retain particular
          categories of personal information is determined based on whether it
          is sufficient to achieve such purposes.  When we no longer require the
          personal information, we may either delete it, anonymize it, or
          isolate it from further processing.  
        </li>
        <li>
          <strong>Collection and disclosure.</strong> The chart below describes
          the personal information we collect by reference to the categories of
          personal information specified in the CCPA (Cal. Civ. Code §1798.140),
          and the categories of third parties to whom we disclose it.  The terms
          in the chart refer to the categories of information and third parties
          detailed above in the Personal information we collect section.
          Information you voluntarily provide to us, such as in free-form
          webforms, may contain other categories of personal information not
          described below. We may also disclose personal information to
          professional advisors, law enforcement and government authorities, and
          business transferees as described above in the How we share your
          personal information section of this Privacy Policy.  
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
