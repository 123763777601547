import React, { useEffect, useRef, useState } from 'react'
import { helpCards } from '../../utils'

const HowCanWeHelp = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const cardRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && isMobile) {
          entry.target.classList.add('card-hovered', 'hover');
        } else {
          entry.target.classList.remove('card-hovered', 'hover');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    cardRefs.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      cardRefs.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [isMobile]);

  return (
   <div className="w-full bg-[#FEF9F0] h-full py-10 sm:py-[72px] px-4 2xl:px-8">
      <h2 className="text-[32px] sm:text-6xl text-center font-[275] mb-16 xl:mb-[94px]">How Can We <span className='font-bold text-[#ED5272]'>Help You?</span></h2>
      <div className='flex flex-col sm:flex-row xl:flex-nowrap gap-5 2xl:gap-12 items-center justify-center lg:justify-start'>
        {
          helpCards.map((card, idx) => (
             <div key={card.id} className='w-[300px] 2xl:w-[324px] h-[420px] rounded-[14px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] cursor-pointer relative overflow-hidden analyze-card' ref={cardRefs[idx]}>
        <div className='absolute top-[-58px] right-[-50px] h-[147px] w-[147px] rounded-full bg-[#000000] bg-opacity-15 opacity-0 sm:opacity-100 icon-div'>
                <h5 className='text-white pt-20 pl-12 text-5xl opacity-50'>{card.id}</h5>
          </div>

          <div className='w-[263px] h-[170px] card-content absolute top-[200px] left-[25px]'>
                <h3 className='text-2xl font-medium text-white mb-4'>{card.title}</h3>
                <p className='text-sm opacity-50 text-white font-quickSand'>{card.description}</p>
              </div>
          <div className='absolute w-full h-[187px] bottom-0 opacity-0 card-img'>
            <img src={card.img} alt="card-hovered" className='w-full h-full opacity-60' />
          </div>
        </div>
          ))
        }
       
       </div>
  </div>
  )
}

export default HowCanWeHelp