import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Link
} from "@react-pdf/renderer";
import logo_upscaled from "../assets/logo_upscaled.png";
import email from '../assets/email.png'
import website from '../assets/internet.png'
import phone from '../assets/phone.png'
import location from '../assets/location.png'
import table from '../assets/table.png'

import ExtraBoldPoppins from "../fonts/Poppins-ExtraBold.ttf";
import boldPoppins from "../fonts/Poppins-Bold.ttf";
import semiboldPoppins from "../fonts/Poppins-SemiBold.ttf";
import poppinsNormal from "../fonts/Poppins-Regular.ttf";

Font.register({
  family: "Poppins",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: poppinsNormal,
      fontWeight: 'normal'
    },
    {
      src: ExtraBoldPoppins,
      fontWeight: "extrabold",
    },
    {
      src: boldPoppins,
      fontWeight: "bold",
    },

    {
      src: semiboldPoppins,
      fontWeight: "semibold",
    },
  ],
});

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    paddingLeft: 20,
  },

  headerText: {
    textAlign: "right",
    flex: 1,
    marginRight: 10, // Adjust the margin as needed
  },
  letterOfIntent: {
    fontSize: 11,
    color: "#6c757d", // This should match the light grey 
    fontFamily: "Poppins",
  },
  preApproval: {
    marginTop: 5,
    fontSize: 18,
    color: "#E85271", // This should match the pinkish red color
    fontWeight: "extrabold",
    fontFamily: "Poppins",
  },

  logo: {
    width: 108,
    height: 48, // Adjust according to your logo's aspect ratio
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },

  page: {
    paddingTop: 35,
    backgroundColor: "#fff",
    position: "relative",
    height: '100vh',
    overflow: 'hidden',
  },

  sideLine: {
    position: 'absolute', 
    top: 0,
    left: 0,
    height: '100vh',
    width: '10px',
    backgroundColor: '#E85271',
  },
  overviewSection: {
    paddingLeft: 50,
    backgroundColor: "#FAD8DF",
    padding: 15,
    paddingTop: 30, // Padding top is higher to accommodate the absolute positioned "OVERVIEW"
    zIndex: 1,
  },
  overviewHeader: {
    fontSize: 15,
    fontWeight: "extrabold",
    fontFamily: "Poppins",
    color: "black",
    position: "absolute",
    right: 35, // Align to the right with padding from the page edge
    top: 15, // Align to the top of the section
  },
  note: {
    fontSize: 10,
    paddingLeft: 10,
    position: "absolute",
    marginBottom: 20,
    left: 5, // Align to the left with padding from the page edge
    top: 15, // Align to the top of the section
    color: "#6c757d",
  },
  detailsRow: {
    marginLeft: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailColumn: {
    width: "32%",
    marginBottom: 20, // Adjust width as needed to fit 3 columns
  },
  overviewColumn: {
    width: "100%", // Make the Home location take up the full width
    marginBottom: 6,
  },
  secondaryColumn: {
    width: "50%", // Other items take up half width
    marginBottom: 6,
  },
  overviewTitle: {
    marginBottom: 4,
    fontSize: 10,
    color: "#6c757d",
    fontWeight: "ultrabold",
  },

  overviewContent: {
    marginTop: 2, 
    fontSize: 16,
    color: '#000',
    fontFamily: 'Poppins', 
    fontWeight: 'semibold',
  },

  contentSection: {
    margin: 20, // Adjust the margin as needed
    padding: 10, // Adjust the padding as needed
    marginLeft: 30, marginRight: 30, marginTop: 20
  },
  contentTitle: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 'semibold',
    marginBottom: 20,
  },
  contentBody: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: 'Poppins',
    fontWeight: 'normal',
  },
  boldText: {
    fontSize: 16,
    fontWeight: "extrabold",
    fontFamily: "Poppins",
  },
  contactEmail: {
    textDecoration: "underline",
    color: "#cb2431", // Use the brand's color
  },

  body: {
    textAlign: "justify",
    lineHeight: 1.5,
  },
  signature: {
    width: 100,
    height: 30,
    marginVertical: 15,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    fontSize: 9,
    width: '100%',
    zIndex: 10,
  },
  pageNumberDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
    paddingLeft: 20,
  },
  pageNumber: {
    height: 40,
    width: 40,
    backgroundColor: '#E85271',
    color: '#fff',
    fontSize: 20,
    display: 'flex',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    marginLeft: '-25px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
    paddingTop: 10,
  },
  companyName: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'ultrabold',
    marginBottom: 5,
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAD8DF',
    padding: 8,
    paddingLeft: 20,
    zIndex: 1,
  },
  linkDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
  },
  linkText: {
    fontSize: 10, 
    fontWeight: 400,
    color: '#6c757d',
    display: 'flex',
  },
  footerLogo: {
    display: 'block',
    height: 20,
    width: 20,
  },
  pre: {},
  termsAndCondition: {
    marginTop: 5,
    fontSize: 22,
    color: "#E85271", // This should match the pinkish red color
    fontWeight: "extrabold",
    fontFamily: "Poppins",
  },
  textSection: {
    marginTop: 4, // Adjust the margin as needed
    marginLeft: 30, marginRight: 30,
    padding: 10, // Adjust the padding as needed
  },
  textContent: {
    fontSize: 14, 
    lineHeight: 1.4,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: 'normal',
    fontFamily: 'Poppins'
  },
  textBold: {
    fontSize: 14,
    marginLeft: 10,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  itemText: {
    fontSize: 12, 
    lineHeight: 1.4,
    marginBottom: 2,
    marginTop: 2,
    fontWeight: 'normal',
    fontFamily: 'Poppins'
  },
  itemBold: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    marginLeft: '4px',
  },
  overViewRow: {
    marginLeft: 30, marginRight: 30,
    marginTop: 5, marginBottom: 5,
    padding: 10,
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  overViewRowItem: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center', 
    gap: 2, 
  },
  tableContainer: {
    marginLeft: 30, marginRight: 30,
    paddingLeft: 10, paddingRight: 10,
  },
  lastTextSection: {
    backgroundColor: '#FAD8DF',
    paddingRight: 30, paddingLeft: 30,
    paddingTop: 5, paddingBottom: 5,
    marginBottom: 5, marginTop: 10,
    zIndex: 1,
  },
  textMessage: {
    fontSize: 10,
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    color: '#000',
    lineHeight: 1.2
  },
  textMessageBold: {
    fontSize: 10, 
    fontFamily: 'Poppins', 
    fontWeight: 'extrabold',
    marginLeft: 5,
    color: '#000'
  },
  tableImage: {
    height: 350, 
    width: '100%',
  }
});

// const dummyData = {
//   homeLocation: "Lakeland, Florida",
//   homePrice: 123,
//   investment: 989,
//   investmentPercentage: "(15%)", // You can adjust the percentage as needed
//   term: "Up to 5 years",
//   approvalDate: "25th April '23",
//   expirationDate: "25th Aug '22",
// };

const formatCurrency = (amount) => {
  // Implement currency formatting logic
  return `$${Number(amount).toLocaleString()}`;
};

const formatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
});

const dummyData = {
  homeLocation: "Lakeland, Florida",
  homePrice: 123,
  investment: 989,
  investmentPercentage: "(15%)", // You can adjust the percentage as needed
  term: "Up to 5 years",
  approvalDate: "25th April '23",
  expirationDate: "25th Aug '22",
};

const ApprovalDocument = ({userResponses}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.sideLine}></View>
      <View style={styles.header}>
        <Image src={logo_upscaled} style={styles.logo} />
        <View style={styles.headerText}>
        <Text style={styles.letterOfIntent}>LETTER OF INTENT</Text>
        <Text style={styles.preApproval}>PRE-APPROVAL</Text>
        </View>
      </View>

      <View style={styles.overviewSection}>
        <Text style={styles.note}>Note: Subject to change</Text>
        <Text style={styles.overviewHeader}>OVERVIEW</Text>
        <View style={{ marginBottom: 20, marginTop: 20, marginLeft: 20 }}>
          <Text style={styles.overviewTitle}>Home location</Text>
          <Text style={styles.overviewContent}>{userResponses.address}</Text>
        </View>
        <View style={styles.detailsRow}>
          <View style={styles.detailColumn}>
            <Text style={styles.overviewTitle}>Home price</Text>
            <Text style={styles.overviewContent}>
              ${formatter.format(userResponses.homeValue)}
            </Text>
          </View>
          <View style={styles.detailColumn}>
            <Text style={styles.overviewTitle}>Home.LLC's investment</Text>
            <Text style={styles.overviewContent}>
              ${userResponses.cashOutAmount} ({userResponses.cashOutAmountPercentage}
              %)
            </Text>
          </View>
          <View style={styles.detailColumn}>
            <Text style={styles.overviewTitle}>Pre Approval Date</Text>
            <Text style={styles.overviewContent}>
              {new Date().toLocaleDateString()}
            </Text>
          </View>
        </View>
        <View style={styles.detailsRow}>
          <View style={styles.detailColumn}>
            <Text style={styles.overviewTitle}>
              Monthly Principal & Interest
            </Text>
            <Text style={styles.overviewContent}>$0</Text>
          </View>
          <View style={styles.detailColumn}>
            <Text style={styles.overviewTitle}>Term</Text>
            <Text style={styles.overviewContent}>{dummyData.term}</Text>
          </View>
          <View style={styles.detailColumn}>
  <Text style={styles.overviewTitle}>Pre Approval expiration Date</Text>
  <Text style={styles.overviewContent}>
  {new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)).toLocaleDateString()}
</Text>
</View>


        </View>
      </View>
      {/* <View style={styles.overviewSection}>
        <View style={styles.overviewBlock}>
          <Text style={styles.overviewTitle}>Home location:</Text>
          <Text style={styles.overviewContent}>{userResponses.address}</Text>

          <Text style={styles.overviewTitle}>Home price:</Text>
          <Text style={styles.overviewContent}>${userResponses.homeValue}</Text>
        </View>

        <View style={styles.overviewBlock}>
          <Text style={styles.overviewTitle}>Home.LLC's investment:</Text>
          <Text style={styles.overviewContent}>${userResponses.qualifiedAmount} ({userResponses.equityPercentage}%)</Text>

          <Text style={styles.overviewTitle}>Term:</Text>
          <Text style={styles.overviewContent}>Up to 5 years</Text>
        </View>

        <View style={styles.overviewBlock}>
          <Text style={styles.overviewTitle}>Pre Approval Date:</Text>
          <Text style={styles.overviewContent}>{userResponses.approvalDate}</Text>

          <Text style={styles.overviewTitle}>Pre Approval Expiration:</Text>
          <Text style={styles.overviewContent}>{userResponses.expirationDate}</Text>
        </View>
      </View> */}

      <View style={styles.contentSection}>
        <Text style={styles.contentTitle}>
          Congratulations, {userResponses.name}!
        </Text>
        <Text style={styles.contentBody}>
          Home Inc. (<Text style={styles.boldText}>"Home.LLC"</Text>) is pleased
          to offer up to {formatCurrency(userResponses.cashOutAmount)} towards
          your<br/> <Text style={{marginTop: 5}}>
  dream home, subject to change.
</Text>
        </Text>
        <Text style={styles.contentBody}>
          Please note that this letter of intent still requires underwriting for
          final approval. Also, this letter of intent is non-binding, it does
          not signify a commitment from{" "}
          <Text style={styles.boldText}>Home.LLC</Text> and does not obligate
          you to obtain an investment from us.
        </Text>
        <Text style={styles.contentBody}>
          Next Steps: Speak with our Investment Manager at 415-707-0976 or schedule an
          appointment <Link src={"https://calendly.com/d/5h3-wjf-y9y/equity-cashout-with-home-llc"} style={styles.contactEmail}>here</Link> before your Pre-Approval Letter expires.
          Secure fast funding, with funds typically credited within 1-3 weeks.
        </Text>
        <Text style={styles.contentBody}>
          If you have any questions, please reach us on{" "}
          <Text style={styles.contactEmail}>support@home.llc</Text>.
        </Text>
      </View>

      {/* <Image style={styles.signature} src={userResponses.managerSignature} /> */}

      <View style={styles.footerContainer}>
        <View style={styles.pageNumberDiv}>
          <Text style={styles.companyName}>{userResponses.companyName}</Text>
          <Text style={styles.pageNumber} color="white">1</Text>
        </View>
        
        <View style={styles.links}>
          <View style={styles.linkDiv}>
            <Image src={email} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
               support@home.llc
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={website} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
               www.home.llc
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={phone} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
              415-707-0976
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={location} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
              Denlyn St, Novato, CA 94947
            </Text>
          </View>
        </View>
      </View>
    </Page>
    {/* 2nd page */}
    <Page size={"A4"} style={styles.page}>
      <View style={styles.sideLine}></View>
      <View style={styles.header}>
        <Image src={logo_upscaled} style={styles.logo} />
        <View style={styles.headerText}>
          <Text style={styles.termsAndCondition}>TERMS & CONDITION</Text>
        </View>
      </View>

      <View style={styles.textSection}>
        <Text style={styles.textContent}>This section contains sample scenarios to help you understand how is {""}
          <Text style={styles.textBold}>Home.LLC Share is calculated.</Text>
          The values in these scenarios are examples only and are not intended to be promises or guarantees of actual values</Text>
      </View>

      <View style={styles.textSection}>
        <Text style={styles.textContent}>
          <Text style={styles.textBold}>Home.LLC Share is</Text>{' '}
          the lesser of
        </Text>
        <Text style={styles.textContent}>
          (a) multiplication of {''}<Text style={styles.textBold}>Ending Home Value</Text>{' '}by <Text style={styles.textBold}>Home.LLC Percentage,</Text>{''} and
        </Text>
        <Text style={styles.textContent}>
          (b) the {''}<Text style={styles.textBold}>HOME.LLC Cap,</Text>{' '}which caps annualized returns on the <Text style={styles.textBold}>Initial Investment to 20%</Text>
        </Text>
      </View>

      <View style={styles.overViewRow}>
        <View style={styles.overViewRowItem}>
          <Text style={styles.itemText}>Initial Home Value</Text>
          <Text style={styles.itemBold}>$1,000,000</Text>
        </View>
        <View style={styles.overViewRowItem}>
          <Text style={styles.itemText}>Initial Investment</Text>
          <Text style={styles.itemBold}>$100,000</Text>
         </View>
         <View style={styles.overViewRowItem}>
          <Text style={styles.itemText}>Home.LLC %</Text>
          <Text style={styles.itemBold}>20.00%</Text>
         </View>
      </View>

      <View style={styles.tableContainer}>
        <Image src={table} style={styles.tableImage} />
      </View>

      <View style={styles.lastTextSection}>
        <Text style={styles.textMessage}>I understand that this <Text style={styles.textMessageBold}>IS NOT A LOAN,</Text>{' '}
          and that <Text style={styles.textMessageBold}>I</Text>{' '}will not be required
          to make any monthly or periodic payments to <Text style={styles.textMessageBold}>HOME.LLC</Text>{' '}
          based upon the <Text style={styles.textMessageBold}>Initial Investment,</Text>{''} nor will any periodic payment
          obligations be imposed upon or accrued on the amount of the <Text style={styles.textMessageBold}>Initial Investment,</Text>{' '} in the form of indebtedness to me.
        </Text>
      </View>

      <View style={styles.footerContainer}>
        <View style={styles.pageNumberDiv}>
          <Text style={styles.companyName}>{userResponses.companyName}</Text>
          <Text style={styles.pageNumber} color="white">2</Text>
        </View>
        
        <View style={styles.links}>
          <View style={styles.linkDiv}>
            <Image src={email} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
               support@home.llc
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={website} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
               www.home.llc
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={phone} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
              415-707-0976
            </Text>
          </View>
          <View style={styles.linkDiv}>
            <Image src={location} style={styles.footerLogo}/>
            <Text style={styles.linkText}>
              Denlyn St, Novato, CA 94947
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default ApprovalDocument;

 