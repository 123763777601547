import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = ({ onBack, onNext, canGoBack, finalStep, isSelectionMade, currentStep, isNextEnabled, showConditionalSteps, onScheduleCall }) => {
  return (
    <div className="bottom-0 right-0 px-4 py-2 flex justify-between items-center w-full">
      <div className="flex justify-start">
        {canGoBack && (
          <button onClick={onBack} className="bg-transparent hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full">
            ← Go Back
          </button>
        )}
      </div>
      <div className="flex justify-end">
        {/* Adjust logic here to support conditional steps scenario */}
        {finalStep || showConditionalSteps && currentStep===3 ? (
          <button
          onClick={() => {
            if (isSelectionMade) {
              onScheduleCall(); // Ensure this doesn't interfere with navigation
            }
          }}
            className={`${
              isSelectionMade ? "bg-transparent   hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full" : "bg-gray-200 text-gray-500 cursor-not-allowed"
            }  text-black font-bold border border-black py-2 px-4 rounded-full transition-colors duration-300 ease-in-out`}
            style={{ pointerEvents: isSelectionMade ? 'auto' : 'none' }}
          >
            Schedule a Call
          </button>
        ) : (
          currentStep < 6 && (
            <button
              onClick={onNext}
                className="bg-transparent   hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full"
              disabled={!isNextEnabled}
            >
              Move to the next →
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Navigation;




// const CalculatorFormBuyer = () => {
//   const steps = [
//     "Purpose Of Loan",
//     "Location Check",
//     "Your Home Value",
//     "Home Ownership",
//     "Equity Estimate",
//   ];
//   const [isComplete, setIsComplete] = useState(false);
//   const [currentStep, setCurrentStep] = useState(0);
//   const [userResponses, setUserResponses] = useState({
//     loanPurpose: "",
//     address: "",
//     homeValue: "", // Previously homeValue state
//     homeOwnership: "", // Previously homeOwnership state
//     equityPercentage: 0, // Previously equityPercentage state
//     creditScore: "", // Previously creditScore state
//     qualifiedAmount: 0, // Previously qualifiedAmount state
//   });
//   const eligibleCities = ["California", "San Francisco", "Miami", "Denver"];
//   const isEligibleForNext = currentStep < steps.length - 1;
//   const showEligibilityResult = currentStep === 6;
//   const userCount = 123456;

//   useEffect(() => {
//     setIsComplete(checkAllFieldsFilled());
// }, [userResponses]);
//   const isSelectionMade = userResponses.creditScore &&  userResponses.homeValue;

//   const stepImages = [
//     "https://media.istockphoto.com/id/1019219898/photo/real-estate-or-property-investment-home-mortgage-loan-rate-saving-money-for-retirement.jpg?s=612x612&w=0&k=20&c=8NEnyouCL-5HsqRsEFUkxc0J7fkv4ZoPl78zDAtk790=",
//     "https://hips.hearstapps.com/hmg-prod/images/edc100123egan-002-6500742f5feb7.jpg",
//     "https://eplan.house/application/files/6314/6702/0525/vid-speredy-JD-23274.jpg",
//     "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
//     "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
//     "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
  
//   ];

//   const handleSelect = (field, value) => {
//     console.log(`Field: ${field}, Value: ${value}`); 
//     setUserResponses(prevResponses => {
//         const newResponses = {
//             ...prevResponses,
//             [field]: value,
//         };
//         console.log("Updated Responses:", JSON.stringify(newResponses, null, 2)); // Logging the new state
//         return newResponses;
//     });
// };
// const checkAllFieldsFilled = () => {
//   // Assuming these are the fields needed to enable the button
//   return userResponses.loanPurpose && userResponses.address && userResponses.homeValue &&
//          userResponses.homeOwnership && userResponses.equityPercentage > 0 && userResponses.creditScore;
// };


// const checkQualification = () => {
//   const allowedCities = ["California", "San Francisco", "Miami", "Denver"];
//   const addressCity = userResponses.address.split(", ")[1]; // Make sure this parsing matches your form's address format

//   if (parseInt(userResponses.creditScore) < 600) {
//       console.log("User does not qualify");
//       return false;
//   }

//   // Ensure we're parsing the user input correctly
//   const homeValueInDollars = parseFloat(userResponses.homeValue.replace(/[^0-9.]+/g, ""));
//   const equityPercentage = parseFloat(userResponses.equityPercentage) / 100; // Ensure this is a decimal

//   // Calculate the amount eligible for cashout
//   const cashoutAmount = homeValueInDollars * equityPercentage;

//   setUserResponses(prev => ({
//       ...prev,
//       qualifiedAmount: cashoutAmount  // Update the qualified amount to be the calculated cashout
//   }));

//   console.log("User qualifies with a cashout amount of:", cashoutAmount);
//   return true;
// };



//   const handleHomeValueChange = (value) => {
//     setUserResponses((prevResponses) => ({
//       ...prevResponses,
//       homeValue: value,
//     }));
//   };

//   const goNext = () => {
//     if (currentStep === 5) { // This is where we handle the qualification check
//         if (checkQualification()) {
//             console.log("Final User Responses:", JSON.stringify(userResponses, null, 2));
//             setCurrentStep(currentStep + 1); // Move to results if qualified
//         } else {
//             alert("Sorry, you do not qualify based on the provided criteria.");
//         }
//     } else if (currentStep < steps.length - 1) {
//         setCurrentStep(currentStep + 1); // Normal behavior for other steps
//     }
// };

  

//   const goBack = () => setCurrentStep((prev) => prev - 1);
  

//   return (
//     <div className="relative flex w-full min-h-screen ">
//       <div className="w-full md:w-2/3 p-4 overflow-auto">
//         <ProgressIndicator steps={steps} currentStep={currentStep} />
//         {/* Conditional rendering based on currentStep */}
//        {currentStep === 0 && (
//   <div className="bg-white p-6 mt-8 ">
//     <div className="flex items-center gap-2 mb-4">
//       <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
//         01
//       </span>
//       <h2 className="text-3xl text-gray-900">When are you planning to buy your home?</h2>
//     </div>
//     <form>
//       <fieldset>
//         <legend className="sr-only">Home Purchase Timing</legend>
//         {[
//           { label: "Right Away", value: "right_away" },
//           { label: "Within 3 Months", value: "within_3_months" },
//           { label: "Within a Year", value: "within_a_year" },
//           { label: "Someday", value: "someday" }
//         ].map((option, index) => (
//           <label key={index} className="block mt-4 text-lg text-gray-700">
//             <input
//               type="radio"
//               name="purchaseTiming"
//               value={option.value}
//               className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
//             />
//             <span className="ml-4">{option.label}</span>
//           </label>
//         ))}
//       </fieldset>
//       <fieldset className="grid grid-cols-2 gap-4 mt-8">
//         <legend className="text-lg text-gray-900">Are you preapproved?</legend>
//         {[
//           { label: "Yes", value: "yes" },
//           { label: "No", value: "no" }
//         ].map((option, index) => (
//           <label key={index} className="block mt-4 text-lg text-gray-700">
//             <input
//               type="radio"
//               name="preapproved"
//               value={option.value}
//               className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
//             />
//             <span className="ml-4">{option.label}</span>
//           </label>
//         ))}
//       </fieldset>
//     </form>
//   </div>
// )}


//         {currentStep === 1 && (
//           <div className="bg-white p-6 rounded-lg mt-8 ml-4">
           
//             <div className="mb-4">
//               <div className="flex items-center mb-8 gap-2">
//                 <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
//                   02
//                 </span>
//                 <h2 className="text-3xl  text-gray-900">Where Are You Planning To Buy?</h2>
//               </div>

//               <p className="text-black">
//                 Your purpose is understandable. Could you tell us your address? <br/>
//                 This helps us make sure we're available in your area.
//               </p>
//             </div>
//             <div className="mt-4">
//               <div className="relative mb-8 mt-4 mr-8">
//               <input
//   type="text"
//   placeholder="Search your property's city, ZIP code, or address"
//   value={userResponses.address}
//   onChange={(e) => handleSelect("address", e.target.value)}
//   className="w-full px-1 py-2 mt-2 leading-tight text-black bg-transparent shadow-none focus:outline-none focus:bg-transparent placeholder-gray-500"
//   style={{ borderBottom: "3px solid black" }}
// />
               
//               </div>
          
//             </div>
            
//           </div>
//         )}

// {currentStep === 2 && (
//   <div className="bg-white p-6 mt-4">
//     <div className="flex items-center mb-4">
//       <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
//         03
//       </span>
//       <h2 className="text-3xl text-gray-900">What Is Important To You?</h2>
//     </div>
//     <form>
//       <fieldset className="preferences-grid ">
//         <legend className="sr-only">Preferences in Housing</legend>
//         {[
//           { label: "Great School", value: "great_school" },
//           { label: "Low Crime", value: "low_crime" },
//           { label: "Easy Commute", value: "easy_commute" },
//           { label: "Low Disaster Risk", value: "low_disaster_risk" },
//           { label: "Big Lot", value: "big_lot" },
//           { label: "High Walkability", value: "high_walkability" },
//           { label: "Others", value: "others" }
//         ].map((option, index) => (
//           <label key={index} className="block text-lg text-gray-700">
//             <input
//               type="radio"
//               name="preference"
//               value={option.value}
//               className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
//             />
//             <span className="ml-4">{option.label}</span>
//           </label>
//         ))}
//       </fieldset>
//     </form>
//   </div>
// )}





// {currentStep === 3 && (
//   <div className="bg-white p-6 mt-8">
//     <div className="flex items-center mb-4">
//       <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
//         04
//       </span>
//       <h2 className="text-3xl text-gray-900"> What Are Your Preferences?</h2>
//     </div>
//     <form>
//       <div className="mb-6">
//         <h3 className="text-xl font-bold mb-4">Bedrooms</h3>
//         <div className="flex space-x-2 mb-2">
//           {["Any", "1+", "2+", "3+", "4+", "5+"].map((label, index) => (
//             <label key={index} className="flex items-center space-x-2">
//               <input
//                 type="radio"
//                 name="bedrooms"
//                 value={label}
//                 className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
//               />
//               <span className="text-gray-700">{label}</span>
//             </label>
//           ))}
//         </div>
//       </div>
//       <div className="mb-8">
//         <h3 className="text-xl font-bold mb-4">Bathrooms</h3>
//         <div className="flex space-x-2 mb-2">
//           {["Any", "1+", "2+", "3+", "4+", "5+"].map((label, index) => (
//             <label key={index} className="flex items-center space-x-2">
//               <input
//                 type="radio"
//                 name="bathrooms"
//                 value={label}
//                 className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
//               />
//               <span className="text-gray-700">{label}</span>
//             </label>
//           ))}
//         </div>
//       </div>
//       <div className="mt-4 mb-6">
//         <h3 className="text-xl font-bold mb-2">SQFT</h3>
//         <input
//           type="range"
//           min="0"
//           max="10000"
//           step="100"
//           className="w-full h-2 bg-pink-200 rounded-lg appearance-none cursor-pointer"
//         />
//       </div>
//       <div className="flex justify-between text-gray-900">
//         <span>Minimum</span>
//         {/* <span>No min</span>
//         <span>Maximum</span> */}
//         <span>Maximum</span>
//       </div>
//     </form>
//   </div>
// )}


// {currentStep === 4 && (
//   <div className="bg-white p-6 mt-8">
//     <div className="flex items-center gap-2 mb-4">
//       <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
//         05
//       </span>
//       <h2 className="text-3xl text-gray-900">You're Qualified For $7,500 In Cashback!</h2>
//     </div>
//     <div className="text-lg text-gray-600 mb-8">
//       <div className="mb-4">
//         <p>Your Home Value:</p>
//         <p className="text-2xl font-bold">$1,970,000</p>
//       </div>
//       <div className="mb-4">
//         <p>State:</p>
//         <p className="text-2xl font-bold">California</p>
//       </div>
//     </div>
//     <div className="pt-4 pb-4 border-t border-b border-gray-200">
//       <h3 className="text-xl text-gray-900 mb-2">Total Savings</h3>
//       <div className="flex justify-between">
//         <p>Traditional Agent:</p>
//         <p className="text-xl">$0</p>
//       </div>
//       <div className="flex justify-between mt-2">
//         <p>Working With Us:</p>
//         <p className="text-xl text-pink-600">+ $19,700</p>
//       </div>
//     </div>
//     <div className="flex justify-end mt-6">
//     </div>
//   </div>
// )}


      

//         {showEligibilityResult && (
//           <EligibilityResult
//             eligibleAmount={userResponses.qualifiedAmount}
//             userCount={userCount}
//             userData={userResponses} 
//           />
//         )}
//       </div>

//       <div className="w-1/3 hidden md:block w-1/3">
//         <img
//           src={stepImages[currentStep]}
//           alt={`Step ${currentStep + 1}`}
//           className="object-cover h-full w-3/7"
//         />
//       </div>

//       <Navigation
//       onBack={goBack}
//       onNext={goNext}
//       canGoBack={currentStep > 0}
//       finalStep={currentStep === steps.length - 1}
//       isSelectionMade={isSelectionMade}
//       currentStep={currentStep}
//     />
//     </div>
//   );
// };

