import React from 'react'

const AnalyzeData = () => {
  return (
   <div className='w-full h-full bg-white py-20'>
     <h2 className='w-full sm:w-[65%] 2xl:w-[1300px] mx-auto text-2xl sm:text-4xl sm:leading-[50px] 2xl:text-6xl font-light 2xl:leading-[82px] text-center mb-6 capitalize'>Our AI analyzed all the data that will impact home prices <span className='text-[#ED5272] font-bold'>in 2024</span></h2>
    <p className='text-[#858E96] text-lg sm:text-xl sm:leading-[60px] text-center font-semibold font-quickSand mb-14'>The data can be broadly classified into 3 categories</p>
    
    <div className='flex flex-col sm:flex-row justify-center items-center gap-20 w-full px-1 sm:px-0'>
     <div className='h-[60px] sm:pr-20 sm:border-r sm:border-r-black'>
      <h4 className='text-xl font-medium text-center mb-2 capitalize'>Market conditions</h4>
      <p className='text-[#ED5272] font-medium capitalize text-center'> (more favorable = higher home price growth)</p>
     </div>
     <div className='h-[60px] sm:pr-20 sm:border-r sm:border-r-black'>
      <h4 className='text-xl font-medium text-center mb-2 capitalize'>Demand for homes</h4>
      <p className='text-[#ED5272] font-medium capitalize text-center'> (higher demand = higher home price growth)</p>
     </div>
     <div className='h-[60px] sm:border-r-black'>
      <h4 className='text-xl font-medium text-center mb-2 capitalize'>Supply of homes</h4>
      <p className='text-[#ED5272] font-medium capitalize text-center'> (lower supply = higher home price growth)</p>
    </div>
    </div>
    </div>
  )
}

export default AnalyzeData