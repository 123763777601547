export const ficoScoreCategories = [
  {
    label: "Poor (<600)",
    range: {
      min: 0,
      max: 599,
    },
    category: "poor",
  },
  {
    label: "Okay (600-660)",
    range: {
      min: 600,
      max: 660,
    },
    category: "okay",
  },
  {
    label: "Decent (660-720)",
    range: {
      min: 661,
      max: 720,
    },
    category: "decent",
  },
  {
    label: "Excellent (>720)",
    range: {
      min: 721,
      max: 850,
    },
    category: "excellent",
  },
];

export const formFields = [
  {
    id: "firstName",
    name: "firstName",
    placeholder: "First name*",
    type: "text",
  },
  {
    id: "lastName",
    name: "lastName",
    placeholder: "Last name*",
    type: "text",
  },
  {
    id: "email",
    name: "email",
    placeholder: "Email address*",
    type: "email",
  },
  {
    id: "mobile",
    name: "mobile",
    placeholder: "Mobile number*",
    type: "tel",
  },
  {
    id: "source",
    name: "source",
    placeholder: "How did you hear about us?*",
    type: "select",
  },
];

export const terms = [
  "Both active monthly employees & W2 income are required with credit report review.",
  "Income/bank statements required for verification of your credit with credit pull.",
  "Home.LLC may need requires IRA proceeds to be used to clear any pre-existing liens or condition to close.",
  "The following limitations shall apply in all cases: no bankruptcy, foreclosure and/or short sale or deed in lieu within the previous five years; no 30-day delinquencies on any mortgage within the prior 24 months.",
  "In most cases, Home.LLC must be in no greater than 2nd lien position and the property must be clear of any liens deemed unacceptable by Home.LLC in its discretion.",
  "A minimum title amount of $10,000 is required on all transactions.",
  "Property condition rating, as described in the uniform appraisal report (UAR), must be at least C4 or better and properties for which comparable valuations, in the discretion of Home.LLC, do not exist may not participate.",
  "Not available in all states.",
  "For consumers with credit scores below 600 you may be required to verify income. You may also be required to utilize IRA proceeds to pay off certain outstanding debts as a condition to close.",
  "To secure the performance of your obligations under this Agreement, you will place a lien on your property in the form of either a 'performance deed of trust' or a 'performance mortgage' depending upon which state the property is located.",
  "Home.LLC charges up to a 3% origination fee and other third party costs/closing costs such as appraisal, title, and government fees.",
  "The time actually required to close varies.",
  "The timeline to funding approved loans may vary depending on when the homeowner submits a completed application and all supplemental documents are received and verified.",
  "Please contact us for more information at support@home.llc",
  "For additional information please review the Home.LLC Product Guide",
];

// email
// {
//   "data": {
//       "results": [
//           {
//               "PurchasedValue": "ofeliathrew@hotmail.com",
//               "Email": [
//                   {
//                       "href": "mailto:ofeliathrew@hotmail.com",
//                       "linktext": "ofeliathrew@hotmail.com",
//                       "status": "Active",
//                       "source": "Purchased"
//                   },
//                   {
//                       "href": "mailto:johnnykys@gmail.com",
//                       "linktext": "johnnykys@gmail.com",
//                       "status": "Active",
//                       "source": "Purchased"
//                   },
//                   {
//                       "href": "mailto:otavera4@yahoo.com",
//                       "linktext": "otavera4@yahoo.com",
//                       "status": "Active",
//                       "source": "Purchased"
//                   }
//               ]
//           }
//       ],
//       "resultCount": 1,
//       "totalCost": 0
//   }
// }

// phone
// {
//   "data": {
//       "results": [
//           {
//               "PurchasedValue": "626-922-2735",
//               "Phone": [
//                   {
//                       "href": "tel:+1-626-922-2735",
//                       "linktext": "626-922-2735",
//                       "phoneType": "mobile",
//                       "status": "Active",
//                       "source": "Purchased"
//                   },
//                   {
//                       "href": "tel:+1-626-922-2785",
//                       "linktext": "626-922-2785",
//                       "phoneType": "mobile",
//                       "status": "Active",
//                       "source": "Purchased"
//                   },
//                   {
//                       "href": "tel:+1-626-214-6837",
//                       "linktext": "626-214-6837",
//                       "phoneType": "mobile",
//                       "status": "Active",
//                       "source": "Purchased"
//                   }
//               ]
//           }
//       ],
//       "resultCount": 1,
//       "totalCost": 0
//   }
// }
