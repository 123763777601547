import React, { useState } from "react";
import arrowRight from "../../assets/arrow.svg";

const NeedFromUs = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "emailOrPhone") {
      setEmailOrPhone(value);
    } else if (name === "message") {
      setMessage(value);
    }
  };

  const handleSubmit = () => {
    setError(""); // Reset error message
    if (!emailOrPhone || !message) {
      setError("Please fill in all fields.");
      return;
    }

    if (!isChecked) {
      setError("Please agree to the terms.");
      return;
    }

    if (!validateEmailOrPhone(emailOrPhone)) {
      setError("Please enter a valid email or phone number.");
      return;
    }

    console.log("Email/Phone:", emailOrPhone);
    console.log("Message:", message);
    setEmailOrPhone("");
    setMessage("");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const validateEmailOrPhone = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for phone number validation (10 digits)
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  return (
    <div className="w-full h-full lg:h-[430px] bg-[#212529] py-16 lg:pt-[97px]">
      <div className="w-full px-4 sm:px-[64px] h-full lg:h-[204px] mx-auto">
        <div className="w-full h-full lg:h-[106px] flex flex-col gap-10 lg:gap-0 lg:flex-row lg:justify-between lg:items-center mb-[10px] sm:mb-8 lg:mb-20 px-2 lg:px-0">
          <div className="flex flex-col items-start gap-3 w-[500px] 2xl:w-[580px]">
            <h4 className="text-xl 2xl:text-[36px] 2xl:leading-[36.1px] font-semibold text-white mb-2">
              What do you need from us?
            </h4>

            <input
              type="text"
              placeholder="Tell us more"
              className="bg-transparent border-b border-white outline-none focus:outline-none w-full p-2 text-lg font-light text-white"
              name="message"
              value={message}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col items-start gap-3 w-[500px] 2xl:w-[580px]">
            <h4 className="text-xl 2xl:text-[36px] 2xl:leading-[36.1px] font-semibold text-white mb-2">
              How can we get in touch?
            </h4>

            <input
              type="text"
              placeholder="Enter Email ID or Phone Number"
              className="bg-transparent border-b border-white outline-none focus:outline-none w-full p-2 text-lg font-light text-white"
              name="emailOrPhone"
              value={emailOrPhone}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <img
              src={arrowRight}
              alt="arrow-right"
              className="mt-2 cursor-pointer hidden sm:block"
              onClick={handleSubmit}
            />
          </div>
        </div>

        <div className="flex items-center gap-5 px-2 mb-10">
          <input
            type="checkbox"
            className="bg-transparent border-none"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p className="text-[10px] leading-[16px] sm:text-lg sm:leading-7 font-light text-white lg:w-[70%]">
            I consent to receive text messages from Hoom Inc., its
            representatives, or anyone communicating on its behalf at the
            specific number I have provided here. I also consent to the
          </p>
        </div>

        {/* <div className="flex items-center justify-between px-[27px] sm:hidden">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.08824 30.1217C5.79383 30.1217 7.17648 28.7391 7.17648 27.0335C7.17648 25.3279 5.79383 23.9453 4.08824 23.9453C2.38265 23.9453 1 25.3279 1 27.0335C1 28.7391 2.38265 30.1217 4.08824 30.1217Z"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M27.9115 30.1217C29.6171 30.1217 30.9997 28.7391 30.9997 27.0335C30.9997 25.3279 29.6171 23.9453 27.9115 23.9453C26.2059 23.9453 24.8232 25.3279 24.8232 27.0335C24.8232 28.7391 26.2059 30.1217 27.9115 30.1217Z"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M16.0003 8.05929C17.7059 8.05929 19.0886 6.67664 19.0886 4.97105C19.0886 3.26546 17.7059 1.88281 16.0003 1.88281C14.2948 1.88281 12.9121 3.26546 12.9121 4.97105C12.9121 6.67664 14.2948 8.05929 16.0003 8.05929Z"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M14.0242 8.49609L5.62305 23.4981"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M17.9756 8.49609L26.3767 23.4981"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M8.05859 27.0273H23.9409"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <img
            src={arrowRight}
            alt="arrow-right"
            className="mt-2 cursor-pointer sm:hidden"
            onClick={handleSubmit}
          />
        </div> */}
        <button className="mx-2 px-10 py-2 text-white border rounded-2xl text-[16px] sm:hidden" onClick={() => handleSubmit()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default NeedFromUs;
