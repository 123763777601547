import { useState } from "react";
import CashoutCalculator from "../components/CashoutPage/CashoutCalculator";
import FicoScoreQualification from "../components/CashoutPage/FicoScoreQualification";
import Header from "../components/CashoutPage/Header";
import HeroSection from "../components/CashoutPage/HeroSection";
import HomeDetails from "../components/CashoutPage/HomeDetails";
import TermsConditions from "../components/CashoutPage/TermsCondition";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import WelcomePopup from "../components/CashoutPage/overlay-popups/Welcome-popup";
import {
  useGetOwnerDetailsUsingRadarIDQuery,
  useGetPropertyUsingRadarIDQuery,
} from "../store/apis/cashoutApi";

function CashoutPage() {
  const cashoutState = useSelector((state) => state.cashout);
  const { radarID } = cashoutState;

  const { data, isLoading, isError, isSuccess } =
    useGetPropertyUsingRadarIDQuery(radarID, { skip: Boolean(radarID === "") });

  const {
    data: ownerDetails,
    isLoading: isOwnerDataLoading,
    isError: isOwnerError,
  } = useGetOwnerDetailsUsingRadarIDQuery(radarID, {
    skip: Boolean(radarID === ""),
  });

  const [showWelcome, setShowWelcome] = useState(true);

  return (
    <>
      <Header />
      <HeroSection />
      <CashoutCalculator propertyRes={data?.results} />
      {radarID && (
        <>
          <HomeDetails
            propertyRes={data?.results}
            ownerDetails={ownerDetails?.results}
          />
        </>
      )}
      <FicoScoreQualification propertyRes={data?.results} />
      <Footer />
      <TermsConditions />
      <WelcomePopup
        isVisible={showWelcome}
        onClose={() => setShowWelcome(false)}
      />
    </>
  );
}

export default CashoutPage;
