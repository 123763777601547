import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// API Slices
import { cashoutApi } from "./apis/cashoutApi";

// Reducers
import addressReducer from "./slices/stringSlice";
import cashoutReducer from "./slices/cashoutSlice";
import cashoutCalculateReducer from "./slices/cashoutCalcSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  address: addressReducer,
  cashout: cashoutReducer,
  cashoutCalc: cashoutCalculateReducer,
  [cashoutApi.reducerPath]: cashoutApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(cashoutApi.middleware),
});

export const persistor = persistStore(store);

export default store;
