import { disablePageScroll, enablePageScroll } from "scroll-lock";
import Button from "./Button";
import MenuSvg from "../../assets/MenuSvg";
import { useEffect, useState } from "react";
import DropdownMenu from "./DropDown";
import { logoNav } from "../../assets";
import { useLocation } from "react-router-dom"; // import the useLocation hook

export const navigation = [
  {
    id: "0",
    title: "Analytics",
    dropdownItems: [
      { name: "Analyze", path: "/analyze" },
      { name: "Home.AI", path: "/ai" },
    ],
  },
  {
    id: "1",
    title: "Buy",
    path: "/buyer",
  },
  {
    id: "2",
    title: "Finance",
    path: "/finance",
  },
  {
    id: "5",
    title: "Invest",
    path: "/invest",
  },
  {
    id: "3",
    title: "Sell",
    path: "/sell",
  },
  {
    id: "4",
    title: "About Us",
    dropdownItems: [
      { name: "FAQ", path: "/faq" },
      { name: "Our Team", path: "/team" },
      { name: "Culture", path: "/culture" },
      { name: "Press", path: "/press" },
      { name: "Legal", path: "/privacy" },
      { name: "Login", path: "/login" },
    ],
  },
];

const Header = () => {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [activeNavItem, setActiveNavItem] = useState("");
  const [scrollY, setScrollY] = useState(0);
  const location = useLocation(); // get the current location

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    const matchingNavItem = navigation.find(
      (item) => currentPath === item.path
    );
    if (matchingNavItem) {
      setActiveNavItem(matchingNavItem.title);
    } else {
      // Check dropdown items
      const matchingDropdownItem = navigation.reduce((acc, item) => {
        if (item.dropdownItems) {
          return (
            item.dropdownItems.find(
              (dropdownItem) => currentPath === dropdownItem.path
            ) || acc
          );
        }
        return acc;
      }, null);
      if (matchingDropdownItem) {
        setActiveNavItem(matchingDropdownItem.name);
      } else {
        setActiveNavItem("");
      }
    }
  }, [location]);

  const toggleNavigation = () => {
    setOpenNavigation(!openNavigation);
    openNavigation ? enablePageScroll() : disablePageScroll();
  };

  const handleActiveNavItem = (item) => {
    setActiveNavItem(item);
    // Store activeNavItem in localStorage
    localStorage.setItem("activeNavItem", item);
  };

  const handleDropdownItemClick = (item) => {
    setActiveNavItem(item.name);
    setOpenNavigation(false);
    localStorage.setItem("activeNavItem", item.name);
    window.location.href = item.path;
  };

  const bgColor = `${
    scrollY > 300
      ? "bg-[#212529] bg-opacity-60"
      : "bg-[#FFFFFF4D] bg-opacity-50"
  }`;
  return (
    <div
      className={`mt-4 fixed top-0 left-1/2 transform -translate-x-1/2 w-[95%] 2xl:w-4/5 z-50
      ${
        openNavigation ? "bg-[#FFFFFF4D] bg-opacity-50" : bgColor
      } rounded-full backdrop-blur`}
    >
      <div className="flex items-center justify-end px-2 sm:px-5 lg:px-7.5 xl:px-10 h-[60px] sm:h-auto sm:py-4">
        {/*******************************Logo********************************/}
        <img
          src={logoNav}
          alt="Logo"
          className="mr-auto cursor-pointer ml-4 sm:ml-0 py-2 sm:py-0"
          onClick={() => {
            window.location.href = "/";
            setActiveNavItem("");
            localStorage.setItem("activeNavItem", "");
          }}
          style={{ height: "40px" }}
        />
        {/*******************************Navigation***************************/}
        <nav
          className={`w-full ${
            openNavigation ? "flex" : "hidden"
          } fixed top-[3.5rem] px-4 sm:top-[5rem] right-0 bottom-0 lg:flex lg:static lg:bg-transparent`}
        >
          <div
            className="flex h-[300px] bg-black bg-opacity-80 rounded-lg sm:rounded-none sm:h-auto flex-col gap-2 sm:gap-0 lg:flex-row
         sm:bg-transparent sm:justify-end items-start sm:items-center w-full mx-2 px-4 sm:px-0 py-2 sm:py-0"
          >
            {navigation.map((item) => (
              <div
                key={item.id}
                className="relative group"
                onMouseEnter={() => setDropdownOpen(item.id)}
                onMouseLeave={() => setDropdownOpen(null)}
              >
                <button
                  className={`dropdown-button text-white font-semibold px-6 py-2 xl:px-12 
                  focus:outline-none relative inline-flex items-center hover:bg-custom-ed5272 hover:text-white hover:rounded-3xl
                  ${
                    dropdownOpen === item.id || !item.dropdownItems
                      ? "open"
                      : ""
                  }
                  ${
                    dropdownOpen === item.id ||
                    activeNavItem === item.title ||
                    (item.dropdownItems &&
                      item.dropdownItems.some(
                        (dropdownItem) => dropdownItem.name === activeNavItem
                      ))
                      ? "bg-custom-ed5272 text-white rounded-3xl"
                      : ""
                  }`}
                  onClick={() => {
                    if (item.path) {
                      window.location.href = item.path;
                    }
                    handleActiveNavItem(item.title);
                  }}
                >
                  {item.title}
                  {item.dropdownItems && (
                    <span className="dropdown-arrow ml-2"></span>
                  )}
                </button>

                {dropdownOpen === item.id && item.dropdownItems && (
                  <DropdownMenu
                    dropdownItems={item.dropdownItems}
                    onItemClick={handleDropdownItemClick}
                    activeItem={activeNavItem}
                  />
                )}
              </div>
            ))}
          </div>
        </nav>
        <Button className="lg:hidden" onClick={toggleNavigation}>
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
