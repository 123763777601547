export const boundry = [
  {
    id: "320",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94502",
      GEOID10: "94502",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 6942424,
      AWATER10: 3080784,
      INTPTLAT10: "+37.7375733",
      INTPTLON10: "-122.2455205",
      Name: "94502",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.268754, 37.738534],
          [-122.262689, 37.747717],
          [-122.259468, 37.752593999999995],
          [-122.257064, 37.756237],
          [-122.25654, 37.756011],
          [-122.25586200000001, 37.75562],
          [-122.25585, 37.755617],
          [-122.25528800000001, 37.755488],
          [-122.254617, 37.75523],
          [-122.254133, 37.754999],
          [-122.253524, 37.754812],
          [-122.25304, 37.754706999999996],
          [-122.252687, 37.75458],
          [-122.252306, 37.754421],
          [-122.251983, 37.754298999999996],
          [-122.251822, 37.754239],
          [-122.251079, 37.753855],
          [-122.250906, 37.753795],
          [-122.25075, 37.75372],
          [-122.25127, 37.752798],
          [-122.252832, 37.750034],
          [-122.253354, 37.749113],
          [-122.25301400000001, 37.749108],
          [-122.25199599999999, 37.749094],
          [-122.251657, 37.749089999999995],
          [-122.25108900000001, 37.749083],
          [-122.24857399999999, 37.748993],
          [-122.239749, 37.748677],
          [-122.239326, 37.748661999999996],
          [-122.236423, 37.748557999999996],
          [-122.23640499999999, 37.748549],
          [-122.236366, 37.748556],
          [-122.236285, 37.748553],
          [-122.236244, 37.748552],
          [-122.236185, 37.748554999999996],
          [-122.236124, 37.74856],
          [-122.23603800000001, 37.748566],
          [-122.23601, 37.748567],
          [-122.235952, 37.748571],
          [-122.235787, 37.748628],
          [-122.235229, 37.748687],
          [-122.233947, 37.748826],
          [-122.227908, 37.749477],
          [-122.22610399999999, 37.749671],
          [-122.226076, 37.749674],
          [-122.225895, 37.749694],
          [-122.226029, 37.749334999999995],
          [-122.226325, 37.748545],
          [-122.226391, 37.74849],
          [-122.22641899999999, 37.748467],
          [-122.226623, 37.748298999999996],
          [-122.226567, 37.747903],
          [-122.226646, 37.747439],
          [-122.226885, 37.74605],
          [-122.226966, 37.745587],
          [-122.2266, 37.745501],
          [-122.226174, 37.745402],
          [-122.225782, 37.745346999999995],
          [-122.225492, 37.745326],
          [-122.225123, 37.7453],
          [-122.225129, 37.74499],
          [-122.225221, 37.742207],
          [-122.22552400000001, 37.733070999999995],
          [-122.225562, 37.731958999999996],
          [-122.225653, 37.731766],
          [-122.225785, 37.731462],
          [-122.226171, 37.731038999999996],
          [-122.22664, 37.730371],
          [-122.226574, 37.729607],
          [-122.226379, 37.727315999999995],
          [-122.226314, 37.726552999999996],
          [-122.226663, 37.726614999999995],
          [-122.226694, 37.726621],
          [-122.226766, 37.726634],
          [-122.226784, 37.726637],
          [-122.227067, 37.726687999999996],
          [-122.22771, 37.726804],
          [-122.22806, 37.726867],
          [-122.228084, 37.726841],
          [-122.22815800000001, 37.726763],
          [-122.228183, 37.726738],
          [-122.228379, 37.726529],
          [-122.228625, 37.726276999999996],
          [-122.231853, 37.72297],
          [-122.235965, 37.718759],
          [-122.23610099999999, 37.71862],
          [-122.236902, 37.71902],
          [-122.24404200000001, 37.724171999999996],
          [-122.244359, 37.724401],
          [-122.246139, 37.725685],
          [-122.247919, 37.726969],
          [-122.248308, 37.72725],
          [-122.248721, 37.727695],
          [-122.248827, 37.727807999999996],
          [-122.256542, 37.721242],
          [-122.264426, 37.732948],
          [-122.269116, 37.737983],
          [-122.268754, 37.738534],
        ],
      ],
    },
  },
  {
    id: "323",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94506",
      GEOID10: "94506",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 48524395,
      AWATER10: 31771,
      INTPTLAT10: "+37.8096292",
      INTPTLON10: "-121.9132414",
      Name: "94506",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-121.973019, 37.835519],
          [-121.97226599999999, 37.835662],
          [-121.9718, 37.835766],
          [-121.971726, 37.835778999999995],
          [-121.971562, 37.835805],
          [-121.971392, 37.835819],
          [-121.971349, 37.835823],
          [-121.971081, 37.835846],
          [-121.970797, 37.835861],
          [-121.97038599999999, 37.835825],
          [-121.970052, 37.835795999999995],
          [-121.969894, 37.835784],
          [-121.969422, 37.835750999999995],
          [-121.96926500000001, 37.83574],
          [-121.969167, 37.835733],
          [-121.969097, 37.835724],
          [-121.96895, 37.835708],
          [-121.968735, 37.835678],
          [-121.96859599999999, 37.835653],
          [-121.968431, 37.835625],
          [-121.96815699999999, 37.835567999999995],
          [-121.968096, 37.835556],
          [-121.967886, 37.835505],
          [-121.967694, 37.835453],
          [-121.967601, 37.835435],
          [-121.96751, 37.835411],
          [-121.967421, 37.835381999999996],
          [-121.967348, 37.835353],
          [-121.96733499999999, 37.835347999999996],
          [-121.967251, 37.835308999999995],
          [-121.9671, 37.835226999999996],
          [-121.96696399999999, 37.835111],
          [-121.966904, 37.835052],
          [-121.966849, 37.834989],
          [-121.966814, 37.834942],
          [-121.966276, 37.834300999999996],
          [-121.966239, 37.834238],
          [-121.966148, 37.83412],
          [-121.96603400000001, 37.834015],
          [-121.9659, 37.833926],
          [-121.965828, 37.833888],
          [-121.965715, 37.833839999999995],
          [-121.965226, 37.833704999999995],
          [-121.965141, 37.833698999999996],
          [-121.965055, 37.833698999999996],
          [-121.964884, 37.833715],
          [-121.96472, 37.833753],
          [-121.964648, 37.833777],
          [-121.96449799999999, 37.833805],
          [-121.964421, 37.833811],
          [-121.964344, 37.833811],
          [-121.96430000000001, 37.833807],
          [-121.96422, 37.833801],
          [-121.963774, 37.833732],
          [-121.963584, 37.833681999999996],
          [-121.963493, 37.83365],
          [-121.963404, 37.833611999999995],
          [-121.963319, 37.83357],
          [-121.962667, 37.833209],
          [-121.962595, 37.833174],
          [-121.962441, 37.833118],
          [-121.962361, 37.833098],
          [-121.962194, 37.833073999999996],
          [-121.96211, 37.83307],
          [-121.962025, 37.833072],
          [-121.96191, 37.833082999999995],
          [-121.961825, 37.833098],
          [-121.961741, 37.833118],
          [-121.961659, 37.833144],
          [-121.961581, 37.833174],
          [-121.961505, 37.83321],
          [-121.961376, 37.833287],
          [-121.961134, 37.833416],
          [-121.961063, 37.833445],
          [-121.960988, 37.83347],
          [-121.96091200000001, 37.833489],
          [-121.960833, 37.833504],
          [-121.960673, 37.833515999999996],
          [-121.96059199999999, 37.833514],
          [-121.960541, 37.833507999999995],
          [-121.960481, 37.833501999999996],
          [-121.95991699999999, 37.83338],
          [-121.959496, 37.833304],
          [-121.959075, 37.833214999999996],
          [-121.958946, 37.833172999999995],
          [-121.958819, 37.833126],
          [-121.958695, 37.833073999999996],
          [-121.958574, 37.833017999999996],
          [-121.958416, 37.832935],
          [-121.957883, 37.832633],
          [-121.957376, 37.832338],
          [-121.956885, 37.832063],
          [-121.956806, 37.832027],
          [-121.956725, 37.831997],
          [-121.95664, 37.831970999999996],
          [-121.956554, 37.83195],
          [-121.956465, 37.831935],
          [-121.956285, 37.831921],
          [-121.955865, 37.831922],
          [-121.954999, 37.831922999999996],
          [-121.95285, 37.831917],
          [-121.95192, 37.831928],
          [-121.951949, 37.832088],
          [-121.951982, 37.832395999999996],
          [-121.951975, 37.83246],
          [-121.951902, 37.833079999999995],
          [-121.951848, 37.833498],
          [-121.951758, 37.834204],
          [-121.951677, 37.834751],
          [-121.951617, 37.835169],
          [-121.951404, 37.835254],
          [-121.951288, 37.835308],
          [-121.951176, 37.835367],
          [-121.951067, 37.835431],
          [-121.950963, 37.835498],
          [-121.950863, 37.83557],
          [-121.950768, 37.835646],
          [-121.950678, 37.835726],
          [-121.950379, 37.836065999999995],
          [-121.9501, 37.836383999999995],
          [-121.949861, 37.836712],
          [-121.949788, 37.836847],
          [-121.949589, 37.837219999999995],
          [-121.94951, 37.837333],
          [-121.949462, 37.837385],
          [-121.949408, 37.837434],
          [-121.949349, 37.837478999999995],
          [-121.949286, 37.83752],
          [-121.948902, 37.837739],
          [-121.94863699999999, 37.837934],
          [-121.948327, 37.838269],
          [-121.948262, 37.838345],
          [-121.948174, 37.838467],
          [-121.948034, 37.838764],
          [-121.94797, 37.838902],
          [-121.947917, 37.839054999999995],
          [-121.947869, 37.839211],
          [-121.947803, 37.839476],
          [-121.947562, 37.840227999999996],
          [-121.947503, 37.84036],
          [-121.94748899999999, 37.840385],
          [-121.947479, 37.840452],
          [-121.947478, 37.840519],
          [-121.947483, 37.840585],
          [-121.947494, 37.840652],
          [-121.947512, 37.840717999999995],
          [-121.947536, 37.840781],
          [-121.94760000000001, 37.840906],
          [-121.947618, 37.84093],
          [-121.94766899999999, 37.840968],
          [-121.947701, 37.84098],
          [-121.947748, 37.840989],
          [-121.947999, 37.840959],
          [-121.94817499999999, 37.840948],
          [-121.94827699999999, 37.840948999999995],
          [-121.948371, 37.840955],
          [-121.948523, 37.840906],
          [-121.948595, 37.840876],
          [-121.948655, 37.840845],
          [-121.948724, 37.84083],
          [-121.94879399999999, 37.840821],
          [-121.948865, 37.840817],
          [-121.94900799999999, 37.840826],
          [-121.949077, 37.840837],
          [-121.949178, 37.840865],
          [-121.949244, 37.840894999999996],
          [-121.949306, 37.84093],
          [-121.949365, 37.840968],
          [-121.949421, 37.841012],
          [-121.94946999999999, 37.841057],
          [-121.949527, 37.841122999999996],
          [-121.949561, 37.841173999999995],
          [-121.949589, 37.841226999999996],
          [-121.949611, 37.841282],
          [-121.949625, 37.841338],
          [-121.949633, 37.841395],
          [-121.949636, 37.841452],
          [-121.949629, 37.841524],
          [-121.949598, 37.841713],
          [-121.949563, 37.841891],
          [-121.949557, 37.842175999999995],
          [-121.949529, 37.842486],
          [-121.949511, 37.842538],
          [-121.949512, 37.842562],
          [-121.94952, 37.842583999999995],
          [-121.949534, 37.842605999999996],
          [-121.949552, 37.842622999999996],
          [-121.949577, 37.842636999999996],
          [-121.949622, 37.842648],
          [-121.949668, 37.842636999999996],
          [-121.949754, 37.842600999999995],
          [-121.94980699999999, 37.842566],
          [-121.9499, 37.842472],
          [-121.949977, 37.84241],
          [-121.95001500000001, 37.84241],
          [-121.950088, 37.842427],
          [-121.950121, 37.842442],
          [-121.950147, 37.842466],
          [-121.95015699999999, 37.842486],
          [-121.950162, 37.842507],
          [-121.950131, 37.842622],
          [-121.950112, 37.842752999999995],
          [-121.950115, 37.842819],
          [-121.950125, 37.842884],
          [-121.950141, 37.842948],
          [-121.950206, 37.843119],
          [-121.950227, 37.843195],
          [-121.950242, 37.843271],
          [-121.950249, 37.843348],
          [-121.95024599999999, 37.843610999999996],
          [-121.950237, 37.843747],
          [-121.950215, 37.843921],
          [-121.95020199999999, 37.843956],
          [-121.95018400000001, 37.843987999999996],
          [-121.950158, 37.844018999999996],
          [-121.950128, 37.844046999999996],
          [-121.950093, 37.844069999999995],
          [-121.95002, 37.844100999999995],
          [-121.949974, 37.844113],
          [-121.949927, 37.844119],
          [-121.949879, 37.844119],
          [-121.949831, 37.844114999999995],
          [-121.94978499999999, 37.844105],
          [-121.949023, 37.843824999999995],
          [-121.94890699999999, 37.843775],
          [-121.948684, 37.843661],
          [-121.948577, 37.843596999999995],
          [-121.948403, 37.843478999999995],
          [-121.948329, 37.843433999999995],
          [-121.948252, 37.843393999999996],
          [-121.948171, 37.843357],
          [-121.94806, 37.843317],
          [-121.948014, 37.843305],
          [-121.947971, 37.843287],
          [-121.947932, 37.843264],
          [-121.947897, 37.843238],
          [-121.947868, 37.843209],
          [-121.947853, 37.843178],
          [-121.947851, 37.843154999999996],
          [-121.947867, 37.843111],
          [-121.947936, 37.843039],
          [-121.947982, 37.842996],
          [-121.948034, 37.842957],
          [-121.948089, 37.842923],
          [-121.948215, 37.842859],
          [-121.948252, 37.842831],
          [-121.948284, 37.842801],
          [-121.948334, 37.842732],
          [-121.948348, 37.842698999999996],
          [-121.94835499999999, 37.842666],
          [-121.948356, 37.842632],
          [-121.94834900000001, 37.842597999999995],
          [-121.948336, 37.842566],
          [-121.948148, 37.842284],
          [-121.948113, 37.842261],
          [-121.94807399999999, 37.842241],
          [-121.948033, 37.842226],
          [-121.947989, 37.842217],
          [-121.947943, 37.842211999999996],
          [-121.947897, 37.842214],
          [-121.947829, 37.842225],
          [-121.947648, 37.842272],
          [-121.947479, 37.842338],
          [-121.947399, 37.842377],
          [-121.947198, 37.842499],
          [-121.94712799999999, 37.842546],
          [-121.947063, 37.842597],
          [-121.947002, 37.842653],
          [-121.94694799999999, 37.842711],
          [-121.946899, 37.842772],
          [-121.946857, 37.842833],
          [-121.946823, 37.842872],
          [-121.946783, 37.842908],
          [-121.946738, 37.842940999999996],
          [-121.94668899999999, 37.842969],
          [-121.946637, 37.842991999999995],
          [-121.946515, 37.843030999999996],
          [-121.946455, 37.843053999999995],
          [-121.946398, 37.843081999999995],
          [-121.946297, 37.84315],
          [-121.946253, 37.843188999999995],
          [-121.94619900000001, 37.843254],
          [-121.946175, 37.843275],
          [-121.946133, 37.843306],
          [-121.94609299999999, 37.843326],
          [-121.946051, 37.843340999999995],
          [-121.946028, 37.843340999999995],
          [-121.94600000000001, 37.84333],
          [-121.945977, 37.843309],
          [-121.945959, 37.843285],
          [-121.94592399999999, 37.843199],
          [-121.945897, 37.843151999999996],
          [-121.945864, 37.843106],
          [-121.945831, 37.843067999999995],
          [-121.945695, 37.842979],
          [-121.945441, 37.842839999999995],
          [-121.945337, 37.842777],
          [-121.945142, 37.842641],
          [-121.94506200000001, 37.842577],
          [-121.944959, 37.842514],
          [-121.944853, 37.842454],
          [-121.944744, 37.842397999999996],
          [-121.944442, 37.842271],
          [-121.94432499999999, 37.842217],
          [-121.944116, 37.842104],
          [-121.94393099999999, 37.841977],
          [-121.943889, 37.841961999999995],
          [-121.943799, 37.841947],
          [-121.94370599999999, 37.841954],
          [-121.943662, 37.841964999999995],
          [-121.94358199999999, 37.841996],
          [-121.94351, 37.84203],
          [-121.943442, 37.842068],
          [-121.943377, 37.842109],
          [-121.943262, 37.842205],
          [-121.943134, 37.842332999999996],
          [-121.943045, 37.842413],
          [-121.942951, 37.842489],
          [-121.94278800000001, 37.842603],
          [-121.942361, 37.842875],
          [-121.942316, 37.842912],
          [-121.942277, 37.842951],
          [-121.942212, 37.843039999999995],
          [-121.942177, 37.843078],
          [-121.942137, 37.843114],
          [-121.942091, 37.843145],
          [-121.942043, 37.843171],
          [-121.941911, 37.843232],
          [-121.941839, 37.843272999999996],
          [-121.941742, 37.843339],
          [-121.94172499999999, 37.843362],
          [-121.941704, 37.843382],
          [-121.941679, 37.843398],
          [-121.941649, 37.843409],
          [-121.941617, 37.843415],
          [-121.941585, 37.843416],
          [-121.941549, 37.843399],
          [-121.941529, 37.843379],
          [-121.941513, 37.843356],
          [-121.941504, 37.843331],
          [-121.941502, 37.843305],
          [-121.941517, 37.843191999999995],
          [-121.941514, 37.843059],
          [-121.941481, 37.843026],
          [-121.941444, 37.842997],
          [-121.941402, 37.842971999999996],
          [-121.941357, 37.842951],
          [-121.941214, 37.842909],
          [-121.94113, 37.84289],
          [-121.941045, 37.842877],
          [-121.940872, 37.842867],
          [-121.94084699999999, 37.842867999999996],
          [-121.940794, 37.84288],
          [-121.940744, 37.842898],
          [-121.940696, 37.842921],
          [-121.940652, 37.842948],
          [-121.940596, 37.842996],
          [-121.94055900000001, 37.843039999999995],
          [-121.940517, 37.843081],
          [-121.940471, 37.84312],
          [-121.94042, 37.843154],
          [-121.940365, 37.843182999999996],
          [-121.940342, 37.84319],
          [-121.940319, 37.843191],
          [-121.940296, 37.843185999999996],
          [-121.940276, 37.843176],
          [-121.94026, 37.843163],
          [-121.94024999999999, 37.843146],
          [-121.940246, 37.843129],
          [-121.940268, 37.84303],
          [-121.94029, 37.842962],
          [-121.940319, 37.842895],
          [-121.940354, 37.842832],
          [-121.940515, 37.84259],
          [-121.940705, 37.842327],
          [-121.940729, 37.84227],
          [-121.940746, 37.842211],
          [-121.94075699999999, 37.842152],
          [-121.940761, 37.842092],
          [-121.940755, 37.842044],
          [-121.940743, 37.842002],
          [-121.940724, 37.841961],
          [-121.9407, 37.841922],
          [-121.940484, 37.841646],
          [-121.940384, 37.841532],
          [-121.94032899999999, 37.841493],
          [-121.94027, 37.841459],
          [-121.940141, 37.841406],
          [-121.940072, 37.841386],
          [-121.939302, 37.841212],
          [-121.939221, 37.841184],
          [-121.939045, 37.841108],
          [-121.938982, 37.841087],
          [-121.938852, 37.84106],
          [-121.938784, 37.841055],
          [-121.938622, 37.841056],
          [-121.938546, 37.841059],
          [-121.938398, 37.841083999999995],
          [-121.938327, 37.841104],
          [-121.93825799999999, 37.841128999999995],
          [-121.938162, 37.841173],
          [-121.938081, 37.841218999999995],
          [-121.938004, 37.841268],
          [-121.937932, 37.841321],
          [-121.93786399999999, 37.841378],
          [-121.937778, 37.841463999999995],
          [-121.937708, 37.84155],
          [-121.937644, 37.841637999999996],
          [-121.937535, 37.841823],
          [-121.937448, 37.842],
          [-121.937395, 37.842119],
          [-121.937348, 37.842239],
          [-121.937274, 37.842459999999996],
          [-121.937241, 37.842577],
          [-121.937215, 37.842695],
          [-121.937184, 37.842901],
          [-121.937175, 37.843008],
          [-121.937173, 37.843115],
          [-121.937178, 37.843223],
          [-121.937196, 37.843371999999995],
          [-121.937224, 37.84344],
          [-121.937258, 37.843506],
          [-121.937314, 37.843592],
          [-121.937332, 37.84365],
          [-121.937344, 37.843708],
          [-121.937349, 37.843768],
          [-121.937348, 37.843824999999995],
          [-121.937309, 37.844001],
          [-121.937288, 37.84413],
          [-121.937264, 37.844271],
          [-121.937236, 37.844314],
          [-121.937213, 37.844331],
          [-121.937186, 37.844344],
          [-121.937157, 37.844352],
          [-121.937126, 37.844356],
          [-121.937094, 37.844353],
          [-121.937065, 37.844345],
          [-121.93699699999999, 37.844315],
          [-121.936891, 37.844248],
          [-121.93684400000001, 37.844209],
          [-121.936801, 37.844167],
          [-121.936767, 37.844125],
          [-121.936505, 37.843911],
          [-121.93630999999999, 37.843764],
          [-121.936241, 37.843717],
          [-121.936138, 37.843657],
          [-121.936026, 37.843621],
          [-121.93596600000001, 37.84361],
          [-121.93590499999999, 37.843604],
          [-121.935843, 37.843602],
          [-121.935793, 37.843606],
          [-121.935378, 37.843587],
          [-121.93501, 37.843576],
          [-121.93491, 37.843568],
          [-121.934708, 37.843567],
          [-121.934608, 37.843575],
          [-121.934465, 37.843596],
          [-121.93440799999999, 37.84363],
          [-121.934355, 37.843666999999996],
          [-121.934308, 37.843709],
          [-121.934265, 37.843754],
          [-121.93422699999999, 37.843803],
          [-121.934199, 37.843848],
          [-121.934168, 37.843886999999995],
          [-121.93413100000001, 37.843923],
          [-121.934089, 37.843955],
          [-121.934042, 37.843983],
          [-121.933993, 37.844007],
          [-121.933919, 37.844031],
          [-121.933889, 37.84404],
          [-121.93385599999999, 37.844043],
          [-121.93382199999999, 37.844042],
          [-121.93378899999999, 37.844035],
          [-121.933759, 37.844023],
          [-121.933733, 37.844006],
          [-121.933711, 37.843984999999996],
          [-121.933669, 37.843919],
          [-121.933605, 37.843851],
          [-121.933566, 37.843821999999996],
          [-121.933463, 37.843773],
          [-121.93343899999999, 37.843757],
          [-121.933419, 37.843737],
          [-121.933405, 37.843714999999996],
          [-121.933397, 37.843691],
          [-121.933396, 37.843665],
          [-121.93341, 37.843623],
          [-121.933426, 37.843547],
          [-121.933435, 37.843469999999996],
          [-121.93343899999999, 37.843393999999996],
          [-121.933435, 37.843331],
          [-121.933367, 37.84317],
          [-121.93329299999999, 37.843008999999995],
          [-121.933239, 37.842901],
          [-121.933219, 37.842835],
          [-121.933193, 37.842768],
          [-121.933161, 37.842704],
          [-121.933122, 37.842642999999995],
          [-121.933043, 37.842544],
          [-121.93299400000001, 37.842503],
          [-121.932939, 37.842467],
          [-121.93288, 37.842434],
          [-121.93278699999999, 37.842394],
          [-121.932744, 37.84238],
          [-121.932704, 37.842362],
          [-121.932667, 37.842338999999996],
          [-121.932636, 37.842312],
          [-121.932609, 37.842282],
          [-121.932581, 37.842234],
          [-121.93256099999999, 37.842172999999995],
          [-121.932547, 37.84211],
          [-121.93254, 37.842047],
          [-121.932541, 37.84196],
          [-121.932529, 37.841877],
          [-121.932511, 37.841795],
          [-121.932486, 37.841713],
          [-121.932422, 37.841561999999996],
          [-121.932405, 37.841538],
          [-121.932355, 37.841499],
          [-121.93229, 37.841477999999995],
          [-121.932219, 37.841473],
          [-121.93217, 37.841476],
          [-121.93212199999999, 37.841484],
          [-121.932076, 37.841497],
          [-121.932033, 37.841515],
          [-121.931993, 37.841538],
          [-121.931949, 37.841591],
          [-121.931914, 37.841643],
          [-121.931885, 37.841696999999996],
          [-121.93186299999999, 37.841753],
          [-121.931836, 37.841853],
          [-121.93181799999999, 37.841901],
          [-121.931792, 37.841947],
          [-121.931761, 37.841989999999996],
          [-121.931724, 37.842031999999996],
          [-121.931652, 37.842075],
          [-121.931583, 37.842109],
          [-121.93151, 37.842138999999996],
          [-121.931434, 37.842164],
          [-121.93134, 37.842188],
          [-121.93079399999999, 37.842245],
          [-121.930453, 37.842219],
          [-121.930371, 37.842223],
          [-121.930292, 37.842231],
          [-121.930213, 37.842245],
          [-121.930109, 37.84227],
          [-121.930064, 37.842276],
          [-121.930018, 37.842276],
          [-121.929973, 37.84227],
          [-121.929929, 37.842259999999996],
          [-121.929888, 37.842245],
          [-121.929717, 37.842141999999996],
          [-121.929619, 37.842076],
          [-121.929407, 37.841910999999996],
          [-121.929366, 37.841888],
          [-121.929321, 37.841868999999996],
          [-121.92927399999999, 37.841856],
          [-121.929192, 37.841844],
          [-121.928879, 37.841943],
          [-121.92876799999999, 37.841971],
          [-121.928501, 37.842025],
          [-121.928352, 37.842045999999996],
          [-121.928287, 37.842103],
          [-121.928135, 37.84232],
          [-121.928019, 37.842441],
          [-121.927857, 37.84249],
          [-121.927614, 37.842484999999996],
          [-121.92728, 37.842503],
          [-121.927171, 37.842577999999996],
          [-121.927139, 37.842684],
          [-121.927076, 37.842783],
          [-121.92694900000001, 37.842827],
          [-121.926785, 37.842799],
          [-121.92658, 37.84272],
          [-121.926414, 37.842625999999996],
          [-121.926361, 37.842462],
          [-121.926321, 37.842290999999996],
          [-121.926256, 37.842181],
          [-121.926098, 37.842041],
          [-121.925949, 37.841936],
          [-121.92576199999999, 37.841715],
          [-121.925603, 37.841544],
          [-121.92545799999999, 37.841395999999996],
          [-121.925361, 37.841255],
          [-121.925221, 37.841145999999995],
          [-121.924995, 37.841105999999996],
          [-121.924708, 37.841100999999995],
          [-121.924382, 37.841069999999995],
          [-121.924154, 37.840986],
          [-121.923802, 37.840868],
          [-121.923461, 37.840802],
          [-121.92318, 37.840832999999996],
          [-121.922902, 37.840849],
          [-121.922674, 37.840880999999996],
          [-121.922482, 37.840973],
          [-121.922319, 37.841134],
          [-121.922072, 37.841300000000004],
          [-121.921895, 37.841460999999995],
          [-121.921932, 37.841649],
          [-121.921995, 37.841844],
          [-121.922129, 37.842054],
          [-121.922231, 37.842223],
          [-121.92232899999999, 37.842434],
          [-121.922342, 37.842585],
          [-121.922204, 37.842681999999996],
          [-121.921896, 37.842724],
          [-121.921539, 37.842728],
          [-121.92124, 37.842782],
          [-121.921103, 37.842901],
          [-121.921069, 37.843025],
          [-121.917539, 37.842220999999995],
          [-121.917388, 37.842186999999996],
          [-121.91062099999999, 37.840646],
          [-121.909171, 37.840316],
          [-121.900333, 37.838916],
          [-121.899767, 37.838826],
          [-121.89937499999999, 37.838764],
          [-121.896101, 37.838251],
          [-121.895966, 37.838229999999996],
          [-121.887419, 37.837036999999995],
          [-121.879558, 37.83594],
          [-121.877411, 37.835640999999995],
          [-121.862967, 37.833625],
          [-121.862945, 37.833428],
          [-121.863023, 37.833067],
          [-121.863162, 37.832761],
          [-121.863469, 37.832437999999996],
          [-121.863554, 37.832353],
          [-121.86376, 37.832147],
          [-121.863908, 37.831873],
          [-121.864007, 37.831693],
          [-121.864207, 37.831195],
          [-121.864194, 37.830796],
          [-121.864044, 37.830306],
          [-121.864077, 37.829707],
          [-121.864339, 37.829086],
          [-121.864648, 37.828227999999996],
          [-121.864909, 37.827737],
          [-121.865432, 37.826893],
          [-121.865771, 37.826471],
          [-121.86595, 37.82625],
          [-121.866077, 37.826094999999995],
          [-121.86614, 37.825672999999995],
          [-121.866066, 37.825458],
          [-121.866323, 37.825207999999996],
          [-121.86658800000001, 37.825041],
          [-121.867104, 37.824864],
          [-121.86717999999999, 37.824808999999995],
          [-121.867215, 37.824771],
          [-121.867263, 37.824743],
          [-121.867332, 37.824661],
          [-121.867409, 37.824594999999995],
          [-121.867512, 37.824469],
          [-121.867588, 37.824348],
          [-121.867658, 37.824194],
          [-121.867699, 37.823969],
          [-121.867727, 37.823896999999995],
          [-121.867761, 37.823831],
          [-121.868066, 37.823457],
          [-121.868239, 37.823133],
          [-121.868301, 37.822963],
          [-121.868342, 37.822919],
          [-121.868578, 37.822342],
          [-121.86875, 37.822050999999995],
          [-121.869525, 37.821281],
          [-121.86965000000001, 37.821188],
          [-121.870142, 37.820875],
          [-121.870331, 37.820797],
          [-121.870626, 37.820676999999996],
          [-121.870889, 37.820523],
          [-121.871222, 37.820313999999996],
          [-121.871353, 37.82022],
          [-121.871443, 37.820149],
          [-121.871575, 37.820011],
          [-121.871678, 37.819764],
          [-121.871616, 37.819561],
          [-121.87158099999999, 37.819390999999996],
          [-121.871574, 37.819226],
          [-121.871588, 37.819027999999996],
          [-121.871629, 37.818664999999996],
          [-121.87170499999999, 37.818506],
          [-121.871726, 37.818428999999995],
          [-121.87179499999999, 37.81799],
          [-121.871855, 37.817187],
          [-121.87186299999999, 37.817164999999996],
          [-121.871891, 37.81716],
          [-121.872002, 37.816857999999996],
          [-121.872057, 37.81677],
          [-121.87213299999999, 37.816682],
          [-121.872202, 37.816632],
          [-121.87259, 37.816412],
          [-121.872749, 37.816209],
          [-121.872894, 37.815901],
          [-121.873012, 37.815467],
          [-121.873039, 37.815462],
          [-121.873053, 37.815445],
          [-121.873454, 37.814786],
          [-121.873579, 37.814555],
          [-121.873738, 37.814357],
          [-121.873897, 37.814209],
          [-121.874105, 37.813994],
          [-121.87460899999999, 37.813345999999996],
          [-121.874631, 37.813339],
          [-121.874679, 37.813296],
          [-121.874803, 37.813137],
          [-121.874852, 37.813044],
          [-121.874962, 37.812653999999995],
          [-121.87499, 37.812593],
          [-121.875079, 37.812458],
          [-121.875134, 37.812326],
          [-121.875139, 37.812259999999995],
          [-121.875175, 37.812075],
          [-121.875197, 37.811962],
          [-121.875259, 37.811791],
          [-121.875266, 37.811747],
          [-121.875275, 37.811734],
          [-121.875411, 37.811533],
          [-121.875467, 37.811423],
          [-121.875584, 37.811099],
          [-121.875633, 37.811037999999996],
          [-121.875757, 37.810758],
          [-121.875847, 37.810686],
          [-121.876067, 37.810465],
          [-121.876199, 37.810497999999995],
          [-121.876248, 37.810469999999995],
          [-121.876331, 37.810344],
          [-121.87639300000001, 37.810212],
          [-121.876449, 37.810123999999995],
          [-121.87665, 37.809872],
          [-121.876677, 37.809810999999996],
          [-121.876691, 37.80974],
          [-121.876698, 37.809636],
          [-121.876685, 37.809526],
          [-121.876643, 37.809416],
          [-121.876567, 37.809311],
          [-121.87638, 37.809092],
          [-121.876339, 37.809008999999996],
          [-121.876325, 37.808893999999995],
          [-121.876339, 37.808822],
          [-121.876492, 37.808515],
          [-121.87665799999999, 37.808399],
          [-121.87679, 37.808195999999995],
          [-121.876942, 37.808026],
          [-121.87715, 37.807686],
          [-121.877177, 37.807663999999995],
          [-121.87724, 37.807614],
          [-121.877448, 37.807499],
          [-121.877544, 37.807471],
          [-121.877814, 37.799451],
          [-121.877819, 37.799321],
          [-121.877562, 37.799293999999996],
          [-121.877447, 37.799275],
          [-121.877005, 37.799206],
          [-121.875749, 37.798995999999995],
          [-121.874251, 37.798764],
          [-121.8735, 37.798655],
          [-121.873199, 37.798617],
          [-121.87298799999999, 37.798584],
          [-121.872778, 37.798545],
          [-121.87257, 37.798501],
          [-121.872364, 37.798452],
          [-121.87215499999999, 37.798381],
          [-121.871431, 37.798119],
          [-121.871074, 37.797982],
          [-121.870719, 37.79784],
          [-121.869697, 37.79747],
          [-121.868052, 37.796858],
          [-121.86800000000001, 37.796839],
          [-121.86765199999999, 37.796710999999995],
          [-121.867454, 37.796633],
          [-121.867259, 37.796551],
          [-121.86706699999999, 37.796462999999996],
          [-121.866879, 37.796371],
          [-121.86669499999999, 37.796274],
          [-121.86651499999999, 37.796172999999996],
          [-121.866338, 37.796067],
          [-121.86616599999999, 37.795957],
          [-121.865893, 37.795769],
          [-121.865762, 37.79567],
          [-121.86563699999999, 37.795567999999996],
          [-121.865516, 37.795462],
          [-121.86540099999999, 37.795353],
          [-121.865291, 37.79524],
          [-121.86518699999999, 37.795124],
          [-121.865054, 37.794959999999996],
          [-121.864908, 37.794757],
          [-121.864867, 37.794682],
          [-121.86472, 37.794408],
          [-121.864605, 37.794177],
          [-121.864426, 37.793780999999996],
          [-121.864394, 37.793709],
          [-121.864299, 37.793473],
          [-121.86421, 37.793234999999996],
          [-121.864127, 37.792995999999995],
          [-121.864041, 37.792668],
          [-121.864022, 37.792592],
          [-121.863968, 37.79235],
          [-121.86392000000001, 37.792106],
          [-121.863842, 37.791553],
          [-121.863812, 37.791286],
          [-121.86378500000001, 37.790962],
          [-121.863787, 37.790752999999995],
          [-121.86379600000001, 37.790648999999995],
          [-121.863811, 37.790545],
          [-121.86389299999999, 37.790161],
          [-121.86407299999999, 37.789335],
          [-121.864111, 37.789142999999996],
          [-121.864141, 37.788962],
          [-121.864164, 37.788781],
          [-121.864168, 37.788727],
          [-121.86417900000001, 37.7886],
          [-121.864188, 37.788418],
          [-121.864161, 37.788103],
          [-121.864135, 37.78792],
          [-121.864103, 37.787738],
          [-121.86403, 37.78749],
          [-121.863974, 37.787331],
          [-121.863911, 37.787172999999996],
          [-121.863832, 37.78693],
          [-121.86363, 37.786349],
          [-121.863158, 37.784499],
          [-121.86285699999999, 37.782539],
          [-121.86278, 37.781825999999995],
          [-121.862747, 37.781428999999996],
          [-121.862719, 37.781000999999996],
          [-121.862708, 37.780761],
          [-121.862562, 37.779163],
          [-121.86222599999999, 37.778265999999995],
          [-121.861886, 37.777468],
          [-121.861734, 37.776706],
          [-121.861702, 37.775838],
          [-121.861739, 37.775549999999996],
          [-121.8618, 37.775295],
          [-121.861869, 37.775041],
          [-121.861988, 37.77465],
          [-121.86210299999999, 37.774316999999996],
          [-121.862234, 37.773969],
          [-121.862414, 37.773514999999996],
          [-121.862583, 37.773092999999996],
          [-121.862987, 37.772137],
          [-121.8631, 37.771882],
          [-121.863315, 37.771398999999995],
          [-121.863671, 37.770638999999996],
          [-121.864177, 37.769600000000004],
          [-121.864598, 37.768798],
          [-121.86556, 37.766920999999996],
          [-121.865643, 37.76676],
          [-121.866172, 37.765738999999996],
          [-121.866266, 37.765555],
          [-121.86633, 37.765415],
          [-121.866385, 37.765277],
          [-121.866438, 37.765128],
          [-121.86652, 37.764844],
          [-121.866555, 37.764706],
          [-121.86668, 37.764212],
          [-121.866825, 37.763652],
          [-121.866905, 37.763393],
          [-121.867028, 37.763048],
          [-121.86712299999999, 37.762723],
          [-121.867175, 37.762496],
          [-121.867276, 37.762026],
          [-121.867578, 37.760594999999995],
          [-121.867696, 37.760090999999996],
          [-121.86777599999999, 37.759578999999995],
          [-121.867812, 37.759357],
          [-121.86787799999999, 37.758700999999995],
          [-121.867949, 37.757979],
          [-121.868081, 37.756637999999995],
          [-121.868111, 37.756352],
          [-121.868115, 37.756265],
          [-121.868127, 37.756178999999996],
          [-121.868145, 37.756094],
          [-121.86816999999999, 37.756009999999996],
          [-121.868202, 37.755927],
          [-121.86824, 37.755846],
          [-121.868285, 37.755767],
          [-121.868336, 37.755691],
          [-121.868426, 37.755578],
          [-121.86858, 37.755451],
          [-121.868664, 37.755393999999995],
          [-121.868753, 37.755341],
          [-121.868845, 37.755292],
          [-121.868941, 37.755248],
          [-121.869215, 37.755139],
          [-121.869345, 37.755081],
          [-121.869472, 37.755016999999995],
          [-121.869664, 37.754908],
          [-121.869742, 37.754846],
          [-121.869815, 37.754781],
          [-121.869883, 37.754712999999995],
          [-121.869945, 37.754641],
          [-121.870033, 37.754518999999995],
          [-121.870434, 37.753837],
          [-121.870506, 37.753715],
          [-121.870867, 37.753156],
          [-121.871103, 37.752792],
          [-121.871161, 37.752683999999995],
          [-121.871212, 37.752573999999996],
          [-121.871263, 37.752441999999995],
          [-121.871294, 37.752348999999995],
          [-121.871325, 37.752234],
          [-121.87135599999999, 37.752075],
          [-121.871425, 37.751439],
          [-121.871464, 37.751013],
          [-121.871499, 37.750389999999996],
          [-121.871569, 37.749455999999995],
          [-121.872024, 37.749469999999995],
          [-121.87310099999999, 37.749517999999995],
          [-121.87411900000001, 37.749589],
          [-121.874921, 37.749589],
          [-121.875299, 37.749527],
          [-121.875616, 37.7494],
          [-121.875824, 37.749352],
          [-121.876482, 37.748808],
          [-121.876991, 37.747955999999995],
          [-121.87750000000001, 37.747198999999995],
          [-121.878248, 37.746395],
          [-121.879025, 37.745968999999995],
          [-121.87983299999999, 37.745495999999996],
          [-121.880491, 37.744999],
          [-121.88094, 37.744479],
          [-121.881599, 37.743651],
          [-121.882077, 37.743342999999996],
          [-121.882975, 37.743012],
          [-121.883195, 37.742993],
          [-121.882595, 37.745425999999995],
          [-121.882746, 37.746784999999996],
          [-121.882947, 37.74859],
          [-121.882921, 37.749773],
          [-121.882852, 37.752846999999996],
          [-121.881602, 37.754006],
          [-121.879615, 37.757551],
          [-121.880809, 37.758393],
          [-121.88114999999999, 37.758632999999996],
          [-121.881186, 37.760459999999995],
          [-121.881192, 37.760709999999996],
          [-121.881313, 37.760883],
          [-121.882718, 37.761637],
          [-121.883912, 37.762164],
          [-121.884764, 37.762567],
          [-121.885302, 37.763016],
          [-121.885571, 37.763374],
          [-121.885796, 37.763822999999995],
          [-121.885885, 37.764495],
          [-121.885796, 37.76602],
          [-121.885661, 37.767993],
          [-121.885302, 37.76889],
          [-121.88498799999999, 37.769428],
          [-121.88454, 37.769742],
          [-121.884316, 37.769877],
          [-121.884316, 37.77185],
          [-121.885482, 37.773016],
          [-121.88498799999999, 37.774944],
          [-121.884271, 37.775527],
          [-121.884092, 37.778711],
          [-121.885078, 37.779249],
          [-121.883734, 37.781317],
          [-121.883329, 37.781939],
          [-121.891595, 37.781794999999995],
          [-121.891602, 37.788619],
          [-121.892004, 37.788647],
          [-121.892374, 37.788646],
          [-121.892461, 37.788463],
          [-121.89273299999999, 37.788117],
          [-121.893001, 37.787845],
          [-121.893193, 37.787617],
          [-121.893396, 37.78734],
          [-121.893569, 37.787102999999995],
          [-121.893708, 37.786974],
          [-121.893881, 37.786895],
          [-121.894074, 37.786777],
          [-121.89419699999999, 37.786643],
          [-121.894291, 37.78644],
          [-121.894301, 37.786178],
          [-121.894291, 37.78606],
          [-121.894385, 37.785852],
          [-121.894527, 37.785576999999996],
          [-121.894572, 37.785278999999996],
          [-121.894599, 37.785191],
          [-121.89464100000001, 37.785109],
          [-121.89479299999999, 37.784911],
          [-121.894842, 37.784718999999996],
          [-121.89488399999999, 37.784603],
          [-121.89491699999999, 37.784575],
          [-121.895036, 37.784476999999995],
          [-121.89509100000001, 37.784351],
          [-121.89515399999999, 37.784257],
          [-121.89542399999999, 37.783994],
          [-121.895452, 37.783944],
          [-121.895493, 37.783812],
          [-121.895562, 37.783724],
          [-121.895646, 37.783642],
          [-121.895689, 37.783431],
          [-121.89569900000001, 37.783401999999995],
          [-121.895741, 37.783372],
          [-121.89595, 37.783218999999995],
          [-121.896241, 37.783082],
          [-121.89638, 37.78306],
          [-121.89658, 37.783071],
          [-121.896677, 37.78306],
          [-121.896753, 37.783015999999996],
          [-121.896885, 37.782872999999995],
          [-121.897072, 37.782769],
          [-121.89727500000001, 37.782574],
          [-121.897314, 37.782537999999995],
          [-121.897688, 37.782291],
          [-121.897751, 37.782236],
          [-121.897806, 37.782202999999996],
          [-121.897875, 37.782016999999996],
          [-121.89788899999999, 37.78194],
          [-121.897931, 37.781918],
          [-121.89797, 37.781797],
          [-121.898035, 37.781715],
          [-121.898132, 37.781631999999995],
          [-121.898409, 37.781484],
          [-121.89845700000001, 37.781439999999996],
          [-121.898485, 37.781405],
          [-121.898872, 37.781408],
          [-121.898661, 37.781779],
          [-121.89884, 37.781785],
          [-121.899378, 37.781803],
          [-121.899558, 37.78181],
          [-121.900662, 37.781878],
          [-121.90066, 37.782692],
          [-121.90065799999999, 37.783082],
          [-121.900657, 37.783189],
          [-121.900651, 37.783854],
          [-121.900648, 37.784113],
          [-121.900645, 37.785202],
          [-121.900643, 37.785773],
          [-121.900639, 37.787223],
          [-121.90063599999999, 37.788092],
          [-121.900635, 37.788665],
          [-121.900819, 37.788658],
          [-121.900826, 37.788838],
          [-121.902491, 37.788866],
          [-121.904469, 37.788878],
          [-121.905446, 37.788883999999996],
          [-121.907371, 37.788877],
          [-121.907619, 37.788877],
          [-121.907921, 37.788875999999995],
          [-121.907915, 37.78881],
          [-121.908455, 37.788823],
          [-121.908528, 37.788821999999996],
          [-121.9093, 37.788813],
          [-121.909942, 37.788826],
          [-121.910566, 37.788834],
          [-121.910933, 37.788838999999996],
          [-121.911245, 37.788871],
          [-121.911541, 37.788874],
          [-121.912429, 37.788883999999996],
          [-121.912725, 37.788869],
          [-121.91283, 37.788973],
          [-121.912908, 37.789103],
          [-121.912962, 37.789269],
          [-121.912978, 37.789435],
          [-121.913039, 37.789327],
          [-121.913173, 37.78924],
          [-121.913347, 37.789201999999996],
          [-121.913596, 37.789207999999995],
          [-121.913834, 37.789223],
          [-121.91408799999999, 37.789229],
          [-121.914439, 37.789243],
          [-121.915038, 37.78924],
          [-121.915145, 37.789221],
          [-121.915291, 37.789161],
          [-121.915509, 37.789059],
          [-121.91581, 37.788906],
          [-121.917093, 37.788928],
          [-121.91896, 37.788959999999996],
          [-121.921408, 37.789001999999996],
          [-121.921858, 37.789010999999995],
          [-121.921941, 37.789012],
          [-121.922191, 37.789016],
          [-121.922451, 37.789142],
          [-121.922568, 37.788908],
          [-121.922754, 37.788683],
          [-121.923434, 37.792293],
          [-121.923706, 37.792317],
          [-121.924196, 37.792361],
          [-121.925505, 37.792297999999995],
          [-121.926781, 37.792277999999996],
          [-121.930249, 37.792302],
          [-121.93278699999999, 37.792321],
          [-121.932813, 37.790742],
          [-121.932828, 37.789836],
          [-121.932844, 37.788900000000005],
          [-121.933097, 37.788896],
          [-121.933096, 37.788621],
          [-121.936936, 37.788520999999996],
          [-121.938282, 37.788486999999996],
          [-121.940023, 37.788636],
          [-121.940395, 37.788686],
          [-121.941121, 37.788786],
          [-121.941249, 37.788803],
          [-121.941294, 37.788815],
          [-121.941481, 37.790827],
          [-121.94155, 37.791557999999995],
          [-121.941626, 37.791606],
          [-121.941963, 37.791815],
          [-121.943205, 37.792587999999995],
          [-121.943619, 37.792846],
          [-121.943808, 37.792961999999996],
          [-121.944378, 37.793313],
          [-121.944568, 37.79343],
          [-121.945399, 37.793901],
          [-121.94646, 37.794502],
          [-121.94748899999999, 37.795085],
          [-121.947893, 37.795314],
          [-121.948725, 37.795786],
          [-121.950504, 37.796652],
          [-121.950935, 37.796862],
          [-121.950919, 37.798784999999995],
          [-121.952209, 37.800157999999996],
          [-121.95325199999999, 37.801266999999996],
          [-121.953474, 37.80131],
          [-121.953479, 37.801325],
          [-121.953459, 37.801359999999995],
          [-121.953418, 37.801441],
          [-121.95339, 37.801502],
          [-121.953355, 37.801578],
          [-121.953316, 37.801655],
          [-121.953251, 37.801773],
          [-121.953226, 37.801815],
          [-121.953174, 37.801901],
          [-121.953147, 37.801946],
          [-121.95312, 37.801989999999996],
          [-121.953094, 37.802035],
          [-121.95307, 37.80208],
          [-121.953047, 37.802126],
          [-121.95302699999999, 37.802171],
          [-121.953012, 37.802215],
          [-121.952989, 37.802259],
          [-121.95294799999999, 37.802343],
          [-121.95291499999999, 37.80243],
          [-121.95288099999999, 37.802509],
          [-121.952862, 37.802593],
          [-121.952854, 37.802639],
          [-121.952849, 37.802686],
          [-121.952843, 37.802782],
          [-121.952838, 37.802831999999995],
          [-121.952841, 37.802887],
          [-121.952843, 37.802942],
          [-121.952846, 37.803000000000004],
          [-121.95285, 37.803058],
          [-121.952854, 37.803115999999996],
          [-121.95285799999999, 37.803173],
          [-121.95286300000001, 37.803228],
          [-121.952868, 37.803281999999996],
          [-121.952874, 37.803331],
          [-121.95288, 37.803424],
          [-121.952871, 37.803501],
          [-121.952842, 37.803577],
          [-121.952803, 37.80365],
          [-121.952762, 37.803723],
          [-121.95270000000001, 37.803836],
          [-121.952657, 37.803911],
          [-121.952615, 37.803985],
          [-121.952573, 37.804055],
          [-121.952533, 37.804122],
          [-121.952492, 37.804189],
          [-121.95245, 37.804257],
          [-121.952407, 37.804324],
          [-121.952364, 37.80439],
          [-121.952323, 37.804456],
          [-121.952292, 37.804516],
          [-121.952264, 37.804581],
          [-121.952242, 37.804638],
          [-121.952222, 37.804696],
          [-121.9522, 37.804770999999995],
          [-121.952182, 37.804834],
          [-121.952168, 37.804879],
          [-121.952152, 37.804945],
          [-121.952246, 37.805186],
          [-121.952251, 37.805195999999995],
          [-121.952275, 37.805234999999996],
          [-121.952297, 37.805285999999995],
          [-121.952313, 37.805339],
          [-121.952322, 37.805392],
          [-121.952324, 37.805445999999996],
          [-121.952319, 37.8055],
          [-121.95230699999999, 37.805552999999996],
          [-121.95228900000001, 37.805605],
          [-121.952264, 37.805656],
          [-121.952206, 37.805735999999996],
          [-121.952084, 37.805859],
          [-121.952045, 37.805898],
          [-121.951886, 37.806059],
          [-121.951858, 37.806093],
          [-121.95181099999999, 37.806033],
          [-121.951767, 37.805963],
          [-121.951695, 37.805926],
          [-121.951268, 37.805710999999995],
          [-121.95122599999999, 37.805684],
          [-121.951232, 37.805614],
          [-121.95121499999999, 37.80556],
          [-121.951162, 37.805526],
          [-121.95104599999999, 37.805513],
          [-121.95092700000001, 37.805484],
          [-121.950871, 37.805459],
          [-121.950875, 37.805599],
          [-121.950699, 37.805838],
          [-121.950836, 37.805977],
          [-121.949581, 37.807469],
          [-121.949688, 37.807525],
          [-121.949723, 37.807533],
          [-121.949713, 37.807545],
          [-121.949986, 37.807699],
          [-121.95053300000001, 37.808023999999996],
          [-121.950699, 37.808124],
          [-121.951508, 37.808586999999996],
          [-121.951696, 37.808701],
          [-121.95177, 37.808746],
          [-121.95165399999999, 37.808868],
          [-121.951297, 37.809250999999996],
          [-121.951129, 37.809447999999996],
          [-121.951104, 37.809467999999995],
          [-121.951052, 37.809495999999996],
          [-121.951027, 37.809501999999995],
          [-121.950963, 37.809498],
          [-121.950914, 37.809487],
          [-121.95088, 37.80947],
          [-121.950815, 37.809452],
          [-121.950721, 37.809477],
          [-121.950667, 37.809501999999995],
          [-121.950616, 37.809531],
          [-121.950537, 37.809591999999995],
          [-121.951255, 37.812277],
          [-121.951204, 37.816452],
          [-121.95113599999999, 37.818914],
          [-121.951229, 37.818909999999995],
          [-121.951326, 37.818914],
          [-121.951423, 37.81893],
          [-121.951521, 37.818957999999995],
          [-121.95167000000001, 37.819009],
          [-121.951819, 37.81904],
          [-121.952027, 37.819054],
          [-121.95219, 37.819082],
          [-121.952263, 37.819111],
          [-121.95233, 37.819145999999996],
          [-121.952372, 37.819192],
          [-121.952415, 37.819249],
          [-121.952437, 37.819303999999995],
          [-121.952459, 37.819382999999995],
          [-121.95245299999999, 37.819441],
          [-121.952382, 37.819516],
          [-121.95232, 37.819545999999995],
          [-121.952231, 37.819575],
          [-121.951918, 37.819649999999996],
          [-121.951808, 37.819697999999995],
          [-121.951702, 37.819763],
          [-121.951655, 37.819821],
          [-121.951632, 37.819880999999995],
          [-121.95160899999999, 37.819964],
          [-121.951643, 37.820108999999995],
          [-121.95180500000001, 37.820439],
          [-121.95190099999999, 37.820558],
          [-121.951939, 37.820582],
          [-121.952079, 37.82063],
          [-121.95218299999999, 37.820653],
          [-121.952312, 37.820693],
          [-121.952471, 37.820735],
          [-121.95261099999999, 37.820788],
          [-121.952747, 37.820851999999995],
          [-121.95289700000001, 37.820954],
          [-121.953024, 37.821068],
          [-121.953144, 37.821193],
          [-121.953226, 37.821326],
          [-121.953312, 37.821448],
          [-121.953386, 37.821522],
          [-121.953467, 37.821591999999995],
          [-121.953585, 37.821656],
          [-121.953718, 37.821701],
          [-121.953832, 37.821726999999996],
          [-121.95394999999999, 37.821745],
          [-121.954137, 37.821748],
          [-121.954258, 37.821740999999996],
          [-121.954351, 37.821746],
          [-121.954427, 37.821745],
          [-121.954666, 37.821771999999996],
          [-121.95476, 37.821798],
          [-121.954955, 37.821883],
          [-121.95504, 37.821943],
          [-121.95517, 37.822089],
          [-121.95521, 37.822171],
          [-121.955243, 37.822255999999996],
          [-121.95527, 37.82239],
          [-121.95528, 37.822562],
          [-121.955277, 37.822776],
          [-121.955272, 37.822852999999995],
          [-121.955249, 37.822944],
          [-121.955213, 37.82304],
          [-121.955176, 37.82309],
          [-121.955129, 37.823143],
          [-121.955078, 37.823187],
          [-121.95490000000001, 37.82328],
          [-121.9548, 37.823325],
          [-121.954636, 37.823423],
          [-121.95460299999999, 37.823467],
          [-121.95459, 37.823502999999995],
          [-121.95459, 37.823544],
          [-121.954602, 37.823594],
          [-121.954644, 37.823656],
          [-121.954719, 37.82374],
          [-121.954773, 37.823841],
          [-121.954915, 37.824053],
          [-121.954943, 37.824089],
          [-121.954982, 37.824126],
          [-121.955049, 37.824172],
          [-121.955185, 37.824222999999996],
          [-121.955285, 37.824245999999995],
          [-121.955355, 37.82427],
          [-121.95542499999999, 37.824313],
          [-121.955489, 37.824389],
          [-121.955557, 37.824546999999995],
          [-121.955813, 37.825081999999995],
          [-121.955822, 37.825153],
          [-121.955888, 37.825365999999995],
          [-121.955939, 37.825491],
          [-121.956035, 37.825652],
          [-121.956162, 37.825779],
          [-121.956436, 37.825987],
          [-121.95659, 37.826059],
          [-121.956743, 37.826115],
          [-121.956993, 37.826183],
          [-121.957108, 37.826223],
          [-121.95719199999999, 37.82626],
          [-121.957311, 37.826327],
          [-121.957405, 37.826403],
          [-121.957484, 37.826525],
          [-121.957514, 37.826659],
          [-121.957534, 37.826785],
          [-121.957571, 37.826892],
          [-121.957604, 37.827014999999996],
          [-121.957692, 37.827262999999995],
          [-121.95775, 37.827411],
          [-121.957819, 37.827549999999995],
          [-121.957904, 37.827675],
          [-121.958043, 37.827857],
          [-121.958085, 37.827919],
          [-121.958185, 37.828044],
          [-121.958298, 37.828174],
          [-121.95854800000001, 37.828415],
          [-121.95873399999999, 37.828579999999995],
          [-121.958886, 37.828727],
          [-121.958991, 37.828813],
          [-121.95909, 37.828924],
          [-121.959137, 37.829017],
          [-121.959163, 37.829118],
          [-121.959176, 37.829239],
          [-121.959191, 37.829454999999996],
          [-121.959199, 37.829888],
          [-121.959183, 37.82996],
          [-121.95916700000001, 37.830072],
          [-121.959107, 37.830434],
          [-121.959118, 37.830531],
          [-121.959164, 37.830591],
          [-121.959247, 37.830653999999996],
          [-121.959396, 37.830712999999996],
          [-121.95960099999999, 37.830782],
          [-121.959773, 37.830852],
          [-121.959848, 37.830951],
          [-121.959934, 37.830993],
          [-121.960039, 37.831052],
          [-121.960109, 37.831154999999995],
          [-121.960205, 37.83125],
          [-121.96038300000001, 37.83137],
          [-121.960452, 37.83139],
          [-121.96061, 37.831438],
          [-121.960859, 37.831485],
          [-121.961135, 37.831517999999996],
          [-121.961406, 37.831561],
          [-121.96165500000001, 37.831593999999996],
          [-121.961895, 37.831637],
          [-121.96213900000001, 37.831694999999996],
          [-121.96232499999999, 37.831714],
          [-121.962523, 37.831722],
          [-121.962685, 37.83171],
          [-121.963189, 37.83166],
          [-121.963369, 37.831655],
          [-121.963546, 37.831669999999995],
          [-121.96430699999999, 37.831849999999996],
          [-121.964578, 37.831893],
          [-121.964849, 37.831919],
          [-121.96501599999999, 37.831924],
          [-121.965223, 37.831907],
          [-121.965403, 37.831897999999995],
          [-121.965684, 37.831925999999996],
          [-121.965936, 37.83192],
          [-121.966223, 37.831866],
          [-121.966731, 37.831781],
          [-121.966965, 37.831745999999995],
          [-121.967417, 37.83159],
          [-121.967915, 37.831455],
          [-121.968234, 37.831401],
          [-121.968586, 37.831410999999996],
          [-121.968861, 37.831379],
          [-121.969138, 37.831243],
          [-121.969398, 37.830965],
          [-121.969543, 37.830787],
          [-121.969671, 37.830646],
          [-121.96990000000001, 37.830560999999996],
          [-121.970089, 37.830577],
          [-121.970235, 37.830625],
          [-121.970373, 37.830774],
          [-121.97053, 37.830979],
          [-121.970715, 37.831195],
          [-121.9709, 37.831438999999996],
          [-121.971108, 37.83168],
          [-121.971641, 37.831748],
          [-121.971778, 37.831807],
          [-121.971852, 37.831900000000005],
          [-121.971907, 37.831984999999996],
          [-121.97209699999999, 37.832229],
          [-121.972222, 37.832403],
          [-121.972289, 37.832595],
          [-121.972276, 37.832831],
          [-121.972275, 37.833003],
          [-121.97232199999999, 37.833117],
          [-121.972469, 37.833247],
          [-121.972472, 37.833422],
          [-121.97244, 37.833622999999996],
          [-121.97234399999999, 37.833763999999995],
          [-121.971886, 37.834102],
          [-121.971771, 37.834182],
          [-121.971593, 37.834331],
          [-121.97150500000001, 37.834418],
          [-121.971358, 37.834545],
          [-121.971247, 37.834604],
          [-121.971127, 37.834683999999996],
          [-121.971034, 37.834767],
          [-121.971, 37.834889],
          [-121.971047, 37.834996],
          [-121.971144, 37.835131],
          [-121.97142, 37.835367],
          [-121.971525, 37.835466],
          [-121.971795, 37.835434],
          [-121.972096, 37.835376],
          [-121.972465, 37.835311],
          [-121.972748, 37.835285999999996],
          [-121.972906, 37.835288],
          [-121.972956, 37.835294],
          [-121.972989, 37.835372],
          [-121.973019, 37.835519],
        ],
      ],
    },
  },
  {
    id: "878",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94556",
      GEOID10: "94556",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 31639821,
      AWATER10: 0,
      INTPTLAT10: "+37.8406559",
      INTPTLON10: "-122.1149135",
      Name: "94556",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.161048, 37.840207],
          [-122.160191, 37.840188999999995],
          [-122.159906, 37.840182999999996],
          [-122.159189, 37.84018],
          [-122.158715, 37.840178],
          [-122.157373, 37.840171999999995],
          [-122.155145, 37.840163],
          [-122.153955, 37.840159],
          [-122.153104, 37.839389],
          [-122.153028, 37.839321],
          [-122.152925, 37.839253],
          [-122.15281, 37.839197999999996],
          [-122.152686, 37.839158],
          [-122.152631, 37.839147],
          [-122.15232, 37.839088],
          [-122.15217200000001, 37.839054],
          [-122.15203, 37.839006999999995],
          [-122.15098, 37.838536],
          [-122.150913, 37.838512],
          [-122.150806, 37.838485],
          [-122.150696, 37.83847],
          [-122.150349, 37.838454999999996],
          [-122.150204, 37.838432],
          [-122.150063, 37.838392999999996],
          [-122.149931, 37.838339],
          [-122.14980800000001, 37.838271999999996],
          [-122.149699, 37.838192],
          [-122.14955, 37.838052],
          [-122.149456, 37.83799],
          [-122.149385, 37.837958],
          [-122.149271, 37.837924],
          [-122.149191, 37.837911999999996],
          [-122.14851999999999, 37.837888],
          [-122.148312, 37.837851],
          [-122.147994, 37.837801],
          [-122.14785599999999, 37.837784],
          [-122.147674, 37.837762],
          [-122.147558, 37.83774],
          [-122.14739, 37.837692],
          [-122.147231, 37.837627],
          [-122.14702299999999, 37.837522],
          [-122.146962, 37.837499],
          [-122.146123, 37.838767],
          [-122.14604, 37.838867],
          [-122.145877, 37.839064],
          [-122.145139, 37.839959],
          [-122.144894, 37.840257],
          [-122.14487199999999, 37.840284],
          [-122.144654, 37.840537999999995],
          [-122.143936, 37.84138],
          [-122.143697, 37.841660999999995],
          [-122.143524, 37.841864],
          [-122.143086, 37.841626999999995],
          [-122.14299, 37.841575],
          [-122.142702, 37.841418999999995],
          [-122.142606, 37.841367999999996],
          [-122.142536, 37.841456],
          [-122.142235, 37.841837999999996],
          [-122.141876, 37.842295],
          [-122.141735, 37.842475],
          [-122.141693, 37.842529],
          [-122.141717, 37.84271],
          [-122.141728, 37.842793],
          [-122.141735, 37.842839],
          [-122.141437, 37.842996],
          [-122.14153, 37.843047999999996],
          [-122.141671, 37.843128],
          [-122.14188899999999, 37.843252],
          [-122.142112, 37.843520999999996],
          [-122.141218, 37.843422],
          [-122.141047, 37.843402999999995],
          [-122.140918, 37.843505],
          [-122.140875, 37.843562],
          [-122.140743, 37.843736],
          [-122.140726, 37.843759],
          [-122.140581, 37.844046999999996],
          [-122.140576, 37.844065],
          [-122.14057199999999, 37.84408],
          [-122.140556, 37.844133],
          [-122.140523, 37.844245],
          [-122.140535, 37.844297],
          [-122.14056099999999, 37.844415999999995],
          [-122.140599, 37.84459],
          [-122.140717, 37.844767],
          [-122.140981, 37.844975],
          [-122.141208, 37.845152999999996],
          [-122.14143, 37.845329],
          [-122.141606, 37.845467],
          [-122.141682, 37.845451],
          [-122.141781, 37.845428999999996],
          [-122.141848, 37.845645999999995],
          [-122.141885, 37.845765],
          [-122.141937, 37.845818],
          [-122.141995, 37.845878],
          [-122.142015, 37.846025999999995],
          [-122.142046, 37.84625],
          [-122.14209, 37.846319],
          [-122.14237299999999, 37.846568],
          [-122.14238399999999, 37.846636],
          [-122.142406, 37.846764],
          [-122.14248599999999, 37.847263],
          [-122.142496, 37.847268],
          [-122.142392, 37.847339999999996],
          [-122.142363, 37.847367999999996],
          [-122.142296, 37.847429],
          [-122.142174, 37.847525],
          [-122.142118, 37.847598999999995],
          [-122.142073, 37.847684],
          [-122.14203, 37.847763],
          [-122.141819, 37.847564],
          [-122.141627, 37.847381999999996],
          [-122.14054, 37.847859],
          [-122.140546, 37.847887],
          [-122.140602, 37.848175],
          [-122.140611, 37.84822],
          [-122.140676, 37.848554],
          [-122.140754, 37.848954],
          [-122.140309, 37.848982],
          [-122.139676, 37.849022],
          [-122.139444, 37.849036],
          [-122.13949, 37.849325],
          [-122.139569, 37.849810999999995],
          [-122.139572, 37.849831],
          [-122.139749, 37.849917999999995],
          [-122.139776, 37.849925],
          [-122.139802, 37.85],
          [-122.139839, 37.850505999999996],
          [-122.139865, 37.850908],
          [-122.139887, 37.851241],
          [-122.139979, 37.851465],
          [-122.140084, 37.851717],
          [-122.14032399999999, 37.851951],
          [-122.140409, 37.851744],
          [-122.140702, 37.851016],
          [-122.14113, 37.851214],
          [-122.14120299999999, 37.85125],
          [-122.141524, 37.851397],
          [-122.14178, 37.851279999999996],
          [-122.14192, 37.851791999999996],
          [-122.141948, 37.851887],
          [-122.142062, 37.852312],
          [-122.142176, 37.85238],
          [-122.14259799999999, 37.852641999999996],
          [-122.142597, 37.852651],
          [-122.142651, 37.856257],
          [-122.142679, 37.858163],
          [-122.142687, 37.858239],
          [-122.142687, 37.858304],
          [-122.142702, 37.858577],
          [-122.142708, 37.858869],
          [-122.142715, 37.859496],
          [-122.142963, 37.859556],
          [-122.143144, 37.859608],
          [-122.143278, 37.859646999999995],
          [-122.14354, 37.859787],
          [-122.14361099999999, 37.859843],
          [-122.143815, 37.860002],
          [-122.144012, 37.860171],
          [-122.14436599999999, 37.86041],
          [-122.14454599999999, 37.860673999999996],
          [-122.144594, 37.860989],
          [-122.144474, 37.861214],
          [-122.14430999999999, 37.861433],
          [-122.14425299999999, 37.861461],
          [-122.144075, 37.861534999999996],
          [-122.144056, 37.861540999999995],
          [-122.143889, 37.861596],
          [-122.143697, 37.861643],
          [-122.14345900000001, 37.861685],
          [-122.143303, 37.861723999999995],
          [-122.143253, 37.861740999999995],
          [-122.143203, 37.86176],
          [-122.143062, 37.861824999999996],
          [-122.143005, 37.86186],
          [-122.142932, 37.861905],
          [-122.142883, 37.861943],
          [-122.142785, 37.86202],
          [-122.142719, 37.86208],
          [-122.142703, 37.862096],
          [-122.142636, 37.862182],
          [-122.142588, 37.862275],
          [-122.142558, 37.862362999999995],
          [-122.142545, 37.862456],
          [-122.142551, 37.86255],
          [-122.14257599999999, 37.862643],
          [-122.14272, 37.862941],
          [-122.142781, 37.863068999999996],
          [-122.142848, 37.863206],
          [-122.14287, 37.863279],
          [-122.142876, 37.863298],
          [-122.142876, 37.863335],
          [-122.142866, 37.863371],
          [-122.14284599999999, 37.863405],
          [-122.143258, 37.863406999999995],
          [-122.143297, 37.863336],
          [-122.143681, 37.863385],
          [-122.143777, 37.862842],
          [-122.143795, 37.862746],
          [-122.144363, 37.862697],
          [-122.144347, 37.863073],
          [-122.144709, 37.863592],
          [-122.14465, 37.863772999999995],
          [-122.144371, 37.864618],
          [-122.144304, 37.86482],
          [-122.144304, 37.865041],
          [-122.144301, 37.865328999999996],
          [-122.144288, 37.865643999999996],
          [-122.144106, 37.865857999999996],
          [-122.143811, 37.866208],
          [-122.143605, 37.866082],
          [-122.14345399999999, 37.866014],
          [-122.14323, 37.86591],
          [-122.143146, 37.866026],
          [-122.143109, 37.866077],
          [-122.142961, 37.866278],
          [-122.14289, 37.866375],
          [-122.142882, 37.866385],
          [-122.142848, 37.86644],
          [-122.142789, 37.866534],
          [-122.142898, 37.866583999999996],
          [-122.142938, 37.866602],
          [-122.142931, 37.866613],
          [-122.142901, 37.866654],
          [-122.142573, 37.867108],
          [-122.142784, 37.867218],
          [-122.142887, 37.867272],
          [-122.142905, 37.867281999999996],
          [-122.142926, 37.867293],
          [-122.14296, 37.868196999999995],
          [-122.142972, 37.86851],
          [-122.142977, 37.868632],
          [-122.14292, 37.868646],
          [-122.142836, 37.868668],
          [-122.142748, 37.868668],
          [-122.14273299999999, 37.868669],
          [-122.142692, 37.868655],
          [-122.14262099999999, 37.868631],
          [-122.142545, 37.868604999999995],
          [-122.14240699999999, 37.86857],
          [-122.142382, 37.868564],
          [-122.142334, 37.868563],
          [-122.142141, 37.868558],
          [-122.141962, 37.868594],
          [-122.14192299999999, 37.868728],
          [-122.14194599999999, 37.868772],
          [-122.141966, 37.868809],
          [-122.142011, 37.868927],
          [-122.142015, 37.86909],
          [-122.142017, 37.86918],
          [-122.142026, 37.869332],
          [-122.142089, 37.869445999999996],
          [-122.142176, 37.869569],
          [-122.142274, 37.869653],
          [-122.142361, 37.869789999999995],
          [-122.142335, 37.869948],
          [-122.142286, 37.870197],
          [-122.142263, 37.870331],
          [-122.142254, 37.870388],
          [-122.14218199999999, 37.870675],
          [-122.142116, 37.870875999999996],
          [-122.141958, 37.872738999999996],
          [-122.141074, 37.872717],
          [-122.13996399999999, 37.872688],
          [-122.13656499999999, 37.872603],
          [-122.136036, 37.876076999999995],
          [-122.133893, 37.876024],
          [-122.133618, 37.875951],
          [-122.133611, 37.875136999999995],
          [-122.132232, 37.875082],
          [-122.128709, 37.874943],
          [-122.128407, 37.874998],
          [-122.128382, 37.874983],
          [-122.12831800000001, 37.874927],
          [-122.128005, 37.874652],
          [-122.127957, 37.874609],
          [-122.12795, 37.874143],
          [-122.127948, 37.874024],
          [-122.128422, 37.873588],
          [-122.128444, 37.873568],
          [-122.128383, 37.873517],
          [-122.128271, 37.873425],
          [-122.12823900000001, 37.873398],
          [-122.128142, 37.873318],
          [-122.127844, 37.873180999999995],
          [-122.127657, 37.873096],
          [-122.126873, 37.872735999999996],
          [-122.126772, 37.87269],
          [-122.126417, 37.872704],
          [-122.126291, 37.872709],
          [-122.126059, 37.872741999999995],
          [-122.125576, 37.872808],
          [-122.125433, 37.872536],
          [-122.125185, 37.872586999999996],
          [-122.125284, 37.872771],
          [-122.125233, 37.872856999999996],
          [-122.124821, 37.872913],
          [-122.12439, 37.872972],
          [-122.124287, 37.872771],
          [-122.124039, 37.872803999999995],
          [-122.123993, 37.872699],
          [-122.123061, 37.872689],
          [-122.12046, 37.872664],
          [-122.120291, 37.872758999999995],
          [-122.119479, 37.873216],
          [-122.116309, 37.873461],
          [-122.116209, 37.873642],
          [-122.116165, 37.873723],
          [-122.116146, 37.873734999999996],
          [-122.115918, 37.874174],
          [-122.112203, 37.872945],
          [-122.110312, 37.87236],
          [-122.10965999999999, 37.872158],
          [-122.108251, 37.871722],
          [-122.106782, 37.870951],
          [-122.106877, 37.870681999999995],
          [-122.107556, 37.868778],
          [-122.107389, 37.867762],
          [-122.10736299999999, 37.867607],
          [-122.107651, 37.867607],
          [-122.108259, 37.867609],
          [-122.108274, 37.867139],
          [-122.108711, 37.86694],
          [-122.109737, 37.866475],
          [-122.109834, 37.866166],
          [-122.109906, 37.865950999999995],
          [-122.109873, 37.865612999999996],
          [-122.10979499999999, 37.865432999999996],
          [-122.109764, 37.865048],
          [-122.109734, 37.864612],
          [-122.109299, 37.864352],
          [-122.108972, 37.864129999999996],
          [-122.108659, 37.863917],
          [-122.108337, 37.863699],
          [-122.10812, 37.863552],
          [-122.107894, 37.863398],
          [-122.107729, 37.863287],
          [-122.107663, 37.863262999999996],
          [-122.107411, 37.86317],
          [-122.10716099999999, 37.863079],
          [-122.10691, 37.862987],
          [-122.106655, 37.862894],
          [-122.10647399999999, 37.862590999999995],
          [-122.106472, 37.862479],
          [-122.106396, 37.858689],
          [-122.106375, 37.857625],
          [-122.104566, 37.857625],
          [-122.103591, 37.857625],
          [-122.103111, 37.857625],
          [-122.103627, 37.856898],
          [-122.104675, 37.855425],
          [-122.105574, 37.854253],
          [-122.107153, 37.852198],
          [-122.107596, 37.851599],
          [-122.107821, 37.851396],
          [-122.108022, 37.85112],
          [-122.108176, 37.850708],
          [-122.108297, 37.850707],
          [-122.108611, 37.850299],
          [-122.108821, 37.850026],
          [-122.108648, 37.850026],
          [-122.10824, 37.850026],
          [-122.108256, 37.849931999999995],
          [-122.108277, 37.849713],
          [-122.108222, 37.849515],
          [-122.108225, 37.849486999999996],
          [-122.10823500000001, 37.849399],
          [-122.108228, 37.849333],
          [-122.108242, 37.849152],
          [-122.108256, 37.849119],
          [-122.108256, 37.848971],
          [-122.10827, 37.848938],
          [-122.10829, 37.848696],
          [-122.108352, 37.848378],
          [-122.10842199999999, 37.848234999999995],
          [-122.108442, 37.848103],
          [-122.108537, 37.847893],
          [-122.108698, 37.847432],
          [-122.108698, 37.847372],
          [-122.108637, 37.847375],
          [-122.10856, 37.847384],
          [-122.108486, 37.847401],
          [-122.108415, 37.847426],
          [-122.108319, 37.847477],
          [-122.108263, 37.847519],
          [-122.108214, 37.847567],
          [-122.108166, 37.847629999999995],
          [-122.108143, 37.847663],
          [-122.108088, 37.847753],
          [-122.108042, 37.847846],
          [-122.107989, 37.847989],
          [-122.107957, 37.848137],
          [-122.107947, 37.848332],
          [-122.107951, 37.849432],
          [-122.107952, 37.849452],
          [-122.107952, 37.849477],
          [-122.10795, 37.849537],
          [-122.107946, 37.849598],
          [-122.10794, 37.849658],
          [-122.10791, 37.849838999999996],
          [-122.107861, 37.850017],
          [-122.107853, 37.850038],
          [-122.107794, 37.850190999999995],
          [-122.10766699999999, 37.850428],
          [-122.107549, 37.850626999999996],
          [-122.107496, 37.850718],
          [-122.10746, 37.850778999999996],
          [-122.107232, 37.851122],
          [-122.107166, 37.851206999999995],
          [-122.107091, 37.851289],
          [-122.10700800000001, 37.851364],
          [-122.106917, 37.851434],
          [-122.106768, 37.851528],
          [-122.106605, 37.851605],
          [-122.106041, 37.851822],
          [-122.105803, 37.851915],
          [-122.105689, 37.851966],
          [-122.105527, 37.852053],
          [-122.105444, 37.852109999999996],
          [-122.102734, 37.852087999999995],
          [-122.102082, 37.852081999999996],
          [-122.101111, 37.852074],
          [-122.099723, 37.852062],
          [-122.094604, 37.852022],
          [-122.091895, 37.852001],
          [-122.09173, 37.851645],
          [-122.091417, 37.850967],
          [-122.091238, 37.85058],
          [-122.09107399999999, 37.850225],
          [-122.090629, 37.849675],
          [-122.090561, 37.849592],
          [-122.08997099999999, 37.849101999999995],
          [-122.089908, 37.848818],
          [-122.08997600000001, 37.848635],
          [-122.089634, 37.848234],
          [-122.089407, 37.848183999999996],
          [-122.089106, 37.848119],
          [-122.088741, 37.84796],
          [-122.088691, 37.847933],
          [-122.088603, 37.847888],
          [-122.088584, 37.847950999999995],
          [-122.088504, 37.848223],
          [-122.087315, 37.848209],
          [-122.083335, 37.848163],
          [-122.08324, 37.848161],
          [-122.08188200000001, 37.848146],
          [-122.081897, 37.843816],
          [-122.080467, 37.843816],
          [-122.079319, 37.843815],
          [-122.072938, 37.843813],
          [-122.07294, 37.84211],
          [-122.072948, 37.837854],
          [-122.072921, 37.837863999999996],
          [-122.072782, 37.837976999999995],
          [-122.07265100000001, 37.838146],
          [-122.072537, 37.838324],
          [-122.07221799999999, 37.838723],
          [-122.071922, 37.839026],
          [-122.071567, 37.839293],
          [-122.071133, 37.839509],
          [-122.070825, 37.839618],
          [-122.070644, 37.839636],
          [-122.070454, 37.839597],
          [-122.070245, 37.839473999999996],
          [-122.07021, 37.839441],
          [-122.070045, 37.839284],
          [-122.069806, 37.839078],
          [-122.069467, 37.838862],
          [-122.069243, 37.838702],
          [-122.068977, 37.838454999999996],
          [-122.068818, 37.838339999999995],
          [-122.06858199999999, 37.838298],
          [-122.06835699999999, 37.838271999999996],
          [-122.068226, 37.838218],
          [-122.068006, 37.838056],
          [-122.067908, 37.838025],
          [-122.067779, 37.838],
          [-122.067503, 37.837968],
          [-122.067143, 37.837905],
          [-122.06701799999999, 37.837865],
          [-122.066771, 37.837748],
          [-122.066642, 37.837725999999996],
          [-122.066209, 37.837694],
          [-122.065539, 37.837678],
          [-122.06520499999999, 37.837592],
          [-122.06486, 37.837368],
          [-122.064756, 37.837339],
          [-122.064854, 37.837247999999995],
          [-122.064905, 37.837134],
          [-122.06491, 37.837122],
          [-122.064884, 37.836976],
          [-122.064842, 37.836897],
          [-122.064731, 37.83677],
          [-122.064589, 37.836683],
          [-122.064398, 37.836605999999996],
          [-122.064234, 37.836490999999995],
          [-122.064123, 37.836380999999996],
          [-122.063981, 37.836254],
          [-122.063929, 37.836186999999995],
          [-122.06386, 37.836099],
          [-122.063734, 37.835907],
          [-122.063659, 37.835761999999995],
          [-122.06357, 37.835625],
          [-122.061419, 37.835609],
          [-122.06150000000001, 37.835595999999995],
          [-122.063361, 37.835304],
          [-122.064101, 37.835172],
          [-122.06501399999999, 37.835009],
          [-122.070819, 37.829684],
          [-122.070776, 37.828766],
          [-122.068902, 37.826118],
          [-122.065314, 37.821048999999995],
          [-122.069873, 37.821115],
          [-122.071525, 37.82114],
          [-122.079098, 37.82125],
          [-122.083257, 37.82131],
          [-122.097864, 37.821518999999995],
          [-122.103279, 37.821596],
          [-122.103715, 37.820053],
          [-122.103704, 37.819798999999996],
          [-122.106797, 37.819807],
          [-122.10814500000001, 37.819809],
          [-122.108323, 37.819807999999995],
          [-122.11231599999999, 37.819825],
          [-122.112492, 37.819556999999996],
          [-122.110701, 37.816291],
          [-122.110255, 37.815478],
          [-122.108035, 37.811433],
          [-122.10821899999999, 37.811316],
          [-122.108543, 37.811087],
          [-122.109055, 37.810753999999996],
          [-122.109256, 37.810654],
          [-122.10931, 37.810549],
          [-122.10987, 37.810254],
          [-122.110214, 37.809944],
          [-122.11028, 37.809826],
          [-122.110252, 37.809574999999995],
          [-122.110249, 37.809444],
          [-122.110246, 37.809332999999995],
          [-122.110357, 37.809233],
          [-122.110536, 37.80914],
          [-122.110711, 37.808859],
          [-122.110931, 37.808551],
          [-122.111087, 37.808414],
          [-122.111351, 37.808581],
          [-122.111346, 37.80838],
          [-122.11131499999999, 37.807356999999996],
          [-122.11368, 37.807316],
          [-122.114165, 37.807309],
          [-122.11507499999999, 37.808001],
          [-122.115578, 37.808247],
          [-122.11598599999999, 37.80889],
          [-122.116105, 37.809325],
          [-122.116728, 37.809779],
          [-122.117183, 37.810024999999996],
          [-122.118213, 37.810215],
          [-122.118908, 37.810233],
          [-122.120201, 37.809798],
          [-122.120198, 37.809886],
          [-122.120249, 37.810044],
          [-122.119554, 37.810365999999995],
          [-122.11929599999999, 37.811018],
          [-122.120715, 37.811268],
          [-122.121509, 37.811399],
          [-122.122064, 37.811493999999996],
          [-122.123096, 37.811676999999996],
          [-122.124053, 37.81196],
          [-122.125595, 37.812407],
          [-122.126616, 37.812703],
          [-122.126526, 37.814240999999996],
          [-122.126392, 37.814240999999996],
          [-122.125, 37.814267],
          [-122.12499700000001, 37.814558],
          [-122.12499700000001, 37.814633],
          [-122.12499199999999, 37.815408999999995],
          [-122.124991, 37.815686],
          [-122.124989, 37.815961],
          [-122.124987, 37.816337],
          [-122.124977, 37.817865999999995],
          [-122.124908, 37.818163999999996],
          [-122.124915, 37.818307],
          [-122.124901, 37.818346],
          [-122.124895, 37.818433999999996],
          [-122.12490199999999, 37.818522],
          [-122.125137, 37.818911],
          [-122.125168, 37.819027999999996],
          [-122.12517199999999, 37.819043],
          [-122.125276, 37.819185999999995],
          [-122.125456, 37.819362],
          [-122.125671, 37.819488],
          [-122.125747, 37.819554],
          [-122.125824, 37.819587],
          [-122.126018, 37.819686],
          [-122.12607299999999, 37.819719],
          [-122.126154, 37.819767],
          [-122.12621, 37.819815999999996],
          [-122.126251, 37.81986],
          [-122.126279, 37.819936999999996],
          [-122.126293, 37.820036],
          [-122.1263, 37.820212],
          [-122.126341, 37.820316],
          [-122.126403, 37.820403999999996],
          [-122.126569, 37.82047],
          [-122.12671499999999, 37.820509],
          [-122.126867, 37.820564],
          [-122.126909, 37.820602],
          [-122.12695, 37.820657],
          [-122.12701200000001, 37.820783999999996],
          [-122.127172, 37.820954],
          [-122.12725499999999, 37.820993],
          [-122.12755899999999, 37.821081],
          [-122.127878, 37.821218],
          [-122.12797499999999, 37.821279],
          [-122.128072, 37.821366999999995],
          [-122.128175, 37.821422],
          [-122.12827899999999, 37.821459999999995],
          [-122.128563, 37.821498999999996],
          [-122.128695, 37.821531],
          [-122.128743, 37.821543],
          [-122.129062, 37.821691],
          [-122.129221, 37.821752],
          [-122.129726, 37.82206],
          [-122.129927, 37.822159],
          [-122.130142, 37.822224999999996],
          [-122.13028, 37.822241],
          [-122.130372, 37.822257],
          [-122.130405, 37.822263],
          [-122.13055, 37.822269],
          [-122.13065399999999, 37.822269],
          [-122.131215, 37.822429],
          [-122.131783, 37.822565999999995],
          [-122.131866, 37.822565999999995],
          [-122.13197, 37.822555],
          [-122.132012, 37.822544],
          [-122.132206, 37.822544],
          [-122.132338, 37.822503999999995],
          [-122.13247, 37.822465],
          [-122.13271499999999, 37.822288],
          [-122.13296, 37.822111],
          [-122.13299, 37.822182],
          [-122.133029, 37.822274],
          [-122.133174, 37.822466],
          [-122.133329, 37.822392],
          [-122.133712, 37.822232],
          [-122.133938, 37.822145],
          [-122.134286, 37.822029],
          [-122.134642, 37.82193],
          [-122.134928, 37.821861999999996],
          [-122.135064, 37.821842],
          [-122.13520199999999, 37.821839],
          [-122.135339, 37.821850999999995],
          [-122.13547299999999, 37.821881],
          [-122.135598, 37.821925],
          [-122.136166, 37.822108],
          [-122.13654, 37.822237],
          [-122.137095, 37.822444],
          [-122.137768, 37.822649999999996],
          [-122.138528, 37.822882],
          [-122.139118, 37.823063],
          [-122.139319, 37.823099],
          [-122.139524, 37.823119999999996],
          [-122.13973, 37.823127],
          [-122.139937, 37.823118],
          [-122.140142, 37.823094999999995],
          [-122.141201, 37.822927],
          [-122.142189, 37.822756],
          [-122.142517, 37.822666],
          [-122.142731, 37.822597],
          [-122.14294100000001, 37.822521],
          [-122.143247, 37.822393],
          [-122.143494, 37.822275],
          [-122.14359400000001, 37.822216999999995],
          [-122.14363399999999, 37.822193999999996],
          [-122.14376, 37.8221],
          [-122.143872, 37.821995],
          [-122.143967, 37.82188],
          [-122.144044, 37.821757],
          [-122.144137, 37.821573],
          [-122.144251, 37.821397],
          [-122.144338, 37.821284999999996],
          [-122.14443299999999, 37.821176],
          [-122.14459, 37.821023],
          [-122.144764, 37.820881],
          [-122.145057, 37.82057],
          [-122.145304, 37.820364999999995],
          [-122.145384, 37.82028],
          [-122.146227, 37.821612],
          [-122.146866, 37.822621],
          [-122.149958, 37.824337],
          [-122.151337, 37.825103],
          [-122.152186, 37.825658],
          [-122.15456499999999, 37.827211],
          [-122.157331, 37.829018],
          [-122.15751, 37.83021],
          [-122.158057, 37.833847],
          [-122.158242, 37.835082],
          [-122.158922, 37.836211],
          [-122.159343, 37.836909999999996],
          [-122.160087, 37.838144],
          [-122.161334, 37.840213],
          [-122.161048, 37.840207],
        ],
        [
          [-122.114564, 37.840528],
          [-122.114439, 37.840457],
          [-122.114454, 37.84026],
          [-122.114438, 37.8401],
          [-122.114376, 37.839943999999996],
          [-122.114188, 37.839856999999995],
          [-122.113955, 37.837896],
          [-122.10928, 37.838173],
          [-122.109235, 37.838237],
          [-122.108914, 37.838681],
          [-122.10885, 37.838769],
          [-122.108375, 37.839425999999996],
          [-122.108341, 37.839475],
          [-122.107887, 37.840129999999995],
          [-122.106425, 37.842241],
          [-122.105938, 37.842946],
          [-122.106161, 37.842948],
          [-122.106319, 37.842949999999995],
          [-122.10647900000001, 37.842988999999996],
          [-122.106548, 37.843016],
          [-122.106666, 37.843087],
          [-122.106762, 37.843182],
          [-122.106921, 37.84334],
          [-122.107159, 37.843908],
          [-122.10753700000001, 37.844809],
          [-122.10779600000001, 37.845545],
          [-122.107883, 37.845512],
          [-122.108462, 37.845298],
          [-122.108503, 37.845372],
          [-122.108552, 37.845444],
          [-122.108559, 37.845476999999995],
          [-122.108552, 37.84563],
          [-122.108573, 37.845696],
          [-122.108573, 37.845845],
          [-122.108559, 37.845878],
          [-122.108559, 37.845926999999996],
          [-122.108573, 37.845966],
          [-122.10858, 37.846097],
          [-122.108663, 37.846317],
          [-122.10871900000001, 37.846592],
          [-122.108726, 37.846702],
          [-122.10873, 37.846948999999995],
          [-122.108733, 37.847048],
          [-122.10871900000001, 37.847108],
          [-122.108712, 37.847201999999996],
          [-122.108698, 37.847251],
          [-122.108698, 37.84731],
          [-122.108751, 37.847336999999996],
          [-122.108844, 37.847366],
          [-122.108941, 37.847372],
          [-122.109001, 37.847364999999996],
          [-122.109039, 37.847362],
          [-122.10913099999999, 37.847336],
          [-122.109213, 37.847293],
          [-122.109281, 37.847235999999995],
          [-122.109316, 37.847192],
          [-122.10954699999999, 37.8467],
          [-122.109677, 37.846424999999996],
          [-122.10999, 37.845752999999995],
          [-122.110091, 37.845537],
          [-122.110174, 37.845382],
          [-122.110231, 37.845302],
          [-122.11028, 37.845237],
          [-122.110362, 37.845144999999995],
          [-122.110501, 37.845017999999996],
          [-122.110604, 37.844941],
          [-122.111294, 37.844511],
          [-122.112865, 37.843658999999995],
          [-122.113428, 37.843354999999995],
          [-122.114405, 37.842829],
          [-122.114536, 37.842769],
          [-122.1145, 37.841713999999996],
          [-122.11447799999999, 37.841601],
          [-122.11452299999999, 37.841561999999996],
          [-122.114564, 37.840528],
        ],
      ],
    },
  },
  {
    id: "883",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94563",
      GEOID10: "94563",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 44733752,
      AWATER10: 40530,
      INTPTLAT10: "+37.8799199",
      INTPTLON10: "-122.1845716",
      Name: "94563",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.23455, 37.916508],
          [-122.234481, 37.916663],
          [-122.234365, 37.917021999999996],
          [-122.23410000000001, 37.916906999999995],
          [-122.233858, 37.916813999999995],
          [-122.233636, 37.916775],
          [-122.233476, 37.916858],
          [-122.233213, 37.916737],
          [-122.232997, 37.916649],
          [-122.232741, 37.916627],
          [-122.232491, 37.916567],
          [-122.232346, 37.91643],
          [-122.232255, 37.916232],
          [-122.23220599999999, 37.916011999999995],
          [-122.23222, 37.915836],
          [-122.232303, 37.915661],
          [-122.23244199999999, 37.915468],
          [-122.232546, 37.915347],
          [-122.232282, 37.915127999999996],
          [-122.232095, 37.915067],
          [-122.23187300000001, 37.915051],
          [-122.23154, 37.914881],
          [-122.231394, 37.914688],
          [-122.231242, 37.914446999999996],
          [-122.2312, 37.914244],
          [-122.231221, 37.914051],
          [-122.231366, 37.913925],
          [-122.231623, 37.913875],
          [-122.23190000000001, 37.913771],
          [-122.231893, 37.913688],
          [-122.23169899999999, 37.913743],
          [-122.231456, 37.913776],
          [-122.231158, 37.913759999999996],
          [-122.230895, 37.913727],
          [-122.23083199999999, 37.913546],
          [-122.230957, 37.913387],
          [-122.23101199999999, 37.91315],
          [-122.230859, 37.912957999999996],
          [-122.230901, 37.912771],
          [-122.230915, 37.912557],
          [-122.230797, 37.912343],
          [-122.230478, 37.912486],
          [-122.23020700000001, 37.912596],
          [-122.229992, 37.912265999999995],
          [-122.22967299999999, 37.911919999999995],
          [-122.229638, 37.91175],
          [-122.229576, 37.91159],
          [-122.229631, 37.911431],
          [-122.229825, 37.911370999999995],
          [-122.23047700000001, 37.91131],
          [-122.230761, 37.911068],
          [-122.230935, 37.910964],
          [-122.231025, 37.91081],
          [-122.23083, 37.910737999999995],
          [-122.23065, 37.910821],
          [-122.230491, 37.91092],
          [-122.230276, 37.910979999999995],
          [-122.230068, 37.910979999999995],
          [-122.229471, 37.910832],
          [-122.229055, 37.910772],
          [-122.228882, 37.910683999999996],
          [-122.228778, 37.910536],
          [-122.228556, 37.910058],
          [-122.228472, 37.909728],
          [-122.228548, 37.90958],
          [-122.228708, 37.90947],
          [-122.228777, 37.909310999999995],
          [-122.228583, 37.909267],
          [-122.227952, 37.909168],
          [-122.22782, 37.909047],
          [-122.227792, 37.908882],
          [-122.22773, 37.908722999999995],
          [-122.227716, 37.908564],
          [-122.227646, 37.908404999999995],
          [-122.227438, 37.908387999999995],
          [-122.227036, 37.908311],
          [-122.226842, 37.908234],
          [-122.226648, 37.908279],
          [-122.226232, 37.90824],
          [-122.225628, 37.908257],
          [-122.22540599999999, 37.908212999999996],
          [-122.225254, 37.908091999999996],
          [-122.225066, 37.907603],
          [-122.224879, 37.907295999999995],
          [-122.224768, 37.907168999999996],
          [-122.224629, 37.907289999999996],
          [-122.224532, 37.907444],
          [-122.224352, 37.907505],
          [-122.22412299999999, 37.907509999999995],
          [-122.224025, 37.907478999999995],
          [-122.223929, 37.90745],
          [-122.223756, 37.907362],
          [-122.223742, 37.907191999999995],
          [-122.223652, 37.907043],
          [-122.223443, 37.907087],
          [-122.223229, 37.90706],
          [-122.223069, 37.906960999999995],
          [-122.222993, 37.906802],
          [-122.222743, 37.906555],
          [-122.222514, 37.906532999999996],
          [-122.222098, 37.90656],
          [-122.221904, 37.906599],
          [-122.221689, 37.90661],
          [-122.221308, 37.906462],
          [-122.221099, 37.906434],
          [-122.220774, 37.906659999999995],
          [-122.220573, 37.906649],
          [-122.220253, 37.906423],
          [-122.220066, 37.906352],
          [-122.219844, 37.906347],
          [-122.219456, 37.906473],
          [-122.219248, 37.906501],
          [-122.21904, 37.906495],
          [-122.218846, 37.906445999999995],
          [-122.218659, 37.906363],
          [-122.218492, 37.906248],
          [-122.21813900000001, 37.906067],
          [-122.217937, 37.906110999999996],
          [-122.217806, 37.906237],
          [-122.217702, 37.90638],
          [-122.217327, 37.906545],
          [-122.216925, 37.906649],
          [-122.216759, 37.906754],
          [-122.21736899999999, 37.906858],
          [-122.217577, 37.90694],
          [-122.217376, 37.906984],
          [-122.217154, 37.906940999999996],
          [-122.216759, 37.906825],
          [-122.216585, 37.906743],
          [-122.216461, 37.906594999999996],
          [-122.216287, 37.906694],
          [-122.21612, 37.906712],
          [-122.21607900000001, 37.906715999999996],
          [-122.215878, 37.906666],
          [-122.215794, 37.906427],
          [-122.215767, 37.906348],
          [-122.215684, 37.906188],
          [-122.21530899999999, 37.905798],
          [-122.215115, 37.905727],
          [-122.214983, 37.905606],
          [-122.214928, 37.905436],
          [-122.214911, 37.905305],
          [-122.214886, 37.905105999999996],
          [-122.214838, 37.904947],
          [-122.21474, 37.904857],
          [-122.214699, 37.90482],
          [-122.214671, 37.904661],
          [-122.214693, 37.904627],
          [-122.214775, 37.904506999999995],
          [-122.21483, 37.904348],
          [-122.214865, 37.904018],
          [-122.214815, 37.903901999999995],
          [-122.214795, 37.903853999999995],
          [-122.214594, 37.903815],
          [-122.214691, 37.904057],
          [-122.214685, 37.904078],
          [-122.214664, 37.904171999999996],
          [-122.214581, 37.904271],
          [-122.214456, 37.904332],
          [-122.21435199999999, 37.904424999999996],
          [-122.21429, 37.904534999999996],
          [-122.214351, 37.904744],
          [-122.214359, 37.904771],
          [-122.214506, 37.904979],
          [-122.214581, 37.905083999999995],
          [-122.214657, 37.905315],
          [-122.214675, 37.905453],
          [-122.214706, 37.905688],
          [-122.21476200000001, 37.905803999999996],
          [-122.214977, 37.905985],
          [-122.215226, 37.906282],
          [-122.21542, 37.906473999999996],
          [-122.215527, 37.906675],
          [-122.215767, 37.907128],
          [-122.215622, 37.907111],
          [-122.21551099999999, 37.907018],
          [-122.215379, 37.906974],
          [-122.215233, 37.906985],
          [-122.214991, 37.907111],
          [-122.21473399999999, 37.907392],
          [-122.214646, 37.907463],
          [-122.214533, 37.907556],
          [-122.214297, 37.907408],
          [-122.214027, 37.907337],
          [-122.213722, 37.907298],
          [-122.213597, 37.907348],
          [-122.213354, 37.907485],
          [-122.213042, 37.907495999999995],
          [-122.212841, 37.907667],
          [-122.21266800000001, 37.907869999999996],
          [-122.212557, 37.907958],
          [-122.21248800000001, 37.908057],
          [-122.212585, 37.90815],
          [-122.212828, 37.908287],
          [-122.21253, 37.908513],
          [-122.212294, 37.908656],
          [-122.212155, 37.908733],
          [-122.212119, 37.909006],
          [-122.212086, 37.909265999999995],
          [-122.212079, 37.909490999999996],
          [-122.212058, 37.909689],
          [-122.211934, 37.909914],
          [-122.211627, 37.91017],
          [-122.21144, 37.910325],
          [-122.210921, 37.910759999999996],
          [-122.21088499999999, 37.910799],
          [-122.210723, 37.910979999999995],
          [-122.213428, 37.912735999999995],
          [-122.215289, 37.913944],
          [-122.216133, 37.914491999999996],
          [-122.216165, 37.914631],
          [-122.216421, 37.915479],
          [-122.216408, 37.915574],
          [-122.216337, 37.915738999999995],
          [-122.216224, 37.915929],
          [-122.215876, 37.916179],
          [-122.215577, 37.916385999999996],
          [-122.215558, 37.916534],
          [-122.215611, 37.916711],
          [-122.21585999999999, 37.917009],
          [-122.216073, 37.917235999999995],
          [-122.216151, 37.917351],
          [-122.21619, 37.917532],
          [-122.21624800000001, 37.917722999999995],
          [-122.216285, 37.917792999999996],
          [-122.216375, 37.917896999999996],
          [-122.21654699999999, 37.918062],
          [-122.216584, 37.918124],
          [-122.216585, 37.918172999999996],
          [-122.216466, 37.918203],
          [-122.216312, 37.918205],
          [-122.216086, 37.918184],
          [-122.215891, 37.918138],
          [-122.215942, 37.918231999999996],
          [-122.216038, 37.918377],
          [-122.216144, 37.918572],
          [-122.216218, 37.918728],
          [-122.216223, 37.918931],
          [-122.21622099999999, 37.919036],
          [-122.21619799999999, 37.919179],
          [-122.21611899999999, 37.919401],
          [-122.21585999999999, 37.919858999999995],
          [-122.21578199999999, 37.920123],
          [-122.215699, 37.920533],
          [-122.215892, 37.921244],
          [-122.215934, 37.921506],
          [-122.215903, 37.921699],
          [-122.215784, 37.921931],
          [-122.215634, 37.922101],
          [-122.215482, 37.922216],
          [-122.215118, 37.922319],
          [-122.214713, 37.922405],
          [-122.214499, 37.922475],
          [-122.214342, 37.922588999999995],
          [-122.214069, 37.922799],
          [-122.21397, 37.922916],
          [-122.213885, 37.923054],
          [-122.21382, 37.923296],
          [-122.21373, 37.923429999999996],
          [-122.213626, 37.923505],
          [-122.213459, 37.923542999999995],
          [-122.213328, 37.923594],
          [-122.213273, 37.923682],
          [-122.213272, 37.924053],
          [-122.213264, 37.924291],
          [-122.213222, 37.924368],
          [-122.212873, 37.924377],
          [-122.212645, 37.924422],
          [-122.212486, 37.924496],
          [-122.208896, 37.921129],
          [-122.209031, 37.921144],
          [-122.209447, 37.921209999999995],
          [-122.209843, 37.921298],
          [-122.21091799999999, 37.921842],
          [-122.211237, 37.92188],
          [-122.211618, 37.921721],
          [-122.21181899999999, 37.921501],
          [-122.212034, 37.921385],
          [-122.212117, 37.921396],
          [-122.212804, 37.921512],
          [-122.212943, 37.921341],
          [-122.213074, 37.92116],
          [-122.21331, 37.920978999999996],
          [-122.213206, 37.920852],
          [-122.212936, 37.920682],
          [-122.212762, 37.920462],
          [-122.212707, 37.920144],
          [-122.212852, 37.920034],
          [-122.212817, 37.91977],
          [-122.212734, 37.919484],
          [-122.212554, 37.919298],
          [-122.212519, 37.919072],
          [-122.21262300000001, 37.918875],
          [-122.212651, 37.918506],
          [-122.212748, 37.918309],
          [-122.212886, 37.918045],
          [-122.212865, 37.917732],
          [-122.212803, 37.917452],
          [-122.212713, 37.917155],
          [-122.212643, 37.916830999999995],
          [-122.212601, 37.916606],
          [-122.212588, 37.916368999999996],
          [-122.212587, 37.916039999999995],
          [-122.21253899999999, 37.915661],
          [-122.21239299999999, 37.915347],
          [-122.212192, 37.915073],
          [-122.212025, 37.914946],
          [-122.211228, 37.914654999999996],
          [-122.210805, 37.914513],
          [-122.210486, 37.914418999999995],
          [-122.210215, 37.914314999999995],
          [-122.209979, 37.914232999999996],
          [-122.209688, 37.914094999999996],
          [-122.20913999999999, 37.913793],
          [-122.209085, 37.913765999999995],
          [-122.208828, 37.913623],
          [-122.208516, 37.913425],
          [-122.20811, 37.913208],
          [-122.207769, 37.913014],
          [-122.207414, 37.91279],
          [-122.207187, 37.912611],
          [-122.20648800000001, 37.911989],
          [-122.206351, 37.911871],
          [-122.20632499999999, 37.911851999999996],
          [-122.20615699999999, 37.911735],
          [-122.205948, 37.911708],
          [-122.205599, 37.911769],
          [-122.205421, 37.91186],
          [-122.20521600000001, 37.912366999999996],
          [-122.20497, 37.912631],
          [-122.204875, 37.912904],
          [-122.204881, 37.913177999999995],
          [-122.20513, 37.913858],
          [-122.205152, 37.914076],
          [-122.204949, 37.914314],
          [-122.204695, 37.914418999999995],
          [-122.204116, 37.914683],
          [-122.20383799999999, 37.914789999999996],
          [-122.203758, 37.914777],
          [-122.20370000000001, 37.909727],
          [-122.20353, 37.909982],
          [-122.203147, 37.910534],
          [-122.202028, 37.912149],
          [-122.20071899999999, 37.914038],
          [-122.200552, 37.914303],
          [-122.200469, 37.914474999999996],
          [-122.200407, 37.914653],
          [-122.200378, 37.914774],
          [-122.200361, 37.914905],
          [-122.200351, 37.915116],
          [-122.200372, 37.915849],
          [-122.20039299999999, 37.916312],
          [-122.200414, 37.916638],
          [-122.20040399999999, 37.916835999999996],
          [-122.20036999999999, 37.917032],
          [-122.200311, 37.917224],
          [-122.200238, 37.917384],
          [-122.200146, 37.917538],
          [-122.200036, 37.917684],
          [-122.19991, 37.917822],
          [-122.199816, 37.917908],
          [-122.199601, 37.918065999999996],
          [-122.199449, 37.918164],
          [-122.198266, 37.918827],
          [-122.198147, 37.918887999999995],
          [-122.197978, 37.918988999999996],
          [-122.197822, 37.919101999999995],
          [-122.197675, 37.919228],
          [-122.197613, 37.919289],
          [-122.197496, 37.919419],
          [-122.197383, 37.919574],
          [-122.19729, 37.919737],
          [-122.19726299999999, 37.919792],
          [-122.197186, 37.920017],
          [-122.197131, 37.920246],
          [-122.197033, 37.920947999999996],
          [-122.19698700000001, 37.921127999999996],
          [-122.196919, 37.921304],
          [-122.19683, 37.921473999999996],
          [-122.196762, 37.921564],
          [-122.196643, 37.921692],
          [-122.196508, 37.921808],
          [-122.196356, 37.921911],
          [-122.196191, 37.922000000000004],
          [-122.19601399999999, 37.922073],
          [-122.195831, 37.922131],
          [-122.195641, 37.922173],
          [-122.195447, 37.922198],
          [-122.195251, 37.922205],
          [-122.19512, 37.922200000000004],
          [-122.194926, 37.922177999999995],
          [-122.19479799999999, 37.922154],
          [-122.194599, 37.922097],
          [-122.194471, 37.92205],
          [-122.194347, 37.921997],
          [-122.194171, 37.921904999999995],
          [-122.194007, 37.921797999999995],
          [-122.193858, 37.921679],
          [-122.192895, 37.920983],
          [-122.19270900000001, 37.920885],
          [-122.192512, 37.9208],
          [-122.192306, 37.920730999999996],
          [-122.192092, 37.920677999999995],
          [-122.191947, 37.920651],
          [-122.1918, 37.920631],
          [-122.190674, 37.920513],
          [-122.189776, 37.92042],
          [-122.189519, 37.920384],
          [-122.189139, 37.920314],
          [-122.18888799999999, 37.920257],
          [-122.188424, 37.920128999999996],
          [-122.186658, 37.919596],
          [-122.186302, 37.919495],
          [-122.185943, 37.919402],
          [-122.18544, 37.919287],
          [-122.185014, 37.919207],
          [-122.184728, 37.919162],
          [-122.18429499999999, 37.919109],
          [-122.18412000000001, 37.919091],
          [-122.180572, 37.918667],
          [-122.180506, 37.918659999999996],
          [-122.176892, 37.918229],
          [-122.174716, 37.917967],
          [-122.174441, 37.917942],
          [-122.174026, 37.917919],
          [-122.173611, 37.917913999999996],
          [-122.173349, 37.917919],
          [-122.172979, 37.917942],
          [-122.172612, 37.917978999999995],
          [-122.172247, 37.91803],
          [-122.172006, 37.918073],
          [-122.171584, 37.918168],
          [-122.171168, 37.918278],
          [-122.170623, 37.918445999999996],
          [-122.169985, 37.918636],
          [-122.166883, 37.919562],
          [-122.16668, 37.919615],
          [-122.16656, 37.919653],
          [-122.166383, 37.919708],
          [-122.166096, 37.91982],
          [-122.166055, 37.919837],
          [-122.165911, 37.919903],
          [-122.165668, 37.920027999999995],
          [-122.165489, 37.920139],
          [-122.165322, 37.920262],
          [-122.16517, 37.920396],
          [-122.165022, 37.920552],
          [-122.164866, 37.920742],
          [-122.16481, 37.920823],
          [-122.16472999999999, 37.920941],
          [-122.164613, 37.921147999999995],
          [-122.164546, 37.92129],
          [-122.16434, 37.921228],
          [-122.164148, 37.92117],
          [-122.16372799999999, 37.921026999999995],
          [-122.16352499999999, 37.920958999999996],
          [-122.16302999999999, 37.920791],
          [-122.162714, 37.920685],
          [-122.162315, 37.920542],
          [-122.162139, 37.92049],
          [-122.162053, 37.920449],
          [-122.161981, 37.920393],
          [-122.161926, 37.920327],
          [-122.161901, 37.920277999999996],
          [-122.161749, 37.920021999999996],
          [-122.161744, 37.920013],
          [-122.161573, 37.919753],
          [-122.161457, 37.91959],
          [-122.161418, 37.919534999999996],
          [-122.161329, 37.919385999999996],
          [-122.16126, 37.919230999999996],
          [-122.161227, 37.919125],
          [-122.161203, 37.919017],
          [-122.161186, 37.918853],
          [-122.16116, 37.918748],
          [-122.161122, 37.918586999999995],
          [-122.160953, 37.918289],
          [-122.160877, 37.918226],
          [-122.16072299999999, 37.918099],
          [-122.160339, 37.917885999999996],
          [-122.160184, 37.917809999999996],
          [-122.159981, 37.917829999999995],
          [-122.159725, 37.917891],
          [-122.159201, 37.917851999999996],
          [-122.158906, 37.917781999999995],
          [-122.158829, 37.917764],
          [-122.15829, 37.917701],
          [-122.15815, 37.917684],
          [-122.15800899999999, 37.917668],
          [-122.158002, 37.917525999999995],
          [-122.15849299999999, 37.917479],
          [-122.158946, 37.917562],
          [-122.159438, 37.917542],
          [-122.15974, 37.91753],
          [-122.15999, 37.917204],
          [-122.15983, 37.91692],
          [-122.159614, 37.916562],
          [-122.159553, 37.916444],
          [-122.159525, 37.916388],
          [-122.159266, 37.916109],
          [-122.158893, 37.915800000000004],
          [-122.15849, 37.915534],
          [-122.158259, 37.915312],
          [-122.158242, 37.915293999999996],
          [-122.158207, 37.915121],
          [-122.158265, 37.914915],
          [-122.158575, 37.914743],
          [-122.158981, 37.914656],
          [-122.15921900000001, 37.914550999999996],
          [-122.159346, 37.914496],
          [-122.159611, 37.914449],
          [-122.160004, 37.914462],
          [-122.16033, 37.914333],
          [-122.16055, 37.914348],
          [-122.160732, 37.914466],
          [-122.160757, 37.914482],
          [-122.16090000000001, 37.914505],
          [-122.160961, 37.914515],
          [-122.160997, 37.914521],
          [-122.16125, 37.914555],
          [-122.161289, 37.914563],
          [-122.161363, 37.914587],
          [-122.161467, 37.914637],
          [-122.161527, 37.914679],
          [-122.161581, 37.914728],
          [-122.161616, 37.914749],
          [-122.16163, 37.914758],
          [-122.161712, 37.914792],
          [-122.161802, 37.914809999999996],
          [-122.161843, 37.914811],
          [-122.161895, 37.914812999999995],
          [-122.161956, 37.914806],
          [-122.16198, 37.914798999999995],
          [-122.162045, 37.914783],
          [-122.162099, 37.914759],
          [-122.16237699999999, 37.914673],
          [-122.162407, 37.914663999999995],
          [-122.162504, 37.914615999999995],
          [-122.162574, 37.914563],
          [-122.162631, 37.914493],
          [-122.162635, 37.914482],
          [-122.162668, 37.914414],
          [-122.16269, 37.914279],
          [-122.162727, 37.914061],
          [-122.162768, 37.914059],
          [-122.162858, 37.914034],
          [-122.162885, 37.914021],
          [-122.162917, 37.913999],
          [-122.16296, 37.913954],
          [-122.162988, 37.913903],
          [-122.16284999999999, 37.913835999999996],
          [-122.162687, 37.913776999999996],
          [-122.162632, 37.913712],
          [-122.162561, 37.913655999999996],
          [-122.162485, 37.91361],
          [-122.16242199999999, 37.913554],
          [-122.162373, 37.913489999999996],
          [-122.162341, 37.913419999999995],
          [-122.162315, 37.913292999999996],
          [-122.16230999999999, 37.913267999999995],
          [-122.162284, 37.913193],
          [-122.162225, 37.913086],
          [-122.16217499999999, 37.913019999999996],
          [-122.162116, 37.912957999999996],
          [-122.162027, 37.912887999999995],
          [-122.161998, 37.912853999999996],
          [-122.161969, 37.912797],
          [-122.16196099999999, 37.912737],
          [-122.161974, 37.912676],
          [-122.16200599999999, 37.912621],
          [-122.162056, 37.912574],
          [-122.162097, 37.912549],
          [-122.16232, 37.912442999999996],
          [-122.16246100000001, 37.912365],
          [-122.162597, 37.912279999999996],
          [-122.162787, 37.912141],
          [-122.162905, 37.912042],
          [-122.162968, 37.911955],
          [-122.163012, 37.911861],
          [-122.163038, 37.911763],
          [-122.163043, 37.911664],
          [-122.163036, 37.911598],
          [-122.162924, 37.911238],
          [-122.16289, 37.911172],
          [-122.162846, 37.91111],
          [-122.162793, 37.911052999999995],
          [-122.162732, 37.911001999999996],
          [-122.162642, 37.910942],
          [-122.162588, 37.910888],
          [-122.162552, 37.910826],
          [-122.162539, 37.910781],
          [-122.162538, 37.910713],
          [-122.162565, 37.91028],
          [-122.162565, 37.91023],
          [-122.162567, 37.910142],
          [-122.16256, 37.910081],
          [-122.162555, 37.910032],
          [-122.163346, 37.909980999999995],
          [-122.16346899999999, 37.909974],
          [-122.164383, 37.909915],
          [-122.164382, 37.909901999999995],
          [-122.164373, 37.90971],
          [-122.164253, 37.909586],
          [-122.165402, 37.909538999999995],
          [-122.165643, 37.90953],
          [-122.16617600000001, 37.909515999999996],
          [-122.166178, 37.909421],
          [-122.16618700000001, 37.909138],
          [-122.16619, 37.909044],
          [-122.166128, 37.908480999999995],
          [-122.165942, 37.906791999999996],
          [-122.165881, 37.906228999999996],
          [-122.165582, 37.90559],
          [-122.166498, 37.905231],
          [-122.166451, 37.905153],
          [-122.16635600000001, 37.905032999999996],
          [-122.16623, 37.904845],
          [-122.166158, 37.904716],
          [-122.166068, 37.904517],
          [-122.166022, 37.904382],
          [-122.16601299999999, 37.904356],
          [-122.166009, 37.904323],
          [-122.166004, 37.904277],
          [-122.166018, 37.904196999999996],
          [-122.166039, 37.904146],
          [-122.16606999999999, 37.904098],
          [-122.16555, 37.903363999999996],
          [-122.16628, 37.899243999999996],
          [-122.16619299999999, 37.899237],
          [-122.165954, 37.899338],
          [-122.16577, 37.899477],
          [-122.165692, 37.899502],
          [-122.16559699999999, 37.899476],
          [-122.165556, 37.899406],
          [-122.16560799999999, 37.89928],
          [-122.165725, 37.899021],
          [-122.165894, 37.898869999999995],
          [-122.16594, 37.898705],
          [-122.166048, 37.898469999999996],
          [-122.166093, 37.898393999999996],
          [-122.166086, 37.897859],
          [-122.166065, 37.896254],
          [-122.166059, 37.895719],
          [-122.165927, 37.895300999999996],
          [-122.165739, 37.894701999999995],
          [-122.165533, 37.894047],
          [-122.165419, 37.893684],
          [-122.165402, 37.89363],
          [-122.1654, 37.893512],
          [-122.165395, 37.893159],
          [-122.16539399999999, 37.893042],
          [-122.16539399999999, 37.893004],
          [-122.165395, 37.892891999999996],
          [-122.165396, 37.892855],
          [-122.16551, 37.892804999999996],
          [-122.165855, 37.892658],
          [-122.165971, 37.892609],
          [-122.165862, 37.892553],
          [-122.165538, 37.892385],
          [-122.16543, 37.89233],
          [-122.165481, 37.892325],
          [-122.165634, 37.892309999999995],
          [-122.165686, 37.892306],
          [-122.16596799999999, 37.8919],
          [-122.166816, 37.890682999999996],
          [-122.166892, 37.890572],
          [-122.16709900000001, 37.890277999999995],
          [-122.167085, 37.890262],
          [-122.167033, 37.890085],
          [-122.167006, 37.889996],
          [-122.167001, 37.889762999999995],
          [-122.166994, 37.889721],
          [-122.166963, 37.889526],
          [-122.166917, 37.889524],
          [-122.16687999999999, 37.889523],
          [-122.166713, 37.88952],
          [-122.166494, 37.889514999999996],
          [-122.166391, 37.889513],
          [-122.166037, 37.889492],
          [-122.165891, 37.889359],
          [-122.16573, 37.889213],
          [-122.165724, 37.889129],
          [-122.16566, 37.888633],
          [-122.165643, 37.888501],
          [-122.165509, 37.887695],
          [-122.165365, 37.886907],
          [-122.165358, 37.886865],
          [-122.16537, 37.886325],
          [-122.165364, 37.88613],
          [-122.165359, 37.885988],
          [-122.165346, 37.885545],
          [-122.165341, 37.885351],
          [-122.16546199999999, 37.884695],
          [-122.16548399999999, 37.884577],
          [-122.164357, 37.884451],
          [-122.163677, 37.884146],
          [-122.16345799999999, 37.884048],
          [-122.163473, 37.883977],
          [-122.163507, 37.883818999999995],
          [-122.163354, 37.883704],
          [-122.163286, 37.883658],
          [-122.163309, 37.883615],
          [-122.162961, 37.88344],
          [-122.162742, 37.88333],
          [-122.162361, 37.883292999999995],
          [-122.162055, 37.883201],
          [-122.161695, 37.883092999999995],
          [-122.16184200000001, 37.882906],
          [-122.161903, 37.882829],
          [-122.162117, 37.882557],
          [-122.162519, 37.882045999999995],
          [-122.16273799999999, 37.881766999999996],
          [-122.163014, 37.881416],
          [-122.162948, 37.881415],
          [-122.16201, 37.881392999999996],
          [-122.159196, 37.88133],
          [-122.158259, 37.881309],
          [-122.158245, 37.881361],
          [-122.158222, 37.881457999999995],
          [-122.158199, 37.881516],
          [-122.15818, 37.881567],
          [-122.158101, 37.881552],
          [-122.15799, 37.881530999999995],
          [-122.157866, 37.881509],
          [-122.157788, 37.881495],
          [-122.157531, 37.881453],
          [-122.156762, 37.881327999999996],
          [-122.15650600000001, 37.881287],
          [-122.156544, 37.881403],
          [-122.156592, 37.88155],
          [-122.156658, 37.881752],
          [-122.156697, 37.881869],
          [-122.15619699999999, 37.882131],
          [-122.15612999999999, 37.881969],
          [-122.155824, 37.882093],
          [-122.155587, 37.882636999999995],
          [-122.155546, 37.882732],
          [-122.155525, 37.882781],
          [-122.155197, 37.883376],
          [-122.155162, 37.883449999999996],
          [-122.15506500000001, 37.883665],
          [-122.153553, 37.883424],
          [-122.153173, 37.883362999999996],
          [-122.15300500000001, 37.883336],
          [-122.153118, 37.882897],
          [-122.153773, 37.882428999999995],
          [-122.154264, 37.882416],
          [-122.15465, 37.882407],
          [-122.154913, 37.882400000000004],
          [-122.154955, 37.882304999999995],
          [-122.155326, 37.881473],
          [-122.155561, 37.881434],
          [-122.155725, 37.881408],
          [-122.155693, 37.881349],
          [-122.155541, 37.88117],
          [-122.155484, 37.881102999999996],
          [-122.155336, 37.880928999999995],
          [-122.15515500000001, 37.880928999999995],
          [-122.155149, 37.880749],
          [-122.155149, 37.880685],
          [-122.155167, 37.878764],
          [-122.155176, 37.877925],
          [-122.155166, 37.877579],
          [-122.15516, 37.877356999999996],
          [-122.155144, 37.876788999999995],
          [-122.155136, 37.876540999999996],
          [-122.155127, 37.876196],
          [-122.15519, 37.875001999999995],
          [-122.155199, 37.874822],
          [-122.155228, 37.874269999999996],
          [-122.15525199999999, 37.87381],
          [-122.155182, 37.873830999999996],
          [-122.15487999999999, 37.873886],
          [-122.154406, 37.873992],
          [-122.154045, 37.874065],
          [-122.153543, 37.874131999999996],
          [-122.153144, 37.874202],
          [-122.152769, 37.874404999999996],
          [-122.152683, 37.874432],
          [-122.152368, 37.874381],
          [-122.151885, 37.874323],
          [-122.1513, 37.874327],
          [-122.150926, 37.874297999999996],
          [-122.150353, 37.874103999999996],
          [-122.14984, 37.873909999999995],
          [-122.149518, 37.873794],
          [-122.149191, 37.873717],
          [-122.149013, 37.873723999999996],
          [-122.148808, 37.873774],
          [-122.148527, 37.873799],
          [-122.148316, 37.873785],
          [-122.148016, 37.873681999999995],
          [-122.14771, 37.873582999999996],
          [-122.147455, 37.873543999999995],
          [-122.14725899999999, 37.873498999999995],
          [-122.146954, 37.873401],
          [-122.146616, 37.873298],
          [-122.146315, 37.873173],
          [-122.146123, 37.873069],
          [-122.146001, 37.872937],
          [-122.145718, 37.872589999999995],
          [-122.145535, 37.872408],
          [-122.145342, 37.872277],
          [-122.145209, 37.872133],
          [-122.145085, 37.87189],
          [-122.144982, 37.871609],
          [-122.144968, 37.871508999999996],
          [-122.144887, 37.871386],
          [-122.144734, 37.871274],
          [-122.144539, 37.871157],
          [-122.144454, 37.87112],
          [-122.14435499999999, 37.870993],
          [-122.14416, 37.870895],
          [-122.143894, 37.870808],
          [-122.143592, 37.87076],
          [-122.14331, 37.870782999999996],
          [-122.143064, 37.870839],
          [-122.142943, 37.870858],
          [-122.14283, 37.870875999999996],
          [-122.142639, 37.870931],
          [-122.142495, 37.870965999999996],
          [-122.142314, 37.870931],
          [-122.142151, 37.87088],
          [-122.142116, 37.870875999999996],
          [-122.14218199999999, 37.870675],
          [-122.142254, 37.870388],
          [-122.142263, 37.870331],
          [-122.142286, 37.870197],
          [-122.142335, 37.869948],
          [-122.142361, 37.869789999999995],
          [-122.142274, 37.869653],
          [-122.142176, 37.869569],
          [-122.142089, 37.869445999999996],
          [-122.142026, 37.869332],
          [-122.142017, 37.86918],
          [-122.142015, 37.86909],
          [-122.142011, 37.868927],
          [-122.141966, 37.868809],
          [-122.14194599999999, 37.868772],
          [-122.14192299999999, 37.868728],
          [-122.141962, 37.868594],
          [-122.142141, 37.868558],
          [-122.142334, 37.868563],
          [-122.142382, 37.868564],
          [-122.14240699999999, 37.86857],
          [-122.142545, 37.868604999999995],
          [-122.14262099999999, 37.868631],
          [-122.142692, 37.868655],
          [-122.14273299999999, 37.868669],
          [-122.142748, 37.868668],
          [-122.142836, 37.868668],
          [-122.14292, 37.868646],
          [-122.142977, 37.868632],
          [-122.142972, 37.86851],
          [-122.14296, 37.868196999999995],
          [-122.142926, 37.867293],
          [-122.142905, 37.867281999999996],
          [-122.142887, 37.867272],
          [-122.142784, 37.867218],
          [-122.142573, 37.867108],
          [-122.142901, 37.866654],
          [-122.142931, 37.866613],
          [-122.142938, 37.866602],
          [-122.142898, 37.866583999999996],
          [-122.142789, 37.866534],
          [-122.142848, 37.86644],
          [-122.142882, 37.866385],
          [-122.14289, 37.866375],
          [-122.142961, 37.866278],
          [-122.143109, 37.866077],
          [-122.143146, 37.866026],
          [-122.14323, 37.86591],
          [-122.14345399999999, 37.866014],
          [-122.143605, 37.866082],
          [-122.143811, 37.866208],
          [-122.144106, 37.865857999999996],
          [-122.144288, 37.865643999999996],
          [-122.144301, 37.865328999999996],
          [-122.144304, 37.865041],
          [-122.144304, 37.86482],
          [-122.144371, 37.864618],
          [-122.14465, 37.863772999999995],
          [-122.144709, 37.863592],
          [-122.144347, 37.863073],
          [-122.144363, 37.862697],
          [-122.143795, 37.862746],
          [-122.143777, 37.862842],
          [-122.143681, 37.863385],
          [-122.143297, 37.863336],
          [-122.143258, 37.863406999999995],
          [-122.14284599999999, 37.863405],
          [-122.142866, 37.863371],
          [-122.142876, 37.863335],
          [-122.142876, 37.863298],
          [-122.14287, 37.863279],
          [-122.142848, 37.863206],
          [-122.142781, 37.863068999999996],
          [-122.14272, 37.862941],
          [-122.14257599999999, 37.862643],
          [-122.142551, 37.86255],
          [-122.142545, 37.862456],
          [-122.142558, 37.862362999999995],
          [-122.142588, 37.862275],
          [-122.142636, 37.862182],
          [-122.142703, 37.862096],
          [-122.142719, 37.86208],
          [-122.142785, 37.86202],
          [-122.142883, 37.861943],
          [-122.142932, 37.861905],
          [-122.143005, 37.86186],
          [-122.143062, 37.861824999999996],
          [-122.143203, 37.86176],
          [-122.143253, 37.861740999999995],
          [-122.143303, 37.861723999999995],
          [-122.14345900000001, 37.861685],
          [-122.143697, 37.861643],
          [-122.143889, 37.861596],
          [-122.144056, 37.861540999999995],
          [-122.144075, 37.861534999999996],
          [-122.14425299999999, 37.861461],
          [-122.14430999999999, 37.861433],
          [-122.144474, 37.861214],
          [-122.144594, 37.860989],
          [-122.14454599999999, 37.860673999999996],
          [-122.14436599999999, 37.86041],
          [-122.144012, 37.860171],
          [-122.143815, 37.860002],
          [-122.14361099999999, 37.859843],
          [-122.14354, 37.859787],
          [-122.143278, 37.859646999999995],
          [-122.143144, 37.859608],
          [-122.142963, 37.859556],
          [-122.142715, 37.859496],
          [-122.142708, 37.858869],
          [-122.142702, 37.858577],
          [-122.142687, 37.858304],
          [-122.142687, 37.858239],
          [-122.142679, 37.858163],
          [-122.142651, 37.856257],
          [-122.142597, 37.852651],
          [-122.14259799999999, 37.852641999999996],
          [-122.142176, 37.85238],
          [-122.142062, 37.852312],
          [-122.141948, 37.851887],
          [-122.14192, 37.851791999999996],
          [-122.14178, 37.851279999999996],
          [-122.141524, 37.851397],
          [-122.14120299999999, 37.85125],
          [-122.14113, 37.851214],
          [-122.140702, 37.851016],
          [-122.140409, 37.851744],
          [-122.14032399999999, 37.851951],
          [-122.140084, 37.851717],
          [-122.139979, 37.851465],
          [-122.139887, 37.851241],
          [-122.139865, 37.850908],
          [-122.139839, 37.850505999999996],
          [-122.139802, 37.85],
          [-122.139776, 37.849925],
          [-122.139749, 37.849917999999995],
          [-122.139572, 37.849831],
          [-122.139569, 37.849810999999995],
          [-122.13949, 37.849325],
          [-122.139444, 37.849036],
          [-122.139676, 37.849022],
          [-122.140309, 37.848982],
          [-122.140754, 37.848954],
          [-122.140676, 37.848554],
          [-122.140611, 37.84822],
          [-122.140602, 37.848175],
          [-122.140546, 37.847887],
          [-122.14054, 37.847859],
          [-122.141627, 37.847381999999996],
          [-122.141819, 37.847564],
          [-122.14203, 37.847763],
          [-122.142073, 37.847684],
          [-122.142118, 37.847598999999995],
          [-122.142174, 37.847525],
          [-122.142296, 37.847429],
          [-122.142363, 37.847367999999996],
          [-122.142392, 37.847339999999996],
          [-122.142496, 37.847268],
          [-122.14248599999999, 37.847263],
          [-122.142406, 37.846764],
          [-122.14238399999999, 37.846636],
          [-122.14237299999999, 37.846568],
          [-122.14209, 37.846319],
          [-122.142046, 37.84625],
          [-122.142015, 37.846025999999995],
          [-122.141995, 37.845878],
          [-122.141937, 37.845818],
          [-122.141885, 37.845765],
          [-122.141848, 37.845645999999995],
          [-122.141781, 37.845428999999996],
          [-122.141682, 37.845451],
          [-122.141606, 37.845467],
          [-122.14143, 37.845329],
          [-122.141208, 37.845152999999996],
          [-122.140981, 37.844975],
          [-122.140717, 37.844767],
          [-122.140599, 37.84459],
          [-122.14056099999999, 37.844415999999995],
          [-122.140535, 37.844297],
          [-122.140523, 37.844245],
          [-122.140556, 37.844133],
          [-122.14057199999999, 37.84408],
          [-122.140576, 37.844065],
          [-122.140581, 37.844046999999996],
          [-122.140726, 37.843759],
          [-122.140743, 37.843736],
          [-122.140875, 37.843562],
          [-122.140918, 37.843505],
          [-122.141047, 37.843402999999995],
          [-122.141218, 37.843422],
          [-122.142112, 37.843520999999996],
          [-122.14188899999999, 37.843252],
          [-122.141671, 37.843128],
          [-122.14153, 37.843047999999996],
          [-122.141437, 37.842996],
          [-122.141735, 37.842839],
          [-122.141728, 37.842793],
          [-122.141717, 37.84271],
          [-122.141693, 37.842529],
          [-122.141735, 37.842475],
          [-122.141876, 37.842295],
          [-122.142235, 37.841837999999996],
          [-122.142536, 37.841456],
          [-122.142606, 37.841367999999996],
          [-122.142702, 37.841418999999995],
          [-122.14299, 37.841575],
          [-122.143086, 37.841626999999995],
          [-122.143524, 37.841864],
          [-122.143697, 37.841660999999995],
          [-122.143936, 37.84138],
          [-122.144654, 37.840537999999995],
          [-122.14487199999999, 37.840284],
          [-122.144894, 37.840257],
          [-122.145139, 37.839959],
          [-122.145877, 37.839064],
          [-122.14604, 37.838867],
          [-122.146123, 37.838767],
          [-122.146962, 37.837499],
          [-122.14702299999999, 37.837522],
          [-122.147231, 37.837627],
          [-122.14739, 37.837692],
          [-122.147558, 37.83774],
          [-122.147674, 37.837762],
          [-122.14785599999999, 37.837784],
          [-122.147994, 37.837801],
          [-122.148312, 37.837851],
          [-122.14851999999999, 37.837888],
          [-122.149191, 37.837911999999996],
          [-122.149271, 37.837924],
          [-122.149385, 37.837958],
          [-122.149456, 37.83799],
          [-122.14955, 37.838052],
          [-122.149699, 37.838192],
          [-122.14980800000001, 37.838271999999996],
          [-122.149931, 37.838339],
          [-122.150063, 37.838392999999996],
          [-122.150204, 37.838432],
          [-122.150349, 37.838454999999996],
          [-122.150696, 37.83847],
          [-122.150806, 37.838485],
          [-122.150913, 37.838512],
          [-122.15098, 37.838536],
          [-122.15203, 37.839006999999995],
          [-122.15217200000001, 37.839054],
          [-122.15232, 37.839088],
          [-122.152631, 37.839147],
          [-122.152686, 37.839158],
          [-122.15281, 37.839197999999996],
          [-122.152925, 37.839253],
          [-122.153028, 37.839321],
          [-122.153104, 37.839389],
          [-122.153955, 37.840159],
          [-122.155145, 37.840163],
          [-122.157373, 37.840171999999995],
          [-122.158715, 37.840178],
          [-122.159189, 37.84018],
          [-122.159906, 37.840182999999996],
          [-122.160191, 37.840188999999995],
          [-122.161048, 37.840207],
          [-122.161334, 37.840213],
          [-122.164462, 37.840315],
          [-122.16549499999999, 37.840348999999996],
          [-122.169576, 37.840482],
          [-122.169658, 37.840519],
          [-122.169909, 37.84066],
          [-122.170087, 37.840773999999996],
          [-122.170236, 37.840899],
          [-122.170393, 37.841071],
          [-122.170442, 37.841128],
          [-122.170559, 37.841265],
          [-122.170693, 37.841391],
          [-122.170907, 37.841469],
          [-122.171251, 37.841511],
          [-122.171882, 37.841646],
          [-122.172162, 37.841741],
          [-122.172432, 37.841879],
          [-122.172429, 37.84193],
          [-122.172277, 37.845057],
          [-122.17227, 37.845409],
          [-122.172253, 37.846464999999995],
          [-122.172247, 37.846818],
          [-122.17226099999999, 37.846893],
          [-122.174896, 37.846907],
          [-122.174777, 37.850549],
          [-122.174886, 37.850507],
          [-122.179954, 37.850479],
          [-122.179615, 37.850823],
          [-122.17957799999999, 37.850930999999996],
          [-122.17958999999999, 37.851043],
          [-122.185703, 37.85105],
          [-122.188597, 37.851054],
          [-122.188976, 37.851054999999995],
          [-122.188969, 37.850435],
          [-122.19203, 37.85038],
          [-122.19203, 37.849596999999996],
          [-122.193977, 37.849568999999995],
          [-122.193977, 37.850463999999995],
          [-122.195914, 37.850477999999995],
          [-122.20230699999999, 37.850457],
          [-122.202657, 37.85048],
          [-122.2031, 37.851192],
          [-122.204094, 37.851386999999995],
          [-122.204801, 37.851414999999996],
          [-122.206954, 37.851464],
          [-122.20706799999999, 37.851523],
          [-122.207169, 37.851534],
          [-122.207255, 37.851548],
          [-122.207335, 37.851569],
          [-122.207413, 37.851592],
          [-122.207464, 37.851617],
          [-122.207578, 37.851670999999996],
          [-122.207647, 37.851704],
          [-122.207748, 37.851738],
          [-122.207812, 37.851749],
          [-122.207869, 37.851749999999996],
          [-122.20794599999999, 37.851751],
          [-122.208031, 37.851752999999995],
          [-122.208096, 37.851749999999996],
          [-122.20822, 37.851732999999996],
          [-122.208308, 37.851718999999996],
          [-122.208486, 37.85169],
          [-122.208898, 37.851941],
          [-122.209835, 37.85304],
          [-122.210875, 37.854226],
          [-122.21081, 37.854335],
          [-122.210408, 37.855064999999996],
          [-122.211586, 37.856359999999995],
          [-122.211854, 37.856801],
          [-122.211862, 37.85682],
          [-122.211885, 37.85687],
          [-122.21189, 37.85688],
          [-122.211902, 37.8569],
          [-122.211907, 37.856909],
          [-122.211926, 37.856936999999995],
          [-122.211933, 37.856946],
          [-122.212012, 37.857012999999995],
          [-122.21238, 37.857234],
          [-122.212814, 37.857431],
          [-122.212889, 37.857607],
          [-122.213196, 37.857600999999995],
          [-122.213297, 37.857693],
          [-122.213495, 37.857859],
          [-122.213559, 37.857912],
          [-122.213389, 37.858309],
          [-122.213756, 37.858678],
          [-122.215266, 37.860192],
          [-122.217024, 37.861956],
          [-122.218872, 37.863800999999995],
          [-122.219826, 37.864771],
          [-122.221148, 37.865348999999995],
          [-122.21918099999999, 37.867225999999995],
          [-122.21799899999999, 37.867815],
          [-122.217897, 37.867866],
          [-122.217768, 37.867954999999995],
          [-122.217272, 37.868297],
          [-122.217241, 37.868317999999995],
          [-122.21709899999999, 37.868415999999996],
          [-122.216769, 37.868576999999995],
          [-122.216276, 37.868822],
          [-122.216372, 37.869074999999995],
          [-122.21655, 37.869546],
          [-122.217489, 37.871826],
          [-122.21764, 37.87225],
          [-122.21849499999999, 37.872733],
          [-122.219804, 37.873495],
          [-122.22076200000001, 37.874772],
          [-122.221414, 37.87564],
          [-122.222368, 37.876905],
          [-122.222408, 37.876957999999995],
          [-122.22362, 37.878566],
          [-122.225101, 37.878904999999996],
          [-122.225567, 37.879124999999995],
          [-122.22577799999999, 37.879225],
          [-122.226832, 37.87965],
          [-122.227116, 37.879764],
          [-122.226567, 37.879633],
          [-122.22644700000001, 37.879612],
          [-122.226264, 37.879594],
          [-122.22608, 37.87959],
          [-122.226019, 37.879593],
          [-122.225876, 37.879619999999996],
          [-122.225738, 37.879661],
          [-122.225607, 37.879715],
          [-122.224759, 37.880153],
          [-122.224659, 37.880210999999996],
          [-122.224497, 37.880285],
          [-122.224325, 37.880341],
          [-122.22414599999999, 37.880379999999995],
          [-122.224023, 37.880396],
          [-122.223961, 37.8804],
          [-122.22381899999999, 37.880393],
          [-122.22368, 37.880369],
          [-122.22359, 37.880345999999996],
          [-122.223461, 37.880297999999996],
          [-122.222095, 37.879733],
          [-122.221954, 37.879678999999996],
          [-122.221825, 37.879611],
          [-122.221746, 37.879557],
          [-122.222156, 37.881453],
          [-122.222228, 37.881653],
          [-122.222285, 37.881837999999995],
          [-122.222283, 37.881933],
          [-122.222275, 37.882011],
          [-122.222195, 37.882079999999995],
          [-122.222104, 37.882095],
          [-122.222016, 37.882070999999996],
          [-122.221913, 37.881968],
          [-122.221795, 37.881788],
          [-122.221632, 37.881658],
          [-122.221422, 37.88153],
          [-122.221308, 37.881434999999996],
          [-122.22111100000001, 37.881192],
          [-122.221068, 37.881088999999996],
          [-122.221053, 37.881011],
          [-122.221022, 37.880891999999996],
          [-122.220995, 37.880741],
          [-122.220863, 37.880677999999996],
          [-122.220785, 37.880683],
          [-122.220721, 37.880725999999996],
          [-122.220685, 37.880792],
          [-122.220709, 37.880967],
          [-122.220744, 37.881127],
          [-122.220853, 37.881268],
          [-122.22091, 37.881372999999996],
          [-122.220979, 37.881518],
          [-122.221088, 37.881656],
          [-122.221209, 37.881743],
          [-122.221283, 37.881817],
          [-122.221357, 37.881938],
          [-122.221383, 37.882016],
          [-122.221332, 37.882222999999996],
          [-122.221205, 37.882569],
          [-122.22112, 37.882709999999996],
          [-122.22126399999999, 37.883537],
          [-122.221408, 37.883735],
          [-122.221538, 37.883872],
          [-122.222009, 37.884288],
          [-122.22236, 37.884597],
          [-122.222375, 37.884603999999996],
          [-122.222393, 37.884613],
          [-122.222422, 37.884623999999995],
          [-122.222439, 37.88463],
          [-122.222461, 37.884637999999995],
          [-122.222529, 37.884662999999996],
          [-122.22255200000001, 37.884671999999995],
          [-122.222707, 37.88473],
          [-122.222827, 37.884755],
          [-122.222963, 37.884796],
          [-122.223034, 37.884823],
          [-122.22313, 37.884867],
          [-122.223257, 37.88489],
          [-122.223529, 37.884955999999995],
          [-122.223601, 37.884979],
          [-122.223872, 37.885068],
          [-122.223997, 37.885141],
          [-122.22403800000001, 37.885182],
          [-122.224062, 37.885234],
          [-122.224105, 37.885304],
          [-122.224127, 37.88537],
          [-122.224125, 37.88543],
          [-122.224104, 37.885484],
          [-122.224098, 37.885526999999996],
          [-122.224036, 37.885709],
          [-122.223985, 37.885762],
          [-122.223972, 37.885833],
          [-122.223912, 37.886027999999996],
          [-122.223905, 37.886095999999995],
          [-122.223888, 37.886167],
          [-122.223894, 37.886285],
          [-122.223929, 37.886443],
          [-122.223996, 37.886599],
          [-122.224081, 37.886737],
          [-122.224107, 37.886789],
          [-122.224204, 37.88688],
          [-122.22424, 37.886936],
          [-122.224266, 37.886997],
          [-122.224336, 37.887115],
          [-122.224352, 37.887189],
          [-122.22443200000001, 37.887307],
          [-122.224523, 37.887429],
          [-122.224723, 37.887661],
          [-122.224756, 37.887721],
          [-122.224778, 37.887783],
          [-122.224793, 37.88785],
          [-122.224791, 37.887913],
          [-122.224796, 37.887974],
          [-122.224777, 37.888033],
          [-122.22474700000001, 37.888098],
          [-122.224705, 37.888158],
          [-122.22464, 37.888211999999996],
          [-122.224586, 37.888268],
          [-122.224457, 37.888369999999995],
          [-122.22434200000001, 37.888483],
          [-122.2243, 37.888545],
          [-122.224277, 37.888613],
          [-122.224284, 37.888683],
          [-122.22431, 37.888753],
          [-122.224351, 37.888818],
          [-122.224518, 37.888995],
          [-122.224554, 37.889033999999995],
          [-122.224616, 37.889088],
          [-122.224984, 37.88972],
          [-122.225885, 37.890403],
          [-122.225838, 37.890426999999995],
          [-122.225557, 37.890572],
          [-122.227231, 37.891843],
          [-122.221164, 37.896356],
          [-122.219392, 37.897693],
          [-122.219367, 37.897677],
          [-122.21932799999999, 37.89767],
          [-122.219288, 37.897669],
          [-122.21923, 37.897681],
          [-122.219179, 37.897707],
          [-122.219152, 37.897731],
          [-122.219052, 37.897878999999996],
          [-122.21896699999999, 37.898033999999996],
          [-122.21885499999999, 37.898196999999996],
          [-122.218772, 37.898303],
          [-122.218634, 37.898454],
          [-122.218554, 37.89852],
          [-122.218462, 37.898575],
          [-122.218361, 37.898619],
          [-122.218285, 37.898643],
          [-122.218232, 37.898675],
          [-122.218196, 37.898719],
          [-122.21813399999999, 37.89881],
          [-122.218058, 37.898908],
          [-122.21798799999999, 37.898967],
          [-122.217905, 37.899013],
          [-122.217812, 37.899045],
          [-122.21770599999999, 37.899068],
          [-122.217589, 37.899108999999996],
          [-122.217484, 37.899166],
          [-122.217393, 37.899237],
          [-122.217355, 37.899294999999995],
          [-122.217318, 37.899389],
          [-122.217304, 37.899487],
          [-122.217309, 37.8996],
          [-122.217297, 37.899741999999996],
          [-122.217264, 37.899892],
          [-122.21724, 37.899946],
          [-122.217206, 37.899996],
          [-122.217138, 37.900062],
          [-122.217084, 37.900098],
          [-122.21699100000001, 37.90014],
          [-122.216923, 37.900158],
          [-122.216662, 37.900203],
          [-122.216486, 37.900222],
          [-122.216397, 37.900228],
          [-122.216257, 37.900251],
          [-122.216044, 37.90027],
          [-122.215901, 37.900273],
          [-122.21424999999999, 37.901499],
          [-122.214704, 37.901942],
          [-122.214914, 37.902152],
          [-122.215105, 37.902352],
          [-122.215277, 37.902511],
          [-122.215312, 37.902543],
          [-122.215535, 37.902722],
          [-122.215857, 37.902954],
          [-122.216121, 37.903135999999996],
          [-122.216436, 37.90333],
          [-122.216782, 37.903518],
          [-122.217082, 37.903662],
          [-122.21748, 37.903800000000004],
          [-122.217885, 37.903923999999996],
          [-122.21816, 37.903997],
          [-122.218437, 37.904064],
          [-122.218791, 37.904165],
          [-122.221308, 37.90492],
          [-122.221826, 37.905069],
          [-122.222175, 37.905156999999996],
          [-122.222342, 37.905187999999995],
          [-122.22251, 37.905211],
          [-122.222765, 37.905232],
          [-122.223022, 37.905235],
          [-122.223547, 37.905214],
          [-122.22400999999999, 37.905181],
          [-122.224313, 37.905161],
          [-122.224678, 37.905145],
          [-122.225043, 37.905142],
          [-122.22537299999999, 37.905153999999996],
          [-122.225628, 37.905181999999996],
          [-122.22588, 37.905226],
          [-122.226126, 37.905286],
          [-122.22636299999999, 37.905359],
          [-122.226587, 37.905440999999996],
          [-122.226803, 37.905535],
          [-122.227011, 37.905642],
          [-122.227394, 37.905893],
          [-122.22767, 37.906093],
          [-122.230839, 37.908425],
          [-122.231796, 37.909123],
          [-122.232575, 37.909701999999996],
          [-122.23270099999999, 37.909805999999996],
          [-122.232876, 37.909971],
          [-122.233037, 37.910145],
          [-122.233175, 37.910320999999996],
          [-122.233258, 37.910444],
          [-122.23336499999999, 37.910633],
          [-122.233453, 37.910829],
          [-122.233522, 37.911029],
          [-122.23357, 37.911232999999996],
          [-122.233729, 37.912251],
          [-122.233915, 37.91334],
          [-122.233993, 37.913768],
          [-122.234035, 37.913907],
          [-122.234114, 37.914113],
          [-122.234199, 37.914283999999995],
          [-122.233959, 37.914411],
          [-122.233907, 37.914511999999995],
          [-122.233907, 37.914683],
          [-122.233966, 37.914911],
          [-122.234048, 37.915153],
          [-122.23420899999999, 37.915451],
          [-122.23423, 37.915558],
          [-122.23424, 37.915605],
          [-122.234318, 37.915856999999995],
          [-122.234529, 37.916405],
          [-122.23455, 37.916508],
        ],
      ],
    },
  },
  {
    id: "886",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94566",
      GEOID10: "94566",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 50827025,
      AWATER10: 385752,
      INTPTLAT10: "+37.6458237",
      INTPTLON10: "-121.8598680",
      Name: "94566",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-121.913669, 37.632732],
          [-121.913423, 37.632737999999996],
          [-121.913071, 37.632746999999995],
          [-121.91268600000001, 37.632756],
          [-121.91260000000001, 37.632757999999995],
          [-121.91254599999999, 37.632756],
          [-121.912442, 37.632759],
          [-121.912314, 37.63267],
          [-121.91136900000001, 37.632632],
          [-121.911024, 37.632619],
          [-121.907157, 37.632478],
          [-121.90568, 37.632433999999996],
          [-121.902807, 37.634116999999996],
          [-121.902799, 37.634183],
          [-121.902762, 37.63424],
          [-121.902663, 37.634307],
          [-121.90252699999999, 37.634388],
          [-121.902475, 37.634422],
          [-121.902398, 37.634462],
          [-121.902337, 37.634479],
          [-121.902277, 37.634488],
          [-121.90218899999999, 37.634482],
          [-121.897288, 37.637358],
          [-121.897142, 37.637443999999995],
          [-121.897144, 37.637547999999995],
          [-121.89713499999999, 37.641754999999996],
          [-121.89707899999999, 37.640992],
          [-121.89428, 37.639016],
          [-121.893922, 37.6393],
          [-121.893818, 37.639381],
          [-121.893751, 37.639435],
          [-121.893721, 37.639458999999995],
          [-121.893142, 37.639039],
          [-121.892327, 37.638507],
          [-121.891757, 37.638107999999995],
          [-121.891625, 37.638016],
          [-121.891535, 37.637935999999996],
          [-121.891389, 37.637783999999996],
          [-121.891238, 37.637572999999996],
          [-121.891114, 37.637361999999996],
          [-121.89096, 37.637408],
          [-121.8905, 37.637547999999995],
          [-121.89034699999999, 37.637595],
          [-121.890287, 37.637613],
          [-121.889996, 37.6377],
          [-121.889304, 37.63791],
          [-121.888947, 37.638022],
          [-121.888599, 37.638132999999996],
          [-121.888576, 37.63814],
          [-121.888214, 37.638239],
          [-121.887059, 37.638557999999996],
          [-121.886674, 37.638664999999996],
          [-121.886635, 37.638709999999996],
          [-121.886591, 37.63875],
          [-121.886567, 37.638771999999996],
          [-121.886494, 37.638826],
          [-121.886344, 37.638923999999996],
          [-121.886296, 37.638949],
          [-121.886192, 37.639007],
          [-121.886057, 37.639089],
          [-121.885983, 37.639134],
          [-121.885919, 37.639171999999995],
          [-121.88576, 37.639268],
          [-121.88562999999999, 37.639334],
          [-121.88479699999999, 37.639755],
          [-121.884686, 37.639812],
          [-121.884581, 37.639865],
          [-121.884497, 37.639908],
          [-121.884418, 37.639949],
          [-121.884451, 37.639993],
          [-121.884529, 37.640094999999995],
          [-121.88466, 37.640353999999995],
          [-121.884734, 37.640488],
          [-121.885012, 37.640916],
          [-121.88514, 37.641090999999996],
          [-121.88531, 37.641301999999996],
          [-121.885487, 37.641498],
          [-121.885656, 37.641667999999996],
          [-121.886534, 37.642492],
          [-121.887125, 37.643049999999995],
          [-121.888318, 37.644206],
          [-121.888329, 37.644217],
          [-121.88835, 37.644236],
          [-121.888358, 37.644244],
          [-121.888383, 37.644268],
          [-121.888392, 37.644276999999995],
          [-121.888461, 37.644343],
          [-121.889144, 37.644993],
          [-121.890753, 37.646544999999996],
          [-121.891299, 37.6471],
          [-121.891331, 37.647133],
          [-121.891429, 37.647231999999995],
          [-121.891463, 37.647264],
          [-121.891491, 37.64729],
          [-121.891576, 37.647371],
          [-121.891605, 37.647397999999995],
          [-121.89177, 37.647554],
          [-121.89203, 37.647799],
          [-121.892266, 37.648025],
          [-121.892431, 37.648182999999996],
          [-121.89258, 37.648005999999995],
          [-121.892636, 37.647937999999996],
          [-121.89289, 37.647635],
          [-121.893047, 37.647448],
          [-121.893273, 37.647221],
          [-121.8935, 37.646996],
          [-121.893575, 37.646949],
          [-121.893608, 37.646907],
          [-121.893874, 37.646578],
          [-121.893889, 37.646561],
          [-121.893952, 37.64646],
          [-121.89402, 37.646370999999995],
          [-121.894052, 37.646328],
          [-121.894357, 37.645927],
          [-121.894404, 37.645865],
          [-121.894451, 37.645803],
          [-121.894634, 37.645548999999995],
          [-121.894716, 37.645437],
          [-121.895066, 37.644869],
          [-121.89514, 37.644756],
          [-121.895314, 37.644496],
          [-121.895422, 37.644334],
          [-121.895504, 37.644239],
          [-121.895551, 37.644197],
          [-121.895571, 37.644180999999996],
          [-121.89564, 37.644138],
          [-121.895748, 37.644090999999996],
          [-121.895851, 37.644061],
          [-121.89595800000001, 37.644042],
          [-121.896067, 37.644036],
          [-121.896176, 37.644041],
          [-121.89627, 37.644056],
          [-121.896398, 37.644092],
          [-121.896506, 37.64414],
          [-121.89657199999999, 37.644179],
          [-121.896626, 37.644219],
          [-121.896642, 37.644231],
          [-121.89671899999999, 37.644307],
          [-121.89678599999999, 37.644402],
          [-121.896853, 37.644525],
          [-121.897059, 37.644904],
          [-121.897571, 37.645845],
          [-121.897622, 37.645973],
          [-121.897643, 37.646059],
          [-121.897654, 37.646105],
          [-121.897665, 37.64622],
          [-121.897661, 37.646335],
          [-121.897639, 37.646457999999996],
          [-121.897634, 37.646488],
          [-121.897609, 37.646636],
          [-121.897577, 37.646833],
          [-121.897568, 37.646929],
          [-121.89757399999999, 37.647062999999996],
          [-121.897577, 37.647082],
          [-121.897605, 37.64723],
          [-121.897613, 37.647267],
          [-121.897632, 37.647354],
          [-121.89764199999999, 37.64738],
          [-121.897656, 37.647417],
          [-121.897674, 37.647464],
          [-121.897681, 37.647482],
          [-121.897707, 37.647531],
          [-121.897738, 37.647588],
          [-121.897746, 37.647600000000004],
          [-121.89777699999999, 37.647642],
          [-121.897815, 37.647695],
          [-121.89782199999999, 37.647704],
          [-121.897941, 37.647847],
          [-121.897984, 37.647898],
          [-121.898113, 37.648030999999996],
          [-121.89850200000001, 37.64843],
          [-121.89863199999999, 37.648562999999996],
          [-121.898898, 37.648861],
          [-121.898913, 37.648877],
          [-121.89928499999999, 37.649331],
          [-121.899648, 37.649797],
          [-121.899895, 37.650113],
          [-121.900108, 37.650386],
          [-121.900496, 37.650883],
          [-121.900592, 37.651024],
          [-121.900655, 37.65115],
          [-121.900684, 37.651244],
          [-121.900705, 37.651308],
          [-121.900717, 37.651404],
          [-121.900718, 37.651587],
          [-121.900714, 37.651717999999995],
          [-121.900709, 37.651919],
          [-121.900724, 37.652038999999995],
          [-121.900743, 37.652108],
          [-121.900766, 37.652192],
          [-121.90078199999999, 37.652234],
          [-121.900854, 37.65236],
          [-121.900892, 37.652408],
          [-121.900934, 37.652462],
          [-121.90102999999999, 37.652556],
          [-121.901122, 37.652629],
          [-121.90134, 37.652769],
          [-121.901373, 37.652794],
          [-121.90142399999999, 37.652832],
          [-121.901532, 37.652927],
          [-121.901831, 37.653222],
          [-121.902062, 37.65345],
          [-121.902079, 37.653481],
          [-121.902078, 37.653513],
          [-121.902062, 37.653549999999996],
          [-121.902047, 37.653563999999996],
          [-121.901757, 37.653743],
          [-121.901455, 37.653917],
          [-121.90134499999999, 37.653971999999996],
          [-121.901046, 37.654126],
          [-121.900975, 37.654171999999996],
          [-121.90117599999999, 37.654499],
          [-121.901234, 37.654568],
          [-121.901291, 37.654621999999996],
          [-121.90138999999999, 37.654693],
          [-121.901463, 37.654733],
          [-121.901581, 37.654781],
          [-121.901707, 37.654814],
          [-121.903341, 37.655083],
          [-121.903485, 37.655125999999996],
          [-121.903618, 37.655189],
          [-121.903724, 37.65526],
          [-121.903786, 37.655314],
          [-121.903849, 37.655384],
          [-121.904054, 37.655651],
          [-121.90415999999999, 37.655787],
          [-121.904188, 37.655819],
          [-121.905112, 37.655367999999996],
          [-121.905118, 37.655401999999995],
          [-121.905325, 37.656265],
          [-121.905373, 37.65659],
          [-121.905407, 37.656814],
          [-121.905442, 37.656935],
          [-121.905704, 37.657593999999996],
          [-121.905766, 37.657809],
          [-121.905952, 37.658259],
          [-121.906125, 37.658615999999995],
          [-121.906222, 37.659012],
          [-121.906477, 37.659895999999996],
          [-121.90659099999999, 37.660123999999996],
          [-121.906815, 37.660571999999995],
          [-121.906843, 37.660682],
          [-121.90687, 37.660917999999995],
          [-121.90673699999999, 37.661207],
          [-121.90670399999999, 37.661280999999995],
          [-121.906633, 37.66138],
          [-121.906504, 37.661561],
          [-121.906473, 37.661597],
          [-121.90641099999999, 37.66167],
          [-121.906379, 37.661708999999995],
          [-121.906326, 37.661758],
          [-121.906357, 37.661794],
          [-121.906466, 37.661943],
          [-121.906879, 37.662504999999996],
          [-121.906907, 37.662543],
          [-121.907013, 37.662696],
          [-121.90708599999999, 37.662801],
          [-121.90729, 37.663095999999996],
          [-121.907306, 37.66312],
          [-121.907377, 37.663228],
          [-121.90746899999999, 37.663368],
          [-121.907544, 37.663489],
          [-121.907704, 37.663745],
          [-121.908014, 37.664293],
          [-121.908078, 37.664406],
          [-121.908162, 37.664567],
          [-121.908269, 37.664773],
          [-121.908281, 37.664795999999996],
          [-121.90847099999999, 37.665169],
          [-121.908621, 37.665493999999995],
          [-121.90872999999999, 37.665729999999996],
          [-121.908925, 37.666216],
          [-121.90911, 37.666675999999995],
          [-121.90937199999999, 37.667318],
          [-121.909508, 37.667676],
          [-121.909695, 37.668166],
          [-121.909705, 37.668192999999995],
          [-121.909722, 37.668237999999995],
          [-121.909737, 37.668275],
          [-121.90974800000001, 37.668303],
          [-121.909794, 37.668419],
          [-121.909876, 37.66862],
          [-121.90993499999999, 37.668768],
          [-121.909982, 37.668884999999996],
          [-121.909997, 37.668923],
          [-121.910054, 37.669063],
          [-121.910127, 37.669242],
          [-121.910243, 37.66955],
          [-121.910262, 37.669602999999995],
          [-121.91032899999999, 37.669784],
          [-121.91035, 37.669838999999996],
          [-121.91037, 37.669891],
          [-121.910439, 37.670065],
          [-121.910461, 37.670121],
          [-121.91049699999999, 37.670214],
          [-121.91054, 37.670322],
          [-121.91056499999999, 37.670387],
          [-121.910612, 37.670504],
          [-121.910642, 37.670583],
          [-121.910668, 37.670649],
          [-121.910736, 37.670826],
          [-121.91094, 37.671357],
          [-121.910954, 37.671392999999995],
          [-121.91101, 37.671534],
          [-121.910876, 37.671566],
          [-121.91047499999999, 37.671665],
          [-121.910342, 37.671698],
          [-121.91032799999999, 37.671759],
          [-121.910286, 37.671853999999996],
          [-121.91026600000001, 37.671901],
          [-121.910225, 37.671945],
          [-121.909893, 37.672115999999995],
          [-121.90988, 37.672121],
          [-121.909727, 37.672185999999996],
          [-121.909549, 37.672261],
          [-121.909016, 37.672488],
          [-121.908839, 37.672564],
          [-121.908655, 37.672641999999996],
          [-121.908628, 37.672654],
          [-121.9081, 37.672869999999996],
          [-121.907916, 37.672945999999996],
          [-121.907734, 37.67302],
          [-121.907531, 37.673103],
          [-121.90718799999999, 37.673244],
          [-121.907007, 37.673319],
          [-121.906238, 37.673634],
          [-121.903932, 37.674580999999996],
          [-121.903164, 37.674897],
          [-121.902841, 37.675028999999995],
          [-121.901873, 37.675426],
          [-121.90172799999999, 37.675486],
          [-121.90155, 37.675557999999995],
          [-121.900916, 37.675815],
          [-121.90045599999999, 37.676002],
          [-121.899016, 37.676589],
          [-121.898604, 37.676756999999995],
          [-121.898586, 37.676764999999996],
          [-121.898383, 37.676846999999995],
          [-121.89842999999999, 37.676876],
          [-121.8987, 37.677048],
          [-121.89913, 37.677324],
          [-121.89965, 37.677655],
          [-121.899854, 37.677786],
          [-121.899755, 37.677884],
          [-121.899658, 37.677982],
          [-121.899599, 37.678039999999996],
          [-121.899013, 37.678643],
          [-121.898842, 37.678829],
          [-121.898772, 37.678917999999996],
          [-121.8987, 37.679023],
          [-121.898532, 37.679317],
          [-121.898432, 37.679494],
          [-121.89838, 37.67957],
          [-121.898305, 37.679662],
          [-121.898156, 37.679809],
          [-121.898084, 37.679867],
          [-121.898021, 37.679826],
          [-121.89796, 37.679805],
          [-121.897903, 37.679791],
          [-121.89785499999999, 37.679784999999995],
          [-121.897836, 37.679784],
          [-121.897781, 37.679783],
          [-121.897708, 37.679792],
          [-121.897659, 37.679811],
          [-121.897584, 37.679846999999995],
          [-121.897537, 37.679876],
          [-121.897375, 37.680009],
          [-121.897287, 37.680065],
          [-121.897221, 37.6801],
          [-121.896892, 37.68023],
          [-121.89670699999999, 37.680305],
          [-121.896556, 37.680358],
          [-121.89647099999999, 37.680388],
          [-121.896079, 37.680549],
          [-121.89531, 37.680889],
          [-121.895175, 37.680944],
          [-121.89491, 37.681066],
          [-121.894068, 37.681512999999995],
          [-121.89377, 37.68167],
          [-121.89368, 37.681714],
          [-121.893588, 37.681751999999996],
          [-121.893241, 37.681875],
          [-121.892875, 37.682018],
          [-121.89255299999999, 37.682154],
          [-121.892392, 37.682238],
          [-121.892292, 37.682296],
          [-121.89211, 37.682390999999996],
          [-121.891824, 37.682551],
          [-121.891899, 37.68266],
          [-121.891742, 37.682742],
          [-121.891554, 37.682843],
          [-121.891496, 37.682876],
          [-121.891277, 37.682998999999995],
          [-121.89112399999999, 37.683087],
          [-121.890996, 37.683150999999995],
          [-121.89097100000001, 37.683161999999996],
          [-121.890884, 37.683202],
          [-121.890735, 37.683254999999996],
          [-121.890495, 37.68335],
          [-121.890337, 37.683413],
          [-121.890175, 37.683471],
          [-121.890025, 37.683524999999996],
          [-121.889764, 37.683624],
          [-121.889691, 37.683648999999996],
          [-121.88953000000001, 37.683707999999996],
          [-121.889335, 37.683768],
          [-121.889286, 37.683783999999996],
          [-121.88914, 37.683838],
          [-121.888761, 37.683977999999996],
          [-121.88857, 37.68405],
          [-121.888408, 37.684107999999995],
          [-121.888204, 37.684182],
          [-121.888021, 37.684242999999995],
          [-121.887922, 37.684281999999996],
          [-121.887763, 37.684346999999995],
          [-121.887594, 37.684405999999996],
          [-121.887376, 37.684484999999995],
          [-121.88709, 37.684585],
          [-121.886922, 37.684644999999996],
          [-121.886721, 37.684714],
          [-121.886706, 37.68472],
          [-121.886435, 37.684832],
          [-121.886234, 37.684916],
          [-121.88613, 37.684956],
          [-121.886079, 37.684976999999996],
          [-121.885997, 37.685019],
          [-121.88596799999999, 37.685052999999996],
          [-121.885963, 37.685072999999996],
          [-121.88591, 37.685092],
          [-121.885753, 37.685151],
          [-121.885701, 37.685172],
          [-121.886516, 37.686129],
          [-121.886532, 37.686138],
          [-121.88683499999999, 37.686248],
          [-121.886279, 37.686485],
          [-121.885897, 37.686603999999996],
          [-121.885211, 37.686819],
          [-121.885077, 37.686859999999996],
          [-121.884675, 37.686986],
          [-121.884542, 37.687028],
          [-121.884078, 37.687173],
          [-121.883526, 37.687346],
          [-121.883298, 37.687428],
          [-121.882686, 37.687602],
          [-121.88222, 37.687734999999996],
          [-121.881833, 37.687844],
          [-121.881006, 37.688078],
          [-121.880674, 37.688173],
          [-121.88028800000001, 37.688283999999996],
          [-121.880186, 37.688313],
          [-121.879964, 37.688376],
          [-121.878682, 37.688739999999996],
          [-121.878146, 37.688893],
          [-121.87799799999999, 37.688463999999996],
          [-121.877864, 37.688037],
          [-121.877843, 37.687959],
          [-121.877805, 37.687839],
          [-121.877737, 37.687597],
          [-121.877471, 37.686644],
          [-121.877428, 37.686512],
          [-121.87731099999999, 37.686155],
          [-121.877049, 37.686021],
          [-121.876732, 37.685859],
          [-121.876102, 37.685435],
          [-121.876038, 37.68539],
          [-121.875455, 37.684982999999995],
          [-121.874614, 37.684384],
          [-121.873741, 37.683752],
          [-121.873203, 37.683357],
          [-121.872556, 37.682867],
          [-121.872427, 37.682773],
          [-121.872093, 37.682531999999995],
          [-121.871213, 37.681916],
          [-121.871117, 37.681846],
          [-121.870829, 37.681638],
          [-121.870733, 37.681568999999996],
          [-121.870588, 37.681469],
          [-121.870156, 37.681172],
          [-121.870012, 37.681073],
          [-121.869462, 37.680662999999996],
          [-121.868975, 37.6803],
          [-121.868311, 37.679791],
          [-121.867813, 37.679437],
          [-121.867255, 37.679041],
          [-121.867121, 37.678947],
          [-121.86671799999999, 37.678664999999995],
          [-121.866585, 37.678571999999996],
          [-121.866495, 37.678506],
          [-121.866227, 37.678308],
          [-121.866138, 37.678242],
          [-121.86609899999999, 37.678216],
          [-121.865985, 37.678138],
          [-121.865947, 37.678112],
          [-121.865848, 37.678039],
          [-121.865551, 37.67782],
          [-121.865453, 37.677748],
          [-121.864959, 37.677400999999996],
          [-121.864556, 37.677119],
          [-121.863888, 37.676593],
          [-121.863807, 37.67653],
          [-121.863518, 37.676308],
          [-121.863411, 37.676226],
          [-121.86302599999999, 37.675961],
          [-121.86296300000001, 37.675913],
          [-121.862776, 37.67577],
          [-121.862714, 37.675723],
          [-121.86265, 37.675775],
          [-121.862571, 37.675827999999996],
          [-121.862481, 37.675877],
          [-121.862402, 37.675906],
          [-121.862314, 37.67594],
          [-121.862052, 37.675982999999995],
          [-121.861864, 37.676001],
          [-121.861468, 37.676024999999996],
          [-121.86132, 37.676023],
          [-121.861069, 37.676021],
          [-121.860957, 37.676015],
          [-121.859923, 37.675967],
          [-121.859402, 37.675959],
          [-121.858908, 37.675957],
          [-121.858826, 37.675957],
          [-121.858597, 37.675933],
          [-121.858278, 37.67588],
          [-121.858031, 37.675849],
          [-121.857406, 37.675824999999996],
          [-121.854738, 37.675742],
          [-121.853748, 37.675743],
          [-121.852769, 37.675731],
          [-121.85233099999999, 37.675726],
          [-121.851269, 37.675694],
          [-121.85081, 37.675692999999995],
          [-121.850721, 37.675703999999996],
          [-121.85063, 37.675716],
          [-121.850517, 37.675771999999995],
          [-121.850431, 37.675855999999996],
          [-121.846801, 37.675771999999995],
          [-121.846012, 37.676035999999996],
          [-121.843542, 37.676859],
          [-121.838635, 37.678495],
          [-121.837544, 37.678858999999996],
          [-121.837544, 37.678748],
          [-121.83757800000001, 37.673324],
          [-121.83758, 37.673169],
          [-121.837426, 37.673185],
          [-121.836444, 37.673293],
          [-121.836252, 37.673311999999996],
          [-121.835823, 37.673356],
          [-121.834362, 37.673522999999996],
          [-121.833213, 37.673649],
          [-121.83194499999999, 37.673776],
          [-121.830635, 37.673929],
          [-121.829673, 37.674014],
          [-121.829143, 37.674078],
          [-121.828153, 37.674198],
          [-121.826775, 37.674344999999995],
          [-121.82677, 37.67429],
          [-121.826758, 37.674127],
          [-121.826754, 37.674073],
          [-121.82675, 37.674026],
          [-121.82674, 37.673887],
          [-121.826737, 37.673840999999996],
          [-121.825689, 37.67395],
          [-121.825119, 37.674009999999996],
          [-121.823729, 37.674154],
          [-121.823709, 37.674155999999996],
          [-121.822764, 37.674254],
          [-121.822548, 37.674278],
          [-121.822039, 37.674335],
          [-121.821502, 37.674389],
          [-121.821124, 37.674431999999996],
          [-121.820499, 37.674504],
          [-121.819991, 37.674558],
          [-121.819614, 37.674599],
          [-121.819614, 37.674482],
          [-121.819613, 37.674304],
          [-121.822644, 37.673971],
          [-121.823731, 37.673853],
          [-121.82424, 37.673798],
          [-121.82480699999999, 37.673741],
          [-121.82522900000001, 37.673677],
          [-121.825523, 37.673598999999996],
          [-121.825653, 37.673562],
          [-121.825828, 37.673521],
          [-121.825932, 37.673508999999996],
          [-121.826678, 37.673383],
          [-121.826705, 37.672802],
          [-121.826705, 37.672775],
          [-121.826753, 37.671279],
          [-121.826867, 37.669745999999996],
          [-121.826854, 37.669567],
          [-121.826846, 37.66944],
          [-121.826817, 37.669008999999996],
          [-121.826844, 37.66879],
          [-121.826869, 37.668721],
          [-121.826908, 37.66862],
          [-121.826947, 37.668518999999996],
          [-121.827021, 37.668423],
          [-121.827067, 37.668346],
          [-121.827089, 37.668198],
          [-121.82708099999999, 37.668033],
          [-121.827083, 37.667618999999995],
          [-121.827072, 37.667562],
          [-121.827037, 37.667501],
          [-121.826995, 37.667452999999995],
          [-121.826806, 37.667285],
          [-121.826531, 37.667069999999995],
          [-121.82602299999999, 37.666729],
          [-121.825366, 37.66632],
          [-121.825257, 37.666242],
          [-121.825117, 37.666121],
          [-121.824449, 37.665315],
          [-121.82436899999999, 37.665189999999996],
          [-121.824269, 37.665047],
          [-121.824161, 37.664881],
          [-121.824084, 37.664732],
          [-121.824016, 37.664578],
          [-121.82396299999999, 37.664442],
          [-121.823917, 37.664304],
          [-121.82386, 37.664153],
          [-121.823849, 37.664123],
          [-121.823802, 37.663934],
          [-121.823771, 37.663812],
          [-121.823733, 37.663737],
          [-121.82369, 37.663698],
          [-121.823634, 37.663671],
          [-121.82348999999999, 37.663615],
          [-121.823171, 37.663526],
          [-121.823061, 37.6635],
          [-121.821345, 37.663267],
          [-121.821056, 37.663242],
          [-121.820064, 37.663117],
          [-121.81958900000001, 37.663047],
          [-121.819586, 37.662040999999995],
          [-121.819583, 37.660849],
          [-121.819583, 37.660835999999996],
          [-121.81958, 37.658902],
          [-121.81958399999999, 37.658806],
          [-121.81959499999999, 37.658518],
          [-121.81960000000001, 37.658423],
          [-121.819261, 37.658401],
          [-121.819013, 37.658341],
          [-121.819005, 37.658324],
          [-121.818902, 37.658308],
          [-121.818826, 37.65828],
          [-121.818791, 37.658252999999995],
          [-121.818625, 37.658170999999996],
          [-121.818342, 37.657967],
          [-121.818162, 37.657813999999995],
          [-121.818017, 37.657709],
          [-121.817844, 37.657605],
          [-121.817388, 37.657407],
          [-121.817354, 37.657385],
          [-121.817105, 37.657325],
          [-121.816932, 37.657319],
          [-121.816739, 37.657363],
          [-121.816552, 37.657391],
          [-121.815965, 37.657351999999996],
          [-121.815743, 37.657325],
          [-121.815591, 37.657281],
          [-121.815405, 37.657209],
          [-121.815225, 37.657115999999995],
          [-121.815073, 37.657088],
          [-121.81502499999999, 37.657088],
          [-121.814976, 37.657105],
          [-121.814804, 37.657094],
          [-121.814741, 37.657077],
          [-121.814548, 37.657012],
          [-121.814502, 37.656985],
          [-121.814479, 37.656984],
          [-121.814471, 37.656967],
          [-121.814285, 37.656847],
          [-121.814044, 37.656659999999995],
          [-121.813947, 37.656560999999996],
          [-121.813822, 37.656402],
          [-121.813636, 37.656203999999995],
          [-121.813567, 37.656165],
          [-121.813504, 37.656149],
          [-121.81344899999999, 37.656144],
          [-121.813401, 37.656149],
          [-121.81318, 37.656203999999995],
          [-121.812924, 37.656242],
          [-121.812772, 37.656209],
          [-121.81271699999999, 37.656182],
          [-121.812668, 37.656144],
          [-121.812655, 37.656116],
          [-121.81262699999999, 37.656000999999996],
          [-121.812606, 37.655797],
          [-121.812585, 37.655699],
          [-121.812565, 37.65566],
          [-121.81253, 37.655622],
          [-121.81219899999999, 37.655358],
          [-121.81214299999999, 37.655325],
          [-121.81183899999999, 37.655221],
          [-121.811611, 37.655166],
          [-121.811521, 37.655127],
          [-121.81092699999999, 37.654945999999995],
          [-121.810727, 37.654814],
          [-121.810671, 37.654759],
          [-121.810609, 37.654731],
          [-121.810568, 37.654686999999996],
          [-121.81016, 37.654379999999996],
          [-121.809918, 37.654236999999995],
          [-121.80964900000001, 37.654044999999996],
          [-121.809352, 37.653897],
          [-121.808536, 37.65349],
          [-121.808301, 37.653456999999996],
          [-121.807997, 37.653428999999996],
          [-121.807831, 37.653396],
          [-121.807521, 37.653352],
          [-121.807306, 37.653358],
          [-121.806961, 37.653385],
          [-121.806774, 37.653462],
          [-121.80652, 37.653549],
          [-121.80634599999999, 37.653608],
          [-121.80623800000001, 37.653645],
          [-121.806083, 37.653698],
          [-121.805864, 37.653793],
          [-121.805833, 37.653807],
          [-121.80582, 37.653814],
          [-121.80573799999999, 37.65383],
          [-121.805653, 37.653828],
          [-121.80565899999999, 37.653805999999996],
          [-121.80569, 37.653745],
          [-121.805698, 37.65351],
          [-121.805702, 37.65343],
          [-121.805689, 37.653118],
          [-121.805686, 37.653008],
          [-121.805674, 37.652526],
          [-121.805673, 37.652483],
          [-121.805671, 37.652421],
          [-121.805668, 37.652318],
          [-121.805666, 37.652198999999996],
          [-121.805711, 37.652141],
          [-121.805741, 37.652102],
          [-121.805708, 37.651554],
          [-121.805675, 37.651013],
          [-121.805673, 37.650967],
          [-121.805643, 37.650484999999996],
          [-121.805587, 37.649543],
          [-121.805547, 37.648873],
          [-121.805244, 37.648227999999996],
          [-121.80470199999999, 37.647203999999995],
          [-121.804542, 37.647044],
          [-121.80394799999999, 37.64645],
          [-121.803507, 37.646009],
          [-121.80311499999999, 37.64568],
          [-121.802735, 37.645458],
          [-121.802616, 37.645354],
          [-121.801568, 37.644448],
          [-121.80142000000001, 37.644318999999996],
          [-121.801276, 37.644188],
          [-121.801135, 37.644054],
          [-121.80099899999999, 37.643917],
          [-121.800866, 37.643778],
          [-121.800737, 37.643637],
          [-121.800613, 37.643493],
          [-121.80049199999999, 37.643347999999996],
          [-121.800376, 37.6432],
          [-121.800264, 37.643049999999995],
          [-121.800157, 37.642897999999995],
          [-121.80005299999999, 37.642744],
          [-121.799954, 37.642587999999996],
          [-121.79986, 37.642430999999995],
          [-121.79977, 37.642271],
          [-121.799593, 37.641929],
          [-121.799511, 37.641756],
          [-121.799435, 37.641580999999995],
          [-121.799364, 37.641405],
          [-121.799298, 37.641227],
          [-121.799238, 37.641048],
          [-121.799182, 37.640868999999995],
          [-121.799132, 37.640688],
          [-121.799087, 37.640505999999995],
          [-121.799048, 37.640324],
          [-121.799013, 37.640141],
          [-121.79901100000001, 37.640125],
          [-121.79898299999999, 37.639948],
          [-121.79896099999999, 37.639770999999996],
          [-121.798943, 37.639593],
          [-121.798922, 37.639171],
          [-121.798921, 37.638985],
          [-121.798926, 37.638799],
          [-121.798937, 37.638613],
          [-121.798953, 37.638427],
          [-121.798974, 37.638242],
          [-121.799001, 37.638056999999996],
          [-121.799033, 37.637872],
          [-121.79907, 37.637689],
          [-121.79910000000001, 37.637583],
          [-121.799135, 37.637478],
          [-121.799174, 37.637374],
          [-121.799219, 37.637271999999996],
          [-121.799269, 37.637170999999995],
          [-121.799323, 37.637071],
          [-121.79911899999999, 37.636918],
          [-121.799219, 37.636765],
          [-121.799325, 37.636643],
          [-121.799438, 37.636545],
          [-121.79957, 37.636437],
          [-121.799832, 37.636255],
          [-121.800134, 37.636084],
          [-121.800528, 37.635827],
          [-121.800635, 37.635751],
          [-121.800791, 37.635629],
          [-121.800823, 37.635598],
          [-121.801013, 37.635422],
          [-121.801207, 37.635205],
          [-121.801267, 37.635138],
          [-121.80135, 37.63503],
          [-121.80142599999999, 37.63493],
          [-121.801511, 37.6348],
          [-121.801583, 37.634679],
          [-121.801673, 37.634507],
          [-121.801771, 37.63429],
          [-121.801797, 37.634233],
          [-121.801839, 37.634094],
          [-121.801875, 37.633956],
          [-121.801879, 37.633932],
          [-121.801903, 37.633811],
          [-121.80193, 37.633573999999996],
          [-121.801938, 37.633399],
          [-121.801938, 37.633378],
          [-121.80195, 37.632875999999996],
          [-121.80195, 37.632835],
          [-121.80195, 37.632763],
          [-121.80194399999999, 37.632714],
          [-121.80194, 37.632674],
          [-121.801864, 37.630807],
          [-121.801882, 37.630414],
          [-121.801936, 37.630002999999995],
          [-121.802097, 37.629466],
          [-121.802274, 37.629176],
          [-121.802483, 37.629208],
          [-121.802656, 37.629098],
          [-121.803023, 37.628737],
          [-121.803108, 37.628644],
          [-121.803263, 37.628474],
          [-121.803297, 37.628437],
          [-121.803411, 37.628325],
          [-121.803566, 37.628203],
          [-121.80366699999999, 37.628132],
          [-121.80380199999999, 37.628046999999995],
          [-121.803845, 37.628021],
          [-121.804003, 37.627936],
          [-121.804032, 37.627921],
          [-121.804422, 37.627734],
          [-121.804561, 37.627662],
          [-121.8048, 37.62752],
          [-121.804919, 37.627441],
          [-121.805166, 37.627262],
          [-121.805487, 37.627035],
          [-121.805871, 37.626765999999996],
          [-121.806438, 37.626387],
          [-121.806586, 37.626293],
          [-121.806789, 37.626172],
          [-121.807227, 37.625938],
          [-121.807423, 37.625851999999995],
          [-121.807532, 37.625813],
          [-121.807645, 37.625765],
          [-121.807815, 37.625675],
          [-121.807917, 37.62561],
          [-121.80797, 37.625565],
          [-121.80806799999999, 37.625468],
          [-121.808132, 37.625378999999995],
          [-121.808199, 37.625216],
          [-121.808289, 37.624856],
          [-121.808365, 37.624708999999996],
          [-121.808476, 37.624593],
          [-121.808533, 37.624522],
          [-121.80861, 37.624446],
          [-121.808715, 37.624359],
          [-121.808823, 37.624233],
          [-121.808888, 37.624131],
          [-121.808953, 37.623996],
          [-121.809005, 37.623844999999996],
          [-121.809069, 37.623579],
          [-121.809125, 37.623353],
          [-121.809271, 37.622783],
          [-121.80932899999999, 37.622572999999996],
          [-121.809427, 37.622049],
          [-121.809463, 37.621885],
          [-121.809493, 37.621925999999995],
          [-121.809586, 37.622051],
          [-121.809617, 37.622093],
          [-121.809906, 37.622503],
          [-121.81070700000001, 37.623639],
          [-121.810823, 37.62364],
          [-121.811326, 37.623644999999996],
          [-121.811402, 37.623644999999996],
          [-121.811543, 37.623644999999996],
          [-121.812295, 37.623643],
          [-121.823082, 37.623626],
          [-121.82504399999999, 37.623622],
          [-121.839873, 37.623598],
          [-121.850836, 37.62358],
          [-121.851752, 37.623579],
          [-121.860671, 37.623564],
          [-121.8662, 37.623554999999996],
          [-121.867789, 37.623553],
          [-121.879919, 37.623532999999995],
          [-121.879883, 37.622369],
          [-121.879883, 37.622057999999996],
          [-121.87979, 37.621715],
          [-121.879572, 37.621216],
          [-121.87848, 37.619158],
          [-121.877017, 37.616246],
          [-121.877305, 37.616178],
          [-121.87776099999999, 37.616073],
          [-121.877991, 37.61602],
          [-121.87808799999999, 37.615998],
          [-121.878159, 37.615981999999995],
          [-121.881547, 37.615198],
          [-121.881844, 37.61513],
          [-121.88199399999999, 37.615015],
          [-121.882092, 37.614951999999995],
          [-121.882285, 37.614854],
          [-121.882612, 37.614939],
          [-121.882818, 37.61499],
          [-121.883125, 37.615058],
          [-121.883535, 37.615111],
          [-121.88376099999999, 37.615113],
          [-121.883927, 37.615033],
          [-121.884215, 37.614987],
          [-121.884357, 37.615086],
          [-121.884417, 37.615234],
          [-121.884312, 37.61538],
          [-121.884166, 37.615508],
          [-121.88410400000001, 37.61559],
          [-121.884037, 37.615835],
          [-121.88385099999999, 37.616012],
          [-121.884012, 37.616243],
          [-121.884298, 37.616361],
          [-121.88454300000001, 37.616396],
          [-121.884913, 37.616465],
          [-121.885117, 37.616565],
          [-121.88528, 37.616566],
          [-121.885505, 37.616634],
          [-121.885771, 37.616685],
          [-121.885998, 37.616704],
          [-121.886224, 37.616738],
          [-121.886388, 37.616755],
          [-121.88659200000001, 37.616873],
          [-121.886794, 37.617053],
          [-121.886854, 37.617219],
          [-121.88691299999999, 37.617383],
          [-121.88696999999999, 37.617627999999996],
          [-121.88690700000001, 37.617742],
          [-121.886946, 37.617922],
          [-121.886759, 37.618068],
          [-121.886696, 37.618165],
          [-121.886732, 37.618411],
          [-121.886771, 37.618624],
          [-121.886646, 37.618721],
          [-121.886439, 37.618817],
          [-121.886417, 37.618930999999996],
          [-121.886416, 37.619029],
          [-121.886331, 37.619175],
          [-121.88637, 37.619355],
          [-121.886388, 37.619503],
          [-121.886529, 37.6197],
          [-121.886691, 37.619848],
          [-121.886855, 37.619883],
          [-121.887163, 37.619918],
          [-121.887532, 37.619921],
          [-121.88781900000001, 37.619924],
          [-121.888088, 37.619895],
          [-121.888252, 37.619896],
          [-121.888378, 37.619872],
          [-121.88850000000001, 37.619752],
          [-121.888653, 37.619713],
          [-121.88887199999999, 37.619641],
          [-121.889018, 37.619527],
          [-121.889102, 37.619398],
          [-121.889146, 37.619251],
          [-121.889168, 37.619136],
          [-121.889231, 37.619023],
          [-121.88909100000001, 37.618809],
          [-121.889133, 37.618711],
          [-121.88913600000001, 37.618514],
          [-121.889179, 37.618401],
          [-121.889202, 37.618221],
          [-121.889225, 37.618091],
          [-121.889207, 37.617911],
          [-121.889146, 37.617844],
          [-121.88906399999999, 37.617779],
          [-121.888943, 37.61768],
          [-121.888842, 37.617596999999996],
          [-121.888781, 37.617498],
          [-121.888741, 37.617432],
          [-121.88874200000001, 37.617334],
          [-121.888743, 37.617269],
          [-121.88878700000001, 37.617121],
          [-121.888892, 37.616993],
          [-121.888955, 37.616862],
          [-121.889018, 37.61678],
          [-121.889061, 37.616634],
          [-121.889105, 37.616471],
          [-121.889085, 37.616357],
          [-121.889029, 37.616078],
          [-121.889073, 37.615866],
          [-121.889012, 37.615735],
          [-121.888995, 37.615505999999996],
          [-121.8891, 37.615424],
          [-121.88924399999999, 37.615376],
          [-121.889429, 37.615314],
          [-121.88951399999999, 37.615151],
          [-121.8897, 37.615071],
          [-121.88974400000001, 37.614923],
          [-121.889766, 37.614843],
          [-121.889748, 37.614695],
          [-121.889721, 37.614594],
          [-121.889689, 37.614433],
          [-121.88967099999999, 37.614286],
          [-121.889552, 37.614022],
          [-121.88953599999999, 37.613777999999996],
          [-121.889517, 37.613631],
          [-121.889457, 37.61345],
          [-121.88946, 37.613302],
          [-121.88927799999999, 37.61304],
          [-121.889219, 37.612925],
          [-121.88922099999999, 37.612745],
          [-121.889243, 37.612631],
          [-121.889287, 37.612517],
          [-121.889432, 37.612452],
          [-121.889556, 37.612372],
          [-121.889637, 37.612389],
          [-121.88969900000001, 37.612438],
          [-121.889842, 37.612505],
          [-121.889986, 37.612522],
          [-121.890109, 37.612556],
          [-121.890211, 37.612607],
          [-121.89053200000001, 37.613101],
          [-121.89061, 37.613412],
          [-121.890729, 37.613577],
          [-121.89093199999999, 37.613791],
          [-121.891033, 37.613923],
          [-121.891256, 37.614137],
          [-121.891583, 37.61427],
          [-121.891685, 37.614354],
          [-121.89180400000001, 37.614633],
          [-121.89194499999999, 37.614782],
          [-121.892046, 37.614962],
          [-121.892146, 37.615127],
          [-121.892102, 37.615241],
          [-121.892101, 37.615387],
          [-121.89215899999999, 37.615601],
          [-121.89226, 37.615716],
          [-121.892423, 37.615832],
          [-121.892586, 37.615916],
          [-121.892811, 37.615983],
          [-121.893036, 37.616082],
          [-121.893487, 37.616153],
          [-121.893631, 37.616171],
          [-121.893876, 37.616189],
          [-121.894143, 37.616273],
          [-121.894328, 37.616308],
          [-121.894552, 37.616358],
          [-121.894717, 37.616361],
          [-121.894881, 37.616411],
          [-121.895044, 37.616478],
          [-121.895227, 37.616544],
          [-121.895494, 37.616662],
          [-121.895657, 37.616729],
          [-121.89587900000001, 37.616976],
          [-121.89599, 37.617115],
          [-121.89609300000001, 37.617052],
          [-121.89627899999999, 37.617005],
          [-121.89640299999999, 37.616957],
          [-121.896508, 37.616892],
          [-121.896633, 37.616763],
          [-121.896758, 37.616583],
          [-121.896761, 37.61642],
          [-121.896661, 37.61624],
          [-121.896539, 37.616074],
          [-121.896502, 37.615912],
          [-121.89666700000001, 37.61575],
          [-121.89687599999999, 37.615539],
          [-121.897043, 37.615426],
          [-121.89723000000001, 37.615232],
          [-121.897553, 37.615030999999995],
          [-121.898132, 37.615899],
          [-121.899063, 37.616349],
          [-121.899458, 37.617064],
          [-121.90007, 37.618172],
          [-121.900146, 37.618638],
          [-121.900818, 37.619102999999996],
          [-121.901967, 37.620236],
          [-121.901816, 37.620298999999996],
          [-121.901333, 37.6205],
          [-121.901631, 37.620974],
          [-121.901871, 37.621424],
          [-121.90290999999999, 37.621476],
          [-121.90308, 37.621485],
          [-121.903335, 37.621468],
          [-121.903388, 37.621525],
          [-121.90371, 37.621871999999996],
          [-121.90406899999999, 37.62226],
          [-121.904297, 37.622506],
          [-121.904132, 37.622538],
          [-121.90335999999999, 37.622689],
          [-121.904998, 37.624102],
          [-121.907297, 37.625675],
          [-121.908423, 37.625944],
          [-121.909481, 37.626197],
          [-121.909546, 37.626521],
          [-121.909897, 37.626672],
          [-121.910207, 37.627306],
          [-121.910269, 37.627432],
          [-121.911019, 37.628319],
          [-121.91136900000001, 37.628732],
          [-121.911698, 37.629098],
          [-121.912248, 37.629861],
          [-121.912721, 37.630973999999995],
          [-121.912822, 37.63126],
          [-121.91296299999999, 37.631656],
          [-121.913163, 37.632218],
          [-121.913266, 37.632321999999995],
          [-121.913346, 37.632404],
          [-121.913588, 37.63265],
          [-121.913669, 37.632732],
        ],
      ],
    },
  },
  {
    id: "888",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94568",
      GEOID10: "94568",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 39683755,
      AWATER10: 0,
      INTPTLAT10: "+37.7152643",
      INTPTLON10: "-121.9148618",
      Name: "94568",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.001277, 37.703728],
          [-121.999234, 37.705479],
          [-121.994514, 37.709528],
          [-121.993107, 37.710733],
          [-121.99167, 37.711967],
          [-121.991496, 37.712140999999995],
          [-121.991108, 37.712531],
          [-121.988479, 37.714832],
          [-121.983345, 37.71933],
          [-121.982707, 37.719881],
          [-121.980579, 37.721719],
          [-121.977937, 37.724004],
          [-121.977303, 37.724548],
          [-121.975403, 37.726183999999996],
          [-121.975286, 37.726284],
          [-121.97477, 37.726729],
          [-121.974309, 37.727087999999995],
          [-121.97292999999999, 37.728168],
          [-121.971263, 37.729555999999995],
          [-121.969366, 37.727475999999996],
          [-121.968462, 37.72659],
          [-121.962452, 37.7207],
          [-121.960271, 37.71874],
          [-121.96003999999999, 37.718531],
          [-121.955109, 37.719718],
          [-121.954604, 37.719839],
          [-121.95375, 37.720045999999996],
          [-121.952007, 37.720464],
          [-121.951198, 37.720665],
          [-121.950351, 37.720873999999995],
          [-121.950005, 37.720962],
          [-121.94945, 37.721105],
          [-121.948755, 37.721277],
          [-121.948205, 37.721413],
          [-121.945823, 37.721984],
          [-121.945022, 37.722353999999996],
          [-121.94476399999999, 37.722423],
          [-121.944183, 37.722581999999996],
          [-121.944075, 37.722612999999996],
          [-121.943994, 37.722636],
          [-121.943967, 37.722643999999995],
          [-121.943933, 37.722654],
          [-121.943889, 37.722666],
          [-121.943738, 37.72271],
          [-121.94369499999999, 37.722722],
          [-121.943419, 37.722767999999995],
          [-121.94259199999999, 37.72291],
          [-121.942498, 37.722926],
          [-121.942383, 37.722946],
          [-121.942319, 37.722966],
          [-121.942189, 37.722998],
          [-121.941798, 37.723096],
          [-121.94166899999999, 37.723129],
          [-121.94157200000001, 37.723147999999995],
          [-121.941285, 37.723205],
          [-121.941189, 37.723225],
          [-121.941169, 37.723228999999996],
          [-121.94095300000001, 37.723273999999996],
          [-121.940522, 37.723394],
          [-121.940331, 37.723444],
          [-121.940195, 37.723484],
          [-121.939551, 37.723631999999995],
          [-121.939269, 37.723701],
          [-121.939191, 37.723729999999996],
          [-121.938735, 37.723842],
          [-121.937066, 37.724250999999995],
          [-121.937003, 37.724247],
          [-121.936514, 37.724368999999996],
          [-121.936158, 37.724467],
          [-121.933754, 37.724996],
          [-121.933499, 37.725153999999996],
          [-121.933434, 37.725170999999996],
          [-121.933263, 37.725215],
          [-121.932788, 37.725347],
          [-121.931962, 37.72558],
          [-121.931715, 37.725621],
          [-121.931669, 37.725629],
          [-121.931533, 37.725659],
          [-121.93129, 37.725713999999996],
          [-121.931277, 37.725716999999996],
          [-121.930986, 37.725798999999995],
          [-121.930744, 37.72586],
          [-121.930507, 37.725918],
          [-121.929799, 37.726095],
          [-121.929564, 37.726154],
          [-121.929372, 37.726078],
          [-121.92928599999999, 37.726084],
          [-121.928642, 37.726171],
          [-121.926976, 37.726566999999996],
          [-121.926726, 37.726627],
          [-121.926477, 37.726687999999996],
          [-121.92623499999999, 37.726746999999996],
          [-121.925823, 37.726848],
          [-121.924697, 37.727115999999995],
          [-121.92451299999999, 37.72716],
          [-121.922641, 37.727616],
          [-121.92236199999999, 37.727683999999996],
          [-121.917374, 37.728896999999996],
          [-121.91647, 37.729113999999996],
          [-121.914499, 37.729586999999995],
          [-121.913568, 37.729828],
          [-121.913497, 37.729839999999996],
          [-121.913243, 37.729887],
          [-121.912688, 37.730000000000004],
          [-121.911147, 37.730318],
          [-121.911021, 37.730329999999995],
          [-121.91057, 37.73038],
          [-121.910459, 37.730393],
          [-121.910394, 37.730405],
          [-121.910201, 37.730446],
          [-121.910138, 37.73046],
          [-121.910084, 37.730472999999996],
          [-121.909921, 37.730514],
          [-121.909868, 37.730528],
          [-121.909782, 37.73055],
          [-121.909671, 37.730578],
          [-121.909525, 37.730616],
          [-121.90944, 37.730639],
          [-121.908445, 37.730896],
          [-121.907245, 37.731207],
          [-121.905462, 37.731669],
          [-121.904721, 37.731863],
          [-121.904467, 37.731927999999996],
          [-121.903958, 37.732048999999996],
          [-121.902553, 37.732385],
          [-121.897418, 37.733612],
          [-121.896811, 37.733758],
          [-121.895449, 37.734083999999996],
          [-121.895267, 37.734128],
          [-121.894897, 37.734215999999996],
          [-121.892231, 37.734842],
          [-121.888274, 37.735772],
          [-121.88570899999999, 37.736374999999995],
          [-121.884236, 37.736723],
          [-121.883366, 37.736928],
          [-121.881567, 37.737328],
          [-121.879961, 37.737724],
          [-121.877425, 37.73835],
          [-121.876706, 37.738527999999995],
          [-121.876051, 37.738689],
          [-121.875145, 37.738913],
          [-121.875125, 37.738918999999996],
          [-121.875056, 37.738928],
          [-121.874917, 37.738963999999996],
          [-121.874495, 37.739073999999995],
          [-121.874449, 37.739087],
          [-121.87440000000001, 37.73909],
          [-121.87434, 37.739090999999995],
          [-121.87385499999999, 37.739217],
          [-121.873542, 37.739317],
          [-121.870979, 37.740026],
          [-121.870733, 37.740086],
          [-121.870719, 37.740089],
          [-121.86370000000001, 37.741793],
          [-121.86001999999999, 37.742688],
          [-121.861074, 37.741904999999996],
          [-121.8615, 37.7415],
          [-121.861879, 37.741467],
          [-121.862077, 37.741400999999996],
          [-121.862637, 37.740676],
          [-121.863148, 37.740263999999996],
          [-121.86426900000001, 37.739934],
          [-121.865011, 37.739636999999995],
          [-121.865621, 37.739291],
          [-121.865693, 37.739214],
          [-121.86593500000001, 37.738960999999996],
          [-121.867748, 37.736834],
          [-121.868325, 37.73652],
          [-121.868244, 37.736354999999996],
          [-121.868147, 37.736112999999996],
          [-121.868095, 37.735954],
          [-121.868028, 37.735786999999995],
          [-121.867948, 37.735532],
          [-121.867913, 37.735423],
          [-121.867883, 37.735327999999996],
          [-121.867864, 37.735267],
          [-121.867849, 37.735217],
          [-121.867847, 37.735205],
          [-121.86782, 37.735082],
          [-121.867812, 37.735003],
          [-121.866624, 37.736505],
          [-121.865222, 37.737120999999995],
          [-121.853971, 37.737739],
          [-121.853715, 37.733288],
          [-121.853703, 37.732065],
          [-121.853638, 37.730979999999995],
          [-121.850653, 37.730959999999996],
          [-121.849943, 37.730956],
          [-121.851339, 37.729939],
          [-121.853121, 37.728642],
          [-121.853568, 37.727533],
          [-121.852762, 37.726495],
          [-121.852345, 37.72671],
          [-121.851986, 37.726895999999996],
          [-121.851562, 37.726096999999996],
          [-121.851366, 37.725728],
          [-121.851445, 37.725499],
          [-121.85156, 37.725170999999996],
          [-121.851737, 37.724835],
          [-121.851851, 37.724621],
          [-121.851761, 37.724357],
          [-121.851652, 37.724222],
          [-121.851279, 37.723939],
          [-121.851049, 37.723752999999995],
          [-121.85101399999999, 37.723725],
          [-121.850983, 37.723690999999995],
          [-121.850698, 37.723385],
          [-121.850658, 37.723332],
          [-121.850382, 37.722958999999996],
          [-121.850076, 37.722643],
          [-121.84978, 37.722429],
          [-121.84962999999999, 37.722333],
          [-121.849555, 37.722283999999995],
          [-121.849555, 37.722045],
          [-121.849869, 37.721447999999995],
          [-121.850261, 37.720701999999996],
          [-121.85031599999999, 37.720670999999996],
          [-121.851073, 37.720647],
          [-121.851258, 37.720639999999996],
          [-121.851942, 37.720639999999996],
          [-121.852216, 37.720639],
          [-121.852416, 37.720639],
          [-121.852447, 37.719896999999996],
          [-121.852451, 37.719788],
          [-121.85254499999999, 37.719215999999996],
          [-121.85250500000001, 37.719024999999995],
          [-121.852456, 37.718787],
          [-121.852371, 37.718377],
          [-121.852255, 37.718363],
          [-121.852058, 37.718348999999996],
          [-121.851924, 37.71824],
          [-121.851716, 37.717985],
          [-121.851583, 37.71767],
          [-121.851558, 37.717394],
          [-121.851354, 37.716997],
          [-121.851272, 37.716772],
          [-121.851035, 37.716481],
          [-121.850887, 37.716274],
          [-121.850904, 37.716119],
          [-121.850923, 37.715866],
          [-121.851018, 37.715615],
          [-121.851361, 37.714957],
          [-121.851518, 37.714622],
          [-121.852141, 37.714029],
          [-121.851821, 37.713370999999995],
          [-121.851761, 37.712952],
          [-121.851784, 37.712455999999996],
          [-121.851794, 37.712351999999996],
          [-121.851867, 37.711645],
          [-121.85197099999999, 37.710918],
          [-121.852029, 37.710428],
          [-121.85190399999999, 37.710179],
          [-121.851873, 37.710063],
          [-121.851831, 37.709955],
          [-121.851781, 37.709869],
          [-121.851715, 37.709769],
          [-121.851625, 37.709662],
          [-121.851436, 37.709475999999995],
          [-121.850892, 37.708939],
          [-121.850809, 37.708852],
          [-121.850732, 37.708782],
          [-121.850641, 37.708684],
          [-121.850558, 37.708586],
          [-121.850498, 37.708501999999996],
          [-121.850412, 37.708273999999996],
          [-121.850399, 37.708180999999996],
          [-121.850394, 37.70794],
          [-121.850405, 37.707654],
          [-121.850413, 37.707485],
          [-121.850444, 37.706916],
          [-121.850447, 37.706855],
          [-121.85045099999999, 37.706652],
          [-121.850459, 37.706255],
          [-121.850498, 37.704316],
          [-121.850498, 37.704273],
          [-121.85050000000001, 37.703613],
          [-121.850506, 37.703433],
          [-121.85050799999999, 37.70339],
          [-121.850497, 37.703123999999995],
          [-121.850482, 37.702892999999996],
          [-121.85048, 37.702853],
          [-121.85046, 37.702715],
          [-121.850214, 37.702736],
          [-121.850004, 37.70274],
          [-121.849773, 37.70272],
          [-121.849606, 37.702689],
          [-121.84941, 37.702639999999995],
          [-121.84932599999999, 37.702608999999995],
          [-121.849253, 37.702582],
          [-121.849079, 37.702504],
          [-121.848891, 37.702394],
          [-121.848726, 37.702286],
          [-121.848457, 37.702115],
          [-121.848318, 37.702020999999995],
          [-121.848187, 37.701954],
          [-121.847951, 37.701844],
          [-121.847794, 37.701778999999995],
          [-121.847473, 37.701662999999996],
          [-121.847214, 37.701595],
          [-121.84693, 37.701543],
          [-121.84691699999999, 37.701541],
          [-121.846539, 37.701494],
          [-121.846132, 37.701457999999995],
          [-121.84579, 37.701428],
          [-121.845522, 37.701408],
          [-121.845298, 37.701378],
          [-121.84499199999999, 37.701321],
          [-121.845665, 37.701326],
          [-121.847684, 37.701341],
          [-121.848357, 37.701346],
          [-121.848585, 37.701347],
          [-121.849272, 37.701352],
          [-121.849501, 37.701353999999995],
          [-121.85060899999999, 37.701378],
          [-121.850922, 37.701384999999995],
          [-121.851474, 37.701398],
          [-121.853935, 37.701405],
          [-121.854455, 37.701406999999996],
          [-121.85504399999999, 37.701409999999996],
          [-121.855587, 37.701412999999995],
          [-121.857218, 37.701425],
          [-121.857762, 37.701429],
          [-121.85812899999999, 37.701432],
          [-121.859032, 37.70144],
          [-121.859613, 37.701446],
          [-121.860534, 37.701463],
          [-121.861706, 37.701478],
          [-121.862845, 37.701485999999996],
          [-121.863225, 37.701488999999995],
          [-121.864116, 37.701498],
          [-121.864328, 37.7015],
          [-121.86475, 37.701504],
          [-121.864966, 37.701505],
          [-121.865179, 37.701505999999995],
          [-121.86542299999999, 37.701505999999995],
          [-121.865683, 37.701508],
          [-121.86615499999999, 37.701510999999996],
          [-121.866399, 37.701513999999996],
          [-121.866447, 37.701513999999996],
          [-121.866593, 37.701515],
          [-121.86664, 37.701521],
          [-121.866711, 37.701516],
          [-121.867753, 37.701524],
          [-121.868517, 37.701530999999996],
          [-121.8708, 37.701549],
          [-121.871094, 37.701555],
          [-121.872024, 37.701574],
          [-121.872208, 37.701578],
          [-121.872227, 37.701578],
          [-121.872339, 37.70158],
          [-121.874578, 37.701614],
          [-121.876842, 37.701648999999996],
          [-121.877237, 37.701657],
          [-121.878187, 37.701678],
          [-121.878425, 37.701678],
          [-121.878821, 37.701679999999996],
          [-121.87885299999999, 37.701679999999996],
          [-121.87895, 37.701679999999996],
          [-121.878983, 37.701681],
          [-121.879224, 37.701681],
          [-121.879947, 37.701684],
          [-121.880189, 37.701685],
          [-121.880543, 37.701685999999995],
          [-121.881606, 37.701689],
          [-121.881961, 37.701691],
          [-121.883193, 37.7017],
          [-121.883651, 37.701704],
          [-121.885689, 37.701713999999996],
          [-121.88689, 37.701723],
          [-121.88798, 37.701732],
          [-121.88812300000001, 37.701730999999995],
          [-121.888257, 37.701730999999995],
          [-121.88836, 37.701733],
          [-121.889933, 37.701749],
          [-121.891701, 37.701767],
          [-121.891728, 37.701768],
          [-121.89175, 37.701769999999996],
          [-121.89180999999999, 37.701769999999996],
          [-121.891838, 37.701769999999996],
          [-121.892133, 37.701769999999996],
          [-121.893019, 37.701772999999996],
          [-121.893123, 37.701774],
          [-121.893315, 37.701775999999995],
          [-121.894326, 37.701788],
          [-121.896625, 37.701816],
          [-121.897361, 37.70185],
          [-121.89741000000001, 37.701853],
          [-121.898026, 37.701872],
          [-121.89837299999999, 37.701876999999996],
          [-121.898429, 37.701876999999996],
          [-121.898583, 37.701879],
          [-121.89859799999999, 37.701879],
          [-121.89865499999999, 37.701879999999996],
          [-121.899254, 37.70189],
          [-121.899548, 37.701895],
          [-121.900808, 37.7019],
          [-121.90105199999999, 37.701896999999995],
          [-121.901652, 37.70189],
          [-121.90205, 37.701871],
          [-121.902326, 37.701858],
          [-121.90310000000001, 37.701865],
          [-121.903245, 37.701864],
          [-121.903644, 37.701862999999996],
          [-121.903784, 37.701862],
          [-121.904004, 37.701862],
          [-121.90420499999999, 37.701867],
          [-121.904346, 37.701871],
          [-121.904797, 37.701882999999995],
          [-121.904879, 37.701882],
          [-121.905829, 37.701875],
          [-121.90647899999999, 37.701884],
          [-121.906908, 37.701769999999996],
          [-121.907068, 37.701729],
          [-121.907198, 37.701896999999995],
          [-121.90984399999999, 37.701944999999995],
          [-121.912066, 37.701986],
          [-121.912099, 37.701986],
          [-121.91386, 37.702003999999995],
          [-121.916271, 37.702017],
          [-121.916445, 37.702017999999995],
          [-121.919884, 37.701692],
          [-121.921551, 37.701447],
          [-121.922434, 37.701178999999996],
          [-121.922584, 37.701133999999996],
          [-121.922843, 37.701077],
          [-121.923039, 37.701035],
          [-121.923571, 37.700919],
          [-121.926535, 37.700274],
          [-121.92752300000001, 37.70006],
          [-121.927815, 37.699993],
          [-121.928693, 37.699796],
          [-121.928986, 37.699729999999995],
          [-121.930095, 37.699467999999996],
          [-121.930936, 37.69927],
          [-121.932715, 37.698873999999996],
          [-121.93290400000001, 37.698842],
          [-121.933434, 37.69874],
          [-121.93381600000001, 37.698668],
          [-121.934476, 37.698566],
          [-121.93455900000001, 37.698558],
          [-121.934639, 37.698549],
          [-121.934881, 37.698525],
          [-121.934962, 37.698516999999995],
          [-121.935137, 37.698502999999995],
          [-121.935181, 37.6985],
          [-121.935665, 37.698468999999996],
          [-121.935751, 37.698464],
          [-121.935842, 37.698467],
          [-121.93684400000001, 37.698451999999996],
          [-121.937908, 37.698459],
          [-121.938197, 37.698461],
          [-121.940243, 37.698474],
          [-121.940571, 37.698476],
          [-121.94065, 37.698477],
          [-121.94197199999999, 37.698488999999995],
          [-121.942294, 37.698492],
          [-121.94289, 37.698496],
          [-121.945481, 37.698516],
          [-121.945583, 37.698521],
          [-121.945937, 37.698523],
          [-121.94726, 37.698530999999996],
          [-121.947495, 37.698532],
          [-121.949168, 37.698549],
          [-121.951672, 37.698582],
          [-121.954961, 37.698614],
          [-121.955325, 37.698617],
          [-121.95554, 37.698616],
          [-121.956222, 37.698612],
          [-121.957303, 37.698605],
          [-121.957443, 37.698603999999996],
          [-121.958164, 37.698575],
          [-121.958224, 37.698572999999996],
          [-121.958469, 37.698552],
          [-121.959255, 37.698428],
          [-121.959402, 37.698405],
          [-121.960397, 37.698229],
          [-121.96109899999999, 37.698099],
          [-121.962553, 37.69784],
          [-121.962913, 37.697784999999996],
          [-121.963917, 37.697634],
          [-121.964022, 37.697621999999996],
          [-121.964747, 37.697553],
          [-121.965469, 37.697485],
          [-121.965799, 37.697463],
          [-121.966651, 37.697404999999996],
          [-121.967972, 37.697373999999996],
          [-121.969381, 37.697404999999996],
          [-121.969426, 37.697403],
          [-121.970174, 37.697449999999996],
          [-121.971599, 37.697561],
          [-121.972149, 37.697613],
          [-121.972605, 37.697666999999996],
          [-121.973145, 37.697745999999995],
          [-121.974003, 37.697902],
          [-121.97426300000001, 37.69795],
          [-121.975538, 37.69817],
          [-121.976246, 37.698302999999996],
          [-121.977662, 37.698541999999996],
          [-121.978004, 37.698599],
          [-121.97903, 37.698772],
          [-121.979372, 37.69883],
          [-121.979409, 37.698837],
          [-121.981225, 37.699152],
          [-121.981498, 37.699200000000005],
          [-121.982015, 37.699284999999996],
          [-121.982542, 37.699362],
          [-121.983138, 37.699444],
          [-121.983817, 37.699528],
          [-121.98491899999999, 37.699650999999996],
          [-121.985348, 37.699687999999995],
          [-121.985885, 37.699751],
          [-121.986766, 37.699867],
          [-121.986822, 37.699875],
          [-121.987177, 37.699931],
          [-121.987599, 37.700019999999995],
          [-121.98763, 37.700026],
          [-121.987662, 37.700033],
          [-121.98837, 37.700233],
          [-121.988652, 37.700308],
          [-121.988714, 37.700323999999995],
          [-121.989419, 37.700573],
          [-121.98972499999999, 37.700692],
          [-121.990146, 37.700872],
          [-121.990718, 37.701131],
          [-121.991098, 37.701288],
          [-121.991146, 37.701308],
          [-121.991751, 37.701586],
          [-121.992253, 37.701805],
          [-121.992784, 37.702023],
          [-121.993682, 37.702371],
          [-121.994053, 37.7025],
          [-121.99504999999999, 37.702815],
          [-121.995357, 37.702901],
          [-121.995689, 37.702985],
          [-121.996614, 37.703196],
          [-121.997385, 37.703351],
          [-121.997838, 37.703429],
          [-121.998176, 37.703482],
          [-121.99859000000001, 37.703539],
          [-121.998646, 37.703544],
          [-121.998876, 37.703569],
          [-121.99973800000001, 37.703648],
          [-122.000326, 37.703696],
          [-122.001155, 37.703725999999996],
          [-122.001277, 37.703728],
        ],
      ],
    },
  },
  {
    id: "1177",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94588",
      GEOID10: "94588",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 82377323,
      AWATER10: 2083051,
      INTPTLAT10: "+37.7931829",
      INTPTLON10: "-121.8606299",
      Name: "94588",
    },
    geometry: {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [-121.89545, 37.839801],
            [-121.89519, 37.840599],
            [-121.894724, 37.842020999999995],
            [-121.893907, 37.844516999999996],
            [-121.893816, 37.844798],
            [-121.893526, 37.845682],
            [-121.893393, 37.846089],
            [-121.889698, 37.846088],
            [-121.888612, 37.846087],
            [-121.880773, 37.846084],
            [-121.87427, 37.846081],
            [-121.86949, 37.84608],
            [-121.867875, 37.846084],
            [-121.863031, 37.846095999999996],
            [-121.862588, 37.846098],
            [-121.861417, 37.846094],
            [-121.856649, 37.846039],
            [-121.846915, 37.845928],
            [-121.846844, 37.845847],
            [-121.846763, 37.845755],
            [-121.84663499999999, 37.845568],
            [-121.84645499999999, 37.845013],
            [-121.846418, 37.844851999999996],
            [-121.846233, 37.844629999999995],
            [-121.846047, 37.844398],
            [-121.845942, 37.844193],
            [-121.845655, 37.843972],
            [-121.845322, 37.843742],
            [-121.845092, 37.843539],
            [-121.844873, 37.843317],
            [-121.8447, 37.843129999999995],
            [-121.844582, 37.842853],
            [-121.844176, 37.842337],
            [-121.844036, 37.842087],
            [-121.843954, 37.841972],
            [-121.843893, 37.841712],
            [-121.84382099999999, 37.841497],
            [-121.843707, 37.841338],
            [-121.843619, 37.8412],
            [-121.843525, 37.840986],
            [-121.843458, 37.840779],
            [-121.8434, 37.840562],
            [-121.84327, 37.840374],
            [-121.843138, 37.840286],
            [-121.842987, 37.840165999999996],
            [-121.842813, 37.840046],
            [-121.842644, 37.839915999999995],
            [-121.842503, 37.839842],
            [-121.842318, 37.839808999999995],
            [-121.842069, 37.839797],
            [-121.84183999999999, 37.839829],
            [-121.84164200000001, 37.839877],
            [-121.841431, 37.839923],
            [-121.841093, 37.839943999999996],
            [-121.840835, 37.839886],
            [-121.840717, 37.839847999999996],
            [-121.840586, 37.839836],
            [-121.840311, 37.839802999999996],
            [-121.83999399999999, 37.839757],
            [-121.839749, 37.839681],
            [-121.839573, 37.839658],
            [-121.839416, 37.839681],
            [-121.83914, 37.839627],
            [-121.839008, 37.83956],
            [-121.838939, 37.839486],
            [-121.838473, 37.839166],
            [-121.838287, 37.839093],
            [-121.838142, 37.839044],
            [-121.838055, 37.838985],
            [-121.838004, 37.83891],
            [-121.837948, 37.838814],
            [-121.837893, 37.838747],
            [-121.837678, 37.838606],
            [-121.83752799999999, 37.838529],
            [-121.837328, 37.838449],
            [-121.837164, 37.838355],
            [-121.836941, 37.838260999999996],
            [-121.83664999999999, 37.838128],
            [-121.836432, 37.838045],
            [-121.836305, 37.837989],
            [-121.836204, 37.837900999999995],
            [-121.836131, 37.837851],
            [-121.83596299999999, 37.837775],
            [-121.83577199999999, 37.837702],
            [-121.835573, 37.837638999999996],
            [-121.835347, 37.837599],
            [-121.835279, 37.837578],
            [-121.835003, 37.837534999999995],
            [-121.834746, 37.837542],
            [-121.834341, 37.837578],
            [-121.83406099999999, 37.837556],
            [-121.83370000000001, 37.837539],
            [-121.833547, 37.837576999999996],
            [-121.833345, 37.837618],
            [-121.83317, 37.837688],
            [-121.83303599999999, 37.837772],
            [-121.832875, 37.837838],
            [-121.83266499999999, 37.837905],
            [-121.832513, 37.837922999999996],
            [-121.832445, 37.837922999999996],
            [-121.83236600000001, 37.83782],
            [-121.832299, 37.837607],
            [-121.832209, 37.837106999999996],
            [-121.832153, 37.836997],
            [-121.832065, 37.836873],
            [-121.831918, 37.836724],
            [-121.831801, 37.836725],
            [-121.831522, 37.836739],
            [-121.831368, 37.836734],
            [-121.831035, 37.836773],
            [-121.830865, 37.836836],
            [-121.830768, 37.836934],
            [-121.830693, 37.837053],
            [-121.83062799999999, 37.837196],
            [-121.830532, 37.837351],
            [-121.830499, 37.837523],
            [-121.830512, 37.837765999999995],
            [-121.830413, 37.838049999999996],
            [-121.830334, 37.838169],
            [-121.830116, 37.838296],
            [-121.829808, 37.838460999999995],
            [-121.829678, 37.838519999999995],
            [-121.82948, 37.838525],
            [-121.829371, 37.838516],
            [-121.829312, 37.838466],
            [-121.828936, 37.838113],
            [-121.828782, 37.838108],
            [-121.828698, 37.838190999999995],
            [-121.828673, 37.838313],
            [-121.828617, 37.838453],
            [-121.82845, 37.838684],
            [-121.828317, 37.838842],
            [-121.828233, 37.838910999999996],
            [-121.828084, 37.838933999999995],
            [-121.827718, 37.838881],
            [-121.82742400000001, 37.838845],
            [-121.82727, 37.838797],
            [-121.827026, 37.838743],
            [-121.826819, 37.83877],
            [-121.826598, 37.838786999999996],
            [-121.826345, 37.838768],
            [-121.826078, 37.838685],
            [-121.825949, 37.838561],
            [-121.825838, 37.838409],
            [-121.825823, 37.838316],
            [-121.825847, 37.838133],
            [-121.825812, 37.837925999999996],
            [-121.825619, 37.837728],
            [-121.825456, 37.837709],
            [-121.825398, 37.837756],
            [-121.825223, 37.838018999999996],
            [-121.825026, 37.838121],
            [-121.824851, 37.83818],
            [-121.824779, 37.838173999999995],
            [-121.82471, 37.838128],
            [-121.82457099999999, 37.838150999999996],
            [-121.824568, 37.838226999999996],
            [-121.824664, 37.838322],
            [-121.824743, 37.838411],
            [-121.82467199999999, 37.838494],
            [-121.824634, 37.838633],
            [-121.824574, 37.838995],
            [-121.824527, 37.838978],
            [-121.82385099999999, 37.838982],
            [-121.82341, 37.839019],
            [-121.823152, 37.839002],
            [-121.823102, 37.838999],
            [-121.82309000000001, 37.838998],
            [-121.823082, 37.838823],
            [-121.823071, 37.838705],
            [-121.823082, 37.838566],
            [-121.823124, 37.838350999999996],
            [-121.823082, 37.838273],
            [-121.823022, 37.838212],
            [-121.822944, 37.838142],
            [-121.822866, 37.838063999999996],
            [-121.822791, 37.837917999999995],
            [-121.82273, 37.837783],
            [-121.822595, 37.837541],
            [-121.822508, 37.837471],
            [-121.82229, 37.837379999999996],
            [-121.822131, 37.837306999999996],
            [-121.822021, 37.837229],
            [-121.821975, 37.837151],
            [-121.821955, 37.837050999999995],
            [-121.821981, 37.836968999999996],
            [-121.822076, 37.836771],
            [-121.822137, 37.836608999999996],
            [-121.822206, 37.836455],
            [-121.82228, 37.836332],
            [-121.822386, 37.836213],
            [-121.822465, 37.836059],
            [-121.822463, 37.835951],
            [-121.822401, 37.835755],
            [-121.822367, 37.835626999999995],
            [-121.822261, 37.835471],
            [-121.822191, 37.835381999999996],
            [-121.822041, 37.83528],
            [-121.821895, 37.835195999999996],
            [-121.821808, 37.835111],
            [-121.821717, 37.835055],
            [-121.821563, 37.835042],
            [-121.821468, 37.835007999999995],
            [-121.821422, 37.834951],
            [-121.821443, 37.834886],
            [-121.821549, 37.834773999999996],
            [-121.821936, 37.834466],
            [-121.822002, 37.834401],
            [-121.822121, 37.834199],
            [-121.822238, 37.833968999999996],
            [-121.822299, 37.833861],
            [-121.822301, 37.833675],
            [-121.822293, 37.833504],
            [-121.822368, 37.833174],
            [-121.822393, 37.833012],
            [-121.82237599999999, 37.832851999999995],
            [-121.822338, 37.832730999999995],
            [-121.822337, 37.832702],
            [-121.822389, 37.832558],
            [-121.822401, 37.832476],
            [-121.822386, 37.832372],
            [-121.822194, 37.831981],
            [-121.82216, 37.831702],
            [-121.822132, 37.831509],
            [-121.822107, 37.831381],
            [-121.82206099999999, 37.831296],
            [-121.82207199999999, 37.831174],
            [-121.822102, 37.831098],
            [-121.822142, 37.83103],
            [-121.822186, 37.830979],
            [-121.822198, 37.830911],
            [-121.822192, 37.830847],
            [-121.82216, 37.83079],
            [-121.822145, 37.830726],
            [-121.822137, 37.830501],
            [-121.82212200000001, 37.830422],
            [-121.82208, 37.830355],
            [-121.822002, 37.830306],
            [-121.821938, 37.830259999999996],
            [-121.821833, 37.830172],
            [-121.82177300000001, 37.83009],
            [-121.821736, 37.830008],
            [-121.821725, 37.829926],
            [-121.82167799999999, 37.829837],
            [-121.821628, 37.829769999999996],
            [-121.82156499999999, 37.829696999999996],
            [-121.821529, 37.829662],
            [-121.82151, 37.829601],
            [-121.821477, 37.829526],
            [-121.821426, 37.829437],
            [-121.821401, 37.829347999999996],
            [-121.821461, 37.829122],
            [-121.821459, 37.829012],
            [-121.821402, 37.828848],
            [-121.82135099999999, 37.828776999999995],
            [-121.821299, 37.828645],
            [-121.821275, 37.82857],
            [-121.821264, 37.828483999999996],
            [-121.82126199999999, 37.828331],
            [-121.821283, 37.828277],
            [-121.821345, 37.828205],
            [-121.821421, 37.828157],
            [-121.821276, 37.828151999999996],
            [-121.821154, 37.828120999999996],
            [-121.821068, 37.828075],
            [-121.821004, 37.828033],
            [-121.82094000000001, 37.82798],
            [-121.820758, 37.827886],
            [-121.820649, 37.827839999999995],
            [-121.820517, 37.82777],
            [-121.820225, 37.827588],
            [-121.82012399999999, 37.827503],
            [-121.820013, 37.827318],
            [-121.819942, 37.827126],
            [-121.819912, 37.826997],
            [-121.819901, 37.826896999999995],
            [-121.819921, 37.826743],
            [-121.819946, 37.826614],
            [-121.81998, 37.826502999999995],
            [-121.819981, 37.826296],
            [-121.819992, 37.82617],
            [-121.819994, 37.826017],
            [-121.819961, 37.825956],
            [-121.819883, 37.825882],
            [-121.819838, 37.825845],
            [-121.819898, 37.824052],
            [-121.819929, 37.823153999999995],
            [-121.820207, 37.814899],
            [-121.82037, 37.810047],
            [-121.820486, 37.806612],
            [-121.82056, 37.804449],
            [-121.820593, 37.803475999999996],
            [-121.820685, 37.800764],
            [-121.820722, 37.799648999999995],
            [-121.820729, 37.799461],
            [-121.82081600000001, 37.796879],
            [-121.820911, 37.794064],
            [-121.82091, 37.793965],
            [-121.820909, 37.793949],
            [-121.820909, 37.793745],
            [-121.820908, 37.793603999999995],
            [-121.820921, 37.79349],
            [-121.820937, 37.789757],
            [-121.820983, 37.779388999999995],
            [-121.820976, 37.778560999999996],
            [-121.820945, 37.77483],
            [-121.820942, 37.774688999999995],
            [-121.82093499999999, 37.774268],
            [-121.820933, 37.774128],
            [-121.820959, 37.774139],
            [-121.821153, 37.774214],
            [-121.821499, 37.774339],
            [-121.821654, 37.774391],
            [-121.821855, 37.77445],
            [-121.822003, 37.774474999999995],
            [-121.822303, 37.774508999999995],
            [-121.82244399999999, 37.774516999999996],
            [-121.822479, 37.774521],
            [-121.82264, 37.77454],
            [-121.82283699999999, 37.774558],
            [-121.82299499999999, 37.774567],
            [-121.82316, 37.774577],
            [-121.823406, 37.774581],
            [-121.82352900000001, 37.774575],
            [-121.823652, 37.774564],
            [-121.823762, 37.774549],
            [-121.823857, 37.774549],
            [-121.82395199999999, 37.774553999999995],
            [-121.824046, 37.774564999999996],
            [-121.824139, 37.774581],
            [-121.82423, 37.774602],
            [-121.824968, 37.774847],
            [-121.825076, 37.774879],
            [-121.825183, 37.774916],
            [-121.825286, 37.774958],
            [-121.825386, 37.775003999999996],
            [-121.825617, 37.775129],
            [-121.825725, 37.775180999999996],
            [-121.825837, 37.775228999999996],
            [-121.825952, 37.775271],
            [-121.82606799999999, 37.77529],
            [-121.826183, 37.775301999999996],
            [-121.826415, 37.77531],
            [-121.826646, 37.775296],
            [-121.826792, 37.775275],
            [-121.826931, 37.775248],
            [-121.827072, 37.775225999999996],
            [-121.82715, 37.775216],
            [-121.827214, 37.775209],
            [-121.827356, 37.775197999999996],
            [-121.8275, 37.775192],
            [-121.827702, 37.775193],
            [-121.827808, 37.775208],
            [-121.827912, 37.775228999999996],
            [-121.828015, 37.775255],
            [-121.828116, 37.775286],
            [-121.828213, 37.775321999999996],
            [-121.82830799999999, 37.775363],
            [-121.828566, 37.775498],
            [-121.828677, 37.775566],
            [-121.828895, 37.775439],
            [-121.82901, 37.775383],
            [-121.829128, 37.775331],
            [-121.829236, 37.775289],
            [-121.829358, 37.775248999999995],
            [-121.829414, 37.775220999999995],
            [-121.829466, 37.775189],
            [-121.829514, 37.775152999999996],
            [-121.829557, 37.775113],
            [-121.829594, 37.77507],
            [-121.82962599999999, 37.775023999999995],
            [-121.829651, 37.774975999999995],
            [-121.82971, 37.774814],
            [-121.829745, 37.774696999999996],
            [-121.829774, 37.774578999999996],
            [-121.829796, 37.77446],
            [-121.829811, 37.774339999999995],
            [-121.829819, 37.77422],
            [-121.829821, 37.774100000000004],
            [-121.82981, 37.773908],
            [-121.82979, 37.773703999999995],
            [-121.829764, 37.7735],
            [-121.829732, 37.773297],
            [-121.82969, 37.773094],
            [-121.829651, 37.772903],
            [-121.829604, 37.772802999999996],
            [-121.829551, 37.772704999999995],
            [-121.829492, 37.772608999999996],
            [-121.82935499999999, 37.772425],
            [-121.829278, 37.772337],
            [-121.829195, 37.772253],
            [-121.829107, 37.772172999999995],
            [-121.82901799999999, 37.772099],
            [-121.82896099999999, 37.772047],
            [-121.828765, 37.771868],
            [-121.828243, 37.771417],
            [-121.828074, 37.771277999999995],
            [-121.82760999999999, 37.770941],
            [-121.82724, 37.770654],
            [-121.82717, 37.770612],
            [-121.827105, 37.770567],
            [-121.827045, 37.770517],
            [-121.82699, 37.770464],
            [-121.82694000000001, 37.770407999999996],
            [-121.826896, 37.770348],
            [-121.826858, 37.770286],
            [-121.826826, 37.770222],
            [-121.826801, 37.770156],
            [-121.826774, 37.770036999999995],
            [-121.826786, 37.769965],
            [-121.82680500000001, 37.769892999999996],
            [-121.826831, 37.769822999999995],
            [-121.826863, 37.769754],
            [-121.82690099999999, 37.769687],
            [-121.826979, 37.769580999999995],
            [-121.82700299999999, 37.769490999999995],
            [-121.827021, 37.769400999999995],
            [-121.827031, 37.76931],
            [-121.827035, 37.769217999999995],
            [-121.827032, 37.769127],
            [-121.826992, 37.768834],
            [-121.826962, 37.768656],
            [-121.826861, 37.768208],
            [-121.82683, 37.768125999999995],
            [-121.826792, 37.768045],
            [-121.826747, 37.767967],
            [-121.826696, 37.767891],
            [-121.82664, 37.767818],
            [-121.826561, 37.767731999999995],
            [-121.825869, 37.766926999999995],
            [-121.82545999999999, 37.766428999999995],
            [-121.825136, 37.766008],
            [-121.825027, 37.765887],
            [-121.825002, 37.765862],
            [-121.824792, 37.765656],
            [-121.824667, 37.765546],
            [-121.82453699999999, 37.76544],
            [-121.82440000000001, 37.765336],
            [-121.82351299999999, 37.764775],
            [-121.82310000000001, 37.764520999999995],
            [-121.822482, 37.764113],
            [-121.831891, 37.764209],
            [-121.83186599999999, 37.756611],
            [-121.831987, 37.756558],
            [-121.832129, 37.756478],
            [-121.832191, 37.756412],
            [-121.832261, 37.756282999999996],
            [-121.832258, 37.756139999999995],
            [-121.832358, 37.756094],
            [-121.832416, 37.756059],
            [-121.832464, 37.756031],
            [-121.832577, 37.756031],
            [-121.832574, 37.756043],
            [-121.832594, 37.756164],
            [-121.832671, 37.756442],
            [-121.832728, 37.756585],
            [-121.832821, 37.756755999999996],
            [-121.83297999999999, 37.757118],
            [-121.8331, 37.757282],
            [-121.833175, 37.757424],
            [-121.833322, 37.757608],
            [-121.83344199999999, 37.757714],
            [-121.83354299999999, 37.757842],
            [-121.833736, 37.758061],
            [-121.833818, 37.758131999999996],
            [-121.833874, 37.758246],
            [-121.833966, 37.75833],
            [-121.833985, 37.758379999999995],
            [-121.834058, 37.758458],
            [-121.834168, 37.758521],
            [-121.834213, 37.758556999999996],
            [-121.834313, 37.75857],
            [-121.83443, 37.758561],
            [-121.834492, 37.758545999999996],
            [-121.834573, 37.758517],
            [-121.83472499999999, 37.758429],
            [-121.834778, 37.758386],
            [-121.834885, 37.758356],
            [-121.83502899999999, 37.75834],
            [-121.8353, 37.758337],
            [-121.835444, 37.758357],
            [-121.83560800000001, 37.758469],
            [-121.835681, 37.758525999999996],
            [-121.835763, 37.758582],
            [-121.835855, 37.758638],
            [-121.835964, 37.758694],
            [-121.836091, 37.758736],
            [-121.836299, 37.758818999999995],
            [-121.836607, 37.758873],
            [-121.836689, 37.758928999999995],
            [-121.836816, 37.758978],
            [-121.83698799999999, 37.759069],
            [-121.83713399999999, 37.759153],
            [-121.83727999999999, 37.759251],
            [-121.837662, 37.759468999999996],
            [-121.83779, 37.759553],
            [-121.838045, 37.759693],
            [-121.838172, 37.759735],
            [-121.838833, 37.759927999999995],
            [-121.838978, 37.759983],
            [-121.839069, 37.760045999999996],
            [-121.839214, 37.760101999999996],
            [-121.839261, 37.760173],
            [-121.83937, 37.76025],
            [-121.839416, 37.760307],
            [-121.839517, 37.760377999999996],
            [-121.839616, 37.760419],
            [-121.83968899999999, 37.760461],
            [-121.839798, 37.760467],
            [-121.83985200000001, 37.760473999999995],
            [-121.839969, 37.760508],
            [-121.840078, 37.760557],
            [-121.840187, 37.760591999999995],
            [-121.840323, 37.760625999999995],
            [-121.84043199999999, 37.760667999999995],
            [-121.840711, 37.760664],
            [-121.840873, 37.76067],
            [-121.841008, 37.760667999999995],
            [-121.84141, 37.760754999999996],
            [-121.841627, 37.760813],
            [-121.841808, 37.760847],
            [-121.842015, 37.760827],
            [-121.842248, 37.760785],
            [-121.842535, 37.760706],
            [-121.84275, 37.760636],
            [-121.842997, 37.760560999999996],
            [-121.843253, 37.760515999999996],
            [-121.843349, 37.76051],
            [-121.843406, 37.760508],
            [-121.843433, 37.760504],
            [-121.84346, 37.760503],
            [-121.843586, 37.760512],
            [-121.843767, 37.760553],
            [-121.844085, 37.760545],
            [-121.844394, 37.760349],
            [-121.844711, 37.760213],
            [-121.845042, 37.760084],
            [-121.845365, 37.759997999999996],
            [-121.84564499999999, 37.759955],
            [-121.845666, 37.759952],
            [-121.845987, 37.759997999999996],
            [-121.846126, 37.760037],
            [-121.846115, 37.760067],
            [-121.846065, 37.760292],
            [-121.846046, 37.760559],
            [-121.846145, 37.760819],
            [-121.846384, 37.761089999999996],
            [-121.846678, 37.761322],
            [-121.84697, 37.761437],
            [-121.847398, 37.761762],
            [-121.847516, 37.761784999999996],
            [-121.847667, 37.761883],
            [-121.84783999999999, 37.761967999999996],
            [-121.847968, 37.762084],
            [-121.84815, 37.762207],
            [-121.848386, 37.762271999999996],
            [-121.848597, 37.762276],
            [-121.84893199999999, 37.762204],
            [-121.84922, 37.76207],
            [-121.849452, 37.761931],
            [-121.849583, 37.761824],
            [-121.849715, 37.761772],
            [-121.849872, 37.761789],
            [-121.849991, 37.761862],
            [-121.850165, 37.761990999999995],
            [-121.850276, 37.762057999999996],
            [-121.850417, 37.762087],
            [-121.85055299999999, 37.7622],
            [-121.850633, 37.762141],
            [-121.850746, 37.762147],
            [-121.850855, 37.762214],
            [-121.850955, 37.762284],
            [-121.851344, 37.762609],
            [-121.851495, 37.762729],
            [-121.851732, 37.762890999999996],
            [-121.85193699999999, 37.76301],
            [-121.852295, 37.763106],
            [-121.852484, 37.763129],
            [-121.852664, 37.763116],
            [-121.852816, 37.763042999999996],
            [-121.852932, 37.762969999999996],
            [-121.853058, 37.762989999999995],
            [-121.853173, 37.763073999999996],
            [-121.853314, 37.763166],
            [-121.85344599999999, 37.763225],
            [-121.853595, 37.76327],
            [-121.853785, 37.763307],
            [-121.854097, 37.763346],
            [-121.854439, 37.763332],
            [-121.854587, 37.763298],
            [-121.85473, 37.763231999999995],
            [-121.854855, 37.763197999999996],
            [-121.854967, 37.763143],
            [-121.855093, 37.76312],
            [-121.855251, 37.763158],
            [-121.855537, 37.763287],
            [-121.855668, 37.763317],
            [-121.856197, 37.763425999999995],
            [-121.85655, 37.76349],
            [-121.856708, 37.763534],
            [-121.85688999999999, 37.763593],
            [-121.857017, 37.763652],
            [-121.857286, 37.763828],
            [-121.85739, 37.76387],
            [-121.857535, 37.763911],
            [-121.85767, 37.763917],
            [-121.857784, 37.763906],
            [-121.85833, 37.763815],
            [-121.858704, 37.763965],
            [-121.859083, 37.764016],
            [-121.859461, 37.764061999999996],
            [-121.860183, 37.763928],
            [-121.86034699999999, 37.763937],
            [-121.860725, 37.763967],
            [-121.860889, 37.763974999999995],
            [-121.861165, 37.763922],
            [-121.861598, 37.763881999999995],
            [-121.861912, 37.763877],
            [-121.86210299999999, 37.763928],
            [-121.86210000000001, 37.764046],
            [-121.86214, 37.764221],
            [-121.86224, 37.764306],
            [-121.862439, 37.764357],
            [-121.86264800000001, 37.764405],
            [-121.863122, 37.764475],
            [-121.863542, 37.764523],
            [-121.863694, 37.764468],
            [-121.86405500000001, 37.764485],
            [-121.864154, 37.764503999999995],
            [-121.864115, 37.764602],
            [-121.864108, 37.764713],
            [-121.864115, 37.764821],
            [-121.864206, 37.764897999999995],
            [-121.864292, 37.764919],
            [-121.864638, 37.764829],
            [-121.864704, 37.764789],
            [-121.865095, 37.764702],
            [-121.86561, 37.764531999999996],
            [-121.866153, 37.764443],
            [-121.866305, 37.764354999999995],
            [-121.866496, 37.764216999999995],
            [-121.866608, 37.764191],
            [-121.86668, 37.764212],
            [-121.866555, 37.764706],
            [-121.86652, 37.764844],
            [-121.866438, 37.765128],
            [-121.866385, 37.765277],
            [-121.86633, 37.765415],
            [-121.866266, 37.765555],
            [-121.866172, 37.765738999999996],
            [-121.865643, 37.76676],
            [-121.86556, 37.766920999999996],
            [-121.864598, 37.768798],
            [-121.864177, 37.769600000000004],
            [-121.863671, 37.770638999999996],
            [-121.863315, 37.771398999999995],
            [-121.8631, 37.771882],
            [-121.862987, 37.772137],
            [-121.862583, 37.773092999999996],
            [-121.862414, 37.773514999999996],
            [-121.862234, 37.773969],
            [-121.86210299999999, 37.774316999999996],
            [-121.861988, 37.77465],
            [-121.861869, 37.775041],
            [-121.8618, 37.775295],
            [-121.861739, 37.775549999999996],
            [-121.861702, 37.775838],
            [-121.861734, 37.776706],
            [-121.861886, 37.777468],
            [-121.86222599999999, 37.778265999999995],
            [-121.862562, 37.779163],
            [-121.862708, 37.780761],
            [-121.862719, 37.781000999999996],
            [-121.862747, 37.781428999999996],
            [-121.86278, 37.781825999999995],
            [-121.86285699999999, 37.782539],
            [-121.863158, 37.784499],
            [-121.86363, 37.786349],
            [-121.863832, 37.78693],
            [-121.863911, 37.787172999999996],
            [-121.863974, 37.787331],
            [-121.86403, 37.78749],
            [-121.864103, 37.787738],
            [-121.864135, 37.78792],
            [-121.864161, 37.788103],
            [-121.864188, 37.788418],
            [-121.86417900000001, 37.7886],
            [-121.864168, 37.788727],
            [-121.864164, 37.788781],
            [-121.864141, 37.788962],
            [-121.864111, 37.789142999999996],
            [-121.86407299999999, 37.789335],
            [-121.86389299999999, 37.790161],
            [-121.863811, 37.790545],
            [-121.86379600000001, 37.790648999999995],
            [-121.863787, 37.790752999999995],
            [-121.86378500000001, 37.790962],
            [-121.863812, 37.791286],
            [-121.863842, 37.791553],
            [-121.86392000000001, 37.792106],
            [-121.863968, 37.79235],
            [-121.864022, 37.792592],
            [-121.864041, 37.792668],
            [-121.864127, 37.792995999999995],
            [-121.86421, 37.793234999999996],
            [-121.864299, 37.793473],
            [-121.864394, 37.793709],
            [-121.864426, 37.793780999999996],
            [-121.864605, 37.794177],
            [-121.86472, 37.794408],
            [-121.864867, 37.794682],
            [-121.864908, 37.794757],
            [-121.865054, 37.794959999999996],
            [-121.86518699999999, 37.795124],
            [-121.865291, 37.79524],
            [-121.86540099999999, 37.795353],
            [-121.865516, 37.795462],
            [-121.86563699999999, 37.795567999999996],
            [-121.865762, 37.79567],
            [-121.865893, 37.795769],
            [-121.86616599999999, 37.795957],
            [-121.866338, 37.796067],
            [-121.86651499999999, 37.796172999999996],
            [-121.86669499999999, 37.796274],
            [-121.866879, 37.796371],
            [-121.86706699999999, 37.796462999999996],
            [-121.867259, 37.796551],
            [-121.867454, 37.796633],
            [-121.86765199999999, 37.796710999999995],
            [-121.86800000000001, 37.796839],
            [-121.868052, 37.796858],
            [-121.869697, 37.79747],
            [-121.870719, 37.79784],
            [-121.871074, 37.797982],
            [-121.871431, 37.798119],
            [-121.87215499999999, 37.798381],
            [-121.872364, 37.798452],
            [-121.87257, 37.798501],
            [-121.872778, 37.798545],
            [-121.87298799999999, 37.798584],
            [-121.873199, 37.798617],
            [-121.8735, 37.798655],
            [-121.874251, 37.798764],
            [-121.875749, 37.798995999999995],
            [-121.877005, 37.799206],
            [-121.877447, 37.799275],
            [-121.877562, 37.799293999999996],
            [-121.877819, 37.799321],
            [-121.877814, 37.799451],
            [-121.877544, 37.807471],
            [-121.877448, 37.807499],
            [-121.87724, 37.807614],
            [-121.877177, 37.807663999999995],
            [-121.87715, 37.807686],
            [-121.876942, 37.808026],
            [-121.87679, 37.808195999999995],
            [-121.87665799999999, 37.808399],
            [-121.876492, 37.808515],
            [-121.876339, 37.808822],
            [-121.876325, 37.808893999999995],
            [-121.876339, 37.809008999999996],
            [-121.87638, 37.809092],
            [-121.876567, 37.809311],
            [-121.876643, 37.809416],
            [-121.876685, 37.809526],
            [-121.876698, 37.809636],
            [-121.876691, 37.80974],
            [-121.876677, 37.809810999999996],
            [-121.87665, 37.809872],
            [-121.876449, 37.810123999999995],
            [-121.87639300000001, 37.810212],
            [-121.876331, 37.810344],
            [-121.876248, 37.810469999999995],
            [-121.876199, 37.810497999999995],
            [-121.876067, 37.810465],
            [-121.875847, 37.810686],
            [-121.875757, 37.810758],
            [-121.875633, 37.811037999999996],
            [-121.875584, 37.811099],
            [-121.875467, 37.811423],
            [-121.875411, 37.811533],
            [-121.875275, 37.811734],
            [-121.875266, 37.811747],
            [-121.875259, 37.811791],
            [-121.875197, 37.811962],
            [-121.875175, 37.812075],
            [-121.875139, 37.812259999999995],
            [-121.875134, 37.812326],
            [-121.875079, 37.812458],
            [-121.87499, 37.812593],
            [-121.874962, 37.812653999999995],
            [-121.874852, 37.813044],
            [-121.874803, 37.813137],
            [-121.874679, 37.813296],
            [-121.874631, 37.813339],
            [-121.87460899999999, 37.813345999999996],
            [-121.874105, 37.813994],
            [-121.873897, 37.814209],
            [-121.873738, 37.814357],
            [-121.873579, 37.814555],
            [-121.873454, 37.814786],
            [-121.873053, 37.815445],
            [-121.873039, 37.815462],
            [-121.873012, 37.815467],
            [-121.872894, 37.815901],
            [-121.872749, 37.816209],
            [-121.87259, 37.816412],
            [-121.872202, 37.816632],
            [-121.87213299999999, 37.816682],
            [-121.872057, 37.81677],
            [-121.872002, 37.816857999999996],
            [-121.871891, 37.81716],
            [-121.87186299999999, 37.817164999999996],
            [-121.871855, 37.817187],
            [-121.87179499999999, 37.81799],
            [-121.871726, 37.818428999999995],
            [-121.87170499999999, 37.818506],
            [-121.871629, 37.818664999999996],
            [-121.871588, 37.819027999999996],
            [-121.871574, 37.819226],
            [-121.87158099999999, 37.819390999999996],
            [-121.871616, 37.819561],
            [-121.871678, 37.819764],
            [-121.871575, 37.820011],
            [-121.871443, 37.820149],
            [-121.871353, 37.82022],
            [-121.871222, 37.820313999999996],
            [-121.870889, 37.820523],
            [-121.870626, 37.820676999999996],
            [-121.870331, 37.820797],
            [-121.870142, 37.820875],
            [-121.86965000000001, 37.821188],
            [-121.869525, 37.821281],
            [-121.86875, 37.822050999999995],
            [-121.868578, 37.822342],
            [-121.868342, 37.822919],
            [-121.868301, 37.822963],
            [-121.868239, 37.823133],
            [-121.868066, 37.823457],
            [-121.867761, 37.823831],
            [-121.867727, 37.823896999999995],
            [-121.867699, 37.823969],
            [-121.867658, 37.824194],
            [-121.867588, 37.824348],
            [-121.867512, 37.824469],
            [-121.867409, 37.824594999999995],
            [-121.867332, 37.824661],
            [-121.867263, 37.824743],
            [-121.867215, 37.824771],
            [-121.86717999999999, 37.824808999999995],
            [-121.867104, 37.824864],
            [-121.86658800000001, 37.825041],
            [-121.866323, 37.825207999999996],
            [-121.866066, 37.825458],
            [-121.86614, 37.825672999999995],
            [-121.866077, 37.826094999999995],
            [-121.86595, 37.82625],
            [-121.865771, 37.826471],
            [-121.865432, 37.826893],
            [-121.864909, 37.827737],
            [-121.864648, 37.828227999999996],
            [-121.864339, 37.829086],
            [-121.864077, 37.829707],
            [-121.864044, 37.830306],
            [-121.864194, 37.830796],
            [-121.864207, 37.831195],
            [-121.864007, 37.831693],
            [-121.863908, 37.831873],
            [-121.86376, 37.832147],
            [-121.863554, 37.832353],
            [-121.863469, 37.832437999999996],
            [-121.863162, 37.832761],
            [-121.863023, 37.833067],
            [-121.862945, 37.833428],
            [-121.862967, 37.833625],
            [-121.877411, 37.835640999999995],
            [-121.879558, 37.83594],
            [-121.887419, 37.837036999999995],
            [-121.895966, 37.838229999999996],
            [-121.89545, 37.839801],
          ],
        ],
        [
          [
            [-121.98352, 37.689073],
            [-121.983356, 37.689036],
            [-121.983248, 37.689021],
            [-121.983158, 37.689025],
            [-121.983059, 37.689022],
            [-121.982972, 37.689029999999995],
            [-121.982873, 37.689049],
            [-121.982788, 37.689073],
            [-121.982529, 37.689167999999995],
            [-121.98243, 37.689212],
            [-121.982345, 37.689263],
            [-121.982275, 37.689346],
            [-121.98222799999999, 37.689417999999996],
            [-121.982146, 37.689519],
            [-121.982085, 37.689563],
            [-121.982001, 37.689612],
            [-121.981935, 37.689643],
            [-121.981684, 37.689721999999996],
            [-121.98148499999999, 37.689786],
            [-121.981269, 37.689838],
            [-121.981163, 37.68985],
            [-121.980842, 37.689845],
            [-121.980773, 37.689848999999995],
            [-121.980716, 37.689862],
            [-121.980653, 37.689855],
            [-121.980189, 37.689772],
            [-121.98007799999999, 37.68976],
            [-121.979839, 37.689724],
            [-121.979701, 37.689707],
            [-121.979593, 37.689702],
            [-121.979497, 37.689703],
            [-121.97937, 37.689715],
            [-121.97913299999999, 37.689761],
            [-121.978992, 37.68978],
            [-121.97873899999999, 37.68978],
            [-121.978461, 37.689757],
            [-121.97838899999999, 37.689738],
            [-121.978259, 37.689726],
            [-121.978028, 37.689710999999996],
            [-121.977868, 37.689710999999996],
            [-121.977755, 37.689721999999996],
            [-121.977598, 37.689755999999996],
            [-121.97735399999999, 37.689885],
            [-121.977149, 37.690011999999996],
            [-121.97690399999999, 37.690149],
            [-121.976853, 37.69018],
            [-121.976832, 37.690193],
            [-121.976795, 37.690216],
            [-121.976728, 37.690273999999995],
            [-121.976658, 37.690326999999996],
            [-121.976585, 37.690402999999996],
            [-121.976557, 37.690489],
            [-121.976505, 37.691030999999995],
            [-121.976497, 37.691157],
            [-121.976529, 37.691432999999996],
            [-121.976519, 37.691531999999995],
            [-121.97649, 37.69164],
            [-121.97645, 37.691700000000004],
            [-121.976365, 37.691902],
            [-121.97627, 37.692059],
            [-121.97617199999999, 37.692214],
            [-121.97607099999999, 37.692357],
            [-121.975895, 37.692583],
            [-121.975831, 37.692651999999995],
            [-121.975752, 37.692724],
            [-121.97567599999999, 37.692769999999996],
            [-121.975319, 37.692859999999996],
            [-121.97524899999999, 37.692895],
            [-121.9752, 37.692926],
            [-121.97514, 37.692977],
            [-121.975109, 37.693022],
            [-121.975084, 37.693081],
            [-121.975086, 37.693137],
            [-121.975103, 37.693193],
            [-121.97514799999999, 37.693245999999995],
            [-121.975307, 37.693366],
            [-121.975357, 37.693422999999996],
            [-121.975389, 37.693489],
            [-121.975428, 37.693709999999996],
            [-121.975427, 37.6938],
            [-121.975397, 37.693988999999995],
            [-121.97534, 37.694164],
            [-121.97530499999999, 37.69425],
            [-121.975259, 37.694328],
            [-121.975195, 37.694416],
            [-121.975049, 37.694534],
            [-121.974946, 37.694598],
            [-121.974867, 37.694676],
            [-121.974791, 37.694742999999995],
            [-121.97471, 37.694874999999996],
            [-121.974693, 37.694964999999996],
            [-121.974695, 37.69508],
            [-121.974684, 37.695243],
            [-121.974691, 37.695346],
            [-121.974729, 37.695398],
            [-121.974774, 37.695428],
            [-121.97500600000001, 37.695552],
            [-121.975242, 37.695802],
            [-121.975298, 37.695837999999995],
            [-121.975358, 37.695868],
            [-121.975486, 37.69591],
            [-121.97585699999999, 37.696044],
            [-121.97595799999999, 37.696073999999996],
            [-121.975988, 37.696092],
            [-121.976002, 37.696124],
            [-121.976005, 37.696144],
            [-121.976002, 37.696169],
            [-121.975974, 37.696196],
            [-121.97593499999999, 37.696208999999996],
            [-121.975875, 37.696208],
            [-121.975797, 37.696194],
            [-121.975442, 37.696107999999995],
            [-121.975256, 37.696072],
            [-121.975139, 37.696055],
            [-121.975013, 37.696042999999996],
            [-121.974743, 37.696052],
            [-121.974647, 37.696069],
            [-121.974569, 37.696093],
            [-121.974508, 37.696124],
            [-121.974459, 37.696166999999996],
            [-121.974422, 37.696245],
            [-121.974396, 37.696331],
            [-121.974374, 37.696383999999995],
            [-121.974322, 37.696427],
            [-121.974256, 37.696453],
            [-121.974159, 37.696467999999996],
            [-121.974114, 37.696506],
            [-121.974053, 37.696577999999995],
            [-121.974016, 37.696643],
            [-121.973958, 37.696723],
            [-121.973906, 37.696785999999996],
            [-121.973843, 37.696819],
            [-121.973749, 37.696843],
            [-121.973532, 37.696926999999995],
            [-121.973417, 37.696979999999996],
            [-121.973317, 37.697033],
            [-121.973236, 37.697082],
            [-121.97320500000001, 37.697116],
            [-121.973669, 37.697199],
            [-121.976822, 37.697762999999995],
            [-121.976843, 37.697767],
            [-121.977002, 37.697795],
            [-121.97745, 37.69786],
            [-121.97766, 37.697883],
            [-121.97766, 37.698014],
            [-121.977661, 37.698170999999995],
            [-121.977661, 37.698409999999996],
            [-121.977662, 37.698541999999996],
            [-121.976246, 37.698302999999996],
            [-121.975538, 37.69817],
            [-121.97426300000001, 37.69795],
            [-121.974003, 37.697902],
            [-121.973145, 37.697745999999995],
            [-121.972605, 37.697666999999996],
            [-121.972149, 37.697613],
            [-121.971599, 37.697561],
            [-121.970174, 37.697449999999996],
            [-121.969426, 37.697403],
            [-121.969381, 37.697404999999996],
            [-121.967972, 37.697373999999996],
            [-121.966651, 37.697404999999996],
            [-121.965799, 37.697463],
            [-121.965469, 37.697485],
            [-121.964747, 37.697553],
            [-121.964022, 37.697621999999996],
            [-121.963917, 37.697634],
            [-121.962913, 37.697784999999996],
            [-121.962553, 37.69784],
            [-121.96109899999999, 37.698099],
            [-121.960397, 37.698229],
            [-121.959402, 37.698405],
            [-121.959255, 37.698428],
            [-121.958469, 37.698552],
            [-121.958224, 37.698572999999996],
            [-121.958164, 37.698575],
            [-121.957443, 37.698603999999996],
            [-121.957303, 37.698605],
            [-121.956222, 37.698612],
            [-121.95554, 37.698616],
            [-121.955325, 37.698617],
            [-121.954961, 37.698614],
            [-121.951672, 37.698582],
            [-121.949168, 37.698549],
            [-121.947495, 37.698532],
            [-121.94726, 37.698530999999996],
            [-121.945937, 37.698523],
            [-121.945583, 37.698521],
            [-121.945481, 37.698516],
            [-121.94289, 37.698496],
            [-121.942294, 37.698492],
            [-121.94197199999999, 37.698488999999995],
            [-121.94065, 37.698477],
            [-121.940571, 37.698476],
            [-121.940243, 37.698474],
            [-121.938197, 37.698461],
            [-121.937908, 37.698459],
            [-121.93684400000001, 37.698451999999996],
            [-121.935842, 37.698467],
            [-121.935751, 37.698464],
            [-121.935665, 37.698468999999996],
            [-121.935181, 37.6985],
            [-121.935137, 37.698502999999995],
            [-121.934962, 37.698516999999995],
            [-121.934881, 37.698525],
            [-121.934639, 37.698549],
            [-121.93455900000001, 37.698558],
            [-121.934476, 37.698566],
            [-121.93381600000001, 37.698668],
            [-121.933434, 37.69874],
            [-121.93290400000001, 37.698842],
            [-121.932715, 37.698873999999996],
            [-121.930936, 37.69927],
            [-121.930095, 37.699467999999996],
            [-121.928986, 37.699729999999995],
            [-121.928693, 37.699796],
            [-121.927815, 37.699993],
            [-121.92752300000001, 37.70006],
            [-121.926535, 37.700274],
            [-121.923571, 37.700919],
            [-121.923039, 37.701035],
            [-121.922843, 37.701077],
            [-121.922584, 37.701133999999996],
            [-121.922434, 37.701178999999996],
            [-121.921551, 37.701447],
            [-121.919884, 37.701692],
            [-121.916445, 37.702017999999995],
            [-121.916271, 37.702017],
            [-121.91386, 37.702003999999995],
            [-121.912099, 37.701986],
            [-121.912066, 37.701986],
            [-121.90984399999999, 37.701944999999995],
            [-121.907198, 37.701896999999995],
            [-121.907068, 37.701729],
            [-121.906908, 37.701769999999996],
            [-121.90647899999999, 37.701884],
            [-121.905829, 37.701875],
            [-121.904879, 37.701882],
            [-121.904797, 37.701882999999995],
            [-121.904346, 37.701871],
            [-121.90420499999999, 37.701867],
            [-121.904004, 37.701862],
            [-121.903784, 37.701862],
            [-121.903644, 37.701862999999996],
            [-121.903245, 37.701864],
            [-121.90310000000001, 37.701865],
            [-121.902326, 37.701858],
            [-121.90205, 37.701871],
            [-121.901652, 37.70189],
            [-121.90105199999999, 37.701896999999995],
            [-121.900808, 37.7019],
            [-121.899548, 37.701895],
            [-121.899254, 37.70189],
            [-121.89865499999999, 37.701879999999996],
            [-121.89859799999999, 37.701879],
            [-121.898583, 37.701879],
            [-121.898429, 37.701876999999996],
            [-121.89837299999999, 37.701876999999996],
            [-121.898026, 37.701872],
            [-121.89741000000001, 37.701853],
            [-121.897361, 37.70185],
            [-121.896625, 37.701816],
            [-121.894326, 37.701788],
            [-121.893315, 37.701775999999995],
            [-121.893123, 37.701774],
            [-121.893019, 37.701772999999996],
            [-121.892133, 37.701769999999996],
            [-121.891838, 37.701769999999996],
            [-121.89180999999999, 37.701769999999996],
            [-121.89175, 37.701769999999996],
            [-121.891728, 37.701768],
            [-121.891701, 37.701767],
            [-121.889933, 37.701749],
            [-121.88836, 37.701733],
            [-121.888257, 37.701730999999995],
            [-121.88812300000001, 37.701730999999995],
            [-121.88798, 37.701732],
            [-121.88689, 37.701723],
            [-121.885689, 37.701713999999996],
            [-121.883651, 37.701704],
            [-121.883193, 37.7017],
            [-121.881961, 37.701691],
            [-121.881606, 37.701689],
            [-121.880543, 37.701685999999995],
            [-121.880189, 37.701685],
            [-121.879947, 37.701684],
            [-121.879224, 37.701681],
            [-121.878983, 37.701681],
            [-121.87895, 37.701679999999996],
            [-121.87885299999999, 37.701679999999996],
            [-121.878821, 37.701679999999996],
            [-121.878425, 37.701678],
            [-121.878187, 37.701678],
            [-121.877237, 37.701657],
            [-121.876842, 37.701648999999996],
            [-121.874578, 37.701614],
            [-121.872339, 37.70158],
            [-121.872227, 37.701578],
            [-121.872208, 37.701578],
            [-121.872024, 37.701574],
            [-121.871094, 37.701555],
            [-121.8708, 37.701549],
            [-121.868517, 37.701530999999996],
            [-121.867753, 37.701524],
            [-121.866711, 37.701516],
            [-121.86664, 37.701521],
            [-121.866593, 37.701515],
            [-121.866447, 37.701513999999996],
            [-121.866399, 37.701513999999996],
            [-121.86615499999999, 37.701510999999996],
            [-121.865683, 37.701508],
            [-121.86542299999999, 37.701505999999995],
            [-121.865179, 37.701505999999995],
            [-121.864966, 37.701505],
            [-121.86475, 37.701504],
            [-121.864328, 37.7015],
            [-121.864116, 37.701498],
            [-121.863225, 37.701488999999995],
            [-121.862845, 37.701485999999996],
            [-121.861706, 37.701478],
            [-121.860534, 37.701463],
            [-121.859613, 37.701446],
            [-121.859032, 37.70144],
            [-121.85812899999999, 37.701432],
            [-121.857762, 37.701429],
            [-121.857218, 37.701425],
            [-121.855587, 37.701412999999995],
            [-121.85504399999999, 37.701409999999996],
            [-121.854455, 37.701406999999996],
            [-121.853935, 37.701405],
            [-121.851474, 37.701398],
            [-121.850922, 37.701384999999995],
            [-121.85060899999999, 37.701378],
            [-121.849501, 37.701353999999995],
            [-121.849272, 37.701352],
            [-121.848585, 37.701347],
            [-121.848357, 37.701346],
            [-121.847684, 37.701341],
            [-121.845665, 37.701326],
            [-121.84499199999999, 37.701321],
            [-121.844961, 37.701321],
            [-121.844871, 37.701321],
            [-121.844841, 37.701321],
            [-121.844099, 37.701319],
            [-121.843854, 37.701316],
            [-121.843045, 37.701307],
            [-121.837658, 37.701252],
            [-121.835863, 37.701234],
            [-121.835334, 37.701228],
            [-121.833749, 37.701212],
            [-121.833381, 37.701209],
            [-121.833221, 37.701207],
            [-121.832604, 37.7012],
            [-121.832547, 37.7012],
            [-121.830756, 37.701189],
            [-121.83014, 37.701186],
            [-121.829845, 37.701184],
            [-121.82962599999999, 37.701183],
            [-121.82896, 37.701178],
            [-121.828666, 37.701177],
            [-121.828962, 37.701105999999996],
            [-121.829631, 37.700946],
            [-121.82985, 37.700893],
            [-121.830147, 37.700821999999995],
            [-121.83014, 37.700520999999995],
            [-121.83016, 37.70045],
            [-121.830229, 37.700362],
            [-121.830291, 37.700317999999996],
            [-121.83034, 37.700285],
            [-121.83070599999999, 37.700192],
            [-121.830921, 37.700115],
            [-121.831121, 37.700016],
            [-121.831287, 37.699911],
            [-121.831425, 37.699796],
            [-121.831467, 37.69978],
            [-121.831536, 37.699697],
            [-121.831605, 37.699642],
            [-121.831757, 37.699539],
            [-121.832103, 37.699307],
            [-121.832151, 37.699262999999995],
            [-121.8322, 37.699197],
            [-121.83249, 37.699048999999995],
            [-121.832649, 37.698944],
            [-121.833002, 37.698659],
            [-121.833119, 37.698575999999996],
            [-121.833181, 37.698482999999996],
            [-121.833195, 37.698417],
            [-121.833181, 37.698333999999996],
            [-121.833105, 37.698236],
            [-121.832967, 37.698098],
            [-121.832953, 37.698032],
            [-121.83296, 37.697972],
            [-121.83305, 37.697922],
            [-121.833527, 37.697784999999996],
            [-121.833956, 37.697641999999995],
            [-121.834296, 37.697568],
            [-121.834488, 37.697527],
            [-121.83485999999999, 37.697488],
            [-121.83491699999999, 37.697483],
            [-121.83500699999999, 37.697466],
            [-121.835332, 37.697367],
            [-121.83567, 37.697285],
            [-121.835788, 37.697219],
            [-121.835905, 37.697103],
            [-121.835947, 37.697021],
            [-121.835995, 37.696867],
            [-121.836037, 37.696774],
            [-121.836085, 37.696708],
            [-121.836327, 37.696504],
            [-121.836569, 37.696329],
            [-121.836617, 37.696307],
            [-121.836892, 37.696248],
            [-121.836852, 37.698001],
            [-121.836759, 37.700536],
            [-121.842338, 37.700542999999996],
            [-121.843007, 37.700544],
            [-121.844084, 37.700545999999996],
            [-121.846225, 37.700556],
            [-121.846283, 37.700520999999995],
            [-121.846449, 37.700398],
            [-121.846572, 37.700272999999996],
            [-121.84663, 37.7002],
            [-121.846695, 37.700120999999996],
            [-121.84676999999999, 37.699987],
            [-121.846859, 37.699788999999996],
            [-121.846922, 37.699599],
            [-121.847036, 37.699282],
            [-121.847155, 37.699037],
            [-121.847265, 37.698926],
            [-121.847382, 37.698855],
            [-121.847429, 37.698833],
            [-121.847504, 37.6988],
            [-121.84765, 37.698752],
            [-121.847832, 37.698730999999995],
            [-121.847902, 37.698724999999996],
            [-121.847925, 37.698723],
            [-121.847925, 37.698671],
            [-121.847925, 37.698657],
            [-121.84795, 37.697417],
            [-121.84796, 37.696982],
            [-121.84795, 37.696415],
            [-121.848048, 37.696455],
            [-121.848094, 37.696473999999995],
            [-121.848232, 37.696531],
            [-121.848278, 37.696549999999995],
            [-121.848276, 37.696216],
            [-121.848274, 37.695859999999996],
            [-121.848272, 37.695293],
            [-121.848889, 37.695032],
            [-121.849048, 37.694779],
            [-121.849108, 37.694609],
            [-121.849137, 37.694421999999996],
            [-121.849132, 37.693871],
            [-121.849028, 37.69387],
            [-121.848858, 37.693867999999995],
            [-121.848857, 37.693914],
            [-121.848807, 37.693867999999995],
            [-121.84873400000001, 37.693867],
            [-121.848591, 37.693864999999995],
            [-121.84848099999999, 37.693832],
            [-121.848453, 37.693788],
            [-121.848419, 37.693681999999995],
            [-121.848408, 37.693647],
            [-121.84839, 37.693491],
            [-121.848411, 37.693013],
            [-121.848404, 37.692945],
            [-121.848379, 37.692695],
            [-121.848355, 37.692442],
            [-121.848342, 37.692387],
            [-121.84818899999999, 37.692112],
            [-121.84792, 37.691826999999996],
            [-121.847837, 37.691766],
            [-121.847691, 37.691683999999995],
            [-121.847076, 37.691486],
            [-121.844784, 37.691248],
            [-121.843379, 37.691103],
            [-121.843163, 37.691081],
            [-121.843073, 37.691058999999996],
            [-121.84300400000001, 37.691026],
            [-121.842942, 37.690971],
            [-121.842693, 37.690695999999996],
            [-121.842395, 37.690388999999996],
            [-121.842195, 37.690201],
            [-121.841821, 37.68985],
            [-121.841365, 37.689395],
            [-121.84133, 37.689378],
            [-121.840203, 37.68828],
            [-121.838855, 37.687002],
            [-121.83883399999999, 37.686983],
            [-121.83788, 37.686029999999995],
            [-121.837763, 37.685914],
            [-121.837643, 37.685997],
            [-121.837602, 37.686037],
            [-121.83748, 37.686161],
            [-121.83744, 37.686202],
            [-121.83718, 37.685941],
            [-121.836362, 37.685094],
            [-121.83605, 37.684787],
            [-121.836022, 37.68476],
            [-121.835726, 37.68448],
            [-121.835398, 37.684139],
            [-121.83509699999999, 37.683834],
            [-121.834223, 37.68291],
            [-121.833986, 37.682649],
            [-121.83376, 37.682387],
            [-121.833387, 37.681906],
            [-121.832926, 37.681291],
            [-121.832738, 37.681079],
            [-121.83248, 37.680824],
            [-121.83229399999999, 37.680658],
            [-121.832208, 37.680588],
            [-121.832084, 37.680485],
            [-121.831894, 37.680337],
            [-121.831794, 37.680259],
            [-121.83028, 37.67912],
            [-121.829803, 37.679383],
            [-121.82885, 37.679908999999995],
            [-121.828724, 37.679978],
            [-121.827898, 37.680434999999996],
            [-121.825279, 37.681881],
            [-121.824844, 37.682119],
            [-121.824066, 37.682563],
            [-121.82364, 37.682806],
            [-121.82315299999999, 37.683085],
            [-121.822935, 37.683209999999995],
            [-121.822524, 37.683445],
            [-121.82041100000001, 37.684671],
            [-121.819649, 37.685114999999996],
            [-121.819647, 37.683122],
            [-121.819642, 37.678668],
            [-121.81963999999999, 37.676226],
            [-121.819638, 37.676003],
            [-121.819635, 37.675337],
            [-121.81963400000001, 37.675115],
            [-121.81963, 37.675059],
            [-121.819621, 37.674892],
            [-121.819619, 37.674837],
            [-121.81961799999999, 37.674789],
            [-121.819615, 37.674645999999996],
            [-121.819614, 37.674599],
            [-121.819991, 37.674558],
            [-121.820499, 37.674504],
            [-121.821124, 37.674431999999996],
            [-121.821502, 37.674389],
            [-121.822039, 37.674335],
            [-121.822548, 37.674278],
            [-121.822764, 37.674254],
            [-121.823709, 37.674155999999996],
            [-121.823729, 37.674154],
            [-121.825119, 37.674009999999996],
            [-121.825689, 37.67395],
            [-121.826737, 37.673840999999996],
            [-121.82674, 37.673887],
            [-121.82675, 37.674026],
            [-121.826754, 37.674073],
            [-121.826758, 37.674127],
            [-121.82677, 37.67429],
            [-121.826775, 37.674344999999995],
            [-121.828153, 37.674198],
            [-121.829143, 37.674078],
            [-121.829673, 37.674014],
            [-121.830635, 37.673929],
            [-121.83194499999999, 37.673776],
            [-121.833213, 37.673649],
            [-121.834362, 37.673522999999996],
            [-121.835823, 37.673356],
            [-121.836252, 37.673311999999996],
            [-121.836444, 37.673293],
            [-121.837426, 37.673185],
            [-121.83758, 37.673169],
            [-121.83757800000001, 37.673324],
            [-121.837544, 37.678748],
            [-121.837544, 37.678858999999996],
            [-121.838635, 37.678495],
            [-121.843542, 37.676859],
            [-121.846012, 37.676035999999996],
            [-121.846801, 37.675771999999995],
            [-121.850431, 37.675855999999996],
            [-121.850517, 37.675771999999995],
            [-121.85063, 37.675716],
            [-121.850721, 37.675703999999996],
            [-121.85081, 37.675692999999995],
            [-121.851269, 37.675694],
            [-121.85233099999999, 37.675726],
            [-121.852769, 37.675731],
            [-121.853748, 37.675743],
            [-121.854738, 37.675742],
            [-121.857406, 37.675824999999996],
            [-121.858031, 37.675849],
            [-121.858278, 37.67588],
            [-121.858597, 37.675933],
            [-121.858826, 37.675957],
            [-121.858908, 37.675957],
            [-121.859402, 37.675959],
            [-121.859923, 37.675967],
            [-121.860957, 37.676015],
            [-121.861069, 37.676021],
            [-121.86132, 37.676023],
            [-121.861468, 37.676024999999996],
            [-121.861864, 37.676001],
            [-121.862052, 37.675982999999995],
            [-121.862314, 37.67594],
            [-121.862402, 37.675906],
            [-121.862481, 37.675877],
            [-121.862571, 37.675827999999996],
            [-121.86265, 37.675775],
            [-121.862714, 37.675723],
            [-121.862776, 37.67577],
            [-121.86296300000001, 37.675913],
            [-121.86302599999999, 37.675961],
            [-121.863411, 37.676226],
            [-121.863518, 37.676308],
            [-121.863807, 37.67653],
            [-121.863888, 37.676593],
            [-121.864556, 37.677119],
            [-121.864959, 37.677400999999996],
            [-121.865453, 37.677748],
            [-121.865551, 37.67782],
            [-121.865848, 37.678039],
            [-121.865947, 37.678112],
            [-121.865985, 37.678138],
            [-121.86609899999999, 37.678216],
            [-121.866138, 37.678242],
            [-121.866227, 37.678308],
            [-121.866495, 37.678506],
            [-121.866585, 37.678571999999996],
            [-121.86671799999999, 37.678664999999995],
            [-121.867121, 37.678947],
            [-121.867255, 37.679041],
            [-121.867813, 37.679437],
            [-121.868311, 37.679791],
            [-121.868975, 37.6803],
            [-121.869462, 37.680662999999996],
            [-121.870012, 37.681073],
            [-121.870156, 37.681172],
            [-121.870588, 37.681469],
            [-121.870733, 37.681568999999996],
            [-121.870829, 37.681638],
            [-121.871117, 37.681846],
            [-121.871213, 37.681916],
            [-121.872093, 37.682531999999995],
            [-121.872427, 37.682773],
            [-121.872556, 37.682867],
            [-121.873203, 37.683357],
            [-121.873741, 37.683752],
            [-121.874614, 37.684384],
            [-121.875455, 37.684982999999995],
            [-121.876038, 37.68539],
            [-121.876102, 37.685435],
            [-121.876732, 37.685859],
            [-121.877049, 37.686021],
            [-121.87731099999999, 37.686155],
            [-121.877428, 37.686512],
            [-121.877471, 37.686644],
            [-121.877737, 37.687597],
            [-121.877805, 37.687839],
            [-121.877843, 37.687959],
            [-121.877864, 37.688037],
            [-121.87799799999999, 37.688463999999996],
            [-121.878146, 37.688893],
            [-121.878682, 37.688739999999996],
            [-121.879964, 37.688376],
            [-121.880186, 37.688313],
            [-121.88028800000001, 37.688283999999996],
            [-121.880674, 37.688173],
            [-121.881006, 37.688078],
            [-121.881833, 37.687844],
            [-121.88222, 37.687734999999996],
            [-121.882686, 37.687602],
            [-121.883298, 37.687428],
            [-121.883526, 37.687346],
            [-121.884078, 37.687173],
            [-121.884542, 37.687028],
            [-121.884675, 37.686986],
            [-121.885077, 37.686859999999996],
            [-121.885211, 37.686819],
            [-121.885897, 37.686603999999996],
            [-121.886279, 37.686485],
            [-121.88683499999999, 37.686248],
            [-121.886532, 37.686138],
            [-121.886516, 37.686129],
            [-121.885701, 37.685172],
            [-121.885753, 37.685151],
            [-121.88591, 37.685092],
            [-121.885963, 37.685072999999996],
            [-121.88596799999999, 37.685052999999996],
            [-121.885997, 37.685019],
            [-121.886079, 37.684976999999996],
            [-121.88613, 37.684956],
            [-121.886234, 37.684916],
            [-121.886435, 37.684832],
            [-121.886706, 37.68472],
            [-121.886721, 37.684714],
            [-121.886922, 37.684644999999996],
            [-121.88709, 37.684585],
            [-121.887376, 37.684484999999995],
            [-121.887594, 37.684405999999996],
            [-121.887763, 37.684346999999995],
            [-121.887922, 37.684281999999996],
            [-121.888021, 37.684242999999995],
            [-121.888204, 37.684182],
            [-121.888408, 37.684107999999995],
            [-121.88857, 37.68405],
            [-121.888761, 37.683977999999996],
            [-121.88914, 37.683838],
            [-121.889286, 37.683783999999996],
            [-121.889335, 37.683768],
            [-121.88953000000001, 37.683707999999996],
            [-121.889691, 37.683648999999996],
            [-121.889764, 37.683624],
            [-121.890025, 37.683524999999996],
            [-121.890175, 37.683471],
            [-121.890337, 37.683413],
            [-121.890495, 37.68335],
            [-121.890735, 37.683254999999996],
            [-121.890884, 37.683202],
            [-121.89097100000001, 37.683161999999996],
            [-121.890996, 37.683150999999995],
            [-121.89112399999999, 37.683087],
            [-121.891277, 37.682998999999995],
            [-121.891496, 37.682876],
            [-121.891554, 37.682843],
            [-121.891742, 37.682742],
            [-121.891899, 37.68266],
            [-121.891824, 37.682551],
            [-121.89211, 37.682390999999996],
            [-121.892292, 37.682296],
            [-121.892392, 37.682238],
            [-121.89255299999999, 37.682154],
            [-121.892875, 37.682018],
            [-121.893241, 37.681875],
            [-121.893588, 37.681751999999996],
            [-121.89368, 37.681714],
            [-121.89377, 37.68167],
            [-121.894068, 37.681512999999995],
            [-121.89491, 37.681066],
            [-121.895175, 37.680944],
            [-121.89531, 37.680889],
            [-121.896079, 37.680549],
            [-121.89647099999999, 37.680388],
            [-121.896556, 37.680358],
            [-121.89670699999999, 37.680305],
            [-121.896892, 37.68023],
            [-121.897221, 37.6801],
            [-121.897287, 37.680065],
            [-121.897375, 37.680009],
            [-121.897537, 37.679876],
            [-121.897584, 37.679846999999995],
            [-121.897659, 37.679811],
            [-121.897708, 37.679792],
            [-121.897781, 37.679783],
            [-121.897836, 37.679784],
            [-121.89785499999999, 37.679784999999995],
            [-121.897903, 37.679791],
            [-121.89796, 37.679805],
            [-121.898021, 37.679826],
            [-121.898084, 37.679867],
            [-121.898156, 37.679809],
            [-121.898305, 37.679662],
            [-121.89838, 37.67957],
            [-121.898432, 37.679494],
            [-121.898532, 37.679317],
            [-121.8987, 37.679023],
            [-121.898772, 37.678917999999996],
            [-121.898842, 37.678829],
            [-121.899013, 37.678643],
            [-121.899599, 37.678039999999996],
            [-121.899658, 37.677982],
            [-121.899755, 37.677884],
            [-121.899854, 37.677786],
            [-121.89965, 37.677655],
            [-121.89913, 37.677324],
            [-121.8987, 37.677048],
            [-121.89842999999999, 37.676876],
            [-121.898383, 37.676846999999995],
            [-121.898586, 37.676764999999996],
            [-121.898604, 37.676756999999995],
            [-121.899016, 37.676589],
            [-121.90045599999999, 37.676002],
            [-121.900916, 37.675815],
            [-121.90155, 37.675557999999995],
            [-121.90172799999999, 37.675486],
            [-121.901873, 37.675426],
            [-121.902841, 37.675028999999995],
            [-121.903164, 37.674897],
            [-121.903932, 37.674580999999996],
            [-121.906238, 37.673634],
            [-121.907007, 37.673319],
            [-121.90718799999999, 37.673244],
            [-121.907531, 37.673103],
            [-121.907734, 37.67302],
            [-121.907916, 37.672945999999996],
            [-121.9081, 37.672869999999996],
            [-121.908628, 37.672654],
            [-121.908655, 37.672641999999996],
            [-121.908839, 37.672564],
            [-121.909016, 37.672488],
            [-121.909549, 37.672261],
            [-121.909727, 37.672185999999996],
            [-121.90988, 37.672121],
            [-121.909893, 37.672115999999995],
            [-121.910225, 37.671945],
            [-121.91026600000001, 37.671901],
            [-121.910286, 37.671853999999996],
            [-121.91032799999999, 37.671759],
            [-121.910342, 37.671698],
            [-121.91047499999999, 37.671665],
            [-121.910876, 37.671566],
            [-121.91101, 37.671534],
            [-121.910954, 37.671392999999995],
            [-121.91094, 37.671357],
            [-121.910736, 37.670826],
            [-121.910668, 37.670649],
            [-121.910642, 37.670583],
            [-121.910612, 37.670504],
            [-121.91056499999999, 37.670387],
            [-121.91054, 37.670322],
            [-121.91049699999999, 37.670214],
            [-121.910461, 37.670121],
            [-121.910439, 37.670065],
            [-121.91037, 37.669891],
            [-121.91035, 37.669838999999996],
            [-121.91032899999999, 37.669784],
            [-121.910262, 37.669602999999995],
            [-121.910243, 37.66955],
            [-121.910127, 37.669242],
            [-121.910054, 37.669063],
            [-121.909997, 37.668923],
            [-121.909982, 37.668884999999996],
            [-121.90993499999999, 37.668768],
            [-121.909876, 37.66862],
            [-121.909794, 37.668419],
            [-121.90974800000001, 37.668303],
            [-121.909737, 37.668275],
            [-121.909722, 37.668237999999995],
            [-121.909705, 37.668192999999995],
            [-121.909695, 37.668166],
            [-121.909508, 37.667676],
            [-121.90937199999999, 37.667318],
            [-121.90911, 37.666675999999995],
            [-121.908925, 37.666216],
            [-121.90872999999999, 37.665729999999996],
            [-121.908621, 37.665493999999995],
            [-121.90847099999999, 37.665169],
            [-121.908281, 37.664795999999996],
            [-121.908269, 37.664773],
            [-121.908162, 37.664567],
            [-121.908078, 37.664406],
            [-121.908014, 37.664293],
            [-121.907704, 37.663745],
            [-121.907544, 37.663489],
            [-121.90746899999999, 37.663368],
            [-121.907377, 37.663228],
            [-121.907306, 37.66312],
            [-121.90729, 37.663095999999996],
            [-121.90708599999999, 37.662801],
            [-121.907013, 37.662696],
            [-121.906907, 37.662543],
            [-121.906879, 37.662504999999996],
            [-121.906466, 37.661943],
            [-121.906357, 37.661794],
            [-121.906326, 37.661758],
            [-121.906379, 37.661708999999995],
            [-121.90641099999999, 37.66167],
            [-121.906473, 37.661597],
            [-121.906504, 37.661561],
            [-121.906633, 37.66138],
            [-121.90670399999999, 37.661280999999995],
            [-121.90673699999999, 37.661207],
            [-121.90687, 37.660917999999995],
            [-121.906843, 37.660682],
            [-121.906815, 37.660571999999995],
            [-121.90659099999999, 37.660123999999996],
            [-121.906477, 37.659895999999996],
            [-121.906222, 37.659012],
            [-121.906125, 37.658615999999995],
            [-121.905952, 37.658259],
            [-121.905766, 37.657809],
            [-121.905704, 37.657593999999996],
            [-121.905442, 37.656935],
            [-121.905407, 37.656814],
            [-121.905373, 37.65659],
            [-121.905325, 37.656265],
            [-121.905118, 37.655401999999995],
            [-121.905112, 37.655367999999996],
            [-121.904188, 37.655819],
            [-121.90415999999999, 37.655787],
            [-121.904054, 37.655651],
            [-121.903849, 37.655384],
            [-121.903786, 37.655314],
            [-121.903724, 37.65526],
            [-121.903618, 37.655189],
            [-121.903485, 37.655125999999996],
            [-121.903341, 37.655083],
            [-121.901707, 37.654814],
            [-121.901581, 37.654781],
            [-121.901463, 37.654733],
            [-121.90138999999999, 37.654693],
            [-121.901291, 37.654621999999996],
            [-121.901234, 37.654568],
            [-121.90117599999999, 37.654499],
            [-121.900975, 37.654171999999996],
            [-121.901046, 37.654126],
            [-121.90134499999999, 37.653971999999996],
            [-121.901455, 37.653917],
            [-121.901757, 37.653743],
            [-121.902047, 37.653563999999996],
            [-121.902062, 37.653549999999996],
            [-121.902078, 37.653513],
            [-121.902079, 37.653481],
            [-121.902062, 37.65345],
            [-121.901831, 37.653222],
            [-121.901532, 37.652927],
            [-121.90142399999999, 37.652832],
            [-121.901373, 37.652794],
            [-121.90134, 37.652769],
            [-121.901122, 37.652629],
            [-121.90102999999999, 37.652556],
            [-121.900934, 37.652462],
            [-121.900892, 37.652408],
            [-121.900854, 37.65236],
            [-121.90078199999999, 37.652234],
            [-121.900766, 37.652192],
            [-121.900743, 37.652108],
            [-121.900724, 37.652038999999995],
            [-121.900709, 37.651919],
            [-121.900714, 37.651717999999995],
            [-121.900718, 37.651587],
            [-121.900717, 37.651404],
            [-121.900705, 37.651308],
            [-121.900684, 37.651244],
            [-121.900655, 37.65115],
            [-121.900592, 37.651024],
            [-121.900496, 37.650883],
            [-121.900108, 37.650386],
            [-121.899895, 37.650113],
            [-121.899648, 37.649797],
            [-121.89928499999999, 37.649331],
            [-121.898913, 37.648877],
            [-121.898898, 37.648861],
            [-121.89863199999999, 37.648562999999996],
            [-121.89850200000001, 37.64843],
            [-121.898113, 37.648030999999996],
            [-121.897984, 37.647898],
            [-121.897941, 37.647847],
            [-121.89782199999999, 37.647704],
            [-121.897815, 37.647695],
            [-121.89777699999999, 37.647642],
            [-121.897746, 37.647600000000004],
            [-121.897738, 37.647588],
            [-121.897707, 37.647531],
            [-121.897681, 37.647482],
            [-121.897674, 37.647464],
            [-121.897656, 37.647417],
            [-121.89764199999999, 37.64738],
            [-121.897632, 37.647354],
            [-121.897613, 37.647267],
            [-121.897605, 37.64723],
            [-121.897577, 37.647082],
            [-121.89757399999999, 37.647062999999996],
            [-121.897568, 37.646929],
            [-121.897577, 37.646833],
            [-121.897609, 37.646636],
            [-121.897634, 37.646488],
            [-121.897639, 37.646457999999996],
            [-121.897661, 37.646335],
            [-121.897665, 37.64622],
            [-121.897654, 37.646105],
            [-121.897643, 37.646059],
            [-121.897622, 37.645973],
            [-121.897571, 37.645845],
            [-121.897059, 37.644904],
            [-121.896853, 37.644525],
            [-121.89678599999999, 37.644402],
            [-121.89671899999999, 37.644307],
            [-121.896642, 37.644231],
            [-121.896626, 37.644219],
            [-121.89657199999999, 37.644179],
            [-121.896506, 37.64414],
            [-121.896398, 37.644092],
            [-121.89627, 37.644056],
            [-121.896176, 37.644041],
            [-121.896067, 37.644036],
            [-121.89595800000001, 37.644042],
            [-121.895851, 37.644061],
            [-121.895748, 37.644090999999996],
            [-121.89564, 37.644138],
            [-121.895571, 37.644180999999996],
            [-121.895551, 37.644197],
            [-121.895504, 37.644239],
            [-121.895422, 37.644334],
            [-121.895314, 37.644496],
            [-121.89514, 37.644756],
            [-121.895066, 37.644869],
            [-121.894716, 37.645437],
            [-121.894634, 37.645548999999995],
            [-121.894451, 37.645803],
            [-121.894404, 37.645865],
            [-121.894357, 37.645927],
            [-121.894052, 37.646328],
            [-121.89402, 37.646370999999995],
            [-121.893952, 37.64646],
            [-121.893889, 37.646561],
            [-121.893874, 37.646578],
            [-121.893608, 37.646907],
            [-121.893575, 37.646949],
            [-121.8935, 37.646996],
            [-121.893273, 37.647221],
            [-121.893047, 37.647448],
            [-121.89289, 37.647635],
            [-121.892636, 37.647937999999996],
            [-121.89258, 37.648005999999995],
            [-121.892431, 37.648182999999996],
            [-121.892266, 37.648025],
            [-121.89203, 37.647799],
            [-121.89177, 37.647554],
            [-121.891605, 37.647397999999995],
            [-121.891576, 37.647371],
            [-121.891491, 37.64729],
            [-121.891463, 37.647264],
            [-121.891429, 37.647231999999995],
            [-121.891331, 37.647133],
            [-121.891299, 37.6471],
            [-121.890753, 37.646544999999996],
            [-121.889144, 37.644993],
            [-121.888461, 37.644343],
            [-121.888392, 37.644276999999995],
            [-121.888383, 37.644268],
            [-121.888358, 37.644244],
            [-121.88835, 37.644236],
            [-121.888329, 37.644217],
            [-121.888318, 37.644206],
            [-121.887125, 37.643049999999995],
            [-121.886534, 37.642492],
            [-121.885656, 37.641667999999996],
            [-121.885487, 37.641498],
            [-121.88531, 37.641301999999996],
            [-121.88514, 37.641090999999996],
            [-121.885012, 37.640916],
            [-121.884734, 37.640488],
            [-121.88466, 37.640353999999995],
            [-121.884529, 37.640094999999995],
            [-121.884451, 37.639993],
            [-121.884418, 37.639949],
            [-121.884497, 37.639908],
            [-121.884581, 37.639865],
            [-121.884686, 37.639812],
            [-121.88479699999999, 37.639755],
            [-121.88562999999999, 37.639334],
            [-121.88576, 37.639268],
            [-121.885919, 37.639171999999995],
            [-121.885983, 37.639134],
            [-121.886057, 37.639089],
            [-121.886192, 37.639007],
            [-121.886296, 37.638949],
            [-121.886344, 37.638923999999996],
            [-121.886494, 37.638826],
            [-121.886567, 37.638771999999996],
            [-121.886591, 37.63875],
            [-121.886635, 37.638709999999996],
            [-121.886674, 37.638664999999996],
            [-121.887059, 37.638557999999996],
            [-121.888214, 37.638239],
            [-121.888576, 37.63814],
            [-121.888599, 37.638132999999996],
            [-121.888947, 37.638022],
            [-121.889304, 37.63791],
            [-121.889996, 37.6377],
            [-121.890287, 37.637613],
            [-121.89034699999999, 37.637595],
            [-121.8905, 37.637547999999995],
            [-121.89096, 37.637408],
            [-121.891114, 37.637361999999996],
            [-121.891238, 37.637572999999996],
            [-121.891389, 37.637783999999996],
            [-121.891535, 37.637935999999996],
            [-121.891625, 37.638016],
            [-121.891757, 37.638107999999995],
            [-121.892327, 37.638507],
            [-121.893142, 37.639039],
            [-121.893721, 37.639458999999995],
            [-121.893751, 37.639435],
            [-121.893818, 37.639381],
            [-121.893922, 37.6393],
            [-121.89428, 37.639016],
            [-121.89707899999999, 37.640992],
            [-121.89713499999999, 37.641754999999996],
            [-121.897144, 37.637547999999995],
            [-121.897142, 37.637443999999995],
            [-121.897288, 37.637358],
            [-121.90218899999999, 37.634482],
            [-121.902277, 37.634488],
            [-121.902337, 37.634479],
            [-121.902398, 37.634462],
            [-121.902475, 37.634422],
            [-121.90252699999999, 37.634388],
            [-121.902663, 37.634307],
            [-121.902762, 37.63424],
            [-121.902799, 37.634183],
            [-121.902807, 37.634116999999996],
            [-121.90568, 37.632433999999996],
            [-121.907157, 37.632478],
            [-121.911024, 37.632619],
            [-121.91136900000001, 37.632632],
            [-121.912314, 37.63267],
            [-121.912442, 37.632759],
            [-121.91254599999999, 37.632756],
            [-121.91260000000001, 37.632757999999995],
            [-121.91268600000001, 37.632756],
            [-121.913071, 37.632746999999995],
            [-121.913423, 37.632737999999996],
            [-121.913669, 37.632732],
            [-121.91398699999999, 37.633131999999996],
            [-121.914091, 37.633218],
            [-121.914273, 37.633482],
            [-121.91481, 37.633933999999996],
            [-121.91507, 37.634201999999995],
            [-121.915076, 37.634211],
            [-121.915369, 37.634631999999996],
            [-121.915727, 37.634994999999996],
            [-121.915778, 37.635113],
            [-121.915785, 37.635129],
            [-121.915857, 37.635298],
            [-121.916045, 37.635732999999995],
            [-121.916053, 37.635752],
            [-121.916167, 37.636016],
            [-121.916352, 37.636444999999995],
            [-121.91705400000001, 37.636994],
            [-121.917338, 37.637248],
            [-121.917964, 37.637809],
            [-121.918229, 37.638047],
            [-121.920221, 37.63989],
            [-121.920274, 37.639939],
            [-121.922135, 37.642036999999995],
            [-121.923269, 37.643332],
            [-121.923585, 37.643822],
            [-121.924439, 37.644997],
            [-121.925769, 37.647209],
            [-121.926118, 37.647788999999996],
            [-121.926533, 37.648466],
            [-121.927369, 37.649831],
            [-121.927604, 37.650254],
            [-121.928012, 37.650985999999996],
            [-121.928521, 37.651855999999995],
            [-121.92958899999999, 37.653679],
            [-121.93061, 37.655895],
            [-121.930801, 37.656651],
            [-121.93115, 37.658032],
            [-121.931171, 37.658266],
            [-121.931282, 37.659501999999996],
            [-121.931597, 37.660546],
            [-121.93179, 37.660558],
            [-121.931943, 37.660575],
            [-121.93219500000001, 37.660613999999995],
            [-121.932689, 37.660697],
            [-121.932741, 37.660705],
            [-121.93311800000001, 37.660768999999995],
            [-121.93322, 37.660782999999995],
            [-121.933309, 37.660804],
            [-121.933384, 37.66083],
            [-121.933459, 37.660862],
            [-121.93352999999999, 37.660905],
            [-121.933613, 37.660979999999995],
            [-121.933773, 37.661104],
            [-121.933818, 37.661144],
            [-121.933888, 37.661249],
            [-121.933913, 37.661336999999996],
            [-121.933915, 37.661463],
            [-121.93390000000001, 37.661598],
            [-121.933894, 37.661722],
            [-121.933905, 37.661840999999995],
            [-121.93394599999999, 37.661955],
            [-121.934007, 37.662045],
            [-121.934128, 37.662186],
            [-121.93431, 37.662304999999996],
            [-121.93437, 37.662337],
            [-121.934489, 37.662388],
            [-121.93457599999999, 37.662417999999995],
            [-121.934753, 37.662455],
            [-121.93495, 37.662479999999995],
            [-121.935052, 37.662483],
            [-121.935142, 37.66249],
            [-121.935197, 37.662501],
            [-121.93528599999999, 37.662518999999996],
            [-121.93538699999999, 37.662549],
            [-121.935581, 37.662622999999996],
            [-121.935694, 37.662673],
            [-121.936249, 37.662969],
            [-121.936726, 37.663201],
            [-121.93705299999999, 37.663398],
            [-121.937172, 37.663461999999996],
            [-121.93730000000001, 37.663523999999995],
            [-121.937424, 37.663571999999995],
            [-121.937484, 37.663591],
            [-121.937667, 37.663601],
            [-121.937733, 37.663602],
            [-121.937823, 37.663587],
            [-121.93816699999999, 37.663491],
            [-121.938356, 37.663452],
            [-121.938681, 37.663419],
            [-121.938818, 37.663409],
            [-121.939005, 37.663404],
            [-121.939157, 37.663405],
            [-121.939264, 37.663415],
            [-121.939331, 37.663429],
            [-121.939407, 37.663446],
            [-121.93957499999999, 37.663506],
            [-121.939652, 37.663529],
            [-121.939742, 37.663548],
            [-121.939832, 37.663548999999996],
            [-121.93991, 37.663540999999995],
            [-121.939982, 37.663525],
            [-121.940073, 37.663499],
            [-121.94023, 37.663427999999996],
            [-121.940332, 37.663396],
            [-121.940413, 37.663378],
            [-121.940513, 37.66335],
            [-121.940605, 37.663333],
            [-121.940713, 37.663322],
            [-121.940875, 37.663317],
            [-121.941271, 37.663320999999996],
            [-121.941475, 37.66334],
            [-121.941684, 37.663376],
            [-121.941959, 37.66348],
            [-121.942318, 37.663657],
            [-121.942431, 37.663700999999996],
            [-121.942568, 37.663733],
            [-121.9427, 37.663757],
            [-121.942874, 37.663762999999996],
            [-121.94300000000001, 37.663759999999996],
            [-121.943229, 37.663720999999995],
            [-121.943358, 37.663692999999995],
            [-121.94346, 37.663681],
            [-121.943554, 37.663663],
            [-121.943565, 37.663677],
            [-121.943705, 37.663737],
            [-121.943752, 37.663767],
            [-121.94381899999999, 37.663827999999995],
            [-121.943913, 37.663941],
            [-121.944012, 37.664083999999995],
            [-121.944142, 37.664254],
            [-121.944193, 37.664307],
            [-121.944264, 37.66435],
            [-121.94436400000001, 37.664412],
            [-121.944504, 37.664463],
            [-121.944565, 37.664471999999996],
            [-121.944639, 37.664474999999996],
            [-121.944784, 37.664456],
            [-121.945011, 37.664412999999996],
            [-121.94513, 37.664383],
            [-121.945295, 37.664345999999995],
            [-121.94548, 37.664336],
            [-121.945662, 37.664336],
            [-121.94577, 37.664348],
            [-121.94588999999999, 37.664367],
            [-121.946123, 37.664428],
            [-121.946218, 37.664466999999995],
            [-121.946375, 37.664522],
            [-121.946571, 37.664584999999995],
            [-121.946797, 37.664652],
            [-121.947005, 37.664705999999995],
            [-121.947174, 37.664759],
            [-121.947401, 37.664856],
            [-121.947585, 37.66493],
            [-121.947639, 37.664955],
            [-121.947719, 37.665006999999996],
            [-121.948024, 37.665245],
            [-121.948133, 37.66532],
            [-121.948363, 37.665448999999995],
            [-121.948385, 37.665462],
            [-121.948751, 37.665641],
            [-121.948871, 37.665712],
            [-121.94900000000001, 37.665791999999996],
            [-121.949377, 37.666072],
            [-121.949509, 37.666160999999995],
            [-121.949633, 37.666226],
            [-121.949923, 37.666399],
            [-121.949985, 37.666449],
            [-121.95021299999999, 37.666695],
            [-121.95052799999999, 37.666973],
            [-121.950672, 37.667091],
            [-121.951006, 37.667319],
            [-121.951047, 37.667347],
            [-121.951138, 37.667412],
            [-121.951161, 37.667428],
            [-121.951291, 37.667522],
            [-121.951425, 37.667609],
            [-121.951549, 37.667696],
            [-121.951632, 37.667747999999996],
            [-121.951793, 37.667849],
            [-121.951852, 37.667887],
            [-121.951978, 37.667991],
            [-121.952054, 37.668043],
            [-121.952226, 37.668126],
            [-121.952312, 37.668175],
            [-121.952491, 37.668304],
            [-121.952626, 37.668409],
            [-121.952709, 37.668489],
            [-121.952754, 37.668546],
            [-121.952783, 37.668594],
            [-121.952805, 37.668644],
            [-121.95282, 37.668696],
            [-121.952829, 37.668748],
            [-121.95283, 37.668800999999995],
            [-121.952825, 37.668853999999996],
            [-121.952812, 37.668906],
            [-121.952793, 37.668957],
            [-121.95276799999999, 37.669005999999996],
            [-121.952598, 37.669233999999996],
            [-121.95247, 37.66943],
            [-121.952436, 37.669503999999996],
            [-121.952415, 37.669576],
            [-121.952402, 37.669666],
            [-121.952403, 37.669757],
            [-121.952415, 37.669843],
            [-121.95244, 37.669967],
            [-121.952539, 37.670300000000005],
            [-121.952551, 37.670369],
            [-121.952559, 37.670486],
            [-121.952527, 37.670685999999996],
            [-121.952468, 37.671074],
            [-121.952471, 37.671155999999996],
            [-121.952511, 37.671372],
            [-121.952551, 37.671489],
            [-121.952657, 37.671698],
            [-121.952716, 37.671777],
            [-121.95279099999999, 37.671855],
            [-121.95304999999999, 37.672081],
            [-121.953211, 37.672201],
            [-121.953328, 37.672276],
            [-121.953438, 37.672332],
            [-121.953508, 37.672362],
            [-121.953584, 37.672382999999996],
            [-121.953697, 37.672407],
            [-121.953817, 37.672422999999995],
            [-121.953934, 37.672432],
            [-121.954234, 37.672444],
            [-121.954466, 37.672477],
            [-121.954619, 37.672516],
            [-121.954801, 37.672571],
            [-121.955337, 37.672747],
            [-121.956105, 37.673015],
            [-121.956211, 37.673052999999996],
            [-121.956426, 37.673138],
            [-121.956532, 37.673193],
            [-121.956725, 37.673269],
            [-121.956851, 37.673313],
            [-121.957022, 37.673356999999996],
            [-121.957367, 37.673471],
            [-121.957749, 37.673648],
            [-121.958105, 37.673745],
            [-121.95814, 37.673756999999995],
            [-121.95836800000001, 37.673842],
            [-121.958555, 37.673905],
            [-121.958631, 37.673944],
            [-121.958715, 37.673992],
            [-121.95882, 37.67406],
            [-121.958944, 37.674155999999996],
            [-121.959078, 37.674281],
            [-121.95922, 37.674397],
            [-121.959337, 37.674473],
            [-121.95945499999999, 37.674526],
            [-121.959563, 37.674563],
            [-121.959656, 37.674582],
            [-121.959814, 37.674605],
            [-121.96002899999999, 37.674634],
            [-121.96015, 37.674644],
            [-121.96020899999999, 37.674654],
            [-121.960381, 37.674698],
            [-121.96042800000001, 37.674717],
            [-121.960509, 37.674754],
            [-121.96057, 37.674783],
            [-121.960708, 37.674896],
            [-121.960731, 37.674915],
            [-121.960868, 37.675025],
            [-121.960946, 37.675067999999996],
            [-121.961015, 37.675094],
            [-121.961093, 37.675106],
            [-121.961165, 37.6751],
            [-121.961219, 37.675089],
            [-121.961303, 37.675044],
            [-121.96134, 37.675014999999995],
            [-121.961355, 37.674996],
            [-121.961371, 37.674977],
            [-121.96140199999999, 37.67491],
            [-121.961448, 37.674769],
            [-121.96146999999999, 37.674656],
            [-121.9615, 37.674549999999996],
            [-121.961572, 37.674364],
            [-121.961688, 37.674130999999996],
            [-121.961719, 37.674082],
            [-121.961736, 37.674057],
            [-121.961795, 37.673985],
            [-121.961918, 37.673885],
            [-121.961975, 37.673845],
            [-121.962111, 37.673764999999996],
            [-121.962225, 37.67371],
            [-121.962347, 37.673660999999996],
            [-121.962563, 37.673567999999996],
            [-121.96275, 37.673466],
            [-121.96284299999999, 37.673404],
            [-121.963064, 37.673246],
            [-121.963132, 37.673181],
            [-121.963166, 37.673139],
            [-121.963236, 37.673034],
            [-121.963246, 37.673007],
            [-121.963263, 37.672944],
            [-121.96329, 37.672776999999996],
            [-121.96331, 37.672720999999996],
            [-121.963325, 37.672695],
            [-121.963497, 37.672418],
            [-121.96351, 37.672346],
            [-121.963504, 37.672272],
            [-121.963484, 37.672188],
            [-121.963473, 37.672118999999995],
            [-121.963473, 37.672005999999996],
            [-121.96348, 37.671934],
            [-121.963513, 37.671819],
            [-121.963545, 37.671754],
            [-121.96364, 37.671614999999996],
            [-121.963713, 37.671524],
            [-121.963765, 37.67149],
            [-121.963829, 37.671482],
            [-121.96397999999999, 37.671473999999996],
            [-121.964067, 37.671479],
            [-121.964841, 37.671614999999996],
            [-121.965402, 37.671683],
            [-121.965706, 37.671703],
            [-121.965828, 37.671704],
            [-121.96593, 37.671698],
            [-121.966387, 37.671653],
            [-121.966718, 37.671602],
            [-121.966945, 37.671561],
            [-121.967128, 37.671521999999996],
            [-121.967372, 37.67145],
            [-121.967456, 37.671434999999995],
            [-121.967597, 37.671428999999996],
            [-121.967757, 37.671445999999996],
            [-121.96789799999999, 37.671472],
            [-121.96803, 37.671504999999996],
            [-121.968192, 37.671554],
            [-121.96877599999999, 37.671804],
            [-121.969236, 37.672027],
            [-121.969469, 37.672104],
            [-121.969582, 37.672140999999996],
            [-121.96966499999999, 37.672160999999996],
            [-121.969728, 37.672180999999995],
            [-121.969787, 37.672205999999996],
            [-121.97056599999999, 37.67264],
            [-121.970721, 37.672745],
            [-121.970833, 37.672838999999996],
            [-121.970941, 37.67295],
            [-121.971121, 37.673152],
            [-121.971216, 37.673241],
            [-121.971328, 37.673334],
            [-121.971468, 37.673412],
            [-121.971572, 37.673454],
            [-121.971605, 37.673463],
            [-121.97167, 37.673480999999995],
            [-121.971875, 37.673555],
            [-121.972061, 37.673631],
            [-121.97236, 37.673744],
            [-121.972453, 37.673786],
            [-121.972645, 37.673885999999996],
            [-121.97280599999999, 37.673987],
            [-121.97288, 37.674026],
            [-121.973125, 37.674098],
            [-121.973332, 37.674138],
            [-121.973405, 37.674143],
            [-121.973531, 37.674141999999996],
            [-121.973612, 37.674136],
            [-121.973731, 37.674121],
            [-121.973859, 37.674098],
            [-121.974064, 37.674068],
            [-121.974203, 37.67404],
            [-121.97423, 37.674033],
            [-121.974408, 37.674023999999996],
            [-121.97451, 37.674015],
            [-121.974671, 37.674012],
            [-121.974756, 37.674023999999996],
            [-121.974831, 37.674045],
            [-121.974928, 37.674105],
            [-121.975038, 37.674202],
            [-121.975054, 37.674223],
            [-121.975083, 37.674259],
            [-121.975228, 37.674486],
            [-121.975367, 37.674683],
            [-121.975433, 37.674765],
            [-121.97550799999999, 37.674839999999996],
            [-121.975697, 37.674965],
            [-121.975817, 37.675027],
            [-121.976064, 37.675185],
            [-121.976118, 37.675211999999995],
            [-121.976332, 37.675275],
            [-121.97656599999999, 37.675349],
            [-121.976747, 37.675443],
            [-121.976811, 37.675481999999995],
            [-121.97692, 37.675587],
            [-121.976943, 37.675616],
            [-121.976956, 37.675644999999996],
            [-121.976957, 37.675675],
            [-121.976943, 37.675774],
            [-121.976843, 37.676021],
            [-121.976803, 37.676147],
            [-121.976793, 37.676201],
            [-121.976792, 37.676252],
            [-121.97680000000001, 37.676347],
            [-121.976833, 37.676491999999996],
            [-121.976865, 37.676555],
            [-121.97689, 37.676584],
            [-121.977102, 37.676778],
            [-121.977149, 37.676821],
            [-121.97736, 37.677035],
            [-121.977441, 37.677109],
            [-121.977517, 37.677178],
            [-121.97799, 37.677585],
            [-121.978216, 37.67779],
            [-121.97826599999999, 37.677851],
            [-121.978295, 37.677899],
            [-121.978358, 37.678036999999996],
            [-121.978374, 37.678117],
            [-121.97838999999999, 37.678197],
            [-121.978471, 37.678385999999996],
            [-121.978485, 37.678419],
            [-121.978549, 37.678534],
            [-121.97861499999999, 37.678652],
            [-121.978692, 37.67875],
            [-121.978764, 37.678818],
            [-121.97887399999999, 37.678898],
            [-121.978967, 37.678954999999995],
            [-121.979033, 37.678978],
            [-121.97905399999999, 37.678979999999996],
            [-121.979172, 37.678995],
            [-121.979341, 37.678999],
            [-121.979579, 37.679604],
            [-121.979584, 37.679617],
            [-121.979823, 37.680226],
            [-121.981462, 37.684388],
            [-121.981466, 37.684402999999996],
            [-121.983491, 37.689006],
            [-121.98352, 37.689073],
          ],
        ],
      ],
    },
  },
  {
    id: "11870",
    type: "Feature",
    properties: {
      ZCTA5CE10: "95134",
      GEOID10: "95134",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 26076146,
      AWATER10: 0,
      INTPTLAT10: "+37.4285605",
      INTPTLON10: "-121.9434498",
      Name: "95134",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-121.97474199999999, 37.460015999999996],
          [-121.974726, 37.460328],
          [-121.974722, 37.460398999999995],
          [-121.974719, 37.460471],
          [-121.974712, 37.460606],
          [-121.974706, 37.46074],
          [-121.974471, 37.460739],
          [-121.970821, 37.461507],
          [-121.968524, 37.461953],
          [-121.96811199999999, 37.46215],
          [-121.968074, 37.462168],
          [-121.967064, 37.462486999999996],
          [-121.965951, 37.462813],
          [-121.964157, 37.463150999999996],
          [-121.959313, 37.463515],
          [-121.959261, 37.463505],
          [-121.957624, 37.463198999999996],
          [-121.955889, 37.46273],
          [-121.955579, 37.46264],
          [-121.955378, 37.462582],
          [-121.954451, 37.462204],
          [-121.954402, 37.462193],
          [-121.954387, 37.462185999999996],
          [-121.954109, 37.462112],
          [-121.953391, 37.461945],
          [-121.952819, 37.461673999999995],
          [-121.951921, 37.461461],
          [-121.95176599999999, 37.461455],
          [-121.950733, 37.461417999999995],
          [-121.949602, 37.461408],
          [-121.949375, 37.461496],
          [-121.948979, 37.461538],
          [-121.948126, 37.461845],
          [-121.947842, 37.461977999999995],
          [-121.947041, 37.462602],
          [-121.946409, 37.463362],
          [-121.946174, 37.463946],
          [-121.946167, 37.464487],
          [-121.946276, 37.464802999999996],
          [-121.946624, 37.465408],
          [-121.946772, 37.465664],
          [-121.94699, 37.466252],
          [-121.94698700000001, 37.466477],
          [-121.947087, 37.467424],
          [-121.94691399999999, 37.467648],
          [-121.946912, 37.467783],
          [-121.946797, 37.467918],
          [-121.946781, 37.467965],
          [-121.946679, 37.468277],
          [-121.946449, 37.46859],
          [-121.945934, 37.468990999999995],
          [-121.945894, 37.469006],
          [-121.945479, 37.469167999999996],
          [-121.944914, 37.469163],
          [-121.944522, 37.468889],
          [-121.944244, 37.468526],
          [-121.944137, 37.468119],
          [-121.944144, 37.467622999999996],
          [-121.944317, 37.467400000000005],
          [-121.944332, 37.466308999999995],
          [-121.944335, 37.466093],
          [-121.944226, 37.465821999999996],
          [-121.943779, 37.465457],
          [-121.943471, 37.465348],
          [-121.943364, 37.465281],
          [-121.943257, 37.465213999999996],
          [-121.943101, 37.465115999999995],
          [-121.943045, 37.465104],
          [-121.942357, 37.464963],
          [-121.94157799999999, 37.464945],
          [-121.941491, 37.464943999999996],
          [-121.941278, 37.464943999999996],
          [-121.941182, 37.464946],
          [-121.941085, 37.464948],
          [-121.940792, 37.464709],
          [-121.940594, 37.46445],
          [-121.940515, 37.464346],
          [-121.940462, 37.464075],
          [-121.940179, 37.463463],
          [-121.940023, 37.463125],
          [-121.939514, 37.46271],
          [-121.939241, 37.462486999999996],
          [-121.938903, 37.462393999999996],
          [-121.9388, 37.462415],
          [-121.93869699999999, 37.462436],
          [-121.938328, 37.46251],
          [-121.937958, 37.462585],
          [-121.937884, 37.462599],
          [-121.936478, 37.462883],
          [-121.936025, 37.462928999999995],
          [-121.93538, 37.462669],
          [-121.934987, 37.462464],
          [-121.934976, 37.462376],
          [-121.93496, 37.462235],
          [-121.93495, 37.462145],
          [-121.934947, 37.46212],
          [-121.935214, 37.461586],
          [-121.935255, 37.461504],
          [-121.935296, 37.461421],
          [-121.935277, 37.461327],
          [-121.935242, 37.461068],
          [-121.935137, 37.46084],
          [-121.934941, 37.460664],
          [-121.934732, 37.460524],
          [-121.934116, 37.460331],
          [-121.933578, 37.460271],
          [-121.932896, 37.460266],
          [-121.932374, 37.460352],
          [-121.932495, 37.460419],
          [-121.932616, 37.460485999999996],
          [-121.932778, 37.460575999999996],
          [-121.932941, 37.460667],
          [-121.933069, 37.460738],
          [-121.933378, 37.460761999999995],
          [-121.93267, 37.460974],
          [-121.932085, 37.460999],
          [-121.931934, 37.460977],
          [-121.931471, 37.460910999999996],
          [-121.93119899999999, 37.460643999999995],
          [-121.93119899999999, 37.460412999999996],
          [-121.931128, 37.460158],
          [-121.93139, 37.459595],
          [-121.93137, 37.4591],
          [-121.931521, 37.458830999999996],
          [-121.931451, 37.458399],
          [-121.93141, 37.458186999999995],
          [-121.931179, 37.457910999999996],
          [-121.931128, 37.45739],
          [-121.931245, 37.457353],
          [-121.931882, 37.457158],
          [-121.93219500000001, 37.457060999999996],
          [-121.932507, 37.456965],
          [-121.932582, 37.456942],
          [-121.932656, 37.456919],
          [-121.932712, 37.456842],
          [-121.932876, 37.456239],
          [-121.932884, 37.455844],
          [-121.93289899999999, 37.455447],
          [-121.932626, 37.455147],
          [-121.932069, 37.455276999999995],
          [-121.931462, 37.45576],
          [-121.931374, 37.455829],
          [-121.93095199999999, 37.456157999999995],
          [-121.930531, 37.456489],
          [-121.930283, 37.456615],
          [-121.930135, 37.456691],
          [-121.929481, 37.45684],
          [-121.929373, 37.456865],
          [-121.92920699999999, 37.456852999999995],
          [-121.92912799999999, 37.456908999999996],
          [-121.92904899999999, 37.456965],
          [-121.928926, 37.457052],
          [-121.92880199999999, 37.457139999999995],
          [-121.928609, 37.457276],
          [-121.928444, 37.457394],
          [-121.928297, 37.457332],
          [-121.928166, 37.457302999999996],
          [-121.92802499999999, 37.457249999999995],
          [-121.927793, 37.457124],
          [-121.927561, 37.456925],
          [-121.927461, 37.456705],
          [-121.927418, 37.456542999999996],
          [-121.92742, 37.456372],
          [-121.927441, 37.456278999999995],
          [-121.927495, 37.456123],
          [-121.927559, 37.456],
          [-121.927726, 37.455683],
          [-121.927796, 37.455549999999995],
          [-121.927866, 37.455416],
          [-121.92790000000001, 37.455352],
          [-121.927889, 37.455332999999996],
          [-121.92786, 37.455279],
          [-121.92785, 37.455262],
          [-121.927786, 37.455214],
          [-121.927655, 37.455148],
          [-121.927633, 37.455135999999996],
          [-121.927622, 37.455131],
          [-121.927384, 37.455036],
          [-121.927363, 37.455028999999996],
          [-121.927076, 37.455179],
          [-121.92704, 37.455193],
          [-121.926971, 37.45522],
          [-121.926901, 37.455248999999995],
          [-121.92686, 37.455245999999995],
          [-121.926755, 37.455241],
          [-121.926604, 37.455059999999996],
          [-121.926581, 37.455031999999996],
          [-121.926571, 37.455020999999995],
          [-121.92643, 37.454868],
          [-121.926417, 37.454841],
          [-121.926356, 37.454708],
          [-121.926294, 37.454572999999996],
          [-121.926197, 37.454546],
          [-121.925945, 37.454479],
          [-121.925783, 37.454436],
          [-121.925712, 37.454417],
          [-121.92564, 37.454397],
          [-121.925621, 37.454395],
          [-121.925566, 37.45439],
          [-121.92554799999999, 37.454389],
          [-121.925393, 37.454294999999995],
          [-121.925354, 37.454294],
          [-121.925224, 37.454293],
          [-121.925041, 37.454186],
          [-121.924893, 37.453984999999996],
          [-121.924874, 37.453716],
          [-121.92489499999999, 37.453569],
          [-121.924953, 37.453435999999996],
          [-121.925012, 37.453291],
          [-121.925079, 37.452881999999995],
          [-121.925136, 37.452248999999995],
          [-121.92504, 37.451674],
          [-121.924791, 37.451144],
          [-121.924459, 37.450716],
          [-121.92421, 37.450243],
          [-121.923999, 37.449929999999995],
          [-121.923967, 37.449438],
          [-121.92416399999999, 37.448738],
          [-121.924181, 37.448619],
          [-121.924194, 37.448471],
          [-121.924263, 37.4483],
          [-121.924374, 37.448108],
          [-121.92463599999999, 37.447728999999995],
          [-121.924691, 37.447614],
          [-121.924711, 37.447537],
          [-121.924705, 37.447388],
          [-121.924615, 37.447278],
          [-121.924498, 37.447207],
          [-121.923726, 37.446866],
          [-121.923444, 37.446729],
          [-121.923389, 37.446646],
          [-121.923368, 37.446504],
          [-121.923485, 37.446075],
          [-121.923671, 37.445668],
          [-121.923823, 37.445174],
          [-121.923982, 37.444811],
          [-121.924064, 37.444509],
          [-121.924092, 37.444317],
          [-121.92405099999999, 37.444092],
          [-121.923954, 37.443888],
          [-121.923816, 37.443706999999996],
          [-121.923672, 37.443542],
          [-121.923251, 37.443118999999996],
          [-121.92319599999999, 37.442986999999995],
          [-121.923183, 37.442844],
          [-121.923245, 37.44263],
          [-121.923355, 37.442541999999996],
          [-121.923755, 37.442301],
          [-121.924099, 37.442114],
          [-121.924416, 37.441905],
          [-121.924595, 37.441767999999996],
          [-121.924706, 37.441652],
          [-121.924788, 37.441477],
          [-121.924837, 37.44135],
          [-121.924899, 37.441229],
          [-121.924995, 37.441069999999996],
          [-121.92515399999999, 37.440894],
          [-121.925567, 37.440532],
          [-121.92576700000001, 37.440349999999995],
          [-121.92598100000001, 37.44012],
          [-121.926236, 37.439807],
          [-121.926374, 37.439625],
          [-121.926477, 37.439411],
          [-121.926615, 37.439087],
          [-121.92666299999999, 37.438900000000004],
          [-121.926718, 37.438542999999996],
          [-121.926649, 37.438252],
          [-121.926512, 37.437928],
          [-121.926312, 37.437526999999996],
          [-121.926249, 37.437419999999996],
          [-121.92616, 37.437267999999996],
          [-121.926085, 37.43712],
          [-121.926023, 37.436955],
          [-121.92598100000001, 37.436713],
          [-121.925926, 37.436344999999996],
          [-121.92594700000001, 37.436005],
          [-121.925988, 37.435637],
          [-121.92607100000001, 37.435356],
          [-121.926243, 37.434954999999995],
          [-121.926657, 37.434115],
          [-121.926795, 37.433763],
          [-121.926919, 37.433488],
          [-121.927064, 37.43323],
          [-121.92720800000001, 37.43306],
          [-121.92736, 37.432894999999995],
          [-121.927601, 37.43239],
          [-121.927925, 37.431878999999995],
          [-121.92806999999999, 37.431664],
          [-121.928166, 37.431467],
          [-121.928245, 37.431250999999996],
          [-121.928263, 37.431203],
          [-121.928387, 37.430835],
          [-121.928449, 37.430544],
          [-121.92843500000001, 37.430285999999995],
          [-121.928373, 37.429933999999996],
          [-121.92834500000001, 37.429618999999995],
          [-121.928339, 37.429538],
          [-121.928304, 37.429291],
          [-121.928242, 37.428917],
          [-121.928098, 37.428461],
          [-121.927967, 37.428187],
          [-121.927836, 37.427938999999995],
          [-121.927684, 37.427593],
          [-121.927347, 37.427198],
          [-121.92667900000001, 37.426505],
          [-121.926369, 37.426165],
          [-121.925935, 37.425818],
          [-121.925687, 37.425604],
          [-121.925669, 37.425578],
          [-121.925473, 37.425284999999995],
          [-121.925336, 37.425044],
          [-121.925239, 37.424845999999995],
          [-121.925171, 37.424577],
          [-121.92514299999999, 37.42434],
          [-121.925174, 37.42404],
          [-121.925175, 37.42402],
          [-121.925178, 37.424],
          [-121.925194, 37.423964],
          [-121.925203, 37.423946],
          [-121.92521099999999, 37.423928],
          [-121.925224, 37.423902999999996],
          [-121.925237, 37.423874],
          [-121.925246, 37.423856],
          [-121.925401, 37.423527],
          [-121.925409, 37.423511999999995],
          [-121.925433, 37.423491999999996],
          [-121.925846, 37.423224999999995],
          [-121.925978, 37.423096],
          [-121.926272, 37.422877],
          [-121.926432, 37.422761],
          [-121.926567, 37.422664],
          [-121.926666, 37.422593],
          [-121.92672400000001, 37.422564],
          [-121.927175, 37.422334],
          [-121.92790600000001, 37.421962],
          [-121.928622, 37.421583],
          [-121.930048, 37.420814],
          [-121.930383, 37.420655],
          [-121.93052399999999, 37.420587999999995],
          [-121.930708, 37.420479],
          [-121.930827, 37.420358],
          [-121.930965, 37.420198],
          [-121.931047, 37.420094],
          [-121.931096, 37.419984],
          [-121.931082, 37.419847],
          [-121.93101300000001, 37.419759],
          [-121.930848, 37.419621],
          [-121.930386, 37.419495],
          [-121.930179, 37.419446],
          [-121.93006199999999, 37.419407],
          [-121.929959, 37.419363],
          [-121.92984200000001, 37.419281],
          [-121.929794, 37.419193],
          [-121.929788, 37.418983],
          [-121.929787, 37.418962],
          [-121.929883, 37.418814],
          [-121.930014, 37.418642999999996],
          [-121.930221, 37.418264],
          [-121.930262, 37.418138],
          [-121.930262, 37.417972999999996],
          [-121.930131, 37.417759],
          [-121.929446, 37.417004999999996],
          [-121.928113, 37.415538999999995],
          [-121.927982, 37.415479],
          [-121.927783, 37.415445999999996],
          [-121.927528, 37.415445999999996],
          [-121.927363, 37.415462],
          [-121.92716300000001, 37.41544],
          [-121.926984, 37.415352],
          [-121.926908, 37.415259],
          [-121.926818, 37.415127],
          [-121.926722, 37.415028],
          [-121.926577, 37.414923],
          [-121.926405, 37.414829999999995],
          [-121.926144, 37.414698],
          [-121.92602600000001, 37.414615999999995],
          [-121.92590200000001, 37.414429],
          [-121.925834, 37.414182],
          [-121.925813, 37.413807999999996],
          [-121.92578499999999, 37.413539],
          [-121.92575099999999, 37.413385],
          [-121.925675, 37.413236999999995],
          [-121.925606, 37.413138],
          [-121.925317, 37.413016999999996],
          [-121.924849, 37.412879],
          [-121.924608, 37.41277],
          [-121.92444, 37.412625999999996],
          [-121.924112, 37.412346],
          [-121.923788, 37.412011],
          [-121.923134, 37.411252999999995],
          [-121.922974, 37.411035],
          [-121.922929, 37.410945],
          [-121.922866, 37.410819],
          [-121.922804, 37.410621],
          [-121.922804, 37.41044],
          [-121.922873, 37.410242],
          [-121.922983, 37.410005999999996],
          [-121.923141, 37.409742],
          [-121.923307, 37.409423],
          [-121.923534, 37.408819],
          [-121.923665, 37.408445],
          [-121.92374699999999, 37.40812],
          [-121.923851, 37.407714999999996],
          [-121.923899, 37.407391],
          [-121.923948, 37.406895999999996],
          [-121.923934, 37.406517],
          [-121.923906, 37.406248],
          [-121.92380299999999, 37.406000999999996],
          [-121.92349300000001, 37.405693],
          [-121.92330000000001, 37.405561],
          [-121.923135, 37.405488999999996],
          [-121.92287999999999, 37.405467],
          [-121.922309, 37.405709],
          [-121.92217099999999, 37.405747999999996],
          [-121.921985, 37.405747999999996],
          [-121.921854, 37.405715],
          [-121.921675, 37.405626999999996],
          [-121.921241, 37.405193],
          [-121.92108999999999, 37.405],
          [-121.920945, 37.404835],
          [-121.920801, 37.404736],
          [-121.920594, 37.404665],
          [-121.920243, 37.404565999999996],
          [-121.91952, 37.404395],
          [-121.91899599999999, 37.404258],
          [-121.918521, 37.404098999999995],
          [-121.918383, 37.404044],
          [-121.918225, 37.403928],
          [-121.91810100000001, 37.403786],
          [-121.918053, 37.403729999999996],
          [-121.917922, 37.403483],
          [-121.917757, 37.403203],
          [-121.917722, 37.403123],
          [-121.917592, 37.402829],
          [-121.91753, 37.402648],
          [-121.91741999999999, 37.402214],
          [-121.917137, 37.401313],
          [-121.917041, 37.401142],
          [-121.91702, 37.401114],
          [-121.91690299999999, 37.400956],
          [-121.916766, 37.400818],
          [-121.91658699999999, 37.400653],
          [-121.916325, 37.400383999999995],
          [-121.916237, 37.400296999999995],
          [-121.916153, 37.400214],
          [-121.915919, 37.39995],
          [-121.915733, 37.399659],
          [-121.915416, 37.399192],
          [-121.91529299999999, 37.398917],
          [-121.91521, 37.39862],
          [-121.915189, 37.398362],
          [-121.915176, 37.397813],
          [-121.915257, 37.397216],
          [-121.915279, 37.397055],
          [-121.915321, 37.396494],
          [-121.915307, 37.396361999999996],
          [-121.91529299999999, 37.396335],
          [-121.915238, 37.396192],
          [-121.915198, 37.39611],
          [-121.915269, 37.396048],
          [-121.915307, 37.396014],
          [-121.915437, 37.395902],
          [-121.9157, 37.395677],
          [-121.915961, 37.395441],
          [-121.916154, 37.395278],
          [-121.916204, 37.395237],
          [-121.916326, 37.395128],
          [-121.916395, 37.395072],
          [-121.91641, 37.395058999999996],
          [-121.916455, 37.395022],
          [-121.916471, 37.39501],
          [-121.916658, 37.394857],
          [-121.917058, 37.394543],
          [-121.917147, 37.394473999999995],
          [-121.91736, 37.394312],
          [-121.917619, 37.394121],
          [-121.917787, 37.393999],
          [-121.917978, 37.393882],
          [-121.91811899999999, 37.393812],
          [-121.918401, 37.393693],
          [-121.918589, 37.393625],
          [-121.918762, 37.393575999999996],
          [-121.918784, 37.393571],
          [-121.91899, 37.393529],
          [-121.919153, 37.393505999999995],
          [-121.919376, 37.393482],
          [-121.919453, 37.393478],
          [-121.91953699999999, 37.393474],
          [-121.919686, 37.393471999999996],
          [-121.91973999999999, 37.393471999999996],
          [-121.919764, 37.393474],
          [-121.920174, 37.3935],
          [-121.920389, 37.393541],
          [-121.920604, 37.393595999999995],
          [-121.920818, 37.393665999999996],
          [-121.920926, 37.39371],
          [-121.921011, 37.393744999999996],
          [-121.921173, 37.393823999999995],
          [-121.921375, 37.393941999999996],
          [-121.921545, 37.394057],
          [-121.921691, 37.394168],
          [-121.92176, 37.39423],
          [-121.921942, 37.394411999999996],
          [-121.922215, 37.394714],
          [-121.92284000000001, 37.395464],
          [-121.92318, 37.395854],
          [-121.923447, 37.396148],
          [-121.923471, 37.396178],
          [-121.923722, 37.396491999999995],
          [-121.923923, 37.396733],
          [-121.924238, 37.397093999999996],
          [-121.924491, 37.396865],
          [-121.925254, 37.396181],
          [-121.92550800000001, 37.395953],
          [-121.92475400000001, 37.394889],
          [-121.92401699999999, 37.393848],
          [-121.923608, 37.393281],
          [-121.92346, 37.393111999999995],
          [-121.92329699999999, 37.392952],
          [-121.92305999999999, 37.392759999999996],
          [-121.922109, 37.392064],
          [-121.92205, 37.392021],
          [-121.921407, 37.391548],
          [-121.92117, 37.391374],
          [-121.921065, 37.391284999999996],
          [-121.921014, 37.391241],
          [-121.920996, 37.391225999999996],
          [-121.920943, 37.391180999999996],
          [-121.920926, 37.391166999999996],
          [-121.92095499999999, 37.391141],
          [-121.921292, 37.390844],
          [-121.921375, 37.390772],
          [-121.922307, 37.389959],
          [-121.922646, 37.389665],
          [-121.922984, 37.38937],
          [-121.923157, 37.38922],
          [-121.924001, 37.388487],
          [-121.92434, 37.388194],
          [-121.924691, 37.387882999999995],
          [-121.924758, 37.387828],
          [-121.925112, 37.387544],
          [-121.925696, 37.387105],
          [-121.926023, 37.386873],
          [-121.926093, 37.386827],
          [-121.92624599999999, 37.38673],
          [-121.92649, 37.386586],
          [-121.926571, 37.386544],
          [-121.92672, 37.386466],
          [-121.926856, 37.386396999999995],
          [-121.92699400000001, 37.386339],
          [-121.927179, 37.386252],
          [-121.927332, 37.386181],
          [-121.927357, 37.386168],
          [-121.927432, 37.386133],
          [-121.927458, 37.386122],
          [-121.927513, 37.386095],
          [-121.927681, 37.386018],
          [-121.92773700000001, 37.385992],
          [-121.92791700000001, 37.385908],
          [-121.928062, 37.385841],
          [-121.928459, 37.38566],
          [-121.92864, 37.385579],
          [-121.929232, 37.38531],
          [-121.929515, 37.385182],
          [-121.930933, 37.384527999999996],
          [-121.931005, 37.384495],
          [-121.9316, 37.384231],
          [-121.931438, 37.383973999999995],
          [-121.931347, 37.383801999999996],
          [-121.931294, 37.383663999999996],
          [-121.931255, 37.383507],
          [-121.93124, 37.383382],
          [-121.931235, 37.383283],
          [-121.93123800000001, 37.383165],
          [-121.931249, 37.383055999999996],
          [-121.93132299999999, 37.382608],
          [-121.934433, 37.379554],
          [-121.935827, 37.378187],
          [-121.935899, 37.378116999999996],
          [-121.93597, 37.378046999999995],
          [-121.936471, 37.378456],
          [-121.93664, 37.378594],
          [-121.937139, 37.37923],
          [-121.93752599999999, 37.379864],
          [-121.937524, 37.379954],
          [-121.937579, 37.380013],
          [-121.937691, 37.380136],
          [-121.937901, 37.380569],
          [-121.93813, 37.381040999999996],
          [-121.938127, 37.381223999999996],
          [-121.938121, 37.381717],
          [-121.937831, 37.382255],
          [-121.93705800000001, 37.382923999999996],
          [-121.936802, 37.383147],
          [-121.936549, 37.383416],
          [-121.936171, 37.383818],
          [-121.93588199999999, 37.384266],
          [-121.935648, 37.38485],
          [-121.935639, 37.38548],
          [-121.93574699999999, 37.385797],
          [-121.935744, 37.386067],
          [-121.936068, 37.387062],
          [-121.936345, 37.38747],
          [-121.937351, 37.388200000000005],
          [-121.938748, 37.389339],
          [-121.9389, 37.389578],
          [-121.939055, 37.389823],
          [-121.939127, 37.389891],
          [-121.939219, 37.389973999999995],
          [-121.93930399999999, 37.390060999999996],
          [-121.939381, 37.390153],
          [-121.93945, 37.390249],
          [-121.939524, 37.390374],
          [-121.939561, 37.390451999999996],
          [-121.939594, 37.390530999999996],
          [-121.939621, 37.390611],
          [-121.939641, 37.390682],
          [-121.93966499999999, 37.390789999999996],
          [-121.939706, 37.391006],
          [-121.939723, 37.391115],
          [-121.939737, 37.391224],
          [-121.939749, 37.391332999999996],
          [-121.939759, 37.391442],
          [-121.93976599999999, 37.391551],
          [-121.93977100000001, 37.39166],
          [-121.939774, 37.39177],
          [-121.939774, 37.391878999999996],
          [-121.939772, 37.391988999999995],
          [-121.939851, 37.393281],
          [-121.939882, 37.393781],
          [-121.939902, 37.393862],
          [-121.939926, 37.393901],
          [-121.939977, 37.394262],
          [-121.940087, 37.394489],
          [-121.940084, 37.394714],
          [-121.94065499999999, 37.395672],
          [-121.940687, 37.395725999999996],
          [-121.940719, 37.395779],
          [-121.94135299999999, 37.396844],
          [-121.941679, 37.397793],
          [-121.941675, 37.398063],
          [-121.941785, 37.398289999999996],
          [-121.941783, 37.398354],
          [-121.94178, 37.398545999999996],
          [-121.94178, 37.398610999999995],
          [-121.941777, 37.39883],
          [-121.941697, 37.399032],
          [-121.94160099999999, 37.399279],
          [-121.941592, 37.399955],
          [-121.94164599999999, 37.400090999999996],
          [-121.941763, 37.400276999999996],
          [-121.942102, 37.400552999999995],
          [-121.942217, 37.400647],
          [-121.942259, 37.400681999999996],
          [-121.942548, 37.400788999999996],
          [-121.94287800000001, 37.400912999999996],
          [-121.943667, 37.40101],
          [-121.944056, 37.401013],
          [-121.944568, 37.401018],
          [-121.944945, 37.401021],
          [-121.9457, 37.401029],
          [-121.946037, 37.401167],
          [-121.94641, 37.401213],
          [-121.946485, 37.401235],
          [-121.94735, 37.401539],
          [-121.947445, 37.40154],
          [-121.94761199999999, 37.401677],
          [-121.94778099999999, 37.401723],
          [-121.948898, 37.402635],
          [-121.949249, 37.402895],
          [-121.949499, 37.402919],
          [-121.95003, 37.402974],
          [-121.950509, 37.403501999999996],
          [-121.95097200000001, 37.403760999999996],
          [-121.951535, 37.403898999999996],
          [-121.951841, 37.404025],
          [-121.952686, 37.404955],
          [-121.952811, 37.405027],
          [-121.952932, 37.40529],
          [-121.952999, 37.405615999999995],
          [-121.952997, 37.405826999999995],
          [-121.953043, 37.406068999999995],
          [-121.953039, 37.406302],
          [-121.95308299999999, 37.406366999999996],
          [-121.953529, 37.406822],
          [-121.953742, 37.406985],
          [-121.954077, 37.407241],
          [-121.95442299999999, 37.407506],
          [-121.954667, 37.407651],
          [-121.955039, 37.407872],
          [-121.956218, 37.408423],
          [-121.956331, 37.408424],
          [-121.956621, 37.408543],
          [-121.957288, 37.408817],
          [-121.957518, 37.408910999999996],
          [-121.957611, 37.408774],
          [-121.95797999999999, 37.408477999999995],
          [-121.959445, 37.408968],
          [-121.959845, 37.4091],
          [-121.96009, 37.409188],
          [-121.960336, 37.409296999999995],
          [-121.96046799999999, 37.409358999999995],
          [-121.96055, 37.409405],
          [-121.960564, 37.409411999999996],
          [-121.960658, 37.409464],
          [-121.960676, 37.409473999999996],
          [-121.96069, 37.409481],
          [-121.960933, 37.409607],
          [-121.961083, 37.409729],
          [-121.961204, 37.409827],
          [-121.961371, 37.409962],
          [-121.961624, 37.410168],
          [-121.961737, 37.410295],
          [-121.961837, 37.41041],
          [-121.96198, 37.410571999999995],
          [-121.962351, 37.410992],
          [-121.962563, 37.411232],
          [-121.96497099999999, 37.413916],
          [-121.965794, 37.414833],
          [-121.966027, 37.416061],
          [-121.967137, 37.417327],
          [-121.967558, 37.417806999999996],
          [-121.96768900000001, 37.418087],
          [-121.967713, 37.418138],
          [-121.96778499999999, 37.418292],
          [-121.967809, 37.418344],
          [-121.967833, 37.418395],
          [-121.967844, 37.418439],
          [-121.967901, 37.418654],
          [-121.967922, 37.418734],
          [-121.967925, 37.418999],
          [-121.967731, 37.419008],
          [-121.967536, 37.419019],
          [-121.967464, 37.418755],
          [-121.96426199999999, 37.418890999999995],
          [-121.96316999999999, 37.418917],
          [-121.962201, 37.418957],
          [-121.961739, 37.418976],
          [-121.961375, 37.418988],
          [-121.96086700000001, 37.419019],
          [-121.960292, 37.419062],
          [-121.959386, 37.419152],
          [-121.95930899999999, 37.41916],
          [-121.959198, 37.419171999999996],
          [-121.959392, 37.419325],
          [-121.959607, 37.419478999999995],
          [-121.959817, 37.419596999999996],
          [-121.960212, 37.419812],
          [-121.960887, 37.420045],
          [-121.96112099999999, 37.420139999999996],
          [-121.961457, 37.420289],
          [-121.961725, 37.420423],
          [-121.96195399999999, 37.420553999999996],
          [-121.962205, 37.420715],
          [-121.962522, 37.420947999999996],
          [-121.962838, 37.421184],
          [-121.964051, 37.422098],
          [-121.964145, 37.422191],
          [-121.964235, 37.422278999999996],
          [-121.964378, 37.422382999999996],
          [-121.96450899999999, 37.422478999999996],
          [-121.964709, 37.422613],
          [-121.965043, 37.422858999999995],
          [-121.965115, 37.422914999999996],
          [-121.965276, 37.42304],
          [-121.965357, 37.423099],
          [-121.965429, 37.423152],
          [-121.965504, 37.423201],
          [-121.965666, 37.423308999999996],
          [-121.965801, 37.423382],
          [-121.965942, 37.423446],
          [-121.965976, 37.423459],
          [-121.966088, 37.423505],
          [-121.966145, 37.423521],
          [-121.966137, 37.423863],
          [-121.966134, 37.423938],
          [-121.966128, 37.424154],
          [-121.966113, 37.424361],
          [-121.966106, 37.42519],
          [-121.966105, 37.425449],
          [-121.966122, 37.425495],
          [-121.966156, 37.425534999999996],
          [-121.966204, 37.425565999999996],
          [-121.96645, 37.425633],
          [-121.967191, 37.425837],
          [-121.967438, 37.425905],
          [-121.967812, 37.426007],
          [-121.96893399999999, 37.426316],
          [-121.968968, 37.426325999999996],
          [-121.969032, 37.426358],
          [-121.969084, 37.4264],
          [-121.96923, 37.426556999999995],
          [-121.969365, 37.426701],
          [-121.969671, 37.427028],
          [-121.969773, 37.427133],
          [-121.969801, 37.427161999999996],
          [-121.969915, 37.427271999999995],
          [-121.96967699999999, 37.42744],
          [-121.968965, 37.427945],
          [-121.968728, 37.428114],
          [-121.968317, 37.428407],
          [-121.967086, 37.429288],
          [-121.966676, 37.429583],
          [-121.966629, 37.429611],
          [-121.966286, 37.429854999999996],
          [-121.96570000000001, 37.430273],
          [-121.965123, 37.430682999999995],
          [-121.964736, 37.430959],
          [-121.96471199999999, 37.430975],
          [-121.96464, 37.431027],
          [-121.964617, 37.431044],
          [-121.964345, 37.431222999999996],
          [-121.964315, 37.431242999999995],
          [-121.96426, 37.431273999999995],
          [-121.964151, 37.431324],
          [-121.964026, 37.431363],
          [-121.963895, 37.431387],
          [-121.963867, 37.431388],
          [-121.963773, 37.431396],
          [-121.963648, 37.431393],
          [-121.963315, 37.43137],
          [-121.961368, 37.431204],
          [-121.960535, 37.431134],
          [-121.960098, 37.431097],
          [-121.95949, 37.431044],
          [-121.957377, 37.430862],
          [-121.956355, 37.430772],
          [-121.955807, 37.430724999999995],
          [-121.95568399999999, 37.430727999999995],
          [-121.955563, 37.430747],
          [-121.955456, 37.430777],
          [-121.955347, 37.430822],
          [-121.955327, 37.430834],
          [-121.955241, 37.430884999999996],
          [-121.954809, 37.431188999999996],
          [-121.954239, 37.431593],
          [-121.953841, 37.431881],
          [-121.953371, 37.432204999999996],
          [-121.953258, 37.432276],
          [-121.95311, 37.432356],
          [-121.952928, 37.43244],
          [-121.952753, 37.432505],
          [-121.952694, 37.432525],
          [-121.952694, 37.432795999999996],
          [-121.953163, 37.43356],
          [-121.953245, 37.433802],
          [-121.953259, 37.433884],
          [-121.95324600000001, 37.433977999999996],
          [-121.953767, 37.43436],
          [-121.954985, 37.436218],
          [-121.95518200000001, 37.436333999999995],
          [-121.955299, 37.436333999999995],
          [-121.95539600000001, 37.436323],
          [-121.955458, 37.436296],
          [-121.955527, 37.436285],
          [-121.95577399999999, 37.436316],
          [-121.956166, 37.436634],
          [-121.956665, 37.43727],
          [-121.95694, 37.437858],
          [-121.958664, 37.439856],
          [-121.959504, 37.44045],
          [-121.959389, 37.440584],
          [-121.959385, 37.440899],
          [-121.959494, 37.441126],
          [-121.961553, 37.443532],
          [-121.962167, 37.444033],
          [-121.96267499999999, 37.444083],
          [-121.963568, 37.444902],
          [-121.964018, 37.445130999999996],
          [-121.964243, 37.445178],
          [-121.964921, 37.445184],
          [-121.966281, 37.444970999999995],
          [-121.966882, 37.444976],
          [-121.967015, 37.444977],
          [-121.967407, 37.445251],
          [-121.967404, 37.445476],
          [-121.967231, 37.445744999999995],
          [-121.966774, 37.446101999999996],
          [-121.96654699999999, 37.44619],
          [-121.965919, 37.446635],
          [-121.965235, 37.44699],
          [-121.965007, 37.447168],
          [-121.965446, 37.447593999999995],
          [-121.966442, 37.447773],
          [-121.967618, 37.448105999999996],
          [-121.968282, 37.449076999999996],
          [-121.968972, 37.449511],
          [-121.97474700000001, 37.454009],
          [-121.974186, 37.459739],
          [-121.974757, 37.459703],
          [-121.97474199999999, 37.460015999999996],
        ],
      ],
    },
  },
  {
    id: "28606",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94043",
      GEOID10: "94043",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 27648208,
      AWATER10: 1469,
      INTPTLAT10: "+37.4140278",
      INTPTLON10: "-122.0706785",
      Name: "94043",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.108285, 37.408104],
          [-122.108213, 37.408176],
          [-122.108189, 37.408201],
          [-122.108171, 37.408218],
          [-122.108117, 37.408272],
          [-122.10810000000001, 37.40829],
          [-122.108033, 37.408356],
          [-122.10797, 37.40842],
          [-122.10794, 37.408449999999995],
          [-122.107848, 37.408566],
          [-122.10779, 37.40864],
          [-122.10772, 37.40873],
          [-122.10766100000001, 37.408816],
          [-122.10750999999999, 37.40904],
          [-122.107327, 37.409380999999996],
          [-122.10728, 37.40947],
          [-122.107238, 37.409580999999996],
          [-122.10715, 37.40981],
          [-122.107113, 37.409912],
          [-122.10699, 37.41026],
          [-122.10675, 37.41082],
          [-122.10670999999999, 37.410894],
          [-122.10666, 37.41099],
          [-122.10654, 37.41117],
          [-122.106522, 37.411193],
          [-122.106275, 37.411103],
          [-122.106101, 37.41104],
          [-122.10597, 37.410969],
          [-122.105885, 37.410877],
          [-122.10583, 37.410779999999995],
          [-122.1058, 37.41074],
          [-122.1057, 37.410669999999996],
          [-122.10566399999999, 37.410647],
          [-122.10557299999999, 37.410589],
          [-122.105444, 37.410506],
          [-122.10537099999999, 37.410565],
          [-122.105609, 37.410807999999996],
          [-122.10573600000001, 37.410937],
          [-122.10488, 37.411667],
          [-122.10459900000001, 37.411998],
          [-122.104376, 37.412245],
          [-122.103672, 37.413027],
          [-122.10338999999999, 37.413340999999996],
          [-122.102242, 37.41265],
          [-122.101295, 37.412048],
          [-122.101812, 37.414423],
          [-122.10188600000001, 37.414513],
          [-122.102011, 37.415165],
          [-122.10203, 37.41552],
          [-122.102008, 37.415614999999995],
          [-122.10195999999999, 37.415825999999996],
          [-122.101879, 37.416188999999996],
          [-122.101634, 37.416705],
          [-122.1015, 37.41699],
          [-122.101432, 37.417182],
          [-122.101227, 37.417758],
          [-122.10116, 37.41795],
          [-122.100883, 37.418051999999996],
          [-122.10065399999999, 37.418138],
          [-122.10067599999999, 37.418777999999996],
          [-122.100687, 37.419073999999995],
          [-122.100669, 37.419126999999996],
          [-122.100616, 37.419286],
          [-122.100599, 37.41934],
          [-122.100617, 37.419340999999996],
          [-122.100671, 37.419343999999995],
          [-122.10069, 37.419346],
          [-122.100828, 37.419354],
          [-122.10091, 37.41936],
          [-122.10057, 37.42044],
          [-122.10052, 37.42047],
          [-122.100486, 37.42047],
          [-122.100338, 37.421285],
          [-122.10025, 37.421769999999995],
          [-122.099966, 37.421752],
          [-122.099114, 37.421698],
          [-122.09897, 37.42169],
          [-122.09882999999999, 37.42169],
          [-122.098693, 37.421682],
          [-122.098282, 37.421659],
          [-122.098146, 37.421652],
          [-122.09792999999999, 37.42164],
          [-122.097864, 37.421636],
          [-122.09732, 37.42161],
          [-122.097284, 37.422286],
          [-122.09794600000001, 37.422346],
          [-122.097888, 37.423066],
          [-122.097859, 37.423173],
          [-122.09776099999999, 37.423553],
          [-122.097635, 37.423971],
          [-122.09754699999999, 37.424234],
          [-122.09719, 37.42404],
          [-122.09697, 37.42417],
          [-122.09703999999999, 37.42422],
          [-122.097506, 37.424597999999996],
          [-122.09776099999999, 37.424805],
          [-122.097846, 37.424873],
          [-122.0981, 37.42508],
          [-122.098833, 37.42574],
          [-122.09900999999999, 37.4259],
          [-122.099156, 37.426037],
          [-122.0995, 37.426361],
          [-122.10053500000001, 37.427335],
          [-122.10088, 37.427659999999996],
          [-122.100997, 37.427768],
          [-122.10105, 37.427817],
          [-122.10115, 37.42791],
          [-122.101345, 37.428098],
          [-122.10146, 37.42821],
          [-122.101427, 37.42828],
          [-122.10140000000001, 37.42834],
          [-122.10135, 37.42843],
          [-122.101322, 37.428489],
          [-122.10129, 37.42856],
          [-122.10127, 37.42859],
          [-122.101256, 37.428618],
          [-122.10121699999999, 37.4287],
          [-122.101169, 37.428799],
          [-122.10114, 37.42886],
          [-122.100882, 37.429434],
          [-122.100796, 37.429665],
          [-122.10074, 37.429814],
          [-122.100723, 37.429857999999996],
          [-122.10068799999999, 37.429953999999995],
          [-122.10063, 37.43011],
          [-122.10055, 37.43056],
          [-122.10051, 37.43076],
          [-122.10050000000001, 37.430816],
          [-122.10042, 37.43128],
          [-122.100392, 37.431455],
          [-122.10037, 37.43159],
          [-122.10025999999999, 37.43205],
          [-122.10023, 37.43226],
          [-122.10019, 37.43253],
          [-122.100182, 37.43257],
          [-122.10010000000001, 37.433009999999996],
          [-122.10009, 37.43311],
          [-122.10005, 37.43338],
          [-122.10002, 37.43347],
          [-122.100004, 37.433491],
          [-122.09998999999999, 37.43351],
          [-122.09994, 37.43354],
          [-122.09985, 37.43356],
          [-122.09971, 37.43356],
          [-122.09971999999999, 37.43367],
          [-122.09973, 37.43378],
          [-122.09977, 37.433879999999995],
          [-122.09980999999999, 37.43396],
          [-122.09982600000001, 37.433977],
          [-122.0999, 37.434059999999995],
          [-122.09992, 37.43412],
          [-122.09992, 37.43421],
          [-122.099918, 37.434246],
          [-122.0999, 37.43464],
          [-122.099882, 37.434764],
          [-122.099874, 37.434815],
          [-122.09986, 37.434909999999995],
          [-122.09980999999999, 37.43499],
          [-122.099748, 37.434985999999995],
          [-122.099535, 37.43497],
          [-122.099381, 37.435157],
          [-122.09931399999999, 37.435238999999996],
          [-122.099459, 37.435787],
          [-122.099514, 37.435995],
          [-122.09946, 37.436119999999995],
          [-122.09942, 37.43616],
          [-122.09935, 37.43618],
          [-122.099332, 37.436177],
          [-122.09922399999999, 37.436029],
          [-122.099045, 37.435908],
          [-122.098821, 37.435753999999996],
          [-122.098651, 37.435611],
          [-122.098432, 37.435507],
          [-122.098146, 37.435475],
          [-122.097931, 37.435507],
          [-122.09786, 37.435500000000005],
          [-122.09778, 37.43548],
          [-122.09772, 37.43542],
          [-122.09759, 37.43534],
          [-122.09739, 37.43528],
          [-122.09666, 37.43499],
          [-122.09653, 37.43495],
          [-122.09647, 37.43495],
          [-122.09639, 37.43496],
          [-122.09615, 37.43501],
          [-122.09595, 37.43503],
          [-122.09579, 37.43503],
          [-122.09568, 37.43501],
          [-122.09564, 37.43501],
          [-122.09548, 37.43494],
          [-122.09540000000001, 37.43488],
          [-122.09538, 37.43485],
          [-122.09534, 37.4348],
          [-122.09534, 37.43472],
          [-122.09540000000001, 37.43448],
          [-122.09562, 37.434259999999995],
          [-122.09585, 37.43401],
          [-122.09609, 37.4337],
          [-122.09617, 37.43356],
          [-122.09617, 37.43353],
          [-122.09611, 37.433389999999996],
          [-122.09609, 37.43333],
          [-122.09609, 37.433279999999996],
          [-122.095415, 37.433309],
          [-122.09422599999999, 37.433361],
          [-122.093648, 37.432876],
          [-122.093394, 37.432266999999996],
          [-122.093415, 37.432137999999995],
          [-122.08684, 37.43175],
          [-122.08676299999999, 37.431745],
          [-122.086727, 37.431743999999995],
          [-122.0866, 37.431737],
          [-122.08659, 37.432493],
          [-122.086586, 37.432846999999995],
          [-122.086472, 37.432936999999995],
          [-122.086471, 37.433026999999996],
          [-122.08612600000001, 37.43352],
          [-122.08606499999999, 37.433924999999995],
          [-122.08595, 37.434059],
          [-122.08589, 37.434374],
          [-122.085748, 37.434474],
          [-122.085263, 37.434819999999995],
          [-122.085199, 37.434967],
          [-122.085362, 37.435024],
          [-122.085525, 37.43508],
          [-122.086406, 37.435386],
          [-122.086767, 37.435473],
          [-122.087375, 37.43562],
          [-122.08806899999999, 37.435787999999995],
          [-122.088439, 37.435846],
          [-122.088704, 37.435914],
          [-122.089191, 37.435885999999996],
          [-122.090886, 37.435793],
          [-122.091223, 37.435787],
          [-122.09159199999999, 37.4358],
          [-122.092814, 37.436039],
          [-122.093346, 37.436158999999996],
          [-122.093729, 37.436127],
          [-122.094124, 37.436113],
          [-122.095113, 37.436077999999995],
          [-122.096394, 37.435970999999995],
          [-122.096637, 37.435950999999996],
          [-122.096862, 37.435932],
          [-122.097369, 37.43589],
          [-122.097613, 37.43587],
          [-122.097515, 37.435924],
          [-122.097439, 37.435969],
          [-122.096927, 37.436281],
          [-122.096757, 37.436386],
          [-122.096575, 37.436496999999996],
          [-122.096055, 37.43682],
          [-122.096032, 37.436834],
          [-122.09585200000001, 37.436946999999996],
          [-122.095216, 37.437185],
          [-122.095156, 37.437219999999996],
          [-122.093228, 37.438373999999996],
          [-122.092906, 37.438568],
          [-122.092675, 37.438860999999996],
          [-122.09243000000001, 37.438964999999996],
          [-122.092266, 37.439036],
          [-122.091927, 37.439167999999995],
          [-122.09168700000001, 37.439260999999995],
          [-122.091522, 37.439327],
          [-122.091441, 37.439361999999996],
          [-122.090988, 37.439388],
          [-122.090964, 37.439389999999996],
          [-122.090144, 37.440404],
          [-122.090141, 37.440433],
          [-122.090099, 37.440884],
          [-122.089775, 37.441486999999995],
          [-122.089703, 37.441624],
          [-122.089152, 37.442448],
          [-122.088801, 37.442876999999996],
          [-122.088718, 37.443079999999995],
          [-122.088717, 37.443214],
          [-122.088712, 37.443899],
          [-122.088694, 37.444064999999995],
          [-122.088691, 37.444096],
          [-122.088685, 37.444300999999996],
          [-122.088663, 37.444396],
          [-122.08851, 37.444468],
          [-122.08845099999999, 37.444621999999995],
          [-122.088378, 37.445003],
          [-122.08816, 37.446149999999996],
          [-122.088156, 37.446175],
          [-122.088122, 37.446354],
          [-122.088088, 37.446532],
          [-122.087615, 37.447317999999996],
          [-122.08775, 37.447362],
          [-122.088058, 37.447462],
          [-122.088313, 37.447644],
          [-122.08848, 37.447962],
          [-122.087273, 37.449138999999995],
          [-122.08693199999999, 37.449501999999995],
          [-122.08644100000001, 37.450025],
          [-122.086207, 37.450275],
          [-122.08588499999999, 37.450348999999996],
          [-122.085073, 37.450539],
          [-122.084982, 37.450666999999996],
          [-122.08479299999999, 37.450938],
          [-122.084682, 37.450811],
          [-122.08462, 37.450717999999995],
          [-122.084544, 37.450658],
          [-122.084469, 37.450624999999995],
          [-122.084407, 37.450624999999995],
          [-122.084379, 37.450685],
          [-122.08436499999999, 37.450762],
          [-122.08436499999999, 37.450845],
          [-122.084345, 37.450872],
          [-122.08431, 37.450899],
          [-122.084269, 37.450911],
          [-122.084228, 37.450900000000004],
          [-122.084165, 37.450872],
          [-122.084138, 37.450866999999995],
          [-122.084097, 37.450877999999996],
          [-122.08399299999999, 37.451080999999995],
          [-122.083959, 37.451102999999996],
          [-122.08386899999999, 37.451125],
          [-122.083759, 37.451114],
          [-122.083704, 37.451080999999995],
          [-122.08365599999999, 37.450998],
          [-122.083642, 37.450949],
          [-122.083545, 37.450845],
          [-122.083504, 37.450828],
          [-122.083463, 37.450828],
          [-122.083387, 37.450860999999996],
          [-122.083414, 37.450927],
          [-122.083421, 37.450981999999996],
          [-122.083407, 37.451037],
          [-122.083304, 37.451091999999996],
          [-122.083281, 37.451098],
          [-122.083249, 37.451108],
          [-122.083208, 37.451158],
          [-122.083125, 37.451169],
          [-122.083022, 37.451141],
          [-122.082966, 37.451114],
          [-122.082884, 37.451087],
          [-122.082835, 37.451080999999995],
          [-122.082808, 37.451108999999995],
          [-122.082794, 37.45118],
          [-122.082849, 37.451257],
          [-122.08296, 37.451378],
          [-122.083001, 37.451443999999995],
          [-122.083008, 37.451477],
          [-122.08296, 37.451521],
          [-122.08296, 37.451564999999995],
          [-122.083022, 37.451772999999996],
          [-122.083049, 37.451944],
          [-122.083042, 37.452003999999995],
          [-122.083001, 37.452037],
          [-122.082932, 37.452075],
          [-122.08264299999999, 37.452092],
          [-122.08251899999999, 37.45207],
          [-122.082436, 37.452031999999996],
          [-122.082374, 37.451988],
          [-122.08230499999999, 37.451966],
          [-122.08221499999999, 37.451993],
          [-122.082126, 37.452048],
          [-122.082043, 37.452059],
          [-122.081974, 37.452059],
          [-122.081905, 37.45207],
          [-122.081857, 37.45207],
          [-122.08180899999999, 37.452065],
          [-122.08162300000001, 37.452003999999995],
          [-122.081437, 37.451949],
          [-122.081313, 37.451921999999996],
          [-122.081251, 37.451916],
          [-122.081161, 37.451938],
          [-122.08103, 37.451993],
          [-122.080975, 37.452003999999995],
          [-122.08094, 37.451999],
          [-122.080803, 37.45196],
          [-122.080741, 37.45196],
          [-122.08072, 37.451971],
          [-122.080596, 37.452003999999995],
          [-122.080527, 37.452014999999996],
          [-122.080362, 37.451993],
          [-122.080231, 37.451966],
          [-122.080196, 37.451938],
          [-122.080203, 37.4519],
          [-122.080244, 37.451812],
          [-122.080244, 37.451746],
          [-122.080203, 37.451657999999995],
          [-122.080203, 37.451603],
          [-122.080189, 37.451537],
          [-122.080044, 37.451378],
          [-122.080024, 37.451197],
          [-122.079996, 37.451136],
          [-122.079858, 37.450983],
          [-122.079803, 37.450807],
          [-122.079789, 37.450741],
          [-122.079748, 37.450691],
          [-122.07952, 37.450624999999995],
          [-122.079486, 37.450609],
          [-122.079465, 37.450587],
          [-122.079438, 37.450499],
          [-122.079431, 37.450438999999996],
          [-122.07941, 37.450389],
          [-122.079351, 37.450353],
          [-122.079327, 37.450345],
          [-122.079334, 37.450179999999996],
          [-122.0793, 37.450071],
          [-122.079245, 37.450005],
          [-122.079038, 37.449947],
          [-122.07901, 37.449939],
          [-122.07899, 37.449911],
          [-122.078941, 37.449691],
          [-122.078831, 37.449526999999996],
          [-122.078673, 37.449362],
          [-122.078576, 37.449279],
          [-122.078535, 37.449235],
          [-122.078514, 37.449148],
          [-122.078514, 37.449070999999996],
          [-122.0785, 37.448972],
          [-122.078404, 37.448884],
          [-122.078335, 37.448800999999996],
          [-122.078328, 37.448713999999995],
          [-122.078335, 37.448664],
          [-122.078314, 37.448603999999996],
          [-122.07828599999999, 37.448564999999995],
          [-122.078273, 37.448482999999996],
          [-122.07821799999999, 37.44845],
          [-122.078156, 37.448406],
          [-122.078149, 37.448344999999996],
          [-122.078052, 37.448225],
          [-122.077916, 37.448222],
          [-122.077781, 37.448219],
          [-122.077651, 37.448215999999995],
          [-122.07752099999999, 37.448214],
          [-122.077508, 37.448219],
          [-122.077494, 37.448246999999995],
          [-122.07748699999999, 37.448378999999996],
          [-122.077508, 37.448461],
          [-122.077404, 37.448845],
          [-122.077342, 37.449104],
          [-122.077349, 37.449296],
          [-122.077336, 37.449400000000004],
          [-122.07732899999999, 37.449554],
          [-122.07732899999999, 37.44984],
          [-122.077281, 37.449988],
          [-122.077198, 37.450049],
          [-122.077156, 37.450054],
          [-122.077088, 37.450043],
          [-122.07699099999999, 37.449982999999996],
          [-122.076915, 37.449961],
          [-122.076764, 37.44995],
          [-122.07671500000001, 37.449922],
          [-122.076633, 37.449829],
          [-122.076557, 37.449774],
          [-122.076522, 37.449774],
          [-122.07643999999999, 37.449796],
          [-122.076405, 37.44984],
          [-122.076323, 37.449923],
          [-122.076254, 37.450005],
          [-122.076219, 37.450005],
          [-122.076185, 37.449999],
          [-122.076137, 37.449978],
          [-122.076081, 37.449961],
          [-122.07603499999999, 37.449961],
          [-122.076013, 37.449967],
          [-122.076006, 37.449985],
          [-122.076006, 37.450038],
          [-122.075971, 37.450109],
          [-122.075951, 37.450126],
          [-122.075916, 37.450131],
          [-122.07588200000001, 37.450131],
          [-122.075813, 37.450098],
          [-122.075737, 37.450064999999995],
          [-122.075654, 37.45],
          [-122.075571, 37.449895],
          [-122.075509, 37.449791],
          [-122.075475, 37.449746999999995],
          [-122.07542, 37.449729999999995],
          [-122.075289, 37.449740999999996],
          [-122.075241, 37.449757999999996],
          [-122.075206, 37.449791],
          [-122.075186, 37.449846],
          [-122.075158, 37.449878999999996],
          [-122.07513, 37.449889999999996],
          [-122.075096, 37.449873],
          [-122.075027, 37.449867999999995],
          [-122.074979, 37.449857],
          [-122.074937, 37.449857],
          [-122.074882, 37.449867999999995],
          [-122.074813, 37.449850999999995],
          [-122.074744, 37.449796],
          [-122.074703, 37.449746999999995],
          [-122.074675, 37.44967],
          [-122.074669, 37.449588],
          [-122.074634, 37.44956],
          [-122.074572, 37.449532999999995],
          [-122.074476, 37.4495],
          [-122.074386, 37.449439],
          [-122.074248, 37.449313],
          [-122.07407599999999, 37.449048999999995],
          [-122.073979, 37.449005],
          [-122.073904, 37.448961],
          [-122.073835, 37.448884],
          [-122.07373799999999, 37.448802],
          [-122.073683, 37.448796],
          [-122.073656, 37.448823999999995],
          [-122.073649, 37.44889],
          [-122.073649, 37.449005],
          [-122.073628, 37.449027],
          [-122.073593, 37.449044],
          [-122.073531, 37.449055],
          [-122.073366, 37.449022],
          [-122.073297, 37.448994],
          [-122.07327, 37.449000000000005],
          [-122.073235, 37.449022],
          [-122.07318000000001, 37.449048999999995],
          [-122.073139, 37.449059999999996],
          [-122.073097, 37.449082],
          [-122.073077, 37.449137],
          [-122.07309000000001, 37.449176],
          [-122.073042, 37.44928],
          [-122.073021, 37.44934],
          [-122.073001, 37.449357],
          [-122.07293899999999, 37.449357],
          [-122.07289399999999, 37.449372],
          [-122.072877, 37.449383999999995],
          [-122.072856, 37.449445],
          [-122.07281499999999, 37.449549],
          [-122.07278099999999, 37.449597],
          [-122.07276, 37.449604],
          [-122.072704, 37.449604],
          [-122.072615, 37.449582],
          [-122.072546, 37.449555],
          [-122.072518, 37.449555],
          [-122.072491, 37.449577],
          [-122.072401, 37.449632],
          [-122.072346, 37.449659],
          [-122.072305, 37.449664999999996],
          [-122.07216, 37.449643],
          [-122.072112, 37.449643],
          [-122.072057, 37.449659],
          [-122.072002, 37.449692],
          [-122.072015, 37.449731],
          [-122.072057, 37.449757999999996],
          [-122.072098, 37.449824],
          [-122.072124, 37.449932],
          [-122.072119, 37.44995],
          [-122.072091, 37.44995],
          [-122.072022, 37.449945],
          [-122.071988, 37.449928],
          [-122.07195300000001, 37.449895],
          [-122.071912, 37.449878999999996],
          [-122.071822, 37.449829],
          [-122.071774, 37.44984],
          [-122.071747, 37.449857],
          [-122.071726, 37.449878999999996],
          [-122.071698, 37.449895],
          [-122.071692, 37.449923],
          [-122.071671, 37.449961],
          [-122.071636, 37.450066],
          [-122.07154, 37.450319],
          [-122.071416, 37.450401],
          [-122.071381, 37.450401],
          [-122.07133999999999, 37.450406],
          [-122.071218, 37.450368],
          [-122.071195, 37.450368],
          [-122.071147, 37.450384],
          [-122.071092, 37.450395],
          [-122.071044, 37.450395],
          [-122.070996, 37.450401],
          [-122.07094000000001, 37.450384],
          [-122.070878, 37.450341],
          [-122.070775, 37.450286],
          [-122.070727, 37.450275],
          [-122.070658, 37.450297],
          [-122.070616, 37.450302],
          [-122.070479, 37.450258],
          [-122.070389, 37.450313],
          [-122.070306, 37.450368],
          [-122.070217, 37.450401],
          [-122.070175, 37.450401],
          [-122.07010700000001, 37.450395],
          [-122.070044, 37.450374],
          [-122.069996, 37.450362999999996],
          [-122.069969, 37.450368],
          [-122.06989300000001, 37.450483],
          [-122.069838, 37.450538],
          [-122.06980300000001, 37.450533],
          [-122.069748, 37.450533],
          [-122.069638, 37.450516],
          [-122.069603, 37.450527],
          [-122.069576, 37.450544],
          [-122.06952799999999, 37.450587999999996],
          [-122.069493, 37.450599],
          [-122.069445, 37.450571],
          [-122.06939, 37.450522],
          [-122.069369, 37.450489],
          [-122.069328, 37.450461],
          [-122.069286, 37.450444999999995],
          [-122.069183, 37.450434],
          [-122.069128, 37.450444999999995],
          [-122.0691, 37.450500000000005],
          [-122.069059, 37.450680999999996],
          [-122.069031, 37.450676],
          [-122.068866, 37.450599],
          [-122.068797, 37.450587999999996],
          [-122.06877, 37.450593],
          [-122.068714, 37.450654],
          [-122.068673, 37.45072],
          [-122.068632, 37.450736],
          [-122.068604, 37.450731],
          [-122.068517, 37.450693],
          [-122.068494, 37.450692],
          [-122.068446, 37.450714],
          [-122.068397, 37.450703],
          [-122.068356, 37.450680999999996],
          [-122.068322, 37.450604],
          [-122.068273, 37.450522],
          [-122.068239, 37.450511],
          [-122.068135, 37.450489],
          [-122.068115, 37.450472999999995],
          [-122.068032, 37.45033],
          [-122.067949, 37.45028],
          [-122.067894, 37.450269],
          [-122.067853, 37.45028],
          [-122.067818, 37.450297],
          [-122.067763, 37.450357],
          [-122.067722, 37.450412],
          [-122.067674, 37.450423],
          [-122.067612, 37.450407],
          [-122.067536, 37.450412],
          [-122.067433, 37.450444999999995],
          [-122.067391, 37.45044],
          [-122.067281, 37.450323999999995],
          [-122.067246, 37.450253],
          [-122.067054, 37.450092999999995],
          [-122.067053, 37.450027999999996],
          [-122.067088, 37.449934],
          [-122.067054, 37.449841],
          [-122.066991, 37.449797],
          [-122.066888, 37.449757999999996],
          [-122.066833, 37.449731],
          [-122.066812, 37.449692],
          [-122.066757, 37.449636999999996],
          [-122.066661, 37.449560999999996],
          [-122.066592, 37.4495],
          [-122.066406, 37.449411999999995],
          [-122.066385, 37.449385],
          [-122.06639200000001, 37.449346],
          [-122.066419, 37.449313],
          [-122.066447, 37.449242],
          [-122.066454, 37.449180999999996],
          [-122.066433, 37.449126],
          [-122.066371, 37.449121],
          [-122.06630200000001, 37.449121],
          [-122.066213, 37.44911],
          [-122.066171, 37.449061],
          [-122.066109, 37.449044],
          [-122.065951, 37.449087999999996],
          [-122.065875, 37.449082],
          [-122.065737, 37.448995],
          [-122.065703, 37.448944999999995],
          [-122.065661, 37.448823999999995],
          [-122.065648, 37.448769],
          [-122.065586, 37.448698],
          [-122.065434, 37.448577],
          [-122.065344, 37.448516999999995],
          [-122.065213, 37.448357],
          [-122.0652, 37.448291],
          [-122.065151, 37.448198],
          [-122.065103, 37.448159],
          [-122.064931, 37.448060999999996],
          [-122.06491, 37.447978],
          [-122.064862, 37.447835],
          [-122.06483399999999, 37.447797],
          [-122.064759, 37.447736],
          [-122.064738, 37.447708999999996],
          [-122.064738, 37.447680999999996],
          [-122.064759, 37.44766],
          [-122.064828, 37.447555],
          [-122.064855, 37.447506],
          [-122.064869, 37.447462],
          [-122.064848, 37.447429],
          [-122.0648, 37.447379],
          [-122.064717, 37.447252999999996],
          [-122.06467599999999, 37.447109999999995],
          [-122.06467599999999, 37.446835],
          [-122.064641, 37.446714],
          [-122.064579, 37.446653999999995],
          [-122.06449, 37.446594],
          [-122.064469, 37.446555],
          [-122.06446199999999, 37.446511],
          [-122.064476, 37.446473],
          [-122.064476, 37.446418],
          [-122.064386, 37.446329999999996],
          [-122.064373, 37.446286],
          [-122.064469, 37.446203],
          [-122.064476, 37.446165],
          [-122.064476, 37.446132],
          [-122.064352, 37.446061],
          [-122.064345, 37.446017],
          [-122.064373, 37.445994999999996],
          [-122.064393, 37.445945],
          [-122.064373, 37.445885],
          [-122.064331, 37.445862999999996],
          [-122.06429, 37.445857],
          [-122.064228, 37.445857],
          [-122.064173, 37.445845999999996],
          [-122.064173, 37.445802],
          [-122.06418, 37.445752999999996],
          [-122.064145, 37.445693],
          [-122.064145, 37.445631999999996],
          [-122.06385900000001, 37.44565],
          [-122.06357299999999, 37.445668],
          [-122.063287, 37.445685],
          [-122.063001, 37.445703],
          [-122.06289100000001, 37.445698],
          [-122.062808, 37.445681],
          [-122.06268399999999, 37.445671],
          [-122.062581, 37.445637999999995],
          [-122.062409, 37.445527999999996],
          [-122.062333, 37.445451],
          [-122.062216, 37.445429],
          [-122.062105, 37.445445],
          [-122.062057, 37.44544],
          [-122.06187800000001, 37.445434],
          [-122.061706, 37.445445],
          [-122.061478, 37.445478],
          [-122.061244, 37.445555],
          [-122.060996, 37.445627],
          [-122.060851, 37.445631999999996],
          [-122.060782, 37.445599],
          [-122.06070000000001, 37.445506],
          [-122.060624, 37.445484],
          [-122.060562, 37.445488999999995],
          [-122.060541, 37.445506],
          [-122.060486, 37.445539],
          [-122.060431, 37.445561],
          [-122.06039, 37.445566],
          [-122.060328, 37.445561],
          [-122.060238, 37.445561],
          [-122.06021, 37.445594],
          [-122.060086, 37.445698],
          [-122.060045, 37.445692],
          [-122.05999, 37.445654],
          [-122.059936, 37.445623999999995],
          [-122.05995, 37.445223999999996],
          [-122.059978, 37.444423],
          [-122.059992, 37.444022],
          [-122.060006, 37.443622],
          [-122.059939, 37.441705],
          [-122.059933, 37.441519],
          [-122.05986, 37.439416],
          [-122.059848, 37.439063],
          [-122.059767, 37.436747],
          [-122.05973900000001, 37.435955],
          [-122.059687, 37.434431],
          [-122.059674, 37.434058],
          [-122.059673, 37.434039],
          [-122.059679, 37.433592999999995],
          [-122.059685, 37.433147],
          [-122.05968899999999, 37.432874],
          [-122.059697, 37.432241999999995],
          [-122.059727, 37.430161999999996],
          [-122.059764, 37.427448999999996],
          [-122.059771, 37.426854],
          [-122.059772, 37.426839],
          [-122.058005, 37.426614],
          [-122.057368, 37.426549],
          [-122.05720000000001, 37.426700000000004],
          [-122.05705, 37.42679],
          [-122.05681, 37.426919999999996],
          [-122.05626, 37.42711],
          [-122.05533, 37.42744],
          [-122.05518000000001, 37.42752],
          [-122.05509, 37.42763],
          [-122.05505, 37.427769999999995],
          [-122.05505, 37.42787],
          [-122.05511, 37.42805],
          [-122.05529, 37.42838],
          [-122.0554, 37.42861],
          [-122.0554, 37.428689999999996],
          [-122.05539, 37.42877],
          [-122.05536000000001, 37.42886],
          [-122.0553, 37.42894],
          [-122.05521, 37.42901],
          [-122.05494, 37.42912],
          [-122.05338, 37.42962],
          [-122.05273, 37.4298],
          [-122.05252, 37.429809999999996],
          [-122.05222, 37.42977],
          [-122.05201, 37.42971],
          [-122.05188, 37.429629999999996],
          [-122.05175, 37.42952],
          [-122.05158, 37.429339999999996],
          [-122.05144, 37.42915],
          [-122.05126, 37.42884],
          [-122.05108, 37.42856],
          [-122.05094, 37.428329999999995],
          [-122.05064999999999, 37.42782],
          [-122.05035, 37.42723],
          [-122.04996, 37.426429999999996],
          [-122.049826, 37.426171],
          [-122.049172, 37.426139],
          [-122.047571, 37.426452999999995],
          [-122.04670899999999, 37.426623],
          [-122.043572, 37.427239],
          [-122.04275799999999, 37.427163],
          [-122.041135, 37.427012],
          [-122.041117, 37.427009999999996],
          [-122.041089, 37.427006999999996],
          [-122.041064, 37.427005],
          [-122.04104699999999, 37.427004],
          [-122.040418, 37.426947],
          [-122.03962899999999, 37.426874999999995],
          [-122.03874, 37.42678],
          [-122.03711799999999, 37.426649],
          [-122.03610499999999, 37.426558],
          [-122.03578, 37.42653],
          [-122.03487, 37.426446],
          [-122.034672, 37.426438],
          [-122.03508, 37.425489999999996],
          [-122.035231, 37.425134],
          [-122.036789, 37.421493999999996],
          [-122.036874, 37.420608],
          [-122.036924, 37.420102],
          [-122.03703, 37.419000000000004],
          [-122.03709, 37.41874],
          [-122.03709599999999, 37.418547],
          [-122.03711, 37.41818],
          [-122.03711, 37.417809999999996],
          [-122.03711, 37.417169],
          [-122.03711, 37.41685],
          [-122.03711, 37.416709999999995],
          [-122.037105, 37.415935],
          [-122.03710000000001, 37.41502],
          [-122.037108, 37.413613],
          [-122.03711, 37.41336],
          [-122.03709, 37.412839999999996],
          [-122.037092, 37.411901],
          [-122.037104, 37.411049],
          [-122.037142, 37.408496],
          [-122.037156, 37.407644999999995],
          [-122.037136, 37.407309999999995],
          [-122.037079, 37.406304999999996],
          [-122.03706, 37.405969999999996],
          [-122.037101, 37.405919],
          [-122.037133, 37.405073],
          [-122.037237, 37.402342],
          [-122.037255, 37.40189],
          [-122.037266, 37.401627999999995],
          [-122.03727, 37.40152],
          [-122.037271, 37.401488],
          [-122.037273, 37.401432],
          [-122.037277, 37.401406],
          [-122.03729, 37.40133],
          [-122.037295, 37.401305],
          [-122.037308, 37.401225],
          [-122.037323, 37.401136],
          [-122.037347, 37.400985],
          [-122.037361, 37.400906],
          [-122.03742, 37.40092],
          [-122.03895, 37.40123],
          [-122.039199, 37.401289999999996],
          [-122.04002, 37.401489999999995],
          [-122.04184000000001, 37.40189],
          [-122.04325, 37.40222],
          [-122.04469499999999, 37.402544],
          [-122.04543, 37.40271],
          [-122.04617999999999, 37.40287],
          [-122.046534, 37.40293],
          [-122.046557, 37.402933999999995],
          [-122.046626, 37.402946],
          [-122.04665, 37.40295],
          [-122.04687200000001, 37.403006],
          [-122.046958, 37.40277],
          [-122.047403, 37.401551999999995],
          [-122.046984, 37.401424999999996],
          [-122.046476, 37.401313],
          [-122.046008, 37.401210999999996],
          [-122.046413, 37.400089],
          [-122.046115, 37.400025],
          [-122.044979, 37.399822],
          [-122.044473, 37.399732],
          [-122.044793, 37.398759999999996],
          [-122.045273, 37.397301],
          [-122.045348, 37.397073999999996],
          [-122.045446, 37.396774],
          [-122.045545, 37.396473],
          [-122.045829, 37.39561],
          [-122.04594399999999, 37.395333],
          [-122.046064, 37.395252],
          [-122.04634300000001, 37.395067],
          [-122.046459, 37.394988],
          [-122.04654099999999, 37.394933],
          [-122.04664700000001, 37.394962],
          [-122.04675, 37.39499],
          [-122.04714, 37.39474],
          [-122.047676, 37.394436999999996],
          [-122.048242, 37.394084],
          [-122.048565, 37.393888],
          [-122.049253, 37.393467],
          [-122.050072, 37.392976999999995],
          [-122.050173, 37.392897999999995],
          [-122.05014, 37.39286],
          [-122.049921, 37.392623],
          [-122.04989, 37.39259],
          [-122.049784, 37.392469],
          [-122.04975, 37.39243],
          [-122.04951, 37.392179999999996],
          [-122.04939, 37.39204],
          [-122.04907, 37.39174],
          [-122.04881, 37.39152],
          [-122.04844, 37.391169999999995],
          [-122.04842, 37.39114],
          [-122.048301, 37.391211],
          [-122.048014, 37.391363],
          [-122.047787, 37.391535],
          [-122.047558, 37.391477],
          [-122.047449, 37.391577999999996],
          [-122.047024, 37.391861],
          [-122.046481, 37.392235],
          [-122.046254, 37.392416],
          [-122.046046, 37.392558],
          [-122.045907, 37.392609],
          [-122.045818, 37.392649999999996],
          [-122.045689, 37.392680999999996],
          [-122.04577, 37.392809],
          [-122.046212, 37.393243],
          [-122.046494, 37.393488999999995],
          [-122.046634, 37.393636],
          [-122.04666499999999, 37.393706],
          [-122.04666499999999, 37.393746],
          [-122.046616, 37.393786],
          [-122.046201, 37.394048999999995],
          [-122.045756, 37.394391999999996],
          [-122.045717, 37.394431999999995],
          [-122.04559499999999, 37.394572],
          [-122.045546, 37.394559],
          [-122.045001, 37.394388],
          [-122.0451, 37.394101],
          [-122.045168, 37.393906],
          [-122.045254, 37.393656],
          [-122.045638, 37.392556],
          [-122.045804, 37.392233999999995],
          [-122.04607899999999, 37.391704],
          [-122.046182, 37.391464],
          [-122.046493, 37.390746],
          [-122.04659699999999, 37.390507],
          [-122.047064, 37.390567],
          [-122.047257, 37.390592],
          [-122.047296, 37.389371],
          [-122.047305, 37.389106],
          [-122.04730599999999, 37.389084],
          [-122.047312, 37.3889],
          [-122.04731, 37.388878999999996],
          [-122.047301, 37.388760999999995],
          [-122.047271, 37.388346],
          [-122.047262, 37.388208],
          [-122.04729499999999, 37.388172],
          [-122.04739599999999, 37.388065],
          [-122.04742999999999, 37.38803],
          [-122.04746, 37.387879999999996],
          [-122.047445, 37.387751],
          [-122.04735099999999, 37.38691],
          [-122.04732, 37.38663],
          [-122.04735, 37.38657],
          [-122.04738499999999, 37.386534],
          [-122.04744, 37.38648],
          [-122.04764, 37.38639],
          [-122.04769, 37.386371],
          [-122.04780000000001, 37.38633],
          [-122.04798, 37.386269999999996],
          [-122.04864, 37.38607],
          [-122.04916, 37.38595],
          [-122.04968, 37.38587],
          [-122.05017, 37.38585],
          [-122.05059, 37.38585],
          [-122.05123, 37.385909999999996],
          [-122.05136, 37.385929999999995],
          [-122.05183, 37.38605],
          [-122.05217999999999, 37.38615],
          [-122.052212, 37.386084],
          [-122.05227, 37.38597],
          [-122.05242799999999, 37.386016],
          [-122.05261, 37.38607],
          [-122.05290000000001, 37.386162999999996],
          [-122.053057, 37.386213999999995],
          [-122.05378999999999, 37.386449999999996],
          [-122.053952, 37.386500000000005],
          [-122.05481, 37.38677],
          [-122.05649, 37.38733],
          [-122.05663200000001, 37.387378999999996],
          [-122.05727, 37.3876],
          [-122.05733000000001, 37.387625],
          [-122.05739, 37.38765],
          [-122.05752, 37.38769],
          [-122.05757299999999, 37.387707999999996],
          [-122.057665, 37.387657],
          [-122.057857, 37.387535],
          [-122.05795, 37.38756],
          [-122.05836, 37.38771],
          [-122.058716, 37.38783],
          [-122.05975, 37.38818],
          [-122.06147, 37.388746999999995],
          [-122.06239, 37.38905],
          [-122.062415, 37.389058],
          [-122.06249, 37.389086],
          [-122.06251499999999, 37.389095],
          [-122.062603, 37.389126999999995],
          [-122.06286, 37.38922],
          [-122.06291, 37.389089999999996],
          [-122.06296, 37.389109999999995],
          [-122.063, 37.38912],
          [-122.06330000000001, 37.38923],
          [-122.06409, 37.38955],
          [-122.06488999999999, 37.38986],
          [-122.06546, 37.39007],
          [-122.06566, 37.39015],
          [-122.06645, 37.39046],
          [-122.06736000000001, 37.39083],
          [-122.067944, 37.391073],
          [-122.06801, 37.3911],
          [-122.06828999999999, 37.39119],
          [-122.06840000000001, 37.39122],
          [-122.06841, 37.391169999999995],
          [-122.06841, 37.3911],
          [-122.068477, 37.391121],
          [-122.0686, 37.39116],
          [-122.06859, 37.39121],
          [-122.06859, 37.391223],
          [-122.06859, 37.391259999999996],
          [-122.068575, 37.391414999999995],
          [-122.06857, 37.39148],
          [-122.068805, 37.391576],
          [-122.06896, 37.391639999999995],
          [-122.069513, 37.391861999999996],
          [-122.06975, 37.391957],
          [-122.07140799999999, 37.392623],
          [-122.0716, 37.392700000000005],
          [-122.07278, 37.39315],
          [-122.076387, 37.394613],
          [-122.07702, 37.39487],
          [-122.07792, 37.395219999999995],
          [-122.07799, 37.39525],
          [-122.07804999999999, 37.39527],
          [-122.07855, 37.39546],
          [-122.078875, 37.395588],
          [-122.08, 37.396029999999996],
          [-122.081339, 37.396578999999996],
          [-122.08168, 37.396719999999995],
          [-122.08202, 37.39685],
          [-122.08207999999999, 37.396879999999996],
          [-122.082194, 37.396916999999995],
          [-122.08241100000001, 37.397006],
          [-122.08297, 37.39722],
          [-122.083166, 37.397297],
          [-122.083417, 37.397397],
          [-122.083883, 37.397582],
          [-122.085281, 37.398137999999996],
          [-122.085748, 37.398323999999995],
          [-122.085872, 37.398373],
          [-122.086246, 37.398520999999995],
          [-122.086371, 37.398571],
          [-122.086551, 37.398641999999995],
          [-122.087092, 37.398857],
          [-122.087273, 37.398928999999995],
          [-122.08807999999999, 37.399249999999995],
          [-122.090504, 37.400213],
          [-122.091312, 37.400534],
          [-122.091392, 37.400566],
          [-122.091635, 37.400662],
          [-122.09171599999999, 37.400694],
          [-122.09195199999999, 37.400788],
          [-122.092106, 37.400849],
          [-122.093279, 37.401314],
          [-122.09367, 37.401469999999996],
          [-122.09443, 37.401772],
          [-122.09671, 37.402678],
          [-122.09747, 37.40298],
          [-122.09828, 37.403301],
          [-122.100712, 37.404266],
          [-122.101523, 37.404588],
          [-122.101625, 37.404627999999995],
          [-122.101933, 37.404751],
          [-122.102036, 37.404792],
          [-122.102903, 37.405135],
          [-122.10362, 37.40542],
          [-122.1045, 37.40582],
          [-122.10543200000001, 37.406326],
          [-122.10598999999999, 37.40663],
          [-122.106041, 37.406662],
          [-122.106243, 37.406788],
          [-122.106534, 37.40697],
          [-122.107407, 37.407516],
          [-122.107699, 37.407697999999996],
          [-122.107821, 37.407773999999996],
          [-122.108187, 37.408003],
          [-122.10831, 37.40808],
          [-122.108285, 37.408104],
        ],
      ],
    },
  },
  {
    id: "28854",
    type: "Feature",
    properties: {
      ZCTA5CE10: "94089",
      GEOID10: "94089",
      CLASSFP10: "B5",
      MTFCC10: "G6350",
      FUNCSTAT10: "S",
      ALAND10: 15517488,
      AWATER10: 31508,
      INTPTLAT10: "+37.4124017",
      INTPTLON10: "-122.0156049",
      Name: "94089",
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.041135, 37.427012],
          [-122.0411, 37.42715],
          [-122.041075, 37.427251999999996],
          [-122.040988, 37.427600000000005],
          [-122.040976, 37.427645],
          [-122.040665, 37.428889999999996],
          [-122.04050000000001, 37.429545],
          [-122.040342, 37.430178999999995],
          [-122.039886, 37.431999],
          [-122.038519, 37.437461],
          [-122.03806399999999, 37.439282],
          [-122.03621799999999, 37.43834],
          [-122.033548, 37.436977],
          [-122.030683, 37.435514],
          [-122.029032, 37.434672],
          [-122.02883800000001, 37.434573],
          [-122.02864, 37.434470999999995],
          [-122.02852, 37.43441],
          [-122.0284, 37.434348],
          [-122.028137, 37.434214],
          [-122.026116, 37.433178999999996],
          [-122.024096, 37.432144],
          [-122.022972, 37.431568999999996],
          [-122.02036, 37.430231],
          [-122.017747, 37.428892999999995],
          [-122.017424, 37.428728],
          [-122.017356, 37.428692999999996],
          [-122.017289, 37.428658],
          [-122.016801, 37.428408999999995],
          [-122.01510999999999, 37.427543],
          [-122.01342, 37.426677],
          [-122.013109, 37.426518],
          [-122.01305099999999, 37.426488],
          [-122.012993, 37.426458],
          [-122.012726, 37.426322],
          [-122.012501, 37.426206],
          [-122.012428, 37.426169],
          [-122.012356, 37.426131999999996],
          [-122.012282, 37.426094],
          [-122.012209, 37.426055999999996],
          [-122.011697, 37.425792],
          [-122.006706, 37.423217],
          [-122.00171399999999, 37.420642],
          [-122.001606, 37.420586],
          [-122.001283, 37.42042],
          [-122.001176, 37.420365],
          [-122.001326, 37.419924],
          [-122.00143800000001, 37.4196],
          [-122.001699, 37.418835],
          [-122.001778, 37.418600999999995],
          [-122.001792, 37.418563],
          [-122.00181599999999, 37.418490999999996],
          [-122.001841, 37.418417999999996],
          [-122.001864, 37.418351],
          [-122.001929, 37.418161],
          [-122.00107, 37.41805],
          [-122.000864, 37.418022],
          [-122.00055, 37.41798],
          [-122.00012, 37.41793],
          [-121.99766, 37.41795],
          [-121.99713, 37.41794],
          [-121.99705, 37.41795],
          [-121.99690000000001, 37.41795],
          [-121.996578, 37.417975999999996],
          [-121.996211, 37.417713],
          [-121.996151, 37.41767],
          [-121.995744, 37.417376999999995],
          [-121.995655, 37.417313],
          [-121.99562, 37.417302],
          [-121.995032, 37.417075],
          [-121.994676, 37.416939],
          [-121.994607, 37.416939],
          [-121.994722, 37.416969],
          [-121.99477, 37.416989],
          [-121.994959, 37.417066],
          [-121.99502799999999, 37.417131999999995],
          [-121.995124, 37.417181],
          [-121.995139, 37.417196],
          [-121.99481, 37.417197],
          [-121.99448, 37.417198],
          [-121.99363, 37.4172],
          [-121.991038, 37.417207],
          [-121.989105, 37.417212],
          [-121.987597, 37.417215999999996],
          [-121.987512, 37.417215],
          [-121.987257, 37.417212],
          [-121.987173, 37.417211],
          [-121.987068, 37.417221999999995],
          [-121.986977, 37.417232999999996],
          [-121.986761, 37.417175],
          [-121.98666, 37.417148],
          [-121.986823, 37.417075],
          [-121.986815, 37.417057],
          [-121.986741, 37.416882],
          [-121.986679, 37.416778],
          [-121.986667, 37.416691],
          [-121.98687100000001, 37.416927],
          [-121.986839, 37.416847],
          [-121.986839, 37.416044],
          [-121.986839, 37.413377],
          [-121.98684, 37.412487999999996],
          [-121.986839, 37.412402],
          [-121.986838, 37.412144],
          [-121.986838, 37.412059],
          [-121.986838, 37.411954],
          [-121.986837, 37.41185],
          [-121.986836, 37.411783],
          [-121.986834, 37.411265],
          [-121.98683199999999, 37.410995],
          [-121.98683199999999, 37.410955],
          [-121.98683199999999, 37.41068],
          [-121.986827, 37.409825],
          [-121.98682600000001, 37.409411],
          [-121.98678100000001, 37.408598999999995],
          [-121.986749, 37.40846],
          [-121.986738, 37.407264999999995],
          [-121.98673099999999, 37.406411],
          [-121.986725, 37.405809],
          [-121.986719, 37.405125],
          [-121.986705, 37.404635999999996],
          [-121.98674, 37.404564],
          [-121.986774, 37.404393999999996],
          [-121.98687799999999, 37.404029],
          [-121.986991, 37.403639999999996],
          [-121.987008, 37.403580999999996],
          [-121.987036, 37.403448999999995],
          [-121.987063, 37.403321999999996],
          [-121.987111, 37.402943],
          [-121.987117, 37.402817],
          [-121.987108, 37.40224],
          [-121.987077, 37.400074],
          [-121.987075, 37.399902999999995],
          [-121.987057, 37.398596999999995],
          [-121.987052, 37.398191],
          [-121.987042, 37.397383],
          [-121.987041, 37.397345],
          [-121.987039, 37.397234],
          [-121.987039, 37.397197],
          [-121.987037, 37.397058],
          [-121.987031, 37.396642],
          [-121.98703, 37.396504],
          [-121.987028, 37.39636],
          [-121.987023, 37.395973],
          [-121.987023, 37.39593],
          [-121.987022, 37.395787],
          [-121.987019, 37.395299],
          [-121.98701, 37.393837],
          [-121.987007, 37.39335],
          [-121.98700099999999, 37.392458999999995],
          [-121.986984, 37.389786],
          [-121.98697899999999, 37.388895],
          [-121.986978, 37.388805],
          [-121.986977, 37.388678],
          [-121.986976, 37.388537],
          [-121.986976, 37.388448],
          [-121.98710000000001, 37.38848],
          [-121.98873, 37.388929999999995],
          [-121.99128, 37.38968],
          [-121.99277000000001, 37.39013],
          [-121.99358, 37.39035],
          [-121.993799, 37.390412],
          [-121.995033, 37.390758999999996],
          [-121.995529, 37.390921],
          [-121.99593, 37.391065],
          [-121.996138, 37.391141],
          [-121.996478, 37.391228],
          [-121.99721, 37.391447],
          [-121.997703, 37.39159],
          [-121.998224, 37.39174],
          [-121.998863, 37.391937999999996],
          [-121.999061, 37.391996999999996],
          [-121.99960300000001, 37.392159],
          [-121.999994, 37.392275999999995],
          [-122.002573, 37.392967],
          [-122.003761, 37.393308999999995],
          [-122.005088, 37.393657999999995],
          [-122.007153, 37.394251],
          [-122.00864, 37.39465],
          [-122.008674, 37.394656999999995],
          [-122.00879499999999, 37.394681],
          [-122.00889, 37.3947],
          [-122.009464, 37.394821],
          [-122.00965, 37.39486],
          [-122.01028600000001, 37.395],
          [-122.0126, 37.39551],
          [-122.012805, 37.395558],
          [-122.012829, 37.395564],
          [-122.01286, 37.395572],
          [-122.012883, 37.395576],
          [-122.01295, 37.39559],
          [-122.01318599999999, 37.395641],
          [-122.015466, 37.396139],
          [-122.01556, 37.39616],
          [-122.01635, 37.396325],
          [-122.01709, 37.39648],
          [-122.01740000000001, 37.39654],
          [-122.01765, 37.3966],
          [-122.01848, 37.396789999999996],
          [-122.020816, 37.397307999999995],
          [-122.02260000000001, 37.397703],
          [-122.022667, 37.397718],
          [-122.022976, 37.397785999999996],
          [-122.024107, 37.398036],
          [-122.024484, 37.39812],
          [-122.02471, 37.39817],
          [-122.0253, 37.39828],
          [-122.027229, 37.398711999999996],
          [-122.02771, 37.39882],
          [-122.02774, 37.398828],
          [-122.02783, 37.398852],
          [-122.02786, 37.39886],
          [-122.02806, 37.398902],
          [-122.02866, 37.399029999999996],
          [-122.02886, 37.399073],
          [-122.028996, 37.399102],
          [-122.029404, 37.399189],
          [-122.029541, 37.399218],
          [-122.029646, 37.39924],
          [-122.029961, 37.399307],
          [-122.030066, 37.39933],
          [-122.030742, 37.399473],
          [-122.030881, 37.399502999999996],
          [-122.030968, 37.399522],
          [-122.031339, 37.399606],
          [-122.032767, 37.399927],
          [-122.033442, 37.400079],
          [-122.033895, 37.400180999999996],
          [-122.034953, 37.400419],
          [-122.035255, 37.400482],
          [-122.03571, 37.40058],
          [-122.035755, 37.400589],
          [-122.035818, 37.400603],
          [-122.035892, 37.400624],
          [-122.035937, 37.400636999999996],
          [-122.036028, 37.400653],
          [-122.036301, 37.400698999999996],
          [-122.036392, 37.400715],
          [-122.036582, 37.400748],
          [-122.03694, 37.40081],
          [-122.03716299999999, 37.40086],
          [-122.037361, 37.400906],
          [-122.037347, 37.400985],
          [-122.037323, 37.401136],
          [-122.037308, 37.401225],
          [-122.037295, 37.401305],
          [-122.03729, 37.40133],
          [-122.037277, 37.401406],
          [-122.037273, 37.401432],
          [-122.037271, 37.401488],
          [-122.03727, 37.40152],
          [-122.037266, 37.401627999999995],
          [-122.037255, 37.40189],
          [-122.037237, 37.402342],
          [-122.037133, 37.405073],
          [-122.037101, 37.405919],
          [-122.03706, 37.405969999999996],
          [-122.037079, 37.406304999999996],
          [-122.037136, 37.407309999999995],
          [-122.037156, 37.407644999999995],
          [-122.037142, 37.408496],
          [-122.037104, 37.411049],
          [-122.037092, 37.411901],
          [-122.03709, 37.412839999999996],
          [-122.03711, 37.41336],
          [-122.037108, 37.413613],
          [-122.03710000000001, 37.41502],
          [-122.037105, 37.415935],
          [-122.03711, 37.416709999999995],
          [-122.03711, 37.41685],
          [-122.03711, 37.417169],
          [-122.03711, 37.417809999999996],
          [-122.03711, 37.41818],
          [-122.03709599999999, 37.418547],
          [-122.03709, 37.41874],
          [-122.03703, 37.419000000000004],
          [-122.036924, 37.420102],
          [-122.036874, 37.420608],
          [-122.036789, 37.421493999999996],
          [-122.035231, 37.425134],
          [-122.03508, 37.425489999999996],
          [-122.034672, 37.426438],
          [-122.03487, 37.426446],
          [-122.03578, 37.42653],
          [-122.03610499999999, 37.426558],
          [-122.03711799999999, 37.426649],
          [-122.03874, 37.42678],
          [-122.03962899999999, 37.426874999999995],
          [-122.040418, 37.426947],
          [-122.04104699999999, 37.427004],
          [-122.041064, 37.427005],
          [-122.041089, 37.427006999999996],
          [-122.041117, 37.427009999999996],
          [-122.041135, 37.427012],
        ],
      ],
    },
  },
];