import React, { useState } from 'react';
import { faqDataBorrow } from '../Faq/faqData';


const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  
    return (
      <div className={`border-b border-b-[#212529] mx-4`}>
        <dt>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-left w-full flex justify-between items-center p-5"
          >
            <span className="flex items-center">
              <div className="shrink-0 h-6 w-6 bg-[#F6BCC8] rounded-full flex items-center justify-center mr-4">
                {/* SVG or other content for the pink circle */}
              </div>
              <span className="font-normal text-[#212529]">{faq.question}</span>
            </span>
            <span className="h-7 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`${isOpen ? 'rotate-180' : ''} text-pink-600 h-6 w-6 transform`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </button>
        </dt>
        <dd className={`${isOpen ? 'max-h-screen' : 'max-h-0'} overflow-hidden transition-max-height duration-100 ease-in-out`}>
          <div className="p-5">
            <p className="text-[#212529]">{faq.answer}</p>
          </div>
        </dd>
      </div>
    );
  };

  const FAQSection = () => {
    return (
      <div className="bg-[#ffffff] py-10 sm:pt-[74px] sm:pb-[79px] px-0  text-[#212529]">
        <div className='flex flex-col items-center sm:justify-center mb-[20px] sm:mb-[60px]'>
        <h2 className="text-[32px] sm:text-6xl text-center font-[275]">Frequently Asked <span className='font-bold text-[#ED5272]'>Questions</span></h2>
        </div>
        <dl className="w-full sm:w-4/5 sm:mx-auto">
          {faqDataBorrow.map((faq, index) => (
            <FAQItem key={index} faq={faq} />
          ))}
        </dl>
      </div>
    );
  };

export default FAQSection;
