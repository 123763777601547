// captains
import Captain1 from "../../assets/team/Captain-1.jpg";
import Captain2 from "../../assets/team/Captain-2.jpg";
import Captain3 from "../../assets/team/Captain-3.jpeg";
import Captain4 from "../../assets/team/Captain-4.jpg";
import Captain5 from "../../assets/team/Captain-5.jpg";
import Captain6 from "../../assets/team/Captain-6.jpg";

import team1 from "../../assets/team/team-1.jpg";
import team2 from "../../assets/team/team-2.jpg";
import team3 from "../../assets/team/team-3.jpg";
import team4 from "../../assets/team/team-4.jpg";
import team5 from "../../assets/team/team-5.jpeg";
import team6 from "../../assets/team/team-6.png";
import team7 from "../../assets/team/team-7.png";
import team8 from "../../assets/team/team-8.JPG";
import team9 from "../../assets/team/team-9.jpg";
import team10 from "../../assets/team/team-10.jpg";
import team11 from "../../assets/team/team-11.jpg";

export const captains = [
  {
    id: 1,
    headshot: Captain1,
    name: "Stanlin Dias",
    linkedin: "https://www.linkedin.com/in/stanlin-dias-32a20a17a/",
    designation: "Capital Markets & BD",
    email: "mailto:stan@home.llc",
  },
  {
    id: 2,
    headshot: Captain2,
    name: "Siddharth Samant",
    linkedin: "https://www.linkedin.com/in/siddharth-samant-90/",
    designation: "Portfolio Manager",
    email: "mailto:sid@home.llc",
  },
  {
    id: 3,
    headshot: Captain3,
    name: "Ashwani Gupta",
    linkedin: "https://www.linkedin.com/in/reachaashu/",
    designation: "Customer Success Director",
    email: "mailto:ash@home.llc",
  },
  {
    id: 4,
    headshot: Captain4,
    name: "Dev Seth",
    linkedin: "https://www.linkedin.com/in/dev-seth-840774185/",
    designation: "Product Manager",
    email: "mailto:dev@home.llc",
  },
  {
    id: 5,
    headshot: Captain5,
    name: "Nishita Ann Matthew",
    linkedin: "https://www.linkedin.com/in/nishitaannmatthew/",
    designation: "Associate Talent Manager",
    email: "mailto:nishita@home.llc",
  },

  {
    id: 6,
    headshot: Captain6,
    name: "Jaya Singh",
    linkedin: "https://www.linkedin.com/in/jaya-singh-65ab0b237/",
    designation: "Associate Talent Manager",
    email: "mailto:jaya@home.llc",
  },
];

const team = [
  { id: 1, headshot: team1, LinkedIn: "" },
  { id: 2, headshot: team2, LinkedIn: "" },
  { id: 3, headshot: team3, LinkedIn: "" },
  { id: 4, headshot: team4, LinkedIn: "" },
  {
    id: 5,
    headshot: team5,
    LinkedIn: "https://www.linkedin.com/in/sudarsh-rathi-665081a5/",
  },
  { id: 6, headshot: team6, LinkedIn: "" },
  { id: 7, headshot: team7, LinkedIn: "" },
  { id: 8, headshot: team8, LinkedIn: "" },
  { id: 9, headshot: team9, LinkedIn: "" },
  {
    id: 10,
    headshot: team10,
    LinkedIn: "https://www.linkedin.com/in/amol-shelke-627813220/",
  },
  {
    id: 11,
    headshot: team11,
    LinkedIn: "https://www.linkedin.com/in/ruban-charles-aa1239165/",
  },
];

export default team;
