import React from 'react';

const ProgressIndicator = ({ steps, currentStep }) => {
  return (
    <div className="ml-8 mt-16 flex mr-8 items-center">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={`w-8 h-8 ${index === 6 ? 'w-16 h-16' : ''} ${index <= currentStep ? 'bg-black' : 'bg-pink-300'} rounded-full flex items-center justify-center`}>
  {
    index === 6
      ? <span className="text-white text-sm">{index + 1}</span> // Adjust the size as needed
      : <span className="text-white text-sm">{index + 1}</span>
  }
</div>

          {index < steps.length - 1 && (
            <div className={`flex-auto border-t-4 ${index < currentStep ? 'border-black' : 'border-pink-300'}`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressIndicator;



// import React from 'react'

// const ProgressIndicator = ({ steps, currentStep }) => {
//     return (
//       <div className="flex items-center">
//         {steps.map((steps, index) => (
//           <div key={index} className="flex items-center">
//             {index < currentStep && (
//               // This is for completed steps
//               <div className="w-8 h-8 bg-pink-500 rounded-full flex items-center justify-center">
//                 {/* Icon or checkmark */}
//               </div>
//             )}
//             {index === currentStep && (
//               // This is for the current step
//               <div className="w-8 h-8 bg-pink-200 rounded-full flex items-center justify-center">
//                 {/* Current step indicator */}
//               </div>
//             )}
//             {index > currentStep && (
//               // This is for future steps
//               <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
//                 {/* Future step indicator */}
//               </div>
//             )}
//             {index > currentStep && (
//               // This is for future steps
//               <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
//                 {/* Future step indicator */}
//               </div>
//             )}
//             {index < steps.length - 1 && (
//               // This is the connector line
//               <div className={`flex-auto border-t-2 ${index < currentStep ? 'border-pink-500' : 'border-gray-300'}`}></div>
//             )}
            
//           </div>
//         ))}
//       </div>
//     );
//   };
  

// export default ProgressIndicator
  