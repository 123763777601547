import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cashoutApi = createApi({
  reducerPath: "propertyRadarApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}`,
  }),
  tagTypes: ["Property", "ownerDetail"],
  endpoints: (builder) => ({
    searchPropertiesUsingAddress: builder.mutation({
      query: (criteriaObject) => ({
        url: `/propertyRadar/get-properties-using-address`,
        method: "POST",
        body: criteriaObject,
      }),
    }),
    getPropertyUsingRadarID: builder.query({
      query: (radarID) =>
        `/propertyRadar/get-property-using-radarID/${radarID}`,
    }),
    getOwnerDetailsUsingRadarID: builder.query({
      query: (radarID) =>
        `/propertyRadar/get-owner-details-using-radarID/${radarID}`,
      providesTags: ["ownerDetail"],
    }),
    getZestimatesUsingAddress: builder.query({
      query: (address) =>
        `/zestimate/get-zestimates-using-address?address=${address}`,
    }),
    purchaseOwnerEmailAddress: builder.mutation({
      query: (personKeyObj) => ({
        url: `propertyRadar/purchase-email-for-person`,
        method: "POST",
        body: personKeyObj,
      }),
      invalidatesTags: ["ownerDetail"],
    }),
    purchaseOwnerPhone: builder.mutation({
      query: (personKeyObj) => ({
        url: `propertyRadar/purchase-phone-for-person`,
        method: "POST",
        body: personKeyObj,
      }),
      invalidatesTags: ["ownerDetail"],
    }),
  }),
});

export const {
  useSearchPropertiesUsingAddressMutation,
  useGetPropertyUsingRadarIDQuery,
  useGetOwnerDetailsUsingRadarIDQuery,
  useGetZestimatesUsingAddressQuery,
  usePurchaseOwnerEmailAddressMutation,
  usePurchaseOwnerPhoneMutation,
} = cashoutApi;
