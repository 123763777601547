import FAQSection from "../components/HomePage/FAQSection";
// import Footer from "../components/HomePage/Footer";
import HeroSection from "../components/HomePage/HeroSection";
import Navbar from "../components/HomePage/Navbar";
// import Testimonials from "../components/HomePage/News";
// import PinkSection from "../components/HomePage/Pink";
// import Products from "../components/HomePage/Products";
// import TestimonialSlider from "../components/HomePage/TestimonialSlider";
import ToolsResources from "../components/HomePage/ToolsCard";
// import { testimonialsHomePage } from "../utils";
import Footer from "../components/Footer";
import LogoSection from "../components/HomePage/LogoSection";
import TestiMonial from "../components/HomePage/TestiMonial";
import GetStarted from "../components/HomePage/GetStarted";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";

function Home() {
  return (
    <div className="Home overflow-x-hidden">
     <Navbar/>
      <HeroSection />
      <LogoSection />
     {/* <Testimonials/> */}
     {/* <Products/> */}
     {/* <TestimonialSlider testimonials={testimonialsHomePage}/> */}
     <TestiMonial />
     <ToolsResources/>
     {/* <PinkSection/> */}
     <GetStarted />
      {/* <FAQSection /> */}
      <NeedFromUs />
     <Footer/>
    </div>
  );
}

export default Home;