import React from 'react'
import { useState } from 'react';
import MarketHotness from './MarketHotness/MarketHotness';
import SaleToListRatio from './SaleToListRatio';
import Heading from '../Ui/Heading';
const TabData=[
    "Market Hotness",
    "Days on Market",
    "Sale to List Ratio",
    "Properties sold above list price",
];

const Housing=() => {
    const [tab, setTab]=useState(TabData[0]);

    return (
      <div className="w-full h-full bg-white relative border-none shadow-none mt-[-5px] px-4 md:px-6">
  <div className="relative w-[95%] 2xl:w-[90%] py-8 md:py-[72px] mx-auto text-black h-full">
    <Heading>Housing</Heading>

    <div className="flex flex-wrap gap-x-4 gap-y-2 md:gap-x-24">
      {TabData?.map((item) => (
        <div
          key={item}
          onClick={() => setTab(item)}
          className={`text-lg md:text-2xl ${
            tab === item && "font-medium underline underline-offset-8"
          } cursor-pointer`}
        >
          {item}
        </div>
      ))}
    </div>
    <div className="w-full mt-6 md:mt-10">
      {tab === TabData[0] ? (
        <MarketHotness />
      ) : tab === TabData[1] ? (
        <MarketHotness />
      ) : tab === TabData[2] ? (
        <SaleToListRatio />
      ) : tab === TabData[3] ? (
        <SaleToListRatio />
      ) : null}
    </div>
  </div>
</div>
    )
}

export default Housing