import React, { useContext } from "react";
import MapBoxComponent from "../../MapBoxComponent";
import AmenitiesCard from "../Cards/AmenitiesCard";
import { CounterContext } from "../../../../hooks/HomeAi";

const Amenities=() => {
  const { aminities }=useContext(CounterContext);

  return (
    <div className="flex flex-col md:flex-row w-full gap-5">
      <div className="w-full md:w-3/5 rounded-xl overflow-hidden">
        <MapBoxComponent
          height={"450px"}
          styleUrl={"mapbox://styles/jayambalani/cly975vft00j701qv64m46d8p"}
        />
        {/* <iframe
          width="100%"
          height="400px"
          src="https://api.mapbox.com/styles/v1/jayambalani/cly975vft00j701qv64m46d8p.html?title=false&access_token=pk.eyJ1IjoiamF5YW1iYWxhbmkiLCJhIjoiY2x0M29sdWwwMDQxZjJxcDkwc2lpYWlueSJ9.9ZrrunddV5NRdIV8VeRNdw&zoomwheel=false#12.31/37.41717/-122.04714"
          title="Amenities Final"
          style={{ border: "none" }}
        ></iframe> */}
      </div>
      <div className="w-full md:w-2/5 flex flex-col max-h-[450px] scrollnon overflow-y-auto">
        {aminities?.map((item, i) => (
          <AmenitiesCard {...item} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Amenities;
