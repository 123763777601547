import { useEffect } from "react";

const TidyCalContact = ({operation}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://asset-tidycal.b-cdn.net/js/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full bg-calendy-contact-section bg-no-repeat bg-center bg-cover px-2 pb-10 pt-10 sm:pt-[94px] sm:pb-20" id="schedule-call">
      <h4 className="text-center text-2xl sm:text-[46px] mb-[94px] sm:leading-[82.1px] font-semibold text-white">
        {operation} Your Property with Confidence
      </h4>

      {/* Adjust the height of this container to fit your design */}
      <div className="w-full sm:w-[1004px] h-auto mx-auto mt-16 overflow-auto">
        {/* TidyCal embed */}
        <div className="tidycal-embed" data-path="m854grm/chat-with-home-agent" style={{ minHeight: '680px' }}></div>
      </div>
    </div>
  );
};

export default TidyCalContact;

