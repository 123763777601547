import React from 'react'
import { pressCardsData } from '../../utils'

const PressCards = () => {
  return (
   <div className='h-full w-full bg-white py-10 sm:py-0 sm:pb-20'>
    <div className='w-full px-6 sm:px-0 lg:w-[90%] lg:mx-auto sm:pt-[94px]'>
     <div className='flex flex-col md:flex-row lg:gap-10 flex-wrap justify-center'>
      {
       pressCardsData.map((card) => (
        <a href={card.url} className='cursor-pointer'>
        <div className='h-full w-full sm:w-[350px] 3xl:w-[350px] mb-5'>
         <img src={card.image} alt="press card" loading='lazy' className='mb-6 w-full' />
          <img src={card.companyLogo} alt="comapany" loading='lazy' className='mb-6 w-[90px] h-[30px]' />
          <p className='text-xl font-semibold leading-8 mb-4'>{card.text}</p>
          <span className='text-lg font-normal capitalize'>{card.date}</span>
         </div>
         </a>
       ))
       }
      </div>
      </div>
    </div>
  )
}

export default PressCards