import React from 'react'
import heroSvg1 from '../../assets/invest/heroSvg-1.svg'
import heroSvg2 from '../../assets/invest/heroSvg-2.svg'
import heroSvg3 from '../../assets/invest/heroSvg-3.svg'


const InvestHero = () => {
  return (
   <div className="w-full h-full sm:h-[900px] bg-invest-hero bg-no-repeat bg-cover bg-center text-white">
   <div className="h-full bg-hero-section-overlay bg-cover">
     <div className="h-full flex flex-col items-center mb-16 sm:mb-0 pt-32 sm:pt-40">
      <p className='mt-20 text-xl font-normal text-center mb-2 sm:mb-8 text-white capitalize'>Get up to 20% gross annual returns</p>
       <h2 className="text-[32px] leading-[42px] font-normal lg:text-[64px] text-center lg:leading-[82.14px] text-white capitalize mb-8">
         Home Equity INVESTMENTS (HEIs)
       </h2>

       {/* row items */}
      <div className="flex flex-col sm:flex-row items-center gap-8 sm:gap-40 mb-8 mt-20 sm:mt-10">
       <div className='h-[167px] w-[272px] flex flex-col items-center'>
        <img src={heroSvg1} alt="hero-text" className='mb-4' />
        <h3 className='mb-1 text-lg sm:text-3xl font-normal capitalize mt-2'>Protection</h3>
        <p className='capitalize text-sm sm:text-xl font-normal'>against market crash</p>
       </div>
       <div className='h-[167px] w-[272px]  flex flex-col items-center'>
        <img src={heroSvg2} alt="hero-text" className='mb-4' />
        <h3 className='mb-1 text-lg sm:text-3xl font-normal capitalize mt-2'>Zero Dependence</h3>
        <p className='capitalize text-sm sm:text-xl font-normal'>on appreciation or rentz </p>
       </div>
       <div className='h-[167px] w-[272px]  flex flex-col items-center'>
        <img src={heroSvg3} alt="hero-text" className='mb-4' />
        <h3 className='mb-1 text-lg sm:text-3xl font-normal capitalize mt-2'>Lower Risk</h3>
        <p className='capitalize text-sm sm:text-xl font-normal'>than SFRs or HELOCs</p>
       </div>
       </div>
     </div>
   </div>
 </div>
  )
}

export default InvestHero