import React, { useContext, useRef } from "react";
import { unEmploymentData } from "./data";
import poverty from "../../assets/poverty.svg";
import OccupationChart from "./Economy/OccupationsChart";
import TopIndustryChart from "./Economy/TopIndustriesChart";
import IncomeComparison from "./Economy/IncomeComparison";
import ReactEcharts from "echarts-for-react";
import { CounterContext } from "../../hooks/HomeAi";
import Heading from "./Ui/Heading";
import SubHeading from "./Ui/SubHeading";
const Economy=() => {
  const chartRef=useRef(null);
  const { Poverty, unemployementRate, area_name, pincode }=useContext(CounterContext);
  const percentageChange=((Math.random()*10)-5).toFixed(2);

  const option={
    xAxis: {
      boundaryGap: false,

      type: "category",
      data: unEmploymentData?.map((data) => data.name),
    },
    yAxis: {
      type: "value",
      axisLabel: { show: false },
      axisLine: { show: true },
    },
    series: [
      {
        data: unEmploymentData?.map((data) => data.employment),
        type: "line",
        symbol: "none",

        lineStyle: {
          width: 3,
          color: "#ED5272",
        },
      },
    ],
  };

  return (
    <div className="w-full h-full bg-[#F1F3F5] relative border-none shadow-none mt-[-5px]">
    <div className="relative w-[90%] py-[30px] xl:py-[50px] mx-auto text-black h-full">
      <Heading>Economy</Heading>
  
      <div className="flex flex-col md:flex-row w-full h-auto mb-10">
        <div className="w-full md:w-2/6 mb-6 md:mb-0">
          <SubHeading>Unemployment Rate</SubHeading>
          <p className="text-[40px] md:text-[80px] font-normal mr-1">{unemployementRate?.currentRate}%</p>
          <p className="text-base md:text-[16px] 2xl:text-lg mt-2 w-full md:w-[85%]">
            The unemployment rate of {area_name} is {unemployementRate?.currentRate}% with {percentageChange}% {percentageChange > 0 ? "increment" : "decrement"} compared to last year.
          </p>
        </div>
        <div className="w-full md:w-4/6 h-[250px] md:h-[313px]">
          <ReactEcharts ref={chartRef} style={{ width: "100%", height: "100%" }} option={option} />
        </div>
      </div>
  
      <div className="flex flex-col md:flex-row w-full mb-10 gap-4 md:gap-10">
        <div className="w-full md:w-3/6 mb-6 md:mb-0">
          <SubHeading>Top 5 Industries</SubHeading>
          <p className="text-base md:text-[16px] 2xl:text-lg mt-0 mb-4 font-normal">Avg earnings and people employed</p>
          <div className="py-4 flex justify-center">
            <TopIndustryChart className="w-full max-w-[300px] md:max-w-none" />
          </div>
        </div>
        <div className="w-full md:w-3/6">
          <h2 className="text-lg md:text-xl 2xl:text-3xl font-[500] md:text-left">Top 5 Occupations</h2>
          <p className="text-base md:text-[16px] 2xl:text-lg mt-0 mb-4 font-normal md:text-left">Avg earnings and people employed</p>
          <div className="flex justify-center">
            <OccupationChart className="w-full max-w-[300px] md:max-w-none" />
          </div>
        </div>
      </div>
  
      <div className="flex flex-col md:flex-row w-full gap-4 md:gap-10">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <SubHeading>Income Distribution</SubHeading>
          <p className="text-base md:text-[16px] 2xl:text-lg mt-0 mb-4 font-normal">Percentage of households in each income bracket</p>
          <IncomeComparison />
        </div>
        <div className="w-full md:w-1/2">
          <SubHeading>Poverty</SubHeading>
          <p className="text-base md:text-[16px] 2xl:text-lg mt-0 mb-4 font-normal">Percentage of population under poverty</p>
  
          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="flex justify-center items-center">
              <div style={{ background: `conic-gradient(#0F5DDD 0% ${Poverty}%, #8FB4F1 ${Poverty}% 100%)` }} className="relative w-48 h-48 md:w-60 md:h-60 rounded-full flex justify-center items-center">
                <div style={{ background: 'conic-gradient(#1F75FE 0% 12.12%, #94BCFB 12.12% 100%)' }} className="absolute w-36 h-36 md:w-48 md:h-48 rounded-full flex justify-center items-center">
                  <div style={{ background: 'conic-gradient(#5090F6 0% 12.53%, #A5C4F5 12.53% 100%)' }} className="absolute w-24 h-24 md:w-36 md:h-36 rounded-full flex justify-center items-center">
                    <div className="absolute w-12 h-12 md:w-24 md:h-24 bg-white rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center gap-x-2">
                <div className="h-[24px] w-[8px] bg-[#0F5DDD]"></div>
                <div className="flex text-black font-semibold text-[18px] md:text-[30px]">{Poverty}% {pincode}</div>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="h-[20px] w-[8px] bg-[#1F75FE]"></div>
                <div className="flex text-black font-semibold text-[14px] md:text-[20px]">12.12% California</div>
              </div>
              <div className="flex items-center gap-x-2">
                <div className="h-[20px] w-[8px] bg-[#5090F6]"></div>
                <div className="flex text-black font-semibold text-[14px] md:text-[20px]">12.53% United States</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Economy;
