import React from 'react'
import nik from '../../assets/testimonial/Nik.svg'
import ted from '../../assets/testimonial/Ted.svg'


const TestiMonial = () => {
  return (
    <div className="w-full h-full pb-10 sm:pb-20">
    <div className="w-full pt-10 sm:pt-[74px]">
    <h2 className="text-[32px] sm:text-6xl font-[275] text-center">Love letters <span className='font-bold text-[#ED5272]'>to us</span></h2>
    </div>
      <div className='px-2 sm:px-12 flex flex-col sm:flex-row items-center justify-center gap-20 sm:gap-[150px] mt-[100px] sm:mt-[140px] h-full'>
        <div className='w-full sm:w-[615px] h-[277px] sm:h-[430px] bg-[#F1F3F5] rounded-[20px] relative'>
          <div className='h-auto sm:h-[150px] mb-6 sm:mb-11'>
            <img src={ted} alt="ted" className='relative top-[-50px] sm:top-[-80px] left-0 h-[114px] w-[114px] sm:h-auto sm:w-auto' />

            <div className='w-52 h-[86px] absolute top-6 right-10'>
              <h4 className='text-2xl sm:text-4xl text-right font-semibold mb-2'>Ted Farry</h4>
              <p className='text-sm sm:text-lg font-semibold font-quickSand text-right text-[#ED5272]'>Senior analyst at a REIT</p>
            </div>
          </div>

          <div className='pb-1 px-6 sm:w-auto sm:px-10 sm:h-[190px]'>
            <h3 className='mb-4 text-2xl 2xl:text-4xl font-semibold'>“Nik is a strategic advisor to me and..</h3>
            <p className='font-quickSand font-normal text-[16px] sm:text-lg 2xl:text-[22px]'>..my leadership team plus is helping us acquire real estate faster!</p>
          </div>
        </div>
        <div className='w-full sm:w-[615px] h-[365px] sm:h-[430px] bg-[#F1F3F5] rounded-[20px] relative'>
          <div className='h-auto sm:h-[150px] mb-6 sm:mb-11'>
            <img src={nik} alt="ted" className='relative top-[-50px] sm:top-[-80px] left-0 h-[114px] w-[114px] sm:h-auto sm:w-auto' />

            <div className='w-56 h-[86px] absolute top-6 right-24'>
              <h4 className='text-2xl sm:text-4xl text-right font-semibold mb-2 whitespace-nowrap'>Nick Seforos</h4>
              <p className='text-sm sm:text-lg font-semibold font-quickSand text-left text-[#ED5272] whitespace-nowrap'>Logistics + Small Business Investor</p>
            </div>
          </div>

          <div className='pb-1 px-6 sm:w-auto sm:px-10 sm:h-[190px]'>
            <h3 className='mb-4 text-2xl 2xl:text-4xl font-semibold'>“Hey, this is Nick and this is Denali. We want to thank..</h3>
            <p className='font-quickSand font-normal text-[16px] sm:text-lg 2xl:text-[22px]'>..Home.LLC for helping us buy this beautiful home. She helped us lower our loan amount by $58,500. I highly recommend Home.LLC team, thank you!</p>
          </div>
         </div>
      </div>
    </div>
  )
}

export default TestiMonial
