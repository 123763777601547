import React from 'react'
import TidyCalContact from '../components/BuyerLanding/TidyCal'
import FAQSection from '../components/BuyerLanding/FaqSection'
import Footer from '../components/Footer'

const ScheduleCallBuy = () => {
  return (
   <div>
    <TidyCalContact operation={'Buy'} />
    <FAQSection />
    <Footer />
    </div>
  )
}

export default ScheduleCallBuy