import Calendar from "../../assets/cashout/calendar.png";
import Square from "../../assets/cashout/lot.png";
import homeArea from "../../assets/cashout/area.png";
import Bed from "../../assets/cashout/beds.png";
import Equity from "../../assets/cashout/equity.png";
import loanBalance from "../../assets/cashout/loan.png";
import DollarSign from "../../assets/cashout/dollar.png";
import homeValue from "../../assets/cashout/home-value.png";
import Person from "../../assets/cashout/person.png";
import StreetViewComponent from "./StreetView";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOwner, updateStateProperty } from "../../store/slices/cashoutSlice";
import { CircularProgress } from "@mui/material";
import {
  usePurchaseOwnerEmailAddressMutation,
  usePurchaseOwnerPhoneMutation,
} from "../../store/apis/cashoutApi";

// Data Block Component
function DataBlock({ title, icon: Icon, value }) {
  return (
    <div className="w-[350px]">
      <h2 className="text-xl font-normal mb-4 border-l-4 border-l-custom-primaryPink pl-4">
        {title}
      </h2>
      <div className="flex items-center gap-2 ml-2">
        <img src={Icon} alt="icons" className="h-6 w-6" />
        <span className="text-2xl">{value ? value : "N/A"}</span>
      </div>
    </div>
  );
}

// Owner Details Component
function OwnerDetails({ owners }) {
  const [purchaseEmailAddress, { isLoading, isError, isSuccess }] =
    usePurchaseOwnerEmailAddressMutation();

  const [
    purchasePhone,
    {
      isLoading: phoneLoading,
      isError: isPhoneError,
      isSuccess: isPhoneSuccess,
    },
  ] = usePurchaseOwnerPhoneMutation();

  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();

  const handleSelectOwner = async (owner) => {
    setSelected(owner);

    if (owner["Email"] === undefined) {
      const data = await purchaseEmailAddress({
        personKey: owner["PersonKey"],
      });
      if (data["results"] !== undefined) {
        console.log(data["results"]);

        dispatch(
          updateStateProperty({
            propertyName: "userEmail",
            value: data["results"][0]["Email"][0]["linktext"],
          })
        );
      }
      console.log(data, "purchase email req");
    }

    if (owner["Phone"] === undefined) {
      const data = await purchasePhone({
        personKey: owner["PersonKey"],
      });
      if (data["results"] !== undefined) {
        console.log(data["results"]);
        dispatch(
          updateStateProperty({
            propertyName: "userPhone",
            value: data["results"][0]["Phone"][0]["linktext"],
          })
        );
      }
      console.log(data, "purchase phone req");
    }

    if (owner["Email"] !== undefined) {
      if (!owner["Email"][0]["linktext"]) {
        const data = await purchaseEmailAddress({
          personKey: owner["PersonKey"],
        });
        if (data["results"] !== undefined) {
          console.log(data["results"]);

          dispatch(
            updateStateProperty({
              propertyName: "userEmail",
              value: data["results"][0]["Email"][0]["linktext"],
            })
          );
        }
        console.log(data["data"]);
        console.log(data, "purchasing email data");
      }
    }

    console.log(owner["Phone"]);
    if (owner["Phone"] !== undefined) {
      if (!owner["Phone"][0]["linkText"]) {
        const data = await purchasePhone({ personKey: owner["PersonKey"] });
        if (data["results"] !== undefined) {
          console.log(data["results"]);

          dispatch(
            updateStateProperty({
              propertyName: "userPhone",
              value: data["results"][0]["Phone"][0]["linktext"],
            })
          );
        }
        console.log(data, "purchasing phone data");
      }
    }

    dispatch(setOwner({ owner }));
  };

  useEffect(() => {
    if (selected === null) {
      dispatch(setOwner({}));
    }
  }, [selected, dispatch]);

  return (
    <div className="w-[350px]" key={"owner details"}>
      <h2 className="text-xl font-normal mb-4 border-l-4 border-l-custom-primaryPink pl-4">
        Owner name (Select)
      </h2>
      <div className="space-y-3">
        {owners
          ?.filter((owner) => owner.PersonType === "Person")
          .map((owner, index) => {
            const { FirstName, LastName, MiddleName } = owner;

            return (
              <div key={index} className="flex items-center gap-2">
                <img src={Person} alt="user" className="h-6 w-6 ml-2" />
                <span className="whitespace-nowrap text-2xl overflow-hidden text-ellipsis w-[200px]">
                  {maskName(FirstName)} {maskName(MiddleName ? MiddleName : "")}{" "}
                  {""}
                  {maskName(LastName)}
                </span>
                <div
                  className={`w-4 h-4 rounded-full border border-custom-primaryPink ${
                    selected?.PersonKey === owner?.PersonKey
                      ? "bg-custom-primaryPink border-black p-1 rounded-full"
                      : "bg-transparent rounded-full"
                  }`}
                  onClick={() => handleSelectOwner(owner)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

// Row Component
function Row({ children }) {
  return (
    <div className="flex items-start justify-center w-full gap-x-80 mb-12 max-h-[200px] max-w-[90%] mx-auto">
      {children}
    </div>
  );
}

// Main Component
function HomeDetails({ propertyRes, ownerDetails }) {
  const propertyData = propertyRes ? propertyRes[0] : null;
  const errorState = useSelector((state) => state.cashout.errorState);

  if (errorState !== "") return null;

  const data = {
    ownedSince: new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    }).format(new Date(propertyData?.LastTransferRecDate || new Date())),
    purchasePrice: propertyData?.LastTransferValue?.toLocaleString("en-US"),
    lotSize: propertyData?.LotSize?.toLocaleString("en-US"),
    area: propertyData?.SqFt?.toLocaleString("en-US"),
    bedBath: `${propertyData?.Beds}/${propertyData?.Baths}`,
    estimatedHomeValue: `$${propertyData?.AVM?.toLocaleString("en-US")}`,
    loanBalance: `$${propertyData?.TotalLoanBalance?.toLocaleString("en-US")}`,
    equity: `$${propertyData?.AvailableEquity?.toLocaleString("en-US")}`,
  };

  return (
    <div
      className="bg-custom-lightWhiteBG w-full -mt-[20px] h-full"
      key={"home details"}
    >
      <h2 className="text-[3.5rem] text-center font-normal font-poppins mb-5">
        Home Details
      </h2>
      <div className="max-w-[90%] w-full mx-auto">
        {/* <div className="ml-10"> */}
        {/* Top Row */}
        <div className="flex justify-center gap-4 items-center">
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM3 3.5L397.5 3.49997L397.5 2.49997L3 2.5L3 3.5Z"
              fill="#ED5272"
            />
          </svg>
          <h2 className="whitespace-nowrap text-4xl font-normal text-custom-gray10 text-center w-[400px]">
            Basic details
          </h2>
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M397.887 2.99997L395 0.113214L392.113 2.99997L395 5.88672L397.887 2.99997ZM0.5 3.5L395 3.49997L395 2.49997L0.5 2.5L0.5 3.5Z"
              fill="#ED5272"
            />
          </svg>
        </div>
        <Row>
          <OwnerDetails owners={ownerDetails} />
          <DataBlock
            title="Owned since"
            icon={Calendar}
            value={data.ownedSince}
          />
          <DataBlock
            title="Purchase price"
            icon={DollarSign}
            value={data.purchasePrice}
          />
        </Row>

        {/* Middle Row */}
        <div className="flex justify-center gap-4 items-center">
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM3 3.5L397.5 3.49997L397.5 2.49997L3 2.5L3 3.5Z"
              fill="#ED5272"
            />
          </svg>
          <h2 className="whitespace-nowrap text-4xl font-normal text-custom-gray10 text-center w-[400px]">
            Property details
          </h2>
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M397.887 2.99997L395 0.113214L392.113 2.99997L395 5.88672L397.887 2.99997ZM0.5 3.5L395 3.49997L395 2.49997L0.5 2.5L0.5 3.5Z"
              fill="#ED5272"
            />
          </svg>
        </div>
        <Row>
          <DataBlock title="Lot size" icon={Square} value={data.lotSize} />
          <DataBlock title="Area" icon={homeArea} value={data.area} />
          <DataBlock title="Bed/Bath" icon={Bed} value={data.bedBath} />
        </Row>

        {/* Bottom Row */}
        <div className="flex justify-center gap-4 items-center">
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM3 3.5L397.5 3.49997L397.5 2.49997L3 2.5L3 3.5Z"
              fill="#ED5272"
            />
          </svg>
          <h2 className="whitespace-nowrap text-4xl font-normal text-custom-gray10 text-center w-[400px]">
            Value & equity details
          </h2>
          <svg
            width="398"
            height="6"
            viewBox="0 0 398 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M397.887 2.99997L395 0.113214L392.113 2.99997L395 5.88672L397.887 2.99997ZM0.5 3.5L395 3.49997L395 2.49997L0.5 2.5L0.5 3.5Z"
              fill="#ED5272"
            />
          </svg>
        </div>
        <Row>
          <DataBlock
            title="Est home value"
            icon={homeValue}
            value={data.estimatedHomeValue}
          />
          <DataBlock
            title="Est mortgage balance"
            icon={loanBalance}
            value={data.loanBalance}
          />
          <DataBlock title="Est equity" icon={Equity} value={data.equity} />
        </Row>
        {/* </div> */}

        <h4 className="text-xl font-normal font-poppins ml-16">Map View</h4>
      </div>

      {/* pass dynamic values to this component from property radar */}
      <div className="w-full h-[400px] mt-4">
        <StreetViewComponent
          lattitude={propertyData?.Latitude}
          longitude={propertyData?.Longitude}
        />
      </div>
    </div>
  );
}

export default HomeDetails;

export const maskName = (name = "as") => {
  if (name?.length <= 2) return name; // Don't mask if the name is too short
  return (
    name[0] +
    "**" +
    name.slice(1, -1).replace(/[a-zA-Z]/g, "*") +
    name[name?.length - 1]
  );
};
