import React from 'react'
import ted from '../../assets/testimonial/Ted.svg'


const Testimonial = () => {
  return (
    <div className='w-full h-full pb-10 sm:pb-20 sm:pt-[74px]'>
    <h2 className='text-3xl sm:text-6xl font-light text-center mb-[80px] sm:mb-[174px]'>Love letters <span className='text-[#6A6DFF] font-bold'>to us</span></h2>
    
    <div className='w-full sm:w-3/4 sm:mx-auto sm:h-[430px] bg-[#F1F1FF] rounded-[20px] relative py-4 sm:py-0'>
     <div className='flex justify-center'>
     <img src={ted} alt="ted" className='absolute top-[-40px] sm:top-[-80px] h-[100px] w-[100px] sm:h-auto sm:w-auto' />
     </div>
     <div className='mt-[80px] sm:mt-[150px] flex flex-col justify-center'>
      <div>
        <h3 className='text-2xl sm:text-4xl font-semibold mb-2 text-center'>Ted Farry</h3>
        <p className='text-sm sm:text-lg text-[#6A6DFF] font-semibold text-center font-quickSand'>Senior analyst at a REIT</p>
      </div>
      <div className='mt-5'>
       <h3 className='text-3xl sm:text-4xl font-semibold mb-4 text-center'>“Nik is a strategic advisor to me and.</h3>
       <p className='text-lg sm:text-xl font-normal text-center font-quickSand'>..my leadership team plus is helping us acquire real estate faster!</p>
      </div>
     </div>
    </div> 
  </div>
  )
}

export default Testimonial
