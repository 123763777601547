export const aminitiesData = [
  {
    type: "Feature",
    properties: {
      formatted_address: "1235 Oakmead Pkwy, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 736-2739",
      name: "Laughing Monk Brewing and Gastropub",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 8:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://sunnyvale.laughingmonkbrewing.com/",
      first_type: "bar",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9924988, 37.387637] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-2828",
      name: "Saigon Seafood Harbor",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Tuesday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Saturday: 10:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Sunday: 10:00 AM – 2:30 PM, 5:00 – 9:00 PM",
        ],
      },
      rating: "4",
      website: "http://saigonharbors.com/",
      first_type: "restaurant",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9970277, 37.3972859] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "969 E Caribbean Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 475-0044",
      name: "Twin Creeks Sports Complex",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2330" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2330" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2330" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 11:30 PM",
          "Tuesday: 4:00 – 11:30 PM",
          "Wednesday: 4:00 – 11:30 PM",
          "Thursday: 4:00 PM – 12:00 AM",
          "Friday: 4:00 PM – 12:00 AM",
          "Saturday: 7:00 AM – 12:00 AM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.twin-creeks.com/",
      first_type: "bar",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0003518, 37.4161451] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "800 W Ahwanee Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 524-3580",
      name: "Hobee's",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://hobees.com/locations/sunnyvale/",
      first_type: "restaurant",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0280205, 37.3955388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 Ellis St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(415) 523-8992",
      name: "Specialty's Café & Bakery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 4:00 PM",
          "Tuesday: 7:00 AM – 4:00 PM",
          "Wednesday: 7:00 AM – 4:00 PM",
          "Thursday: 7:00 AM – 4:00 PM",
          "Friday: 7:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://specialtys.com/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0507926, 37.4028743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-2987",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.starbucks.com/store-locator/store/16802/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0303206, 37.388266] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "666 N Fair Oaks Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 736-3793",
      name: "Chavez Supermarket",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://www.chavezsuper.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.014124, 37.3902022] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "415 N Mary Ave #102, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-6200",
      name: "Little India Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Tuesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Saturday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Sunday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.littleindiacafeus.com/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0423227, 37.3898184] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1127 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-4221",
      name: "Giovanni's New York Pizzeria",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 2:00 PM, 3:00 – 9:00 PM",
          "Tuesday: 10:30 AM – 2:00 PM, 3:00 – 9:00 PM",
          "Wednesday: 10:30 AM – 2:00 PM, 3:00 – 9:00 PM",
          "Thursday: 10:30 AM – 2:00 PM, 3:00 – 9:00 PM",
          "Friday: 10:30 AM – 2:00 PM, 3:00 – 9:00 PM",
          "Saturday: 10:30 AM – 9:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "https://www.giovannisnypizza.com/",
      first_type: "restaurant",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9969444, 37.3969444] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "510 Lawrence Expy, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 738-8515",
      name: "St. John's Bar & Grill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "http://www.stjohnsgrill.com/",
      first_type: "bar",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9953639, 37.3825627] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "773 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 736-0101",
      name: "Burger King",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 7:00 AM – 12:00 AM",
          "Friday: 7:00 AM – 12:00 AM",
          "Saturday: 7:00 AM – 12:00 AM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      rating: "3.6",
      website: "https://burgerking.com/store-locator/store/restaurant_6,232",
      first_type: "restaurant",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0294045, 37.3954448] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "464 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 735-1900",
      name: "Pizza Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 12:00 AM",
          "Tuesday: 10:00 AM – 12:00 AM",
          "Wednesday: 10:00 AM – 12:00 AM",
          "Thursday: 10:00 AM – 12:00 AM",
          "Friday: 10:00 AM – 1:00 AM",
          "Saturday: 10:00 AM – 1:00 AM",
          "Sunday: 10:00 AM – 12:00 AM",
        ],
      },
      rating: "3.7",
      website:
        "https://locations.pizzahut.com/ca/sunnyvale/464-n-mathilda-ave?utm_medium=organic&utm_source=local&utm_campaign=googlelistings&utm_content=website&utm_term=292848",
      first_type: "meal_delivery",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0302023, 37.388337] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "415 N Mary Ave Suite 105, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 720-1774",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 9:00 PM",
          "Tuesday: 8:30 AM – 9:00 PM",
          "Wednesday: 8:30 AM – 9:00 PM",
          "Thursday: 8:30 AM – 9:00 PM",
          "Friday: 8:30 AM – 9:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.9",
      website:
        "https://restaurants.subway.com/united-states/ca/sunnyvale/415-n-mary-ave?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=5834&utm_campaign=evergreen-2020&y_source=1_MTQ4OTk2NzktNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.042271, 37.389998899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "615 Caliente Dr, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 732-3030",
      name: "Domino's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 12:00 AM",
          "Tuesday: 10:00 AM – 12:00 AM",
          "Wednesday: 10:00 AM – 12:00 AM",
          "Thursday: 10:00 AM – 12:00 AM",
          "Friday: 10:00 AM – 1:00 AM",
          "Saturday: 10:00 AM – 1:00 AM",
          "Sunday: 10:00 AM – 12:00 AM",
        ],
      },
      rating: "3.1",
      website:
        "https://www.dominos.com/en/?utm_source=google&utm_medium=loclist&utm_campaign=localmaps",
      first_type: "meal_delivery",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0135707, 37.3914497] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "736 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 530-9200",
      name: "Dish n Dash",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "https://dishdash.com/",
      first_type: "restaurant",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0288109, 37.3943264] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "484 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 737-1834",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://luckysupermarkets.com/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "grocery_or_supermarket",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0295791, 37.388414299999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "920 E Arques Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 735-1111",
      name: "Sri Ananda Bhavan - Sunnyvale",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1730" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1030" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1730" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 2:30 PM, 5:30 – 10:00 PM",
          "Tuesday: 10:30 AM – 2:30 PM, 5:30 – 10:00 PM",
          "Wednesday: 10:30 AM – 2:30 PM, 5:30 – 10:00 PM",
          "Thursday: 10:30 AM – 2:30 PM, 5:30 – 10:00 PM",
          "Friday: 10:30 AM – 2:30 PM, 5:30 – 10:00 PM",
          "Saturday: 10:30 AM – 3:00 PM, 5:30 – 10:00 PM",
          "Sunday: 10:30 AM – 3:00 PM, 5:30 – 10:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://anandabhavanusa.com/",
      first_type: "restaurant",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.007696, 37.380645] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "511 Borregas Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 735-8280",
      name: "Seto Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Tuesday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Wednesday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Thursday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Friday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Saturday: 5:00 – 8:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "meal_delivery",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0258303, 37.3885094] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "548 Lawrence Expy #2, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-8988",
      name: "Merit Vegan Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Tuesday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Wednesday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Thursday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Friday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Saturday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Sunday: 11:00 AM – 3:00 PM, 4:30 – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.meritvegetarian.com/",
      first_type: "meal_takeaway",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9951073, 37.3848818] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "921 E Duane Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 732-3319",
      name: "Barn Thai",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1730" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:00 PM, 5:30 – 7:30 PM",
          "Tuesday: 11:30 AM – 2:00 PM, 5:30 – 7:30 PM",
          "Wednesday: 11:30 AM – 2:00 PM, 5:30 – 7:30 PM",
          "Thursday: 11:30 AM – 2:00 PM, 5:30 – 7:30 PM",
          "Friday: 11:30 AM – 2:00 PM, 5:30 – 7:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "https://www.barnthaisunnyvale.com/",
      first_type: "restaurant",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0038376, 37.3891144] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 Ellis St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(415) 523-8992",
      name: "Specialty's Café & Bakery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 4:00 PM",
          "Tuesday: 7:00 AM – 4:00 PM",
          "Wednesday: 7:00 AM – 4:00 PM",
          "Thursday: 7:00 AM – 4:00 PM",
          "Friday: 7:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://specialtys.com/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0507926, 37.4028743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-2987",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.starbucks.com/store-locator/store/16802/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0303206, 37.388266] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "415 N Mary Ave #102, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-6200",
      name: "Little India Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Tuesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Saturday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Sunday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.littleindiacafeus.com/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0423227, 37.3898184] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "550 Lawrence Expy, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 733-2730",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.5",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/SUNNYVALE/550-LAWRENCE-EXPWY/4324.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-121.995403, 37.3854773] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3051 Tasman Dr, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(650) 254-8330",
      name: "1 Oz Coffee",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 2:00 PM",
          "Tuesday: 9:00 AM – 2:00 PM",
          "Wednesday: 9:00 AM – 2:00 PM",
          "Thursday: 9:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 2:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: 9:00 AM – 2:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://1ozcoffee.com/",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.982848, 37.4046071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5390 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 982-9040",
      name: "Loop Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "2.4",
      website:
        "https://www.loopneighborhood.com/location/great-america-shell/?utm_source=GBP&utm_campaign=5390%20Great%20America%20Parkway&utm_medium=organic",
      first_type: "convenience_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.978386, 37.4100366] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Ellis St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 695-4886",
      name: "Union 82",
      opening_hours: "missing",
      rating: "4.2",
      website: "missing",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0539388, 37.397065399999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "346 W Maude Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 738-1856",
      name: "Bagel Street Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 PM",
          "Tuesday: 5:00 AM – 2:00 PM",
          "Wednesday: 5:00 AM – 2:00 PM",
          "Thursday: 5:00 AM – 2:00 PM",
          "Friday: 5:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 7:00 AM – 2:00 PM",
        ],
      },
      rating: "4.5",
      website: "https://www.bagelstreetcafe.net/",
      first_type: "cafe",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0297233, 37.3890328] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "223 E Maude Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 542-9692",
      name: "Sunnywich Cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 5:00 PM",
          "Tuesday: 11:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 11:00 AM – 5:00 PM",
          "Friday: 11:00 AM – 5:00 PM",
          "Saturday: 11:00 AM – 5:00 PM",
          "Sunday: 11:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://www.facebook.com/sunnywichcafe/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0245064, 37.387903] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4949 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 562-6731",
      name: "La Fontana",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "0930" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "0930" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "0930" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "0930" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "0930" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0700" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 – 9:30 AM, 11:00 AM – 1:30 PM, 4:30 – 9:30 PM",
          "Tuesday: 6:30 – 9:30 AM, 11:00 AM – 1:30 PM, 4:30 – 9:30 PM",
          "Wednesday: 6:30 – 9:30 AM, 11:00 AM – 1:30 PM, 4:30 – 9:30 PM",
          "Thursday: 6:30 – 9:30 AM, 11:00 AM – 1:30 PM, 4:30 – 9:30 PM",
          "Friday: 6:30 – 9:30 AM, 11:00 AM – 1:30 PM",
          "Saturday: 7:00 – 10:00 AM, 11:00 AM – 1:30 PM",
          "Sunday: 7:00 – 10:00 AM, 11:00 AM – 1:30 PM",
        ],
      },
      rating: "4",
      website: "https://hiltonsantaclara.com/dining/",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9766667, 37.4025] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "546 Lawrence Expy #1, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(669) 600-3993",
      name: "Bagel Place Cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 4:00 PM",
          "Tuesday: 6:00 AM – 4:00 PM",
          "Wednesday: 6:00 AM – 4:00 PM",
          "Thursday: 6:00 AM – 4:00 PM",
          "Friday: 6:00 AM – 4:00 PM",
          "Saturday: 6:00 AM – 4:00 PM",
          "Sunday: 6:00 AM – 4:00 PM",
        ],
      },
      rating: "4.5",
      website: "https://bagelsplacecafe.com/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9951865, 37.3846059] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 W Java Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Coffee Manufactory",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 2:00 PM",
          "Tuesday: 6:00 AM – 2:00 PM",
          "Wednesday: 6:00 AM – 2:00 PM",
          "Thursday: 6:00 AM – 2:00 PM",
          "Friday: 6:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.marriott.com/en-us/hotels/sjcva-tetra-hotel-autograph-collection/dining/",
      first_type: "cafe",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0227593, 37.4127559] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "237 Moffett Park Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Megan's Artisan Matcha Cafe & Bakery",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "missing",
      first_type: "cafe",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0191908, 37.4057228] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "677 Tasman Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(669) 264-2094",
      name: "Boba Drive",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 9:00 PM",
          "Tuesday: 12:00 – 9:00 PM",
          "Wednesday: 12:00 – 9:00 PM",
          "Thursday: 12:00 – 9:00 PM",
          "Friday: 12:00 – 9:00 PM",
          "Saturday: 12:00 – 9:00 PM",
          "Sunday: 12:00 – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.bobadrive.shop/",
      first_type: "cafe",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.00866, 37.4031944] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "696 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 830-9970",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 10:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/Sunnyvale/696-N-Mathilda-Ave/37235.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0289637, 37.3934387] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1161 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(669) 264-5986",
      name: "Pastel Tea Lounge",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://linktr.ee/pasteltealounge",
      first_type: "cafe",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9969638, 37.3979147] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1034 Indian Wells Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(844) 718-2848",
      name: "Redwood Place Cafe & Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:00 PM",
          "Tuesday: 8:00 AM – 7:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 8:00 AM – 7:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 8:00 AM – 7:00 PM",
          "Sunday: 8:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0000124, 37.386125899999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "919 Hermosa Ct, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "missing",
      name: "Caffe Macs",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 2:30 PM",
        ],
      },
      rating: "3",
      website: "missing",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.040861, 37.388376400000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6250 America Center Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Gather",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 2:00 PM",
          "Tuesday: 7:00 AM – 2:00 PM",
          "Wednesday: 7:00 AM – 2:00 PM",
          "Thursday: 7:00 AM – 2:00 PM",
          "Friday: 7:00 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://americacenter.aramarkcafe.com/",
      first_type: "cafe",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9778605, 37.419529] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5101 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 200-1234",
      name: "Market Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.1",
      website:
        "https://www.hyatt.com/en-US/hotel/california/hyatt-regency-santa-clara/clara/dining",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9766807, 37.4055129] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3201 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-9607",
      name: "T.J. Maxx & HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9847552, 37.3899085] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-3599",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.walmart.com/store/3123-santa-clara-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9854336, 37.390084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3201 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-9607",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "3.5",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9846901, 37.389877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "815 Stewart Dr, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 991-9090",
      name: "Movement Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://movementgyms.com/sunnyvale/?utm_source=google-my-business&utm_medium=organic&utm_campaign=sunnyvale",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0107559, 37.3842062] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1080 Enterprise Way, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 419-4730",
      name: "Moffett Towers Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://www.moffett-towers-club.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0364532, 37.4067878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1239 Reamwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 744-6334",
      name: "Smash Gyms Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "1958" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Tuesday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Wednesday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Thursday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Friday: 8:00 AM – 12:00 PM, 4:00 – 7:58 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "http://www.smashsunnyvale.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9891052, 37.404541] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1242 Birchwood Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(415) 517-2310",
      name: "Just Breathe Yoga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 7:00 PM",
          "Tuesday: 12:00 – 7:00 PM",
          "Wednesday: 12:00 – 7:00 PM",
          "Thursday: 12:00 – 7:00 PM",
          "Friday: 12:00 – 7:00 PM",
          "Saturday: 9:30 – 10:30 AM",
          "Sunday: 10:00 – 11:00 AM",
        ],
      },
      rating: "4.7",
      website: "http://www.justbreatheyogarivermark.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9921415, 37.4040875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "725 San Aleso Ave #2, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 469-9405",
      name: "Silicon Valley Athletics - Personal Trainer Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 9:00 PM",
          "Tuesday: 5:00 AM – 9:00 PM",
          "Wednesday: 5:00 AM – 9:00 PM",
          "Thursday: 5:00 AM – 9:00 PM",
          "Friday: 5:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 3:00 PM",
          "Sunday: 6:00 AM – 3:00 PM",
        ],
      },
      rating: "5",
      website: "https://siliconvalleyathletics.com/",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0282502, 37.3939843] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1206 Mountain View -Alviso Rd C, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 533-1125",
      name: "XP360 Strength & Conditioning",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Tuesday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Wednesday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Thursday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Friday: 5:30 – 11:00 AM, 4:00 – 6:00 PM",
          "Saturday: 8:00 – 10:30 AM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.xp360fit.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9907128, 37.4103274] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1000 Escalon Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(650) 209-7253",
      name: "Gym | Meadows Apartments",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 11:00 PM",
          "Saturday: 5:00 AM – 11:00 PM",
          "Sunday: 5:00 AM – 11:00 PM",
        ],
      },
      rating: "4.9",
      website: "http://www.the-meadows.com/",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0457083, 37.3878732] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1253 Birchwood Dr Ste C, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 733-0348",
      name: "Sunnyvale Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Tuesday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Wednesday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Thursday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Friday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Saturday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://www.sunnyvalefitness.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.993009, 37.405] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1242 Birchwood Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(650) 605-3670",
      name: "Push Personal Fitness - Sunnyvale Personal Training",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1800" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 5:00 PM, 6:00 – 9:00 PM",
          "Tuesday: 7:00 AM – 2:30 PM, 6:00 – 9:00 PM",
          "Wednesday: 7:00 AM – 5:00 PM, 6:00 – 9:00 PM",
          "Thursday: 6:00 – 9:00 PM",
          "Friday: 7:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: 9:00 AM – 2:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.pushfitnessonline.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9920279, 37.4041736] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "477 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 596-3824",
      name: "ZUMBA Fitness at Gateway!",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1800" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 6:00 – 7:00 PM",
          "Wednesday: Closed",
          "Thursday: 6:00 – 7:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://bonnie123.zumba.com/classes",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0314474, 37.388816899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1249 Crossman Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "CRSM 1249",
      opening_hours: "missing",
      rating: "4.9",
      website: "missing",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0129558, 37.4081953] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1063 Morse Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-8300",
      name: "Apartment Gym & Racquetball Court | Mission Pointe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website: "http://missionpointebywindsor.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0173972, 37.4006493] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "671 Tasman Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 598-7324",
      name: "F45 Training North Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1130" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 8:00 PM",
          "Tuesday: 5:30 AM – 8:00 PM",
          "Wednesday: 5:30 AM – 8:00 PM",
          "Thursday: 5:30 AM – 8:00 PM",
          "Friday: 5:30 AM – 1:00 PM",
          "Saturday: 7:30 – 11:30 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "http://f45training.com/northsunnyvale",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0086291, 37.4033457] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1170 Discovery Wy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 419-2700",
      name: "The Club at Moffett Towers 2",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "http://www.mt2club.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0342114, 37.4084284] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1111 Elko Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 300-2545",
      name: "FG personal Training",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 1:00 PM",
          "Saturday: 7:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website:
        "https://www.thumbtack.com/ca/sunnyvale/personal-trainers/fg-personal-training/service/447208981094580230",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9949169, 37.4068597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1111 Elko Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Art of Play",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "http://www.teamaop.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9949169, 37.4068597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1111 Elko Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Brazilian Jiu - Jitsu & Mma",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9949169, 37.4068597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1242 Birchwood Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 937-1234",
      name: "Desi Physique Personal Training Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "5",
      website: "https://desiphysique.com/sunnyvale.html",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9921415, 37.4040875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "477 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "missing",
      name: "Mountain View Volleyball Club",
      opening_hours: "missing",
      rating: "4.4",
      website: "http://mvvclub.com/",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0318279, 37.3888583] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "477 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 730-7199",
      name: "Sunnyvale PAL Boxing",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1815" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1815" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1815" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1815" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 – 6:00 PM, 6:15 – 8:00 PM",
          "Tuesday: 4:30 – 6:00 PM, 6:15 – 8:00 PM",
          "Wednesday: 4:30 – 6:00 PM, 6:15 – 8:00 PM",
          "Thursday: 4:30 – 6:00 PM, 6:15 – 8:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.sunnyvalepal.com/boxing",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0318866, 37.3888739] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "W. Maude Ave &, N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(925) 838-7070",
      name: "Sunnyvale Square Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://lajollamgt.com/",
      first_type: "shopping_mall",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0298805, 37.3887696] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1123 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 752-0381",
      name: "Dick's Lakewood Corporation",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://dicks-lakewood.jany.io/",
      first_type: "shopping_mall",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9970621, 37.3968577] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1283 Mountain View -Alviso Rd, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-5353",
      name: "GE Healthcare",
      opening_hours: "missing",
      rating: "2.3",
      website: "http://www.gehealthcare.com/",
      first_type: "pharmacy",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9883939, 37.410886700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "830 Stewart Dr, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(702) 706-4460",
      name: "Sprayable Inc.",
      opening_hours: "missing",
      rating: "5",
      website: "http://sprayable.co/",
      first_type: "pharmacy",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0096202, 37.3837026] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-4480",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.2",
      website: "https://www.walmart.com/store/3123-santa-clara-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9851505, 37.3900821] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1287 Hammerwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(650) 206-9343",
      name: "Q-Bit Wellness Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 4:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "https://qbitwellness.com/",
      first_type: "pharmacy",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9907372, 37.4097473] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1283 Mountain View -Alviso Rd Suite A & B, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-5353",
      name: "RLS",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "http://rls.bio/",
      first_type: "pharmacy",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9884213, 37.4110241] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA, USA",
      formatted_phone_number: "missing",
      name: "San Jose",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8852525, 37.33874] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Chesapeake Terrace, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 747-0999",
      name: "Radisson Hotel Sunnyvale - Silicon Valley",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.choicehotels.com/california/sunnyvale/radisson-hotels/cak33?mc=llgoxxpx",
      first_type: "lodging",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0022243, 37.4094159] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "748 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 733-1212",
      name: "Larkspur Landing Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website:
        "https://www.larkspurhotels.com/sunnyvale/?utm_source=google-gbp&utm_medium=organic&utm_campaign=gbp",
      first_type: "lodging",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0280497, 37.3945343] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 735-7800",
      name: "Best Western Silicon Valley Inn",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.05622.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:05622",
      first_type: "lodging",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0292664, 37.3923314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1080 Stewart Dr, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 720-8893",
      name: "Residence Inn Sunnyvale Silicon Valley II",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.marriott.com/en-us/hotels/sfosu-residence-inn-sunnyvale-silicon-valley-ii/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-121.9969813, 37.3853471] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "900 Hamlin Ct, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 745-1515",
      name: "Sonesta ES Suites Sunnyvale",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.sonesta.com/sonesta-es-suites/ca/sunnyvale/sonesta-es-suites-sunnyvale",
      first_type: "lodging",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0294667, 37.4002007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "504 Ross Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-9900",
      name: "Days Inn & Suites by Wyndham Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.3",
      website:
        "https://www.wyndhamhotels.com/days-inn/sunnyvale-california/days-inn-and-suites-sunnyvale/overview?CID=LC:iqv0j1dklijly45:45766&iata=00093796",
      first_type: "lodging",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0283168, 37.4015993] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "775 N Mathilda Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 736-4595",
      name: "Motel 6 Sunnyvale, CA - North",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.7",
      website:
        "https://www.motel6.com/en/home/motels.ca.sunnyvale.9481.html?lid=Local_Milestone_1054&travelAgentNumber=TA001305&corporatePlusNumber=CP792N5W&utm_source=google%20my%20business&utm_medium=listing&utm_campaign=visit%20website",
      first_type: "lodging",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0302662, 37.3959788] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4949 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 330-0001",
      name: "Hilton Santa Clara",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.hilton.com/en/hotels/sjcschf-hilton-santa-clara/?SEO_id=GMB-AMER-HH-SJCSCHF&y_source=1_MTIyMDkxMC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9768768, 37.4026274] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "940 W Weddell Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-3742",
      name: "Comfort Inn Sunnyvale - Silicon Valley",
      opening_hours: "missing",
      rating: "4",
      website:
        "https://www.choicehotels.com/california/sunnyvale/comfort-inn-hotels/caa94?mc=llgoxxpx",
      first_type: "lodging",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0259636, 37.3997268] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "816 W Ahwanee Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 734-4607",
      name: "Vagabond Inn Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.4",
      website: "https://www.vagabondinn.com/vagabond-inn-sunnyvale",
      first_type: "lodging",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0278554, 37.3965664] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1217 Wildwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 738-7473",
      name: "Ramada by Wyndham Sunnyvale/Silicon Valley",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.5",
      website:
        "https://www.wyndhamhotels.com/ramada/sunnyvale-california/ramada-silicon-valley/overview?CID=LC:wmcic5n98gs1g0r:02426&iata=00093796",
      first_type: "lodging",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9923192, 37.3909741] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "590 N Mathilda Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 737-1177",
      name: "Travel Inn Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website: "https://www.travelinnsunnyvale.com/",
      first_type: "lodging",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0296268, 37.3912801] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "815 Stewart Dr, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 991-9090",
      name: "Movement Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://movementgyms.com/sunnyvale/?utm_source=google-my-business&utm_medium=organic&utm_campaign=sunnyvale",
      first_type: "gym",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0107559, 37.3842062] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "450 Persian Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-4554",
      name: "Hindu Temple & Community Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.7",
      website: "http://www.sunnyvale-hindutemple.org/",
      first_type: "hindu_temple",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0141067, 37.4050143] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1220 N Fair Oaks Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 610-1265",
      name: "Kensington Place Apartments",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website:
        "https://prometheusapartments.com/ca/sunnyvale-apartments/kensington-place/",
      first_type: "point_of_interest",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0094988, 37.4045786] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "811 E Arques Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 470-1680",
      name: "Lowe's Home Improvement",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.lowes.com/store/CA-Sunnyvale/2211?cm_mmc=lod-_-c-_-lcl-_-awr-_-yxt-_-go-_-2211-_-na-_-0-_-0&y_source=1_MTE4MzI3Ny03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "hardware_store",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.010564, 37.3821532] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "440 N Wolfe Rd, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 524-1400",
      name: "Plug and Play Tech Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.plugandplaytechcenter.com/",
      first_type: "finance",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0130231, 37.383569] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1177 Kern Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 598-4240",
      name: "Sports Basement Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://shop.sportsbasement.com/",
      first_type: "bicycle_store",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.996806, 37.383054] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Santa Clara, CA, USA",
      formatted_phone_number: "missing",
      name: "Santa Clara",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.santaclaraca.gov/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9552356, 37.3541079] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "484 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 737-1834",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://luckysupermarkets.com/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "grocery_or_supermarket",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0295791, 37.388414299999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1180 N Fair Oaks Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 962-0297",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://groceryoutlet.com/circulars/storeid/320",
      first_type: "grocery_or_supermarket",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0097437, 37.403226] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "933 E Duane Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 962-0477",
      name: "Taj Mahal Fresh Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.tajfresh.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.00345, 37.389282] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "829 Borregas Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 734-9621",
      name: "Guadalajara Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0232606, 37.3970826] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12 N Akron Rd, Mountain View, CA 94035, USA",
      formatted_phone_number: "(669) 265-6850",
      name: "Moffett Field Commissary",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "missing",
      first_type: "grocery_or_supermarket",
      zip_code: "94035",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0568004, 37.413337299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-3599",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.walmart.com/store/3123-santa-clara-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9854336, 37.390084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1151 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "New Wing Yuan Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.99716, 37.3976377] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "259 E Maude Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-1500",
      name: "Mi Ranchito Produce",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0238026, 37.3878606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1820 Barber Ln, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 943-9080",
      name: "Sonesta Silicon Valley",
      opening_hours: "missing",
      rating: "3.9",
      website:
        "https://www.sonesta.com/sonesta-hotels-resorts/ca/milpitas/sonesta-silicon-valley",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9117622, 37.400624] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3955 Freedom Cir, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-6400",
      name: "Birk's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 5:00 – 9:00 PM",
          "Sunday: 5:00 – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.birksrestaurant.com/",
      first_type: "restaurant",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9728546, 37.3855743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "53 Headquarters Dr, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 526-0600",
      name: "Chick-fil-A",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 10:00 PM",
          "Tuesday: 6:30 AM – 10:00 PM",
          "Wednesday: 6:30 AM – 10:00 PM",
          "Thursday: 6:30 AM – 10:00 PM",
          "Friday: 6:30 AM – 10:00 PM",
          "Saturday: 6:30 AM – 10:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website:
        "https://www.chick-fil-a.com/locations/ca/first-street?utm_source=yext&utm_medium=link",
      first_type: "restaurant",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9535188, 37.4157438] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "182 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 719-9811",
      name: "Banana Leaf Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1145" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Saturday: 11:45 AM – 8:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.bananaleaf-usa.com/",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.920126, 37.428123] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "940 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 957-9215",
      name: "Dave & Buster's Milpitas - San Jose",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 12:00 AM",
          "Tuesday: 10:00 AM – 12:00 AM",
          "Wednesday: 10:00 AM – 1:00 AM",
          "Thursday: 10:00 AM – 1:00 AM",
          "Friday: 10:00 AM – 2:00 AM",
          "Saturday: 10:00 AM – 2:00 AM",
          "Sunday: 10:00 AM – 12:00 AM",
        ],
      },
      rating: "4",
      website: "https://www.daveandbusters.com/us/en/about/locations/milpitas",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8979516, 37.417728] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3935 Freedom Cir, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 496-6777",
      name: "Pedro's Restaurant & Cantina",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 12:00 – 10:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.pedrosrestaurants.com/location/pedros-restaurants-santa-clara/",
      first_type: "restaurant",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9708574, 37.3849257] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1010 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 942-7441",
      name: "Cinemark Century Great Mall 20 XD and ScreenX",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.cinemark.com/theatres/ca-milpitas/cinemark-century-great-mall-20-xd-and-screenx?utm_medium=organic&utm_source=gmb&utm_campaign=local_listing_theater&utm_content=GMB_listing&y_source=1_MTc0OTMwMDItNzE1LWxvY2F0aW9uLndlYnNpdGU=",
      first_type: "movie_theater",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8991708, 37.4182504] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "458 Barber Ln, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 435-5500",
      name: "Anjappar Chettinad Indian Restaurant",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 10:00 PM",
          "Tuesday: 11:30 AM – 10:00 PM",
          "Wednesday: 11:30 AM – 10:00 PM",
          "Thursday: 11:30 AM – 10:00 PM",
          "Friday: 11:30 AM – 10:00 PM",
          "Saturday: 11:30 AM – 10:00 PM",
          "Sunday: 11:30 AM – 9:30 PM",
        ],
      },
      rating: "3.7",
      website: "https://anjapparca.com/",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9172361, 37.421238900000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "447 Great Mall Dr #K113, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-0400",
      name: "Wetzel's Pretzels",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3",
      website: "https://www.wetzels.com/",
      first_type: "bakery",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8979868, 37.4157251] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1235 Oakmead Pkwy, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 736-2739",
      name: "Laughing Monk Brewing and Gastropub",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 8:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://sunnyvale.laughingmonkbrewing.com/",
      first_type: "bar",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9924988, 37.387637] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "50 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(800) 786-1000",
      name: "In-N-Out Burger",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 6, time: "0130" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 0, time: "0130" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 1:00 AM",
          "Tuesday: 10:30 AM – 1:00 AM",
          "Wednesday: 10:30 AM – 1:00 AM",
          "Thursday: 10:30 AM – 1:00 AM",
          "Friday: 10:30 AM – 1:30 AM",
          "Saturday: 10:30 AM – 1:30 AM",
          "Sunday: 10:30 AM – 1:00 AM",
        ],
      },
      rating: "4.5",
      website: "https://locations.in-n-out.com/115",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.922394, 37.426289399999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "969 E Caribbean Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 475-0044",
      name: "Twin Creeks Sports Complex",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2330" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2330" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2330" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 11:30 PM",
          "Tuesday: 4:00 – 11:30 PM",
          "Wednesday: 4:00 – 11:30 PM",
          "Thursday: 4:00 PM – 12:00 AM",
          "Friday: 4:00 PM – 12:00 AM",
          "Saturday: 7:00 AM – 12:00 AM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.twin-creeks.com/",
      first_type: "bar",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0003518, 37.4161451] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 Barber Ln, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 943-1110",
      name: "Bistro",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "0930" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "0930" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "0930" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "0930" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "0930" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 – 9:30 AM, 5:00 – 10:00 PM",
          "Tuesday: 6:30 – 9:30 AM, 5:00 – 10:00 PM",
          "Wednesday: 6:30 – 9:30 AM, 5:00 – 10:00 PM",
          "Thursday: 6:30 – 9:30 AM, 5:00 – 10:00 PM",
          "Friday: 6:30 – 9:30 AM, 5:00 – 10:00 PM",
          "Saturday: 7:00 – 10:00 AM, 5:00 – 10:00 PM",
          "Sunday: 7:00 – 10:00 AM",
        ],
      },
      rating: "3.5",
      website:
        "http://www.starwoodhotels.com/sheraton/property/dining/attraction_detail.html?propertyID=670&attractionId=14700",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9110397, 37.4026491] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "174 W Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-2327",
      name: "Black Bear Diner Milpitas",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://blackbeardiner.com/location/milpitas/",
      first_type: "restaurant",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9096642, 37.4285359] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "127 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-9810",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:30 PM",
          "Tuesday: 5:00 AM – 7:30 PM",
          "Wednesday: 5:00 AM – 7:30 PM",
          "Thursday: 5:00 AM – 7:30 PM",
          "Friday: 5:00 AM – 7:30 PM",
          "Saturday: 6:00 AM – 7:30 PM",
          "Sunday: 6:00 AM – 7:30 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.starbucks.com/store-locator/store/9209/",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.920945, 37.428371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1135 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-2828",
      name: "Saigon Seafood Harbor",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Tuesday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Saturday: 10:00 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Sunday: 10:00 AM – 2:30 PM, 5:00 – 9:00 PM",
        ],
      },
      rating: "4",
      website: "http://saigonharbors.com/",
      first_type: "restaurant",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9970277, 37.3972859] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3001 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(800) 786-1000",
      name: "In-N-Out Burger",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 6, time: "0130" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 0, time: "0130" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 1:00 AM",
          "Tuesday: 10:30 AM – 1:00 AM",
          "Wednesday: 10:30 AM – 1:00 AM",
          "Thursday: 10:30 AM – 1:00 AM",
          "Friday: 10:30 AM – 1:30 AM",
          "Saturday: 10:30 AM – 1:30 AM",
          "Sunday: 10:30 AM – 1:00 AM",
        ],
      },
      rating: "4.5",
      website: "https://locations.in-n-out.com/176",
      first_type: "restaurant",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9820575, 37.3880553] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "110 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 935-9875",
      name: "Romano's Macaroni Grill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.macaronigrill.com/locations/location?id=1770",
      first_type: "bar",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.920594, 37.427328] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3906 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-0630",
      name: "Red Robin Gourmet Burgers and Brews",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://locations.redrobin.com/ca/santa-clara/102/?utm_campaign=GBP&utm_source=Digital&utm_medium=GMB&utm_content=Rivermark&utm_term=GBPRivermark",
      first_type: "restaurant",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9456965, 37.3940732] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3113 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 727-1419",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:30 PM",
          "Tuesday: 6:00 AM – 7:30 PM",
          "Wednesday: 6:00 AM – 7:30 PM",
          "Thursday: 6:00 AM – 7:30 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 7:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/11569/",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9833074, 37.3893922] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "127 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-9810",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:30 PM",
          "Tuesday: 5:00 AM – 7:30 PM",
          "Wednesday: 5:00 AM – 7:30 PM",
          "Thursday: 5:00 AM – 7:30 PM",
          "Friday: 5:00 AM – 7:30 PM",
          "Saturday: 6:00 AM – 7:30 PM",
          "Sunday: 6:00 AM – 7:30 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.starbucks.com/store-locator/store/9209/",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.920945, 37.428371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3113 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 727-1419",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:30 PM",
          "Tuesday: 6:00 AM – 7:30 PM",
          "Wednesday: 6:00 AM – 7:30 PM",
          "Thursday: 6:00 AM – 7:30 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 7:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/11569/",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9833074, 37.3893922] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3932 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 213-0810",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0630" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 5:00 PM",
          "Tuesday: 5:30 AM – 5:00 PM",
          "Wednesday: 5:30 AM – 5:00 PM",
          "Thursday: 5:30 AM – 5:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:30 AM – 6:00 PM",
          "Sunday: 6:30 AM – 6:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Santa%20Clara,%20CA,%20USA&selected=Santa%20Clara",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.945995, 37.39441] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2030 Wyatt Dr #110, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 588-4070",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/12670/",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.960228, 37.3875043] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1545 California Cir, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-0447",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0330" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0330" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0330" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0330" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0330" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 3:30 AM – 8:00 PM",
          "Tuesday: 3:30 AM – 8:00 PM",
          "Wednesday: 3:30 AM – 8:00 PM",
          "Thursday: 3:30 AM – 8:00 PM",
          "Friday: 3:30 AM – 8:00 PM",
          "Saturday: 4:30 AM – 7:30 PM",
          "Sunday: 4:30 AM – 7:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/11230/",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.92143, 37.45166] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3133 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 986-8824",
      name: "Krispy Kreme",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://site.krispykreme.com/ca/santa-clara/3133-mission-college-blvd?y_source=1_MTE4OTc5OTItNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9839187, 37.3885294] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "The Marketplace, 2579 N First St, San Jose, CA 95131, USA",
      formatted_phone_number: "(408) 428-9887",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 6:00 PM",
          "Tuesday: 4:00 AM – 6:00 PM",
          "Wednesday: 4:00 AM – 6:00 PM",
          "Thursday: 4:00 AM – 6:00 PM",
          "Friday: 4:00 AM – 6:00 PM",
          "Saturday: 6:30 AM – 2:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "https://www.starbucks.com/store-locator/store/12056/",
      first_type: "cafe",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9279339, 37.3853315] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "670 River Oaks Pkwy G, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 577-0542",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0630" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 6:00 PM",
          "Tuesday: 5:00 AM – 6:00 PM",
          "Wednesday: 5:00 AM – 6:00 PM",
          "Thursday: 5:00 AM – 6:00 PM",
          "Friday: 5:00 AM – 6:00 PM",
          "Saturday: 6:30 AM – 5:00 PM",
          "Sunday: 6:30 AM – 4:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/12834/",
      first_type: "cafe",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9237938, 37.3981843] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "543 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 416-0700",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 5:00 PM",
          "Tuesday: 5:30 AM – 5:00 PM",
          "Wednesday: 5:30 AM – 5:00 PM",
          "Thursday: 5:30 AM – 5:00 PM",
          "Friday: 5:30 AM – 5:00 PM",
          "Saturday: 6:00 AM – 5:00 PM",
          "Sunday: 6:00 AM – 5:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Milpitas,%20CA,%20USA&selected=Milpitas",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.898106, 37.434545] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "99 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 263-0181",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/MILPITAS/99-N-MILPITAS-BLVD/6053.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9013599, 37.4344581] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3970 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-0980",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "3.3",
      website: "https://www.starbucks.com/store-locator/store/7500",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.947655, 37.394775] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "120 W Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 586-8061",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0330" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0330" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0330" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0330" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0330" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 3:30 AM – 9:30 PM",
          "Tuesday: 3:30 AM – 9:30 PM",
          "Wednesday: 3:30 AM – 9:30 PM",
          "Thursday: 3:30 AM – 9:30 PM",
          "Friday: 3:30 AM – 9:30 PM",
          "Saturday: 4:30 AM – 10:00 PM",
          "Sunday: 4:30 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/116312/",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.909308, 37.429231] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "528 Barber Ln, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 382-9168",
      name: "Fantasia Coffee & Tea",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://order.online/business/fantasia-coffee--tea-110227",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.916504, 37.420841] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 262-9855",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "3.4",
      website: "https://www.starbucks.com/store-locator/store/17933",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8979071, 37.4348145] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2850 Augustine Dr, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 727-9726",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/SANTA-CLARA/2850-AUGUSTINE-DR/5461.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9788991, 37.3818168] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "550 Lawrence Expy, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 733-2730",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.5",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/SUNNYVALE/550-LAWRENCE-EXPWY/4324.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-121.995403, 37.3854773] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4180 N First St, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 943-3193",
      name: "Panera Bread",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.panerabread.com/en-us/cafe/locations/ca/san-jose/4180-n-1st-street?utm_medium=local&utm_source=google&utm_campaign=dpm-dist&utm_term=204481&utm_content=main",
      first_type: "cafe",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9551609, 37.4164723] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "181 E Tasman Dr #80, San Jose, CA 95134, USA",
      formatted_phone_number: "(669) 284-9959",
      name: "Caffe Bene",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 9:00 AM – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://caffebenesanjose.com/",
      first_type: "cafe",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.937611, 37.41277] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2209 Tasman Dr, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-8803",
      name: "Mikayla's Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 2:00 PM",
          "Tuesday: 7:00 AM – 2:00 PM",
          "Wednesday: Closed",
          "Thursday: 7:00 AM – 2:00 PM",
          "Friday: 7:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://mikaylascafe.com/",
      first_type: "restaurant",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9633637, 37.4082445] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "82 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 941-9330",
      name: "Tea Top Milpitas",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 8:00 PM",
          "Tuesday: 11:30 AM – 8:00 PM",
          "Wednesday: 11:30 AM – 8:00 PM",
          "Thursday: 11:30 AM – 8:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.teatopusa.com/",
      first_type: "cafe",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9214078, 37.42642] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "301 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-0304",
      name: "Walmart Supercenter",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/2119-milpitas-ca",
      first_type: "department_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.921167, 37.4313384] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 834-1527",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.target.com/sl/san-jose-north/2581",
      first_type: "department_store",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3201 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-9607",
      name: "T.J. Maxx & HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9847552, 37.3899085] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1150 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 262-4055",
      name: "Kohl's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 10:00 PM",
          "Tuesday: 9:00 AM – 10:00 PM",
          "Wednesday: 9:00 AM – 10:00 PM",
          "Thursday: 9:00 AM – 10:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.kohls.com/stores/ca/milpitas-994.shtml?utm_source=google&utm_medium=organic&utm_campaign=local",
      first_type: "department_store",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8992693, 37.416777599999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1360 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-1821",
      name: "Marshalls",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.marshalls.com/",
      first_type: "department_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8953884, 37.413704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1400 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-0454",
      name: "Burlington",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://stores.burlington.com/ca/milpitas/224/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "clothing_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8955054, 37.4150193] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "239 Ranch Dr Space 8, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-1580",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2230" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 10:00 PM",
          "Tuesday: 10:00 AM – 10:00 PM",
          "Wednesday: 10:00 AM – 10:00 PM",
          "Thursday: 10:00 AM – 10:00 PM",
          "Friday: 10:00 AM – 10:30 PM",
          "Saturday: 10:00 AM – 10:30 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9220377, 37.4297971] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-3599",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.walmart.com/store/3123-santa-clara-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9854336, 37.390084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1330 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 945-9650",
      name: "Saks OFF 5TH",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.saksoff5th.com/",
      first_type: "store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.896025, 37.413383] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3201 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-9607",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "3.5",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9846901, 37.389877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "525 Los Coches St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-1130",
      name: "India Community Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "4",
      website: "http://www.indiacc.org/",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8950362, 37.4319272] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1900 Duane Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-4494",
      name: "American Barbell Clubs",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.americanbarbellclubs.com/",
      first_type: "gym",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.959164, 37.379918] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 Norman Ave STE 201, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 893-4466",
      name: "Sagar Hot Yoga Santa Clara",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:30 PM",
          "Tuesday: 6:00 AM – 9:30 PM",
          "Wednesday: 6:00 AM – 9:30 PM",
          "Thursday: 6:00 AM – 9:30 PM",
          "Friday: 6:00 AM – 9:30 PM",
          "Saturday: 6:00 AM – 9:30 PM",
          "Sunday: 6:00 AM – 9:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://sagarhotyoga.com/",
      first_type: "gym",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9561712, 37.3846515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "46650 Landing Pkwy, Fremont, CA 94538, USA",
      formatted_phone_number: "(510) 226-8500",
      name: "Bay Club Fremont",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.bayclubs.com/fremont/?utm_source=Chatmeter&utm_medium=Google&utm_campaign=ClubPage&utm_content=FremontCH",
      first_type: "gym",
      zip_code: "46650",
    },
    geometry: { type: "Point", coordinates: [-121.940697, 37.483559] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1239 Reamwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 744-6334",
      name: "Smash Gyms Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "1958" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Tuesday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Wednesday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Thursday: 8:00 AM – 12:00 PM, 2:00 – 8:00 PM",
          "Friday: 8:00 AM – 12:00 PM, 4:00 – 7:58 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "http://www.smashsunnyvale.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9891052, 37.404541] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3475 Woodward Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 844-8485",
      name: "Academy of Self Defense",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1130" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "0730" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0845" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 1:00 PM, 4:00 – 8:30 PM",
          "Tuesday: 6:30 AM – 1:00 PM, 4:00 – 8:30 PM",
          "Wednesday: 6:30 AM – 1:00 PM, 4:00 – 8:30 PM",
          "Thursday: 6:30 AM – 1:00 PM, 4:00 – 8:30 PM",
          "Friday: 6:30 – 7:30 AM, 10:00 AM – 1:00 PM, 5:00 – 6:00 PM",
          "Saturday: 8:45 AM – 12:00 PM",
          "Sunday: 10:00 – 11:30 AM",
        ],
      },
      rating: "4.7",
      website: "https://academyselfdefense.com/",
      first_type: "gym",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9451473, 37.3847821] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1325 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 586-3225",
      name: "Milpitas Sports Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website:
        "https://www.milpitas.gov/Facilities/Facility/Details/Milpitas-Sports-Center-75",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.883123, 37.4380666] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1242 Birchwood Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(415) 517-2310",
      name: "Just Breathe Yoga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 7:00 PM",
          "Tuesday: 12:00 – 7:00 PM",
          "Wednesday: 12:00 – 7:00 PM",
          "Thursday: 12:00 – 7:00 PM",
          "Friday: 12:00 – 7:00 PM",
          "Saturday: 9:30 – 10:30 AM",
          "Sunday: 10:00 – 11:00 AM",
        ],
      },
      rating: "4.7",
      website: "http://www.justbreatheyogarivermark.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9921415, 37.4040875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2354 Calle Del Mundo, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 537-3957",
      name: "Relentless Boxing & Training Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1830" },
          },
          {
            close: { day: 2, time: "1610" },
            open: { day: 2, time: "1530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1830" },
          },
          {
            close: { day: 4, time: "1610" },
            open: { day: 4, time: "1530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1830" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 6:00 PM, 6:30 – 7:30 PM",
          "Tuesday: 3:30 – 4:10 PM, 5:00 – 6:00 PM, 6:30 – 7:30 PM",
          "Wednesday: Closed",
          "Thursday: 3:30 – 4:10 PM, 5:00 – 6:00 PM, 6:30 – 7:30 PM",
          "Friday: Closed",
          "Saturday: 10:00 – 11:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "https://relentless-boxing.com/",
      first_type: "gym",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9673542, 37.40893] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "55 River Oaks Pl Suite #10, San Jose, CA 95134, USA",
      formatted_phone_number: "(833) 448-2561",
      name: "CorePower Yoga - Golden Triangle",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.corepoweryoga.com/yoga-studios/ca/south-bay/golden-triangle",
      first_type: "gym",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9403749, 37.4018735] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1206 Mountain View -Alviso Rd C, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 533-1125",
      name: "XP360 Strength & Conditioning",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Tuesday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Wednesday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Thursday: 5:30 – 11:00 AM, 4:00 – 7:30 PM",
          "Friday: 5:30 – 11:00 AM, 4:00 – 6:00 PM",
          "Saturday: 8:00 – 10:30 AM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.xp360fit.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9907128, 37.4103274] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1200 S Abel St 2nd Floor, Suite 1272, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 883-0095",
      name: "Bikram Yoga Milpitas",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0515" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0515" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0515" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0515" },
          },
          {
            close: { day: 5, time: "2015" },
            open: { day: 5, time: "0515" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 5:15 AM – 8:30 PM",
          "Tuesday: 5:15 AM – 8:30 PM",
          "Wednesday: 5:15 AM – 8:30 PM",
          "Thursday: 5:15 AM – 8:30 PM",
          "Friday: 5:15 AM – 8:15 PM",
          "Saturday: 9:30 AM – 6:30 PM",
          "Sunday: 9:30 AM – 6:30 PM",
        ],
      },
      rating: "4.6",
      website: "http://bikramyoga-milpitas.com/",
      first_type: "physiotherapist",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9033313, 37.4129126] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1268 S Abel St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 768-7342",
      name: "Super Spin Table Tennis Club",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 9:00 PM",
          "Tuesday: 5:00 – 9:00 PM",
          "Wednesday: 5:00 – 9:00 PM",
          "Thursday: 5:00 – 9:00 PM",
          "Friday: 5:00 – 9:00 PM",
          "Saturday: 12:00 – 6:00 PM",
          "Sunday: 12:00 – 6:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.superspintt.com/",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9030447, 37.4125593] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "105 Lonetree Ct, Milpitas, CA 95035, USA",
      formatted_phone_number: "(669) 200-8555",
      name: "VKool Magazine",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://vkool.com/",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9045999, 37.4092258] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1000 Jacklin Rd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 483-9833",
      name: "Smash Gyms Milpitas",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 7:00 PM",
          "Tuesday: 4:30 – 7:00 PM",
          "Wednesday: 4:00 – 7:00 PM",
          "Thursday: 4:30 – 7:00 PM",
          "Friday: 12:00 – 1:00 PM",
          "Saturday: 9:30 – 11:00 AM",
          "Sunday: 10:00 – 11:00 AM",
        ],
      },
      rating: "4.6",
      website: "http://smashmilpitas.com/",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8927462, 37.4461735] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2712 Augustine Dr Suite #210, Santa Clara, CA 95050, USA",
      formatted_phone_number: "(833) 448-2561",
      name: "CorePower Yoga - Santa Clara Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.corepoweryoga.com/yoga-studios/ca/south-bay/santa-clara-square",
      first_type: "gym",
      zip_code: "95050",
    },
    geometry: { type: "Point", coordinates: [-121.9750215, 37.3816345] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1120 Cadillac Ct, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 719-1612",
      name: "Apex Pilates Studio",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 7:30 PM",
          "Tuesday: 8:00 AM – 7:30 PM",
          "Wednesday: 8:00 AM – 8:30 PM",
          "Thursday: Closed",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:00 AM – 12:00 PM",
          "Sunday: 7:00 AM – 12:00 PM",
        ],
      },
      rating: "4",
      website: "http://www.apexpilates.com/",
      first_type: "gym",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.917848, 37.443959199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "513 Los Coches St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 634-3978",
      name: "Nithyanandeshwara Hindu Temple",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "0030" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 0, time: "0030" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 – 9:00 PM",
          "Tuesday: 5:30 – 9:00 PM",
          "Wednesday: 5:30 – 9:00 PM",
          "Thursday: 5:30 – 9:00 PM",
          "Friday: 5:30 – 9:00 PM",
          "Saturday: 8:00 AM – 12:30 AM",
          "Sunday: 8:00 AM – 12:30 AM",
        ],
      },
      rating: "4.6",
      website: "http://nithyanandeshwarahindutemplebayarea.org/",
      first_type: "hindu_temple",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.896531, 37.4313016] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1253 Birchwood Dr Ste C, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 733-0348",
      name: "Sunnyvale Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Tuesday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Wednesday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Thursday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Friday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Saturday: 5:00 – 11:00 AM, 3:00 – 8:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://www.sunnyvalefitness.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.993009, 37.405] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1242 Birchwood Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(650) 605-3670",
      name: "Push Personal Fitness - Sunnyvale Personal Training",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1800" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 5:00 PM, 6:00 – 9:00 PM",
          "Tuesday: 7:00 AM – 2:30 PM, 6:00 – 9:00 PM",
          "Wednesday: 7:00 AM – 5:00 PM, 6:00 – 9:00 PM",
          "Thursday: 6:00 – 9:00 PM",
          "Friday: 7:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: 9:00 AM – 2:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.pushfitnessonline.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9920279, 37.4041736] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "447 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 956-2033",
      name: "Great Mall",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.simon.com/mall/great-mall",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.897412, 37.415738] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3905-3986 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(510) 770-9798",
      name: "Rivermark Village",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.rivermarkvillage.com/",
      first_type: "shopping_mall",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9455503, 37.3943632] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "670 River Oaks Pkwy, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 982-8474",
      name: "River Oaks Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://sheaproperties.com/property/river-oaks-plaza/",
      first_type: "shopping_mall",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9239452, 37.3981693] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "506 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 514-0025",
      name: "The Great Mall Milpitas Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.simon.com/mall/great-mall",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8969393, 37.4175675] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "20 S Abbott Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Milpitas Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.913042, 37.4276589] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "375 Jacklin Rd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 477-2505",
      name: "Foothill Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://shoppingmilpitas.com/foothill-square/",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9030619, 37.4473286] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2804 Mission College Blvd #240, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 987-4119",
      name: "Almaden Ranch",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.almadenranchsanjose.com/web/contact.html",
      first_type: "shopping_mall",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9779891, 37.3912932] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "538 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-5975",
      name: "Sunglass Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://stores.sunglasshut.com/us/ca/milpitas/538-great-mall-dr?cid=YEXT_5138&y_source=1_MTE0MzM2OS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8968571, 37.4159522] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1513, 4130 N First St, San Jose, CA 95134, USA",
      formatted_phone_number: "missing",
      name: "Shop@First",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:30 PM",
          "Tuesday: 9:00 AM – 9:30 PM",
          "Wednesday: 9:00 AM – 9:30 PM",
          "Thursday: 9:00 AM – 9:30 PM",
          "Friday: 9:00 AM – 9:30 PM",
          "Saturday: 9:00 AM – 9:30 PM",
          "Sunday: 9:00 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.shopatfirst.com/",
      first_type: "shopping_mall",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9537739, 37.4163072] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "McCarthy Ranch Marketplace",
      opening_hours: "missing",
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9201816, 37.428493] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "231 W Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Calaveras Plaza Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9117662, 37.4287217] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "S Abel St, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Abel Plaza",
      opening_hours: "missing",
      rating: "3.9",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9080194, 37.4292179] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1123 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 752-0381",
      name: "Dick's Lakewood Corporation",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://dicks-lakewood.jany.io/",
      first_type: "shopping_mall",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9970621, 37.3968577] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4633-4699 Old Ironsides Dr, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 727-0588",
      name: "Orchard Commercial",
      opening_hours: "missing",
      rating: "missing",
      website: "https://parkway4lease.com/contact-us",
      first_type: "shopping_mall",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9789654, 37.3965228] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1783 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Crescent Square",
      opening_hours: "missing",
      rating: "4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9118725, 37.4555712] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "City Square Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1730" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1730" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Sunday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.910612, 37.4562648] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "327 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(800) 762-1641",
      name: "Milpitas Town Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://shoppingmilpitas.com/milpitas-town-center/",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8970921, 37.4348265] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "447 Great Mall Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Tesla Supercharger",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website: "https://www.tesla.com/supercharger",
      first_type: "shopping_mall",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.898362, 37.413524] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3159 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "missing",
      name: "Mercado",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 10:00 PM",
          "Tuesday: 8:30 AM – 10:00 PM",
          "Wednesday: 8:30 AM – 10:00 PM",
          "Thursday: 8:30 AM – 10:00 PM",
          "Friday: 8:30 AM – 10:00 PM",
          "Saturday: 8:30 AM – 10:00 PM",
          "Sunday: 8:30 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9841184, 37.388987] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "N First St, San Jose, CA 95131, USA",
      formatted_phone_number: "missing",
      name: "The Market Place Mall",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.9274287, 37.3849759] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 834-1528",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: Closed",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.cvs.com/store-locator/san-jose-ca-pharmacies/95-holger-way-san-jose-ca-95134/storeid=17582?WT.mc_id=LS_GOOGLE_RX_17582",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "301 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 934-0204",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "3",
      website: "https://www.walmart.com/store/2119-milpitas-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9211774, 37.431568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3571 N First St #201, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 876-6191",
      name: "Tony Lau, PharmD (for Cisco employees only)",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 7:00 PM",
          "Tuesday: 8:30 AM – 7:00 PM",
          "Wednesday: 8:30 AM – 7:00 PM",
          "Thursday: 8:30 AM – 7:00 PM",
          "Friday: 8:30 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "3.5",
      website: "https://www.ciscolifeconnections.com/pharmacy/",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.944584, 37.407282] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3571 N First St Suite 201, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 876-6191",
      name: "Veena Toledo, Pharm (for Cisco employees only)",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 7:00 PM",
          "Tuesday: 8:30 AM – 7:00 PM",
          "Wednesday: 8:30 AM – 7:00 PM",
          "Thursday: 8:30 AM – 7:00 PM",
          "Friday: 8:30 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.ciscolifeconnections.com/pharmacy/",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9451092, 37.4071921] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3970 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-0985",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.2",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/santa-clara/3970-rivermark-plaza.html",
      first_type: "pharmacy",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9476283, 37.3947642] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "342 W Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 263-3963",
      name: "Walgreens Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "2.3",
      website:
        "https://www.walgreens.com/locator/walgreens-342+w+calaveras+blvd-milpitas-ca-95035/id=3671",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9115369, 37.4263733] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1283 Mountain View -Alviso Rd, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 734-5353",
      name: "GE Healthcare",
      opening_hours: "missing",
      rating: "2.3",
      website: "http://www.gehealthcare.com/",
      first_type: "pharmacy",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9883939, 37.410886700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "48006 Fremont Blvd, Fremont, CA 94538, USA",
      formatted_phone_number: "(510) 498-8222",
      name: "Central Admixture Pharmacy Services",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      rating: "5",
      website: "https://www.capspharmacy.com/",
      first_type: "pharmacy",
      zip_code: "48006",
    },
    geometry: { type: "Point", coordinates: [-121.9371215, 37.4705018] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "45 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-6424",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.cvs.com/store-locator/milpitas-ca-pharmacies/45-n-milpitas-blvd-milpitas-ca-95035/storeid=9161?WT.mc_id=LS_GOOGLE_RX_9161",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9017932, 37.433653799999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "611 S Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 945-2900",
      name: "Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1715" },
            open: { day: 1, time: "0915" },
          },
          {
            close: { day: 2, time: "1715" },
            open: { day: 2, time: "0915" },
          },
          {
            close: { day: 3, time: "1715" },
            open: { day: 3, time: "0915" },
          },
          {
            close: { day: 4, time: "1715" },
            open: { day: 4, time: "0915" },
          },
          {
            close: { day: 5, time: "1715" },
            open: { day: 5, time: "0915" },
          },
        ],
        weekday_text: [
          "Monday: 9:15 AM – 5:15 PM",
          "Tuesday: 9:15 AM – 5:15 PM",
          "Wednesday: 9:15 AM – 5:15 PM",
          "Thursday: 9:15 AM – 5:15 PM",
          "Friday: 9:15 AM – 5:15 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.4",
      website: "https://healthy.kaiserpermanente.org/html/kaiser/index.shtml",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8950158, 37.4244598] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-4480",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.2",
      website: "https://www.walmart.com/store/3123-santa-clara-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9851505, 37.3900821] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "611 S Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 945-2900",
      name: "Kaiser Permanente Medical",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4",
      website:
        "https://healthy.kaiserpermanente.org/northern-california/facilities/milpitas-medical-offices-100353",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8949669, 37.4248349] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "114 S Park Victoria Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 262-2056",
      name: "Calaveras Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1530" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 6:30 PM",
          "Tuesday: 9:30 AM – 6:30 PM",
          "Wednesday: 9:30 AM – 6:30 PM",
          "Thursday: 9:30 AM – 6:30 PM",
          "Friday: 9:30 AM – 6:30 PM",
          "Saturday: 9:30 AM – 3:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "http://calaverasrx.com/",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8832528, 37.4341092] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "555 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 262-9855",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.3",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/milpitas/555-e-calaveras-blvd.html",
      first_type: "pharmacy",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.898068, 37.4351375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "TARGET PHARMACY, 95 Holger Way STORE T2581, San Jose, CA 951341377, USA",
      formatted_phone_number: "(408) 834-1528",
      name: "Grishma Patel",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way T-2581, San Jose, CA 951341377, USA",
      formatted_phone_number: "(408) 834-1528",
      name: "Andrea Siao",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way TARGET-2581, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 834-1527",
      name: "Hong Tran",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4160 North First Street, San Jose, CA, 95134-1513, San Jose, CA 95134, USA",
      formatted_phone_number: "(970) 567-8541",
      name: "Nature's Rx",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "missing",
      website: "https://www.naturesrx.global/",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9545276, 37.417371499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4110 N First St, San Jose, CA 951341513, USA",
      formatted_phone_number: "(408) 434-1839",
      name: "Brianna Willard",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9539768, 37.415771] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4110 N First St, San Jose, CA 951341513, USA",
      formatted_phone_number: "(408) 434-1839",
      name: "Kevin Chen",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9539768, 37.415771] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Jose, CA, USA",
      formatted_phone_number: "missing",
      name: "San Jose",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8852525, 37.33874] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "30 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 719-1313",
      name: "Hilton Garden Inn San Jose/Milpitas",
      opening_hours: "missing",
      rating: "4",
      website:
        "https://www.hilton.com/en/hotels/sjcmhgi-hilton-garden-inn-san-jose-milpitas/?SEO_id=GMB-AMER-GI-SJCMHGI&y_source=1_MjA4MzUyNi03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9231443, 37.4250274] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "40 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 719-1212",
      name: "Larkspur Landing Milpitas",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.8",
      website:
        "https://www.larkspurhotels.com/milpitas/?utm_source=google-gbp&utm_medium=organic&utm_campaign=gbp",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9218536, 37.4250849] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4949 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 330-0001",
      name: "Hilton Santa Clara",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.hilton.com/en/hotels/sjcschf-hilton-santa-clara/?SEO_id=GMB-AMER-HH-SJCSCHF&y_source=1_MTIyMDkxMC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9768768, 37.4026274] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2131 Gold St, Alviso, CA 95002, USA",
      formatted_phone_number: "(408) 262-0401",
      name: "Extended Stay America - San Jose - Santa Clara",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.8",
      website:
        "https://www.extendedstayamerica.com/hotels/ca/san-jose/santa-clara?channel=gmb-listing&utm_source=google&utm_medium=organic&utm_campaign=gmb_listing",
      first_type: "lodging",
      zip_code: "95002",
    },
    geometry: { type: "Point", coordinates: [-121.9756984, 37.4195888] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "270 S Abbott Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 883-3487",
      name: "Days Inn by Wyndham San Jose Airport",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.8",
      website:
        "https://www.wyndhamhotels.com/days-inn/milpitas-california/days-inn-by-wyndham-san-jose-airport/overview?CID=LC:iqv0j1dklijly45:15578&iata=00093796",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9111757, 37.4260548] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1501 California Cir, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 941-9222",
      name: "Residence Inn Milpitas Silicon Valley",
      opening_hours: "missing",
      rating: "4.5",
      website:
        "https://www.marriott.com/en-us/hotels/sjcml-residence-inn-milpitas-silicon-valley/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9206047, 37.4504597] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "321 Cypress Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 383-9500",
      name: "Staybridge Suites Silicon Valley-Milpitas, an IHG Hotel",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.ihg.com/staybridge/hotels/us/en//milpitas/sfocd/hoteldetail",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.92211, 37.422358] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Valley Way, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 263-5566",
      name: "Best Western Plus Brookside Inn",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.05538.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:05538",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.914672, 37.4268] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "63 Ranch Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 942-0201",
      name: "Best Buy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://stores.bestbuy.com/ca/milpitas/63-ranch-dr-142.html/?ref=NS&loc=ns100",
      first_type: "electronics_store",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9231572, 37.4279297] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5101 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 200-1234",
      name: "Hyatt Regency Santa Clara",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.hyatt.com/hyatt-regency/en-US/clara-hyatt-regency-santa-clara/?src=corp_lclb_gmb_seo_clara",
      first_type: "lodging",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9769213, 37.4051338] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4900 Marie P DeBartolo Way, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(415) 464-9377",
      name: "Levi's Stadium",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website: "http://levisstadium.com/",
      first_type: "stadium",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9693774, 37.4033165] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "330 Cypress Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 433-9700",
      name: "Extended Stay America - San Jose - Milpitas - McCarthy Ranch",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.7",
      website:
        "https://www.extendedstayamerica.com/hotels/ca/san-jose/milpitas-mccarthy-ranch?channel=gmb-listing&utm_source=google&utm_medium=organic&utm_campaign=gmb_listing",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9209704, 37.4225084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "215 Barber Ct, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 428-9090",
      name: "Hampton Inn Milpitas",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.hilton.com/en/hotels/sfomphx-hampton-milpitas/?SEO_id=GMB-AMER-HX-SFOMPHX&y_source=1_MjA4MzA2Ny03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9197598, 37.423525] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "485 S Main St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-8383",
      name: "Americas Best Value Inn Milpitas Silicon Valley",
      opening_hours: "missing",
      rating: "3.1",
      website:
        "https://www.sonesta.com/americas-best-value-inn/ca/milpitas/americas-best-value-inn-milpitas-silicon-valley",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.905641, 37.4234952] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 834-1528",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: Closed",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.cvs.com/store-locator/san-jose-ca-pharmacies/95-holger-way-san-jose-ca-95134/storeid=17582?WT.mc_id=LS_GOOGLE_RX_17582",
      first_type: "pharmacy",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "378 W Calaveras Blvd #5253, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 649-3620",
      name: "Heritage Inn Milpitas",
      opening_hours: "missing",
      rating: "2.6",
      website: "https://www.heritageinnca.com/",
      first_type: "lodging",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9123691, 37.4261084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4343 Renaissance Dr, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 317-4291",
      name: "Enclave - CA Apartments",
      opening_hours: "missing",
      rating: "3.4",
      website: "https://www.enclaveapartmenthomes.com/",
      first_type: "real_estate_agency",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9623638, 37.4137214] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4701 Great America Pkwy, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-1776",
      name: "California's Great America",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 10:00 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.cagreatamerica.com/",
      first_type: "amusement_park",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9742877, 37.3979214] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Fremont, CA, USA",
      formatted_phone_number: "missing",
      name: "Fremont",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.988583, 37.5485396] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3970 Rivermark Plaza, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-0980",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/santa-clara/3970-rivermark-plaza.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.947634, 37.394745] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "555 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 262-9850",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/milpitas/555-e-calaveras-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8980612, 37.4350391] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 S Abbott Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 493-6786",
      name: "Namaste Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 12:00 AM",
          "Tuesday: 9:00 AM – 12:00 AM",
          "Wednesday: 9:00 AM – 12:00 AM",
          "Thursday: 9:00 AM – 12:00 AM",
          "Friday: 9:00 AM – 12:00 AM",
          "Saturday: 9:00 AM – 12:00 AM",
          "Sunday: 9:00 AM – 12:00 AM",
        ],
      },
      rating: "4",
      website: "http://www.namasteplaza.net/",
      first_type: "supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9133056, 37.4283155] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2 S Park Victoria Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 942-3388",
      name: "Ocean Supermarket",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "3.9",
      website: "missing",
      first_type: "supermarket",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8832717, 37.435161199999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Milpitas Square, 338 Barber Ln, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-8899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://www.99ranch.com/",
      first_type: "supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.91695, 37.42286] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "401 Jacklin Rd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 941-9642",
      name: "Smart & Final Extra!",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.smartandfinal.com/store",
      first_type: "grocery_or_supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9024178, 37.4477897] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3255 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 961-3599",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.walmart.com/store/3123-santa-clara-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9854336, 37.390084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "215 W Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 586-8205",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://groceryoutlet.com/circulars/storeid/196",
      first_type: "grocery_or_supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9115385, 37.4286044] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1180 N Fair Oaks Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 962-0297",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://groceryoutlet.com/circulars/storeid/320",
      first_type: "grocery_or_supermarket",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0097437, 37.403226] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2732 Augustine Dr Ste 1600, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 562-9162",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.wholefoodsmarket.com/stores/santaclara?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9759063, 37.3810157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "41 Rio Robles E, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 944-9103",
      name: "The Market @ North Park, Inc.",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:30 PM",
          "Tuesday: 8:00 AM – 9:30 PM",
          "Wednesday: 8:00 AM – 9:30 PM",
          "Thursday: 8:00 AM – 9:30 PM",
          "Friday: 8:00 AM – 9:30 PM",
          "Saturday: 8:00 AM – 9:30 PM",
          "Sunday: 8:00 AM – 8:30 PM",
        ],
      },
      rating: "3.5",
      website: "missing",
      first_type: "grocery_or_supermarket",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.941539, 37.406603] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3730 N First St #100, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 435-8424",
      name: "First Mart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 8:00 PM",
        ],
      },
      rating: "3.9",
      website: "missing",
      first_type: "convenience_store",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9461853, 37.410973] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 263-1288",
      name: "Marina Grocery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://m.facebook.com/pages/Marina-Grocery/158563550823655",
      first_type: "grocery_or_supermarket",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9015177, 37.433104699999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1269 State St, Alviso, CA 95002, USA",
      formatted_phone_number: "(408) 722-6919",
      name: "Marina Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "3.8",
      website: "missing",
      first_type: "convenience_store",
      zip_code: "95002",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9733333, 37.427777799999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "95 Holger Way, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 834-1527",
      name: "Target Grocery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.target.com/c/order-pickup/-/N-ng0a0?aisles_cid=5xt1a%2CGrocery%2C%2Fc%2Fgrocery%2F-%2FN-5xt1a",
      first_type: "grocery_or_supermarket",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9561986, 37.4181795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1151 Lawrence Expy, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "New Wing Yuan Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.99716, 37.3976377] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1306 Great Mall Pkwy, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 942-6957",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.7",
      website:
        "https://locations.traderjoes.com/ca/milpitas/229/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8997978, 37.4125543] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1261 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 618-8238",
      name: "Coconut Hill Indian Grocery Milpitas",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:30 PM",
          "Tuesday: 9:00 AM – 9:30 PM",
          "Wednesday: 9:00 AM – 9:30 PM",
          "Thursday: 9:00 AM – 9:30 PM",
          "Friday: 9:00 AM – 9:30 PM",
          "Saturday: 9:00 AM – 9:30 PM",
          "Sunday: 9:00 AM – 9:30 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.coconuthill.com/",
      first_type: "supermarket",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8853592, 37.4360621] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2104 N Capitol Ave, San Jose, CA 95132, USA",
      formatted_phone_number: "(408) 942-8650",
      name: "ampm",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "1.9",
      website: "https://www.ampm.com/",
      first_type: "convenience_store",
      zip_code: "95132",
    },
    geometry: { type: "Point", coordinates: [-121.887857, 37.407474] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "891 Island Dr Suite E, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-7711",
      name: "La Val's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 8:30 PM",
          "Saturday: 11:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.lavalspizza.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2408004, 37.737593] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "891-B Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 814-0560",
      name: "La Penca Azul",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.lapencaazul.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2407649, 37.7377259] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1411 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 769-5441",
      name: "Oscar's Grill",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 2:00 PM",
          "Tuesday: 7:00 AM – 2:00 PM",
          "Wednesday: 7:00 AM – 2:00 PM",
          "Thursday: 7:00 AM – 2:00 PM",
          "Friday: 7:00 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.9",
      website: "https://www.oscarsgrillalameda.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2366478, 37.7266069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1750 N Loop Rd STE 125, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 864-0108",
      name: "Why Cook? Cafe & Catering",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 2:00 PM",
          "Tuesday: 7:30 AM – 2:00 PM",
          "Wednesday: 7:30 AM – 2:00 PM",
          "Thursday: 7:30 AM – 2:00 PM",
          "Friday: 7:30 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.9",
      website: "http://whycookalameda.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2409556, 37.72734] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Clubhouse Memorial Rd, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 337-9950",
      name: "Jim's On the Course",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://jimsonthecourse.com/",
      first_type: "bar",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2332483, 37.7389609] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883 Island Dr C-2, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 749-0460",
      name: "Angel Fish Japanese Cuisine",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:00 AM – 2:00 PM, 4:30 – 7:30 PM",
          "Wednesday: 11:30 AM – 2:00 PM, 4:30 – 7:30 PM",
          "Thursday: 11:30 AM – 2:00 PM, 4:30 – 7:30 PM",
          "Friday: 11:30 AM – 2:00 PM, 4:30 – 7:30 PM",
          "Saturday: 11:30 AM – 2:00 PM, 4:30 – 7:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "https://www.angelfishalameda.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2403223, 37.7375311] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "891-A Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-1911",
      name: "China Villa Restaurant",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 8:30 PM",
          "Tuesday: 11:30 AM – 8:30 PM",
          "Wednesday: 11:30 AM – 8:30 PM",
          "Thursday: 11:30 AM – 8:30 PM",
          "Friday: 11:30 AM – 8:30 PM",
          "Saturday: 11:30 AM – 8:30 PM",
          "Sunday: 11:30 AM – 8:30 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://smorefood.com/order-online/china-villa-restaurant-alameda-94502-cra8yhd1?utm_source=gmb&utm_medium=website",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2407981, 37.7378756] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "109 Maitland Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 522-7400",
      name: "Maitland Market Cafe & Deli",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "store",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2354812, 37.7357067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "980 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(341) 388-0002",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 8:30 PM",
          "Tuesday: 4:30 AM – 8:30 PM",
          "Wednesday: 4:30 AM – 8:30 PM",
          "Thursday: 4:30 AM – 8:30 PM",
          "Friday: 4:30 AM – 8:30 PM",
          "Saturday: 5:30 AM – 8:30 PM",
          "Sunday: 5:30 AM – 8:30 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.starbucks.ca/store-locator/store/1031742/island-mecartney-980-island-dr-alameda-ca-94502-us",
      first_type: "cafe",
      zip_code: "94502",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.239135, 37.735948799999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1310 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 263-8201",
      name: "Freckles Deli & Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.frecklesdelialameda.com/",
      first_type: "restaurant",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2364905, 37.7253911] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883 Island Dr # B2, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 217-8292",
      name: "Coffee & Tea Traders",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 3:30 PM",
          "Tuesday: 7:00 AM – 3:30 PM",
          "Wednesday: 7:00 AM – 3:30 PM",
          "Thursday: 7:00 AM – 3:30 PM",
          "Friday: 7:00 AM – 3:30 PM",
          "Saturday: 7:00 AM – 2:00 PM",
          "Sunday: 10:00 AM – 1:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://coffee-tea-traders.square.site/",
      first_type: "cafe",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2403168, 37.7375816] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2708 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(650) 465-9128",
      name: "Café 1888",
      opening_hours: "missing",
      rating: "missing",
      website: "http://cafeone888.com/",
      first_type: "cafe",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2532308, 37.7341773] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "980 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(341) 388-0002",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 8:30 PM",
          "Tuesday: 4:30 AM – 8:30 PM",
          "Wednesday: 4:30 AM – 8:30 PM",
          "Thursday: 4:30 AM – 8:30 PM",
          "Friday: 4:30 AM – 8:30 PM",
          "Saturday: 5:30 AM – 8:30 PM",
          "Sunday: 5:30 AM – 8:30 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.starbucks.ca/store-locator/store/1031742/island-mecartney-980-island-dr-alameda-ca-94502-us",
      first_type: "cafe",
      zip_code: "94502",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.239135, 37.735948799999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1501 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 589-1186",
      name: "Cafe Jolie Petit",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: 9:00 AM – 2:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.cafejolies.com/",
      first_type: "cafe",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2392628, 37.726007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883D Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 864-2030",
      name: "Anytime Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.3",
      website:
        "https://www.anytimefitness.com/gyms/152/alameda-ca-94502/?utm_source=google&utm_medium=organic-businesslisting&utm_campaign=gbp-listing&utm_content=152",
      first_type: "gym",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2404705, 37.7374325] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1320 Harbor Bay Pkwy #104, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 864-9339",
      name: "Bespoke Wellness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://bespokewellness.com/",
      first_type: "spa",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2361054, 37.7239671] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2259 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 523-4769",
      name: "Concrete Body",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.concretebody.com/",
      first_type: "gym",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2480316, 37.730648] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2213 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 523-1900",
      name: "ProBalance Physical Therapy & Pilates Studio",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 9:00 AM – 2:00 PM",
          "Sunday: 8:00 AM – 12:00 PM",
        ],
      },
      rating: "5",
      website: "http://probalancept.com/",
      first_type: "gym",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2476562, 37.7300238] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1501 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "missing",
      name: "Fitness Court at Harbor Bay",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "5",
      website: "https://www.nationalfitnesscampaign.com/app",
      first_type: "gym",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2392628, 37.726007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883 Island Dr # 213, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 522-8866",
      name: "Harbor Bay Landing",
      opening_hours: "missing",
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2397613, 37.7376861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 747-1647",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/alameda/867-island-dr.html",
      first_type: "pharmacy",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.239544, 37.7379176] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2183 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-1830",
      name: "Pharmaceutical Services Corporation",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2480315, 37.7289884] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2173 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 864-4199",
      name: "MIDTOWN PHARMACY",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94502",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2470999, 37.728875099999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Island Dr, Alameda, CA 945026751, USA",
      formatted_phone_number: "(510) 747-1647",
      name: "Brian Fong",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.2395305, 37.7379402] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Oakland, CA, USA",
      formatted_phone_number: "missing",
      name: "Oakland",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.oaklandnet.com/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.2711639, 37.8043514] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1700 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-4500",
      name: "Hampton Inn & Suites Oakland Airport-Alameda",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.hilton.com/en/hotels/oakamhx-hampton-suites-oakland-airport-alameda/?SEO_id=GMB-AMER-HX-OAKAMHX&y_source=1_MjA4MjYyMC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.242502, 37.7245372] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Clubhouse Memorial Rd, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 747-7800",
      name: "Corica Park Golf Course",
      opening_hours: "missing",
      rating: "4.2",
      website: "http://coricapark.com/",
      first_type: "health",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2332077, 37.7389907] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "883D Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 864-2030",
      name: "Anytime Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.3",
      website:
        "https://www.anytimefitness.com/gyms/152/alameda-ca-94502/?utm_source=google&utm_medium=organic-businesslisting&utm_campaign=gbp-listing&utm_content=152",
      first_type: "gym",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2404705, 37.7374325] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-4833",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.safeway.com/safeway/ca/alameda/867-island-dr.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2394204, 37.7379044] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Highway 61, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 747-7529",
      name: "Shoreline Park",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:30 PM",
          "Tuesday: 6:00 AM – 8:30 PM",
          "Wednesday: 6:00 AM – 8:30 PM",
          "Thursday: 6:00 AM – 8:30 PM",
          "Friday: 6:00 AM – 8:30 PM",
          "Saturday: 6:00 AM – 8:30 PM",
          "Sunday: 6:00 AM – 8:30 PM",
        ],
      },
      rating: "4.7",
      website: "https://alamedaca.gov/recreation/shoreline-park",
      first_type: "park",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2577644, 37.7382887] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1650 Harbor Bay Pkwy Suite 150, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 263-2300",
      name: "Intelligrated Systems",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 5:00 PM",
          "Tuesday: 7:00 AM – 5:00 PM",
          "Wednesday: 7:00 AM – 5:00 PM",
          "Thursday: 7:00 AM – 5:00 PM",
          "Friday: 7:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "http://intelligrated.com/",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2407006, 37.7240568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "801 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-4300",
      name: "Waters Edge Lodge",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "5",
      website: "https://www.aecliving.com/the-lodge/",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2389307, 37.7384786] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "875 Island Dr A, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 522-1234",
      name: "The UPS Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 6:30 PM",
          "Tuesday: 8:30 AM – 6:30 PM",
          "Wednesday: 8:30 AM – 6:30 PM",
          "Thursday: 8:30 AM – 6:30 PM",
          "Friday: 8:30 AM – 6:30 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "3.8",
      website:
        "https://locations.theupsstore.com/ca/alameda/875-island-dr?utm_source=Yext&utm_medium=organic&utm_campaign=Listings",
      first_type: "finance",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.240111, 37.7381223] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 Aughinbaugh Way, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-4010",
      name: "Bay Farm School K-8",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0810" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0810" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0810" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0810" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0810" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:10 AM – 4:00 PM",
          "Tuesday: 8:10 AM – 4:00 PM",
          "Wednesday: 8:10 AM – 4:00 PM",
          "Thursday: 8:10 AM – 4:00 PM",
          "Friday: 8:10 AM – 4:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "https://bayfarm.alamedaunified.org/",
      first_type: "primary_school",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2519223, 37.7441211] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2253 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(650) 341-3310",
      name: "Synactive, Inc. Pvt Ltd",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 6, time: "0000" },
            open: { day: 1, time: "0000" },
          },
        ],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "http://www.liquid-ui.com/",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2479483, 37.7305748] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2221 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 288-9710",
      name: "Kelly Lux - State Farm Insurance Agent",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: 10:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.kellylux.com/?cmpid=gjcg_blm_0001",
      first_type: "insurance_agency",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.247962, 37.73058] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1851 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(650) 837-7000",
      name: "Exelixis Inc",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://www.exelixis.com/",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2437549, 37.7261828] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "891 Island Dr Suite E, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-7711",
      name: "La Val's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 8:30 PM",
          "Saturday: 11:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.lavalspizza.com/",
      first_type: "meal_delivery",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2408004, 37.737593] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-1870",
      name: "Stacy and Witbeck, Inc.",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.stacywitbeck.com/",
      first_type: "general_contractor",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2536397, 37.7341295] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1351 Harbor Bay Pkwy, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-3200",
      name: "Penumbra Inc",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website: "http://www.penumbrainc.com/",
      first_type: "point_of_interest",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2357861, 37.7280495] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(866) 733-2693",
      name: "Redbox",
      opening_hours: "missing",
      rating: "missing",
      website:
        "https://www.redbox.com/locations/california/alameda/94502/64090",
      first_type: "movie_rental",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2395305, 37.7379402] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1420 Harbor Bay Pkwy # 280, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-0122",
      name: "US Flight Standards District Office",
      opening_hours: "missing",
      rating: "3.4",
      website: "http://www.faa.gov",
      first_type: "local_government_office",
      zip_code: "94502",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2375594, 37.723920199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3189 Mecartney Rd, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 769-2002",
      name: "Bay Farm Church",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1130" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: 10:00 – 11:30 AM",
        ],
      },
      rating: "4.6",
      website: "http://www.bayfarmchurch.org/",
      first_type: "church",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2417451, 37.7380367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Alameda, CA, USA",
      formatted_phone_number: "missing",
      name: "Alameda",
      opening_hours: "missing",
      rating: "missing",
      website: "http://ci.alameda.ca.us/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.2821855, 37.7798721] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "867 Island Dr, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 521-4833",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.safeway.com/safeway/ca/alameda/867-island-dr.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2394204, 37.7379044] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "396 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2959",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "3.2",
      website:
        "https://restaurants.subway.com/united-states/ca/moraga/396-park-street?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=15583&utm_campaign=evergreen-2020&y_source=1_MTQ4OTc2NTEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1253533, 37.860019400000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "504 Center St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-6453",
      name: "Mountain Mike's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:30 PM",
          "Tuesday: 10:00 AM – 9:30 PM",
          "Wednesday: 10:00 AM – 9:30 PM",
          "Thursday: 10:00 AM – 9:30 PM",
          "Friday: 10:00 AM – 10:00 PM",
          "Saturday: 10:00 AM – 10:00 PM",
          "Sunday: 10:00 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.mountainmikespizza.com/locations/moraga-center/",
      first_type: "meal_delivery",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.125832, 37.85822] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1375 Moraga Way, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4300",
      name: "Michael's Ristorante Italiano",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: 4:00 – 9:00 PM",
          "Saturday: 4:00 – 9:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.penninis.com/michael-s/michael-s-menu",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1282194, 37.8359719] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "361 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-1411",
      name: "Round Table Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://ordering.roundtablepizza.com/site/moraga-rheem-blvd",
      first_type: "meal_delivery",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1262299, 37.861286799999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "581 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-0284",
      name: "Golden Palace Restaurant",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2115" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 2, time: "2115" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2115" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2115" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2115" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:00 AM – 9:15 PM",
          "Wednesday: 11:00 AM – 9:15 PM",
          "Thursday: 11:00 AM – 9:15 PM",
          "Friday: 11:00 AM – 9:15 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 12:00 – 9:15 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://smorefood.com/order-online/golden-palace-moraga-94556-d6s4xihz?utm_source=gmb&utm_medium=website",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1245079, 37.8556775] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-8888",
      name: "Nation’s Giant Hamburgers",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://nationsrestaurants.com/",
      first_type: "meal_takeaway",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1253022, 37.8600241] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1012 School St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-5127",
      name: "Ranch House Cafe Moraga",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 2:00 PM",
          "Sunday: 8:00 AM – 2:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1296326, 37.8363005] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1419 Moraga Way, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4444",
      name: "La Finestra Ristorante",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 8:00 PM",
          "Wednesday: 11:30 AM – 8:00 PM",
          "Thursday: 11:30 AM – 8:00 PM",
          "Friday: 11:30 AM – 8:30 PM",
          "Saturday: 4:30 – 8:30 PM",
          "Sunday: 11:30 AM – 8:30 PM",
        ],
      },
      rating: "4.5",
      website: "http://lafinestraristorante.com/",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.12769, 37.8355155] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1375 Moraga Way D, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-1515",
      name: "Pennini's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 8:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 12:00 – 9:00 PM",
          "Sunday: 12:00 – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.penninis.com/",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1280342, 37.8360241] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "382 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4040",
      name: "Lamorinda Pizza",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 4:00 – 8:00 PM",
          "Wednesday: 4:00 – 8:00 PM",
          "Thursday: 4:00 – 8:00 PM",
          "Friday: 4:00 – 8:00 PM",
          "Saturday: 4:00 – 8:00 PM",
          "Sunday: 4:00 – 8:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://lamorindapizza.com/",
      first_type: "meal_delivery",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1257577, 37.8600124] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "343 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-1740",
      name: "Chef Chao Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:00 AM – 2:00 PM, 4:00 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:00 PM, 4:00 – 9:00 PM",
          "Thursday: 11:00 AM – 2:00 PM, 4:00 – 9:00 PM",
          "Friday: 11:00 AM – 2:00 PM, 4:00 – 9:00 PM",
          "Saturday: 11:00 AM – 2:00 PM, 4:00 – 9:00 PM",
          "Sunday: 3:00 – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "https://www.chefchaorestaurant.com/",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1273681, 37.8614804] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "360 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-7662",
      name: "Ristorante Amoroma",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 4:00 – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://amoroma1.com",
      first_type: "liquor_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1265685, 37.8604213] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1375 Moraga Way B, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-1203",
      name: "Mucho Wraps",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 4:00 PM",
          "Tuesday: 10:00 AM – 4:00 PM",
          "Wednesday: 10:00 AM – 4:00 PM",
          "Thursday: 10:00 AM – 4:00 PM",
          "Friday: 10:00 AM – 4:00 PM",
          "Saturday: 10:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "https://muchowraps.squarespace.com/",
      first_type: "meal_takeaway",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.128148, 37.8359049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "380 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-1828",
      name: "China Moon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: Closed",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1258435, 37.8600212] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "470 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-0523",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.starbucks.com/store-locator/store/16113/",
      first_type: "cafe",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1244491, 37.8587147] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "410 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 247-5651",
      name: "Taco Bell",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 12:00 AM",
          "Tuesday: 7:00 AM – 12:00 AM",
          "Wednesday: 7:00 AM – 12:00 AM",
          "Thursday: 7:00 AM – 1:00 AM",
          "Friday: 7:00 AM – 1:00 AM",
          "Saturday: 8:00 AM – 1:00 AM",
          "Sunday: 8:00 AM – 12:00 AM",
        ],
      },
      rating: "3.6",
      website:
        "https://locations.tacobell.com/ca/moraga/410-moraga-rd.html?utm_source=yext&utm_campaign=googlelistings&utm_medium=referral&utm_term=031448&utm_content=website&y_source=1_NjE0MzcxMC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "meal_takeaway",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1244323, 37.8595492] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "512 Center St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-0420",
      name: "Royal Siam",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Tuesday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Wednesday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Thursday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Friday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Saturday: 11:30 AM – 3:00 PM, 4:30 – 9:30 PM",
          "Sunday: 4:30 – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.royalsiammoraga.com/",
      first_type: "restaurant",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1258108, 37.8581025] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1928 Saint Mary's Road, Moraga, CA 94575, USA",
      formatted_phone_number: "missing",
      name: "Global Gael Food Truck",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1000" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "1000" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "1000" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "1000" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "1000" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 – 10:00 AM, 11:30 AM – 3:00 PM",
          "Tuesday: 8:30 – 10:00 AM, 11:30 AM – 3:00 PM",
          "Wednesday: 8:30 – 10:00 AM, 11:30 AM – 3:00 PM",
          "Thursday: 8:30 – 10:00 AM, 11:30 AM – 3:00 PM",
          "Friday: 8:30 – 10:00 AM, 11:30 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "restaurant",
      zip_code: "94575",
    },
    geometry: { type: "Point", coordinates: [-122.1103506, 37.8413512] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1928 Saint Mary's Road, Moraga, CA 94575, USA",
      formatted_phone_number: "(925) 631-4378",
      name: "The 1928 Pub",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 5:00 – 10:00 PM",
          "Thursday: 5:00 – 10:00 PM",
          "Friday: 5:00 – 10:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "bar",
      zip_code: "94575",
    },
    geometry: { type: "Point", coordinates: [-122.1089284, 37.8406385] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1558 Canyon Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2337",
      name: "Canyon Club Brewery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 9:00 PM",
          "Tuesday: 4:00 – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.canyonclub.works/",
      first_type: "bar",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1285303, 37.8334739] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1928 Saint Mary's Rd, Moraga, CA 94575, USA",
      formatted_phone_number: "(925) 631-4378",
      name: "Café Louis",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 8:00 PM",
          "Tuesday: 7:30 AM – 8:00 PM",
          "Wednesday: 7:30 AM – 8:00 PM",
          "Thursday: 7:30 AM – 8:00 PM",
          "Friday: 7:30 AM – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "cafe",
      zip_code: "94575",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1092002, 37.840288199999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1355 Moraga Way, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-9492",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.starbucks.com/store-locator/store/8332",
      first_type: "cafe",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.128485, 37.8362999] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "470 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-0523",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.starbucks.com/store-locator/store/16113/",
      first_type: "cafe",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1244491, 37.8587147] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1460 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-0842",
      name: "iTea",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1230" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1230" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1230" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1230" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1230" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1230" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 12:30 – 8:00 PM",
          "Wednesday: 12:30 – 8:00 PM",
          "Thursday: 12:30 – 8:00 PM",
          "Friday: 12:30 – 8:00 PM",
          "Saturday: 12:30 – 8:00 PM",
          "Sunday: 12:30 – 8:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.itea-usa.com/",
      first_type: "cafe",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1266062, 37.83604] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "910 Country Club Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-1908",
      name: "Si Si Caffe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 4:00 PM",
          "Tuesday: 6:30 AM – 4:00 PM",
          "Wednesday: 6:30 AM – 4:00 PM",
          "Thursday: 6:30 AM – 4:00 PM",
          "Friday: 6:30 AM – 4:00 PM",
          "Saturday: 7:00 AM – 4:00 PM",
          "Sunday: 7:00 AM – 4:00 PM",
        ],
      },
      rating: "4.6",
      website: "missing",
      first_type: "cafe",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.131223, 37.8339373] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 Center St, Moraga, CA 94570, USA",
      formatted_phone_number: "(510) 326-6249",
      name: "vesselsart tea set wholesale",
      opening_hours: "missing",
      rating: "missing",
      website: "https://www.vesselsartware.com/copy-of-about",
      first_type: "cafe",
      zip_code: "94570",
    },
    geometry: { type: "Point", coordinates: [-122.1256794, 37.859379] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "590 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-1150",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264046, 37.856081] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "472 Center St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-0213",
      name: "T.J. Maxx",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1257045, 37.8588696] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "351 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-2400",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.24hourfitness.com/gyms/moraga-ca/moraga-rheem-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264747, 37.8613217] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "594 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2363",
      name: "Luna Gymnastics",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 8:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "3.8",
      website: "http://www.lunagymnastics.com/index.html",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1252186, 37.8558024] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "455 Moraga Rd suite c, Moraga, CA 94556, USA",
      formatted_phone_number: "(415) 420-8041",
      name: "Star Pilates and Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1130" },
            open: { day: 0, time: "0730" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1130" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 7:30 PM",
          "Tuesday: 7:30 AM – 7:30 PM",
          "Wednesday: 7:30 AM – 7:30 PM",
          "Thursday: 7:30 AM – 7:30 PM",
          "Friday: 7:30 AM – 7:30 PM",
          "Saturday: 7:30 – 11:30 AM",
          "Sunday: 7:30 – 11:30 AM",
        ],
      },
      rating: "missing",
      website: "http://www.starpilatesandfitness.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1236372, 37.8587044] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1460-A Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 408-1322",
      name: "Moraga Bootcamp",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 – 11:00 AM",
          "Tuesday: 6:00 – 11:00 AM",
          "Wednesday: 6:00 – 11:00 AM",
          "Thursday: 6:00 – 11:00 AM",
          "Friday: 6:00 – 11:00 AM",
          "Saturday: 8:00 – 10:00 AM",
          "Sunday: 8:00 – 10:00 AM",
        ],
      },
      rating: "5",
      website: "https://www.moragabootcamp.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1266564, 37.8362784] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1460 Moraga Rd Suite H, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 247-5164",
      name: "Bay Area Ballplayers",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 4:30 PM",
          "Tuesday: 10:00 AM – 4:30 PM",
          "Wednesday: 10:00 AM – 4:30 PM",
          "Thursday: 10:00 AM – 4:30 PM",
          "Friday: 10:00 AM – 3:00 PM",
          "Saturday: 10:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://bayareaballplayers.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1268773, 37.8360637] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "920 Country Club Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 596-5317",
      name: "Higher Ground Moraga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0945" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "1715" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 6:30 AM – 9:00 PM",
          "Wednesday: 8:30 AM – 7:00 PM",
          "Thursday: 6:30 AM – 9:00 PM",
          "Friday: 9:00 AM – 5:15 PM",
          "Saturday: Closed",
          "Sunday: 9:45 AM – 12:00 PM",
        ],
      },
      rating: "5",
      website: "https://www.highergroundmoraga.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1309928, 37.8338438] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1640 School St #104, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 788-4044",
      name: "Shape Shifters Pilates & Health",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 8:00 AM – 6:30 PM",
          "Wednesday: 8:00 AM – 6:30 PM",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 9:30 AM – 5:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.shapeshifterspilatesandhealth.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1323425, 37.8338645] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "535 Center St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 388-0883",
      name: "F45 Training Moraga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "5",
      website: "https://f45training.com/moraga",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.125147, 37.857678099999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12 Harold Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(510) 853-9144",
      name: "Nia Janet Kaufman",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: 10:00 – 11:00 AM",
          "Saturday: Closed",
          "Sunday: 10:00 – 11:00 AM",
        ],
      },
      rating: "missing",
      website: "http://www.niajanetkaufman.com/",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1401642, 37.8636694] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1600 Riviera Avenue, Walnut Creek, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Movement Integration Nikki Chrysostomou",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1328081, 37.8690904] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(916) 580-2527",
      name: "Rheem Valley Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1249411, 37.85741] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Moraga Shopping Center, 1450 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Moraga Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 7:00 PM",
          "Tuesday: 9:30 AM – 7:00 PM",
          "Wednesday: 9:30 AM – 7:00 PM",
          "Thursday: 9:30 AM – 7:00 PM",
          "Friday: 9:30 AM – 7:00 PM",
          "Saturday: 9:30 AM – 7:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1277102, 37.835856] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-0204",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.cvs.com/store-locator/moraga-ca-pharmacies/580-moraga-rd-moraga-ca-94556/storeid=9203?WT.mc_id=LS_GOOGLE_RX_9203",
      first_type: "pharmacy",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1260581, 37.856633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "346 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 247-5602",
      name: "Rheem Specialty Pharmacy",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1294141, 37.8612862] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Walnut Creek, CA, USA",
      formatted_phone_number: "missing",
      name: "Walnut Creek",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.walnut-creek.org/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0651819, 37.9100783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1928 St Marys Rd, Moraga, CA 94575, USA",
      formatted_phone_number: "(925) 631-4000",
      name: "Saint Mary's College of California",
      opening_hours: "missing",
      rating: "4.5",
      website: "http://www.stmarys-ca.edu/",
      first_type: "university",
      zip_code: "94575",
    },
    geometry: { type: "Point", coordinates: [-122.1089374, 37.8408837] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "300 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 280-3950",
      name: "Campolindo High School",
      opening_hours: "missing",
      rating: "4.6",
      website: "https://www.acalanes.k12.ca.us/campolindo",
      first_type: "secondary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1261766, 37.8672417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Helen Ln, Lafayette, CA 94549, USA",
      formatted_phone_number: "(559) 916-1687",
      name: "Heather Scharf Photography",
      opening_hours: "missing",
      rating: "5",
      website: "http://heatherscharfphotography.com/",
      first_type: "point_of_interest",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1024793, 37.8660531] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Moraga Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 280-3930",
      name: "Miramonte High School",
      opening_hours: "missing",
      rating: "4.5",
      website: "http://www.acalanes.k12.ca.us/miramonte",
      first_type: "secondary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1455424, 37.8406792] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7867 Redwood Rd, Oakland, CA 94619, USA",
      formatted_phone_number: "(888) 327-2757",
      name: "Reinhardt Redwood Regional Park",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 10:00 PM",
        ],
      },
      rating: "4.8",
      website: "http://www.ebparks.org/parks/redwood",
      first_type: "park",
      zip_code: "94619",
    },
    geometry: { type: "Point", coordinates: [-122.1629595, 37.8115064] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1600 St Andrews Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2200",
      name: "Moraga Country Club",
      opening_hours: "missing",
      rating: "4.4",
      website: "http://www.moragacc.com/",
      first_type: "point_of_interest",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1372052, 37.8360568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1480 Moraga Rd Suite C, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4480",
      name: "The UPS Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1530" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:30 PM",
          "Tuesday: 8:00 AM – 6:30 PM",
          "Wednesday: 8:00 AM – 6:30 PM",
          "Thursday: 8:00 AM – 6:30 PM",
          "Friday: 8:00 AM – 6:30 PM",
          "Saturday: 10:00 AM – 3:30 PM",
          "Sunday: 10:00 AM – 3:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://locations.theupsstore.com/ca/moraga/1480-moraga-rd?utm_source=Yext&utm_medium=organic&utm_campaign=Listings",
      first_type: "finance",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.127527, 37.8357379] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1100 Moraga Way # 113, Moraga, CA 94556, USA",
      formatted_phone_number: "(510) 694-2244",
      name: "Katy Anderson + Katie Krembs Team",
      opening_hours: "missing",
      rating: "5",
      website: "http://www.katyandkatie.com/",
      first_type: "real_estate_agency",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.134761, 37.8367137] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "590 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-1150",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264046, 37.856081] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "561 Merriewood Dr, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 927-3550",
      name: "Burton Valley Elementary School",
      opening_hours: "missing",
      rating: "4.3",
      website: "http://www.lafsd.k12.ca.us/",
      first_type: "primary_school",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.0934236, 37.8612691] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1010 Camino Pablo, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-7206",
      name: "Joaquin Moraga Intermediate School",
      opening_hours: "missing",
      rating: "4.4",
      website: "http://jm.moraga.k12.ca.us/",
      first_type: "school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1307295, 37.8253061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "396 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2959",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "3.2",
      website:
        "https://restaurants.subway.com/united-states/ca/moraga/396-park-street?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=15583&utm_campaign=evergreen-2020&y_source=1_MTQ4OTc2NTEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1253533, 37.860019400000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1425 St Marys Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-2520",
      name: "Moraga Commons Park",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.7",
      website: "https://www.moraga.ca.us/249/Moraga-Commons-Park",
      first_type: "park",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1257424, 37.8409136] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1678 School St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-7900",
      name: "The Saklan School",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "http://www.saklan.org/",
      first_type: "primary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1330739, 37.8330293] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "351 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-2400",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.24hourfitness.com/gyms/moraga-ca/moraga-rheem-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264747, 37.8613217] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Park St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 247-5426",
      name: "Rheem Theatre",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 8:00 PM",
          "Tuesday: 12:00 – 8:00 PM",
          "Wednesday: 12:00 – 8:00 PM",
          "Thursday: 12:00 – 8:00 PM",
          "Friday: 12:00 – 9:00 PM",
          "Saturday: 12:00 – 9:00 PM",
          "Sunday: 12:00 – 8:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://www.cinemawest.com/locations/rheem-theatre",
      first_type: "movie_theater",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1268943, 37.8604305] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 Moraga Valley Ln, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4800",
      name: "Moraga Valley Presbyterian Church",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "4.7",
      website: "http://www.mvpctoday.org/",
      first_type: "church",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.136759, 37.840153] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "187 Pinehurst Rd, Canyon, CA 94516, USA",
      formatted_phone_number: "(925) 376-4671",
      name: "Canyon Elementary School",
      opening_hours: "missing",
      rating: "4.4",
      website: "https://canyon.k12.ca.us/",
      first_type: "primary_school",
      zip_code: "94516",
    },
    geometry: { type: "Point", coordinates: [-122.1635041, 37.8276031] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Lafayette, CA, USA",
      formatted_phone_number: "missing",
      name: "Lafayette",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.ci.lafayette.ca.us/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.1180201, 37.8857582] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1355 Moraga Way, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-9492",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "3.9",
      website:
        "https://local.safeway.com/safeway/ca/moraga/1355-moraga-way.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1285358, 37.8362968] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4261 El Camino Real, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 493-2844",
      name: "Dinah's Garden Hotel",
      opening_hours: "missing",
      rating: "4.5",
      website: "http://www.dinahshotel.com/",
      first_type: "lodging",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1203004, 37.407464] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 N Shoreline Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 961-3828",
      name: "Cinemark Century Mountain View 16",
      opening_hours: "missing",
      rating: "4.6",
      website:
        "https://www.cinemark.com/theatres/ca-mountain-view/cinemark-century-mountain-view-16?utm_medium=organic&utm_source=gmb&utm_campaign=local_listing_theater&utm_content=GMB_listing&y_source=1_MTc0OTMxMDktNzE1LWxvY2F0aW9uLndlYnNpdGU=",
      first_type: "movie_theater",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0811554, 37.4144292] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4926 El Camino Real, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 968-7511",
      name: "Amber India",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
          "Sunday: 11:30 AM – 2:30 PM, 5:00 – 9:30 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "bar",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1079311, 37.397167] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 940-9500",
      name: "Cascal",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 10:00 PM",
          "Saturday: 11:30 AM – 10:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.cascalmv.com/",
      first_type: "bar",
      zip_code: "94041",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0810107, 37.391193699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1177 W El Camino Real, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 737-2323",
      name: "Madras Café",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 8:30 AM – 10:00 PM",
          "Wednesday: 8:30 AM – 10:00 PM",
          "Thursday: 8:30 AM – 10:00 PM",
          "Friday: 8:30 AM – 10:00 PM",
          "Saturday: 8:30 AM – 10:00 PM",
          "Sunday: 8:30 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.madrascafe.us/index.htm",
      first_type: "cafe",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0549333, 37.3743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "357 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 965-2000",
      name: "Sakoon",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2230" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1200" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 10:30 PM",
          "Saturday: 12:00 – 3:00 PM, 5:00 – 10:30 PM",
          "Sunday: 12:00 – 3:00 PM, 5:00 – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.sakoonrestaurant.com/",
      first_type: "bar",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0800861, 37.3917472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "607 W Dana St Unit H, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 968-3227",
      name: "Sushi Tomi",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Tuesday: Closed",
          "Wednesday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Thursday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
          "Friday: 11:30 AM – 1:30 PM, 5:00 – 8:30 PM",
          "Saturday: 11:30 AM – 1:30 PM, 5:00 – 8:30 PM",
          "Sunday: 11:30 AM – 1:30 PM, 5:00 – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://sushitomi.us/",
      first_type: "restaurant",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.078048, 37.3917973] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2020 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 938-2020",
      name: "ViVe Sol",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 5:00 – 8:30 PM",
          "Thursday: 5:00 – 8:30 PM",
          "Friday: 5:00 – 9:00 PM",
          "Saturday: 5:00 – 9:00 PM",
          "Sunday: 5:00 – 8:30 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.solrestaurantsca.com/vive",
      first_type: "restaurant",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0996111, 37.3953222] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "401 Castro St #100, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 237-3132",
      name: "Scratch",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 9:30 PM",
          "Tuesday: 4:00 – 9:30 PM",
          "Wednesday: 4:00 – 9:30 PM",
          "Thursday: 4:00 – 9:30 PM",
          "Friday: 4:00 – 10:00 PM",
          "Saturday: 10:00 AM – 10:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://www.scratchmtnview.com/",
      first_type: "bar",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.080452, 37.391093] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "895 Villa St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 963-9568",
      name: "Steins Beer Garden & Restaurant",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 10:00 PM",
          "Wednesday: 11:30 AM – 10:00 PM",
          "Thursday: 11:30 AM – 10:00 PM",
          "Friday: 11:30 AM – 11:00 PM",
          "Saturday: 10:00 AM – 11:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.steinsbeergarden.com/",
      first_type: "bar",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.079885, 37.3940533] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "750 Castro Street, Park Place South, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 564-9255",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:00 PM",
          "Tuesday: 5:30 AM – 9:00 PM",
          "Wednesday: 5:30 AM – 9:00 PM",
          "Thursday: 5:30 AM – 9:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/11148/",
      first_type: "cafe",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.083068, 37.387571] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1067 N San Antonio Rd #1300, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 948-2696",
      name: "Chef Chu's",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Tuesday: Closed",
          "Wednesday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Thursday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Sunday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.chefchu.com/",
      first_type: "restaurant",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1136827, 37.4005779] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "233 3rd St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 948-3524",
      name: "Los Altos Grill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 9:00 PM",
          "Tuesday: 12:00 – 9:00 PM",
          "Wednesday: 12:00 – 9:00 PM",
          "Thursday: 12:00 – 9:00 PM",
          "Friday: 12:00 – 9:30 PM",
          "Saturday: 12:00 – 9:30 PM",
          "Sunday: 12:00 – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "https://losaltosgrill.com/",
      first_type: "bar",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1148581, 37.3782219] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "174 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 988-9323",
      name: "Amarin Thai Cuisine",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "1145" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Tuesday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Wednesday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Thursday: 11:30 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Friday: 11:30 AM – 2:00 PM, 5:00 – 9:30 PM",
          "Saturday: 5:00 – 9:30 PM",
          "Sunday: 11:45 AM – 2:00 PM, 5:00 – 9:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.amarinthaicuisine.com/",
      first_type: "restaurant",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0789767, 37.3942565] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1380 Pear Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 965-2048",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 6:00 PM",
          "Tuesday: 4:30 AM – 6:00 PM",
          "Wednesday: 4:30 AM – 6:00 PM",
          "Thursday: 4:30 AM – 6:00 PM",
          "Friday: 4:30 AM – 6:00 PM",
          "Saturday: 5:30 AM – 6:00 PM",
          "Sunday: 5:30 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/7373/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.077546, 37.415785] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2146 Leghorn St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 254-1231",
      name: "Krispy Kreme",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://site.krispykreme.com/ca/mountain-view/2146-leghorn-st?y_source=1_MTE4OTgwMDctNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0942484, 37.419211100000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 Ellis St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(415) 523-8992",
      name: "Specialty's Café & Bakery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 4:00 PM",
          "Tuesday: 7:00 AM – 4:00 PM",
          "Wednesday: 7:00 AM – 4:00 PM",
          "Thursday: 7:00 AM – 4:00 PM",
          "Friday: 7:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://specialtys.com/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0507926, 37.4028743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1100 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 793-0564",
      name: "Passage to India",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Tuesday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Wednesday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Thursday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Friday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Saturday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
          "Sunday: 10:00 AM – 2:00 PM, 4:30 – 9:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://www.passagetoindia.net/",
      first_type: "meal_delivery",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.087112, 37.3877568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "235 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 961-9749",
      name: "Don Giovanni",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 9:00 PM",
          "Tuesday: 5:00 – 9:00 PM",
          "Wednesday: 5:00 – 9:00 PM",
          "Thursday: 5:00 – 9:00 PM",
          "Friday: 5:00 – 9:00 PM",
          "Saturday: 5:00 – 9:00 PM",
          "Sunday: 5:00 – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://dongiovannis.com/",
      first_type: "restaurant",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0790841, 37.3933697] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1159 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(800) 786-1000",
      name: "In-N-Out Burger",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0100" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 2, time: "0100" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 3, time: "0100" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 4, time: "0100" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 5, time: "0100" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 6, time: "0130" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 0, time: "0130" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 1:00 AM",
          "Tuesday: 10:30 AM – 1:00 AM",
          "Wednesday: 10:30 AM – 1:00 AM",
          "Thursday: 10:30 AM – 1:00 AM",
          "Friday: 10:30 AM – 1:30 AM",
          "Saturday: 10:30 AM – 1:30 AM",
          "Sunday: 10:30 AM – 1:00 AM",
        ],
      },
      rating: "4.5",
      website: "https://locations.in-n-out.com/129",
      first_type: "restaurant",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0933529, 37.420941] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1177 W El Camino Real, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 737-2323",
      name: "Madras Café",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 8:30 AM – 10:00 PM",
          "Wednesday: 8:30 AM – 10:00 PM",
          "Thursday: 8:30 AM – 10:00 PM",
          "Friday: 8:30 AM – 10:00 PM",
          "Saturday: 8:30 AM – 10:00 PM",
          "Sunday: 8:30 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.madrascafe.us/index.htm",
      first_type: "cafe",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0549333, 37.3743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "201 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 967-4473",
      name: "Red Rock Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 8:00 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 5:30 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.redrockcoffee.com/",
      first_type: "cafe",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0787562, 37.3936221] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "750 Castro Street, Park Place South, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 564-9255",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:00 PM",
          "Tuesday: 5:30 AM – 9:00 PM",
          "Wednesday: 5:30 AM – 9:00 PM",
          "Thursday: 5:30 AM – 9:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/11148/",
      first_type: "cafe",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.083068, 37.387571] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1380 Pear Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 965-2048",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 6:00 PM",
          "Tuesday: 4:30 AM – 6:00 PM",
          "Wednesday: 4:30 AM – 6:00 PM",
          "Thursday: 4:30 AM – 6:00 PM",
          "Friday: 4:30 AM – 6:00 PM",
          "Saturday: 5:30 AM – 6:00 PM",
          "Sunday: 5:30 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/7373/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.077546, 37.415785] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2146 Leghorn St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 254-1231",
      name: "Krispy Kreme",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://site.krispykreme.com/ca/mountain-view/2146-leghorn-st?y_source=1_MTE4OTgwMDctNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0942484, 37.419211100000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 Ellis St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(415) 523-8992",
      name: "Specialty's Café & Bakery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 4:00 PM",
          "Tuesday: 7:00 AM – 4:00 PM",
          "Wednesday: 7:00 AM – 4:00 PM",
          "Thursday: 7:00 AM – 4:00 PM",
          "Friday: 7:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://specialtys.com/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0507926, 37.4028743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "415 N Mary Ave #102, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-6200",
      name: "Little India Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Tuesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Wednesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Saturday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Sunday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.littleindiacafeus.com/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0423227, 37.3898184] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "852 Villa St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 210-9986",
      name: "Verde Tea Cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 7:00 PM",
          "Wednesday: 11:30 AM – 7:00 PM",
          "Thursday: 11:30 AM – 7:00 PM",
          "Friday: 11:30 AM – 7:00 PM",
          "Saturday: 11:30 AM – 7:00 PM",
          "Sunday: 11:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "cafe",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0794885, 37.3941637] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "580 Rengstorff Ave North, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 966-1515",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:30 PM",
          "Tuesday: 5:30 AM – 6:30 PM",
          "Wednesday: 5:30 AM – 6:30 PM",
          "Thursday: 5:30 AM – 6:30 PM",
          "Friday: 5:30 AM – 6:30 PM",
          "Saturday: 5:30 AM – 6:30 PM",
          "Sunday: 5:30 AM – 6:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/16324/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.094239, 37.4114435] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1037 El Monte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 254-8929",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 7:00 PM",
          "Tuesday: 5:30 AM – 7:00 PM",
          "Wednesday: 5:30 AM – 7:00 PM",
          "Thursday: 5:30 AM – 7:00 PM",
          "Friday: 5:30 AM – 7:00 PM",
          "Saturday: 6:00 AM – 6:30 PM",
          "Sunday: 6:00 AM – 6:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/8806/",
      first_type: "cafe",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0947763, 37.3909514] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2410 Charleston Rd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 967-9176",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 4:30 PM",
          "Sunday: 6:00 AM – 4:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/7381/",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.096419, 37.4218596] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "744 W Dana St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 390-9638",
      name: "Dana Street Roasting Company",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 2:00 PM",
          "Sunday: 8:00 AM – 2:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.danastreetroasting.com/",
      first_type: "cafe",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0790417, 37.3924139] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1060 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 428-0960",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.8",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/MOUNTAIN-VIEW/1060-RENGSTORFF-AVE/16328.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0936501, 37.4194009] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "167 Main St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 322-5544",
      name: "Lisa's Tea Treasures",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 11:30 AM – 5:30 PM",
          "Thursday: 11:30 AM – 5:30 PM",
          "Friday: 11:30 AM – 5:30 PM",
          "Saturday: 11:00 AM – 5:30 PM",
          "Sunday: 11:00 AM – 5:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://lisastealosaltos.com/",
      first_type: "cafe",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1150092, 37.3791631] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 245-2987",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.starbucks.com/store-locator/store/16802/",
      first_type: "cafe",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0303206, 37.388266] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "205 State St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 559-1941",
      name: "Rick's Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0730" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 2:00 PM",
          "Tuesday: 7:30 AM – 2:00 PM",
          "Wednesday: 7:30 AM – 2:00 PM",
          "Thursday: 7:30 AM – 2:00 PM",
          "Friday: 7:30 AM – 2:00 PM",
          "Saturday: 7:30 AM – 3:00 PM",
          "Sunday: 7:30 AM – 3:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.rickscafeonline.com/",
      first_type: "cafe",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.116369, 37.379399] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "367 State St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 941-6722",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Los%20Altos,%20CA,%20USA&selected=Los%20Altos",
      first_type: "cafe",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.117914, 37.378768] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "565 San Antonio Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 947-4952",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 6:30 PM",
          "Tuesday: 5:00 AM – 6:30 PM",
          "Wednesday: 5:00 AM – 6:30 PM",
          "Thursday: 5:00 AM – 6:30 PM",
          "Friday: 5:00 AM – 6:30 PM",
          "Saturday: 5:00 AM – 6:30 PM",
          "Sunday: 5:00 AM – 6:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/116466/",
      first_type: "cafe",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1122888, 37.4027794] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "205 E Middlefield Rd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 210-8032",
      name: "Clocktower Coffee Roasting Company",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 5:00 PM",
          "Tuesday: 6:00 AM – 5:00 PM",
          "Wednesday: 6:00 AM – 5:00 PM",
          "Thursday: 6:00 AM – 5:00 PM",
          "Friday: 6:00 AM – 5:00 PM",
          "Saturday: 7:00 AM – 4:00 PM",
          "Sunday: 7:00 AM – 4:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "cafe",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0615779, 37.3971431] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "296 Main St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 949-3565",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:30 PM",
          "Tuesday: 5:30 AM – 6:30 PM",
          "Wednesday: 5:30 AM – 6:30 PM",
          "Thursday: 5:30 AM – 6:30 PM",
          "Friday: 5:30 AM – 6:30 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/12115/",
      first_type: "cafe",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1166825, 37.3785835] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 917-0796",
      name: "Walmart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/2280-mountain-view-ca",
      first_type: "department_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1092052, 37.4010201] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "555 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 965-7764",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.target.com/sl/mountain-view/322",
      first_type: "department_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1062303, 37.4011442] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1000 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 988-1841",
      name: "Costco Wholesale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.costco.com/warehouse-locations/mountain-view-CA-143.html?utm_term=KW&utm_campaign=Homepage&utm_medium=GMB&utm_source=Google&utm_content=Link",
      first_type: "department_store",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.095643, 37.420947] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1040 Grant Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 965-4091",
      name: "Marshalls & HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.marshalls.com/",
      first_type: "department_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0753842, 37.379163] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 947-7600",
      name: "Kohl's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.kohls.com/stores/ca/mountainview-1388.shtml?utm_source=google&utm_medium=organic&utm_campaign=local",
      first_type: "department_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1075821, 37.4031776] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1030 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 625-8049",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 10:00 PM",
          "Tuesday: 10:00 AM – 10:00 PM",
          "Wednesday: 10:00 AM – 10:00 PM",
          "Thursday: 10:00 AM – 10:00 PM",
          "Friday: 9:30 AM – 11:00 PM",
          "Saturday: 9:30 AM – 11:00 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0959091, 37.419490800000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "550 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 941-2268",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "2.6",
      website:
        "https://www.24hourfitness.com/gyms/mountain-view-ca/mountain-view-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1094347, 37.402635] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3921 Fabian Way, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 223-8700",
      name: "Oshman Family JCC",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 6:00 PM",
          "Sunday: 7:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.paloaltojcc.org/",
      first_type: "gym",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1038742, 37.4237671] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1080 Enterprise Way, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 419-4730",
      name: "Moffett Towers Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://www.moffett-towers-club.com/",
      first_type: "gym",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0364532, 37.4067878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 Miramonte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "missing",
      name: "St Francis High School Gym",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 2:30 PM",
          "Tuesday: 7:45 AM – 2:30 PM",
          "Wednesday: 7:45 AM – 2:30 PM",
          "Thursday: 7:45 AM – 2:30 PM",
          "Friday: 7:45 AM – 2:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.sfhs.com/",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0848059, 37.3696275] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3412 Ross Rd, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 856-9622",
      name: "Palo Alto Family YMCA",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0615" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0615" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0615" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0615" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0615" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:15 AM – 9:00 PM",
          "Tuesday: 6:15 AM – 9:00 PM",
          "Wednesday: 6:15 AM – 9:00 PM",
          "Thursday: 6:15 AM – 9:00 PM",
          "Friday: 6:15 AM – 7:00 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.ymcasv.org/paloalto",
      first_type: "gym",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.116495, 37.4283512] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3921 Fabian Way, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 321-2326",
      name: "Blossom Birth & Family",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 2:00 PM",
          "Tuesday: 10:00 AM – 2:00 PM",
          "Wednesday: 10:00 AM – 2:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 10:00 AM – 2:00 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: 10:00 AM – 2:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.blossombirthandfamily.org/",
      first_type: "gym",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1029975, 37.4232923] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2598 W Middlefield Rd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 867-0447",
      name: "CrossFit Palo Alto",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 9:00 – 11:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.crossfitpaloalto.com/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.1017384, 37.4158819] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1910 W El Camino Real Unit F, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 961-8100",
      name: "The Little Gym of Mountain View",
      opening_hours: "missing",
      rating: "4.6",
      website: "https://www.thelittlegym.com/california-mountain-view/",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0971256, 37.3942608] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "112 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(408) 822-9597",
      name: "NCFIT Mountain View",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0730" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 8:00 PM",
          "Tuesday: 5:00 AM – 8:00 PM",
          "Wednesday: 5:00 AM – 8:00 PM",
          "Thursday: 5:00 AM – 8:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 7:30 AM – 12:00 PM",
          "Sunday: 7:30 AM – 12:00 PM",
        ],
      },
      rating: "4.9",
      website: "http://www.nc.fit/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0978268, 37.4042914] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "275 Moffett Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 862-3976",
      name: "Yoga Belly",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1815" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 7:30 PM",
          "Tuesday: 9:30 AM – 7:30 PM",
          "Wednesday: 9:30 AM – 7:00 PM",
          "Thursday: 9:30 AM – 6:15 PM",
          "Friday: 8:00 AM – 5:30 PM",
          "Saturday: 9:00 AM – 10:30 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.yogabellyworld.com/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0763987, 37.3973136] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Grant Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 969-9622",
      name: "El Camino YMCA",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:00 PM",
          "Tuesday: 5:30 AM – 9:00 PM",
          "Wednesday: 5:30 AM – 9:00 PM",
          "Thursday: 5:30 AM – 9:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 7:00 AM – 6:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://www.ymcasv.org/locations/el-camino-ymca",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0792443, 37.3706287] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2044 Old Middlefield Way, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 965-8991",
      name: "Evolution Trainers",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "http://www.evolutiontrainers.com/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0916883, 37.4147401] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1931 Old Middlefield Way Unit C, Mountain View, CA 94043, USA",
      formatted_phone_number: "(408) 373-0204",
      name: "Tandez Academy of Martial Arts",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1800" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 – 8:00 PM",
          "Tuesday: 5:00 – 8:00 PM",
          "Wednesday: 6:00 – 8:00 PM",
          "Thursday: 5:00 – 8:00 PM",
          "Friday: 6:00 – 8:00 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.tandezacademyofmartialarts.com/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0888489, 37.413796100000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "575 N Shoreline Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Mountain View Kendo Dojo",
      opening_hours: "missing",
      rating: "5",
      website: "http://mountainviewkendo.org/",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0772016, 37.4022382] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 W Middlefield Rd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 903-6331",
      name: "Whisman Sports Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 – 10:00 PM",
          "Tuesday: 5:30 – 10:00 PM",
          "Wednesday: 5:30 – 10:00 PM",
          "Thursday: 5:30 – 10:00 PM",
          "Friday: 5:30 – 10:00 PM",
          "Saturday: 8:30 AM – 4:00 PM",
          "Sunday: 9:00 AM – 4:30 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0850025, 37.4107795] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "241 Polaris Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 386-5808",
      name: "Boss Barbell Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "4.7",
      website: "missing",
      first_type: "gym",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0835946, 37.3985976] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "319 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 584-3465",
      name: "Transform Fitness Studio - Mountain View",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0730" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:30 AM – 7:00 PM",
        ],
      },
      rating: "4.9",
      website: "http://www.transformfitnessmv.com/",
      first_type: "gym",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0797863, 37.3921312] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "745 Distel Dr #121, Los Altos, CA 94022, USA",
      formatted_phone_number: "(805) 364-0139",
      name: "Beth Alexander Fitness",
      opening_hours: "missing",
      rating: "5",
      website: "http://www.bethalexanderfitness.com/",
      first_type: "school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.103733, 37.3951375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2482 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 479-6286",
      name: "Fit Theory- Personal Training Studio",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 10:00 – 11:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.fittheorystudio.com/",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1092978, 37.3993387] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1040 Grant Rd Ste 165, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 429-4085",
      name: "City Sports Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://citysportsfitness.com/Pages/clubhome.aspx?clubid=1085&utm_source=google&utm_medium=googlemaps&utm_campaign=maps",
      first_type: "gym",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0750465, 37.3795731] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2550 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(408) 234-6471",
      name: "San Antonio Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://sanantoniocenter.com/",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.108281, 37.4034613] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 San Antonio Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(415) 675-7742",
      name: "The Village at San Antonio Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://villageatsanantoniocenter.com/directory/",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1111885, 37.404069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1111 W El Camino Real, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 739-2252",
      name: "Cala Center",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0533349, 37.3738394] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1710 Miramonte at Cuesta, Mountain View, CA 94040, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Blossom Valley",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.regencycenters.com/property/detail/93012/Blossom-Valley",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0879056, 37.37245] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3966 Middlefield Rd, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 494-1404",
      name: "Charleston Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1096868, 37.4186381] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1350 Grant Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "missing",
      name: "Grant Park Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0765569, 37.3773531] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Monta Loma Plaza",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "0945" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0945" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "0945" },
          },
          {
            close: { day: 5, time: "1815" },
            open: { day: 5, time: "0945" },
          },
          {
            close: { day: 6, time: "1830" },
            open: { day: 6, time: "0945" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 12:00 PM",
          "Tuesday: 9:45 AM – 12:00 PM",
          "Wednesday: 9:45 AM – 12:00 PM",
          "Thursday: 9:45 AM – 12:00 PM",
          "Friday: 9:45 AM – 6:15 PM",
          "Saturday: 9:45 AM – 6:30 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0940006, 37.4108756] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moffett Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Moffett Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0750238, 37.3993061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "327 Moffett Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Moffett Central",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0752452, 37.3980539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "530 Franklin St, Mountain View, CA 94041, USA",
      formatted_phone_number: "missing",
      name: "JUCUAPA USULUTAN",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0841107, 37.3905273] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "650 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "missing",
      name: "Mountain View City Center",
      opening_hours: "missing",
      rating: "4.7",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0829362, 37.38811] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Rengstorff Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 8:30 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0960125, 37.4204947] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "E, 2398 Charleston Rd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(800) 762-1641",
      name: "Charleston Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0966097, 37.422539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "173 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "missing",
      name: "Mountain View Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0720425, 37.3790349] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "663 S Bernardo Ave, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 248-6658",
      name: "Cherry Chase Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0576115, 37.3728561] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "S Mary Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "missing",
      name: "Mary Manor Shopping Center",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.046633, 37.3784536] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "W. Maude Ave &, N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(925) 838-7070",
      name: "Sunnyvale Square Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://lajollamgt.com/",
      first_type: "shopping_mall",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0298805, 37.3887696] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "205 South Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "missing",
      name: "Woodland Plaza Shopping Center",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0796611, 37.3669727] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "555 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 965-0129",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.1",
      website:
        "https://www.cvs.com/store-locator/mountain-view-ca-pharmacies/555-showers-dr-mountain-view-ca-94040/storeid=16113?WT.mc_id=LS_GOOGLE_RX_16113",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1057378, 37.4014707] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 917-0878",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "3.3",
      website: "https://www.walmart.com/store/2280-mountain-view-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1094945, 37.4011371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2570 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 917-3660",
      name: "Pacific Research Associates",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1112342, 37.4012174] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "570 N Shoreline Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 961-4851",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3.3",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/mountain-view/570-n-shoreline-blvd.html",
      first_type: "pharmacy",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0793795, 37.4030758] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "850 California St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 691-4004",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "2.6",
      website:
        "https://www.cvs.com/store-locator/mountain-view-ca-pharmacies/850-california-st-mountain-view-ca-94041/storeid=7774?WT.mc_id=LS_GOOGLE_RX_7774",
      first_type: "pharmacy",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0813783, 37.3918559] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1041 El Monte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 961-5560",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "2.3",
      website:
        "https://www.cvs.com/store-locator/mountain-view-ca-pharmacies/1041-el-monte-ave-mountain-view-ca-94040/storeid=9346?WT.mc_id=LS_GOOGLE_RX_9346",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0945746, 37.3900289] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 San Antonio Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 948-4605",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.3",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/mountain-view/645-san-antonio-rd.html",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.111801, 37.4016953] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2630 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 941-8430",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "2.6",
      website:
        "https://www.cvs.com/store-locator/mountain-view-ca-pharmacies/2630-w-el-camino-real-mountain-view-ca-94040/storeid=9684?WT.mc_id=LS_GOOGLE_RX_9684",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1145801, 37.4023905] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2500 Grant Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 988-8240",
      name: "El Camino Health Outpatient Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: 9:30 AM – 4:00 PM",
          "Sunday: 9:30 AM – 4:00 PM",
        ],
      },
      rating: "4.7",
      website:
        "https://locations.elcaminohealth.org/mountain-view-hospital-campus/2500-grant-rd/outpatient_pharmacy-mv?utm_campaign=listings&utm_source=google&utm_medium=location_link&utm_content=outpatient_pharmacy-mv",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0793486, 37.369258900000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1165 W El Camino Real, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 732-1688",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "2.5",
      website:
        "https://www.cvs.com/store-locator/sunnyvale-ca-pharmacies/1165-w-el-camino-real-sunnyvale-ca-94087/storeid=9856?WT.mc_id=LS_GOOGLE_RX_9856",
      first_type: "pharmacy",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0540848, 37.3737912] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "LOS ALTOS MASONIC BUILDING, 146 Main St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 948-1200",
      name: "Altos Therapeutics LLC",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.11525, 37.3796581] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1000 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 988-1841",
      name: "Costco Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "3.3",
      website:
        "https://m.costco.com/warehouse-locations/mountain-view-ca-143.html",
      first_type: "pharmacy",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0958745, 37.4209287] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "160 1st St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 941-1844",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/los-altos/160-1st-st.html",
      first_type: "pharmacy",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1190505, 37.3789593] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "639 S Bernardo Ave, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 732-5902",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/sunnyvale/639-s-bernardo-ave.html",
      first_type: "pharmacy",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0579805, 37.3729277] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "701 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 934-7000",
      name: "Palo Alto Medical Foundation - Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.7",
      website: "http://www.pamf.org/mountainview/",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0651921, 37.3764314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "570 N Shoreline Blvd, Mountain View, CA 940433103, USA",
      formatted_phone_number: "(650) 961-4851",
      name: "Bich-Loan Hoang",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0794005, 37.4029895] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "800 California St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 969-2034",
      name: "Dana Oriental Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 7:00 PM",
          "Tuesday: 11:00 AM – 7:00 PM",
          "Wednesday: 11:00 AM – 7:00 PM",
          "Thursday: 11:00 AM – 7:00 PM",
          "Friday: 11:00 AM – 7:00 PM",
          "Saturday: 11:00 AM – 7:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94041",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0809693, 37.391643099999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "555 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 903-2150",
      name: "Pharmacy | Kaiser Permanente Mountain View Medical Offices",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:30 PM",
          "Tuesday: 8:30 AM – 5:30 PM",
          "Wednesday: 8:30 AM – 5:30 PM",
          "Thursday: 8:30 AM – 5:30 PM",
          "Friday: 8:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "2.7",
      website:
        "https://thrive.kaiserpermanente.org/care-near-you/northern-california/santaclara/departments/pharmacy/",
      first_type: "pharmacy",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.081037, 37.3891783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1905 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 967-3531",
      name: "Walgreens Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "2.9",
      website:
        "https://www.walgreens.com/locator/walgreens-1905+w+el+camino+real-mountain+view-ca-94040/id=13948",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0965976, 37.3927379] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1905 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 967-3531",
      name: "COVID-19 Drive-Thru Testing at Walgreens",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "3.2",
      website: "https://www.walgreens.com/findcare/covid19/testing",
      first_type: "pharmacy",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0965707, 37.3925804] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Sunnyvale, CA, USA",
      formatted_phone_number: "missing",
      name: "Sunnyvale",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.sunnyvale.ca.gov/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0363496, 37.36883] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "390 Moffett Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 988-0300",
      name: "Hampton Inn & Suites Mountain View",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.hilton.com/en/hotels/nuqcahx-hampton-suites-mountain-view/?SEO_id=GMB-AMER-HX-NUQCAHX&y_source=1_MjA4MzkxNi03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0757809, 37.3988429] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "55 Fairchild Dr, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 967-6856",
      name: "Ramada by Wyndham Mountain View",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.5",
      website:
        "https://www.ramadasiliconvalley.com/?utm_source=google%20my%20business&utm_medium=listing&utm_campaign=visit%20website",
      first_type: "lodging",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0637462, 37.4063521] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1854 El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 940-1300",
      name: "Residence Inn Palo Alto Mountain View",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.marriott.com/en-us/hotels/sfomv-residence-inn-palo-alto-mountain-view/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0951402, 37.3926659] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "860 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 940-1000",
      name: "Hotel Avante, a JdV by Hyatt Hotel",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website:
        "https://www.hyatt.com/en-US/hotel/california/jdv-hotel-avante/sjcja/?src=corp_lclb_gmb_seo_sjcja",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.061016, 37.376197] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4261 El Camino Real, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 493-2844",
      name: "Dinah's Garden Hotel",
      opening_hours: "missing",
      rating: "4.5",
      website: "http://www.dinahshotel.com/",
      first_type: "lodging",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1203004, 37.407464] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4290 El Camino Real, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 857-0787",
      name: "Crowne Plaza Palo Alto, an IHG Hotel",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.ihg.com/crowneplaza/hotels/us/en//palo-alto/sfoca/hoteldetail",
      first_type: "lodging",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1215384, 37.405935] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1012 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 961-6720",
      name: "Hotel Aria Mountain View",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.8",
      website: "https://hotelariamountainview.com/",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0859968, 37.3870861] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "93 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 967-6957",
      name: "Hotel Strata",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.7",
      website:
        "https://www.hotelstrata.com/?utm_source=google&utm_medium=organic&utm_campaign=gbp_listing",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0759028, 37.3811477] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1600 Villa St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 528-2128",
      name: "Avalon Mountain View",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website:
        "https://www.avaloncommunities.com/california/mountain-view-apartments/avalon-mountain-view?utm_source=gmb&utm_medium=organic&utm_campaign=gmblist",
      first_type: "real_estate_agency",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0866167, 37.3975517] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 735-7800",
      name: "Best Western Silicon Valley Inn",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.05622.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:05622",
      first_type: "lodging",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0292664, 37.3923314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "748 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 733-1212",
      name: "Larkspur Landing Sunnyvale",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website:
        "https://www.larkspurhotels.com/sunnyvale/?utm_source=google-gbp&utm_medium=organic&utm_campaign=gbp",
      first_type: "lodging",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0280497, 37.3945343] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "El Camino/El Monte, 1665 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 969-9641",
      name: "Super 8 by Wyndham Mountain View",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.7",
      website:
        "https://www.wyndhamhotels.com/super-8/mountain-view-california/super-8-mountain-view/overview?CID=LC:SE::GGL:RIO:National:10296&iata=00093796",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0933179, 37.389652] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4238 El Camino Real, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 493-4222",
      name: "The Palo Alto Inn",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website: "https://www.thepaloaltoinn.com/",
      first_type: "lodging",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1214735, 37.4077448] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 969-8200",
      name: "Hotel Zico",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website:
        "https://www.hotelzico.com/?utm_source=google&utm_medium=organic&utm_campaign=business_listing",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0685654, 37.3797073] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2300 W El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 962-9912",
      name: "SureStay Plus By Best Western Mountain View",
      opening_hours: "missing",
      rating: "3.4",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.52111.html?iata=90000026&ssob=BLSSH0004G&cid=BLSSH0004G:google:gmb:52111",
      first_type: "lodging",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1053284, 37.3978497] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 253-0000",
      name: "Googleplex",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "https://about.google/locations/?region=north-america",
      first_type: "point_of_interest",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0853242, 37.4220541] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "715 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(408) 738-8680",
      name: "Best Buy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://stores.bestbuy.com/ca/mountain-view/715-e-el-camino-real-685.html/?ref=NS&loc=ns100",
      first_type: "electronics_store",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0634868, 37.3753172] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "One Amphitheatre Pkwy, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 967-4040",
      name: "Shoreline Amphitheatre",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 10:00 AM – 2:00 PM",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website:
        "https://www.shorelineamphitheatre.com/?utm_source=GMBlisting&utm_medium=organic",
      first_type: "point_of_interest",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0807023, 37.426834199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Mountain View, CA, USA",
      formatted_phone_number: "missing",
      name: "Mountain View",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0838511, 37.3860517] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "590 Showers Dr, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 917-1013",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/mountain-view/81/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1107579, 37.4020838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1350 Grant Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 966-8899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://www.99ranch.com/",
      first_type: "supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0767399, 37.3775704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4800 El Camino Real, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 559-0300",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.wholefoodsmarket.com/stores/losaltos?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1105877, 37.3990844] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "639 S Bernardo Ave, Sunnyvale, CA 94087, USA",
      formatted_phone_number: "(408) 732-5900",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/sunnyvale/639-s-bernardo-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94087",
    },
    geometry: { type: "Point", coordinates: [-122.0581172, 37.3727912] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "645 San Antonio Rd, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 948-4533",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/mountain-view/645-san-antonio-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1118435, 37.4018092] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "570 N Shoreline Blvd, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 961-4868",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "3.9",
      website:
        "https://local.safeway.com/safeway/ca/mountain-view/570-n-shoreline-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0793665, 37.4031265] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "40 S Rengstorff Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 967-3630",
      name: "La Plaza Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0630" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 9:00 PM",
          "Tuesday: 6:30 AM – 9:00 PM",
          "Wednesday: 6:30 AM – 9:00 PM",
          "Thursday: 6:30 AM – 9:00 PM",
          "Friday: 6:30 AM – 9:00 PM",
          "Saturday: 6:30 AM – 9:00 PM",
          "Sunday: 6:30 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.laplazasupermarket.com/",
      first_type: "supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0979917, 37.4029462] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "143 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 691-1600",
      name: "Nijiya Market Mountain View Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 8:30 PM",
          "Sunday: 9:00 AM – 8:30 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.nijiya.com/",
      first_type: "supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.071405, 37.3786808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1750 Miramonte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 903-5850",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.safeway.com/safeway/ca/mountain-view/1750-miramonte-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0884411, 37.3723424] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "340 Castro St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 961-5652",
      name: "Ava's Downtown Market & Deli",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 8:30 PM",
          "Tuesday: 8:30 AM – 8:30 PM",
          "Wednesday: 8:30 AM – 8:30 PM",
          "Thursday: 8:30 AM – 8:30 PM",
          "Friday: 8:30 AM – 8:30 PM",
          "Saturday: 8:30 AM – 8:30 PM",
          "Sunday: 8:30 AM – 8:30 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "grocery_or_supermarket",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0802357, 37.3921324] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "342 1st St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 948-4425",
      name: "Draeger's Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.draegers.com/",
      first_type: "supermarket",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.116882, 37.376268] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "484 N Mathilda Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 737-1834",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://luckysupermarkets.com/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "grocery_or_supermarket",
      zip_code: "94085",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0295791, 37.388414299999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12 N Akron Rd, Mountain View, CA 94035, USA",
      formatted_phone_number: "(669) 265-6850",
      name: "Moffett Field Commissary",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "missing",
      first_type: "grocery_or_supermarket",
      zip_code: "94035",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0568004, 37.413337299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "801 W El Camino Real Suite B, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 960-1900",
      name: "Rose International Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 9:00 AM – 8:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://roseinternationalmarket.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94040",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0845741, 37.385591700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "141 E El Camino Real, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 962-1092",
      name: "Smart & Final",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.smartandfinal.com/store",
      first_type: "grocery_or_supermarket",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0716676, 37.3787526] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3445 Alma St, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 561-4394",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.groceryoutlet.com/circulars/storeid/253?utm_source=extnet&utm_medium=yext",
      first_type: "grocery_or_supermarket",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1243723, 37.4188552] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3922 Middlefield Rd, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 494-1629",
      name: "Piazza's Fine Foods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.piazzasfinefoods.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1097918, 37.4186066] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "988 N San Antonio Rd, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 947-9172",
      name: "ampm",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "2",
      website: "https://www.ampm.com/",
      first_type: "convenience_store",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.114653, 37.399837] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 316-6321",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/mountain-view/580-n-rengstorff-ave.html",
      first_type: "grocery_or_supermarket",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0944938, 37.4111327] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "160 1st St, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 941-1801",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/los-altos/160-1st-st.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1190452, 37.3789745] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "41 Tunnel Rd, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 843-3000",
      name: "Claremont Club & Spa - A Fairmont Hotel",
      opening_hours: "missing",
      rating: "4.5",
      website:
        "https://www.fairmont.com/claremont-berkeley/?goto=fiche_hotel&code_hotel=A553&merchantid=seo-maps-US-A553&sourceid=aw-cen&utm_medium=seo%20maps&utm_source=google%20Maps&utm_campaign=seo%20maps",
      first_type: "lodging",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2420658, 37.8590352] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2600 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-8981",
      name: "Graduate Berkeley",
      opening_hours: "missing",
      rating: "4.1",
      website: "https://www.graduatehotels.com/berkeley",
      first_type: "restaurant",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.256305, 37.867942] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2086 Allston Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-7300",
      name: "Hotel Shattuck Plaza",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://www.hotelshattuckplaza.com/?utm_source=google&utm_medium=organic&utm_campaign=business-listing",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2684195, 37.869183199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1517 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 548-5525",
      name: "Chez Panisse",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.chezpanisse.com/",
      first_type: "cafe",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2689248, 37.8796069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "53 Lafayette Cir, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 962-2469",
      name: "Tutu's Food and Drink",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:30 PM",
          "Tuesday: 8:00 AM – 8:30 PM",
          "Wednesday: 8:00 AM – 8:30 PM",
          "Thursday: 8:00 AM – 8:30 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 8:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://tutusfooddrink.com/",
      first_type: "restaurant",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1199834, 37.8906734] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5801 College Ave, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 655-6385",
      name: "Zachary's Chicago Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:30 PM",
          "Saturday: 11:00 AM – 9:30 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.7",
      website: "https://zacharys.com/",
      first_type: "restaurant",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2521527, 37.8462961] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2399 Telegraph Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 649-1000",
      name: "CREAM",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 10:00 PM",
          "Tuesday: 12:00 – 10:00 PM",
          "Wednesday: 12:00 – 10:00 PM",
          "Thursday: 12:00 – 10:00 PM",
          "Friday: 12:00 PM – 12:00 AM",
          "Saturday: 12:00 PM – 12:00 AM",
          "Sunday: 12:00 – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.creamnation.com/Berkeley",
      first_type: "store",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.258725, 37.867035] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2181 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Jupiter",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 10:00 PM",
          "Tuesday: 11:30 AM – 10:00 PM",
          "Wednesday: 11:30 AM – 10:00 PM",
          "Thursday: 11:30 AM – 10:00 PM",
          "Friday: 11:30 AM – 12:00 AM",
          "Saturday: 11:30 AM – 11:00 PM",
          "Sunday: 11:30 AM – 10:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://jupiterbeer.com/",
      first_type: "bar",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.267582, 37.8697958] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5912 College Ave, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 735-9459",
      name: "Millennium",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 8:00 PM",
          "Tuesday: 5:00 – 9:00 PM",
          "Wednesday: 5:00 – 9:00 PM",
          "Thursday: 5:00 – 9:00 PM",
          "Friday: 5:00 – 9:30 PM",
          "Saturday: 5:00 – 9:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "http://www.millenniumrestaurant.com/",
      first_type: "restaurant",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2519064, 37.8474479] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2200 Oxford St, Between Allston, Kittredge St, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 809-0400",
      name: "Gather",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 11:00 AM – 2:00 PM, 4:00 – 8:30 PM",
          "Thursday: 11:00 AM – 2:00 PM, 4:00 – 8:30 PM",
          "Friday: 11:00 AM – 2:00 PM, 4:00 – 8:30 PM",
          "Saturday: 10:00 AM – 2:00 PM, 4:00 – 8:30 PM",
          "Sunday: 10:00 AM – 2:00 PM, 4:00 – 8:30 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.gatherberkeley.com/",
      first_type: "restaurant",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2661179, 37.8695038] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1512 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 549-3183",
      name: "Cheese Board Collective Pizzeria",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 8:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "http://cheeseboardcollective.coop/",
      first_type: "restaurant",
      zip_code: "94709",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2694794, 37.879872299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2922 Domingo Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 649-8538",
      name: "Rick & Ann's Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: Closed",
          "Thursday: 8:30 AM – 2:00 PM",
          "Friday: 8:30 AM – 2:00 PM",
          "Saturday: 8:30 AM – 2:00 PM",
          "Sunday: 8:30 AM – 2:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://rickandanns.com/",
      first_type: "cafe",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2441028, 37.8585333] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2261 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 548-6900",
      name: "Angeline's Louisiana Kitchen",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.angelineskitchen.com/",
      first_type: "restaurant",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2675528, 37.8682333] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3565 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 299-8700",
      name: "Postino Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Tuesday: 11:30 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Wednesday: 11:30 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Thursday: 11:30 AM – 3:00 PM, 4:30 – 8:00 PM",
          "Friday: 11:30 AM – 3:00 PM, 4:30 – 8:30 PM",
          "Saturday: 11:30 AM – 3:00 PM, 4:30 – 8:30 PM",
          "Sunday: 4:30 – 8:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.postinorestaurant.com/",
      first_type: "restaurant",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1211538, 37.891226] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1920 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 843-2739",
      name: "Triple Rock Brewing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 12:00 – 10:00 PM",
          "Wednesday: 12:00 – 11:00 PM",
          "Thursday: 12:00 – 11:00 PM",
          "Friday: 12:00 – 11:00 PM",
          "Saturday: 12:00 – 11:00 PM",
          "Sunday: 12:00 – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://triplerock.com/",
      first_type: "bar",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2687553, 37.873416] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2377 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 843-1525",
      name: "La Note",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.lanoterestaurant.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.267249, 37.866206] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2102 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 549-9950",
      name: "Revival Bar+Kitchen",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 8:30 PM",
          "Tuesday: 4:00 – 8:30 PM",
          "Wednesday: 4:00 – 8:30 PM",
          "Thursday: 4:00 – 8:30 PM",
          "Friday: 4:00 – 9:00 PM",
          "Saturday: 4:00 – 9:00 PM",
          "Sunday: 4:00 – 8:30 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.revivalbarandkitchen.com/",
      first_type: "bar",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2684878, 37.8710465] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2020 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 926-6300",
      name: "Comal",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1730" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1730" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 – 9:30 PM",
          "Tuesday: 5:30 – 9:30 PM",
          "Wednesday: 5:30 – 9:30 PM",
          "Thursday: 5:30 – 10:00 PM",
          "Friday: 5:30 – 10:00 PM",
          "Saturday: 5:30 – 10:00 PM",
          "Sunday: 5:30 – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.comalberkeley.com/",
      first_type: "bar",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2686336, 37.871580699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2315 Durant Ave 2nd floor, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 280-1547",
      name: "Julia's Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 2, time: "1945" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 3, time: "1945" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 4, time: "1945" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 5, time: "1945" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "1945" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 12:00 – 2:00 PM, 5:00 – 7:45 PM",
          "Wednesday: 12:00 – 2:00 PM, 5:00 – 7:45 PM",
          "Thursday: 12:00 – 2:00 PM, 5:00 – 7:45 PM",
          "Friday: 12:00 – 2:00 PM, 5:00 – 7:45 PM",
          "Saturday: 11:30 AM – 2:00 PM, 5:00 – 7:45 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "https://www.berkeleycityclub.com/julias-restaurant",
      first_type: "restaurant",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2628369, 37.8676208] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2600 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-8981",
      name: "Henry's Bar & Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 10:00 PM",
          "Tuesday: 4:00 – 10:00 PM",
          "Wednesday: 4:00 – 10:00 PM",
          "Thursday: 4:00 – 10:00 PM",
          "Friday: 4:00 – 10:00 PM",
          "Saturday: 4:00 – 10:00 PM",
          "Sunday: 4:00 – 10:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.graduatehotels.com/berkeley/restaurant/henrys/",
      first_type: "bar",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2565786, 37.8679852] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2086 Allston Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-7300",
      name: "Hotel Shattuck Plaza",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://www.hotelshattuckplaza.com/?utm_source=google&utm_medium=organic&utm_campaign=business-listing",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2684195, 37.869183199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2299 Piedmont Ave, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 643-9932",
      name: "edmonds cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://ihouse.berkeley.edu/dining/edmonds-caf%C3%A9",
      first_type: "cafe",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2520194, 37.869682] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1517 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 548-5525",
      name: "Chez Panisse",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 10:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.chezpanisse.com/",
      first_type: "cafe",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2689248, 37.8796069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2922 Domingo Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 649-8538",
      name: "Rick & Ann's Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: Closed",
          "Thursday: 8:30 AM – 2:00 PM",
          "Friday: 8:30 AM – 2:00 PM",
          "Saturday: 8:30 AM – 2:00 PM",
          "Sunday: 8:30 AM – 2:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://rickandanns.com/",
      first_type: "cafe",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2441028, 37.8585333] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2522 Bancroft Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Cafe Milano",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2585944, 37.8686139] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2377 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 843-1525",
      name: "La Note",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:00 PM",
          "Tuesday: 8:00 AM – 2:00 PM",
          "Wednesday: 8:00 AM – 2:00 PM",
          "Thursday: 8:00 AM – 2:00 PM",
          "Friday: 8:00 AM – 2:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.lanoterestaurant.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.267249, 37.866206] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1920 Oxford St, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 843-2233",
      name: "Yali's Café",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://www.yaliscafe.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.266408, 37.8734782] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1018 Oak Hill Rd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 385-0352",
      name: "Millie's American Kitchen",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 7:00 AM – 1:30 PM",
          "Wednesday: 7:00 AM – 1:30 PM",
          "Thursday: 7:00 AM – 1:30 PM",
          "Friday: 7:00 AM – 1:30 PM",
          "Saturday: 8:00 AM – 1:30 PM",
          "Sunday: 8:00 AM – 1:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.milliesamericankitchen.com/",
      first_type: "meal_takeaway",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1201999, 37.8931735] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1620 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 845-2233",
      name: "Guerilla Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 2:00 PM",
          "Tuesday: Closed",
          "Wednesday: 7:00 AM – 2:00 PM",
          "Thursday: 7:00 AM – 2:00 PM",
          "Friday: 7:00 AM – 2:00 PM",
          "Saturday: 7:00 AM – 2:00 PM",
          "Sunday: 7:00 AM – 2:00 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "cafe",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.26925, 37.8778833] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2124 Vine St, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 841-0564",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 8:00 PM",
          "Tuesday: 5:30 AM – 8:00 PM",
          "Wednesday: 5:30 AM – 8:00 PM",
          "Thursday: 5:30 AM – 8:00 PM",
          "Friday: 5:30 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=berkeley,%20ca&selected=4th%20Street",
      first_type: "cafe",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2683931, 37.8801963] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2300 College Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 843-5282",
      name: "Caffè Strada",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 7:00 AM – 12:00 AM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.caffestrada.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.254859, 37.869146] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3518 Mount Diablo Blvd #E, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 299-0735",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Lafayette,%20CA,%20USA&selected=Lafayette",
      first_type: "cafe",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.118, 37.893118] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2629 Telegraph Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 849-1100",
      name: "Le Bateau Ivre Restaurant",
      opening_hours: "missing",
      rating: "4.3",
      website: "http://www.lebateauivre.net/",
      first_type: "restaurant",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2585589, 37.8625029] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2086 University Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 549-9137",
      name: "Asha Tea House",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 7:00 PM",
          "Tuesday: 10:30 AM – 7:00 PM",
          "Wednesday: 10:30 AM – 7:00 PM",
          "Thursday: 10:30 AM – 7:00 PM",
          "Friday: 10:30 AM – 7:00 PM",
          "Saturday: 10:30 AM – 8:00 PM",
          "Sunday: 10:30 AM – 7:30 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.ashateahouse.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.268853, 37.871952] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "307 63rd St, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 653-5458",
      name: "Cole Coffee Bean + Retail Shop",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 1:00 PM",
          "Tuesday: 6:00 AM – 5:00 PM",
          "Wednesday: 6:00 AM – 5:00 PM",
          "Thursday: 6:00 AM – 5:00 PM",
          "Friday: 6:00 AM – 5:00 PM",
          "Saturday: 7:00 AM – 5:00 PM",
          "Sunday: 7:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.colecoffee.com/locations",
      first_type: "cafe",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2525717, 37.8502163] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3547 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 284-5811",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 5:30 AM – 4:30 PM",
          "Sunday: 5:30 AM – 4:30 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/8809/",
      first_type: "cafe",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1200003, 37.8916515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2 Sather Rd, Berkeley, CA 94720, USA",
      formatted_phone_number: "missing",
      name: "The Golden Bear Café",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 7:00 PM",
          "Tuesday: 7:30 AM – 7:00 PM",
          "Wednesday: 7:30 AM – 7:00 PM",
          "Thursday: 7:30 AM – 7:00 PM",
          "Friday: 7:30 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.7",
      website:
        "http://dining.berkeley.edu/locations/campus-restaurants/the-golden-bear-cafe/",
      first_type: "cafe",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2596451, 37.8698436] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2916 Domingo Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 843-1434",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=berkeley,%20ca&selected=Domingo",
      first_type: "cafe",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.244069, 37.858707] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Berkeley, CA 94720, USA",
      formatted_phone_number: "missing",
      name: "The Coffee Lab",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "missing",
      first_type: "cafe",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2558245, 37.8727848] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2042 University Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 704-0325",
      name: "Cafe Platano",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 6:00 PM",
          "Tuesday: 11:00 AM – 6:00 PM",
          "Wednesday: 11:00 AM – 6:00 PM",
          "Thursday: 11:00 AM – 6:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.cafeplatano.com/",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.269705, 37.8717944] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "590 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-1150",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264046, 37.856081] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "472 Center St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-0213",
      name: "T.J. Maxx",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1257045, 37.8588696] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2020 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-2210",
      name: "McCaulou's Department Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 4:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://mccaulous.com/",
      first_type: "department_store",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2096393, 37.8272177] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2187 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 982-3726",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "3.7",
      website: "https://www.target.com/sl/berkeley-central/3202",
      first_type: "department_store",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2676042, 37.8696588] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "250 Village Square, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-3448",
      name: "McCaulou's Department Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "http://www.mccaulous.com/",
      first_type: "department_store",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1907515, 37.88337] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3512 Mount Diablo Blvd #3814, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 283-3380",
      name: "McCaulou's Department Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 5:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://mccaulous.com/",
      first_type: "department_store",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1186938, 37.8933058] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2001 Allston Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 848-9622",
      name: "Berkeley YMCA",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 9:00 PM",
          "Tuesday: 5:00 AM – 9:00 PM",
          "Wednesday: 5:00 AM – 9:00 PM",
          "Thursday: 5:00 AM – 9:00 PM",
          "Friday: 5:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 5:00 PM",
          "Sunday: 7:00 AM – 5:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://www.berkeleyymca.org/",
      first_type: "gym",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.269707, 37.8692946] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4000 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 283-4000",
      name: "Oakwood Athletic Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.oakwoodathleticclub.com/",
      first_type: "spa",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.148986, 37.890264] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2600 Bancroft Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 848-6370",
      name: "YWCA Berkeley/Oakland",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "http://ywca-berkeley.org/",
      first_type: "gym",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2567367, 37.8688964] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "351 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 377-2400",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.24hourfitness.com/gyms/moraga-ca/moraga-rheem-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1264747, 37.8613217] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Orinda Way Suite 3, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 360-7051",
      name: "The Living Lean Program",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 1:00 PM",
          "Sunday: 10:00 AM – 1:00 PM",
        ],
      },
      rating: "5",
      website: "http://livingleanprogram.com/",
      first_type: "gym",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1857355, 37.8802639] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2567 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 204-9642",
      name: "Funky Door Yoga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:00 PM",
          "Tuesday: 5:30 AM – 9:00 PM",
          "Wednesday: 5:30 AM – 9:00 PM",
          "Thursday: 5:30 AM – 9:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 5:30 AM – 9:00 PM",
          "Sunday: 5:30 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://funkydoor.com/",
      first_type: "gym",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2669122, 37.8628004] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "50 Vashell Way #120, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 708-3279",
      name: "CoreKinetics",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 4, time: "0500" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 AM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: Closed",
          "Saturday: 8:30 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1814887, 37.8797673] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1666 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 486-0264",
      name: "Yoga Kula",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "4.8",
      website: "http://www.yogakula.com/",
      first_type: "gym",
      zip_code: "94709",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2691876, 37.877113699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2220 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 530-4000",
      name: "Montclair Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 8:00 PM",
          "Tuesday: 5:00 AM – 8:00 PM",
          "Wednesday: 5:00 AM – 8:00 PM",
          "Thursday: 5:00 AM – 8:00 PM",
          "Friday: 5:00 AM – 8:00 PM",
          "Saturday: 5:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://montclairfitness.com/",
      first_type: "gym",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2078487, 37.8232507] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "15 Altarinda Rd, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 317-2181",
      name: "Orinda Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 9:00 PM",
          "Tuesday: 5:00 AM – 9:00 PM",
          "Wednesday: 5:00 AM – 9:00 PM",
          "Thursday: 5:00 AM – 9:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 5:00 AM – 9:00 PM",
          "Sunday: 5:00 AM – 9:00 PM",
        ],
      },
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1839939, 37.8834622] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Northwood Dr, Orinda, CA 94563, USA",
      formatted_phone_number: "(510) 290-3428",
      name: "DiskIN Pilates Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 12:00 – 7:00 PM",
          "Thursday: 12:00 – 6:00 PM",
          "Friday: 12:00 – 5:00 PM",
          "Saturday: 10:00 AM – 3:00 PM",
          "Sunday: 10:00 AM – 3:00 PM",
        ],
      },
      rating: "5",
      website: "http://diskinpilatescenter.com/",
      first_type: "gym",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1804692, 37.8786791] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "99 Brookwood Rd, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 317-3302",
      name: "Atma Yoga",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1315" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1115" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:30 PM",
          "Tuesday: 9:30 AM – 6:30 PM",
          "Wednesday: 9:30 AM – 6:30 PM",
          "Thursday: 9:30 AM – 7:00 PM",
          "Friday: 9:30 AM – 1:15 PM",
          "Saturday: 9:00 – 11:15 AM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.atmayoga.com/",
      first_type: "gym",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1818891, 37.8769335] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3506 Mount Diablo Blvd Ste. E, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 900-5788",
      name: "Club Pilates",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 8:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 12:00 PM",
          "Saturday: 7:00 AM – 1:00 PM",
          "Sunday: 7:00 AM – 1:00 PM",
        ],
      },
      rating: "4.7",
      website:
        "https://www.clubpilates.com/lafayette/?utm_source=google.com&utm_medium=referral&utm_campaign=yext",
      first_type: "gym",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1179829, 37.8932782] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3517 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 299-9800",
      name: "Orangetheory Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1115" },
            open: { day: 0, time: "0725" },
          },
          {
            close: { day: 1, time: "1825" },
            open: { day: 1, time: "0445" },
          },
          {
            close: { day: 2, time: "1825" },
            open: { day: 2, time: "0445" },
          },
          {
            close: { day: 3, time: "1825" },
            open: { day: 3, time: "0445" },
          },
          {
            close: { day: 4, time: "1825" },
            open: { day: 4, time: "0445" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0445" },
          },
          {
            close: { day: 6, time: "1115" },
            open: { day: 6, time: "0725" },
          },
        ],
        weekday_text: [
          "Monday: 4:45 AM – 6:25 PM",
          "Tuesday: 4:45 AM – 6:25 PM",
          "Wednesday: 4:45 AM – 6:25 PM",
          "Thursday: 4:45 AM – 6:25 PM",
          "Friday: 4:45 AM – 1:30 PM",
          "Saturday: 7:25 – 11:15 AM",
          "Sunday: 7:25 – 11:15 AM",
        ],
      },
      rating: "4.9",
      website:
        "https://www.orangetheory.com/en-us/locations/california/lafayette/3517-mt-diablo-blvd/?utm_medium=seo&utm_source=gg&utm_term=tombras&utm_campaign=reputationdotcom--National",
      first_type: "gym",
      zip_code: "94549",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1174268, 37.891913099999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Clark Kerr Campus Bldg 25, Sport Lane 25, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-7796",
      name: "Cal Gymnastics Club",
      opening_hours: "missing",
      rating: "4.9",
      website: "missing",
      first_type: "gym",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2472255, 37.8654287] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "210 Stadium Rim Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 642-7796",
      name: "Stadium Fitness Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "https://recsports.berkeley.edu/stadium-fitness-center-hours/",
      first_type: "gym",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2515758, 37.870522599999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3474 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 310-6821",
      name: "Lamorinda Crossfit",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "0700" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 1, time: "1000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "0700" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 2, time: "1000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "0700" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 3, time: "1000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "0700" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 4, time: "1000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "0700" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 5, time: "1000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 – 7:00 AM, 9:00 – 10:00 AM, 5:00 – 8:00 PM",
          "Tuesday: 6:00 – 7:00 AM, 9:00 – 10:00 AM, 5:00 – 8:00 PM",
          "Wednesday: 6:00 – 7:00 AM, 9:00 – 10:00 AM, 5:00 – 8:00 PM",
          "Thursday: 6:00 – 7:00 AM, 9:00 – 10:00 AM, 5:00 – 8:00 PM",
          "Friday: 6:00 – 7:00 AM, 9:00 – 10:00 AM, 5:00 – 6:00 PM",
          "Saturday: 8:00 – 11:00 AM",
          "Sunday: 9:00 – 11:00 AM",
        ],
      },
      rating: "5",
      website: "http://lamorindacrossfit.com/",
      first_type: "gym",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1152652, 37.89276] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2064 Antioch Ct suite c, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 499-5559",
      name: "Montclair Village Pilates",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://montclairvillagepilates.com/",
      first_type: "gym",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.208992, 37.826349] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2036 Blake St, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 859-4431",
      name: "Grassroots CrossFit",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:00 PM",
          "Tuesday: 5:30 AM – 9:00 PM",
          "Wednesday: 5:30 AM – 9:00 PM",
          "Thursday: 5:30 AM – 9:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 9:00 AM – 1:00 PM",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "4.9",
      website: "http://www.grassrootscrossfit.com/",
      first_type: "gym",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2680654, 37.8628387] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3400 Mount Diablo Blvd Suite 11, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 956-9508",
      name: "Lamorinda Jiu Jitsu & Kickboxing",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:30 PM, 4:00 – 7:30 PM",
          "Tuesday: 6:00 AM – 12:30 PM, 4:00 – 7:30 PM",
          "Wednesday: 6:00 AM – 12:30 PM, 4:00 – 7:30 PM",
          "Thursday: 6:00 AM – 12:30 PM, 4:00 – 7:30 PM",
          "Friday: 9:00 AM – 12:30 PM, 4:00 – 7:30 PM",
          "Saturday: 9:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.lamorindajiujitsu.com/",
      first_type: "gym",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1098327, 37.8943367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Moraga Way suite 3, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-9060",
      name: "Orinda Theatre Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: 8:00 AM – 4:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94563",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1823306, 37.879117799999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(916) 580-2527",
      name: "Rheem Valley Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1249411, 37.85741] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3589-3599 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(877) 225-5337",
      name: "Lafayette Mercantile",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.sitecenters.com/properties/lafayette-mercantile",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1230395, 37.8910432] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "21 Lafayette Cir, Lafayette, CA 94549, USA",
      formatted_phone_number: "(877) 225-5337",
      name: "La Fiesta Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 4:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://lafiestasquare.com/",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1199781, 37.8914906] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3575 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "missing",
      name: "The Clocktower Lafayette",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 7:30 PM",
          "Tuesday: 9:30 AM – 7:30 PM",
          "Wednesday: 9:30 AM – 7:30 PM",
          "Thursday: 9:30 AM – 7:30 PM",
          "Friday: 9:30 AM – 7:30 PM",
          "Saturday: 9:30 AM – 7:30 PM",
          "Sunday: 9:30 AM – 7:30 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1217917, 37.8914309] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "missing",
      name: "Plaza Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1181782, 37.8929034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3483 Golden Gate Way, Lafayette, CA 94549, USA",
      formatted_phone_number: "(510) 504-1066",
      name: "Golden Gate Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "https://www.3483goldengateway.com/",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1157847, 37.8913235] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3477 Golden Gate Way, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 384-7242",
      name: "Lamorinda Jewish Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 5:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.chabadoflamorinda.com/",
      first_type: "shopping_mall",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1150086, 37.8915129] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2550 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Telegraph Channing Shops",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2569282, 37.8679555] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1508 Walnut St, Berkeley, CA 94709, USA",
      formatted_phone_number: "missing",
      name: "Walnut Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 8:00 PM",
          "Tuesday: 9:30 AM – 8:00 PM",
          "Wednesday: 9:30 AM – 8:00 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 8:00 PM",
          "Saturday: 9:30 AM – 8:00 PM",
          "Sunday: 9:30 AM – 8:00 PM",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2684195, 37.8799824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2115 Allston Way #1, Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Trumpetvine Court",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2672054, 37.8697435] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2170 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Downtown Berkeley BART Plaza",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1900" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1900" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1900" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1900" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1900" },
          },
          {
            close: { day: 1, time: "0000" },
            open: { day: 6, time: "1900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 PM – 12:00 AM",
          "Tuesday: 7:00 PM – 12:00 AM",
          "Wednesday: 7:00 PM – 12:00 AM",
          "Thursday: 7:00 PM – 12:00 AM",
          "Friday: 7:00 PM – 12:00 AM",
          "Saturday: 7:00 PM – 12:00 AM",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2683576, 37.8698617] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1922A Berkeley Way, Berkeley, CA 94703, USA",
      formatted_phone_number: "missing",
      name: "BERKELEY PLAZA",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 8:00 PM",
          "Tuesday: 9:30 AM – 8:00 PM",
          "Wednesday: 9:30 AM – 8:00 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 8:00 PM",
          "Saturday: 9:30 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94703",
    },
    geometry: { type: "Point", coordinates: [-122.2733725, 37.8726236] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1961 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-8600",
      name: "Montclair Veterinary Hospital",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "https://montclairvethospital.com/",
      first_type: "pharmacy",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2107543, 37.8281654] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2461 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(855) 554-2889",
      name: "Valor Compounding Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.7",
      website: "https://valorcompounding.com/",
      first_type: "pharmacy",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2671779, 37.8646986] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "282 Village Square, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-1211",
      name: "The Medicine Shoppe Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://orindamedicineshoppe.com/",
      first_type: "pharmacy",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1905593, 37.8831876] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "50 Moraga Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-4956",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.cvs.com/store-locator/orinda-ca-pharmacies/50-moraga-way-orinda-ca-94563/storeid=9939?WT.mc_id=LS_GOOGLE_RX_9939",
      first_type: "pharmacy",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1814677, 37.8784893] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3625 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 284-7121",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 2:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Saturday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 11:00 AM – 12:30 PM, 1:00 – 5:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.cvs.com/store-locator/lafayette-ca-pharmacies/3625-mt-diablo-blvd-lafayette-ca-94549/storeid=9938?WT.mc_id=LS_GOOGLE_RX_9938",
      first_type: "pharmacy",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1250139, 37.8906844] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "346 Rheem Blvd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 247-5602",
      name: "Rheem Specialty Pharmacy",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1294141, 37.8612862] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "580 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-0204",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.cvs.com/store-locator/moraga-ca-pharmacies/580-moraga-rd-moraga-ca-94556/storeid=9203?WT.mc_id=LS_GOOGLE_RX_9203",
      first_type: "pharmacy",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1260581, 37.856633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2655 Telegraph Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 549-9063",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "2.6",
      website:
        "https://www.cvs.com/store-locator/berkeley-ca-pharmacies/2655-telegraph-ave-berkeley-ca-94704/storeid=10121?WT.mc_id=LS_GOOGLE_RX_10121",
      first_type: "pharmacy",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2583598, 37.8620453] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2450 Ashby Ave rm 5505, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 204-1893",
      name: "Joann Dominguez, M.D., PharmD",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.sebmf.org/dr-joann-dominguez.html",
      first_type: "pharmacy",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2571526, 37.8554349] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6310 College Ave, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 985-0017",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/oakland/6310-college-ave.html",
      first_type: "pharmacy",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2521728, 37.8504925] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1451 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 849-0484",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 7:00 PM",
          "Saturday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 11:00 AM – 12:30 PM, 1:00 – 5:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.cvs.com/store-locator/berkeley-ca-pharmacies/1451-shattuck-ave-berkeley-ca-94709/storeid=9941?WT.mc_id=LS_GOOGLE_RX_9941",
      first_type: "pharmacy",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.269022, 37.8812815] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2000 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-8535",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 8:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "1.4",
      website:
        "https://www.cvs.com/store-locator/oakland-ca-pharmacies/2000-mountain-blvd-oakland-ca-94611/storeid=9929?WT.mc_id=LS_GOOGLE_RX_9929",
      first_type: "pharmacy",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2098581, 37.8272957] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1991 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-2215",
      name: "Rite Aid Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 9:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.riteaid.com/locations/ca/oakland/1991-mountain-boulevard.html",
      first_type: "pharmacy",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2104654, 37.8273645] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1444 Shattuck Pl, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 542-5242",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 7:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 7:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 7:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/berkeley/1444-shattuck-place.html",
      first_type: "pharmacy",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2700555, 37.8808006] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "27 Orinda Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 253-1904",
      name: "Rite Aid Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: Closed",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "5",
      website: "https://www.riteaid.com/locations/ca/orinda/27-orinda-way.html",
      first_type: "pharmacy",
      zip_code: "94563",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1894793, 37.882951299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "27 Orinda Way, Orinda, CA 945632538, USA",
      formatted_phone_number: "(925) 253-1904",
      name: "Terri Coleman",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.1894971, 37.8827889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "27 Orinda Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 253-1904",
      name: "Ramy Farhat Nehme",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1894971, 37.8827889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3540 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 284-1550",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/lafayette/3540-mt-diablo-blvd.html",
      first_type: "pharmacy",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1194287, 37.8929112] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7779 Claremont Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "missing",
      name: "Walgreens",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2341905, 37.8646149] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3625 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 284-7121",
      name: "Satinder Sheena",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1250139, 37.8906844] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Oakland, CA, USA",
      formatted_phone_number: "missing",
      name: "Oakland",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.oaklandnet.com/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.2711639, 37.8043514] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "41 Tunnel Rd, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 843-3000",
      name: "Claremont Club & Spa - A Fairmont Hotel",
      opening_hours: "missing",
      rating: "4.5",
      website:
        "https://www.fairmont.com/claremont-berkeley/?goto=fiche_hotel&code_hotel=A553&merchantid=seo-maps-US-A553&sourceid=aw-cen&utm_medium=seo%20maps&utm_source=google%20Maps&utm_campaign=seo%20maps",
      first_type: "lodging",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2420658, 37.8590352] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2600 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-8981",
      name: "Graduate Berkeley",
      opening_hours: "missing",
      rating: "4.1",
      website: "https://www.graduatehotels.com/berkeley",
      first_type: "lodging",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.256305, 37.867942] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2315 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 848-7800",
      name: "Berkeley City Club",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.5",
      website: "http://www.berkeleycityclub.com/",
      first_type: "lodging",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2627745, 37.8676008] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2740 Telegraph Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "missing",
      name: "Rose Garden Inn",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website: "missing",
      first_type: "lodging",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2595841, 37.8599417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2086 Allston Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 845-7300",
      name: "Hotel Shattuck Plaza",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://www.hotelshattuckplaza.com/?utm_source=google&utm_medium=organic&utm_campaign=business-listing",
      first_type: "cafe",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2684195, 37.869183199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2220 Piedmont Ave, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-7989",
      name: "Haas School of Business",
      opening_hours: "missing",
      rating: "4.8",
      website: "https://haas.berkeley.edu/",
      first_type: "point_of_interest",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2533495, 37.8716267] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3287 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(855) 382-8632",
      name: "Lafayette Park Hotel & Spa",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.5",
      website:
        "https://www.lafayetteparkhotel.com/?utm_source=google&utm_medium=bizlisting&utm_campaign=google_places",
      first_type: "lodging",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1012304, 37.8962807] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2680 Bancroft Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 549-1000",
      name: "Bancroft Hotel",
      opening_hours: "missing",
      rating: "4.2",
      website: "http://www.bancrofthotel.com/",
      first_type: "lodging",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2550974, 37.869083599999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2227 Piedmont Ave, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-2730",
      name: "California Memorial Stadium",
      opening_hours: "missing",
      rating: "4.6",
      website:
        "https://calbears.com/sports/2020/6/18/california-memorial-stadium.aspx",
      first_type: "stadium",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2507783, 37.8706571] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2501 Grizzly Peak Blvd, Orinda, CA 94563, USA",
      formatted_phone_number: "(510) 544-2747",
      name: "Tilden Regional Park",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 10:00 PM",
          "Saturday: 5:00 AM – 10:00 PM",
          "Sunday: 5:00 AM – 10:00 PM",
        ],
      },
      rating: "4.8",
      website: "http://www.ebparks.org/parks/tilden",
      first_type: "park",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.2452468, 37.8976908] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Centennial Dr, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-5132",
      name: "The Lawrence Hall of Science",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 5:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 5:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://lawrencehallofscience.org/",
      first_type: "tourist_attraction",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.246517, 37.879205] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "University Avenue and, Oxford St, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-6000",
      name: "University of California, Berkeley",
      opening_hours: "missing",
      rating: "3.5",
      website: "https://www.berkeley.edu/",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2594606, 37.870151] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2299 Piedmont Ave, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 643-9932",
      name: "edmonds cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://ihouse.berkeley.edu/dining/edmonds-caf%C3%A9",
      first_type: "cafe",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2520194, 37.869682] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "300 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 280-3950",
      name: "Campolindo High School",
      opening_hours: "missing",
      rating: "4.6",
      website: "https://www.acalanes.k12.ca.us/campolindo",
      first_type: "secondary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1261766, 37.8672417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 Centennial Dr, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 643-2755",
      name: "Julia Morgan Hall",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 5:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 5:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "4",
      website:
        "http://botanicalgarden.berkeley.edu/julia_morgan_hall#tab-1-0-overview",
      first_type: "university",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2383129, 37.8747632] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11 Camino Pablo, Orinda, CA 94563, USA",
      formatted_phone_number: "missing",
      name: "Orinda",
      opening_hours: "missing",
      rating: "4.3",
      website: "https://www.bart.gov/stations/orin",
      first_type: "train_station",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1836847, 37.8784774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 Golf Course Dr, Berkeley, CA 94708, USA",
      formatted_phone_number: "(510) 848-7373",
      name: "Tilden Park Golf Course",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 8:00 PM",
          "Tuesday: 6:30 AM – 8:00 PM",
          "Wednesday: 6:30 AM – 8:00 PM",
          "Thursday: 6:30 AM – 8:00 PM",
          "Friday: 6:00 AM – 8:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "4.1",
      website: "http://www.tildenparkgc.com/",
      first_type: "health",
      zip_code: "94708",
    },
    geometry: { type: "Point", coordinates: [-122.241865, 37.8896068] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Senior Hall, Berkeley, CA 94720, USA",
      formatted_phone_number: "(650) 722-1510",
      name: "Senior Hall",
      opening_hours: "missing",
      rating: "missing",
      website: "http://ogb.berkeley.edu/",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2553301, 37.8718875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Berkeley, CA, USA",
      formatted_phone_number: "missing",
      name: "Berkeley",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.ci.berkeley.ca.us/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.273042, 37.8715226] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5727 College Ave, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 923-9428",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://locations.traderjoes.com/ca/oakland/231/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2525292, 37.8455681] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1444 Shattuck Pl, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 526-3086",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      rating: "4",
      website:
        "https://local.safeway.com/safeway/ca/berkeley/1444-shattuck-place.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2700198, 37.8807907] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Telegraph Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 649-1333",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.wholefoodsmarket.com/stores/berkeley?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2602487, 37.8556066] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3502 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 284-5305",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.wholefoodsmarket.com/stores/lafayette?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.117205, 37.8933586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 University Ave, Berkeley, CA 94703, USA",
      formatted_phone_number: "(510) 204-9074",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://locations.traderjoes.com/ca/berkeley/186/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94703",
    },
    geometry: { type: "Point", coordinates: [-122.2732515, 37.8717488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2096 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 482-2266",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "3.9",
      website:
        "https://local.safeway.com/safeway/ca/oakland/2096-mountain-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2088816, 37.825454] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1963 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-4290",
      name: "Lucky Supermarket",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.luckysupermarkets.com/",
      first_type: "supermarket",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2109058, 37.8275406] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3068 Claremont Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 652-2490",
      name: "Star Grocery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://www.facebook.com/stargroceryberkeley/",
      first_type: "grocery_or_supermarket",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2481652, 37.8547255] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3540 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 283-0228",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website:
        "https://local.safeway.com/safeway/ca/lafayette/3540-mt-diablo-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1194644, 37.8928578] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2 Camino Sobrante, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-8792",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4",
      website:
        "https://local.safeway.com/safeway/ca/orinda/2-camino-sobrante.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1909871, 37.8838107] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3649 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 299-9344",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/lafayette/115/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.126966, 37.8902393] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3615 Mount Diablo Blvd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 283-0737",
      name: "Diablo Foods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "4.7",
      website: "http://www.diablofoods.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.124596, 37.890792] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1550 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 841-7942",
      name: "Andronico's Community Markets",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.andronicos.com/ca/berkeley/1550-shattuck-ave.html",
      first_type: "grocery_or_supermarket",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.269894, 37.878766] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6310 College Ave, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 985-0012",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 12:00 AM",
          "Tuesday: 5:00 AM – 12:00 AM",
          "Wednesday: 5:00 AM – 12:00 AM",
          "Thursday: 5:00 AM – 12:00 AM",
          "Friday: 5:00 AM – 12:00 AM",
          "Saturday: 5:00 AM – 12:00 AM",
          "Sunday: 5:00 AM – 12:00 AM",
        ],
      },
      rating: "4",
      website:
        "https://local.safeway.com/safeway/ca/oakland/6310-college-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2521213, 37.85058] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1355 Moraga Way, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-9492",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "3.9",
      website:
        "https://local.safeway.com/safeway/ca/moraga/1355-moraga-way.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1285358, 37.8362968] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2441 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 703-0314",
      name: "Shattuck Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2671005, 37.8650546] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2187 Shattuck Ave., Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 982-3726",
      name: "Target Grocery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.target.com/c/order-pickup/-/N-ng0a0?aisles_cid=5xt1a%2CGrocery%2C%2Fc%2Fgrocery%2F-%2FN-5xt1a",
      first_type: "grocery_or_supermarket",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2676042, 37.8696588] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6407 Telegraph Ave, Oakland, CA 94609, USA",
      formatted_phone_number: "(510) 658-7508",
      name: "ampm",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.4",
      website: "https://www.ampm.com/",
      first_type: "convenience_store",
      zip_code: "94609",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2608734, 37.850416300000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3298 Livermore Outlets Dr #605, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7024",
      name: "Wetzel's Pretzels",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 7:00 PM",
          "Tuesday: 11:00 AM – 7:00 PM",
          "Wednesday: 11:00 AM – 7:00 PM",
          "Thursday: 11:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.wetzels.com/",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8448198, 37.6989203] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3410 Livermore Outlets Dr Ste FC2, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7226",
      name: "Villa Italian Kitchen",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://locations.villaitaliankitchen.com/en-us/ca/livermore/3410-livermore-outlets-dr/",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8449949, 37.6985769] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3620 Fallon Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 452-1155",
      name: "BJ's Restaurant & Brewhouse",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 12:00 AM",
          "Tuesday: 11:00 AM – 12:00 AM",
          "Wednesday: 11:00 AM – 12:00 AM",
          "Thursday: 11:00 AM – 12:00 AM",
          "Friday: 11:00 AM – 12:00 AM",
          "Saturday: 11:00 AM – 12:00 AM",
          "Sunday: 11:00 AM – 12:00 AM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.bjsrestaurants.com/locations/ca/dublin?utm_source=google&utm_medium=organic&utm_campaign=google_my_business",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8515465, 37.7027216] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 S Gale Ridge Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 735-4253",
      name: "The Bridges Golf Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.thebridgesgolf.com/",
      first_type: "restaurant",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9340433, 37.7710003] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1350 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1311",
      name: "The Cheesecake Factory",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://locations.thecheesecakefactory.com/ca/pleasanton-109.html?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9290448, 37.6940889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7020 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-4361",
      name: "Chipotle Mexican Grill",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "1045" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "1045" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "1045" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "1045" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1045" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1045" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1045" },
          },
        ],
        weekday_text: [
          "Monday: 10:45 AM – 11:00 PM",
          "Tuesday: 10:45 AM – 11:00 PM",
          "Wednesday: 10:45 AM – 11:00 PM",
          "Thursday: 10:45 AM – 3:00 PM",
          "Friday: 10:45 AM – 11:00 PM",
          "Saturday: 10:45 AM – 11:00 PM",
          "Sunday: 10:45 AM – 11:00 PM",
        ],
      },
      rating: "3.3",
      website:
        "https://locations.chipotle.com/ca/dublin/7020-amador-plaza-rd?utm_source=google&utm_medium=yext&utm_campaign=yext_listings",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9291182, 37.706373199999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2882 Kitty Hawk Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 447-1224",
      name: "Cattlemens",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 10:00 PM",
          "Tuesday: 4:00 – 10:00 PM",
          "Wednesday: 4:00 – 10:00 PM",
          "Thursday: 4:00 – 10:00 PM",
          "Friday: 4:00 – 10:00 PM",
          "Saturday: 4:00 – 10:00 PM",
          "Sunday: 4:00 – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.cattlemens.com/liv.htm",
      first_type: "meal_takeaway",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8166413, 37.6992139] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5104 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-1800",
      name: "Tommy T's Comedy Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 12:00 – 10:00 PM",
          "Thursday: 12:00 – 10:00 PM",
          "Friday: 12:00 PM – 12:00 AM",
          "Saturday: 12:00 PM – 12:00 AM",
          "Sunday: 6:00 – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.tommyts.com/",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9054471, 37.6980549] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4930 Dublin Blvd Unit 680, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 248-2050",
      name: "Five Guys",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://restaurants.fiveguys.com/4930-dublin-blvd",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8851426, 37.7041918] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4555 Hopyard Rd C-23, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-0707",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 6:30 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://restaurants.subway.com/united-states/ca/pleasanton/4555-hopyard-road?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=2827&utm_campaign=evergreen-2020&y_source=1_MTQ4OTY2OTQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9024241, 37.6887815] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7995 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-5464",
      name: "Casa Orozco Dublin",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.casaorozco.com/",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.936245, 37.7060508] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "780 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-8438",
      name: "Oasis Restaurant & Wine Lounge",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.oasiswinelounge.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8742229, 37.6637824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6505 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-9335",
      name: "Outback Steakhouse",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://locations.outback.com/california/dublin/6505-regional-st.?utm_source=gmb&utm_medium=local_search&utm_campaign=website_cta&y_source=1_MTU1MDI1NDAtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9318782, 37.699737] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2106 First St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 371-6588",
      name: "First Street Alehouse",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 11:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.firststreetalehouse.com/",
      first_type: "bar",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.769966, 37.681498] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5900 Signal Hill Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-7040",
      name: "Dublin Ranch Golf Course",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "0615" },
          },
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0615" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0615" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0615" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0615" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0615" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0615" },
          },
        ],
        weekday_text: [
          "Monday: 6:15 AM – 5:30 PM",
          "Tuesday: 6:15 AM – 5:30 PM",
          "Wednesday: 6:15 AM – 5:30 PM",
          "Thursday: 6:15 AM – 5:30 PM",
          "Friday: 6:15 AM – 5:30 PM",
          "Saturday: 6:15 AM – 5:30 PM",
          "Sunday: 6:15 AM – 5:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.dublinranchgolf.com/",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8573991, 37.7251481] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "31187 Mission Blvd, Hayward, CA 94544, USA",
      formatted_phone_number: "(510) 441-1125",
      name: "Sonic Drive-In",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 6, time: "0030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 0, time: "0030" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 12:00 AM",
          "Tuesday: 9:00 AM – 12:00 AM",
          "Wednesday: 9:00 AM – 12:00 AM",
          "Thursday: 9:00 AM – 12:00 AM",
          "Friday: 9:00 AM – 12:30 AM",
          "Saturday: 9:00 AM – 12:30 AM",
          "Sunday: 9:00 AM – 12:00 AM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.sonicdrivein.com/locations/us/ca/hayward/31187-mission-boulevard/store-6170/?utm_source=google%20my%20business&utm_medium=organic&utm_campaign=website%20link",
      first_type: "store",
      zip_code: "31187",
    },
    geometry: { type: "Point", coordinates: [-122.0353589, 37.6191472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3434 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "China Max",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "2.5",
      website: "missing",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8450145, 37.6987191] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3402 Paragon, Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(352) 434-8828",
      name: "Japan Cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.premiumoutlets.com/outlet/san-francisco/stores/japan-cafe",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.845255, 37.6986287] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6058 Dougherty Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-6200",
      name: "Denica's Real Food Kitchen",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 2:00 PM",
          "Tuesday: 6:00 AM – 2:00 PM",
          "Wednesday: 6:00 AM – 2:00 PM",
          "Thursday: 6:00 AM – 2:00 PM",
          "Friday: 6:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.denicascafe.com/",
      first_type: "bakery",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9106422, 37.7076375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7448 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-0101",
      name: "Chuck E. Cheese",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://locations.chuckecheese.com/us/ca/dublin/7448-amador-valley-blvd.",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9294055, 37.7089743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4972 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 560-1495",
      name: "Barnes & Noble",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://stores.barnesandnoble.com/store/2942",
      first_type: "book_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8847613, 37.7055949] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3121 Crow Canyon Pl Suite C, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 901-1005",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=San%20Ramon,%20CA,%20USA&selected=Crow%20Canyon",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9696255, 37.7787572] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4555 Hopyard Rd Suite C-15, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0138",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/16549/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.902522, 37.689071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2505 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 831-9515",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 7:30 PM",
          "Tuesday: 5:30 AM – 7:30 PM",
          "Wednesday: 5:30 AM – 7:30 PM",
          "Thursday: 5:30 AM – 7:30 PM",
          "Friday: 5:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/14881",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9787211, 37.7742908] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7940 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-7520",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 9:00 PM",
          "Tuesday: 4:00 AM – 9:00 PM",
          "Wednesday: 4:00 AM – 9:00 PM",
          "Thursday: 4:00 AM – 9:00 PM",
          "Friday: 4:00 AM – 9:30 PM",
          "Saturday: 4:30 AM – 10:00 PM",
          "Sunday: 4:30 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/12125",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.933821, 37.702356] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "101 E Vineyard Ave, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 846-0259",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 7:00 PM",
          "Tuesday: 4:00 AM – 7:00 PM",
          "Wednesday: 4:00 AM – 7:00 PM",
          "Thursday: 4:00 AM – 7:00 PM",
          "Friday: 4:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/10037/",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8043506, 37.6506786] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5765 Valley Ave STE 100, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 401-0018",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Pleasanton,%20CA,%20USA&selected=Pleasanton",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.896658, 37.676606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1472 First St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 606-5663",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.starbucks.com/store-locator/store/12121/",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7780411, 37.6791464] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "166 Sunset Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 866-6628",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=San%20Ramon,%20CA,%20USA&selected=San%20Ramon",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9613012, 37.7627783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11000 Bollinger Canyon Rd Suite A, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 964-9195",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=San%20Ramon,%20CA,%20USA&selected=Bollinger%20Canyon",
      first_type: "cafe",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.9233691, 37.7740724] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Magnolia Square Shopping Center, 2005 Crow Canyon Pl, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 806-0870",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 6:00 PM",
          "Tuesday: 4:30 AM – 6:00 PM",
          "Wednesday: 4:30 AM – 6:00 PM",
          "Thursday: 4:30 AM – 6:00 PM",
          "Friday: 4:30 AM – 6:00 PM",
          "Saturday: 5:00 AM – 6:00 PM",
          "Sunday: 5:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/9129/",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.97233, 37.779934] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4710",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:00 AM – 7:00 PM",
          "Sunday: 5:00 AM – 7:00 PM",
        ],
      },
      rating: "3.7",
      website: "https://www.starbucks.com/store-locator/store/6699",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.874078, 37.706682] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "152 S Livermore Ave, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 579-5011",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 6:00 PM",
          "Tuesday: 5:00 AM – 6:00 PM",
          "Wednesday: 5:00 AM – 6:00 PM",
          "Thursday: 5:00 AM – 6:00 PM",
          "Friday: 5:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Livermore,%20CA,%20USA&selected=Livermore",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.767874, 37.681844] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7030 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-4401",
      name: "Panera Bread",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.panerabread.com/en-us/cafe/locations/ca/dublin/7030-amador-plaza-road?utm_medium=local&utm_source=google&utm_campaign=dpm-dist&utm_term=601121&utm_content=main",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9291908, 37.7064965] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2481 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 820-4325",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/SAN-RAMON/2481-SAN-RAMON-VALLEY-RD/6765.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9765951, 37.7743639] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2115 First St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 245-1700",
      name: "Panama Bay Coffee Co.",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7697456, 37.6811526] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3121 Crow Canyon Pl, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 275-2140",
      name: "Panera Bread",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.panerabread.com/en-us/cafe/locations/ca/san-ramon/3121-crow-canyon-place?utm_medium=local&utm_source=google&utm_campaign=dpm-dist&utm_term=601461&utm_content=main",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.969646, 37.778832] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "22095 Center St, Castro Valley, CA 94546, USA",
      formatted_phone_number: "(510) 886-9010",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.starbucks.com/store-locator/store/13387/",
      first_type: "cafe",
      zip_code: "22095",
    },
    geometry: { type: "Point", coordinates: [-122.0627072, 37.6900864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "K, 2217 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 838-8388",
      name: "Best Donuts",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 PM",
          "Tuesday: 5:00 AM – 2:00 PM",
          "Wednesday: 5:00 AM – 2:00 PM",
          "Thursday: 5:00 AM – 2:00 PM",
          "Friday: 5:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "4.6",
      website: "missing",
      first_type: "cafe",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9796076, 37.7810797] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "37501 Niles Blvd, Fremont, CA 94536, USA",
      formatted_phone_number: "(510) 790-0944",
      name: "Tyme For Tea & Co",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 11:00 AM – 3:00 PM",
          "Thursday: 11:00 AM – 3:00 PM",
          "Friday: 11:00 AM – 3:00 PM",
          "Saturday: 11:00 AM – 4:00 PM",
          "Sunday: 11:00 AM – 4:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://tymefortea.com/",
      first_type: "cafe",
      zip_code: "37501",
    },
    geometry: { type: "Point", coordinates: [-121.9792643, 37.5768672] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-3333",
      name: "Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.macys.com/stores/ca/pleasanton/stoneridge-shopping-center_333.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9297693, 37.6949838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-8744",
      name: "Walmart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.walmart.com/store/2161-pleasanton-ca",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8873959, 37.6982996] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3150 Fostoria Way, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 277-0407",
      name: "Costco Wholesale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.costco.com/warehouse-locations/danville-CA-21.html?utm_term=KW&utm_campaign=Homepage&utm_medium=GMB&utm_source=Google&utm_content=Link",
      first_type: "department_store",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9734384, 37.7826555] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6306",
      name: "Costco Wholesale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.costco.com/warehouse-locations/livermore-CA-146.html?utm_term=KW&utm_campaign=Homepage&utm_medium=GMB&utm_source=Google&utm_content=Link",
      first_type: "department_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8117112, 37.7026501] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7200 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8900",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 8:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.target.com/sl/dublin/328",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9318325, 37.7066014] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7892 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 875-1550",
      name: "Marshalls",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.marshalls.com/",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.932101, 37.7018677] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2610 Bishop Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 277-0202",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 11:00 PM",
          "Tuesday: 7:00 AM – 11:00 PM",
          "Wednesday: 7:00 AM – 11:00 PM",
          "Thursday: 7:00 AM – 11:00 PM",
          "Friday: 7:00 AM – 11:00 PM",
          "Saturday: 7:00 AM – 11:00 PM",
          "Sunday: 7:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.target.com/sl/san-ramon/949",
      first_type: "department_store",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9641968, 37.7621464] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9207",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/5610-san-ramon-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9294433, 37.729496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 241-1042",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 8:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.target.com/sl/dublin-east/2771",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8536969, 37.702983] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2900 Las Positas Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 243-0450",
      name: "Kohl's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.kohls.com/stores/ca/livermore-1121.shtml?utm_source=google&utm_medium=organic&utm_campaign=local",
      first_type: "department_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7653143, 37.7002357] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4525 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 924-1060",
      name: "Kohl's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.kohls.com/stores/ca/pleasanton-761.shtml?utm_source=google&utm_medium=organic&utm_campaign=local",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8866526, 37.6999976] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6900 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 875-0712",
      name: "Burlington",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2330" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 9:00 AM – 11:30 PM",
          "Saturday: 9:00 AM – 11:30 PM",
          "Sunday: 9:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://stores.burlington.com/ca/dublin/369/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "clothing_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9307067, 37.7049723] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7886 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-5995",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 9:30 PM",
          "Friday: 9:30 AM – 10:00 PM",
          "Saturday: 9:30 AM – 10:00 PM",
          "Sunday: 10:00 AM – 9:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9314005, 37.7020159] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3140 Fostoria Way, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 355-0223",
      name: "Marshalls",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.marshalls.com/",
      first_type: "department_store",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9740924, 37.782083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2700 Las Positas Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 455-0215",
      name: "Walmart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/1972-livermore-ca",
      first_type: "department_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7679977, 37.6995429] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4255 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-4800",
      name: "Macy's Furniture Gallery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.macys.com/stores/ca/pleasanton/rose-pavilion_343.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875051, 37.699641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4840 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-8440",
      name: "T.J. Maxx",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.88241, 37.704367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "120 Sunset Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 277-1308",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9622497, 37.7616043] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0827",
      name: "Sunglass Hut at Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/1300-stoneridge-mall-rd?cid=YEXT_4970&y_source=1_MTE0Mzc4My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9279165, 37.693924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4770 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1515",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.24hourfitness.com/gyms/pleasanton-ca/pleasanton-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8989944, 37.6979806] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4450 Norris Canyon Rd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 244-9855",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website:
        "https://www.24hourfitness.com/gyms/san-ramon-ca/san-ramon-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9697426, 37.7740072] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3839 E Castro Valley Blvd, Castro Valley, CA 94552, USA",
      formatted_phone_number: "(510) 733-6448",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://www.24hourfitness.com/gyms/castro-valley-ca/castro-valley-active?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94552",
    },
    geometry: { type: "Point", coordinates: [-122.0515801, 37.6944633] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2650 Kitty Hawk Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 447-4496",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website:
        "https://www.24hourfitness.com/gyms/livermore-ca/livermore-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8144956, 37.6993881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Bollinger Canyon Ln, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 735-8500",
      name: "Clubsport San Ramon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.clubsportsr.com/",
      first_type: "gym",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.944841, 37.7725847] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1538, 2021 Omega Rd #120, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 552-7958",
      name: "California Strength",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "http://www.californiastrength.com/",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.982413, 37.783751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6930 Village Pkwy ste e, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-9642",
      name: "Joya Dublin",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1030" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1800" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1800" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1030" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "2000" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1800" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 – 11:00 AM, 6:00 – 7:30 PM",
          "Tuesday: 9:00 – 11:00 AM, 6:00 – 7:30 PM",
          "Wednesday: 9:30 – 11:00 AM, 4:30 – 9:30 PM",
          "Thursday: 7:00 – 10:30 AM, 8:00 – 9:30 PM",
          "Friday: 9:30 – 11:00 AM, 6:00 – 7:30 PM",
          "Saturday: 9:00 – 10:30 AM, 4:00 – 5:30 PM",
          "Sunday: 9:00 – 10:30 AM, 4:00 – 5:30 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.joyayoga.com/",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9253436, 37.7070291] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7090 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2822",
      name: "Bay Club Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 10:00 PM",
          "Tuesday: 5:30 AM – 10:00 PM",
          "Wednesday: 5:30 AM – 10:00 PM",
          "Thursday: 5:30 AM – 10:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:30 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.bayclubs.com/pleasanton/?utm_source=Chatmeter&utm_medium=Google&utm_campaign=ClubPage&utm_content=PleasantonCH",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9190628, 37.6994725] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1279 Quarry Ln, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 967-3265",
      name: "Reform Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 8:00 – 10:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "https://hittitnow.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8651654, 37.675682699999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "225 Spring St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 600-1966",
      name: "Leisure Sports Hospitality",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.leisuresportsinc.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8736385, 37.6628177] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "31199 Mission Blvd, Hayward, CA 94544, USA",
      formatted_phone_number: "(510) 413-3919",
      name: "Fitness 19",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.fit19.com/locations/hayward?utm_campaign=Google%20Business%20Profile&utm_source=ppc&utm_medium=Hayward%20-%20SoCi%20GMB",
      first_type: "gym",
      zip_code: "31199",
    },
    geometry: { type: "Point", coordinates: [-122.0357103, 37.6180051] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2411 Old Crow Canyon Rd Suite J, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 820-6885",
      name: "Diablo Gymnastics School",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1230" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 12:30 – 7:00 PM",
          "Saturday: 9:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.1",
      website: "http://www.diablogym.net/",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.980752, 37.778972] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2411 Old Crow Canyon Rd Suite N & Suite O, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 457-4587",
      name: "CrossFit San Ramon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1030" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 7:30 PM",
          "Tuesday: 5:30 AM – 7:30 PM",
          "Wednesday: 5:30 AM – 7:30 PM",
          "Thursday: 5:30 AM – 7:30 PM",
          "Friday: 5:30 AM – 6:30 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: 8:30 – 10:30 AM",
        ],
      },
      rating: "5",
      website: "https://crossfitsr.com/",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9812577, 37.779097] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1601 Railroad Ave, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 549-1590",
      name: "Darcio Lira Brazilian Jiu Jitsu and Martial Arts",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 9:00 PM",
          "Tuesday: 11:00 AM – 12:30 PM, 5:00 – 9:00 PM",
          "Wednesday: 5:00 – 9:00 PM",
          "Thursday: 11:00 AM – 12:30 PM, 5:00 – 9:00 PM",
          "Friday: 5:00 – 9:00 PM",
          "Saturday: 9:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.darciolirajiujitsu.com/",
      first_type: "gym",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7764889, 37.680899] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4250 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 416-1100",
      name: "Vim + Vigor Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://vnvfit.com/locations/pleasanton",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.877163, 37.699057] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3201 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-7000",
      name: "Peace Lutheran Church",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1145" },
            open: { day: 0, time: "0945" },
          },
          {
            close: { day: 2, time: "0530" },
            open: { day: 2, time: "0030" },
          },
          {
            close: { day: 3, time: "0530" },
            open: { day: 3, time: "0030" },
          },
          {
            close: { day: 5, time: "0530" },
            open: { day: 5, time: "0030" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 12:30 – 5:30 AM",
          "Wednesday: 12:30 – 5:30 AM",
          "Thursday: Closed",
          "Friday: 12:30 – 5:30 AM",
          "Saturday: Closed",
          "Sunday: 9:45 – 11:45 AM",
        ],
      },
      rating: "5",
      website: "http://www.peacejourney.org/",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9275053, 37.8008067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5860 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 924-1110",
      name: "Studio 7 Fitness Pleasanton",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8967037, 37.6835109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7075 Commerce Cir # C, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(855) 479-3287",
      name: "P2P Transformation Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0430" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Tuesday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Wednesday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Thursday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Friday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Saturday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Sunday: 7:00 – 11:00 AM",
        ],
      },
      rating: "4.7",
      website: "https://p2ptransformationcenter.com/clubs/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.918242, 37.698613] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6690 Amador Plaza Rd #215, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 236-0011",
      name: "Art of Living Center For Yoga and Meditation",
      opening_hours: "missing",
      rating: "5",
      website: "https://www.artofliving.org/aw-en/node/8615",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9260609, 37.7021083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6690 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 456-4615",
      name: "WattsPTandPilates",
      opening_hours: "missing",
      rating: "5",
      website: "https://wattsptandpilates.com/",
      first_type: "physiotherapist",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.926061, 37.7021083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2774 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-2868",
      name: "San Francisco Premium Outlets",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.premiumoutlets.com/outlet/san-francisco",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8440481, 37.6987272] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2778",
      name: "Stoneridge Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.simon.com/mall/stoneridge-shopping-center",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9288449, 37.6952657] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4980 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(510) 460-6922",
      name: "Hacienda Crossings Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.hacienda-crossings.com/",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8852004, 37.704458] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "missing",
      name: "Country Club Village",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9298991, 37.7295737] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "777 Rincon Ave, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "Livermore Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7845916, 37.6881942] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2217 Stoneridge Mall Rd Spc C207, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-8636",
      name: "Sunglass Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/2217-stoneridge-mall-rd?cid=YEXT_4201&y_source=1_MTE0MjQyMS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.928741, 37.695824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0827",
      name: "Sunglass Hut at Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/1300-stoneridge-mall-rd?cid=YEXT_4970&y_source=1_MTE0Mzc4My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9279165, 37.693924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5130-5350 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Persimmon Place",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.persimmonplacedublin.com/",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8908339, 37.7053863] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2976 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 456-0502",
      name: "Sunglass Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://stores.sunglasshut.com/us/ca/livermore/2976-livermore-outlets-dr?cid=YEXT_6134&y_source=1_MTE0MzcxMi03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8460343, 37.6991378] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3505 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(408) 292-1601",
      name: "Republic Square at Livermore",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.4",
      website: "http://republicsquareatlivermore.com/",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8400049, 37.6991823] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "6000 Bollinger Canyon Rd Suite 2650, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 815-1902",
      name: "City Center Bishop Ranch",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 7:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "http://www.citycenterbishopranch.com/?utm_source=GBL&utm_medium=organic&utm_campaign=Local",
      first_type: "shopping_mall",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9590578, 37.763008] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Vintage Hills Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8560276, 37.6622117] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Tassajara Crossing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.regencycenters.com/property/detail/627/Tassajara-Crossing",
      first_type: "shopping_mall",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9185171, 37.7982336] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "31109 Mission Blvd, Hayward, CA 94544, USA",
      formatted_phone_number: "(510) 475-8896",
      name: "Fairway Park Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "31109",
    },
    geometry: { type: "Point", coordinates: [-122.0363717, 37.6193855] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5674 Sonoma Dr, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 734-0280",
      name: "Elk Park Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "missing",
      website: "http://elkparkshoppingcenter.com/contact/",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.879075, 37.647931699999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "417 Market Pl, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 277-3000",
      name: "The Marketplace Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website: "http://www.terramarcenters.com/",
      first_type: "shopping_mall",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9512563, 37.7640034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "150 Sunset Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 327-0473",
      name: "Shops At Bishop Ranch",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9610394, 37.7625532] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2820 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Fallon Gateway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8526627, 37.7039485] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4500 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(610) 215-7939",
      name: "Metro 580",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/metro580?utm_source=gmb&utm_medium=Yext",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8865938, 37.6996521] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4225 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(916) 619-5425",
      name: "Rose Pavilion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/rosepavilion",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8753625, 37.6983269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6783",
      name: "Costco Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "2.6",
      website:
        "https://www.costco.com/warehouse-locations/livermore-ca-146.html",
      first_type: "pharmacy",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8122662, 37.7026118] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6783",
      name: "May Mikhail, PhD",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8122662, 37.7026118] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7200 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 241-0000",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: Closed",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Saturday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.cvs.com/store-locator/dublin-ca-pharmacies/7200-amador-plaza-rd-dublin-ca-94568/storeid=16116?WT.mc_id=LS_GOOGLE_RX_16116",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.931845, 37.706742299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9217",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.walmart.com/store/5610-san-ramon-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9293619, 37.7292341] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2700 Las Positas Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 455-0116",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.walmart.com/store/1972-livermore-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7678069, 37.6991894] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2610 Bishop Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 867-0245",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: Closed",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.3",
      website:
        "https://www.cvs.com/store-locator/san-ramon-ca-pharmacies/2610-bishop-dr-san-ramon-ca-94583/storeid=16365?WT.mc_id=LS_GOOGLE_RX_16365",
      first_type: "pharmacy",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9641968, 37.7621464] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-9024",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 4:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.walmart.com/store/2161-pleasanton-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.887287, 37.698316] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-5914",
      name: "Natasha A. Ching, RN",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273686, 37.706388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-5914",
      name: "Arthur W. Alm Jr, BS",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273531, 37.7063699] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "20629 Redwood Rd, Castro Valley, CA 94546, USA",
      formatted_phone_number: "(510) 538-0268",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/castro-valley/20629-redwood-rd.html",
      first_type: "pharmacy",
      zip_code: "20629",
    },
    geometry: { type: "Point", coordinates: [-122.0750242, 37.6962757] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-5600",
      name: "Haritha Pingili, BS",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8725827, 37.7060831] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7601 Stoneridge Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 847-5500",
      name: "Kaiser Permanente Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 6:00 PM",
          "Tuesday: 8:30 AM – 6:00 PM",
          "Wednesday: 8:30 AM – 6:00 PM",
          "Thursday: 8:30 AM – 6:00 PM",
          "Friday: 8:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website:
        "https://thrive.kaiserpermanente.org/care-near-you/northern-california/diablo/departments/pharmacy/pharmacy-locations-and-hours-of-operation/",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9267652, 37.6908048] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Rite Aid Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 9:00 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "5",
      website:
        "https://www.riteaid.com/locations/ca/pleasanton/2819-hopyard-road.html",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8948191, 37.6759839] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7201 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-3823",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.cvs.com/store-locator/dublin-ca-pharmacies/7201-regional-st-dublin-ca-94568/storeid=3024?WT.mc_id=LS_GOOGLE_RX_3024",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9325259, 37.7050872] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3999 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8552",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Saturday: 9:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/3999-santa-rita-road-pleasanton-ca-94588/storeid=9251?WT.mc_id=LS_GOOGLE_RX_9251",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8759178, 37.6971875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 249-9011",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/6750-bernal-ave-pleasanton-ca-94566/storeid=10119?WT.mc_id=LS_GOOGLE_RX_10119",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.897972, 37.6565793] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 484-1960",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.7",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899566, 37.6560943] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-5600",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/dublin/4440-tassajara-rd.html",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8740515, 37.7068034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9120 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 829-9335",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.cvs.com/store-locator/san-ramon-ca-pharmacies/9120-alcosta-blvd-san-ramon-ca-94583/storeid=9348?WT.mc_id=LS_GOOGLE_RX_9348",
      first_type: "pharmacy",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9306894, 37.7286967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5420 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-7944",
      name: "Raley's Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.raleys.com/",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8785381, 37.65176] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Fremont, CA, USA",
      formatted_phone_number: "missing",
      name: "Fremont",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.988583, 37.5485396] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5115 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8800",
      name: "Four Points by Sheraton Pleasanton",
      opening_hours: "missing",
      rating: "3.8",
      website:
        "https://www.marriott.com/en-us/hotels/oakfp-four-points-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9028116, 37.6967602] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5535 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1212",
      name: "Larkspur Landing Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website:
        "https://www.larkspurhotels.com/pleasanton/?utm_source=google-gbp&utm_medium=organic&utm_campaign=gbp",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9065647, 37.6987105] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4950 Hacienda Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-9006",
      name: "Hyatt Place Dublin/Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.hyatt.com/en-US/hotel/california/hyatt-place-dublin-pleasanton/sfozb/?src=corp_lclb_gmb_seo_sfozb",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8873456, 37.7057541] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2600 Bishop Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 867-9200",
      name: "San Ramon Marriott",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.marriott.com/en-us/hotels/oaksr-san-ramon-marriott/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94583",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9652344, 37.762877399999986],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5059 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1414",
      name: "Courtyard Pleasanton",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.marriott.com/en-us/hotels/oakpl-courtyard-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9023404, 37.6940157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2801 Constitution Dr, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 292-2000",
      name: "Hilton Garden Inn Livermore",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.hilton.com/en/hotels/lvklhgi-hilton-garden-inn-livermore/?SEO_id=GMB-AMER-GI-LVKLHGI&y_source=1_MjA4MzQ1MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8139801, 37.7029327] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11950 Dublin Canyon Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 847-6000",
      name: "Pleasanton Marriott",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.marriott.com/en-us/hotels/oakmc-pleasanton-marriott/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "11950",
    },
    geometry: { type: "Point", coordinates: [-121.9353956, 37.6957527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6275 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 967-2750",
      name: "La Quinta Inn & Suites by Wyndham Dublin - Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.wyndhamhotels.com/laquinta/dublin-california/la-quinta-dublin-pleasanton/overview?CID=LC:6ysy27krtpcrqev:52796&iata=00093796",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9079749, 37.7061977] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1071 Market Pl, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 277-9292",
      name: "Residence Inn San Ramon",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.marriott.com/en-us/hotels/oakrs-residence-inn-san-ramon/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9539915, 37.7630776] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 961-9600",
      name: "Holiday Inn Express & Suites Livermore, an IHG Hotel",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.ihg.com/holidayinnexpress/hotels/us/en/livermore/lvmre/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-LVMRE",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8174074, 37.70403] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2774 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-2868",
      name: "San Francisco Premium Outlets",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.premiumoutlets.com/outlet/san-francisco",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8440481, 37.6987272] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11920 Dublin Canyon Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-0500",
      name: "Residence Inn Pleasanton",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.marriott.com/en-us/hotels/oakri-residence-inn-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "11920",
    },
    geometry: { type: "Point", coordinates: [-121.936522, 37.6969172] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "18090 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 866-2900",
      name: "Sonesta Select San Ramon",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.sonesta.com/sonesta-select/ca/san-ramon/sonesta-select-san-ramon",
      first_type: "lodging",
      zip_code: "18090",
    },
    geometry: { type: "Point", coordinates: [-121.9650701, 37.7577969] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6680 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-7750",
      name: "Holiday Inn Dublin-Pleasanton, an IHG Hotel",
      opening_hours: "missing",
      rating: "3.9",
      website:
        "https://www.ihg.com/holidayinn/hotels/us/en/dublin/dubrm/hoteldetail?cm_mmc=GoogleMaps-_-HI-_-US-_-DUBRM",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9329223, 37.7001724] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2929 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 243-1000",
      name: "Courtyard Livermore",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.marriott.com/en-us/hotels/oaklm-courtyard-livermore/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8151134, 37.703512] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5375 Owens Ct, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1300",
      name: "Best Western Plus Pleasanton Inn",
      opening_hours: "missing",
      rating: "4",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.05665.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:05665",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9050425, 37.7006895] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2850 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 606-6400",
      name: "Hampton Inn Livermore",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.hilton.com/en/hotels/lvkcahx-hampton-livermore/?SEO_id=GMB-AMER-HX-LVKCAHX&y_source=1_MjA4MzQ2Ny03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8166707, 37.7026503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2778",
      name: "Stoneridge Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.simon.com/mall/stoneridge-shopping-center",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9288449, 37.6952657] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Hayward, CA, USA",
      formatted_phone_number: "missing",
      name: "Hayward",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.hayward-ca.gov/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0809964, 37.6687665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4299 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-8899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://www.99ranch.com/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8733168, 37.6995615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7333 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-3999",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9331229, 37.7063676] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3903 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0763",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/pleasanton/66/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8768923, 37.6967871] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9207",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/5610-san-ramon-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9294433, 37.729496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1320 Railroad Ave, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 961-0019",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.groceryoutlet.com/circulars/storeid/35?utm_source=extnet&utm_medium=yext",
      first_type: "grocery_or_supermarket",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7800588, 37.680766] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "100 Sunset Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 355-9000",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.wholefoodsmarket.com/stores/ram?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9612642, 37.761762399999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4710",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/dublin/4440-tassajara-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.874053, 37.7066902] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11050 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 359-2001",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/san-ramon/11050-bollinger-canyon-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "11050",
    },
    geometry: { type: "Point", coordinates: [-121.922063, 37.774864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4100 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-5800",
      name: "Draeger's Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.draegers.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9187954, 37.8012097] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1122 A, E Stanley Blvd, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 243-1947",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/livermore/208/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7816937, 37.6803533] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2505 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 831-9515",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/san-ramon/2505-san-ramon-valley-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9768882, 37.7731996] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1701 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-5530",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/1701-santa-rita-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8744057, 37.6748422] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-8644",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899526, 37.6560809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "22224 Redwood Rd, Castro Valley, CA 94546, USA",
      formatted_phone_number: "(510) 538-2738",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/castro-valley/84/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "22224",
    },
    geometry: { type: "Point", coordinates: [-122.073003, 37.6853639] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1554 First St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 455-5667",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://local.safeway.com/safeway/ca/livermore/1554-first-st.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7771987, 37.6804056] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6783 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 999-8242",
      name: "Sahara Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.saharasupermarket.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.915141, 37.704493] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0298",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/danville/3496-camino-tassajara.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9177857, 37.7980836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5775 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 730-0101",
      name: "Smart & Final Extra!",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.smartandfinal.com/store",
      first_type: "grocery_or_supermarket",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9065079, 37.699497400000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-4034",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/dublin/7499-dublin-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273886, 37.7060094] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 S Gale Ridge Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 735-4253",
      name: "The Bridges Golf Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.thebridgesgolf.com/",
      first_type: "restaurant",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9340433, 37.7710003] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 964-9647",
      name: "Baja Fresh",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 3:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 8:00 PM",
          "Saturday: 11:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.bajafresh.com/stores/mexican-food-danville/30530?utm_source=gmb&utm_medium=gmb&utm_campaign=gmb&utm_id=gmb",
      first_type: "restaurant",
      zip_code: "11000",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9220933, 37.798456699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3540 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-4295",
      name: "The Grille at Blackhawk",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 8:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 10:30 AM – 9:00 PM",
          "Sunday: 10:30 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://thegrilleatblackhawk.com/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9199958, 37.7998747] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 Crow Canyon Rd A, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-1236",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 10:00 PM",
          "Tuesday: 9:00 AM – 10:00 PM",
          "Wednesday: 9:00 AM – 10:00 PM",
          "Thursday: 9:00 AM – 10:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://restaurants.subway.com/united-states/ca/danville/9000-crow-canyon-rd?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=12105&utm_campaign=evergreen-2020&y_source=1_MTQ5MTM0MjYtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9235778, 37.7986897] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11000 Bollinger Canyon Rd Suite D, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 964-9573",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 9:30 PM",
          "Tuesday: 8:30 AM – 9:30 PM",
          "Wednesday: 8:30 AM – 9:30 PM",
          "Thursday: 8:30 AM – 9:30 PM",
          "Friday: 8:30 AM – 9:30 PM",
          "Saturday: 8:30 AM – 9:30 PM",
          "Sunday: 8:30 AM – 9:30 PM",
        ],
      },
      rating: "2.5",
      website:
        "https://restaurants.subway.com/united-states/ca/san-ramon/11000-bollinger-canyon-road?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=32680&utm_campaign=evergreen-2020&y_source=1_MTQ5MTI0MzMtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.9234969, 37.7741123] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 Crow Canyon Rd H, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-1888",
      name: "Amazing Wok Chinese Cuisine",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Tuesday: Closed",
          "Wednesday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Saturday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
          "Sunday: 11:00 AM – 2:30 PM, 4:30 – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://amazingwok-danvilleca.com/",
      first_type: "meal_delivery",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9236319, 37.7982612] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-7838",
      name: "Blue Sakana Blackhawk",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1630" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1200" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 3:00 PM, 4:30 – 8:30 PM",
          "Tuesday: 12:00 – 3:00 PM, 4:30 – 8:30 PM",
          "Wednesday: 12:00 – 3:00 PM, 4:30 – 8:30 PM",
          "Thursday: 12:00 – 3:00 PM, 4:30 – 8:30 PM",
          "Friday: 12:00 – 3:00 PM, 4:30 – 9:30 PM",
          "Saturday: 12:00 – 3:00 PM, 4:30 – 9:30 PM",
          "Sunday: 12:00 – 3:00 PM, 4:30 – 8:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://bluesakanablackhawk.com/contact/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9193937, 37.7998102] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Tassajara Crossing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.regencycenters.com/property/detail/627/Tassajara-Crossing",
      first_type: "shopping_mall",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9185171, 37.7982336] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "500 Bollinger Canyon Way Ste A2, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 735-0114",
      name: "Domino's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 6, time: "0100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 0, time: "0100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 12:00 AM",
          "Tuesday: 10:00 AM – 12:00 AM",
          "Wednesday: 10:00 AM – 12:00 AM",
          "Thursday: 10:00 AM – 12:00 AM",
          "Friday: 10:00 AM – 1:00 AM",
          "Saturday: 10:00 AM – 1:00 AM",
          "Sunday: 10:00 AM – 12:00 AM",
        ],
      },
      rating: "3.3",
      website:
        "https://www.dominos.com/en/?utm_source=google&utm_medium=loclist&utm_campaign=localmaps",
      first_type: "meal_takeaway",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9481349, 37.7710951] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3446 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-1221",
      name: "China Paradise Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Thursday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Friday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Saturday: 11:30 AM – 2:30 PM, 4:00 – 8:30 PM",
          "Sunday: 4:00 – 8:30 PM",
        ],
      },
      rating: "4.4",
      website: "http://chinaparadiserestaurant.net/",
      first_type: "meal_delivery",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9197647, 37.7978568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3407 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 406-4744",
      name: "786 Kabob House",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://786kabobhouse.com/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9179117, 37.8007598] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 594-1740",
      name: "corporate cuisine",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Sunday: 5:00 – 7:00 PM",
        ],
      },
      rating: "missing",
      website: "https://www.corporatecuisineinc.com/contact/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.923103, 37.7978055] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4000 Blackhawk Plaza Cir A1, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-7662",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/6364/",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.919761, 37.800611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3414 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-4949",
      name: "Ascona Pizza Company",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://asconapizza.com/",
      first_type: "meal_takeaway",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.919712, 37.798521] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4050 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-6111",
      name: "Blackhawk Pizzeria",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:30 PM",
          "Saturday: 11:00 AM – 9:30 PM",
          "Sunday: 12:00 – 9:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://blackhawkpizzeria.com/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9192444, 37.8008084] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 Crow Canyon Rd K, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-1790",
      name: "Similan Thai Cuisine",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1700" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:00 – 9:00 PM",
          "Friday: 11:30 AM – 3:00 PM, 5:00 – 9:30 PM",
          "Saturday: 11:30 AM – 3:00 PM, 5:00 – 9:30 PM",
          "Sunday: 5:00 – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://similanthai.com/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9235001, 37.7981567] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3452 Camino Tassajara, Blackhawk, CA 94506, USA",
      formatted_phone_number: "(925) 736-7779",
      name: "High Tech Burrito - Blackhawk",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "http://hightechburrito.com/?y_source=1_Mjc4MDcwNTYtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9195994, 37.7978115] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-3977",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 6:00 PM",
          "Sunday: 5:30 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/9021/",
      first_type: "cafe",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.922071, 37.798801] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-8940",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/DANVILLE/10000-CROW-CANYON-RD/12306.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "10000",
    },
    geometry: { type: "Point", coordinates: [-121.9229592, 37.7989604] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11040 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 964-1328",
      name: "King Palace",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1530" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 3:30 – 9:00 PM",
          "Tuesday: 11:00 AM – 9:30 PM",
          "Wednesday: 11:00 AM – 9:30 PM",
          "Thursday: 11:00 AM – 9:30 PM",
          "Friday: 11:00 AM – 9:30 PM",
          "Saturday: 11:00 AM – 9:30 PM",
          "Sunday: 11:00 AM – 9:30 PM",
        ],
      },
      rating: "4",
      website: "https://www.kingpalaceca.com/",
      first_type: "restaurant",
      zip_code: "11040",
    },
    geometry: { type: "Point", coordinates: [-121.9231766, 37.7753721] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11000 Bollinger Canyon Rd Suite A, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 964-9195",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=San%20Ramon,%20CA,%20USA&selected=Bollinger%20Canyon",
      first_type: "cafe",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.9233691, 37.7740724] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3422 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0888",
      name: "Bagel Street Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 3:00 PM",
          "Tuesday: 6:00 AM – 3:00 PM",
          "Wednesday: 6:00 AM – 3:00 PM",
          "Thursday: 6:00 AM – 3:00 PM",
          "Friday: 6:00 AM – 3:00 PM",
          "Saturday: 7:00 AM – 2:00 PM",
          "Sunday: 7:00 AM – 2:00 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.920784, 37.7977601] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4000 Blackhawk Plaza Cir A1, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-7662",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/6364/",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.919761, 37.800611] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11060 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 359-2005",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 6:00 PM",
          "Tuesday: 6:00 AM – 6:00 PM",
          "Wednesday: 6:00 AM – 6:00 PM",
          "Thursday: 6:00 AM – 6:00 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.starbucks.com/store-locator/store/6771",
      first_type: "cafe",
      zip_code: "11060",
    },
    geometry: { type: "Point", coordinates: [-121.9219012, 37.7741693] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-3977",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 6:00 PM",
          "Sunday: 5:30 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/9021/",
      first_type: "cafe",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.922071, 37.798801] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-8940",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/DANVILLE/10000-CROW-CANYON-RD/12306.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "10000",
    },
    geometry: { type: "Point", coordinates: [-121.9229592, 37.7989604] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0298",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:00 AM – 7:00 PM",
          "Sunday: 5:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/12201",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.91776, 37.79821] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3456 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 644-2999",
      name: "Brown Butter",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 2:30 PM",
          "Tuesday: Closed",
          "Wednesday: 11:00 AM – 2:30 PM, 4:00 – 7:00 PM",
          "Thursday: 11:00 AM – 2:30 PM, 4:00 – 7:00 PM",
          "Friday: 11:00 AM – 2:30 PM, 4:00 – 7:00 PM",
          "Saturday: 11:00 AM – 2:30 PM, 4:00 – 7:00 PM",
          "Sunday: 11:00 AM – 2:30 PM, 4:00 – 7:00 PM",
        ],
      },
      rating: "4.7",
      website: "http://www.brownbutterbp.com/",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9184712, 37.8001393] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3450 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 217-4631",
      name: "Happy Lemon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 9:00 PM",
          "Tuesday: 12:00 – 9:00 PM",
          "Wednesday: 12:00 – 9:00 PM",
          "Thursday: 12:00 – 9:00 PM",
          "Friday: 12:00 – 9:00 PM",
          "Saturday: 12:00 – 9:00 PM",
          "Sunday: 12:00 – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "cafe",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9196612, 37.797842800000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11020 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 736-8638",
      name: "T4",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://t4sanramon.com/",
      first_type: "cafe",
      zip_code: "11020",
    },
    geometry: { type: "Point", coordinates: [-121.9239451, 37.7749613] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Bollinger Canyon Ln, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 735-8500",
      name: "Clubsport San Ramon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.clubsportsr.com/",
      first_type: "gym",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.944841, 37.7725847] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3201 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-7000",
      name: "Peace Lutheran Church",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1145" },
            open: { day: 0, time: "0945" },
          },
          {
            close: { day: 2, time: "0530" },
            open: { day: 2, time: "0030" },
          },
          {
            close: { day: 3, time: "0530" },
            open: { day: 3, time: "0030" },
          },
          {
            close: { day: 5, time: "0530" },
            open: { day: 5, time: "0030" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 12:30 – 5:30 AM",
          "Wednesday: 12:30 – 5:30 AM",
          "Thursday: Closed",
          "Friday: 12:30 – 5:30 AM",
          "Saturday: Closed",
          "Sunday: 9:45 – 11:45 AM",
        ],
      },
      rating: "5",
      website: "http://www.peacejourney.org/",
      first_type: "park",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9275053, 37.8008067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3494 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(833) 448-2561",
      name: "CorePower Yoga - Blackhawk",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.9",
      website:
        "https://www.corepoweryoga.com/yoga-studios/ca/east-bay/blackhawk",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9192203, 37.7998031] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3400 Blackhawk Plaza Cir Ste D126, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 967-2809",
      name: "OsteoStrong Danville Blackhawk",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 9:00 AM – 2:30 PM",
          "Wednesday: 9:00 AM – 2:30 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 2:30 PM",
          "Saturday: 8:00 AM – 2:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website: "http://www.osteostrong.me/",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.917688, 37.8005165] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3464 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 828-9642",
      name: "Joya Blackhawk",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "1530" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 8:00 PM",
          "Tuesday: 6:00 AM – 1:00 PM, 5:00 – 8:30 PM",
          "Wednesday: 6:00 AM – 12:00 PM, 5:00 – 9:30 PM",
          "Thursday: 6:00 AM – 12:30 PM, 5:00 – 9:00 PM",
          "Friday: 6:00 AM – 12:00 PM",
          "Saturday: 8:00 AM – 12:30 PM",
          "Sunday: 8:00 AM – 1:00 PM, 3:30 – 5:30 PM",
        ],
      },
      rating: "5",
      website: "https://www.joyayoga.com/",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9185, 37.800415] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3550 Blackhawk Plaza Cir, Blackhawk, CA 94506, USA",
      formatted_phone_number: "(857) 221-2254",
      name: "Real McCoy Pilates & Body Contouring",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0630" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 5:00 PM",
          "Tuesday: 5:00 AM – 5:00 PM",
          "Wednesday: 5:00 AM – 5:00 PM",
          "Thursday: 5:00 AM – 5:00 PM",
          "Friday: 5:00 AM – 5:00 PM",
          "Saturday: 6:30 AM – 2:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.realmccoypilates.com/",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9203193, 37.8000228] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3418 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 854-5500",
      name: "Club Pilates",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 8:30 PM",
          "Tuesday: 6:00 AM – 8:00 PM",
          "Wednesday: 6:30 AM – 8:00 PM",
          "Thursday: 6:00 AM – 8:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: 8:00 AM – 4:00 PM",
        ],
      },
      rating: "4.8",
      website:
        "https://www.clubpilates.com/danville/?utm_source=google.com&utm_medium=referral&utm_campaign=yext",
      first_type: "gym",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9214048, 37.7978434] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6086 Lakeview Cir, San Ramon, CA 94582, USA",
      formatted_phone_number: "(650) 773-1391",
      name: "Food Fitness Fashion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1230" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1230" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1230" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1230" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: 12:30 – 6:00 PM",
          "Friday: 12:30 – 6:00 PM",
          "Saturday: 12:30 – 6:00 PM",
          "Sunday: 12:30 – 6:00 PM",
        ],
      },
      rating: "5",
      website: "http://foodfitnessfashion.club/",
      first_type: "gym",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9462223, 37.7828682] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "265 Silver Lake Dr, Danville, CA 94526, USA",
      formatted_phone_number: "missing",
      name: "Crow canyon country club",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "gym",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9643855, 37.7927751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "The Foothills, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 302-9109",
      name: "Yoga, Tai Chi, & Health Coaching by Anne Cavazos",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9611386, 37.7780103] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Bollinger Canyon Ln, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 202-6574",
      name: "One Thru Five Basketball Academy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 3:00 – 8:00 PM",
          "Tuesday: 3:00 – 8:00 PM",
          "Wednesday: 3:00 – 8:00 PM",
          "Thursday: 3:00 – 8:00 PM",
          "Friday: 3:00 – 6:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://onethrufive.com/",
      first_type: "gym",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9445557, 37.7723356] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3470 Fostoria Way Suite B, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 759-8021",
      name: "ATP Fitness | Private Personal Training Gym",
      opening_hours: "missing",
      rating: "5",
      website: "http://www.atpfitnesssanramon.com/",
      first_type: "gym",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9672993, 37.7842789] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3420 Fostoria Way Ste. A203, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 683-6329",
      name: "Jazzercise",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1000" },
            open: { day: 0, time: "0645" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "1000" },
            open: { day: 1, time: "0615" },
          },
          {
            close: { day: 1, time: "1845" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "1000" },
            open: { day: 2, time: "0615" },
          },
          {
            close: { day: 2, time: "1845" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1000" },
            open: { day: 3, time: "0615" },
          },
          {
            close: { day: 3, time: "1845" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1000" },
            open: { day: 4, time: "0615" },
          },
          {
            close: { day: 4, time: "1845" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1000" },
            open: { day: 5, time: "0615" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0645" },
          },
        ],
        weekday_text: [
          "Monday: 6:15 – 10:00 AM, 4:30 – 6:45 PM",
          "Tuesday: 6:15 – 10:00 AM, 4:30 – 6:45 PM",
          "Wednesday: 6:15 – 10:00 AM, 4:30 – 6:45 PM",
          "Thursday: 6:15 – 10:00 AM, 4:30 – 6:45 PM",
          "Friday: 6:15 – 10:00 AM, 4:00 – 5:00 PM",
          "Saturday: 6:45 – 10:00 AM",
          "Sunday: 6:45 – 10:00 AM, 4:00 – 5:00 PM",
        ],
      },
      rating: "5",
      website:
        "https://www.jazzercise.com/location/jazzercise-san-ramon-fitness-center?utm_source=google&utm_medium=organic&utm_campaign=render_gmb",
      first_type: "gym",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9678866, 37.783776] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3420 Fostoria Way A-100, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 222-5101",
      name: "HELIX Performance Physical Therapy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 7:00 PM",
          "Saturday: 11:00 AM – 2:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.helixpt.com/",
      first_type: "gym",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9678096, 37.7835362] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Tassajara Crossing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.regencycenters.com/property/detail/627/Tassajara-Crossing",
      first_type: "shopping_mall",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9185171, 37.7982336] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3380 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-2751",
      name: "Blackhawk Plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: 8:00 AM – 5:00 PM",
          "Sunday: 8:00 AM – 5:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.shopblackhawkplaza.com/",
      first_type: "shopping_mall",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9187168, 37.80061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3435 Blackhawk Plaza Cir, Blackhawk, CA 94506, USA",
      formatted_phone_number: "missing",
      name: "Blackhawk Plaza Duck Pond",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9183974, 37.800512] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11000 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(800) 762-1641",
      name: "The Plaza at Gale Ranch",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.9229457, 37.7746636] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3420 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0262",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 2:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Saturday: 9:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
        ],
      },
      rating: "3.2",
      website:
        "https://www.cvs.com/store-locator/danville-ca-pharmacies/3420-camino-tassajara-danville-ca-94506/storeid=9296?WT.mc_id=LS_GOOGLE_RX_9296",
      first_type: "pharmacy",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9211191, 37.7977299] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11050 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 359-2005",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "3",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/san-ramon/11050-bollinger-canyon-rd.html",
      first_type: "pharmacy",
      zip_code: "11050",
    },
    geometry: { type: "Point", coordinates: [-121.9219085, 37.7747575] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0346",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/danville/3496-camino-tassajara.html",
      first_type: "pharmacy",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9178045, 37.7980709] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11050 Bollinger Canyon Rd, San Ramon, CA 945824959, USA",
      formatted_phone_number: "(925) 359-2005",
      name: "Parisa Vatanka",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "11050",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9220258, 37.774658499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11050 Bollinger Canyon Rd, San Ramon, CA 945824959, USA",
      formatted_phone_number: "(925) 359-2005",
      name: "Michelle Banaga",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "11050",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9220258, 37.774658499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11050 Bollinger Canyon Rd, San Ramon, CA 945824959, USA",
      formatted_phone_number: "(925) 359-2005",
      name: "Anh Ha",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "11050",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9220258, 37.774658499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "San Ramon, CA, USA",
      formatted_phone_number: "missing",
      name: "San Ramon",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.ci.san-ramon.ca.us/srhistory/history.htm",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9539616, 37.7643595] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2100 Mt Diablo Scenic Blvd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 837-5375",
      name: "The Athenian School",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0810" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0810" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0810" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0810" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0810" },
          },
        ],
        weekday_text: [
          "Monday: 8:10 AM – 3:30 PM",
          "Tuesday: 8:10 AM – 3:30 PM",
          "Wednesday: 8:10 AM – 3:30 PM",
          "Thursday: 8:10 AM – 3:30 PM",
          "Friday: 8:10 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.athenian.org/",
      first_type: "secondary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9501298, 37.8347843] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "711 Silver Lake Dr, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 735-5700",
      name: "Bay Club Crow Canyon",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 9:30 PM",
          "Tuesday: 5:30 AM – 9:30 PM",
          "Wednesday: 5:30 AM – 9:30 PM",
          "Thursday: 5:30 AM – 9:30 PM",
          "Friday: 5:30 AM – 9:30 PM",
          "Saturday: 5:30 AM – 9:30 PM",
          "Sunday: 5:30 AM – 9:30 PM",
        ],
      },
      rating: "4.4",
      website:
        "https://www.bayclubs.com/crowcanyon/?utm_source=Chatmeter&utm_medium=Google&utm_campaign=ClubPage&utm_content=CrowCanyonCH",
      first_type: "point_of_interest",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9599186, 37.7885168] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10550 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-6400",
      name: "Dougherty Valley High School",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 4:00 PM",
          "Tuesday: 7:45 AM – 4:00 PM",
          "Wednesday: 7:45 AM – 4:00 PM",
          "Thursday: 7:45 AM – 4:00 PM",
          "Friday: 7:45 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.5",
      website: "https://dvhs.srvusd.net/",
      first_type: "secondary_school",
      zip_code: "10550",
    },
    geometry: { type: "Point", coordinates: [-121.9023247, 37.7692188] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 S Gale Ridge Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 735-4253",
      name: "The Bridges Golf Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.thebridgesgolf.com/",
      first_type: "restaurant",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9340433, 37.7710003] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11040 Bollinger Canyon Rd Ste. E, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 648-9990",
      name: "The UPS Store",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 11:00 AM – 4:00 PM",
        ],
      },
      rating: "3.1",
      website:
        "https://locations.theupsstore.com/ca/san-ramon/11040-bollinger-canyon-rd?utm_source=Yext&utm_medium=organic&utm_campaign=Listings",
      first_type: "finance",
      zip_code: "11040",
    },
    geometry: { type: "Point", coordinates: [-121.922739, 37.775284] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11000 Crow Canyon Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 964-9647",
      name: "Baja Fresh",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 3:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 8:00 PM",
          "Saturday: 11:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.bajafresh.com/stores/mexican-food-danville/30530?utm_source=gmb&utm_medium=gmb&utm_campaign=gmb&utm_id=gmb",
      first_type: "restaurant",
      zip_code: "11000",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9220933, 37.798456699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3540 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-4295",
      name: "The Grille at Blackhawk",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:30 AM – 8:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 10:30 AM – 9:00 PM",
          "Sunday: 10:30 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://thegrilleatblackhawk.com/",
      first_type: "restaurant",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9199958, 37.7998747] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir # 265, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-7144",
      name: "Michael Schierman, MD",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website:
        "https://www.johnmuirhealth.com/doctor/Michael-Schierman-MD/1427128131?cid=orgloc%3AwebsiteURL%3Ayext&y_source=1_NDEyMjUxNS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "doctor",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9167395, 37.799079600000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9000 Crow Canyon Rd A, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-1236",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 10:00 PM",
          "Tuesday: 9:00 AM – 10:00 PM",
          "Wednesday: 9:00 AM – 10:00 PM",
          "Thursday: 9:00 AM – 10:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://restaurants.subway.com/united-states/ca/danville/9000-crow-canyon-rd?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=12105&utm_campaign=evergreen-2020&y_source=1_MTQ5MTM0MjYtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9235778, 37.7986897] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4100 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-5800",
      name: "Draeger's Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.draegers.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9187954, 37.8012097] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4165 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-7070",
      name: "Humphrey Jonathan B C",
      opening_hours: "missing",
      rating: "5",
      website: "http://www.blackhawkmedicalgroup.com/",
      first_type: "doctor",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.916707, 37.799101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir STE 150, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0401",
      name: "Bunkis Juris MD",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.plasticsurgery1.com/",
      first_type: "doctor",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.916707, 37.799101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir #200, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 732-5516",
      name: "RK Dentistry, Richard J. Koeltl, DDS",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:30 PM",
          "Tuesday: 7:30 AM – 4:30 PM",
          "Wednesday: 7:30 AM – 4:30 PM",
          "Thursday: 7:30 AM – 4:30 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "https://www.rkdentistry.com/",
      first_type: "dentist",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.916707, 37.799101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6400 Main Branch Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-1500",
      name: "Gale Ranch Middle School",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1445" },
            open: { day: 1, time: "0815" },
          },
          {
            close: { day: 2, time: "1445" },
            open: { day: 2, time: "0815" },
          },
          {
            close: { day: 3, time: "1445" },
            open: { day: 3, time: "0920" },
          },
          {
            close: { day: 4, time: "1445" },
            open: { day: 4, time: "0815" },
          },
          {
            close: { day: 5, time: "1445" },
            open: { day: 5, time: "0815" },
          },
        ],
        weekday_text: [
          "Monday: 8:15 AM – 2:45 PM",
          "Tuesday: 8:15 AM – 2:45 PM",
          "Wednesday: 9:20 AM – 2:45 PM",
          "Thursday: 8:15 AM – 2:45 PM",
          "Friday: 8:15 AM – 2:45 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.6",
      website: "https://grms.srvusd.net/",
      first_type: "school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9112933, 37.7691261] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir # 265, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-7144",
      name: "Christine Chang, MD",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "3.3",
      website:
        "https://www.johnmuirhealth.com/doctor/Christine-Chang-MD/1770696056?cid=orgloc%3AwebsiteURL%3Ayext&y_source=1_NDEyMjQxOC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "doctor",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9167423, 37.7990755] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3820 Blackhawk Rd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-3800",
      name: "Blackhawk Services",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "general_contractor",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9230187, 37.800595] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir STE 100, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-7070",
      name: "Blackhawk Medical Center: Monsefan Awisa R",
      opening_hours: "missing",
      rating: "missing",
      website: "https://www.blackhawkmedicalgroup.com/",
      first_type: "health",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.916707, 37.799101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4165 Blackhawk Plaza Cir STE 100, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-7070",
      name: "Julie A. Laguardia, FNP",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.blackhawkmedicalgroup.com/",
      first_type: "point_of_interest",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.916707, 37.799101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Danville, CA, USA",
      formatted_phone_number: "missing",
      name: "Danville",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.9999606, 37.8215929] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4100 Blackhawk Plaza Cir, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 648-5800",
      name: "Draeger's Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 8:00 PM",
          "Tuesday: 7:00 AM – 8:00 PM",
          "Wednesday: 7:00 AM – 8:00 PM",
          "Thursday: 7:00 AM – 8:00 PM",
          "Friday: 7:00 AM – 8:00 PM",
          "Saturday: 7:00 AM – 8:00 PM",
          "Sunday: 7:00 AM – 8:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.draegers.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9187954, 37.8012097] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11050 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 359-2001",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/san-ramon/11050-bollinger-canyon-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "11050",
    },
    geometry: { type: "Point", coordinates: [-121.922063, 37.774864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3496 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 736-0298",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/danville/3496-camino-tassajara.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9177857, 37.7980836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3298 Livermore Outlets Dr #605, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7024",
      name: "Wetzel's Pretzels",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 7:00 PM",
          "Tuesday: 11:00 AM – 7:00 PM",
          "Wednesday: 11:00 AM – 7:00 PM",
          "Thursday: 11:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.wetzels.com/",
      first_type: "bakery",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8448198, 37.6989203] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3410 Livermore Outlets Dr Ste FC2, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7226",
      name: "Villa Italian Kitchen",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.5",
      website:
        "https://locations.villaitaliankitchen.com/en-us/ca/livermore/3410-livermore-outlets-dr/",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8449949, 37.6985769] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3620 Fallon Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 452-1155",
      name: "BJ's Restaurant & Brewhouse",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 12:00 AM",
          "Tuesday: 11:00 AM – 12:00 AM",
          "Wednesday: 11:00 AM – 12:00 AM",
          "Thursday: 11:00 AM – 12:00 AM",
          "Friday: 11:00 AM – 12:00 AM",
          "Saturday: 11:00 AM – 12:00 AM",
          "Sunday: 11:00 AM – 12:00 AM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.bjsrestaurants.com/locations/ca/dublin?utm_source=google&utm_medium=organic&utm_campaign=google_my_business",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8515465, 37.7027216] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1350 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1311",
      name: "The Cheesecake Factory",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://locations.thecheesecakefactory.com/ca/pleasanton-109.html?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9290448, 37.6940889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2882 Kitty Hawk Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 447-1224",
      name: "Cattlemens",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 10:00 PM",
          "Tuesday: 4:00 – 10:00 PM",
          "Wednesday: 4:00 – 10:00 PM",
          "Thursday: 4:00 – 10:00 PM",
          "Friday: 4:00 – 10:00 PM",
          "Saturday: 4:00 – 10:00 PM",
          "Sunday: 4:00 – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.cattlemens.com/liv.htm",
      first_type: "meal_takeaway",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8166413, 37.6992139] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4930 Dublin Blvd Unit 680, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 248-2050",
      name: "Five Guys",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://restaurants.fiveguys.com/4930-dublin-blvd",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8851426, 37.7041918] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5104 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-1800",
      name: "Tommy T's Comedy Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 12:00 – 10:00 PM",
          "Thursday: 12:00 – 10:00 PM",
          "Friday: 12:00 PM – 12:00 AM",
          "Saturday: 12:00 PM – 12:00 AM",
          "Sunday: 6:00 – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.tommyts.com/",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9054471, 37.6980549] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7020 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-4361",
      name: "Chipotle Mexican Grill",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "1045" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "1045" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "1045" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "1045" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1045" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1045" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1045" },
          },
        ],
        weekday_text: [
          "Monday: 10:45 AM – 11:00 PM",
          "Tuesday: 10:45 AM – 11:00 PM",
          "Wednesday: 10:45 AM – 11:00 PM",
          "Thursday: 10:45 AM – 3:00 PM",
          "Friday: 10:45 AM – 11:00 PM",
          "Saturday: 10:45 AM – 11:00 PM",
          "Sunday: 10:45 AM – 11:00 PM",
        ],
      },
      rating: "3.3",
      website:
        "https://locations.chipotle.com/ca/dublin/7020-amador-plaza-rd?utm_source=google&utm_medium=yext&utm_campaign=yext_listings",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9291182, 37.706373199999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4555 Hopyard Rd C-23, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-0707",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 6:30 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://restaurants.subway.com/united-states/ca/pleasanton/4555-hopyard-road?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=2827&utm_campaign=evergreen-2020&y_source=1_MTQ4OTY2OTQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9024241, 37.6887815] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5900 Signal Hill Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-7040",
      name: "Dublin Ranch Golf Course",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "0615" },
          },
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0615" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0615" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0615" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0615" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0615" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0615" },
          },
        ],
        weekday_text: [
          "Monday: 6:15 AM – 5:30 PM",
          "Tuesday: 6:15 AM – 5:30 PM",
          "Wednesday: 6:15 AM – 5:30 PM",
          "Thursday: 6:15 AM – 5:30 PM",
          "Friday: 6:15 AM – 5:30 PM",
          "Saturday: 6:15 AM – 5:30 PM",
          "Sunday: 6:15 AM – 5:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.dublinranchgolf.com/",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8573991, 37.7251481] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3434 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "China Max",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "2.5",
      website: "missing",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8450145, 37.6987191] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3402 Paragon, Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(352) 434-8828",
      name: "Japan Cafe",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.premiumoutlets.com/outlet/san-francisco/stores/japan-cafe",
      first_type: "restaurant",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.845255, 37.6986287] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6058 Dougherty Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-6200",
      name: "Denica's Real Food Kitchen",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 2:00 PM",
          "Tuesday: 6:00 AM – 2:00 PM",
          "Wednesday: 6:00 AM – 2:00 PM",
          "Thursday: 6:00 AM – 2:00 PM",
          "Friday: 6:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.denicascafe.com/",
      first_type: "bakery",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9106422, 37.7076375] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7995 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-5464",
      name: "Casa Orozco Dublin",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.casaorozco.com/",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.936245, 37.7060508] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "780 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-8438",
      name: "Oasis Restaurant & Wine Lounge",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.oasiswinelounge.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8742229, 37.6637824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6505 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-9335",
      name: "Outback Steakhouse",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 10:00 PM",
          "Tuesday: 11:00 AM – 10:00 PM",
          "Wednesday: 11:00 AM – 10:00 PM",
          "Thursday: 11:00 AM – 10:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 11:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://locations.outback.com/california/dublin/6505-regional-st.?utm_source=gmb&utm_medium=local_search&utm_campaign=website_cta&y_source=1_MTU1MDI1NDAtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9318782, 37.699737] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7448 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-0101",
      name: "Chuck E. Cheese",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 8:00 PM",
          "Wednesday: 11:00 AM – 8:00 PM",
          "Thursday: 11:00 AM – 8:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://locations.chuckecheese.com/us/ca/dublin/7448-amador-valley-blvd.",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9294055, 37.7089743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6000 Dougherty Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-0696",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "3.2",
      website:
        "https://restaurants.subway.com/united-states/ca/dublin/6000-dougherty-rd?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=23318&utm_campaign=evergreen-2020&y_source=1_MTQ5MTE1MTktNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9110141, 37.7067761] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3712 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 361-0447",
      name: "Buffalo Wild Wings",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 11:00 PM",
          "Tuesday: 11:00 AM – 11:00 PM",
          "Wednesday: 11:00 AM – 11:00 PM",
          "Thursday: 11:00 AM – 11:00 PM",
          "Friday: 11:00 AM – 11:00 PM",
          "Saturday: 11:00 AM – 11:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.buffalowildwings.com/locations/us/ca/dublin/3712-dublin-blvd/sports-bar-1383?utm_source=google%20my%20business&utm_medium=organic&utm_campaign=website%20link",
      first_type: "restaurant",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8661186, 37.7050412] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "21001 San Ramon Valley Blvd Suite E-2, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 875-9666",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 6:30 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://restaurants.subway.com/united-states/ca/san-ramon/21001-san-ramon-valley-blvd?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=26254&utm_campaign=evergreen-2020&y_source=1_MTQ5MDI5ODEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "21001",
    },
    geometry: { type: "Point", coordinates: [-121.9429319, 37.7237201] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4972 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 560-1495",
      name: "Barnes & Noble",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 8:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://stores.barnesandnoble.com/store/2942",
      first_type: "book_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8847613, 37.7055949] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4555 Hopyard Rd Suite C-15, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0138",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/16549/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.902522, 37.689071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7940 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-7520",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 9:00 PM",
          "Tuesday: 4:00 AM – 9:00 PM",
          "Wednesday: 4:00 AM – 9:00 PM",
          "Thursday: 4:00 AM – 9:00 PM",
          "Friday: 4:00 AM – 9:30 PM",
          "Saturday: 4:30 AM – 10:00 PM",
          "Sunday: 4:30 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/12125",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.933821, 37.702356] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5765 Valley Ave STE 100, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 401-0018",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Pleasanton,%20CA,%20USA&selected=Pleasanton",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.896658, 37.676606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4710",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:00 AM – 7:00 PM",
          "Sunday: 5:00 AM – 7:00 PM",
        ],
      },
      rating: "3.7",
      website: "https://www.starbucks.com/store-locator/store/6699",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.874078, 37.706682] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7030 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-4401",
      name: "Panera Bread",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 7:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.panerabread.com/en-us/cafe/locations/ca/dublin/7030-amador-plaza-road?utm_medium=local&utm_source=google&utm_campaign=dpm-dist&utm_term=601121&utm_content=main",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9291908, 37.7064965] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "11000 Bollinger Canyon Rd Suite A, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 964-9195",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=San%20Ramon,%20CA,%20USA&selected=Bollinger%20Canyon",
      first_type: "cafe",
      zip_code: "11000",
    },
    geometry: { type: "Point", coordinates: [-121.9233691, 37.7740724] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1737 Santa Rita Rd Ste 400, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 485-1921",
      name: "Noah's NY Bagels",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 PM",
          "Tuesday: 5:00 AM – 2:00 PM",
          "Wednesday: 5:00 AM – 2:00 PM",
          "Thursday: 5:00 AM – 2:00 PM",
          "Friday: 5:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://locations.noahs.com/us/ca/pleasanton/1737-santa-rita-rd?y_source=1_ODk4NTc2My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8739182, 37.6752166] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7741 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-1414",
      name: "Erik's DeliCafé",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://eriksdelicafe.com/location/dublin-shamrock-village-california-deli-cafe/",
      first_type: "meal_takeaway",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9341573, 37.707400899999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "883 Airway Boulevard, Airway Blvd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 960-9709",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 6:00 PM",
          "Tuesday: 4:00 AM – 6:00 PM",
          "Wednesday: 4:00 AM – 6:00 PM",
          "Thursday: 4:00 AM – 6:00 PM",
          "Friday: 4:00 AM – 6:00 PM",
          "Saturday: 4:30 AM – 6:00 PM",
          "Sunday: 4:30 AM – 6:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/9599/",
      first_type: "cafe",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8175307, 37.6992457] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5140 Hacienda Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 398-1267",
      name: "Ross Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 1:30 PM",
          "Tuesday: 7:00 AM – 1:30 PM",
          "Wednesday: 7:00 AM – 1:30 PM",
          "Thursday: 7:00 AM – 1:30 PM",
          "Friday: 7:00 AM – 1:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "https://www.rosscafe.com/",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.889365, 37.7109728] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Dublin Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 241-1042",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 8:00 PM",
          "Tuesday: 8:00 AM – 8:00 PM",
          "Wednesday: 8:00 AM – 8:00 PM",
          "Thursday: 8:00 AM – 8:00 PM",
          "Friday: 8:00 AM – 8:00 PM",
          "Saturday: 8:00 AM – 8:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "3.7",
      website: "https://www.starbucks.com/store-locator/store/68320",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8537349, 37.702732700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3662 Fallon Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-1924",
      name: "Panera Bread",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 8:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://www.panerabread.com/en-us/cafe/locations/ca/dublin/3662-fallon-road?utm_medium=local&utm_source=google&utm_campaign=dpm-dist&utm_term=601770&utm_content=main",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8514041, 37.7031669] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4283 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(510) 340-8971",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 7:30 PM",
          "Tuesday: 4:00 AM – 7:30 PM",
          "Wednesday: 4:00 AM – 7:30 PM",
          "Thursday: 4:00 AM – 7:30 PM",
          "Friday: 4:00 AM – 7:30 PM",
          "Saturday: 4:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/1008983",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.872972, 37.698959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5138 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 785-4708",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0430" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0400" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 8:00 PM",
          "Tuesday: 4:00 AM – 8:00 PM",
          "Wednesday: 4:00 AM – 8:00 PM",
          "Thursday: 4:00 AM – 8:00 PM",
          "Friday: 4:00 AM – 9:30 PM",
          "Saturday: 4:00 AM – 10:00 PM",
          "Sunday: 4:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/1010050",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.888854, 37.7056066] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5200 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 452-2226",
      name: "WFM Coffee Bar",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      rating: "3",
      website:
        "http://www.wholefoodsmarket.com/stores/dublin-ca?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "cafe",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.889848, 37.7057581] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4432 Rosewood Dr #925, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 368-0595",
      name: "Thrive Cafe at Rosewood Commons",
      opening_hours: "missing",
      rating: "3.6",
      website: "missing",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8824125, 37.6978496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2792 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7032",
      name: "Auntie Anne's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://locations.auntieannes.com/ca/livermore/2792-livermore-outlets-drive?utm_source=google&utm_medium=organic&utm_campaign=locations_partner",
      first_type: "bakery",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8464404, 37.698363] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1987 Santa Rita Rd # C, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-9900",
      name: "The Press Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "https://thepressartisancafe.com/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8753223, 37.678825499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "348 N Canyons Pkwy, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 579-5500",
      name: "Parchments",
      opening_hours: "missing",
      rating: "4.5",
      website: "https://facebook.com/Parchments/",
      first_type: "cafe",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8104118, 37.7037969] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-8744",
      name: "Walmart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.walmart.com/store/2161-pleasanton-ca",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8873959, 37.6982996] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-3333",
      name: "Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.macys.com/stores/ca/pleasanton/stoneridge-shopping-center_333.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9297693, 37.6949838] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6306",
      name: "Costco Wholesale",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:30 PM",
          "Tuesday: 9:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 9:00 AM – 8:30 PM",
          "Friday: 9:00 AM – 8:30 PM",
          "Saturday: 9:00 AM – 7:00 PM",
          "Sunday: 9:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.costco.com/warehouse-locations/livermore-CA-146.html?utm_term=KW&utm_campaign=Homepage&utm_medium=GMB&utm_source=Google&utm_content=Link",
      first_type: "department_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8117112, 37.7026501] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7200 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8900",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 8:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.target.com/sl/dublin/328",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9318325, 37.7066014] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7892 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 875-1550",
      name: "Marshalls",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.marshalls.com/",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.932101, 37.7018677] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9207",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/5610-san-ramon-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9294433, 37.729496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 241-1042",
      name: "Target",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 8:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.target.com/sl/dublin-east/2771",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8536969, 37.702983] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4525 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 924-1060",
      name: "Kohl's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.kohls.com/stores/ca/pleasanton-761.shtml?utm_source=google&utm_medium=organic&utm_campaign=local",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8866526, 37.6999976] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6900 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 875-0712",
      name: "Burlington",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2330" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 9:00 AM – 11:30 PM",
          "Saturday: 9:00 AM – 11:30 PM",
          "Sunday: 9:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://stores.burlington.com/ca/dublin/369/?utm_source=google&utm_medium=organic&utm_campaign=maps",
      first_type: "clothing_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9307067, 37.7049723] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7886 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-5995",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 9:30 PM",
          "Friday: 9:30 AM – 10:00 PM",
          "Saturday: 9:30 AM – 10:00 PM",
          "Sunday: 10:00 AM – 9:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9314005, 37.7020159] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4255 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-4800",
      name: "Macy's Furniture Gallery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.macys.com/stores/ca/pleasanton/rose-pavilion_343.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875051, 37.699641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4840 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 803-8440",
      name: "T.J. Maxx",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "https://tjmaxx.tjx.com/store/index.jsp",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.88241, 37.704367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5300 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 558-2900",
      name: "Nordstrom Rack",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://stores.nordstromrack.com/us/ca/dublin/5300-dublin-boulevard?utm_source=google&utm_medium=organic&utm_campaign=rack&utm_content=476&utm_channel=low_nd_seo_local&sp_source=google&sp_campaign=rack",
      first_type: "clothing_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8920818, 37.705721] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5350 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-7706",
      name: "HomeGoods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 8:00 PM",
          "Friday: 9:30 AM – 9:30 PM",
          "Saturday: 9:30 AM – 9:30 PM",
          "Sunday: 9:30 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.homegoods.com/",
      first_type: "department_store",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.892811, 37.7057257] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0827",
      name: "Sunglass Hut at Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/1300-stoneridge-mall-rd?cid=YEXT_4970&y_source=1_MTE0Mzc4My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9279165, 37.693924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1500 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-9780",
      name: "JCPenney",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 7:00 PM",
          "Tuesday: 11:00 AM – 7:00 PM",
          "Wednesday: 11:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 11:00 AM – 8:00 PM",
          "Saturday: 11:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.jcpenney.com/locations/ca/pleasanton/clothing-stores-pleasanton-ca-0389.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9296739, 37.6964787] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3070 W Jack London Blvd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 449-9041",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.846377, 37.696034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4770 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1515",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.24hourfitness.com/gyms/pleasanton-ca/pleasanton-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8989944, 37.6979806] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2650 Kitty Hawk Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 447-4496",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website:
        "https://www.24hourfitness.com/gyms/livermore-ca/livermore-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8144956, 37.6993881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6930 Village Pkwy ste e, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-9642",
      name: "Joya Dublin",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1030" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "1800" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "1800" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1030" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "2000" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1800" },
          },
          {
            close: { day: 6, time: "1030" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 – 11:00 AM, 6:00 – 7:30 PM",
          "Tuesday: 9:00 – 11:00 AM, 6:00 – 7:30 PM",
          "Wednesday: 9:30 – 11:00 AM, 4:30 – 9:30 PM",
          "Thursday: 7:00 – 10:30 AM, 8:00 – 9:30 PM",
          "Friday: 9:30 – 11:00 AM, 6:00 – 7:30 PM",
          "Saturday: 9:00 – 10:30 AM, 4:00 – 5:30 PM",
          "Sunday: 9:00 – 10:30 AM, 4:00 – 5:30 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.joyayoga.com/",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9253436, 37.7070291] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7090 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2822",
      name: "Bay Club Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 10:00 PM",
          "Tuesday: 5:30 AM – 10:00 PM",
          "Wednesday: 5:30 AM – 10:00 PM",
          "Thursday: 5:30 AM – 10:00 PM",
          "Friday: 5:30 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:30 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://www.bayclubs.com/pleasanton/?utm_source=Chatmeter&utm_medium=Google&utm_campaign=ClubPage&utm_content=PleasantonCH",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9190628, 37.6994725] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1279 Quarry Ln, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 967-3265",
      name: "Reform Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 8:00 – 10:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "https://hittitnow.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8651654, 37.675682699999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "225 Spring St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 600-1966",
      name: "Leisure Sports Hospitality",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.leisuresportsinc.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8736385, 37.6628177] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4250 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 416-1100",
      name: "Vim + Vigor Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://vnvfit.com/locations/pleasanton",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.877163, 37.699057] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5860 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 924-1110",
      name: "Studio 7 Fitness Pleasanton",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8967037, 37.6835109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4480-A Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 875-1604",
      name: "Empower Martial Arts",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1430" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1430" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1430" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 2:30 – 8:30 PM",
          "Tuesday: 2:30 – 8:30 PM",
          "Wednesday: 2:30 – 9:00 PM",
          "Thursday: 3:00 – 8:30 PM",
          "Friday: 3:00 – 8:30 PM",
          "Saturday: 8:30 AM – 4:00 PM",
          "Sunday: 8:30 AM – 3:00 PM",
        ],
      },
      rating: "4.9",
      website: "http://www.empowerdublin.com/",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.873811, 37.707666] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5258 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 587-5182",
      name: "Orangetheory Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1145" },
            open: { day: 0, time: "0715" },
          },
          {
            close: { day: 1, time: "1915" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1915" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1915" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1915" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1145" },
            open: { day: 6, time: "0715" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:15 PM",
          "Tuesday: 5:00 AM – 7:15 PM",
          "Wednesday: 5:00 AM – 7:15 PM",
          "Thursday: 5:00 AM – 7:15 PM",
          "Friday: 5:00 AM – 6:00 PM",
          "Saturday: 7:15 – 11:45 AM",
          "Sunday: 7:15 – 11:45 AM",
        ],
      },
      rating: "4.8",
      website:
        "https://www.orangetheory.com/en-us/locations/california/dublin/5258-dublin-blvd/?utm_medium=seo&utm_source=gg&utm_term=tombras&utm_campaign=reputationdotcom--National",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8911424, 37.7056522] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "5635 W Las Positas Blvd Ste 410, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 477-5050",
      name: "Kinect Performance",
      opening_hours: "missing",
      rating: "5",
      website: "https://www.kinectperformance.com/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8839663, 37.6906135] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5653 Stoneridge Dr #101, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 693-7506",
      name: "Prodigy Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "1000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "1000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "1000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Tuesday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Wednesday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Thursday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Friday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Saturday: 8:00 – 11:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.we-are-prodigy.com/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8859241, 37.6912727] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6483 Sierra Ln, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 361-5874",
      name: "Cooper Sports Performance",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0630" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0630" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0630" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0630" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0630" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 6:30 AM – 8:00 PM",
          "Tuesday: 6:30 AM – 8:00 PM",
          "Wednesday: 6:30 AM – 8:00 PM",
          "Thursday: 6:30 AM – 8:00 PM",
          "Friday: 6:30 AM – 8:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.9",
      website: "http://www.coopersportsperformance.com/",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9119758, 37.7062635] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1989 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(844) 442-6559",
      name: "BollyNaach Dance Studio - Pleasanton",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 8:00 PM",
          "Tuesday: 5:00 – 8:00 PM",
          "Wednesday: 5:00 – 8:00 PM",
          "Thursday: 5:00 – 8:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "4.9",
      website: "https://www.bollynaach.com/page/lesson-registration",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762291, 37.6789501] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6901 York Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-4500",
      name: "Stager Community Gymnasium",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.5",
      website: "missing",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9208132, 37.709615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7075 Commerce Cir # C, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(855) 479-3287",
      name: "P2P Transformation Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1100" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1100" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1100" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1100" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1100" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0430" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Tuesday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Wednesday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Thursday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Friday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Saturday: 4:30 – 11:00 AM, 5:00 – 9:30 PM",
          "Sunday: 7:00 – 11:00 AM",
        ],
      },
      rating: "4.7",
      website: "https://p2ptransformationcenter.com/clubs/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.918242, 37.698613] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2490 Nissen Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 453-6937",
      name: "IMX Pilates and Fitness Tri-Valley",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:30 PM",
          "Tuesday: 6:00 AM – 7:30 PM",
          "Wednesday: 6:00 AM – 7:30 PM",
          "Thursday: 6:00 AM – 7:30 PM",
          "Friday: 6:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "5",
      website: "https://www.imxpilates.com/california/livermore/",
      first_type: "gym",
      zip_code: "94551",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8159572, 37.699687399999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6690 Amador Plaza Rd #215, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 236-0011",
      name: "Art of Living Center For Yoga and Meditation",
      opening_hours: "missing",
      rating: "5",
      website: "https://www.artofliving.org/aw-en/node/8615",
      first_type: "gym",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9260609, 37.7021083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6690 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 456-4615",
      name: "WattsPTandPilates",
      opening_hours: "missing",
      rating: "5",
      website: "https://wattsptandpilates.com/",
      first_type: "physiotherapist",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.926061, 37.7021083] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "148 Ray St #B, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 699-2293",
      name: "Springs Pilates Pleasanton",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8720454, 37.6630585] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2774 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-2868",
      name: "San Francisco Premium Outlets",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.premiumoutlets.com/outlet/san-francisco",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8440481, 37.6987272] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2778",
      name: "Stoneridge Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.simon.com/mall/stoneridge-shopping-center",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9288449, 37.6952657] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4980 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(510) 460-6922",
      name: "Hacienda Crossings Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.hacienda-crossings.com/",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8852004, 37.704458] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "missing",
      name: "Country Club Village",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0000" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9298991, 37.7295737] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "2217 Stoneridge Mall Rd Spc C207, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-8636",
      name: "Sunglass Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/2217-stoneridge-mall-rd?cid=YEXT_4201&y_source=1_MTE0MjQyMS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.928741, 37.695824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5130-5350 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 279-1800",
      name: "Persimmon Place",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.persimmonplacedublin.com/",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8908339, 37.7053863] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2976 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 456-0502",
      name: "Sunglass Hut",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://stores.sunglasshut.com/us/ca/livermore/2976-livermore-outlets-dr?cid=YEXT_6134&y_source=1_MTE0MzcxMi03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8460343, 37.6991378] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3505 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(408) 292-1601",
      name: "Republic Square at Livermore",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.4",
      website: "http://republicsquareatlivermore.com/",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8400049, 37.6991823] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0827",
      name: "Sunglass Hut at Macy's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://stores.sunglasshut.com/us/ca/pleasanton/1300-stoneridge-mall-rd?cid=YEXT_4970&y_source=1_MTE0Mzc4My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9279165, 37.693924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Vintage Hills Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8560276, 37.6622117] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2820 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Fallon Gateway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: 9:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8526627, 37.7039485] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4500 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(610) 215-7939",
      name: "Metro 580",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/metro580?utm_source=gmb&utm_medium=Yext",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8865938, 37.6996521] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4225 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(916) 619-5425",
      name: "Rose Pavilion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/rosepavilion",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8753625, 37.6983269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Valley Center Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9279336, 37.7103144] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7311 Village Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Village Square/Valley Plaza",
      opening_hours: "missing",
      rating: "4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9261532, 37.7113783] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7886 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(833) 800-4343",
      name: "Dublin Retail Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://properties.kimcorealty.com/p/commercial-real-estate-listings/Dublin-CA-94568/114510",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9321835, 37.7023414] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Signature Center, 4801 - 4889 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Gateway Square Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9024115, 37.6925378] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7841 Amador Valley Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Shamrock Village",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9353362, 37.70663] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3992 Summit Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Tivoli Plaza",
      opening_hours: "missing",
      rating: "4.8",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8650193, 37.7045588] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4500 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Dublin Corners",
      opening_hours: "missing",
      rating: "4.2",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8813881, 37.7053182] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6783",
      name: "Costco Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "2.6",
      website:
        "https://www.costco.com/warehouse-locations/livermore-ca-146.html",
      first_type: "pharmacy",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8122662, 37.7026118] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Independence Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 443-6783",
      name: "May Mikhail, PhD",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8122662, 37.7026118] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7200 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 241-0000",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1100" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Thursday: Closed",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Saturday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 12:00 – 5:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://www.cvs.com/store-locator/dublin-ca-pharmacies/7200-amador-plaza-rd-dublin-ca-94568/storeid=16116?WT.mc_id=LS_GOOGLE_RX_16116",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.931845, 37.706742299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9217",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.walmart.com/store/5610-san-ramon-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9293619, 37.7292341] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-9024",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 4:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.walmart.com/store/2161-pleasanton-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.887287, 37.698316] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-5914",
      name: "Natasha A. Ching, RN",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273686, 37.706388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-5914",
      name: "Arthur W. Alm Jr, BS",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273531, 37.7063699] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-5600",
      name: "Haritha Pingili, BS",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 9:00 AM – 8:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8725827, 37.7060831] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-5600",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/dublin/4440-tassajara-rd.html",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8740515, 37.7068034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3999 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8552",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Saturday: 9:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/3999-santa-rita-road-pleasanton-ca-94588/storeid=9251?WT.mc_id=LS_GOOGLE_RX_9251",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8759178, 37.6971875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Rite Aid Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 9:00 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "5",
      website:
        "https://www.riteaid.com/locations/ca/pleasanton/2819-hopyard-road.html",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8948191, 37.6759839] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9120 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 829-9335",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.cvs.com/store-locator/san-ramon-ca-pharmacies/9120-alcosta-blvd-san-ramon-ca-94583/storeid=9348?WT.mc_id=LS_GOOGLE_RX_9348",
      first_type: "pharmacy",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9306894, 37.7286967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3010 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-9138",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/3010-bernal-avenue-pleasanton-ca-94566/storeid=10924?WT.mc_id=LS_GOOGLE_RX_10924",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8567369, 37.6699032] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11440 Windemere Pkwy, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 364-6401",
      name: "Walgreens Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "4",
      website:
        "https://www.walgreens.com/locator/walgreens-11440+windemere+pkwy-san+ramon-ca-94582/id=16090",
      first_type: "pharmacy",
      zip_code: "11440",
    },
    geometry: { type: "Point", coordinates: [-121.905024, 37.7633567] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7201 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-3823",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.cvs.com/store-locator/dublin-ca-pharmacies/7201-regional-st-dublin-ca-94568/storeid=3024?WT.mc_id=LS_GOOGLE_RX_3024",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9325259, 37.7050872] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7601 Stoneridge Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 847-5500",
      name: "Kaiser Permanente Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 6:00 PM",
          "Tuesday: 8:30 AM – 6:00 PM",
          "Wednesday: 8:30 AM – 6:00 PM",
          "Thursday: 8:30 AM – 6:00 PM",
          "Friday: 8:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website:
        "https://thrive.kaiserpermanente.org/care-near-you/northern-california/diablo/departments/pharmacy/pharmacy-locations-and-hours-of-operation/",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9267652, 37.6908048] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 249-9011",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/6750-bernal-ave-pleasanton-ca-94566/storeid=10119?WT.mc_id=LS_GOOGLE_RX_10119",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.897972, 37.6565793] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 484-1960",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.7",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899566, 37.6560943] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5420 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-7944",
      name: "Raley's Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.raleys.com/",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8785381, 37.65176] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 241-1043",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 9:30 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 9:30 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 PM – 12:00 AM",
          "Friday: 12:00 AM – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://www.cvs.com/store-locator/dublin-ca-pharmacies/2800-dublin-blvd-dublin-ca-94568/storeid=17628?WT.mc_id=LS_GOOGLE_RX_17628",
      first_type: "pharmacy",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8536969, 37.702983] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Livermore, CA, USA",
      formatted_phone_number: "missing",
      name: "Livermore",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.cityoflivermore.net/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.7684732, 37.6818688] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4950 Hacienda Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-9006",
      name: "Hyatt Place Dublin/Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.hyatt.com/en-US/hotel/california/hyatt-place-dublin-pleasanton/sfozb/?src=corp_lclb_gmb_seo_sfozb",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8873456, 37.7057541] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5115 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8800",
      name: "Four Points by Sheraton Pleasanton",
      opening_hours: "missing",
      rating: "3.8",
      website:
        "https://www.marriott.com/en-us/hotels/oakfp-four-points-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9028116, 37.6967602] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5535 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1212",
      name: "Larkspur Landing Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4",
      website:
        "https://www.larkspurhotels.com/pleasanton/?utm_source=google-gbp&utm_medium=organic&utm_campaign=gbp",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9065647, 37.6987105] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5059 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1414",
      name: "Courtyard Pleasanton",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.marriott.com/en-us/hotels/oakpl-courtyard-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9023404, 37.6940157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2801 Constitution Dr, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 292-2000",
      name: "Hilton Garden Inn Livermore",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.hilton.com/en/hotels/lvklhgi-hilton-garden-inn-livermore/?SEO_id=GMB-AMER-GI-LVKLHGI&y_source=1_MjA4MzQ1MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8139801, 37.7029327] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6275 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 967-2750",
      name: "La Quinta Inn & Suites by Wyndham Dublin - Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.wyndhamhotels.com/laquinta/dublin-california/la-quinta-dublin-pleasanton/overview?CID=LC:6ysy27krtpcrqev:52796&iata=00093796",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9079749, 37.7061977] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 961-9600",
      name: "Holiday Inn Express & Suites Livermore, an IHG Hotel",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.ihg.com/holidayinnexpress/hotels/us/en/livermore/lvmre/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-LVMRE",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8174074, 37.70403] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2774 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-2868",
      name: "San Francisco Premium Outlets",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.premiumoutlets.com/outlet/san-francisco",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8440481, 37.6987272] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11950 Dublin Canyon Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 847-6000",
      name: "Pleasanton Marriott",
      opening_hours: "missing",
      rating: "4.3",
      website:
        "https://www.marriott.com/en-us/hotels/oakmc-pleasanton-marriott/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "11950",
    },
    geometry: { type: "Point", coordinates: [-121.9353956, 37.6957527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2929 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 243-1000",
      name: "Courtyard Livermore",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.marriott.com/en-us/hotels/oaklm-courtyard-livermore/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8151134, 37.703512] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5375 Owens Ct, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1300",
      name: "Best Western Plus Pleasanton Inn",
      opening_hours: "missing",
      rating: "4",
      website:
        "https://www.bestwestern.com/en_US/book/hotel-rooms.05665.html?iata=00171880&ssob=BLBWI0004G&cid=BLBWI0004G:google:gmb:05665",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9050425, 37.7006895] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2850 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 606-6400",
      name: "Hampton Inn Livermore",
      opening_hours: "missing",
      rating: "4.4",
      website:
        "https://www.hilton.com/en/hotels/lvkcahx-hampton-livermore/?SEO_id=GMB-AMER-HX-LVKCAHX&y_source=1_MjA4MzQ2Ny03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "lodging",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8166707, 37.7026503] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6680 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-7750",
      name: "Holiday Inn Dublin-Pleasanton, an IHG Hotel",
      opening_hours: "missing",
      rating: "3.9",
      website:
        "https://www.ihg.com/holidayinn/hotels/us/en/dublin/dubrm/hoteldetail?cm_mmc=GoogleMaps-_-HI-_-US-_-DUBRM",
      first_type: "lodging",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9329223, 37.7001724] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11920 Dublin Canyon Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-0500",
      name: "Residence Inn Pleasanton",
      opening_hours: "missing",
      rating: "4.1",
      website:
        "https://www.marriott.com/en-us/hotels/oakri-residence-inn-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "11920",
    },
    geometry: { type: "Point", coordinates: [-121.936522, 37.6969172] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Stoneridge Mall Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-2778",
      name: "Stoneridge Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.simon.com/mall/stoneridge-shopping-center",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9288449, 37.6952657] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5102 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 351-0303",
      name: "Motel 6 Pleasanton, CA",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website:
        "https://www.motel6.com/en/home/motels.ca.pleasanton.9642.html?lid=Local_Milestone_1311&travelAgentNumber=TA001305&corporatePlusNumber=CP792N5W&utm_source=google%20my%20business&utm_medium=listing&utm_campaign=visit%20website",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.904863, 37.6976368] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Kitty Hawk Rd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 447-7275",
      name: "Boomers Livermore",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 9:00 PM",
          "Tuesday: 12:00 – 9:00 PM",
          "Wednesday: 12:00 – 9:00 PM",
          "Thursday: 12:00 – 9:00 PM",
          "Friday: 12:00 – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website:
        "https://boomersparks.com/livermore?utm_source=gmb&utm_medium=Yext",
      first_type: "amusement_park",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8099623, 37.6995465] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4770 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1515",
      name: "24 Hour Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 10:00 PM",
          "Tuesday: 5:00 AM – 10:00 PM",
          "Wednesday: 5:00 AM – 10:00 PM",
          "Thursday: 5:00 AM – 10:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 8:00 PM",
          "Sunday: 6:00 AM – 8:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.24hourfitness.com/gyms/pleasanton-ca/pleasanton-super-sport?Adb_id=GGL_LOC_ACQ_CDP",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8989944, 37.6979806] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Hayward, CA, USA",
      formatted_phone_number: "missing",
      name: "Hayward",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.hayward-ca.gov/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0809964, 37.6687665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4299 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-8899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://www.99ranch.com/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8733168, 37.6995615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7333 Regional St, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-3999",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9331229, 37.7063676] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3903 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0763",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/pleasanton/66/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8768923, 37.6967871] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9100 Alcosta Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 364-9207",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.walmart.com/store/5610-san-ramon-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9294433, 37.729496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4440 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4710",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/dublin/4440-tassajara-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.874053, 37.7066902] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1701 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-5530",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/1701-santa-rita-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8744057, 37.6748422] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11050 Bollinger Canyon Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 359-2001",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.3",
      website:
        "https://local.safeway.com/safeway/ca/san-ramon/11050-bollinger-canyon-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "11050",
    },
    geometry: { type: "Point", coordinates: [-121.922063, 37.774864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6783 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 999-8242",
      name: "Sahara Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "3.9",
      website: "https://www.saharasupermarket.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.915141, 37.704493] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5200 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 452-2226",
      name: "Whole Foods Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website:
        "https://www.wholefoodsmarket.com/stores/dublin-ca?utm_source=google&utm_medium=organic&utm_campaign=listings",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8897911, 37.7057284] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5775 Johnson Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 730-0101",
      name: "Smart & Final Extra!",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.smartandfinal.com/store",
      first_type: "grocery_or_supermarket",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9065079, 37.699497400000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-8644",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899526, 37.6560809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7499 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 556-4034",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/dublin/7499-dublin-blvd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9273886, 37.7060094] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7153 Amador Plaza Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 307-1172",
      name: "Sprouts Farmers Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://sprouts.com/store/ca/dublin/dublin",
      first_type: "grocery_or_supermarket",
      zip_code: "94568",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9291759, 37.707724799999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5321 Hopyard Rd # F, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-9101",
      name: "Banou International Foods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 6:00 PM",
          "Tuesday: 10:00 AM – 6:00 PM",
          "Wednesday: 10:00 AM – 6:00 PM",
          "Thursday: 10:00 AM – 6:00 PM",
          "Friday: 10:00 AM – 6:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 11:00 AM – 4:00 PM",
        ],
      },
      rating: "4.4",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.903796, 37.6996785] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6040 Dougherty Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-9950",
      name: "Vijetha Indian Supermarket",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 9:30 PM",
          "Tuesday: 9:30 AM – 9:30 PM",
          "Wednesday: 9:30 AM – 9:30 PM",
          "Thursday: 9:30 AM – 9:30 PM",
          "Friday: 9:00 AM – 9:30 PM",
          "Saturday: 9:00 AM – 9:30 PM",
          "Sunday: 9:00 AM – 9:30 PM",
        ],
      },
      rating: "3.7",
      website: "https://www.vijethagrocery.com/",
      first_type: "supermarket",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9112368, 37.7073575] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2803 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-8220",
      name: "Gene's Fine Foods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://genesfinefoods.org/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8950958, 37.6756515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6155 W Las Positas Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-1520",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.3",
      website: "https://luckysupermarkets.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.9050911, 37.6823257] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "21001 San Ramon Valley Blvd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 828-1200",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website: "https://luckysupermarkets.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "21001",
    },
    geometry: { type: "Point", coordinates: [-121.94425, 37.723858] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4225 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(916) 619-5425",
      name: "Rose Pavilion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/rosepavilion",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8753625, 37.6983269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "780 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-8438",
      name: "Oasis Restaurant & Wine Lounge",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.oasiswinelounge.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8742229, 37.6637824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6770 Bernal Ave Suite 410, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 485-3260",
      name: "The Habit Burger Grill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1030" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 5, time: "2230" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 10:00 PM",
          "Tuesday: 10:30 AM – 10:00 PM",
          "Wednesday: 10:30 AM – 10:00 PM",
          "Thursday: 10:30 AM – 10:00 PM",
          "Friday: 10:30 AM – 10:30 PM",
          "Saturday: 10:30 AM – 10:30 PM",
          "Sunday: 10:30 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://order.habitburger.com/store/51/category",
      first_type: "meal_takeaway",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8980285, 37.6576074] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "625 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-1224",
      name: "Blue Agave Club",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1401" },
            open: { day: 2, time: "1145" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1145" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1145" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1145" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1145" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:45 AM – 2:01 PM, 5:00 – 8:00 PM",
          "Wednesday: 11:45 AM – 2:00 PM, 5:00 – 8:00 PM",
          "Thursday: 11:45 AM – 2:00 PM, 5:00 – 8:00 PM",
          "Friday: 11:45 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Saturday: 11:45 AM – 2:00 PM, 5:00 – 9:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.2",
      website: "http://www.blueagaveclub.com/",
      first_type: "restaurant",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.875366, 37.661826] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3015 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-9600",
      name: "The Hop Yard American Alehouse & Grill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 8:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:30 PM",
          "Saturday: 11:00 AM – 9:30 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.hopyard.com/",
      first_type: "bar",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8973773, 37.6770239] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4555 Hopyard Rd C-23, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-0707",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1830" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 6:30 PM",
        ],
      },
      rating: "3.8",
      website:
        "https://restaurants.subway.com/united-states/ca/pleasanton/4555-hopyard-road?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=2827&utm_campaign=evergreen-2020&y_source=1_MTQ4OTY2OTQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9024241, 37.6887815] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3037 Hopyard Rd STE C, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 264-5996",
      name: "Pizza Bello",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1030" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1030" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1030" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1030" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1030" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1030" },
          },
        ],
        weekday_text: [
          "Monday: 10:30 AM – 8:00 PM",
          "Tuesday: 10:30 AM – 8:00 PM",
          "Wednesday: 10:30 AM – 8:00 PM",
          "Thursday: 10:30 AM – 8:00 PM",
          "Friday: 10:30 AM – 9:30 PM",
          "Saturday: 10:30 AM – 9:00 PM",
          "Sunday: 11:00 AM – 8:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.pizza-bello.com/",
      first_type: "meal_delivery",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8969017, 37.6768653] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "288 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-2520",
      name: "Gay Nineties Pizza Co",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 11:00 AM – 10:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.gayninetiespizza.com/",
      first_type: "restaurant",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.876703, 37.658414] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "443 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 223-8074",
      name: "Lokanta Grill & Bar",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:30 PM",
          "Tuesday: 11:00 AM – 9:30 PM",
          "Wednesday: 11:00 AM – 9:30 PM",
          "Thursday: 11:00 AM – 9:30 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 10:00 AM – 10:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.eatlokanta.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762731, 37.6601563] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "101 E Vineyard Ave Suite 131, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 484-1444",
      name: "Subway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 9:00 PM",
          "Tuesday: 7:00 AM – 9:00 PM",
          "Wednesday: 7:00 AM – 9:00 PM",
          "Thursday: 7:00 AM – 9:00 PM",
          "Friday: 7:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 8:30 PM",
          "Sunday: 8:00 AM – 8:30 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://restaurants.subway.com/united-states/ca/livermore/101-east-vineyard-avenue?utm_source=yxt-goog&utm_medium=local&utm_term=acq&utm_content=50530&utm_campaign=evergreen-2020&y_source=1_MTQ5MDM5NjctNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "meal_takeaway",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8044005, 37.6508433] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "425 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 600-0411",
      name: "Nonni's Bistro",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://nonnisbistro.net/",
      first_type: "restaurant",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8763382, 37.6599077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "475 St John St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-0987",
      name: "Barone's Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1600" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: 4:00 – 9:00 PM",
          "Friday: 4:00 – 10:00 PM",
          "Saturday: 4:00 – 10:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.4",
      website: "http://www.baronespleasanton.com/",
      first_type: "restaurant",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8753586, 37.6640873] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "101 E Vineyard Ave, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 846-0259",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 7:00 PM",
          "Tuesday: 4:00 AM – 7:00 PM",
          "Wednesday: 4:00 AM – 7:00 PM",
          "Thursday: 4:00 AM – 7:00 PM",
          "Friday: 4:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/10037/",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8043506, 37.6506786] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6774 Bernal Ave, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 249-6766",
      name: "Panda Express",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 9:00 PM",
          "Thursday: 10:00 AM – 9:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 10:00 AM – 9:00 PM",
        ],
      },
      rating: "3.6",
      website: "https://www.pandaexpress.com/locations/ca/pleasanton/2006",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8983346, 37.657503299999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "830 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-8218",
      name: "Main Street Brewery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1600" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1600" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2330" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2330" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 – 8:00 PM",
          "Tuesday: 4:00 – 8:00 PM",
          "Wednesday: 11:30 AM – 8:00 PM",
          "Thursday: 11:30 AM – 8:00 PM",
          "Friday: 11:30 AM – 11:30 PM",
          "Saturday: 11:30 AM – 11:30 PM",
          "Sunday: 11:30 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.mainstbrewery.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8738889, 37.6644444] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4555 Hopyard Rd Suite C-15, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0138",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/16549/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.902522, 37.689071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "F, 3059 Hopyard Rd STE F, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-5251",
      name: "Chinese Szechuan Restaurant",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1600" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1630" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 11:00 AM – 2:00 PM, 4:30 – 8:00 PM",
          "Wednesday: 11:00 AM – 2:00 PM, 4:30 – 8:00 PM",
          "Thursday: 11:00 AM – 2:00 PM, 4:30 – 8:00 PM",
          "Friday: 11:00 AM – 2:00 PM, 4:30 – 8:00 PM",
          "Saturday: 11:30 AM – 2:00 PM, 4:30 – 8:00 PM",
          "Sunday: 4:00 – 8:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://chineseszechuan87.com/",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8961913, 37.6774595] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "405 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-2222",
      name: "Pastas Trattoria",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1730" },
          },
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1730" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1730" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1730" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "1730" },
          },
          {
            close: { day: 6, time: "1430" },
            open: { day: 6, time: "1130" },
          },
          {
            close: { day: 6, time: "2030" },
            open: { day: 6, time: "1730" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Tuesday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Wednesday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Thursday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Friday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Saturday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
          "Sunday: 11:30 AM – 2:30 PM, 5:30 – 8:30 PM",
        ],
      },
      rating: "4.5",
      website: "http://www.pastastrattoriapleasanton.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.876494, 37.659704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "649 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 484-9600",
      name: "Strizzi's Restaurant",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2000" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 8:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 8:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.strizzis.com/",
      first_type: "restaurant",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8751602, 37.6624336] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3120-H Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 485-4242",
      name: "Mountain Mike's Pizza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 9:00 PM",
          "Tuesday: 10:00 AM – 9:00 PM",
          "Wednesday: 10:00 AM – 10:00 PM",
          "Thursday: 10:00 AM – 10:00 PM",
          "Friday: 10:00 AM – 10:00 PM",
          "Saturday: 10:00 AM – 10:00 PM",
          "Sunday: 10:00 AM – 10:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.mountainmikespizza.com/locations/pleasanton-santa-rita/",
      first_type: "meal_takeaway",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.877196, 37.690388] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6025 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 484-3878",
      name: "De La Torre's Trattoria",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1700" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "1700" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 2:00 PM, 5:00 – 8:30 PM",
          "Tuesday: 11:30 AM – 2:00 PM, 5:00 – 8:30 PM",
          "Wednesday: 11:30 AM – 2:00 PM, 5:00 – 8:30 PM",
          "Thursday: 11:30 AM – 2:00 PM, 5:00 – 8:30 PM",
          "Friday: 11:30 AM – 2:00 PM, 5:00 – 9:30 PM",
          "Saturday: 5:00 – 9:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website:
        "http://www.delatorres.net/?y_source=1_NDg0OTQ4NzgtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      first_type: "restaurant",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9047222, 37.6827778] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5765 Valley Ave STE 100, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 401-0018",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Pleasanton,%20CA,%20USA&selected=Pleasanton",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.896658, 37.676606] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "101 E Vineyard Ave, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 846-0259",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 7:00 PM",
          "Tuesday: 4:00 AM – 7:00 PM",
          "Wednesday: 4:00 AM – 7:00 PM",
          "Thursday: 4:00 AM – 7:00 PM",
          "Friday: 4:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.starbucks.com/store-locator/store/10037/",
      first_type: "cafe",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.8043506, 37.6506786] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4555 Hopyard Rd Suite C-15, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0138",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/16549/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.902522, 37.689071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "1737 Santa Rita Rd Ste 400, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 485-1921",
      name: "Noah's NY Bagels",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1400" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1400" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 PM",
          "Tuesday: 5:00 AM – 2:00 PM",
          "Wednesday: 5:00 AM – 2:00 PM",
          "Thursday: 5:00 AM – 2:00 PM",
          "Friday: 5:00 AM – 2:00 PM",
          "Saturday: 6:00 AM – 2:00 PM",
          "Sunday: 6:00 AM – 2:00 PM",
        ],
      },
      rating: "4.1",
      website:
        "https://locations.noahs.com/us/ca/pleasanton/1737-santa-rita-rd?y_source=1_ODk4NTc2My03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8739182, 37.6752166] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6786 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 931-0907",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 4:30 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 4:30 AM – 7:30 PM",
          "Saturday: 4:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.starbucks.com/store-locator/store/116429/",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.90004, 37.656612] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "349 Main St #110, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 474-9301",
      name: "Peet's Coffee",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 6:00 PM",
          "Tuesday: 5:30 AM – 6:00 PM",
          "Wednesday: 5:30 AM – 6:00 PM",
          "Thursday: 5:30 AM – 6:00 PM",
          "Friday: 5:30 AM – 6:00 PM",
          "Saturday: 6:00 AM – 6:00 PM",
          "Sunday: 6:00 AM – 6:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.peets.com/pages/store-locator?stockist-query=Pleasanton,%20CA,%20USA&selected=Pleasanton%20-%20Main%20St.",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8766764, 37.659435499999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "511 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 989-5789",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/1010051/",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.875909, 37.660676] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4191 First St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(510) 759-2384",
      name: "Circle K",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "1.5",
      website:
        "https://www.circlek.com/store-locator/US/pleasanton/4191-1st-st/2655643?utm_source=google&utm_medium=local&utm_campaign=google-local",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8696871, 37.6636959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3040 Bernal Ave #320, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 519-7565",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0430" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0430" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 4:30 AM – 7:00 PM",
          "Tuesday: 4:30 AM – 7:00 PM",
          "Wednesday: 4:30 AM – 7:00 PM",
          "Thursday: 4:30 AM – 7:00 PM",
          "Friday: 4:30 AM – 7:00 PM",
          "Saturday: 5:30 AM – 7:00 PM",
          "Sunday: 5:30 AM – 7:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.starbucks.com/store-locator/store/1015505",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.857363, 37.6690413] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1987 Santa Rita Rd # C, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-9900",
      name: "The Press Cafe",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "https://thepressartisancafe.com/",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8753223, 37.678825499999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1701 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-5530",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://www.starbucks.com/store-locator/store/8658",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.87439, 37.67484] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-8644",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0530" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0530" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0530" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0530" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0530" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0530" },
          },
        ],
        weekday_text: [
          "Monday: 5:30 AM – 7:30 PM",
          "Tuesday: 5:30 AM – 7:30 PM",
          "Wednesday: 5:30 AM – 7:30 PM",
          "Thursday: 5:30 AM – 7:30 PM",
          "Friday: 5:30 AM – 7:30 PM",
          "Saturday: 5:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "3.6",
      website: "https://www.starbucks.com/store-locator/store/69558",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8994761, 37.6561054] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4432 Rosewood Dr #925, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 368-0595",
      name: "Thrive Cafe at Rosewood Commons",
      opening_hours: "missing",
      rating: "3.6",
      website: "missing",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8824125, 37.6978496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4283 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(510) 340-8971",
      name: "Starbucks",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "0530" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0400" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0400" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0400" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0400" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0400" },
          },
          {
            close: { day: 6, time: "1930" },
            open: { day: 6, time: "0430" },
          },
        ],
        weekday_text: [
          "Monday: 4:00 AM – 7:30 PM",
          "Tuesday: 4:00 AM – 7:30 PM",
          "Wednesday: 4:00 AM – 7:30 PM",
          "Thursday: 4:00 AM – 7:30 PM",
          "Friday: 4:00 AM – 7:30 PM",
          "Saturday: 4:30 AM – 7:30 PM",
          "Sunday: 5:30 AM – 7:30 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.starbucks.com/store-locator/store/1008983",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.872972, 37.698959] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3001 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 249-0620",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/PLEASANTON/3001-BERNAL-AVENUE/19799.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8582467, 37.6698207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5420 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-4471",
      name: "Peet's Coffee & Tea",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1430" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 3:00 PM",
          "Tuesday: 6:00 AM – 3:00 PM",
          "Wednesday: 6:00 AM – 3:00 PM",
          "Thursday: 6:00 AM – 3:00 PM",
          "Friday: 6:00 AM – 3:00 PM",
          "Saturday: 6:00 AM – 3:00 PM",
          "Sunday: 6:00 AM – 2:30 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.peets.com/",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.878408, 37.6516881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "55 W Angela St # 101, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-0887",
      name: "Juice and Java Express",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: 9:00 AM – 3:00 PM",
          "Sunday: 10:00 AM – 3:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://juiceandjava.express/",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8753691, 37.6594062] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2792 Livermore Outlets Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 292-7032",
      name: "Auntie Anne's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 8:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 7:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://locations.auntieannes.com/ca/livermore/2792-livermore-outlets-drive?utm_source=google&utm_medium=organic&utm_campaign=locations_partner",
      first_type: "bakery",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8464404, 37.698363] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6800 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1955",
      name: "McDonald's",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.6",
      website:
        "https://www.mcdonalds.com/us/en-us/location/CA/PLEASANTON/6800-SANTA-RITA-RD/11838.html?cid=RF:YXT:GMB::Clicks",
      first_type: "cafe",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8714539, 37.7006534] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "440 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 425-9033",
      name: "Mr. Sun Tea Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2030" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1200" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1200" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 12:00 – 8:00 PM",
          "Tuesday: 12:00 – 8:00 PM",
          "Wednesday: 12:00 – 8:00 PM",
          "Thursday: 12:00 – 8:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:30 AM – 8:30 PM",
        ],
      },
      rating: "4.8",
      website: "http://mrsunteausa.com/",
      first_type: "cafe",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8758518, 37.6601542] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-8744",
      name: "Walmart",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      rating: "3.8",
      website: "https://www.walmart.com/store/2161-pleasanton-ca",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8873959, 37.6982996] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4255 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 227-4800",
      name: "Macy's Furniture Gallery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 8:00 PM",
          "Tuesday: 10:00 AM – 8:00 PM",
          "Wednesday: 10:00 AM – 8:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 9:00 PM",
          "Saturday: 10:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 7:00 PM",
        ],
      },
      rating: "3.9",
      website:
        "https://www.macys.com/stores/ca/pleasanton/rose-pavilion_343.html",
      first_type: "department_store",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.875051, 37.699641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3070 W Jack London Blvd, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 449-9041",
      name: "Ross Dress for Less",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.rossstores.com/",
      first_type: "clothing_store",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.846377, 37.696034] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "225 Spring St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 600-1966",
      name: "Leisure Sports Hospitality",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.leisuresportsinc.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8736385, 37.6628177] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1279 Quarry Ln, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 967-3265",
      name: "Reform Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 7:00 PM",
          "Tuesday: 5:00 AM – 7:00 PM",
          "Wednesday: 5:00 AM – 7:00 PM",
          "Thursday: 5:00 AM – 7:00 PM",
          "Friday: 5:00 AM – 7:00 PM",
          "Saturday: 8:00 – 10:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "4.8",
      website: "https://hittitnow.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8651654, 37.675682699999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "55 W Angela St Suite 210, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 475-8797",
      name: "Pleasanton Pilates",
      opening_hours: "missing",
      rating: "5",
      website: "https://pleasantonpilates.net/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8753628, 37.6594632] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "148 Ray St #B, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 699-2293",
      name: "Springs Pilates Pleasanton",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8720454, 37.6630585] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6754 Bernal Ave #730, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 399-1624",
      name: "Pure Barre",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:30 PM",
          "Tuesday: 6:00 AM – 7:30 PM",
          "Wednesday: 6:00 AM – 7:30 PM",
          "Thursday: 6:00 AM – 7:30 PM",
          "Friday: 6:00 AM – 6:30 PM",
          "Saturday: 8:00 AM – 12:00 PM",
          "Sunday: 8:00 AM – 12:00 PM",
        ],
      },
      rating: "4.8",
      website: "https://www.purebarre.com/location/pleasanton-ca",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8975146, 37.656784] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6766 Bernal Ave Ste. 530, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 255-0880",
      name: "Club Pilates",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 5:00 PM",
          "Tuesday: 7:00 AM – 8:30 PM",
          "Wednesday: 9:00 AM – 8:30 PM",
          "Thursday: 7:30 AM – 8:30 PM",
          "Friday: 7:00 AM – 6:30 PM",
          "Saturday: 8:00 AM – 12:30 PM",
          "Sunday: 8:00 AM – 12:30 PM",
        ],
      },
      rating: "4.9",
      website:
        "https://www.clubpilates.com/pleasanton/?utm_source=google.com&utm_medium=referral&utm_campaign=yext",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.897394, 37.6575391] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1989 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(844) 442-6559",
      name: "BollyNaach Dance Studio - Pleasanton",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1700" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1700" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1700" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 – 8:00 PM",
          "Tuesday: 5:00 – 8:00 PM",
          "Wednesday: 5:00 – 8:00 PM",
          "Thursday: 5:00 – 8:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "4.9",
      website: "https://www.bollynaach.com/page/lesson-registration",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762291, 37.6789501] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3400 W Ruby Hill Dr, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-5840",
      name: "The Club at Ruby Hill",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.5",
      website:
        "https://www.rubyhill.com/?utm_source=google&utm_medium=organic&utm_campaign=google_business_profile",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8148827, 37.6400444] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5860 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 924-1110",
      name: "Studio 7 Fitness Pleasanton",
      opening_hours: "missing",
      rating: "5",
      website: "missing",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8967037, 37.6835109] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "5635 W Las Positas Blvd Ste 410, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 477-5050",
      name: "Kinect Performance",
      opening_hours: "missing",
      rating: "5",
      website: "https://www.kinectperformance.com/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8839663, 37.6906135] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5653 Stoneridge Dr #101, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 693-7506",
      name: "Prodigy Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "1000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "1000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "1000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "1000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1500" },
          },
          {
            close: { day: 6, time: "1100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Tuesday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Wednesday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Thursday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Friday: 6:00 – 10:00 AM, 3:00 – 7:00 PM",
          "Saturday: 8:00 – 11:00 AM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "https://www.we-are-prodigy.com/",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8859241, 37.6912727] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4250 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 416-1100",
      name: "Vim + Vigor Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 11:00 PM",
          "Tuesday: 5:00 AM – 11:00 PM",
          "Wednesday: 5:00 AM – 11:00 PM",
          "Thursday: 5:00 AM – 11:00 PM",
          "Friday: 5:00 AM – 9:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "4",
      website: "https://vnvfit.com/locations/pleasanton",
      first_type: "gym",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.877163, 37.699057] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3283 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 828-9642",
      name: "Joya Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.4",
      website: "http://www.joyayoga.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8580609, 37.667628] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1056 Serpentine Ln # A, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-1010",
      name: "West Coast Olympic Gymnastics Academy",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.9",
      website: "https://wcoga.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8670886, 37.6716006] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "164 Ray St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-1614",
      name: "Body Balance Fitness & Massage",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: 7:00 AM – 7:00 PM",
          "Sunday: 7:00 AM – 7:00 PM",
        ],
      },
      rating: "5",
      website: "http://www.bodybalancepleasanton.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8721087, 37.6629961] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5424 Sunol Blvd suite 6, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 575-7774",
      name: "IMX Pilates & Fitness Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "1000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:30 PM",
          "Tuesday: 7:00 AM – 6:30 PM",
          "Wednesday: 7:00 AM – 6:30 PM",
          "Thursday: 7:00 AM – 6:30 PM",
          "Friday: 7:00 AM – 6:30 PM",
          "Saturday: 8:00 – 10:00 AM",
          "Sunday: 8:00 – 11:00 AM",
        ],
      },
      rating: "5",
      website: "https://www.imxpilates.com/california/pleasanton/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8787363, 37.6511791] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3283 Bernal Ave STE 105, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 425-7075",
      name: "The Resistance Fitness Center",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "0645" },
            open: { day: 1, time: "0545" },
          },
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "1630" },
          },
          {
            close: { day: 2, time: "0645" },
            open: { day: 2, time: "0545" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "1630" },
          },
          {
            close: { day: 3, time: "0645" },
            open: { day: 3, time: "0545" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "1630" },
          },
          {
            close: { day: 4, time: "0645" },
            open: { day: 4, time: "0545" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "1630" },
          },
          {
            close: { day: 5, time: "0645" },
            open: { day: 5, time: "0545" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "1630" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 5:45 – 6:45 AM, 10:00 AM – 12:00 PM, 4:30 – 8:30 PM",
          "Tuesday: 5:45 – 6:45 AM, 10:00 AM – 12:00 PM, 4:30 – 8:30 PM",
          "Wednesday: 5:45 – 6:45 AM, 10:00 AM – 12:00 PM, 4:30 – 8:30 PM",
          "Thursday: 5:45 – 6:45 AM, 10:00 AM – 12:00 PM, 4:30 – 8:30 PM",
          "Friday: 5:45 – 6:45 AM, 10:00 AM – 12:00 PM, 4:30 – 7:30 PM",
          "Saturday: 10:00 AM – 12:00 PM",
          "Sunday: 10:00 AM – 12:00 PM",
        ],
      },
      rating: "4.8",
      website: "https://www.theresistancefc.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8579083, 37.6677462] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "530 Boulder Ct Suite #102, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 519-0462",
      name: "Tornado Acro Gymnastics & Martial Arts Sport Club",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.9",
      website: "https://www.tornadosportclub.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8628497, 37.6702924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "530 Boulder Ct STE 104, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Kazio Acrobatics Gymnastics & Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1430" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1430" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1415" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "2045" },
            open: { day: 4, time: "1430" },
          },
          {
            close: { day: 5, time: "1845" },
            open: { day: 5, time: "1430" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 2:30 – 7:00 PM",
          "Tuesday: 2:30 – 7:00 PM",
          "Wednesday: 10:00 AM – 12:00 PM, 2:15 – 7:00 PM",
          "Thursday: 10:00 AM – 12:00 PM, 2:30 – 8:45 PM",
          "Friday: 2:30 – 6:45 PM",
          "Saturday: 9:30 AM – 3:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "https://www.gokazio.com/",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8624303, 37.6703636] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3020 Bernal Ave #140, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 401-7399",
      name: "Orangetheory Fitness",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1930" },
            open: { day: 1, time: "0545" },
          },
          {
            close: { day: 2, time: "1930" },
            open: { day: 2, time: "0430" },
          },
          {
            close: { day: 3, time: "1930" },
            open: { day: 3, time: "0545" },
          },
          {
            close: { day: 4, time: "1930" },
            open: { day: 4, time: "0430" },
          },
          {
            close: { day: 5, time: "1930" },
            open: { day: 5, time: "0545" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 5:45 AM – 7:30 PM",
          "Tuesday: 4:30 AM – 7:30 PM",
          "Wednesday: 5:45 AM – 7:30 PM",
          "Thursday: 4:30 AM – 7:30 PM",
          "Friday: 5:45 AM – 7:30 PM",
          "Saturday: 7:00 AM – 12:00 PM",
          "Sunday: 8:00 AM – 12:00 PM",
        ],
      },
      rating: "4.8",
      website:
        "https://www.orangetheory.com/en-us/locations/california/pleasanton/3020-bernal-avenue-suite-140/?utm_medium=seo&utm_source=gg&utm_term=tombras&utm_campaign=reputationdotcom--National",
      first_type: "gym",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.856669, 37.6693827] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3500 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Vintage Hills Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8560276, 37.6622117] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5674 Sonoma Dr, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 734-0280",
      name: "Elk Park Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1100" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1100" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1100" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1100" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1100" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 11:00 AM – 9:00 PM",
          "Tuesday: 11:00 AM – 9:00 PM",
          "Wednesday: 11:00 AM – 9:00 PM",
          "Thursday: 11:00 AM – 9:00 PM",
          "Friday: 11:00 AM – 9:00 PM",
          "Saturday: 11:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "missing",
      website: "http://elkparkshoppingcenter.com/contact/",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.879075, 37.647931699999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750-6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(800) 762-1641",
      name: "Pleasanton Gateway Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0700" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0700" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 10:00 PM",
          "Tuesday: 7:00 AM – 10:00 PM",
          "Wednesday: 7:00 AM – 10:00 PM",
          "Thursday: 7:00 AM – 10:00 PM",
          "Friday: 7:00 AM – 10:00 PM",
          "Saturday: 7:00 AM – 10:00 PM",
          "Sunday: 7:00 AM – 10:00 PM",
        ],
      },
      rating: "4.3",
      website: "https://www.shoppleasantongateway.com/",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8985715, 37.6568119] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1737 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Amador Center",
      opening_hours: "missing",
      rating: "4.4",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8744059, 37.6754202] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1987-1991 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Mission plaza",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 6:00 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8755828, 37.679043] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3015 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Hopyard Village",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.hopyard.com/",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.897603, 37.676864] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3160 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Santa Rita Square",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:30 PM",
          "Tuesday: 9:00 AM – 9:30 PM",
          "Wednesday: 9:00 AM – 9:30 PM",
          "Thursday: 9:00 AM – 9:30 PM",
          "Friday: 9:00 AM – 9:30 PM",
          "Saturday: 9:00 AM – 9:30 PM",
          "Sunday: 9:00 AM – 9:30 PM",
        ],
      },
      rating: "4.3",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.877434, 37.6906198] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6027 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Val Vista Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 10:00 PM",
          "Tuesday: 6:00 AM – 10:00 PM",
          "Wednesday: 6:00 AM – 10:00 PM",
          "Thursday: 6:00 AM – 10:00 PM",
          "Friday: 6:00 AM – 10:00 PM",
          "Saturday: 6:00 AM – 10:00 PM",
          "Sunday: 6:00 AM – 10:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.90503, 37.6823239] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4225 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(916) 619-5425",
      name: "Rose Pavilion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/rosepavilion",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8753625, 37.6983269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2693-2733 Stoneridge Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(510) 770-9798",
      name: "Pacific Pearl",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:00 AM",
          "Tuesday: 8:00 AM – 12:00 AM",
          "Wednesday: 8:00 AM – 12:00 AM",
          "Thursday: 8:00 AM – 12:00 AM",
          "Friday: 8:00 AM – 12:00 AM",
          "Saturday: 8:00 AM – 12:00 AM",
          "Sunday: 8:00 AM – 12:00 AM",
        ],
      },
      rating: "4.3",
      website: "http://www.shoppacificpearl.com/",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8496833, 37.6962802] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Signature Center, 4801 - 4889 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Gateway Square Shopping Center",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 11:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9024115, 37.6925378] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3020 W Jack London Blvd, Livermore, CA 94551, USA",
      formatted_phone_number: "(862) 203-4352",
      name: "Shops at Livermore",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1730" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "2000" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 7:00 PM",
          "Tuesday: 10:00 AM – 7:00 PM",
          "Wednesday: 10:00 AM – 7:00 PM",
          "Thursday: 10:00 AM – 7:00 PM",
          "Friday: 10:00 AM – 8:00 PM",
          "Saturday: 10:00 AM – 8:00 PM",
          "Sunday: 10:00 AM – 5:30 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.was-group.com/portfolio-item/shoppes-at-livermore/",
      first_type: "shopping_mall",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8454558, 37.6968747] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5420 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-7944",
      name: "Raley's Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1330" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Tuesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Wednesday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Thursday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Friday: 10:00 AM – 1:30 PM, 2:00 – 6:30 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.3",
      website: "http://www.raleys.com/",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8785381, 37.65176] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6750 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 249-9011",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/6750-bernal-ave-pleasanton-ca-94566/storeid=10119?WT.mc_id=LS_GOOGLE_RX_10119",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.897972, 37.6565793] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3010 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-9138",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1100" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: 10:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 11:00 AM – 1:30 PM, 2:00 – 5:00 PM",
        ],
      },
      rating: "3.4",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/3010-bernal-avenue-pleasanton-ca-94566/storeid=10924?WT.mc_id=LS_GOOGLE_RX_10924",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8567369, 37.6699032] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 484-1960",
      name: "Safeway Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1345" },
          },
          {
            close: { day: 1, time: "1300" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1345" },
          },
          {
            close: { day: 2, time: "1300" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1345" },
          },
          {
            close: { day: 3, time: "1300" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1345" },
          },
          {
            close: { day: 4, time: "1300" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1345" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1345" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1345" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Tuesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Wednesday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Thursday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Friday: 9:00 AM – 1:00 PM, 1:45 – 8:00 PM",
          "Saturday: 9:00 AM – 1:00 PM, 1:45 – 5:00 PM",
          "Sunday: 10:00 AM – 1:00 PM, 1:45 – 5:00 PM",
        ],
      },
      rating: "2.7",
      website:
        "https://local.pharmacy.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899566, 37.6560943] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Rite Aid Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: Closed",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 9:00 PM",
          "Saturday: 9:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "5",
      website:
        "https://www.riteaid.com/locations/ca/pleasanton/2819-hopyard-road.html",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8948191, 37.6759839] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 734-9024",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1600" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 4:00 PM",
        ],
      },
      rating: "3.5",
      website: "https://www.walmart.com/store/2161-pleasanton-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.887287, 37.698316] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3999 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8552",
      name: "CVS Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1230" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1300" },
          },
          {
            close: { day: 1, time: "1230" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1300" },
          },
          {
            close: { day: 2, time: "1230" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1300" },
          },
          {
            close: { day: 3, time: "1230" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1300" },
          },
          {
            close: { day: 4, time: "1230" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "1300" },
          },
          {
            close: { day: 5, time: "1230" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1300" },
          },
          {
            close: { day: 6, time: "1230" },
            open: { day: 6, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Tuesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Wednesday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Thursday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Friday: 8:00 AM – 12:30 PM, 1:00 – 9:00 PM",
          "Saturday: 9:00 AM – 12:30 PM, 1:00 – 6:00 PM",
          "Sunday: 10:00 AM – 12:30 PM, 1:00 – 6:00 PM",
        ],
      },
      rating: "3.7",
      website:
        "https://www.cvs.com/store-locator/pleasanton-ca-pharmacies/3999-santa-rita-road-pleasanton-ca-94588/storeid=9251?WT.mc_id=LS_GOOGLE_RX_9251",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8759178, 37.6971875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6372",
      name: "Walmart Pharmacy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 10:00 AM – 6:00 PM",
          "Sunday: 10:00 AM – 6:00 PM",
        ],
      },
      rating: "4.2",
      website: "https://www.walmart.com/store/5611-pleasanton-ca/pharmacy",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "Chinese herbal medicine",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8757968, 37.6604484] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4271 First St, Pleasanton, CA 945667123, USA",
      formatted_phone_number: "(925) 846-4455",
      name: "Bertha Ortiz",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8710257, 37.6626364] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 945661218, USA",
      formatted_phone_number: "(925) 484-1960",
      name: "Michael Lai",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8994836, 37.6560494] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1763 Santa Rita Rd, Pleasanton, CA 945665657, USA",
      formatted_phone_number: "(925) 426-1562",
      name: "Mahesh Makwana",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8750285, 37.6759064] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1763 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-1562",
      name: "Walgreens Pharmacy",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1330" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "1400" },
          },
          {
            close: { day: 1, time: "1330" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "1400" },
          },
          {
            close: { day: 2, time: "1330" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "1400" },
          },
          {
            close: { day: 3, time: "1330" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "1400" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "1400" },
          },
          {
            close: { day: 6, time: "1330" },
            open: { day: 6, time: "1000" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1400" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Tuesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Wednesday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Thursday: Closed",
          "Friday: 9:00 AM – 1:30 PM, 2:00 – 7:00 PM",
          "Saturday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
          "Sunday: 10:00 AM – 1:30 PM, 2:00 – 6:00 PM",
        ],
      },
      rating: "2.1",
      website:
        "https://www.walgreens.com/locator/walgreens-1763+santa+rita+rd-pleasanton-ca-94566/id=2150",
      first_type: "pharmacy",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8748575, 37.6759832] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 945885241, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Paul Chan",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8947708, 37.6759516] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 945885241, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Umakant Trivedi",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8947708, 37.6759516] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2819 Hopyard Rd, Pleasanton, CA 945885241, USA",
      formatted_phone_number: "(925) 846-8345",
      name: "Parmjeet Virk",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8947708, 37.6759516] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3059 Hopyard Rd Suite A2-B, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 623-5511",
      name: "Apotheco Pharmacy Choice Bay Area",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website:
        "https://www.apothecopharmacy.com/location/apotheco-pharmacy-choice-bay-area/",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.896238, 37.6774926] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "5860 W Las Positas Blvd #19, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(855) 434-4844",
      name: "KabaFusion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 5:00 PM",
          "Tuesday: 8:30 AM – 5:00 PM",
          "Wednesday: 8:30 AM – 5:00 PM",
          "Thursday: 8:30 AM – 5:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "missing",
      website: "https://kabafusion.com/",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.896827, 37.6837559] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4234 Hacienda Dr Ste 150, Pleasanton, CA 945882721, USA",
      formatted_phone_number: "(925) 924-0061",
      name: "Hilda Chung",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.8957934, 37.687951] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3999 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 460-8552",
      name: "Thanh Phuong Le",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "pharmacy",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8759178, 37.6971875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Hayward, CA, USA",
      formatted_phone_number: "missing",
      name: "Hayward",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.hayward-ca.gov/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-122.0809964, 37.6687665] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1472 Cedarwood Ln, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-2375",
      name: "Jeffery E. Teutsch DDS",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 7:30 AM – 2:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.snazzysmiles.com/",
      first_type: "dentist",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8752115, 37.671497300000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2005 Valley Ave Gate 8, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-7600",
      name: "Alameda County Fairgrounds",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "1200" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "1200" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1200" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "1200" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "1200" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: 12:00 – 11:00 PM",
          "Thursday: 12:00 – 8:00 PM",
          "Friday: 12:00 – 11:00 PM",
          "Saturday: 12:00 – 11:00 PM",
          "Sunday: 12:00 – 11:00 PM",
        ],
      },
      rating: "4.3",
      website: "http://www.alamedacountyfair.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.8968548, 37.660049699999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2025 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-2742",
      name: "Tri Valley Inn & Suites",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "3.9",
      website: "https://www.trivalleyinn.com/",
      first_type: "lodging",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8753621, 37.6799088] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Pleasanton Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 399-8644",
      name: "The First Tee of The Tri-Valley",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "4.7",
      website: "https://firstteetrivalley.org/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8822041, 37.6598417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5059 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-1414",
      name: "Courtyard Pleasanton",
      opening_hours: "missing",
      rating: "4.2",
      website:
        "https://www.marriott.com/en-us/hotels/oakpl-courtyard-pleasanton/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9023404, 37.6940157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8500 Clubhouse Dr, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-6666",
      name: "Callippe Preserve Golf Course",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.5",
      website: "https://www.playcallippe.com/",
      first_type: "tourist_attraction",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8603759, 37.6267405] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "405 Boulder Ct, Pleasanton, CA 94559, USA",
      formatted_phone_number: "(510) 782-5316",
      name: "Southern Alameda County Dental",
      opening_hours: "missing",
      rating: "3.5",
      website: "http://www.sacds.org/",
      first_type: "dentist",
      zip_code: "94559",
    },
    geometry: { type: "Point", coordinates: [-121.8637101, 37.671993] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2500 Stanley Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(510) 544-3230",
      name: "Shadow Cliffs Regional Recreation Area",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1900" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "1900" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 7:00 PM",
          "Tuesday: 6:00 AM – 7:00 PM",
          "Wednesday: 6:00 AM – 7:00 PM",
          "Thursday: 6:00 AM – 7:00 PM",
          "Friday: 6:00 AM – 7:00 PM",
          "Saturday: 6:00 AM – 7:00 PM",
          "Sunday: 6:00 AM – 7:00 PM",
        ],
      },
      rating: "4.4",
      website: "https://www.ebparks.org/parks/shadow-cliffs",
      first_type: "park",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.841661, 37.6692998] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4501 Pleasanton Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "missing",
      name: "ATM",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "atm",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8869232, 37.6619067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4545 Chabot Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 730-0070",
      name: "Hyatt House Pleasanton",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.3",
      website:
        "https://www.hyatt.com/en-US/hotel/california/hyatt-house-pleasanton/oakxp/?src=corp_lclb_gmb_seo_oakxp",
      first_type: "lodging",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.900912, 37.689132] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1184 Vineyard Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-8666",
      name: "Palm Event Center in the Vineyard",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "4.8",
      website: "http://www.palmeventcenter.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8204379, 37.6544004] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5424 Sunol Blvd #10, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(510) 862-7904",
      name: "Accord Property Management",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.tricityhome.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8786905, 37.6512472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5733 Hanifen Way, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 330-0365",
      name: "Quicksilver Conicerge & Errand Service",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: 8:00 AM – 6:00 PM",
          "Sunday: 8:00 AM – 6:00 PM",
        ],
      },
      rating: "missing",
      website: "http://www.quicksilverconcierge.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8678119, 37.6435827] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4695 Chabot Drive, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 396-4678",
      name: "Law Office of K. Walter Johnson",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "5",
      website: "http://www.bestestateplan.com/",
      first_type: "lawyer",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8844291, 37.6755305] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "807 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-8802",
      name: "The Rose Hotel",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.7",
      website: "https://www.rosehotel.net/",
      first_type: "lodging",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8748136, 37.663977] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "6055 Sunol Blvd Building C, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 600-2522",
      name: "Thermo Fisher Scientific",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      rating: "4.6",
      website: "https://www.thermofisher.com/",
      first_type: "point_of_interest",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8839219, 37.645538] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "410 Vineyard Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 931-0200",
      name: "Casa Real at Ruby Hill Winery",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 9:00 AM – 12:00 PM",
          "Sunday: 9:00 AM – 12:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://www.casarealevents.com/",
      first_type: "food",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8072222, 37.6513889] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "780 Main St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-8438",
      name: "Oasis Restaurant & Wine Lounge",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "1130" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "1130" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "1130" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "1130" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "1130" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1130" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "1130" },
          },
        ],
        weekday_text: [
          "Monday: 11:30 AM – 9:00 PM",
          "Tuesday: 11:30 AM – 9:00 PM",
          "Wednesday: 11:30 AM – 9:00 PM",
          "Thursday: 11:30 AM – 9:00 PM",
          "Friday: 11:30 AM – 9:00 PM",
          "Saturday: 11:30 AM – 9:00 PM",
          "Sunday: 11:30 AM – 9:00 PM",
        ],
      },
      rating: "4.4",
      website: "http://www.oasiswinelounge.com/",
      first_type: "bar",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8742229, 37.6637824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Livermore, CA, USA",
      formatted_phone_number: "missing",
      name: "Livermore",
      opening_hours: "missing",
      rating: "missing",
      website: "http://www.cityoflivermore.net/",
      first_type: "locality",
      zip_code: null,
    },
    geometry: { type: "Point", coordinates: [-121.7684732, 37.6818688] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4299 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-8899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "3.9",
      website: "http://www.99ranch.com/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8733168, 37.6995615] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3903 Santa Rita Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 468-0763",
      name: "Trader Joe's",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website:
        "https://locations.traderjoes.com/ca/pleasanton/66/?utm_source=gmb&utm_medium=organic&utm_campaign=local",
      first_type: "grocery_or_supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8768923, 37.6967871] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1701 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 417-5530",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/1701-santa-rita-rd.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8744057, 37.6748422] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6790 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-8644",
      name: "Safeway",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0200" },
            open: { day: 0, time: "0500" },
          },
          {
            close: { day: 2, time: "0200" },
            open: { day: 1, time: "0500" },
          },
          {
            close: { day: 3, time: "0200" },
            open: { day: 2, time: "0500" },
          },
          {
            close: { day: 4, time: "0200" },
            open: { day: 3, time: "0500" },
          },
          {
            close: { day: 5, time: "0200" },
            open: { day: 4, time: "0500" },
          },
          {
            close: { day: 6, time: "0200" },
            open: { day: 5, time: "0500" },
          },
          {
            close: { day: 0, time: "0200" },
            open: { day: 6, time: "0500" },
          },
        ],
        weekday_text: [
          "Monday: 5:00 AM – 2:00 AM",
          "Tuesday: 5:00 AM – 2:00 AM",
          "Wednesday: 5:00 AM – 2:00 AM",
          "Thursday: 5:00 AM – 2:00 AM",
          "Friday: 5:00 AM – 2:00 AM",
          "Saturday: 5:00 AM – 2:00 AM",
          "Sunday: 5:00 AM – 2:00 AM",
        ],
      },
      rating: "4.2",
      website:
        "https://local.safeway.com/safeway/ca/pleasanton/6790-bernal-ave.html?utm_source=G&utm_medium=Maps&utm_campaign=G+Places",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.899526, 37.6560809] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3112 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-6369",
      name: "Walmart Neighborhood Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 11:00 PM",
          "Saturday: 6:00 AM – 11:00 PM",
          "Sunday: 6:00 AM – 11:00 PM",
        ],
      },
      rating: "4.1",
      website: "https://www.walmart.com/store/5611-pleasanton-ca",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8771697, 37.6899077] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2803 Hopyard Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 846-8220",
      name: "Gene's Fine Foods",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website: "http://genesfinefoods.org/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8950958, 37.6756515] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6155 W Las Positas Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 462-1520",
      name: "Lucky",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 11:00 PM",
          "Tuesday: 6:00 AM – 11:00 PM",
          "Wednesday: 6:00 AM – 11:00 PM",
          "Thursday: 6:00 AM – 11:00 PM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.3",
      website: "https://luckysupermarkets.com/",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.9050911, 37.6823257] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1257 Quarry Ln # 155, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 249-9457",
      name: "Distribu Tech",
      opening_hours: "missing",
      rating: "missing",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8654299, 37.6746601] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "126 Spring St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-8595",
      name: "Hidalgo's Little Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.1",
      website: "missing",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8726443, 37.662198] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3550 Bernal Ave STE 105, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 398-8649",
      name: "Grocery Outlet",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 8:00 AM – 9:00 PM",
          "Sunday: 8:00 AM – 9:00 PM",
        ],
      },
      rating: "4.6",
      website: "https://groceryoutlet.com/circulars/storeid/478",
      first_type: "grocery_or_supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8559381, 37.6619076] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3121 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-1083",
      name: "ampm",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 9:00 PM",
          "Tuesday: 6:00 AM – 9:00 PM",
          "Wednesday: 6:00 AM – 9:00 PM",
          "Thursday: 6:00 AM – 9:00 PM",
          "Friday: 6:00 AM – 9:00 PM",
          "Saturday: 6:00 AM – 9:00 PM",
          "Sunday: 6:00 AM – 9:00 PM",
        ],
      },
      rating: "2.6",
      website: "https://www.ampm.com/",
      first_type: "convenience_store",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8586053, 37.6690808] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3160 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 461-3000",
      name: "New India Bazar",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2130" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2130" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "2130" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 9:30 PM",
          "Tuesday: 8:30 AM – 9:30 PM",
          "Wednesday: 8:30 AM – 9:30 PM",
          "Thursday: 8:30 AM – 9:30 PM",
          "Friday: 8:30 AM – 9:30 PM",
          "Saturday: 8:30 AM – 9:30 PM",
          "Sunday: 8:30 AM – 9:30 PM",
        ],
      },
      rating: "4.2",
      website: "http://www.newindiabazar.com/",
      first_type: "supermarket",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8772963, 37.6908999] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4225 Rosewood Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(916) 619-5425",
      name: "Rose Pavilion",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "0000" },
            open: { day: 0, time: "0600" },
          },
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0600" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0600" },
          },
          {
            close: { day: 4, time: "0000" },
            open: { day: 3, time: "0600" },
          },
          {
            close: { day: 5, time: "0000" },
            open: { day: 4, time: "0600" },
          },
          {
            close: { day: 6, time: "0000" },
            open: { day: 5, time: "0600" },
          },
          {
            close: { day: 0, time: "0000" },
            open: { day: 6, time: "0600" },
          },
        ],
        weekday_text: [
          "Monday: 6:00 AM – 12:00 AM",
          "Tuesday: 6:00 AM – 12:00 AM",
          "Wednesday: 6:00 AM – 12:00 AM",
          "Thursday: 6:00 AM – 12:00 AM",
          "Friday: 6:00 AM – 12:00 AM",
          "Saturday: 6:00 AM – 12:00 AM",
          "Sunday: 6:00 AM – 12:00 AM",
        ],
      },
      rating: "4.1",
      website:
        "https://properties.brixmor.com/p/retail-real-estate/Pleasanton-CA-94588/rosepavilion",
      first_type: "shopping_mall",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8753625, 37.6983269] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2701 Stoneridge Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 594-6899",
      name: "99 Ranch Market",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:00 PM",
          "Tuesday: 8:00 AM – 10:00 PM",
          "Wednesday: 8:00 AM – 10:00 PM",
          "Thursday: 8:00 AM – 10:00 PM",
          "Friday: 8:00 AM – 10:00 PM",
          "Saturday: 8:00 AM – 10:00 PM",
          "Sunday: 8:00 AM – 10:00 PM",
        ],
      },
      rating: "4.2",
      website: "missing",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8498644, 37.6956681] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4040 Pimlico Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 249-5165",
      name: "INDIA CASH & CARRY",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2200" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2200" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2200" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2200" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2200" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2200" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2200" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 10:00 PM",
          "Tuesday: 9:00 AM – 10:00 PM",
          "Wednesday: 9:00 AM – 10:00 PM",
          "Thursday: 9:00 AM – 10:00 PM",
          "Friday: 9:00 AM – 10:00 PM",
          "Saturday: 9:00 AM – 10:00 PM",
          "Sunday: 9:00 AM – 10:00 PM",
        ],
      },
      rating: "3.8",
      website: "http://www.indiacashandcarry.com/",
      first_type: "supermarket",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8703254, 37.6996572] },
  },
];
