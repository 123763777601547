import React from "react";

const BuyerHero = () => {
  return (
    <div className="w-full h-[780px] sm:h-[900px] bg-buyer-landing-bg bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-hero-section-overlay bg-cover flex justify-center">
        <div className="z-10 sm:px-6 lg:px-8 my-[90px] sm:mt-[120px]">
          <h2 className="text-[30px] text-white leading-[40px] capitalize w-[314px] sm:w-auto lg:w-[1006px] mx-auto sm:mx-0  mt-[150px] text-center sm:text-5xl md:text-[64px] sm:leading-[82.4px] font-light">
            Buy your{" "}
            <span className="text-[#ED5272] font-bold">dream home</span>
          </h2>

          <p className="text-sm sm:text-xl text-white font-medium text-center">
            Convenient, Stress-free, and Affordable!
          </p>

          {/* hero buttons */}
          <div className="w-full sm:max-w-[710px] sm:mx-auto h-auto sm:h-[60px] flex flex-col sm:flex-row sm:items-center sm:justify-center gap-5 sm:gap-8 mt-[150px] sm:px-0 sm:mb-0">
            <button
              className="border border-white rounded-[50px] h-full w-full sm:w-[310px]
             text-white py-3 sm:py-0 px-[10px] text-center text-[14px] sm:text-[22px] sm:leading-[32px] hover:bg-[#ED5272]
             hover:border-none">
              <a href="#schedule-call">Schedule a call</a>
            </button>
            <button
              className="border border-white rounded-[50px] h-full w-full sm:w-[310px]
        text-white py-3 sm:py-0 px-[10px] text-center text-[14px] sm:text-[22px] sm:leading-[32px] hover:bg-[#ED5272]
        hover:border-none">
              <a href="/buy"> Check if you qualify </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerHero;
