import React from "react";
import HeroSection from "../components/HomeAnalytics/HeroSection";
import EmbedingContent from "../components/HomeAnalytics/EmbedingContent";
import PinkSection from "../components/HomePage/Pink";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import Header from "../components/HomePage/Navbar";

const HomeAnalytics = () => {
  return (
    <div>
      <Header/>
      <HeroSection />
      <EmbedingContent />
      <PinkSection/>
      <Footer/>
    </div>
  );
};

export default HomeAnalytics;
