import React from "react";

const ToolsResources = () => {
  return (
    <div className="bg-[#F1F3F5] text-[#212529] px-3 py-10 sm:p-0 xl:pt-[74px] xl:pb-[80px] xl:px-5">
      <h2 className="text-[32px] sm:text-6xl font-normal text-center px-4 sm:px-8 w-full mb-[50px]">
        Tools & Resources
      </h2>
      <div className="flex flex-wrap lg:flex-nowrap mt-2 justify-between gap-8 2xl:gap-0">
        {/* Tools & Resources Section */}
        <div className="w-full lg:w-3/5 px-0">
          <div className="grid grid-cols-2 gap-4">
            {/* Left column items */}
            <div className="space-y-6 w-full sm:w-80 2xl:w-96">
              <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                <a
                  href="https://www.resiclubanalytics.com/p/ceo-accurate-home-price-forecaster-2023-heres-expects-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-inherit">
                  Home Price Forecast for 2024
                </a>
              </div>

              <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                <a
                  href="https://www.investopedia.com/homebuying-guide-4776300"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-inherit">
                  Home buying guide{" "}
                </a>
              </div>

              <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                <a
                  href="https://www.amazon.com/dp/B0BZWH6H71?ref_=cm_sw_r_cp_ud_dp_98CQ8KEBR46RPHH3PG64"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-inherit">
                  Home ownership guide{" "}
                </a>
              </div>
              <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                <a
                  href="https://www.bankrate.com/real-estate/how-to-sell-your-house/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-inherit">
                  Home selling guide{" "}
                </a>
              </div>
            </div>

            {/* Right column items */}
            <div className="space-y-6 w-full sm:w-96">
              <a
                href="https://www.nerdwallet.com/mortgages/refinance-calculator"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-inherit">
                <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                  Home Affordability calculator
                </div>{" "}
              </a>
              <a
                href=" https://www.nerdwallet.com/mortgages/rent-vs-buy-calculator"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-inherit">
                <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                  Rent vs. Buy calculator
                </div>
              </a>
              <a
                href=" https://www.nerdwallet.com/mortgages/rent-vs-buy-calculator"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-inherit">
                <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                  Sell vs. Rent calculator
                </div>
              </a>

              <a
                href="https://www.bankrate.com/mortgages/refinance-calculator/"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-inherit">
                <div className="border border-black p-4 rounded hover:bg-[#ED5272] hover:text-white cursor-pointer">
                  Refinance calculator
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* Market Trends Section */}
        <div className="w-full text-center bg-white rounded-lg">
          <div className=" p-1 sm:p-4">
            {/* Table */}
            <div className="border-collapse">
              <table className="min-w-full table-auto">
                <thead className="border-b">
                  <tr>
                    <th className="text-left p-4">Indicator</th>
                    <th className="p-4">Latest</th>
                    <th className="p-4">MoM</th>
                    <th className="p-4">YoY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="text-left p-4 text-sm sm:text-lg">
                      30-Year Mortgage Rate
                    </td>
                    <td className="p-4">7.17%</td>
                    <td className="p-4">38 bps</td>
                    <td className="p-4">74 bps</td>
                  </tr>
                  <tr className="border-b">
                    <td className="text-left p-4 text-sm sm:text-lg">
                      S&P C-S Home Price Index
                    </td>
                    <td className="p-4">315.4</td>
                    <td className="p-4">0.4%</td>
                    <td className="p-4">6.1%</td>
                  </tr>
                  <tr className="border-b">
                    <td className="text-left p-4 text-sm sm:text-lg">
                      Active Inventory
                    </td>
                    <td className="p-4">694,820</td>
                    <td className="p-4">4.5%</td>
                    <td className="p-4">23.5%</td>
                  </tr>
                  <tr>
                    <td className="text-left p-4 text-sm sm:text-lg">
                      Median Days on Market
                    </td>
                    <td className="p-4">50</td>
                    <td className="p-4">-11 days</td>
                    <td className="p-4">-2 days</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* More items if necessary */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsResources;
