import React from "react";
import logo from "../../assets/logo_upscaled 2.png";
import checkIcon from "../../assets/check-icon.svg";
import crossIcon from "../../assets/cross-icon.svg";


const StandsOut = () => {
  return (
    <div className="h-full w-full bg-white py-10 sm:py-0 sm:pt-[74px] sm:pb-20">
     <div className="w-full px-4 xl:max-w-[1580px] mx-auto mb-8 sm:mb-20">
     <h2 className="text-3xl sm:text-6xl font-light text-center mb-[90px]">How <span className="text-[#ED5272] font-bold">Home.LLC</span> Stands out</h2>
      </div>

      <div className="w-full gap-4 overflow-x-auto px-1 sm:px-10 2xl:px-[160px]">
        <div className="w-full pr-0 px-2">
          <table className="w-full text-black">
            <thead className="border-b border-dashed">
              <tr className="border-b border-dashed">
                <td className="w-[140px]"></td>
                <td className="text-center p-4 bg-[#AEE5D842] bg-opacity-25">
                  <img src={logo} alt="company-logo" className="w-24 mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  Credit Card
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  HELOC/ HELoan
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  Reverse Mortgage
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-dashed text-left">
                <td className="w-[200px]">Eligibility</td>
                <td className="text-center  py-4 px-2 bg-[#AEE5D833] bg-opacity-25">
                  <img src={checkIcon} alt="check-icon"  className="w-[25px] mx-auto"  />
                </td>
                <td className="text-center p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={checkIcon} alt="check-icon" className="w-[25px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={checkIcon} alt="check-icon" className="w-[27px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={crossIcon} alt="check-icon"  className="w-[20px] mx-auto" />
                </td>
              </tr>
              <tr className="border-b border-dashed text-left">
                <td>Term</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-25 flex flex-col items-center lg:items-center gap-2">
                  <img src={checkIcon} alt="check-icon" />
                  <p>Up to 25 years</p>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  To calculate*
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  10-15 years
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  10-15 years
                </td>
              </tr>
              <tr className="border-b border-dashed text-left">
                <td>Minimum monthly payment</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-25 flex flex-col items-center lg:items-center gap-2">
                  <img src={checkIcon} alt="check-icon" />
                  <p>$0</p>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  $1,416
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  $588 - $606
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  $0
                </td>
              </tr>
              <tr className="border-b border-dashed text-left">
                <td>Pre-payment penalty</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-25 flex flex-col items-center lg:items-center gap-2">
                <img src={checkIcon} alt="check-icon" className="w-[25px] mx-auto" />
                  <p>No penalty</p>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={checkIcon} alt="check-icon" className="w-[25px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={crossIcon} alt="check-icon" className="w-[20px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={crossIcon} alt="check-icon" className="w-[20px] mx-auto" />
                </td>
              </tr>
              <tr className="border-b border-dashed text-left">
                <td>Interest rate</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-25 flex flex-col items-center lg:items-center gap-2">
                  <img src={checkIcon} alt="check-icon" />
                  <p>Not applicable</p>
                </td>
                <td className="text-center  p-4 bg-[#a0e3d333] bg-opacity-20">
                  <a href="https://www.investopedia.com/average-credit-card-interest-rate-5076674" rel="noreferrer" target="_blank"
                    className="text-black underline">24.37%</a>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <a href="https://www.bankrate.com/home-equity/current-interest-rates/?zipCode=576104" rel="noreferrer" target="_blank"
                    className="text-black underline">8.50% - 10.47%</a>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <a href="https://reverse.mortgage/rates" target="_blank" rel="noreferrer"
                    className="text-black underline">7.56% - 7.9%%</a>
                </td>
              </tr>
              <tr className="text-left border-b border-dashed">
                <td>Lifetime cost</td>
                <td className="text-center p-4 bg-[#AEE5D833] bg-opacity-25 flex flex-col items-center lg:items-center gap-2">
                  <img src={checkIcon} alt="check-icon" />
                  <p className=" w-[164px]">$50,000 to $100,000 over 25years</p>
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                  <p className="w-[164px] mx-auto">
                   Upto $141,028 over 42 years 
                  </p>
                  
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">-</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">-</td>
              </tr>
              <tr className="text-left">
                <td>Positive Impact on FICO</td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-25">
                <img src={checkIcon} alt="check-icon" className="w-[25px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={checkIcon} alt="check-icon" className="w-[25px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={crossIcon} alt="check-icon" className="w-[20px] mx-auto" />
                </td>
                <td className="text-center  p-4 bg-[#AEE5D833] bg-opacity-20">
                <img src={crossIcon} alt="check-icon" className="w-[20px] mx-auto" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StandsOut;
