import React from "react";
import BlogsHero from "../components/BlogsLanding/BlogsHero";
import Blogs from "../components/BlogsLanding/Blogs";
import Header from "../components/HomePage/Navbar";
import PinkSection from "../components/HomePage/Pink";
// import Footer from "../components/HomePage/Footer";
// import FaqSection from "../components/Faq/FaqSection";
// import FaqHero from "../components/Faq/FaqHero";
import Footer from "../components/Footer";

const BlogsPage = () => {
  return (
    <div className="overflow-x-hidden">
      <Header/>
      <BlogsHero />
      <Blogs />
      <PinkSection/>
      <Footer/>
    </div>
  );
};

export default BlogsPage;
