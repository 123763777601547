import React, { useRef } from 'react'
import svgBg from '../../assets/svg-bg.png'
import card1 from '../../assets/AiPage/card-1.jpg'
import card2 from '../../assets/AiPage/card-2.jpg'
import card3 from '../../assets/AiPage/card-3.jpg'
import card4 from '../../assets/AiPage/card-4.jpg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlArrowLeft, SlArrowRight  } from "react-icons/sl";

const ArrowUp = () => {
  return (
    <svg width="72" height="45" viewBox="0 0 72 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M59.0481 0.522014L49.5989 0.595794L48.9934 1.04497C47.2712 2.32285 47.2712 4.91845 48.9934 6.19663L49.5986 6.64581L55.4526 6.71959L61.3065 6.79337L50.7634 17.9331L40.2201 29.0726L33.6209 22.1079C29.0986 17.3353 26.8582 15.0716 26.5023 14.9156C25.8048 14.6097 24.7706 14.6334 24.0889 14.9709C23.7057 15.1607 19.882 19.111 11.9157 27.5473C-0.332178 40.5178 0.000886189 40.1306 0.000886189 41.3965C0.000886189 43.6235 2.35426 45.1782 4.24945 44.2033C4.62888 44.008 8.22165 40.3128 15.0804 33.0631L25.3435 22.2151L32.0478 29.285C39.0726 36.6925 39.0508 36.6729 40.2185 36.6729C41.4181 36.6729 41.0314 37.0455 53.5825 23.7969L65.4804 11.2381L65.5226 17.2791L65.5649 23.3202L65.914 23.9778C66.9154 25.8636 69.3532 26.1677 70.7138 24.5763C70.9823 24.2621 71.2687 23.7514 71.3503 23.4414C71.4479 23.0697 71.4987 19.4816 71.4992 12.9092L71.5 2.94099L71.1463 2.19078C70.7279 1.30379 70.0007 0.718566 69.1202 0.560232C68.7778 0.498552 64.2453 0.481434 59.0481 0.522014Z" fill="#ED5272"/>
</svg>
  )
}

const ArrowDown = () => {
  return (
<svg width="72" height="45" viewBox="0 0 72 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M59.0481 44.478L49.5989 44.4042L48.9934 43.955C47.2712 42.6771 47.2712 40.0815 48.9934 38.8034L49.5986 38.3542L55.4526 38.2804L61.3065 38.2066L50.7634 27.0669L40.2201 15.9274L33.6209 22.8921C29.0986 27.6647 26.8582 29.9284 26.5023 30.0844C25.8048 30.3903 24.7706 30.3666 24.0889 30.0291C23.7057 29.8393 19.882 25.889 11.9157 17.4527C-0.332178 4.48224 0.000886189 4.86944 0.000886189 3.60351C0.000886189 1.37652 2.35426 -0.178188 4.24945 0.796749C4.62888 0.991974 8.22165 4.6872 15.0804 11.9369L25.3435 22.7849L32.0478 15.715C39.0726 8.30747 39.0508 8.3271 40.2185 8.3271C41.4181 8.3271 41.0314 7.9545 53.5825 21.2031L65.4804 33.7619L65.5226 27.7209L65.5649 21.6798L65.914 21.0222C66.9154 19.1364 69.3532 18.8323 70.7138 20.4237C70.9823 20.7379 71.2687 21.2486 71.3503 21.5586C71.4479 21.9303 71.4987 25.5184 71.4992 32.0908L71.5 42.059L71.1463 42.8092C70.7279 43.6962 70.0007 44.2814 69.1202 44.4398C68.7778 44.5014 64.2453 44.5186 59.0481 44.478Z" fill="#ED5272"/>
</svg>
  )
}

const HighArrow = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.5" cy="11" r="10.5" fill="#009B00"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.736 7.504L13.018 7.51742L12.9079 7.59909C12.5948 7.83143 12.5948 8.30336 12.9079 8.53575L13.0179 8.61742L14.0823 8.63083L15.1466 8.64425L13.2297 10.6696L11.3128 12.695L10.1129 11.4287C9.29065 10.561 8.88331 10.1494 8.81861 10.121C8.69178 10.0654 8.50374 10.0697 8.3798 10.1311C8.31013 10.1656 7.6149 10.8838 6.16648 12.4177C3.9396 14.776 4.00016 14.7056 4.00016 14.9357C4.00016 15.3406 4.42805 15.6233 4.77263 15.446C4.84161 15.4105 5.49484 14.7387 6.74189 13.4206L8.60792 11.4482L9.82688 12.7336C11.1041 14.0805 11.1002 14.0769 11.3124 14.0769C11.5306 14.0769 11.4602 14.1446 13.7423 11.7358L15.9055 9.45237L15.9132 10.5507L15.9209 11.6491L15.9844 11.7687C16.1664 12.1116 16.6097 12.1669 16.857 11.8775C16.9059 11.8204 16.958 11.7275 16.9728 11.6712C16.9905 11.6036 16.9998 10.9512 16.9998 9.75622L17 7.94382L16.9357 7.80742C16.8596 7.64614 16.7274 7.53974 16.5673 7.51095C16.5051 7.49974 15.681 7.49662 14.736 7.504Z" fill="white"/>
</svg>

  )
}

const RiskArrow = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.5" cy="11" r="10.5" fill="#D62728"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.736 15.496L13.018 15.4826L12.9079 15.4009C12.5948 15.1686 12.5948 14.6966 12.9079 14.4642L13.0179 14.3826L14.0823 14.3692L15.1466 14.3558L13.2297 12.3304L11.3128 10.305L10.1129 11.5713C9.29065 12.439 8.88331 12.8506 8.81861 12.879C8.69178 12.9346 8.50374 12.9303 8.3798 12.8689C8.31013 12.8344 7.6149 12.1162 6.16648 10.5823C3.9396 8.22404 4.00016 8.29444 4.00016 8.06428C4.00016 7.65937 4.42805 7.37669 4.77263 7.55396C4.84161 7.58945 5.49484 8.26131 6.74189 9.57943L8.60792 11.5518L9.82688 10.2664C11.1041 8.91954 11.1002 8.92311 11.3124 8.92311C11.5306 8.92311 11.4602 8.85536 13.7423 11.2642L15.9055 13.5476L15.9132 12.4493L15.9209 11.3509L15.9844 11.2313C16.1664 10.8884 16.6097 10.8331 16.857 11.1225C16.9059 11.1796 16.958 11.2725 16.9728 11.3288C16.9905 11.3964 16.9998 12.0488 16.9998 13.2438L17 15.0562L16.9357 15.1926C16.8596 15.3539 16.7274 15.4603 16.5673 15.489C16.5051 15.5003 15.681 15.5034 14.736 15.496Z" fill="white"/>
</svg>
  )
}

const ModArrow = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10.5" cy="11" r="10.5" fill="#98DF8A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M14.736 7.504L13.018 7.51742L12.9079 7.59909C12.5948 7.83143 12.5948 8.30336 12.9079 8.53575L13.0179 8.61742L14.0823 8.63083L15.1466 8.64425L13.2297 10.6696L11.3128 12.695L10.1129 11.4287C9.29065 10.561 8.88331 10.1494 8.81861 10.121C8.69178 10.0654 8.50374 10.0697 8.3798 10.1311C8.31013 10.1656 7.6149 10.8838 6.16648 12.4177C3.9396 14.776 4.00016 14.7056 4.00016 14.9357C4.00016 15.3406 4.42805 15.6233 4.77263 15.446C4.84161 15.4105 5.49484 14.7387 6.74189 13.4206L8.60792 11.4482L9.82688 12.7336C11.1041 14.0805 11.1002 14.0769 11.3124 14.0769C11.5306 14.0769 11.4602 14.1446 13.7423 11.7358L15.9055 9.45237L15.9132 10.5507L15.9209 11.6491L15.9844 11.7687C16.1664 12.1116 16.6097 12.1669 16.857 11.8775C16.9059 11.8204 16.958 11.7275 16.9728 11.6712C16.9905 11.6036 16.9998 10.9512 16.9998 9.75622L17 7.94382L16.9357 7.80742C16.8596 7.64614 16.7274 7.53974 16.5673 7.51095C16.5051 7.49974 15.681 7.49662 14.736 7.504Z" fill="white"/>
</svg>

  )
}


const LowArrow = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="11" r="10.5" fill="#B9AFAB" />
<path fillRule="evenodd" clipRule="evenodd" d="M14.736 15.496L13.018 15.4826L12.9079 15.4009C12.5948 15.1686 12.5948 14.6966 12.9079 14.4642L13.0179 14.3826L14.0823 14.3692L15.1466 14.3558L13.2297 12.3304L11.3128 10.305L10.1129 11.5713C9.29065 12.439 8.88331 12.8506 8.81861 12.879C8.69178 12.9346 8.50374 12.9303 8.3798 12.8689C8.31013 12.8344 7.6149 12.1162 6.16648 10.5823C3.9396 8.22404 4.00016 8.29444 4.00016 8.06428C4.00016 7.65937 4.42805 7.37669 4.77263 7.55396C4.84161 7.58945 5.49484 8.26131 6.74189 9.57943L8.60792 11.5518L9.82688 10.2664C11.1041 8.91954 11.1002 8.92311 11.3124 8.92311C11.5306 8.92311 11.4602 8.85536 13.7423 11.2642L15.9055 13.5476L15.9132 12.4493L15.9209 11.3509L15.9844 11.2313C16.1664 10.8884 16.6097 10.8331 16.857 11.1225C16.9059 11.1796 16.958 11.2725 16.9728 11.3288C16.9905 11.3964 16.9998 12.0488 16.9998 13.2438L17 15.0562L16.9357 15.1926C16.8596 15.3539 16.7274 15.4603 16.5673 15.489C16.5051 15.5003 15.681 15.5034 14.736 15.496Z" fill="white"/>
</svg>
  )
}

const Comparison = () => {
  let sliderRef = useRef(null);
  let sliderRefTwo = useRef(null)

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  const nextSlider2 = () => {
    sliderRefTwo.slickNext()
  }

  const prevSlider2  = () => {
    sliderRefTwo.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,  // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ],
  };
    
  return (
    <div className='w-full bg-gradient-to-b from-[#495057] to-[#212529] py-10 h-full sm:h-[1700px]'>
      <h2 className='text-2xl sm:text-4xl 2xl:text-6xl font-light text-center
       flex items-center justify-center gap-1 sm:gap-4 text-white capitalize mb-20'>Ohio scores <span className='text-[#ED5272] font-bold'>great</span><ArrowUp /></h2>
      <div className='w-full h-full bg-no-repeat bg-center bg-opacity-80 bg-cover' style={{ backgroundImage: `url(${svgBg})` }}>

        <div className='w-full relative'>
          <button onClick={previous} className='absolute left-0 sm:left-2 top-60 z-10 h-10 w-10 rounded-full bg-black sm:bg-transparent flex items-center justify-center'><SlArrowLeft className='text-white cursor-pointer font-extrabold' size={30} /></button>
          <button onClick={next} className='absolute right-0 sm:right-2 top-60 z-10  h-10 w-10 rounded-full bg-black sm:bg-transparent flex items-center justify-center'><SlArrowRight className='text-white cursor-pointer font-extrabold' size={30} /></button>
        <Slider {...settings}
          ref={slider => {
          sliderRef = slider;
        }} className='flex flex-row items-center px-1 sm:px-10 mb-20 sm:mb-40'>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card1} alt="card1" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>1</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Akron, AH</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#3</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#1</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#14</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card2} alt="card1" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>2</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Toledo, OH</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#6</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#2</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#20</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card1} alt="card1" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>3</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Rochester, NY</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#4</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#7</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#7</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card2} alt="card1" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>4</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Cleveland, OH</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Moderate <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#31</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#5</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#19</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl mr-2'>
            <div className='relative h-[274px] mb-4'>
              <img src={card1} alt="card1" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>5</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Pittsburgh, PA</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Moderate <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#35</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#3</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2  w-[140px]'>High <HighArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#24</span>
              </div>
            </div>
          </div>
         </Slider>
        </div>
        <h2 className='text-2xl sm:text-4xl 2xl:text-6xl font-light text-center
       flex items-center justify-center gap-1 sm:gap-4 text-white capitalize mb-20'>Florida - <span className='text-[#ED5272] font-bold'>not so much</span><ArrowDown /></h2>
        <div className='w-full relative'>
        <button onClick={prevSlider2} className='absolute left-0 sm:left-2 top-60 z-10 h-10 w-10 rounded-full bg-black sm:bg-transparent flex items-center justify-center'><SlArrowLeft className='text-white cursor-pointer font-extrabold' size={30} /></button>
        <button onClick={nextSlider2} className='absolute right-0 sm:right-2 top-60 z-10  h-10 w-10 rounded-full bg-black sm:bg-transparent flex items-center justify-center'><SlArrowRight className='text-white cursor-pointer font-extrabold' size={30} /></button>
        <Slider {...settings}
          ref={slider => {
            sliderRefTwo = slider;
        }}  className='flex flex-row items-center px-2 sm:px-10'>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card3} alt="card3" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>96</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Orlando, FL</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#80</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Moderate <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#54</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#89</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card4} alt="card4" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>97</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Provo, UT</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Moderate <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#53</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#69</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>At Risk <RiskArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#97</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl'>
            <div className='relative h-[274px] mb-4'>
              <img src={card3} alt="card3" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>98</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>North Port, FL</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#87</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Moderate <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#37</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>At Risk <RiskArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#98</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl mr-4'>
            <div className='relative h-[274px] mb-4'>
              <img src={card4} alt="card4" className='w-full h-full rounded-lg' />
              <span className='h-6 w-6 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>99</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Lakeland, FL</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#88</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Mod <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#16</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>At Risk <RiskArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#100</span>
              </div>
            </div>
          </div>
          <div className='h-full sm:h-[511px] w-[90%] sm:w-2/4 bg-white p-6 rounded-xl mr-4'>
            <div className='relative h-[274px] mb-4'>
              <img src={card3} alt="card4" className='w-full h-full rounded-lg' />
              <span className='h-8 w-8 rounded-full bg-[#ED5272] absolute right-4 flex items-center justify-center text-white font-bold top-4'>100</span>
              <p className='bg-[#212529] px-6  h-[70px] absolute bottom-[-10px] rounded-tr-2xl text-lg sm:text-2xl 2xl:text-4xl font-semibold text-center py-4 left-0 text-white'>Cape Coral, FL</p>
            </div>
            <div className='w-[95%] mx-auto'>
              <div className='flex justify-between items-center border-b-2 py-2'>
                <h5 className='font-semibold'>Markets</h5>
                <h5 className='font-semibold'>Score</h5>
                <h5 className='font-semibold'>Rank</h5>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Market Conditions</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Low <LowArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#86</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Demand</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>Mod <ModArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#25</span>
              </div>
              <div className='flex items-start justify-between mt-4'>
                <span className='font-regular opacity-70 w-[185px]'>Supply</span>
                <span className='font-regular flex items-center gap-2 w-[140px]'>At Risk <RiskArrow /></span>
                <span className='font-regular text-[#ED5272] w-[80px] text-center text-lg'>#99</span>
              </div>
            </div>
          </div>
          </Slider>
          </div>
      </div>
    </div>
  );
};

export default Comparison;
