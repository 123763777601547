import React from "react";
import { Close } from "@mui/icons-material";
import popup1 from "../../../assets/cashout/popup-1.png";
import popup2 from "../../../assets/cashout/popup-2.png";
import popup3 from "../../../assets/cashout/popup-3.png";
import CTAbutton from "../CTAbutton";

const WelcomePopup = ({ isVisible, onClose }) => {
  if (!isVisible) return null; // Return null if the component is hidden

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="max-w-[1100px] w-full h-[600px] rounded-[40px] bg-custom-gray10 text-white">
        <div className="px-10 flex justify-center flex-col py-14 relative w-full">
          <Close
            fontSize="medium"
            className="absolute top-10 right-14 cursor-pointer text-custom-primaryPink"
            onClick={onClose}
          />

          <h3 className="text-center text-[35px] font-normal mt-10">
            Access between{" "}
            <span className="text-custom-primaryPink">$30,000 & $500,000</span>{" "}
            without monthly payments{" "}
          </h3>
          <p className="text-center font-normal text-md mt-5">
            See how much you can access with a no obligation quote, and no
            impact to your credit score.
          </p>

          <div className="flex justify-center gap-32 mt-5 items-center">
            <div className="flex-col items-center flex">
              <img src={popup1} alt="icons" />
              <p className="text-lg font-normal text-center">
                No need to refinance
              </p>
            </div>
            <div className="flex-col items-center flex">
              <img src={popup2} alt="icons" />
              <p className="text-lg font-normal text-center">
                No need for perfect credit
              </p>
            </div>
            <div className="flex-col items-center flex">
              <img src={popup3} alt="icons" />
              <p className="text-lg font-normal text-center">
                No monthly payments
              </p>
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <CTAbutton
              buttonProps={{ onClick: onClose }}
              extraStyles={"w-[400px]"}
            >
              Apply Now
            </CTAbutton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePopup;
