import React from 'react'
import mapSection from '../../assets/map-section.png'

const MapSection = () => {
  return (
   <div className='w-full h-full'>
     <img src={mapSection} alt="" className='w-full h-full' />
    </div>
  )
}

export default MapSection