import React from 'react'
import titleSvg from '../../assets/title-svg.png'

const Projections = () => {
  return (
   <div className='py-20 bg-white border-t-2 px-2'>
    <h2 className='w-full sm:w-auto text-xl sm:text-4xl 2xl:text-6xl font-light text-center mb-6 sm:flex sm:items-center sm:justify-center gap-1 sm:gap-4'>
    2023 MSA <span className='font-bold text-[#ED5272]'>Projections vs Performance</span> <img src={titleSvg} className='hidden sm:block' alt="" />
    </h2>
    <p className='text-center text-lg sm:w-[70%] 2xl:text-xl 2xl:w-[1156px] mx-auto text-[#858E96] font-normal'>Home.AI had projected home price growth for the same 100 MSAs last year.
We ran the predictions against actual home price growth in 2023. We’ll let the numbers speak for themselves.</p>
    </div>
  )
}

export default Projections