import React from "react";
import PressHero from "../components/PressLanding/PressHero";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
import Header from "../components/HomePage/Navbar";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import LogoSection from "../components/HomePage/LogoSection";
import PressCards from "../components/PressLanding/PressCards";

const PressPage = () => {
  return (
    <div className="overflow-x-hidden sm:overflow-x-auto">
      <Header/>
      <PressHero />
      <LogoSection />
      <PressCards />
      <NeedFromUs />
      <Footer/>
    </div>
  );
};

export default PressPage;
