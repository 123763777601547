import React, { useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ApprovalDocument from '../../pages/PdfCashOut';

const EligibilityResult = ({ userData }) => {
  const [isDownloaded, setIsDownloaded] = useState(false);

  const handleDownloadClick = () => {
    setIsDownloaded(true);
    setTimeout(() => {
      window.location.href = '/borrow';
    }, 2000); // Redirect after 3 seconds
  };

  // Generate the PDF only if the user is eligible
  const pdfExportComponent = userData ? (
    <PDFDownloadLink
      document={<ApprovalDocument userResponses={userData} />}
      fileName="pre-approval-letter.pdf"
      className="text-black bg-transparent border-2 border-black py-2 px-4 sm:px-12 mt-2 sm:mt-4 text-lg rounded-full hover:bg-pink-500 hover:text-white hover:border-white transition duration-300 ease-in-out"
      onClick={handleDownloadClick}
    >
      {({ loading }) => (loading ? 'Preparing document...' : 'Download Pre-Approval Letter')}
    </PDFDownloadLink>
  ) : null;

  return (
    <div className="bg-white p-6 mx-auto mb-64 my-8 max-w-4xl">
      <h2 className="text-4xl font-semibold text-gray-900 my-4">
        Congratulations, You're eligible for a Cashout
      </h2>
      <p className="text-gray-700 mt-2 mb-12">
        Unlock your home equity effortlessly
      </p>
      <div>
        {pdfExportComponent}
      </div>
    </div>
  );
};

export default EligibilityResult;

