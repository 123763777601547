// Calculator.js


// import React, { useState } from 'react';

// function PriceCalculator() {
//   const [homeValue, setHomeValue] = useState('');
//   const [cashoutAmount, setCashoutAmount] = useState('');
//   const [investmentYears, setInvestmentYears] = useState('');
//   const [companyShare, setCompanyShare] = useState('');
//   const [customerShare, setCustomerShare] = useState('');
//   const [sliderPercentage, setSliderPercentage] = useState(0);
//   const [errorMessage, setErrorMessage] = useState('');

//   const calculateEquityShares = () => {
//     if (!homeValue || !cashoutAmount || !investmentYears) {
//       setErrorMessage('Please enter all fields correctly.');
//       return;
//     }

//     const homeValueFloat = parseFloat(homeValue);
//     const cashoutAmountFloat = parseFloat(cashoutAmount);
//     const investmentYearsInt = parseInt(investmentYears);
//     let companyShareDollar = cashoutAmountFloat;
//     let previousYearShare = cashoutAmountFloat;

//     for (let i = 1; i <= investmentYearsInt; i++) {
//       const yearlyIncrease = previousYearShare * 0.20; // 20% yearly increase
//       companyShareDollar += yearlyIncrease;
//       previousYearShare += yearlyIncrease;
//     }

//     const maxCashout = homeValueFloat * 0.15; // Max 15% cashout
//     const clampedCashout = Math.min(cashoutAmountFloat, maxCashout);
//     companyShareDollar = Math.min(companyShareDollar, 2 * clampedCashout);
//     const customerShareDollar = homeValueFloat - companyShareDollar;

//     setCompanyShare(companyShareDollar.toFixed(2));
//     setCustomerShare(customerShareDollar.toFixed(2));
//     setErrorMessage('');
//     setSliderPercentage((clampedCashout / homeValueFloat) * 100);
//   };

//   return (
//     <div className="flex flex-row justify-center items-start bg-blue-600 text-white ">
//       <div className="w-1/2 p-8">
//         <h1 className="text-2xl font-bold mb-6">Input Details</h1>
//         <div className="mb-6">
//           <label className="block mb-2">Current Home Value ($):</label>
//           <input
//             type="number"
//             className="w-full p-2 rounded bg-blue-500"
//             value={homeValue}
//             onChange={(e) => setHomeValue(e.target.value)}
//           />
//         </div>
//         <div className="mb-6">
//           <label className="block mb-2">Cashout Amount ($):</label>
//           <input
//             type="number"
//             className="w-full p-2 rounded bg-blue-500"
//             value={cashoutAmount}
//             onChange={(e) => setCashoutAmount(e.target.value)}
//           />
//           <input
//             type="range"
//             min="0"
//             max="100"
//             className="w-full mt-2 cursor-pointer"
//             value={sliderPercentage}
//             onChange={(e) => setSliderPercentage(e.target.value)}
//           />
//         </div>
//         <div className="mb-6">
//           <label className="block mb-2">Number of Years of Investment:</label>
//           <input
//             type="number"
//             className="w-full p-2 rounded bg-blue-500"
//             value={investmentYears}
//             onChange={(e) => setInvestmentYears(e.target.value)}
//             min="1"
//             max="5"
//           />
//         </div>
//         <button onClick={calculateEquityShares} className="bg-white text-blue-600 font-bold p-3 rounded hover:bg-blue-500 hover:text-white transition duration-200">Calculate Shares</button>
//         {errorMessage && <div className="mt-4 p-3 text-red-600 bg-red-100 rounded">{errorMessage}</div>}
//       </div>
//       <div className="w-1/2 p-8">
//         <h1 className="text-2xl font-bold mb-6">Results</h1>
//         {companyShare && customerShare && (
//           <div className="space-y-4">
//             <div>
//               <div className="text-sm font-bold">Company's Share: ${companyShare}</div>
//               <div className="w-full bg-pink-500 rounded h-8" style={{ width: `${100 * parseFloat(companyShare) / (parseFloat(companyShare) + parseFloat(customerShare))}%` }}></div>
//             </div>
//             <div>
//               <div className="text-sm font-bold">Customer's Share: ${customerShare}</div>
//               <div className="w-full bg-blue-500 rounded h-8" style={{ width: `${100 * parseFloat(customerShare) / (parseFloat(companyShare) + parseFloat(customerShare))}%` }}></div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PriceCalculator;

import React, { useState, useEffect } from 'react';
// import { formatNumberWithCommasAndDollarSign } from '.';
import { SlArrowDown, SlArrowUp } from "react-icons/sl";


function PriceCalculator() {
  const [homeValue, setHomeValue] = useState(500000);
  const [cashOutAmount, setCashOutAmount] = useState(75000);
  const [investmentYears, setInvestmentYears] = useState(5);
  const [sliderPercentage, setSliderPercentage] = useState(15);
  const [companyShare, setCompanyShare] = useState('');
  const [customerShare, setCustomerShare] = useState('');

  const formatter = new Intl.NumberFormat("en-US", {
    currency: "USD",
  });  

  useEffect(() => {
    calculateEquityShares();
  }, [homeValue, cashOutAmount, investmentYears, sliderPercentage]);

  const calculateEquityShares = () => {
    const maxCashout = homeValue * 0.15;
    const clampedCashout = Math.min(cashOutAmount, maxCashout);
    let companyShareDollar = clampedCashout;
    let previousYearShare = clampedCashout;

    for (let i = 1; i <= investmentYears; i++) {
      const yearlyIncrease = previousYearShare * 0.20;
      companyShareDollar += yearlyIncrease;
      previousYearShare += yearlyIncrease;
    }

    companyShareDollar = Math.min(companyShareDollar, 2 * clampedCashout);
    const customerShareDollar = homeValue - companyShareDollar;

    setCompanyShare(companyShareDollar.toFixed(2));
    setCustomerShare(customerShareDollar.toFixed(2));
  };

  const handleSliderChange = (value) => {
    const newCashOutAmount = (value / 100) * homeValue;
    setCashOutAmount(newCashOutAmount);
    setSliderPercentage(value);
  };

  const handleHomeValueIncrease = () => {
    setHomeValue((prevValue) => prevValue + 10000)
  }

  const handleHomeValueDecrease = () => {
    if (homeValue !== 0) {
      setHomeValue((prevValue) => prevValue - 10000)
    }
  }

  const handleCashOutValueIncrease = () => {
    setCashOutAmount((prevValue) => prevValue + 10000)
  }

  const handleCashOutValueDecrease = () => {
    if (cashOutAmount > 10000) {
      setCashOutAmount((prevValue) => prevValue - 10000)
    }
  }
  
  const handleIncreaseYear = () => {
    if (investmentYears < 5) {
      setInvestmentYears((prev) => prev + 1)
    }
  }

  const handleDecreaseYear = () => {
    if (investmentYears > 1) {
      setInvestmentYears((prev) => prev - 1)
    }
  }


  return (
    <div className="px-2 sm:px-6 bg-[#212529] text-white border-t py-8 sm:pt-[74px] sm:pb-20">
        <h2 className="text-3xl sm:text-6xl font-light text-center mb-[90px]">Home Equity <span className="text-[#ED5272] font-bold">Calculator</span></h2>
        <div className='flex flex-col sm:flex-row w-full sm:w-[70%] mx-auto border rounded-lg p-2'>
    <div className="w-full sm:w-1/2 p-4">
      <div style={{ marginBottom: '20px' }} className='relative'>
        <label htmlFor="homeValue" style={{ display: 'block', marginBottom: '5px' }}>Current Home Value ($):</label>
        <input
          type="text"
          id="homeValue"
          value={`$${formatter.format(homeValue)}`}
           onChange={(e) => setHomeValue(e.target.value.replace(/[^\d.]/g, ''))}
              // style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
            className='p-2.5 border-b w-full text-white bg-transparent outline-none'
          required
            />
            <div className='flex flex-col gap-2 items-center absolute bottom-2 right-0'>
              <SlArrowUp className='cursor-pointer opacity-80' size={15} onClick={handleHomeValueIncrease} />
              <SlArrowDown className='cursor-pointer opacity-80' size={15} onClick={handleHomeValueDecrease} />
            </div>
      </div>
        <div style={{ marginBottom: '20px' }} className='relative'>
        <label htmlFor="cashoutAmount" style={{ display: 'block', marginBottom: '5px' }}>Cashout Amount ($):</label>
        <input
          type="text"
          id="cashoutAmount"
          value={`$${formatter.format(cashOutAmount)}`}
          onChange={(e) => setCashOutAmount(e.target.value.replace(/[^\d.]/g, ''))}
          // style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
          className='p-2.5 border-b w-full text-white bg-transparent outline-none'
          required
        />
        <input
          type="range"
          id="cashoutSlider"
          min="0"
          max="15"
          value={sliderPercentage}
          onChange={(e) => handleSliderChange(parseFloat(e.target.value))}
          style={{ width: '100%', marginTop: '18px' }}
        />
          <div style={{ textAlign: 'center', marginTop: '5px' }}>{sliderPercentage}%</div>
            <div className='flex flex-col gap-2 items-center absolute bottom-20 right-0'>
              <SlArrowUp className='cursor-pointer opacity-80' size={15} onClick={handleCashOutValueIncrease} />
              <SlArrowDown className='cursor-pointer opacity-80' size={15} onClick={handleCashOutValueDecrease} />
          </div>
      </div>
        <div style={{ marginBottom: '20px' }} className='relative'>
        <label htmlFor="investmentYears" style={{ display: 'block', marginBottom: '5px' }}>Number of Years of Investment:</label>
        <input
          type="number"
          id="investmentYears"
          value={investmentYears}
          onChange={(e) => setInvestmentYears(parseInt(e.target.value))}
          // style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
          className='p-2.5 border-b w-full text-white bg-transparent outline-none years-of-investment'
          required
          max="5"
          min="1"
            />
          <div className='flex flex-col gap-2 items-center absolute bottom-2 right-0'>
              <SlArrowUp className='cursor-pointer opacity-80' size={15} onClick={handleIncreaseYear} />
              <SlArrowDown className='cursor-pointer opacity-80' size={15} onClick={handleDecreaseYear} />
          </div>
      </div>
      </div>
      <div className="w-full sm:w-1/2 p-4 sm:ml-8 sm:pt-16">
      <div className="flex flex-col justify-between mb-4">
          <div className="flex flex-col mt-8 justify-center gap-10 bg-gray-100 p-4 shadow text-lg rounded-lg">
          <div className="text-green-600">
            <p className="font-bold text-2xl">Customer's share</p>
            <p className='text-4xl font-semibold'>${formatter.format(customerShare)}</p>
          </div>
          <div className="text-red-600">
            <p className="font-bold text-2xl">Home.LLC's share</p>
            <p className='text-4xl font-semibold'>${formatter.format(companyShare)}</p>
          </div>
        </div>
        </div>
        </div>
      </div>
     
    </div>
  );
}

export default PriceCalculator;





// export default Calculator;


// const PriceCalculator = () => {
//   const [homeValue, setHomeValue] = useState(500000);
//   const [cashValue, setCashValue] = useState(75000);
//   const [years, setYears] = useState(5);
//   const [months, setMonths] = useState(0);
//   const [homeWorth, setHomeWorth] = useState(75000);
//   const [shareValue, setShareValue] = useState(75000);

//   const handleValueChange = (valueSetter, increment) => {
//     valueSetter((prevValue) => prevValue + increment);
//   };

//   const handleHomeValueChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setHomeValue(newValue || 0);
//   };

//   const handleCashValueChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setCashValue(newValue || 0);
//   };

//   const handleYearsChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setYears(newValue || 0);
//   };

//   const handleMonthsChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setMonths(newValue || 0);
//   };

//   const handleHomeWorthChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setHomeWorth(newValue || 0);
//   };

//   const handleShareValueChange = (event) => {
//     const newValue = parseInt(event.target.value.replace(/[^\d]/g, ""), 10);
//     setShareValue(newValue || 0);
//   };

//   return (
//     <div className="w-full h-auto 2xl:h-[762px] xl:py-16 mb-16">
//       <div className="w-full 2xl:max-w-[1580px] mx-auto h-full xl:h-[591px]">
//         <h4
//           className="text-[20px] leading-[30px] xl:text-[48px] xl:leading-[66px] text-white sm:text-[#212529]
//          sm:font-semibold mb-10 text-center sm:text-left w-full bg-[#ED5272]
//           sm:bg-transparent py-[16px] sm:py-0 uppercase sm:capitalize font-normal">
//           Pricing Calculator
//         </h4>

//         <div className="inline-flex w-full flex-col lg:flex-row items-center gap-[20px] lg:gap-[90px] overflow-hidden px-2">
//           <div className="w-full h-auto lg:w-[558px] lg:h-[562px]">
//             <div className="flex gap-12 p-4 items-center border-b border-[#B2B2B2] mb-2">
//               <p className="capitalize text-sm lg:text-xl text-[#343A40] font-light w-[120px] lg:w-[199px]">
//                 My Home Value is
//               </p>

//               <div className="flex items-center gap-4">
//                 <input
//                   type="text"
//                   value={`$${homeValue.toLocaleString()}`}
//                   onChange={handleHomeValueChange}
//                   placeholder="$500,000"
//                   className="border-none outline-none w-[175px] text-[36px] leading-[54px] font-semibold text-[#212529]"
//                 />
//                 <div className="flex flex-col gap-2 items-center">
//                   <button onClick={() => handleValueChange(setHomeValue, 1)}>
//                     <img src={upArrow} alt="up-arrow" width={10} height={16} />
//                   </button>
//                   <button onClick={() => handleValueChange(setHomeValue, -1)}>
//                     <img
//                       src={downArrow}
//                       alt="down-arrow"
//                       width={10}
//                       height={16}
//                     />
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="flex gap-12 p-4 items-center border-b border-[#B2B2B2] mb-2">
//               <p className="capitalize text-sm lg:text-xl text-[#343A40] font-light w-[120px] lg:w-[199px]">
//                 I can get upto
//               </p>

//               <div className="flex items-center gap-2">
//                 <input
//                   type="text"
//                   value={`$${cashValue.toLocaleString()}`}
//                   onChange={handleCashValueChange}
//                   placeholder="$75000"
//                   className="border-none outline-none w-[145px] text-[36px] leading-[54px] font-semibold text-[#212529] mr-2"
//                 />
//                 <span className="font-light text-sm whitespace-nowrap">
//                   In Cash
//                 </span>
//                 <div className="flex flex-col gap-2 items-center">
//                   <button onClick={() => handleValueChange(setCashValue, 1)}>
//                     <img src={upArrow} alt="up-arrow" width={10} height={16} />
//                   </button>
//                   <button onClick={() => handleValueChange(setCashValue, -1)}>
//                     <img
//                       src={downArrow}
//                       alt="down-arrow"
//                       width={10}
//                       height={16}
//                     />
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="flex gap-4 sm:gap-12 p-4 items-center border-b border-[#B2B2B2] mb-2">
//               <p className="capitalize text-sm lg:text-xl text-[#343A40] font-light w-[120px] lg:w-[199px]">
//                 when i settled in
//               </p>

//               <div className="inline-flex items-center gap-4">
//                 <div className="flex items-center gap-4">
//                   <div className="flex items-center gap-2">
//                     <input
//                       type="text"
//                       value={years}
//                       onChange={handleYearsChange}
//                       placeholder="5"
//                       className="border-none outline-none w-[23px] text-[36px] leading-[54px] font-semibold text-[#212529] mr-1"
//                     />
//                     <span className="font-light text-sm whitespace-nowrap">
//                       in years
//                     </span>
//                   </div>

//                   <div className="flex flex-col gap-2 items-center">
//                     <button onClick={() => handleValueChange(setYears, 1)}>
//                       <img
//                         src={upArrow}
//                         alt="up-arrow"
//                         width={14}
//                         height={16}
//                       />
//                     </button>
//                     <button onClick={() => handleValueChange(setYears, -1)}>
//                       <img
//                         src={downArrow}
//                         alt="down-arrow"
//                         width={14}
//                         height={16}
//                       />
//                     </button>
//                   </div>
//                 </div>
//                 <div className="flex items-center gap-4">
//                   <div className="flex items-center gap-2">
//                     <input
//                       type="text"
//                       value={months}
//                       onChange={handleMonthsChange}
//                       placeholder="5"
//                       className="border-none outline-none w-[23px] text-[36px] leading-[54px] font-semibold text-[#212529] mr-1"
//                     />
//                     <span className="font-light text-sm whitespace-nowrap">
//                       months
//                     </span>
//                   </div>
//                   <div className="flex flex-col gap-2 items-center">
//                     <button onClick={() => handleValueChange(setMonths, 1)}>
//                       <img
//                         src={upArrow}
//                         alt="up-arrow"
//                         width={14}
//                         height={16}
//                       />
//                     </button>
//                     <button onClick={() => handleValueChange(setMonths, -1)}>
//                       <img
//                         src={downArrow}
//                         alt="down-arrow"
//                         width={14}
//                         height={16}
//                       />
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="flex gap-12 p-4 items-center justify-start border-b border-[#B2B2B2] mb-2">
//               <p className="capitalize text-sm lg:text-xl text-[#343A40] font-light w-[120px] lg:w-[199px]">
//                 and my home’s value is
//               </p>

//               <div className="flex justify-start items-center gap-4">
//                 <input
//                   type="text"
//                   value={`$${homeWorth.toLocaleString()}`}
//                   onChange={handleHomeWorthChange}
//                   placeholder="$75,000"
//                   className="border-none outline-none w-[148px] text-[36px] leading-[54px] font-semibold text-[#212529]"
//                 />

//                 <div className="flex flex-col gap-2 items-center">
//                   <button onClick={() => handleValueChange(setHomeWorth, 1)}>
//                     <img src={upArrow} alt="up-arrow" width={10} height={16} />
//                   </button>
//                   <button onClick={() => handleValueChange(setHomeWorth, -1)}>
//                     <img
//                       src={downArrow}
//                       alt="down-arrow"
//                       width={10}
//                       height={16}
//                     />
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="flex gap-12 p-4 items-center border-b border-[#B2B2B2]">
//               <p className="capitalize text-sm lg:text-xl text-[#343A40] font-light w-[120px] lg:w-[199px]">
//                 where my share is
//               </p>

//               <div className="flex items-center gap-4">
//                 <input
//                   type="text"
//                   value={`$${shareValue.toLocaleString()}`}
//                   onChange={handleShareValueChange}
//                   placeholder="$75,000"
//                   className="border-none outline-none w-[148px] text-[36px] leading-[54px] font-semibold text-[#212529]"
//                 />
//                 <div className="flex flex-col gap-2 items-center">
//                   <button onClick={() => handleValueChange(setShareValue, 1)}>
//                     <img src={upArrow} alt="up-arrow" width={10} height={16} />
//                   </button>
//                   <button onClick={() => handleValueChange(setShareValue, -1)}>
//                     <img
//                       src={downArrow}
//                       alt="down-arrow"
//                       width={10}
//                       height={16}
//                     />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div>
//             <img src={calculatorImage} alt="calculated-price" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };



