import React from "react";
import Bus from "../../../../assets/HomeAi/bus.svg";
import Locator from "../../../../assets/HomeAi/Locator.svg";
import Arrow from "../../../../assets/HomeAi/Arrow.svg";

const TransitCard=({ properties }) => {
  return (
    <div className="flex items-center gap-5 p-3 border-dashed border-b border-black">
      <img src={Bus} alt="bus" className="w-10 h-10" />
      <span className="h-full border-black border"></span>
      <div className="flex gap-3 justify-between items-center w-full">
        <div className="flex flex-col text-md w-[320px]">
          <b>{properties.name}</b>
          {/* <div>Location of the transit</div> */}
          {/* <div>Timings: 10:00 AM - 10:00 PM</div> */}
          <div className="text-base">{properties.formatted_address}</div>
        </div>
        <div className="flex flex-col w-24 items-end">
          <div className="flex gap-2">
            <img src={Locator} alt="Lovator" className="w-6 h-9" />
            <div className="flex gap-1 items-end">
              <span className="text-2xl">24</span>
              <span className="text-xl">KM</span>
            </div>
          </div>
          <div className="text-xs"> from downtown</div>
        </div>
      </div>
    </div>
  );
};

export default TransitCard;
