import React from 'react';

const CreditAuthorizationPolicy = () => {
  return (
    <div className="p-4 text-white">
      <h2 className="text-xl font-bold mb-4">Changes to Credit Authorization Policy</h2>
      <p className="mb-6">Home reserves the right, at its sole discretion, to modify, add, or remove portions of this Credit Authorization Policy at any time without notice. It is your responsibility to periodically review this policy for changes. Your continued use of the Site or submission of an inquiry, pre-qualification request, and/or application indicates acceptance of any changes.</p>

      <h2 className="text-xl font-bold mb-4">Consumer Reports</h2>
      <p className="mb-6">In connection with your inquiry regarding qualification for a Home investment ("Pre-Qualification Inquiry") or your application for a Home investment on the Site ("Application"), we may request consumer reports and related information about you. These requests are regulated by the Fair Credit Reporting Act. By providing written instruction, you authorize Home to obtain such reports from U.S. national credit reporting agencies like Experian, Equifax, and TransUnion ("Credit Bureaus").</p>

      <h2 className="text-xl font-bold mb-4">Inquiry & Pre-Qualification</h2>
      <p className="mb-6">For your Pre-Qualification Inquiry, we may conduct a "soft credit pull" through Credit Bureaus to assess your initial qualification for a Home investment. This will not impact your credit score. By submitting your Pre-Qualification Inquiry on the Site, you authorize Home to obtain information from your credit profile. Upon request, we will disclose the Credit Bureau(s) from which we obtained your consumer report.</p>

      <h2 className="text-xl font-bold mb-4">Investment Application</h2>
      <p className="mb-6">If you meet initial eligibility requirements and decide to submit an Application, we may conduct a "hard credit pull" to verify your identity and make decisions regarding your Application. This inquiry will impact your credit score. By submitting your Application on the Site, you authorize Home to obtain information from your credit profile. Upon request, we will disclose the Credit Bureau(s) from which we obtained your consumer report.</p>

      <h2 className="text-xl font-bold mb-4">Verification of Mortgages & Liens</h2>
      <p className="mb-6">You consent to Home obtaining verification reports for your mortgage(s) and related liens (Verification of Mortgage "VOM") from lenders and mortgage servicing agencies to evaluate your application. VOM documents your mortgage payment history and other relevant information. We may also use VOM reports to verify balances, monthly payments, and monitor your mortgage information. We may anonymize and use any obtained reports to improve our business and develop new products and services.</p>

      <h2 className="text-xl font-bold mb-4">Monitoring Investments</h2>
      <p className="mb-6">If you complete an investment with Home, you authorize us to obtain consumer reports through soft credit pulls during the investment term for servicing and monitoring purposes.</p>
    </div>
  );
};

export default CreditAuthorizationPolicy;
