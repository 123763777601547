import React from "react";

const CalendyContact = () => {
  return (
    <div className="w-full h-full sm:h-[1028px] bg-calendy-contact-section bg-no-repeat bg-center bg-cover py-[90px] px-2">
      <h4 className="text-3xl xl:w-[878px] xl:mx-auto text-center xl:text-[64px] xl:leading-[82.4px] capitalize mb-14 text-[#ffffff] font-light" id="schedule-call">
        Schedule a call <span className="font-bold text-[#6A6DFF]">with us</span>
      </h4>

      {/* <p className="text-lg sm:text-[24px] font-light sm:leading-[145%] text-white text-center mt-10">
        Maximize Your Property's Potential - Schedule Your Free Consultation
        Today!
      </p> */}

      <div className="w-[369px] h-[744px] sm:w-[1004px] sm:h-[680px] mx-auto mt-16">
        <iframe
          className="h-full w-full hide-scrollbar rounded-lg"
          title="calendy"
          src="https://calendly.com/sid-fr2t/30min"
          frameborder="0"></iframe>
      </div>
    </div>
  );
};

export default CalendyContact;