export const schoolData = [
  {
    type: "Feature",
    properties: {
      formatted_address: "777 San Miguel Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 522-8278",
      name: "San Miguel Elementary School",
      rating: "4.1",
      website: "http://www.sesd.org/sanmiguel",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0082369, 37.3904488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Lakechime Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 522-8272",
      name: "Lakewood Tech EQ Elementary School",
      rating: "4.8",
      website: "https://www.sesd.org/lakewood",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:30 PM",
          "Tuesday: 7:30 AM – 3:30 PM",
          "Wednesday: 7:30 AM – 3:30 PM",
          "Thursday: 7:30 AM – 3:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.003084, 37.3982192] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1110 Fairwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 523-4870",
      name: "Fairwood Explorer Elementary School",
      rating: "5",
      website: "http://www.sesd.org/fairwood",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9909497, 37.3963749] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "450 N Sunnyvale Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 522-8229",
      name: "Bishop Elementary School",
      rating: "4.3",
      website: "https://www.sesd.org/bishop",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0247508, 37.3867637] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "510 Lawrence Expy # 221, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 531-6428",
      name: "Arts Educators Group",
      rating: "missing",
      website: "http://www.artseducators.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9951621, 37.3824605] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "562 N Britton Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 481-9900",
      name: "The King's Academy",
      rating: "4.2",
      website: "https://www.tka.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0123114, 37.386654] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-5532",
      name: "Mission Early College High School",
      rating: "missing",
      website: "https://mechs.santaclarausd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 12:00 PM",
          "Tuesday: 8:30 AM – 12:00 PM",
          "Wednesday: 8:30 AM – 12:00 PM",
          "Thursday: 8:30 AM – 12:00 PM",
          "Friday: 8:30 AM – 12:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9835632, 37.3923072] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-5007",
      name: "Mission College",
      rating: "4.2",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9828037, 37.3925485] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "NASA Research Park, Building 23 Moffett Field, CA 94035, USA",
      formatted_phone_number: "(412) 268-2000",
      name: "Carnegie Mellon University Silicon Valley",
      rating: "4.8",
      website: "http://sv.cmu.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94035",
    },
    geometry: { type: "Point", coordinates: [-122.0597686, 37.4104329] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "830 Stewart Dr, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 400-9008",
      name: "California South Bay University",
      rating: "1.5",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0096247, 37.3834881] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "595 Lawrence Expy, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 481-9988",
      name: "Herguan University",
      rating: "3.4",
      website: "http://www.herguanuniversity.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9969246, 37.3864641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1070 Innovation Way, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 745-8000",
      name: "Foothill College Sunnyvale Center",
      rating: "4.6",
      website: "https://foothill.edu/sunnyvale",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 10:00 AM – 3:00 PM",
          "Wednesday: 10:00 AM – 3:00 PM",
          "Thursday: 10:00 AM – 3:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0288871, 37.4047486] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "440 N Wolfe Rd, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "missing",
      name: "tenCube",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0133121, 37.3836869] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Innospring, 2901 Tasman Dr #107, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(808) 626-5831",
      name: "Global University of Engineering",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.979004, 37.4041279] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Gary G. Gillmor & Family, 3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-2200",
      name: "Gary G. Gillmor and Family Academic Center",
      rating: "4.7",
      website: "missing",
      opening_hours: "missing",
      first_type: "art_gallery",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9832284, 37.3920411] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "525 Del Rey Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 480-1934",
      name: "BM RESEARCH",
      rating: "4",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0316238, 37.3932952] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1250 Borregas Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Brooks College (Sunnyvale)",
      rating: "missing",
      website: "https://brookscollege.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 6:30 PM",
          "Tuesday: 9:30 AM – 6:30 PM",
          "Wednesday: 9:30 AM – 6:30 PM",
          "Thursday: 9:30 AM – 6:30 PM",
          "Friday: 9:30 AM – 6:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94089",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0182495, 37.408814500000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "900 Hamlin Ct, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Carnegie Mellon",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0290939, 37.3999954] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "201 W California Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "missing",
      name: "De Anza Community College",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0290343, 37.382801] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3000 Mission College Blvd. (SEC111, Gary G. Gillmor & Family, 3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-5110",
      name: "Mission College International Student Center",
      rating: "1",
      website:
        "https://missioncollege.edu/student_services/international-students/index.html",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9832284, 37.3920411] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4415 Fortran Ct, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 946-5900",
      name: "Saba Academy",
      rating: "4.3",
      website: "http://www.sabaacademy.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9581482, 37.421049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3003 Scott Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-1161",
      name: "Granada Islamic School (GIS)",
      rating: "3.5",
      website: "http://www.granadaschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9598105, 37.3771692] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "275 Redwood Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2852",
      name: "Curtner Elementary School",
      rating: "4.7",
      website: "https://curtner.musd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1445" },
            open: { day: 1, time: "0845" },
          },
          {
            close: { day: 2, time: "1445" },
            open: { day: 2, time: "0845" },
          },
          {
            close: { day: 3, time: "1445" },
            open: { day: 3, time: "0845" },
          },
          {
            close: { day: 4, time: "1445" },
            open: { day: 4, time: "0845" },
          },
          {
            close: { day: 5, time: "1445" },
            open: { day: 5, time: "0845" },
          },
        ],
        weekday_text: [
          "Monday: 8:45 AM – 2:45 PM",
          "Tuesday: 8:45 AM – 2:45 PM",
          "Wednesday: 8:45 AM – 2:45 PM",
          "Thursday: 8:45 AM – 2:45 PM",
          "Friday: 8:45 AM – 2:45 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9129066, 37.440770699999987],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Laurie Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 423-1900",
      name: "Montague Elementary School",
      rating: "4.1",
      website: "https://montague.santaclarausd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:30 PM",
          "Tuesday: 8:00 AM – 3:30 PM",
          "Wednesday: 8:00 AM – 3:30 PM",
          "Thursday: 8:00 AM – 3:30 PM",
          "Friday: 8:00 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9466726, 37.3888266] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4949 Calle De Escuela, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 423-1500",
      name: "Kathryn Hughes Elementary School",
      rating: "4.1",
      website: "https://hughes.santaclarausd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.963882, 37.4051351] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1110 Fairwood Ave, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 523-4870",
      name: "Fairwood Explorer Elementary School",
      rating: "5",
      website: "http://www.sesd.org/fairwood",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-121.9909497, 37.3963749] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "140 N Abbott Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2870",
      name: "Anthony Spangler Elementary School",
      rating: "4.9",
      website: "https://spangler.musd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.913941, 37.4310468] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Lakechime Dr, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 522-8272",
      name: "Lakewood Tech EQ Elementary School",
      rating: "4.8",
      website: "https://www.sesd.org/lakewood",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:30 PM",
          "Tuesday: 7:30 AM – 3:30 PM",
          "Wednesday: 7:30 AM – 3:30 PM",
          "Thursday: 7:30 AM – 3:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.003084, 37.3982192] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "47370 Warm Springs Blvd, Fremont, CA 94539, USA",
      formatted_phone_number: "(510) 656-1611",
      name: "Warm Springs Elementary School",
      rating: "4.7",
      website: "https://fremontunified.org/warmsprings/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "47370",
    },
    geometry: { type: "Point", coordinates: [-121.9247949, 37.4825121] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1585 Fallen Leaf Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2882",
      name: "Pearl Zanker Elementary School",
      rating: "4.6",
      website: "http://zanker.musd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:30 PM",
          "Tuesday: 7:30 AM – 3:30 PM",
          "Wednesday: 7:30 AM – 3:30 PM",
          "Thursday: 7:30 AM – 3:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9060728, 37.4077058] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1505 Escuela Pkwy, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2858",
      name: "Marshall Pomeroy Elementary",
      rating: "5",
      website: "https://pomeroy.musd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1530" },
            open: { day: 0, time: "0815" },
          },
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0815" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0815" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0815" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0815" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0815" },
          },
        ],
        weekday_text: [
          "Monday: 8:15 AM – 3:30 PM",
          "Tuesday: 8:15 AM – 3:30 PM",
          "Wednesday: 8:15 AM – 3:30 PM",
          "Thursday: 8:15 AM – 3:30 PM",
          "Friday: 8:15 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: 8:15 AM – 3:30 PM",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9026308, 37.4538735] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "341 Great Mall Pkwy, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 539-1121",
      name: "Stratford School",
      rating: "5",
      website:
        "https://www.stratfordschools.com/schools/northern-california/south-bay/milpitas-great-mall-parkway/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8938978, 37.410879] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5030 N First St, Alviso, CA 95002, USA",
      formatted_phone_number: "(408) 423-1700",
      name: "George Mayne Elementary School",
      rating: "3.5",
      website: "https://mayne.santaclarausd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1905" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1905" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1905" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1905" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1905" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 7:05 PM",
          "Tuesday: 7:30 AM – 7:05 PM",
          "Wednesday: 7:30 AM – 7:05 PM",
          "Thursday: 7:30 AM – 7:05 PM",
          "Friday: 7:30 AM – 7:05 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95002",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9667954, 37.424214600000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "345 Boulder St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2876",
      name: "Joseph Weller Elementary School",
      rating: "4.2",
      website: "http://weller.musd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:00 PM",
          "Tuesday: 7:30 AM – 3:00 PM",
          "Wednesday: 7:30 AM – 3:00 PM",
          "Thursday: 7:30 AM – 3:00 PM",
          "Friday: 7:30 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9054194, 37.4579365] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3534 Zanker Rd, San Jose, CA 95134, USA",
      formatted_phone_number: "(669) 369-4100",
      name: "Abram Agnew Elementary School",
      rating: "missing",
      website: "https://agnew.santaclarausd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "95134",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9308505, 37.406647199999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25 Corning Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 539-5543",
      name: "Stratford School",
      rating: "missing",
      website:
        "https://www.stratfordschools.com/schools/northern-california/south-bay/milpitas-corning/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9069386, 37.4249705] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "125 N Milpitas Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 669-4851",
      name: "Stratford School",
      rating: "missing",
      website:
        "https://www.stratfordschools.com/schools/northern-california/south-bay/milpitas-beresford-square/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9015731, 37.4356964] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1280 S Abel St, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 644-0440",
      name: "Smart Learning Academy",
      rating: "5",
      website: "https://slamilpitas.wixsite.com/smartlearningacademy",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1800" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: 9:30 AM – 6:00 PM",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9033293, 37.4128397] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1750 McCandless Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2695",
      name: "Mabel Mattos Elementary School",
      rating: "missing",
      website: "https://www.musd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0815" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0815" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0815" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0815" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0815" },
          },
        ],
        weekday_text: [
          "Monday: 8:15 AM – 3:00 PM",
          "Tuesday: 8:15 AM – 3:00 PM",
          "Wednesday: 8:15 AM – 3:00 PM",
          "Thursday: 8:15 AM – 3:00 PM",
          "Friday: 8:15 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8985399, 37.4061176] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1300 Edsel Dr, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2662",
      name: "Robert Randall Elementary School",
      rating: "2.9",
      website: "https://randall.musd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1438" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1438" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1235" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1438" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1438" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:38 PM",
          "Tuesday: 8:00 AM – 2:38 PM",
          "Wednesday: 8:00 AM – 12:35 PM",
          "Thursday: 8:00 AM – 2:38 PM",
          "Friday: 8:00 AM – 2:38 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8816681, 37.4298952] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3003 Scott Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 980-1161",
      name: "Granada Islamic School (GIS)",
      rating: "3.5",
      website: "http://www.granadaschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9598105, 37.3771692] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1331 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2690",
      name: "Calaveras Hills High School",
      rating: "missing",
      website: "https://chhs.musd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:30 PM",
          "Tuesday: 7:30 AM – 4:30 PM",
          "Wednesday: 7:30 AM – 4:30 PM",
          "Thursday: 7:30 AM – 4:30 PM",
          "Friday: 7:30 AM – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8828105, 37.4369137] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "North Valley Baptist School, 941 Clyde Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-8883",
      name: "North Valley Baptist Schools",
      rating: "5",
      website: "http://www.nvbschools.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1500" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1500" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: 8:00 AM – 3:00 PM",
          "Sunday: 8:00 AM – 3:00 PM",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9512313, 37.3903592] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1285 Escuela Pkwy, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 635-2800",
      name: "Milpitas High School",
      rating: "3.4",
      website: "http://mhs.musd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9005301, 37.4509315] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3175 Bowers Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 450-4962",
      name: "Scout from University of California",
      rating: "3",
      website: "http://www.ucscout.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1830" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1830" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1830" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1830" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1830" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "1730" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:30 PM",
          "Tuesday: 7:30 AM – 6:30 PM",
          "Wednesday: 7:30 AM – 6:30 PM",
          "Thursday: 7:30 AM – 6:30 PM",
          "Friday: 7:30 AM – 6:30 PM",
          "Saturday: 9:30 AM – 5:30 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9765484, 37.3796975] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3588 Zanker Rd, San Jose, CA 95134, USA",
      formatted_phone_number: "(669) 369-4400",
      name: "Kathleen MacDonald High School",
      rating: "missing",
      website: "https://macdonald.santaclarausd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1615" },
            open: { day: 1, time: "0815" },
          },
          {
            close: { day: 2, time: "1615" },
            open: { day: 2, time: "0815" },
          },
          {
            close: { day: 3, time: "1615" },
            open: { day: 3, time: "0815" },
          },
          {
            close: { day: 4, time: "1615" },
            open: { day: 4, time: "0815" },
          },
          {
            close: { day: 5, time: "1615" },
            open: { day: 5, time: "0815" },
          },
        ],
        weekday_text: [
          "Monday: 8:15 AM – 4:15 PM",
          "Tuesday: 8:15 AM – 4:15 PM",
          "Wednesday: 8:15 AM – 4:15 PM",
          "Thursday: 8:15 AM – 4:15 PM",
          "Friday: 8:15 AM – 4:15 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9342902, 37.4076871] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3901 Lick Mill Blvd APT 260, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(510) 573-2497",
      name: "Gurus Educational Services",
      rating: "missing",
      website: "http://www.guruseducation.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 3, time: "0000" },
            open: { day: 2, time: "0000" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: Open 24 hours",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9432068, 37.3960357] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1231 Arizona Ave, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Milpitas High School Pool",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9045306, 37.4493996] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-5532",
      name: "Mission Early College High School",
      rating: "missing",
      website: "https://mechs.santaclarausd.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1200" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1200" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1200" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1200" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1200" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 12:00 PM",
          "Tuesday: 8:30 AM – 12:00 PM",
          "Wednesday: 8:30 AM – 12:00 PM",
          "Thursday: 8:30 AM – 12:00 PM",
          "Friday: 8:30 AM – 12:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9835632, 37.3923072] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 855-5007",
      name: "Mission College",
      rating: "4.2",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9828037, 37.3925485] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "47671 Westinghouse Dr, Fremont, CA 94539, USA",
      formatted_phone_number: "(510) 592-9688",
      name: "Northwestern Polytechnic University",
      rating: "3.8",
      website: "https://www.npu.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "47671",
    },
    geometry: { type: "Point", coordinates: [-121.9259163, 37.4778899] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3120 Scott Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(833) 637-0866",
      name: "International Technological University (ITU)",
      rating: "4",
      website: "http://itu.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0930" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0930" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0930" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0930" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0930" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0930" },
          },
        ],
        weekday_text: [
          "Monday: 9:30 AM – 4:30 PM",
          "Tuesday: 9:30 AM – 4:30 PM",
          "Wednesday: 9:30 AM – 4:30 PM",
          "Thursday: 9:30 AM – 4:30 PM",
          "Friday: 9:30 AM – 4:30 PM",
          "Saturday: 9:30 AM – 4:30 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9635747, 37.3771463] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "90 E Tasman Dr, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 944-6000",
      name: "Palmer College of Chiropractic West Campus",
      rating: "4.2",
      website: "https://www.palmer.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:30 PM",
          "Tuesday: 8:00 AM – 4:30 PM",
          "Wednesday: 8:00 AM – 4:30 PM",
          "Thursday: 8:00 AM – 4:30 PM",
          "Friday: 8:00 AM – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9410472, 37.4101201] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "191 Baypointe Pkwy, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 498-5100",
      name: "University of Silicon Valley",
      rating: "3.5",
      website: "https://usv.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9407246, 37.4139545] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2010 Fortune Dr, San Jose, CA 95131, USA",
      formatted_phone_number: "(408) 435-8989",
      name: "Silicon Valley University",
      rating: "3.3",
      website: "http://www.svuca.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 4:00 PM",
          "Tuesday: 10:00 AM – 4:00 PM",
          "Wednesday: 10:00 AM – 4:00 PM",
          "Thursday: 10:00 AM – 4:00 PM",
          "Friday: 10:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95131",
    },
    geometry: { type: "Point", coordinates: [-121.8940921, 37.4003239] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3590 N First St, San Jose, CA 95134, USA",
      formatted_phone_number: "(800) 266-2107",
      name: "University of Phoenix - San Jose Learning Center",
      rating: "4",
      website:
        "http://www.phoenix.edu/campus-locations/ca/bay-area-campus/san-jose-learning-center.html",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9440658, 37.4087691] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3520 De La Cruz Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-8551",
      name: "Golden State Baptist College",
      rating: "4.5",
      website: "http://gsbc.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9447528, 37.3871774] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "420 Whitney Pl, Fremont, CA 94539, USA",
      formatted_phone_number: "(510) 553-9600",
      name: "Aviation Institute of Maintenance",
      rating: "4.2",
      website: "https://aviationmaintenance.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0830" },
          },
          {
            close: { day: 6, time: "1300" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 8:00 PM",
          "Tuesday: 9:00 AM – 8:00 PM",
          "Wednesday: 9:00 AM – 8:00 PM",
          "Thursday: 9:00 AM – 8:00 PM",
          "Friday: 8:30 AM – 5:00 PM",
          "Saturday: 9:00 AM – 1:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94539",
    },
    geometry: { type: "Point", coordinates: [-121.9210074, 37.4689154] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4435 Fortran Dr, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 234-5890",
      name: "California Reformed University & Seminary",
      rating: "missing",
      website: "http://cruseminary.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.9582065, 37.4229218] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1521 California Cir, Milpitas, CA 95035, USA",
      formatted_phone_number: "(844) 872-8680",
      name: "University of Silicon Andhra",
      rating: "4.5",
      website: "http://www.uofsa.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.921085, 37.451085] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Innospring, 2901 Tasman Dr #107, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(808) 626-5831",
      name: "Global University of Engineering",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.979004, 37.4041279] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Gary G. Gillmor & Family, 3000 Mission College Blvd, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 988-2200",
      name: "Gary G. Gillmor and Family Academic Center",
      rating: "4.7",
      website: "missing",
      opening_hours: "missing",
      first_type: "art_gallery",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9832284, 37.3920411] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "595 Lawrence Expy, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 481-9988",
      name: "Herguan University",
      rating: "3.4",
      website: "http://www.herguanuniversity.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-121.9969246, 37.3864641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "541 Sinclair Frontage Rd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 372-6837",
      name: "SVAMU",
      rating: "missing",
      website: "http://www.svamu.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "1100" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 11:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8858963, 37.4274992] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3175 Bowers Ave, Santa Clara, CA 95054, USA",
      formatted_phone_number: "(408) 861-3700",
      name: "UCSC Silicon Valley Extension",
      rating: "4.5",
      website: "https://www.ucsc-extension.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 7:00 PM",
          "Tuesday: 9:00 AM – 7:00 PM",
          "Wednesday: 9:00 AM – 7:00 PM",
          "Thursday: 9:00 AM – 7:00 PM",
          "Friday: 9:00 AM – 7:00 PM",
          "Saturday: 9:00 AM – 5:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95054",
    },
    geometry: { type: "Point", coordinates: [-121.9765833, 37.37967] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "500 E Calaveras Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "(408) 946-8505",
      name: "NCV College",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.8966543, 37.4324759] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "105 Fourier Ave, Fremont, CA 94539, USA",
      formatted_phone_number: "(510) 592-9688",
      name: "Northwestern Polytechnic University - East Building",
      rating: "4",
      website: "http://www.npu.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94539",
    },
    geometry: { type: "Point", coordinates: [-121.9251886, 37.4813608] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "91 E Tasman Dr, San Jose, CA 95134, USA",
      formatted_phone_number: "(408) 492-8097",
      name: "Institute for Business and Technology",
      rating: "3",
      website: "http://www.ibt.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2245" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2245" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2245" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2245" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2245" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 10:45 PM",
          "Tuesday: 8:00 AM – 10:45 PM",
          "Wednesday: 8:00 AM – 10:45 PM",
          "Thursday: 8:00 AM – 10:45 PM",
          "Friday: 8:00 AM – 10:45 PM",
          "Saturday: 8:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95134",
    },
    geometry: { type: "Point", coordinates: [-121.942975, 37.410988] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1525 McCarthy Blvd, Milpitas, CA 95035, USA",
      formatted_phone_number: "missing",
      name: "Ross Business Institute(Silicon Valley)",
      rating: "missing",
      website: "http://rossbi.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 5:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "95035",
    },
    geometry: { type: "Point", coordinates: [-121.9200217, 37.4064661] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 Aughinbaugh Way, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-4010",
      name: "Bay Farm School K-8",
      rating: "4.2",
      website: "https://bayfarm.alamedaunified.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0810" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0810" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0810" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0810" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0810" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:10 AM – 4:00 PM",
          "Tuesday: 8:10 AM – 4:00 PM",
          "Wednesday: 8:10 AM – 4:00 PM",
          "Thursday: 8:10 AM – 4:00 PM",
          "Friday: 8:10 AM – 4:00 PM",
          "Saturday: 9:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2519223, 37.7441211] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1801 N Loop Rd, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 522-0200",
      name: "Coastline Christian Schools",
      rating: "4.8",
      website: "https://coastlinechristian.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.2386101, 37.7273199] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Packet Landing Rd, Alameda, CA 94502, USA",
      formatted_phone_number: "(510) 748-4003",
      name: "Earhart Elementary School",
      rating: "3.7",
      website: "https://earhart.alamedaunified.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94502",
    },
    geometry: { type: "Point", coordinates: [-122.238127, 37.7446696] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "561 Merriewood Dr, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 927-3550",
      name: "Burton Valley Elementary School",
      rating: "4.3",
      website: "http://www.lafsd.k12.ca.us/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.0934236, 37.8612691] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1678 School St, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-7900",
      name: "The Saklan School",
      rating: "missing",
      website: "http://www.saklan.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1330739, 37.8330293] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "187 Pinehurst Rd, Canyon, CA 94516, USA",
      formatted_phone_number: "(925) 376-4671",
      name: "Canyon Elementary School",
      rating: "4.4",
      website: "https://canyon.k12.ca.us/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94516",
    },
    geometry: { type: "Point", coordinates: [-122.1635041, 37.8276031] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1111 Camino Pablo, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4435",
      name: "Camino Pablo Elementary School",
      rating: "4.5",
      website: "missing",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1212541, 37.8213872] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "90 Laird Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 376-4441",
      name: "Donald L. Rheem Elementary School",
      rating: "4.6",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1319712, 37.8574661] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "22 Wakefield Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-0105",
      name: "Los Perales Elementary School",
      rating: "missing",
      website: "http://lp.moraga.k12.ca.us/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1367705, 37.8472854] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25 El Camino Moraga, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 258-3099",
      name: "Del Rey Elementary",
      rating: "3.2",
      website: "https://dr.orindaschools.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1531865, 37.8458546] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "300 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 280-3950",
      name: "Campolindo High School",
      rating: "4.6",
      website: "https://www.acalanes.k12.ca.us/campolindo",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1261766, 37.8672417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Moraga Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 280-3930",
      name: "Miramonte High School",
      rating: "4.5",
      website: "http://www.acalanes.k12.ca.us/miramonte",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1455424, 37.8406792] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1660 School St #106a, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 466-3606",
      name: "StrivePath Academic & College Coaching - Lamorinda",
      rating: "5",
      website: "http://www.mystrivepath.com/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1325117, 37.8335265] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1928 St Marys Rd, Moraga, CA 94575, USA",
      formatted_phone_number: "(925) 631-4000",
      name: "Saint Mary's College of California",
      rating: "4.5",
      website: "http://www.stmarys-ca.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94575",
    },
    geometry: { type: "Point", coordinates: [-122.1089374, 37.8408837] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Hagerty Lounge",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1106969, 37.8400499] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Dryden Hall",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1090849, 37.8406516] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Filippi Administrative Building",
      rating: "missing",
      website: "http://www.stmarys-ca.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1096174, 37.8425023] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Saint Mary's College, De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Galileo 214",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1085498, 37.8422496] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Brother Urban Gregory (BUG) Hall, 1928 St Marys Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 744-7635",
      name: "Saint Mary’s College - School of Economics & Business Administration",
      rating: "4",
      website:
        "https://www.stmarys-ca.edu/school-of-economics-and-business-administration?utm_campaign=gmb",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94556",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.109441, 37.843668099999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Ferroggiaro Hall",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1096174, 37.8403406] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Dante Hall 203",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1096253, 37.8412117] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "L and U Samples",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1100303, 37.8427071] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Saint Mary's College, De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "SMC Brothers' Garden",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1080763, 37.8414367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 631-4000",
      name: "Galileo Hall",
      rating: "missing",
      website: "http://stmarys-ca.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1087299, 37.8421013] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Brother Urban Gregory Hall",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1095064, 37.8436728] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Saint Mary's College, De La Salle Dr, Moraga, CA 94556, USA",
      formatted_phone_number: "missing",
      name: "Warehouse 124",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1074058, 37.842472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1660 School St #106a, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 466-3606",
      name: "StrivePath Academic & College Coaching - Lamorinda",
      rating: "5",
      website: "http://www.mystrivepath.com/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1325117, 37.8335265] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3880 Middlefield Rd, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 213-8245",
      name: "Challenger School - Middlefield",
      rating: "4.4",
      website:
        "https://www.challengerschool.com/campus/california/palo-alto/middlefield?utm_source=gmb&utm_medium=organic&utm_term=md",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1110346, 37.4203519] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "310 Easy St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 254-0748",
      name: "German International School of Silicon Valley (GISSV) - Mountain View Campus",
      rating: "4.8",
      website: "http://www.gissv.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.066965, 37.400947] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "310 Easy St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 903-0986",
      name: "Yew Chung International School of Silicon Valley",
      rating: "4",
      website: "http://www.ycis-sv.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0669679, 37.3999824] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "253 Martens Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 526-3490",
      name: "Amy Imai Elementary School",
      rating: "4.5",
      website: "https://imai.mvwsd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0751432, 37.3748491] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "102 W Portola Ave #1210, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 947-4100",
      name: "Bullis Charter School, North Campus",
      rating: "3.9",
      website: "http://bullischarterschool.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:30 PM",
          "Tuesday: 8:00 AM – 4:30 PM",
          "Wednesday: 8:00 AM – 4:30 PM",
          "Thursday: 8:00 AM – 4:30 PM",
          "Friday: 8:00 AM – 4:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94022",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.1151076, 37.396194799999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "525 Hans Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 526-3480",
      name: "Benjamin Bubb Elementary School",
      rating: "4.3",
      website: "http://bubb.mvwsd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.081822, 37.378191] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "450 San Antonio Rd, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 494-8200",
      name: "Gideon Hausner Jewish Day School",
      rating: "4.9",
      website: "http://www.hausnerschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1048052, 37.4131101] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "115 W Dana St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 526-3520",
      name: "Landels School Field",
      rating: "4.3",
      website: "http://landels.mvwsd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0713186, 37.3883726] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "500 Toft St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 526-3590",
      name: "Mariano Castro Elementary School",
      rating: "4.2",
      website: "http://castro.mvwsd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0930453, 37.3944549] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4120 Middlefield Rd, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 856-0833",
      name: "Greendell School",
      rating: "4.7",
      website: "https://greendell.pausd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1065376, 37.4159787] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "550 Almond Ave, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 917-5400",
      name: "Almond Elementary School",
      rating: "4",
      website: "http://www.almondschool.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1014523, 37.3838271] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1054 Carson Dr, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 522-8267",
      name: "Vargas Elementary School",
      rating: "4.4",
      website: "http://www.sesd.org/vargas",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:30 PM",
          "Tuesday: 7:30 AM – 3:30 PM",
          "Wednesday: 7:30 AM – 3:30 PM",
          "Thursday: 7:30 AM – 3:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0488516, 37.3814037] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1120 Rose Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 943-4200",
      name: "Springer Elementary School",
      rating: "5",
      website: "http://www.springerschool.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0945358, 37.3713306] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1625 San Luis Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 903-6925",
      name: "Theuerkauf Elementary School",
      rating: "4.1",
      website: "http://theuerkauf.mvwsd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0838847, 37.4053772] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 Thompson Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 903-6915",
      name: "Monta Loma Elementary",
      rating: "4.6",
      website: "http://montaloma.mvwsd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0985336, 37.4089402] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1120 Miramonte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 967-1839",
      name: "St. Joseph Mountain View",
      rating: "4.2",
      website: "https://www.sjmv.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0875421, 37.3846747] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "500 E Meadow Dr, Palo Alto, CA 94306, USA",
      formatted_phone_number: "(650) 856-0845",
      name: "Fairmeadow Elementary School",
      rating: "4.2",
      website: "https://fairmeadow.pausd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1172028, 37.4224396] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "195 Leota Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 243-9333",
      name: "Silicon Valley Academy",
      rating: "4.1",
      website: "http://svaschool.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94086",
    },
    geometry: { type: "Point", coordinates: [-122.0503183, 37.3807509] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 San Pierre Way, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 903-6950",
      name: "Stevenson Elementary School",
      rating: "4.6",
      website: "http://stevenson.mvwsd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0834582, 37.4038635] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "445 S Mary Ave, Sunnyvale, CA 94086, USA",
      formatted_phone_number: "(408) 736-3286",
      name: "Sunnyvale Christian School",
      rating: "4.9",
      website: "http://www.sunnyvalechristian.school/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 5:30 PM",
          "Tuesday: 7:30 AM – 5:30 PM",
          "Wednesday: 7:30 AM – 5:30 PM",
          "Thursday: 7:30 AM – 5:30 PM",
          "Friday: 7:30 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94086",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0481419, 37.373868900000012],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "201 Almond Ave, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 960-8811",
      name: "Los Altos High School",
      rating: "3.8",
      website: "http://www.mvla.net/lahs/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1093843, 37.3859381] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 Miramonte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 968-1213",
      name: "Saint Francis High School",
      rating: "4.3",
      website: "http://www.sfhs.com/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:30 PM",
          "Tuesday: 8:00 AM – 2:30 PM",
          "Wednesday: 8:00 AM – 2:30 PM",
          "Thursday: 8:00 AM – 2:30 PM",
          "Friday: 8:00 AM – 2:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.0863418, 37.3695904] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "310 Easy St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 254-0748",
      name: "German International School of Silicon Valley (GISSV) - Mountain View Campus",
      rating: "4.8",
      website: "http://www.gissv.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.066965, 37.400947] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "360 S Shoreline Blvd, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 967-2324",
      name: "Mountain View Academy",
      rating: "4.6",
      website: "https://www.mountainviewacademy.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 3:00 PM",
          "Tuesday: 9:00 AM – 3:00 PM",
          "Wednesday: 9:00 AM – 3:00 PM",
          "Thursday: 9:00 AM – 3:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0849872, 37.3938722] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3900 Fabian Way, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 213-9600",
      name: "Kehillah Jewish High School",
      rating: "4.3",
      website: "http://www.kehillah.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0905" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 3:00 PM",
          "Tuesday: 8:30 AM – 3:00 PM",
          "Wednesday: 8:30 AM – 3:00 PM",
          "Thursday: 9:05 AM – 3:00 PM",
          "Friday: 8:30 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1045408, 37.424716] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "909 N San Antonio Rd, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 941-4350",
      name: "School for Independent Learners",
      rating: "4.7",
      website: "https://sileducation.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1140907, 37.3994522] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "180 N Rengstorff Ave, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 417-7600",
      name: "Waldorf School of the Peninsula - Mountain View Campus",
      rating: "3.3",
      website:
        "https://waldorfpeninsula.org/?utm_source=google_business&utm_medium=organic&utm_campaign=mountain_view_google_business_profile",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 4:00 PM",
          "Tuesday: 7:45 AM – 4:00 PM",
          "Wednesday: 7:45 AM – 4:00 PM",
          "Thursday: 7:45 AM – 4:00 PM",
          "Friday: 7:45 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0966936, 37.4056494] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3921 Fabian Way, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 485-3589",
      name: "Meira Academy",
      rating: "5",
      website: "http://meiraacademy.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1020687, 37.4234817] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1885 Miramonte Ave, Mountain View, CA 94040, USA",
      formatted_phone_number: "(650) 694-6368",
      name: "Summer at Saint Francis",
      rating: "missing",
      website: "http://sfhs.com/Summer",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 2:00 PM",
          "Tuesday: 9:00 AM – 2:00 PM",
          "Wednesday: 9:00 AM – 2:00 PM",
          "Thursday: 9:00 AM – 2:00 PM",
          "Friday: 9:00 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.086637, 37.3696153] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2462 Wyandotte St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 493-7071",
      name: "Palo Alto Preparatory School",
      rating: "4.1",
      website: "http://paloaltoprep.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 1:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94043",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0981658, 37.417064800000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1200 Villa St, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 489-0071",
      name: "Khan Lab School- Upper School",
      rating: "4.2",
      website: "https://khanlabschool.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0840059, 37.3966478] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "655 W Evelyn Ave, Mountain View, CA 94041, USA",
      formatted_phone_number: "(855) 552-3194",
      name: "View High School",
      rating: "missing",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 2, time: "0000" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 12:00 AM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.0770423, 37.3939872] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "490 W El Camino Real, Mountain View, CA 94041, USA",
      formatted_phone_number: "(650) 336-0816",
      name: "Russian School of Mathematics - Mountain View",
      rating: "4.4",
      website: "https://www.russianschool.com/location/mountain-view",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2000" },
            open: { day: 1, time: "1500" },
          },
          {
            close: { day: 2, time: "2000" },
            open: { day: 2, time: "1500" },
          },
          {
            close: { day: 3, time: "2000" },
            open: { day: 3, time: "1500" },
          },
          {
            close: { day: 4, time: "2000" },
            open: { day: 4, time: "1500" },
          },
          {
            close: { day: 5, time: "2000" },
            open: { day: 5, time: "1500" },
          },
        ],
        weekday_text: [
          "Monday: 3:00 – 8:00 PM",
          "Tuesday: 3:00 – 8:00 PM",
          "Wednesday: 3:00 – 8:00 PM",
          "Thursday: 3:00 – 8:00 PM",
          "Friday: 3:00 – 8:00 PM",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 5:00 PM",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94041",
    },
    geometry: { type: "Point", coordinates: [-122.080327, 37.3842958] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Meadows Office Complex, 301 Route 17 North 12th floor, Mountain View, CA 94040, USA",
      formatted_phone_number: "(800) 222-6284",
      name: "Kumon",
      rating: "missing",
      website: "https://www.kumon.com/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94040",
    },
    geometry: { type: "Point", coordinates: [-122.1062651, 37.403651] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Classroom, 4000 Middlefield Rd Room K5, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(650) 646-1066",
      name: "Living Wisdom High School",
      rating: "5",
      website: "http://www.livingwisdomhighschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 6:00 PM",
          "Tuesday: 8:30 AM – 6:00 PM",
          "Wednesday: 8:30 AM – 6:00 PM",
          "Thursday: 8:30 AM – 6:00 PM",
          "Friday: 8:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1091145, 37.4171703] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "909 N San Antonio Rd, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 941-4350",
      name: "Little Free Library",
      rating: "missing",
      website: "https://sileducation.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1139388, 37.3994367] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "201 Almond Ave, Los Altos, CA 94022, USA",
      formatted_phone_number: "missing",
      name: "Large Gym",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1089705, 37.3869731] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "480 E Meadow Dr, Palo Alto, CA 94306, USA",
      formatted_phone_number: "missing",
      name: "Palo Alto Chinese School",
      rating: "5",
      website: "http://www.paloaltochineseschool.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "1900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: 7:00 – 9:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94306",
    },
    geometry: { type: "Point", coordinates: [-122.1190419, 37.4213879] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "NASA Research Park, Building 23 Moffett Field, CA 94035, USA",
      formatted_phone_number: "(412) 268-2000",
      name: "Carnegie Mellon University Silicon Valley",
      rating: "4.8",
      website: "http://sv.cmu.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94035",
    },
    geometry: { type: "Point", coordinates: [-122.0597686, 37.4104329] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1069 E Meadow Cir, Palo Alto, CA 94303, USA",
      formatted_phone_number: "(888) 820-1484",
      name: "Sofia University",
      rating: "4",
      website: "http://www.sofia.edu",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1089342, 37.4301211] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "5150 El Camino Real Building D, Suite D12, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 961-5555",
      name: "Palo Alto University - Los Altos Campus",
      rating: "3.5",
      website: "https://www.paloaltou.edu/mountainviewcampus",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1024525, 37.395171] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "PO Box 4001, Los Altos, CA 94022, USA",
      formatted_phone_number: "(650) 292-0751",
      name: "Metavu Network",
      rating: "missing",
      website: "http://metavu.net/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94022",
    },
    geometry: { type: "Point", coordinates: [-122.1141298, 37.3852183] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1070 Innovation Way, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "(408) 745-8000",
      name: "Foothill College Sunnyvale Center",
      rating: "4.6",
      website: "https://foothill.edu/sunnyvale",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: 10:00 AM – 3:00 PM",
          "Wednesday: 10:00 AM – 3:00 PM",
          "Thursday: 10:00 AM – 3:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0288871, 37.4047486] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Wyandotte St, Mountain View, CA 94043, USA",
      formatted_phone_number: "(650) 938-2478",
      name: "China Graduate School of Theology",
      rating: "1",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0978491, 37.417049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1075 Space Park Way, Mountain View, CA 94043, USA",
      formatted_phone_number: "missing",
      name: "Langley Ops and training",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94043",
    },
    geometry: { type: "Point", coordinates: [-122.0694126, 37.4164458] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1069 E Meadow Cir, Palo Alto, CA 94303, USA",
      formatted_phone_number: "missing",
      name: "台科大矽谷中心",
      rating: "missing",
      website:
        "https://www.bic.ntust.edu.tw/%E9%81%B8%E6%8B%94%E6%A0%A1%E5%9C%92%E8%A1%8D%E7%94%9F%E5%89%B5%E6%A5%AD%E9%9A%8A%E5%8F%8A%E9%80%B2%E9%A7%90%E4%BC%81%E6%A5%AD%E8%B5%B4%E7%9F%BD%E8%B0%B7%E8%A8%88%E7%95%AB",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94303",
    },
    geometry: { type: "Point", coordinates: [-122.1088558, 37.430074] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "525 Del Rey Ave, Sunnyvale, CA 94085, USA",
      formatted_phone_number: "(408) 480-1934",
      name: "BM RESEARCH",
      rating: "4",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94085",
    },
    geometry: { type: "Point", coordinates: [-122.0316238, 37.3932952] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "900 Hamlin Ct, Sunnyvale, CA 94089, USA",
      formatted_phone_number: "missing",
      name: "Carnegie Mellon",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94089",
    },
    geometry: { type: "Point", coordinates: [-122.0290939, 37.3999954] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1 Hiller Dr, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 843-2512",
      name: "Bentley School Hiller",
      rating: "4.8",
      website:
        "http://www.bentleyschool.org/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing&utm_id=gmb_listing&utm_content=lower+school",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2338803, 37.8532057] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "350 Camino Pablo, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 258-0016",
      name: "Wagner Ranch Elementary School",
      rating: "5",
      website: "https://wr.orindaschools.org/#",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.2070801, 37.8976943] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4850 Clarewood Dr, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 547-3146",
      name: "St Theresa Catholic School",
      rating: "4.2",
      website: "http://www.sttheresaschool.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2322603, 37.8371675] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25 S Hill Ct, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 549-4900",
      name: "Kaiser Early Childhood Center",
      rating: "5",
      website: "https://www.ousd.org/enroll/enroll-at-ousd/ece",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2315311, 37.8527693] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2955 Claremont Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 644-6410",
      name: "John Muir Elementary School",
      rating: "4.8",
      website: "http://johnmuirelementary.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2450198, 37.8574427] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6686 Chabot Rd, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 654-4884",
      name: "Chabot Elementary School",
      rating: "4.6",
      website: "http://www.chabotelementary.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2430573, 37.8503912] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2800 Forest Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 644-6890",
      name: "Emerson Elementary School",
      rating: "5",
      website:
        "http://www.berkeleyschools.net/schools/elementary-schools/emerson/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2502782, 37.8610751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "830 Regal Rd, Berkeley, CA 94708, USA",
      formatted_phone_number: "(510) 644-8810",
      name: "Cragmont Elementary School",
      rating: "4.6",
      website: "http://www.cragmont.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1900" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1900" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1900" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1900" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1900" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 7:00 PM",
          "Tuesday: 7:00 AM – 7:00 PM",
          "Wednesday: 7:00 AM – 7:00 PM",
          "Thursday: 7:00 AM – 7:00 PM",
          "Friday: 7:00 AM – 7:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94708",
    },
    geometry: { type: "Point", coordinates: [-122.2678292, 37.8941018] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3855 Happy Valley Rd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 927-3560",
      name: "Happy Valley Elementary School",
      rating: "4.1",
      website: "http://www.lafsd.k12.ca.us/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1423885, 37.9045286] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "460 63rd St, Oakland, CA 94609, USA",
      formatted_phone_number: "(510) 654-7365",
      name: "Peralta Elementary School",
      rating: "4.6",
      website: "https://www.peraltaschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94609",
    },
    geometry: { type: "Point", coordinates: [-122.2586978, 37.849492] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1757 Mountain Blvd, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-6100",
      name: "Montclair Elementary School",
      rating: "4.3",
      website: "https://www.ousd.org/montclair",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2128694, 37.830872] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "187 Pinehurst Rd, Canyon, CA 94516, USA",
      formatted_phone_number: "(925) 376-4671",
      name: "Canyon Elementary School",
      rating: "4.4",
      website: "https://canyon.k12.ca.us/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94516",
    },
    geometry: { type: "Point", coordinates: [-122.1635041, 37.8276031] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5880 Thornhill Dr, Oakland, CA 94611, USA",
      formatted_phone_number: "(510) 339-6800",
      name: "Thornhill Elementary School",
      rating: "4.3",
      website: "http://www.thornhillschool.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94611",
    },
    geometry: { type: "Point", coordinates: [-122.2110144, 37.8359106] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "30 Marguerite Dr, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 879-1270",
      name: "Hillcrest Elementary School",
      rating: "4.3",
      website: "http://www.hillcrestpta.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2326884, 37.8406516] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2722 Benvenue Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 549-0605",
      name: "The Academy",
      rating: "4.5",
      website: "http://theacademyschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 4:00 PM",
          "Tuesday: 8:30 AM – 4:00 PM",
          "Wednesday: 8:30 AM – 4:00 PM",
          "Thursday: 8:30 AM – 4:00 PM",
          "Friday: 8:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2549692, 37.8608195] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1225 Milvia St, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 526-4744",
      name: "School of the Madeleine",
      rating: "5",
      website: "https://themadeleine.com/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94709",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2717914, 37.884845899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "40 Dulwich Rd, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 428-2606",
      name: "Aurora School",
      rating: "5",
      website: "http://www.auroraschool.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2313127, 37.8399198] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2840 Ellsworth St, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 644-6290",
      name: "Sylvia Mendez Elementary School",
      rating: "4.2",
      website: "http://www.sylviamendezschool.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2619258, 37.8581242] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2015 Virginia St, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 644-6225",
      name: "Berkeley Arts Magnet School",
      rating: "3.8",
      website: "http://www.bampta.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2704667, 37.8768558] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "410 Alcatraz Ave, Oakland, CA 94609, USA",
      formatted_phone_number: "(510) 653-3324",
      name: "Escuela Bilingüe Internacional (EBI) | Preschool & 1st Grade School",
      rating: "4.6",
      website: "http://www.ebinternacional.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94609",
    },
    geometry: { type: "Point", coordinates: [-122.2571218, 37.8511751] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "300 Moraga Rd, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 280-3950",
      name: "Campolindo High School",
      rating: "4.6",
      website: "https://www.acalanes.k12.ca.us/campolindo",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1261766, 37.8672417] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6100 Broadway, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 652-0111",
      name: "The College Preparatory School",
      rating: "4.6",
      website: "http://www.college-prep.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1545" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1545" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1545" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1545" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1545" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 3:45 PM",
          "Tuesday: 7:45 AM – 3:45 PM",
          "Wednesday: 7:45 AM – 3:45 PM",
          "Thursday: 7:45 AM – 3:45 PM",
          "Friday: 7:45 AM – 3:45 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2396198, 37.8482687] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "750 Moraga Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 280-3930",
      name: "Miramonte High School",
      rating: "4.5",
      website: "http://www.acalanes.k12.ca.us/miramonte",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1455424, 37.8406792] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1200 Pleasant Hill Rd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 280-3970",
      name: "Acalanes High School",
      rating: "4.5",
      website: "https://www.acalanes.k12.ca.us/Page/151",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94549",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0976332, 37.903892899999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "19 Altarinda Rd, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-7553",
      name: "Orinda Academy",
      rating: "4.8",
      website: "https://www.orindaacademy.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 4:00 PM",
          "Tuesday: 8:30 AM – 4:00 PM",
          "Wednesday: 8:30 AM – 4:00 PM",
          "Thursday: 8:30 AM – 4:00 PM",
          "Friday: 8:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1838179, 37.8840068] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2727 College Ave, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 841-8489",
      name: "Maybeck High School",
      rating: "5",
      website: "http://maybeckhs.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1330" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 3:30 PM",
          "Tuesday: 7:45 AM – 3:30 PM",
          "Wednesday: 7:45 AM – 3:30 PM",
          "Thursday: 7:45 AM – 3:30 PM",
          "Friday: 7:45 AM – 1:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2524409, 37.8608534] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4660 Harbord Dr, Oakland, CA 94618, USA",
      formatted_phone_number: "(510) 450-1110",
      name: "Holy Names High School",
      rating: "4.7",
      website: "https://www.hnhsoakland.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94618",
    },
    geometry: { type: "Point", coordinates: [-122.2313724, 37.8401435] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10 Irwin Way, Orinda, CA 94563, USA",
      formatted_phone_number: "(925) 254-0199",
      name: "Holden High School",
      rating: "4.6",
      website: "https://holdenhigh.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94563",
    },
    geometry: { type: "Point", coordinates: [-122.1900777, 37.8850855] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1000 Upper Happy Valley Rd, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 283-2101",
      name: "Bentley Upper School",
      rating: "3.3",
      website:
        "http://www.bentleyschool.org/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing&utm_id=gmb_listing&utm_content=upper+school",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1511841, 37.8925711] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1918 Bonita Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 517-6609",
      name: "Mentoring Academy",
      rating: "4.3",
      website: "http://www.mentoringacademy.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1730" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1730" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1730" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1730" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:30 PM",
          "Tuesday: 9:00 AM – 5:30 PM",
          "Wednesday: 9:00 AM – 5:30 PM",
          "Thursday: 9:00 AM – 5:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2721678, 37.8726907] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1980 Allston Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 644-6121",
      name: "Berkeley High School",
      rating: "4",
      website: "http://bhs.berkeleyschools.net/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2714149, 37.867470700000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "970 Dewing Ave Suite 202, Lafayette, CA 94549, USA",
      formatted_phone_number: "(925) 385-0562",
      name: "Doing College and Beyond",
      rating: "missing",
      website: "http://www.doingcollege.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "1800" },
            open: { day: 6, time: "1300" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 9:00 PM",
          "Tuesday: 8:00 AM – 9:00 PM",
          "Wednesday: 8:00 AM – 9:00 PM",
          "Thursday: 8:00 AM – 9:00 PM",
          "Friday: 8:00 AM – 9:00 PM",
          "Saturday: 1:00 – 6:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94549",
    },
    geometry: { type: "Point", coordinates: [-122.1232062, 37.8905166] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1940 Virginia St, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 984-0599",
      name: "Bayhill High School",
      rating: "4.4",
      website:
        "https://www.bayhillhs.org/?utm_source=google_maps&utm_medium=organic",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1300" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 1:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.271655, 37.876061] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "922 Santa Barbara Rd, Berkeley, CA 94707, USA",
      formatted_phone_number: "(510) 859-4535",
      name: "Sallie B College Consulting",
      rating: "5",
      website: "https://www.salliebcollegeconsulting.com/contact",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94707",
    },
    geometry: { type: "Point", coordinates: [-122.2679644, 37.8922058] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1414 Walnut St, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 843-4667",
      name: "Berkeley-Oakland Midrasha",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2686232, 37.881664] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1681 Shattuck Ave., Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 548-6612",
      name: "Emerging Writers Institute",
      rating: "missing",
      website: "https://www.emergingwritersinstitute.com/contact",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94709",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2688484, 37.876845599999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2000 Bancroft Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Berkeley High M Building",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94704",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.2705131, 37.866891599999988],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2000 Bancroft Way, Berkeley, CA 94704, USA",
      formatted_phone_number: "missing",
      name: "Donahue Gym",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.271081, 37.8669066] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1660 School St #106a, Moraga, CA 94556, USA",
      formatted_phone_number: "(925) 466-3606",
      name: "StrivePath Academic & College Coaching - Lamorinda",
      rating: "5",
      website: "http://www.mystrivepath.com/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94556",
    },
    geometry: { type: "Point", coordinates: [-122.1325117, 37.8335265] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "University Avenue and, Oxford St, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-6000",
      name: "University of California, Berkeley",
      rating: "3.5",
      website: "https://www.berkeley.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2594606, 37.870151] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 Centennial Dr, Berkeley, CA 94705, USA",
      formatted_phone_number: "(510) 643-2755",
      name: "Julia Morgan Hall",
      rating: "4",
      website:
        "http://botanicalgarden.berkeley.edu/julia_morgan_hall#tab-1-0-overview",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1700" },
            open: { day: 0, time: "1000" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "1000" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "1000" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "1000" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "1000" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "1000" },
          },
          {
            close: { day: 6, time: "1700" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 10:00 AM – 5:00 PM",
          "Tuesday: 10:00 AM – 5:00 PM",
          "Wednesday: 10:00 AM – 5:00 PM",
          "Thursday: 10:00 AM – 5:00 PM",
          "Friday: 10:00 AM – 5:00 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 10:00 AM – 5:00 PM",
        ],
      },
      first_type: "university",
      zip_code: "94705",
    },
    geometry: { type: "Point", coordinates: [-122.2383129, 37.8747632] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Senior Hall, Berkeley, CA 94720, USA",
      formatted_phone_number: "(650) 722-1510",
      name: "Senior Hall",
      rating: "missing",
      website: "http://ogb.berkeley.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2553301, 37.8718875] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "225 Bancroft Way, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-2274",
      name: "Berkeley Law Admissions Office",
      rating: "4.8",
      website: "http://www.law.berkeley.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.254074, 37.8694689] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "200 Minor Ln, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-2020",
      name: "Meredith Morgan Eye Center",
      rating: "4.1",
      website: "https://eyecare.berkeley.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1530" },
            open: { day: 0, time: "0830" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1530" },
            open: { day: 6, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:30 PM",
          "Tuesday: 9:00 AM – 4:30 PM",
          "Wednesday: 9:00 AM – 4:30 PM",
          "Thursday: 9:00 AM – 4:30 PM",
          "Friday: 9:00 AM – 4:30 PM",
          "Saturday: 8:30 AM – 3:30 PM",
          "Sunday: 8:30 AM – 3:30 PM",
        ],
      },
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2552904, 37.8712924] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2728 Durant Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 841-9230",
      name: "Wright Institute",
      rating: "4.9",
      website: "http://www.wi.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2531627, 37.868295] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "101 Cross-Sproul Path, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 642-0212",
      name: "Hertz Hall",
      rating: "4.9",
      website: "https://calperformances.org/visit/venues/hertz-hall/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2557289, 37.8710584] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2299 Piedmont Ave, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-9490",
      name: "International House",
      rating: "4.2",
      website: "http://ihouse.berkeley.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2300" },
            open: { day: 0, time: "0800" },
          },
          {
            close: { day: 1, time: "2300" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "2300" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "2300" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "2300" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "2300" },
            open: { day: 5, time: "0800" },
          },
          {
            close: { day: 6, time: "2300" },
            open: { day: 6, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 11:00 PM",
          "Tuesday: 8:00 AM – 11:00 PM",
          "Wednesday: 8:00 AM – 11:00 PM",
          "Thursday: 8:00 AM – 11:00 PM",
          "Friday: 8:00 AM – 11:00 PM",
          "Saturday: 8:00 AM – 11:00 PM",
          "Sunday: 8:00 AM – 11:00 PM",
        ],
      },
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2519854, 37.8696753] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Berkeley, CA 94709, USA",
      formatted_phone_number: "missing",
      name: "Memorial Pool",
      rating: "2.4",
      website: "http://www.berkeley.edu/map?memglade",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2602161, 37.8730714] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2018 Oxford St, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 642-6000",
      name: "University Hall",
      rating: "missing",
      website: "http://www.berkeley.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2662639, 37.8722049] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "290 Simon Hall, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-4567",
      name: "Career Development Office-Berkeley",
      rating: "missing",
      website: "https://career.berkeley.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2526135, 37.8698324] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Ridge Rd, Berkeley, CA 94709, USA",
      formatted_phone_number: "(510) 649-2400",
      name: "Graduate Theological Union",
      rating: "5",
      website: "https://www.gtu.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "library",
      zip_code: "94709",
    },
    geometry: { type: "Point", coordinates: [-122.2618869, 37.8756942] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2336 Piedmont Ave, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 540-1984",
      name: "Greenlining Institute - Casa Joaquin House",
      rating: "missing",
      website: "https://greenlining.org/work/leadership-academy/casa-joaquin/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2524289, 37.8682353] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Minor Hall, 380 Minor Ln, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-2020",
      name: "UC Berkeley School of Optometry Professional Program",
      rating: "5",
      website: "http://optometry.berkeley.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1630" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1630" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1630" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1630" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:30 PM",
          "Tuesday: 9:00 AM – 4:30 PM",
          "Wednesday: 9:00 AM – 4:30 PM",
          "Thursday: 9:00 AM – 4:30 PM",
          "Friday: 9:00 AM – 4:30 PM",
          "Saturday: 9:00 AM – 4:30 PM",
          "Sunday: 9:00 AM – 4:30 PM",
        ],
      },
      first_type: "school",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2546416, 37.8714207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "260 Chavez Student Ctr, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 642-0518",
      name: "Disabled Students' Program",
      rating: "4",
      website: "http://dsp.berkeley.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2600361, 37.8697743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Oppenheimer Way, Berkeley, CA 94720, USA",
      formatted_phone_number: "missing",
      name: "Hearst Mining Circle",
      rating: "4.8",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      first_type: "park",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2570583, 37.8735547] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 486-4000",
      name: "Building 50B",
      rating: "5",
      website: "http://www.lbl.gov/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2525618, 37.8766666] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2050 Center St, Berkeley, CA 94704, USA",
      formatted_phone_number: "(510) 981-2800",
      name: "Berkeley City College",
      rating: "4.7",
      website: "https://www.berkeleycitycollege.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2230" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "2230" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "2230" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "2230" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "2230" },
            open: { day: 5, time: "0730" },
          },
          {
            close: { day: 6, time: "2230" },
            open: { day: 6, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 10:30 PM",
          "Tuesday: 7:30 AM – 10:30 PM",
          "Wednesday: 7:30 AM – 10:30 PM",
          "Thursday: 7:30 AM – 10:30 PM",
          "Friday: 7:30 AM – 10:30 PM",
          "Saturday: 7:30 AM – 10:30 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2698694, 37.8699811] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2000 Center St Suite 200, Berkeley, CA 94704, USA",
      formatted_phone_number: "(800) 235-7587",
      name: "Pacific Lutheran Theological Seminary",
      rating: "3.4",
      website: "http://www.plts.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94704",
    },
    geometry: { type: "Point", coordinates: [-122.2702781, 37.8699059] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "72 Lawrence Rd, Berkeley, CA 94720, USA",
      formatted_phone_number: "(510) 486-6036",
      name: "National Center for Electron Microscopy",
      rating: "5",
      website: "http://foundry.lbl.gov/facilities/ncem/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94720",
    },
    geometry: { type: "Point", coordinates: [-122.2424563, 37.8755073] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6363 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8000",
      name: "The Quarry Lane School",
      rating: "2.7",
      website: "https://www.quarrylane.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8701133, 37.7275323] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4000 Goldenbay Ave, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 560-4000",
      name: "Quail Run Elementary School",
      rating: "4.1",
      website: "https://qres.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9142774, 37.7628743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5151 Sherwood Way, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 803-3100",
      name: "Live Oak Elementary School",
      rating: "4.4",
      website: "https://loes.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:30 PM",
          "Tuesday: 8:00 AM – 2:30 PM",
          "Wednesday: 8:00 AM – 2:30 PM",
          "Thursday: 8:00 AM – 2:30 PM",
          "Friday: 8:00 AM – 2:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.8945204, 37.7541022] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3300 Dennis Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4256",
      name: "Mohr Elementary School",
      rating: "5",
      website: "http://mohr.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8601609, 37.6916729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4150 Dorman Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4220",
      name: "Donlon Elementary",
      rating: "5",
      website: "https://donlon.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:30 PM",
          "Tuesday: 8:00 AM – 3:30 PM",
          "Wednesday: 8:00 AM – 3:30 PM",
          "Thursday: 8:00 AM – 3:30 PM",
          "Friday: 8:00 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9075869, 37.6844878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4151 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4210",
      name: "Fairlands Elementary School",
      rating: "4.6",
      website: "https://fairlands.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8740946, 37.6924617] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3250 Pine Valley Rd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 479-3900",
      name: "Walt Disney Elementary School",
      rating: "5",
      website: "https://wdes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.93525, 37.741216] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8700 N Gale Ridge Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 855-7300",
      name: "Coyote Creek Elementary School",
      rating: "4.8",
      website: "https://ckes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9273831, 37.777944] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6011 Massara St, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 314-2000",
      name: "Creekside Elementary School",
      rating: "5",
      website: "https://cres.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 6, time: "0000" },
            open: { day: 1, time: "0000" },
          },
        ],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.8812539, 37.7923207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3150 Palermo Way, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4000",
      name: "Kolb Elementary School",
      rating: "4.4",
      website: "http://kes.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8589254, 37.7123365] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3300 Antone Way, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-4200",
      name: "John Green Elementary School",
      rating: "3.4",
      website: "http://ges.dublinusd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8615009, 37.7184877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7243 Tamarack Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-1037",
      name: "Frederiksen Elementary School",
      rating: "4.4",
      website: "http://fes.dublinusd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9228665, 37.7152067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2300 Talavera Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 242-3200",
      name: "Bollinger Canyon Elementary",
      rating: "4",
      website: "https://bces.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.970968, 37.7611629] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5025 Canyon Crest Dr, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 855-2700",
      name: "Golden View Elementary School",
      rating: "4.8",
      website: "https://gles.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.943058, 37.78704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "13000 Broadmoor Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 479-6100",
      name: "Montevideo Elementary School",
      rating: "4.1",
      website: "https://moes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "13000",
    },
    geometry: { type: "Point", coordinates: [-121.9510692, 37.7558209] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "480 Adams Way, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4230",
      name: "Valley View Elementary School",
      rating: "4.2",
      website: "http://valleyview.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8623056, 37.6603096] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8435 Davona Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-2568",
      name: "Murray Elementary School",
      rating: "4.7",
      website: "http://mes.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9330196, 37.7220371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1273 N Livermore Ave, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 455-8021",
      name: "Valley Montessori School",
      rating: "4.6",
      website: "http://valleymontessorischool.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 6:00 PM",
          "Tuesday: 7:30 AM – 6:00 PM",
          "Wednesday: 7:30 AM – 6:00 PM",
          "Thursday: 7:30 AM – 6:00 PM",
          "Friday: 7:30 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7718129, 37.6953688] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4576 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 315-4940",
      name: "Stratford School",
      rating: "3.6",
      website:
        "https://www.stratfordschools.com/schools/northern-california/east-bay/pleasanton/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992526, 37.6898586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "298 Junction Ave, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 606-4720",
      name: "Junction Avenue K-8 School",
      rating: "3.9",
      website: "https://www.livermoreschools.org/junction",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0810" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0810" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0810" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0810" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0810" },
          },
        ],
        weekday_text: [
          "Monday: 8:10 AM – 4:00 PM",
          "Tuesday: 8:10 AM – 4:00 PM",
          "Wednesday: 8:10 AM – 4:00 PM",
          "Thursday: 8:10 AM – 4:00 PM",
          "Friday: 8:10 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.766303, 37.687582] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8151 Village Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-3300",
      name: "Dublin High School",
      rating: "3.2",
      website: "https://dhs.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9241051, 37.7203614] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9870 Broadmoor Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 803-3200",
      name: "California High School",
      rating: "3.7",
      website: "http://chs.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9455518, 37.7467376] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10550 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-6400",
      name: "Dougherty Valley High School",
      rating: "4.5",
      website: "https://dvhs.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 4:00 PM",
          "Tuesday: 7:45 AM – 4:00 PM",
          "Wednesday: 7:45 AM – 4:00 PM",
          "Thursday: 7:45 AM – 4:00 PM",
          "Friday: 7:45 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10550",
    },
    geometry: { type: "Point", coordinates: [-121.9023247, 37.7692188] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Wall St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 606-4800",
      name: "Granada High School",
      rating: "3.4",
      website: "http://www.livermoreschools.org/granadahigh",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.7880118, 37.674877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6363 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8000",
      name: "The Quarry Lane School",
      rating: "2.7",
      website: "https://www.quarrylane.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8701133, 37.7275323] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "600 Maple St, Livermore, CA 94550, USA",
      formatted_phone_number: "(925) 606-4812",
      name: "Livermore High School",
      rating: "4",
      website: "https://www.livermoreschools.org/livermorehigh",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0825" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0825" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0825" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0825" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0825" },
          },
        ],
        weekday_text: [
          "Monday: 8:25 AM – 4:00 PM",
          "Tuesday: 8:25 AM – 4:00 PM",
          "Wednesday: 8:25 AM – 4:00 PM",
          "Thursday: 8:25 AM – 4:00 PM",
          "Friday: 8:25 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94550",
    },
    geometry: { type: "Point", coordinates: [-121.761673, 37.6808812] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "27170 Mission Blvd, Hayward, CA 94544, USA",
      formatted_phone_number: "(510) 881-4300",
      name: "Moreau Catholic High School",
      rating: "4.1",
      website: "http://www.moreaucatholic.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "27170",
    },
    geometry: { type: "Point", coordinates: [-122.0599937, 37.6438645] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "B-Building",
      rating: "missing",
      website: "http://foothillfalcons.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9189549, 37.6731568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "Foothill High School",
      rating: "4.1",
      website: "http://www.foothillfalcons.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:30 PM",
          "Tuesday: 7:30 AM – 4:30 PM",
          "Wednesday: 7:00 AM – 2:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9198812, 37.6725157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6379 Clark Ave STE 200, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 587-5610",
      name: "DPIE - Dublin Partners in Education",
      rating: "2.3",
      website: "https://dpie.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.921044, 37.703007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1155 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(863) 873-0987",
      name: "CACC Chinese School",
      rating: "missing",
      website: "http://caccusa.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 1:00 PM",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8740214, 37.6681985] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10540 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-1200",
      name: "Del Amigo High School",
      rating: "5",
      website: "https://dahs.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:00 PM",
          "Tuesday: 8:30 AM – 2:00 PM",
          "Wednesday: 8:30 AM – 2:00 PM",
          "Thursday: 8:30 AM – 2:00 PM",
          "Friday: 8:30 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10540",
    },
    geometry: { type: "Point", coordinates: [-121.9045996, 37.7673836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "725 Whipple Rd, Union City, CA 94587, USA",
      formatted_phone_number: "(510) 476-2696",
      name: "Decoto K-12 School for Independent Study",
      rating: "5",
      website: "http://sites.google.com/a/nhusd.k12.ca.us/dis/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94587",
    },
    geometry: { type: "Point", coordinates: [-122.0273109, 37.6063024] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1633 East Ave, Hayward, CA 94541, USA",
      formatted_phone_number: "(510) 723-3170",
      name: "Hayward High School",
      rating: "3.6",
      website: "http://www.haywardhigh.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94541",
    },
    geometry: { type: "Point", coordinates: [-122.069216, 37.6709511] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4645 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4260",
      name: "Village High School",
      rating: "4.5",
      website: "https://village.pleasantonusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1410" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1410" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1410" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1410" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1410" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:10 PM",
          "Tuesday: 8:30 AM – 2:10 PM",
          "Wednesday: 8:30 AM – 2:10 PM",
          "Thursday: 8:30 AM – 2:10 PM",
          "Friday: 8:30 AM – 2:10 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8749709, 37.6557868] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "FHS Large Gym",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9178881, 37.6730294] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "J12",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9194216, 37.6720623] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Band Room",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9185922, 37.6717029] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "The Talon Times",
      rating: "missing",
      website: "http://talontimes.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.919361, 37.6717472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4301 Hacienda Dr #120, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 623-5433",
      name: "Fusion Academy Pleasanton",
      rating: "4.8",
      website:
        "https://www.fusionacademy.com/campuses/pleasanton/?crnt=pleasanton&utm_source=GMB&utm_medium=organic&utm_campaign=campuses&piCId=80042",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.893005, 37.6925132] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Campus Hill Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 424-1000",
      name: "Las Positas College",
      rating: "4.3",
      website: "http://www.laspositascollege.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8001828, 37.710488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1690 Watermill Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 866-1822",
      name: "Diablo Valley College - San Ramon Campus",
      rating: "4.5",
      website: "https://www.dvc.edu/san-ramon/index.html",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9098222, 37.7549069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Dublin Professional Center, 7600 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 485-5208",
      name: "Chabot-Las Positas Community College District",
      rating: "5",
      website: "https://www.clpccd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9298513, 37.7037761] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12647 Alcosta Blvd #190, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 543-8075",
      name: "UC Davis Bay Area MBA",
      rating: "5",
      website: "http://gsm.ucdavis.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "12647",
    },
    geometry: { type: "Point", coordinates: [-121.9594557, 37.772885] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5506 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 828-8695",
      name: "School of Practical Philosophy",
      rating: "missing",
      website: "http://practicalphilosopher.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8799659, 37.6497527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5875 Arnold Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 560-2600",
      name: "Eksigent Technologies LLC",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8919253, 37.7122658] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5000 Executive Pkwy, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 272-7370",
      name: "Austin University",
      rating: "missing",
      website: "http://www.austin.university/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2130" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2130" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2130" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2130" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1730" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1600" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:30 PM",
          "Tuesday: 9:00 AM – 9:30 PM",
          "Wednesday: 9:00 AM – 9:30 PM",
          "Thursday: 9:00 AM – 9:30 PM",
          "Friday: 9:00 AM – 5:30 PM",
          "Saturday: 10:00 AM – 4:00 PM",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9667764, 37.7690158] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Campus Hill Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 424-1000",
      name: "1600 Student Services and Administration",
      rating: "4",
      website: "http://laspositascollege.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8004016, 37.7106951] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25800 Carlos Bee Blvd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-2880",
      name: "Center for International Education",
      rating: "2.6",
      website: "http://www.csueastbay.edu/cie",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: { type: "Point", coordinates: [-122.0547101, 37.6580731] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25800 Carlos Bee Blvd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-3803",
      name: "Student Services and Administration [SA]",
      rating: "5",
      website: "http://www.csueastbay.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: {
      type: "Point",
      coordinates: [-122.0542774, 37.657555900000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25800 Carlos Bee Blvd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-2358",
      name: "Student and Faculty Building",
      rating: "3.3",
      website: "https://www.csueastbay.edu/visit/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 5, time: "0500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 AM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: { type: "Point", coordinates: [-122.0544818, 37.6581293] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1901 Harder Rd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-3000",
      name: "Cal State East Bay",
      rating: "3.4",
      website: "https://www.csueastbay.edu/",
      opening_hours: {
        open_now: true,
        periods: [{ open: { day: 0, time: "0000" } }],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Open 24 hours",
          "Sunday: Open 24 hours",
        ],
      },
      first_type: "university",
      zip_code: "94542",
    },
    geometry: { type: "Point", coordinates: [-122.0530954, 37.6536231] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Science Building - South, Harder Rd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-3000",
      name: "South Science Building",
      rating: "4.8",
      website: "http://www.csueastbay.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94542",
    },
    geometry: { type: "Point", coordinates: [-122.0543745, 37.65589] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Science Building - North, Old Hillary Rd, Hayward, CA 94542, USA",
      formatted_phone_number: "missing",
      name: "North Science Building",
      rating: "4",
      website: "http://www.csueastbay.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94542",
    },
    geometry: { type: "Point", coordinates: [-122.0545381, 37.6562319] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25800 Carlos Bee Blvd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-3000",
      name: "Cal State East Bay College of Business & Economics",
      rating: "5",
      website: "http://www.csueastbay.edu/cbe/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: { type: "Point", coordinates: [-122.0551251, 37.6571878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "25800 Carlos Bee Blvd, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-3000",
      name: "California State University, East Bay",
      rating: "4.2",
      website: "http://www.csueastbay.edu/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: { type: "Point", coordinates: [-122.0561984, 37.6560295] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "25800 Carlos Bee Blvd SF, #336, Hayward, CA 94542, USA",
      formatted_phone_number: "(510) 885-7368",
      name: "Institute for STEM Education, California State University East Bay",
      rating: "missing",
      website: "https://www.csueastbay.edu/steminstitute/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 4:00 PM",
          "Tuesday: 9:00 AM – 4:00 PM",
          "Wednesday: 9:00 AM – 4:00 PM",
          "Thursday: 9:00 AM – 4:00 PM",
          "Friday: 9:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "25800",
    },
    geometry: { type: "Point", coordinates: [-122.057296, 37.656255] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Music Building, E Loop Rd, Hayward, CA 94542, USA",
      formatted_phone_number: "missing",
      name: "Music Building",
      rating: "4.2",
      website: "https://www.csueastbay.edu/class/departments/music/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94542",
    },
    geometry: { type: "Point", coordinates: [-122.0584863, 37.6584583] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Old Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 551-1280",
      name: "P2P College Prep",
      rating: "missing",
      website: "https://p2pcollegeprep.org/contact-info",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8806314, 37.6575314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "111 Deerwood Rd STE 200, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 855-3220",
      name: "Oxford Advisors, LLC",
      rating: "5",
      website: "http://www.oxford-advisors.com/contact/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9893244, 37.7739099] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6011 Massara St, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 314-2000",
      name: "Creekside Elementary School",
      rating: "5",
      website: "https://cres.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 6, time: "0000" },
            open: { day: 1, time: "0000" },
          },
        ],
        weekday_text: [
          "Monday: Open 24 hours",
          "Tuesday: Open 24 hours",
          "Wednesday: Open 24 hours",
          "Thursday: Open 24 hours",
          "Friday: Open 24 hours",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.8812539, 37.7923207] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8700 N Gale Ridge Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 855-7300",
      name: "Coyote Creek Elementary School",
      rating: "4.8",
      website: "https://ckes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9273831, 37.777944] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5025 Canyon Crest Dr, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 855-2700",
      name: "Golden View Elementary School",
      rating: "4.8",
      website: "https://gles.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.943058, 37.78704] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4675 Camino Tassajara, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 855-7800",
      name: "Tassajara Hills Elementary School",
      rating: "4.7",
      website: "https://thes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.8788869, 37.8011677] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2200 Holbrook Dr, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 855-2800",
      name: "Sycamore Valley Elementary School",
      rating: "4.8",
      website: "https://syes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94506",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.9442406, 37.807166699999989],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1001 Diablo Rd, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 855-5400",
      name: "Green Valley Elementary School",
      rating: "4",
      website: "https://gves.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1515" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1515" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1515" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1515" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1515" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:15 PM",
          "Tuesday: 8:00 AM – 3:15 PM",
          "Wednesday: 8:00 AM – 3:15 PM",
          "Thursday: 8:00 AM – 3:15 PM",
          "Friday: 8:00 AM – 3:15 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9798604, 37.8336163] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1475 Harlan Dr, Danville, CA 94526, USA",
      formatted_phone_number: "(925) 855-5300",
      name: "Greenbrook Elementary School",
      rating: "3.8",
      website: "https://gbes.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94526",
    },
    geometry: { type: "Point", coordinates: [-121.9743567, 37.7912779] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10540 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-1200",
      name: "Venture School",
      rating: "4.5",
      website: "https://veis.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "10540",
    },
    geometry: { type: "Point", coordinates: [-121.9044885, 37.7673283] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "4135 Blackhawk Plaza Cir STE 150, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 791-5371",
      name: "Safari Kid - Danville",
      rating: "5",
      website:
        "https://safarikidusa.com/danville-location/?utm_source=NEXT&utm_medium=Google_Danville_GMB",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 6:00 PM",
          "Tuesday: 9:00 AM – 6:00 PM",
          "Wednesday: 9:00 AM – 6:00 PM",
          "Thursday: 9:00 AM – 6:00 PM",
          "Friday: 9:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9192515, 37.8020089] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2100 Mt Diablo Scenic Blvd, Danville, CA 94506, USA",
      formatted_phone_number: "(925) 837-5375",
      name: "The Athenian School",
      rating: "5",
      website: "https://www.athenian.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0810" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0810" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0810" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0810" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0810" },
          },
        ],
        weekday_text: [
          "Monday: 8:10 AM – 3:30 PM",
          "Tuesday: 8:10 AM – 3:30 PM",
          "Wednesday: 8:10 AM – 3:30 PM",
          "Thursday: 8:10 AM – 3:30 PM",
          "Friday: 8:10 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94506",
    },
    geometry: { type: "Point", coordinates: [-121.9501298, 37.8347843] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10550 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-6400",
      name: "Dougherty Valley High School",
      rating: "4.5",
      website: "https://dvhs.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 4:00 PM",
          "Tuesday: 7:45 AM – 4:00 PM",
          "Wednesday: 7:45 AM – 4:00 PM",
          "Thursday: 7:45 AM – 4:00 PM",
          "Friday: 7:45 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10550",
    },
    geometry: { type: "Point", coordinates: [-121.9023247, 37.7692188] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10540 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-1200",
      name: "Del Amigo High School",
      rating: "5",
      website: "https://dahs.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:00 PM",
          "Tuesday: 8:30 AM – 2:00 PM",
          "Wednesday: 8:30 AM – 2:00 PM",
          "Thursday: 8:30 AM – 2:00 PM",
          "Friday: 8:30 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10540",
    },
    geometry: { type: "Point", coordinates: [-121.9045996, 37.7673836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6363 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8000",
      name: "The Quarry Lane School",
      rating: "2.7",
      website: "https://www.quarrylane.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8701133, 37.7275323] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4000 Goldenbay Ave, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 560-4000",
      name: "Quail Run Elementary School",
      rating: "4.1",
      website: "https://qres.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:00 AM – 3:00 PM",
          "Thursday: 8:00 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9142774, 37.7628743] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5151 Sherwood Way, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 803-3100",
      name: "Live Oak Elementary School",
      rating: "4.4",
      website: "https://loes.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1430" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1430" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1430" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1430" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 2:30 PM",
          "Tuesday: 8:00 AM – 2:30 PM",
          "Wednesday: 8:00 AM – 2:30 PM",
          "Thursday: 8:00 AM – 2:30 PM",
          "Friday: 8:00 AM – 2:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.8945204, 37.7541022] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3300 Dennis Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4256",
      name: "Mohr Elementary School",
      rating: "5",
      website: "http://mohr.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8601609, 37.6916729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4150 Dorman Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4220",
      name: "Donlon Elementary",
      rating: "5",
      website: "https://donlon.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:30 PM",
          "Tuesday: 8:00 AM – 3:30 PM",
          "Wednesday: 8:00 AM – 3:30 PM",
          "Thursday: 8:00 AM – 3:30 PM",
          "Friday: 8:00 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9075869, 37.6844878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4151 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4210",
      name: "Fairlands Elementary School",
      rating: "4.6",
      website: "https://fairlands.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8740946, 37.6924617] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3150 Palermo Way, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 551-4000",
      name: "Kolb Elementary School",
      rating: "4.4",
      website: "http://kes.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 6:00 PM",
          "Tuesday: 8:00 AM – 6:00 PM",
          "Wednesday: 8:00 AM – 6:00 PM",
          "Thursday: 8:00 AM – 6:00 PM",
          "Friday: 8:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8589254, 37.7123365] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3300 Antone Way, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-4200",
      name: "John Green Elementary School",
      rating: "3.4",
      website: "http://ges.dublinusd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8615009, 37.7184877] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7243 Tamarack Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-1037",
      name: "Frederiksen Elementary School",
      rating: "4.4",
      website: "http://fes.dublinusd.org/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9228665, 37.7152067] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3250 Pine Valley Rd, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 479-3900",
      name: "Walt Disney Elementary School",
      rating: "5",
      website: "https://wdes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.93525, 37.741216] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4576 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 315-4940",
      name: "Stratford School",
      rating: "3.6",
      website:
        "https://www.stratfordschools.com/schools/northern-california/east-bay/pleasanton/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992526, 37.6898586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8435 Davona Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-2568",
      name: "Murray Elementary School",
      rating: "4.7",
      website: "http://mes.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9330196, 37.7220371] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "480 Adams Way, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4230",
      name: "Valley View Elementary School",
      rating: "4.2",
      website: "http://valleyview.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8623056, 37.6603096] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1999 Harvest Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4250",
      name: "Walnut Grove Elementary School",
      rating: "4.1",
      website: "http://walnutgrove.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8854479, 37.673212] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7997 Vomac Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-1204",
      name: "Dublin Elementary School",
      rating: "4.8",
      website: "https://des.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9360475, 37.7145799] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7534 Blue Fox Way, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 479-6000",
      name: "Country Club Elementary School",
      rating: "4",
      website: "https://cces.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9404367, 37.7290656] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2849 Calais Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 479-1600",
      name: "Neil Armstrong Elementary School",
      rating: "4.2",
      website: "https://naes.srvusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9487112, 37.7409362] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2100 E Cantara Dr, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 307-1950",
      name: "Jose Maria Amador Elementary School",
      rating: "4.9",
      website: "http://aes.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 3:30 PM",
          "Tuesday: 7:30 AM – 3:30 PM",
          "Wednesday: 7:30 AM – 3:30 PM",
          "Thursday: 7:30 AM – 3:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.84342, 37.7191728] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6379 Clark Ave STE 200, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 587-5610",
      name: "DPIE - Dublin Partners in Education",
      rating: "2.3",
      website: "https://dpie.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.921044, 37.703007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "12995 Harcourt Way, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-3800",
      name: "Hidden Hills Elementary School",
      rating: "4.1",
      website: "https://hhes.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "12995",
    },
    geometry: { type: "Point", coordinates: [-121.8936929, 37.7646641] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8151 Village Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 833-3300",
      name: "Dublin High School",
      rating: "3.2",
      website: "https://dhs.dublinusd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9241051, 37.7203614] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10550 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-6400",
      name: "Dougherty Valley High School",
      rating: "4.5",
      website: "https://dvhs.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 4:00 PM",
          "Tuesday: 7:45 AM – 4:00 PM",
          "Wednesday: 7:45 AM – 4:00 PM",
          "Thursday: 7:45 AM – 4:00 PM",
          "Friday: 7:45 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10550",
    },
    geometry: { type: "Point", coordinates: [-121.9023247, 37.7692188] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "9870 Broadmoor Dr, San Ramon, CA 94583, USA",
      formatted_phone_number: "(925) 803-3200",
      name: "California High School",
      rating: "3.7",
      website: "http://chs.srvusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94583",
    },
    geometry: { type: "Point", coordinates: [-121.9455518, 37.7467376] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6363 Tassajara Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 829-8000",
      name: "The Quarry Lane School",
      rating: "2.7",
      website: "https://www.quarrylane.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8701133, 37.7275323] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6379 Clark Ave STE 200, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 587-5610",
      name: "DPIE - Dublin Partners in Education",
      rating: "2.3",
      website: "https://dpie.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.921044, 37.703007] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1155 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(863) 873-0987",
      name: "CACC Chinese School",
      rating: "missing",
      website: "http://caccusa.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 1:00 PM",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8740214, 37.6681985] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "10540 Albion Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 479-1200",
      name: "Del Amigo High School",
      rating: "5",
      website: "https://dahs.srvusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1400" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1400" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1400" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1400" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1400" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:00 PM",
          "Tuesday: 8:30 AM – 2:00 PM",
          "Wednesday: 8:30 AM – 2:00 PM",
          "Thursday: 8:30 AM – 2:00 PM",
          "Friday: 8:30 AM – 2:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "10540",
    },
    geometry: { type: "Point", coordinates: [-121.9045996, 37.7673836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "B-Building",
      rating: "missing",
      website: "http://foothillfalcons.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9189549, 37.6731568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "Foothill High School",
      rating: "4.1",
      website: "http://www.foothillfalcons.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:30 PM",
          "Tuesday: 7:30 AM – 4:30 PM",
          "Wednesday: 7:00 AM – 2:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9198812, 37.6725157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3600 Central Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 828-2551",
      name: "Emerald High School",
      rating: "4.6",
      website: "http://ehs.dublinusd.org/",
      opening_hours: "missing",
      first_type: "school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8636661, 37.7087093] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4301 Hacienda Dr #120, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 623-5433",
      name: "Fusion Academy Pleasanton",
      rating: "4.8",
      website:
        "https://www.fusionacademy.com/campuses/pleasanton/?crnt=pleasanton&utm_source=GMB&utm_medium=organic&utm_campaign=campuses&piCId=80042",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.893005, 37.6925132] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3110 Constitution Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 264-9477",
      name: "Acton Academy East Bay",
      rating: "5",
      website: "http://www.actoneastbay.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "school",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8190281, 37.7047466] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Dublin High School - Science & Engineering Building",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9233087, 37.7197356] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8151 Village Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Building K",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9242889, 37.7188152] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "8151 Village Pkwy, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Building L",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9254893, 37.7189836] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1155 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 461-6100",
      name: "Amador Valley High School",
      rating: "4.1",
      website: "http://amador.pleasantonusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0845" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0845" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:45 AM – 3:00 PM",
          "Thursday: 8:45 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8750383, 37.6681661] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4645 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4260",
      name: "Village High School",
      rating: "4.5",
      website: "https://village.pleasantonusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1410" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1410" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1410" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1410" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1410" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:10 PM",
          "Tuesday: 8:30 AM – 2:10 PM",
          "Wednesday: 8:30 AM – 2:10 PM",
          "Thursday: 8:30 AM – 2:10 PM",
          "Friday: 8:30 AM – 2:10 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8749709, 37.6557868] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "FHS Large Gym",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9178881, 37.6730294] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4713 First St #120, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 786-3379",
      name: "Perla Spanish Tutoring Center",
      rating: "missing",
      website: "http://www.plspanish.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 8:30 PM",
          "Tuesday: 8:30 AM – 8:30 PM",
          "Wednesday: 8:30 AM – 8:30 PM",
          "Thursday: 8:30 AM – 8:30 PM",
          "Friday: 8:30 AM – 8:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762226, 37.6577539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Band Room",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9185922, 37.6717029] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Campus Hill Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 424-1000",
      name: "Las Positas College",
      rating: "4.3",
      website: "http://www.laspositascollege.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8001828, 37.710488] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1690 Watermill Rd, San Ramon, CA 94582, USA",
      formatted_phone_number: "(925) 866-1822",
      name: "Diablo Valley College - San Ramon Campus",
      rating: "4.5",
      website: "https://www.dvc.edu/san-ramon/index.html",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94582",
    },
    geometry: { type: "Point", coordinates: [-121.9098222, 37.7549069] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Dublin Professional Center, 7600 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 485-5208",
      name: "Chabot-Las Positas Community College District",
      rating: "5",
      website: "https://www.clpccd.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "university",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9298513, 37.7037761] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5875 Arnold Rd, Dublin, CA 94568, USA",
      formatted_phone_number: "(925) 560-2600",
      name: "Eksigent Technologies LLC",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.8919253, 37.7122658] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3000 Campus Hill Dr, Livermore, CA 94551, USA",
      formatted_phone_number: "(925) 424-1000",
      name: "1600 Student Services and Administration",
      rating: "4",
      website: "http://laspositascollege.edu/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8004016, 37.7106951] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5506 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 828-8695",
      name: "School of Practical Philosophy",
      rating: "missing",
      website: "http://practicalphilosopher.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8799659, 37.6497527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Old Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 551-1280",
      name: "P2P College Prep",
      rating: "missing",
      website: "https://p2pcollegeprep.org/contact-info",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8806314, 37.6575314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6833 Corte Munras, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 998-5075",
      name: "Phoenix Biosystems",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.9025985, 37.674384] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4234 Hacienda Dr #110, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0120",
      name: "University of the Pacific",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8957448, 37.6879659] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4695 Chabot Dr Suite 200, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 690-2880",
      name: "Beyond Academy",
      rating: "4.7",
      website: "https://beyondacademyca.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "2100" },
            open: { day: 0, time: "0900" },
          },
          {
            close: { day: 1, time: "2100" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "2100" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "2100" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "2100" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "2100" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "2100" },
            open: { day: 6, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 9:00 PM",
          "Tuesday: 9:00 AM – 9:00 PM",
          "Wednesday: 9:00 AM – 9:00 PM",
          "Thursday: 9:00 AM – 9:00 PM",
          "Friday: 9:00 AM – 9:00 PM",
          "Saturday: 9:00 AM – 9:00 PM",
          "Sunday: 9:00 AM – 9:00 PM",
        ],
      },
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9004042, 37.6951167] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6500 Dublin Blvd, Dublin, CA 94568, USA",
      formatted_phone_number: "missing",
      name: "Drones Academy",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94568",
    },
    geometry: { type: "Point", coordinates: [-121.9100952, 37.703392] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "Center 1020, 4900 Hopyard Rd STE 100, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(602) 687-6210",
      name: "E.I.D.H.I. International University",
      rating: "missing",
      website: "http://www.eidhi.com/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9047494, 37.6935045] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "School of Management",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9248223, 37.6989142] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address:
        "6200 Stoneridge Mall Rd #300, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 399-6339",
      name: "American Tech And Management University (ATMU)",
      rating: "5",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9245198, 37.6959045] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1850 Campus Loop, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "1850 Building",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.8013108, 37.7121059] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2300 Campus Loop, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "2300 Building",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: { type: "Point", coordinates: [-121.7995489, 37.7125318] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2400 Campus Loop, Livermore, CA 94551, USA",
      formatted_phone_number: "missing",
      name: "2400 Building",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94551",
    },
    geometry: {
      type: "Point",
      coordinates: [-121.7985173, 37.711108900000013],
    },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3300 Dennis Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4256",
      name: "Mohr Elementary School",
      rating: "5",
      website: "http://mohr.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8601609, 37.6916729] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "480 Adams Way, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4230",
      name: "Valley View Elementary School",
      rating: "4.2",
      website: "http://valleyview.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8623056, 37.6603096] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4151 W Las Positas Blvd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4210",
      name: "Fairlands Elementary School",
      rating: "4.6",
      website: "https://fairlands.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8740946, 37.6924617] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4150 Dorman Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4220",
      name: "Donlon Elementary",
      rating: "5",
      website: "https://donlon.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:30 PM",
          "Tuesday: 8:00 AM – 3:30 PM",
          "Wednesday: 8:00 AM – 3:30 PM",
          "Thursday: 8:00 AM – 3:30 PM",
          "Friday: 8:00 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9075869, 37.6844878] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1999 Harvest Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4250",
      name: "Walnut Grove Elementary School",
      rating: "4.1",
      website: "http://walnutgrove.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8854479, 37.673212] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4576 Willow Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 315-4940",
      name: "Stratford School",
      rating: "3.6",
      website:
        "https://www.stratfordschools.com/schools/northern-california/east-bay/pleasanton/?utm_source=google&utm_medium=organic&utm_campaign=gmb_listing_stratford",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8992526, 37.6898586] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1125 Concord St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4240",
      name: "Vintage Hills Elementary School",
      rating: "4.6",
      website: "http://vintagehills.pleasantonusd.net/",
      opening_hours: "missing",
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8554996, 37.6554658] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5301 Case Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-3772",
      name: "Hearst Elementary School",
      rating: "4.1",
      website: "http://hearst.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1515" },
            open: { day: 1, time: "0745" },
          },
          {
            close: { day: 2, time: "1515" },
            open: { day: 2, time: "0745" },
          },
          {
            close: { day: 3, time: "1515" },
            open: { day: 3, time: "0745" },
          },
          {
            close: { day: 4, time: "1515" },
            open: { day: 4, time: "0745" },
          },
          {
            close: { day: 5, time: "1515" },
            open: { day: 5, time: "0745" },
          },
        ],
        weekday_text: [
          "Monday: 7:45 AM – 3:15 PM",
          "Tuesday: 7:45 AM – 3:15 PM",
          "Wednesday: 7:45 AM – 3:15 PM",
          "Thursday: 7:45 AM – 3:15 PM",
          "Friday: 7:45 AM – 3:15 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.885656, 37.6517907] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1454 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4200",
      name: "Alisal Elementary School",
      rating: "4.1",
      website: "https://alisal.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0730" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0730" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:00 PM",
          "Tuesday: 7:30 AM – 4:00 PM",
          "Wednesday: 7:30 AM – 4:00 PM",
          "Thursday: 7:30 AM – 4:00 PM",
          "Friday: 7:30 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8708485, 37.6722781] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "2149 Rheem Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(717) 440-7567",
      name: "Guru Kripa Kids",
      rating: "5",
      website: "missing",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.874606, 37.6859669] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "3750 Boulder St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-9400",
      name: "The Quarry Lane School - Pleasanton East Campus",
      rating: "5",
      website: "https://www.quarrylane.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1800" },
            open: { day: 1, time: "0700" },
          },
          {
            close: { day: 2, time: "1800" },
            open: { day: 2, time: "0700" },
          },
          {
            close: { day: 3, time: "1800" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1800" },
            open: { day: 4, time: "0700" },
          },
          {
            close: { day: 5, time: "1800" },
            open: { day: 5, time: "0700" },
          },
        ],
        weekday_text: [
          "Monday: 7:00 AM – 6:00 PM",
          "Tuesday: 7:00 AM – 6:00 PM",
          "Wednesday: 7:00 AM – 6:00 PM",
          "Thursday: 7:00 AM – 6:00 PM",
          "Friday: 7:00 AM – 6:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8651686, 37.6723736] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "118 Neal St, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 846-7220",
      name: "Lighthouse Baptist Church",
      rating: "5",
      website: "http://lbceastbay.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 0, time: "1200" },
            open: { day: 0, time: "0930" },
          },
          {
            close: { day: 0, time: "1930" },
            open: { day: 0, time: "1800" },
          },
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0900" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0900" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0900" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0900" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0900" },
          },
          {
            close: { day: 6, time: "1200" },
            open: { day: 6, time: "1000" },
          },
        ],
        weekday_text: [
          "Monday: 9:00 AM – 5:00 PM",
          "Tuesday: 9:00 AM – 5:00 PM",
          "Wednesday: 9:00 AM – 5:00 PM",
          "Thursday: 9:00 AM – 5:00 PM",
          "Friday: 9:00 AM – 5:00 PM",
          "Saturday: 10:00 AM – 12:00 PM",
          "Sunday: 9:30 AM – 12:00 PM, 6:00 – 7:30 PM",
        ],
      },
      first_type: "primary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.873018, 37.6594946] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "11601 Main St, Sunol, CA 94586, USA",
      formatted_phone_number: "(925) 862-2026",
      name: "Sunol Glen School",
      rating: "4.4",
      website: "http://www.sunol.k12.ca.us/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1600" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1600" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1600" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1600" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1600" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 4:00 PM",
          "Tuesday: 8:00 AM – 4:00 PM",
          "Wednesday: 8:00 AM – 4:00 PM",
          "Thursday: 8:00 AM – 4:00 PM",
          "Friday: 8:00 AM – 4:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "11601",
    },
    geometry: { type: "Point", coordinates: [-121.88506, 37.5937079] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4713 First St #120, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 786-3379",
      name: "Perla Spanish Tutoring Center",
      rating: "missing",
      website: "http://www.plspanish.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 8:30 PM",
          "Tuesday: 8:30 AM – 8:30 PM",
          "Wednesday: 8:30 AM – 8:30 PM",
          "Thursday: 8:30 AM – 8:30 PM",
          "Friday: 8:30 AM – 8:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762226, 37.6577539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "7700 Highland Oaks Dr, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 426-4420",
      name: "Lydiksen Elementary School",
      rating: "4",
      website: "https://lydiksen.pleasantonusd.net/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1530" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1530" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1530" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1530" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:30 PM",
          "Tuesday: 8:00 AM – 3:30 PM",
          "Wednesday: 8:00 AM – 3:30 PM",
          "Thursday: 8:00 AM – 3:30 PM",
          "Friday: 8:00 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "primary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9210516, 37.6805671] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1155 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(863) 873-0987",
      name: "CACC Chinese School",
      rating: "missing",
      website: "http://caccusa.org/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 0, time: "1300" },
            open: { day: 0, time: "0900" },
          },
        ],
        weekday_text: [
          "Monday: Closed",
          "Tuesday: Closed",
          "Wednesday: Closed",
          "Thursday: Closed",
          "Friday: Closed",
          "Saturday: Closed",
          "Sunday: 9:00 AM – 1:00 PM",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8740214, 37.6681985] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "B-Building",
      rating: "missing",
      website: "http://foothillfalcons.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9189549, 37.6731568] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 461-6600",
      name: "Foothill High School",
      rating: "4.1",
      website: "http://www.foothillfalcons.org/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1630" },
            open: { day: 1, time: "0730" },
          },
          {
            close: { day: 2, time: "1630" },
            open: { day: 2, time: "0730" },
          },
          {
            close: { day: 3, time: "1430" },
            open: { day: 3, time: "0700" },
          },
          {
            close: { day: 4, time: "1630" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1530" },
            open: { day: 5, time: "0730" },
          },
        ],
        weekday_text: [
          "Monday: 7:30 AM – 4:30 PM",
          "Tuesday: 7:30 AM – 4:30 PM",
          "Wednesday: 7:00 AM – 2:30 PM",
          "Thursday: 8:30 AM – 4:30 PM",
          "Friday: 7:30 AM – 3:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9198812, 37.6725157] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4645 Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 426-4260",
      name: "Village High School",
      rating: "4.5",
      website: "https://village.pleasantonusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1410" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "1410" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "1410" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "1410" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "1410" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 2:10 PM",
          "Tuesday: 8:30 AM – 2:10 PM",
          "Wednesday: 8:30 AM – 2:10 PM",
          "Thursday: 8:30 AM – 2:10 PM",
          "Friday: 8:30 AM – 2:10 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8749709, 37.6557868] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4713 First St #120, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 786-3379",
      name: "Perla Spanish Tutoring Center",
      rating: "missing",
      website: "http://www.plspanish.com/",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "2030" },
            open: { day: 1, time: "0830" },
          },
          {
            close: { day: 2, time: "2030" },
            open: { day: 2, time: "0830" },
          },
          {
            close: { day: 3, time: "2030" },
            open: { day: 3, time: "0830" },
          },
          {
            close: { day: 4, time: "2030" },
            open: { day: 4, time: "0830" },
          },
          {
            close: { day: 5, time: "2030" },
            open: { day: 5, time: "0830" },
          },
        ],
        weekday_text: [
          "Monday: 8:30 AM – 8:30 PM",
          "Tuesday: 8:30 AM – 8:30 PM",
          "Wednesday: 8:30 AM – 8:30 PM",
          "Thursday: 8:30 AM – 8:30 PM",
          "Friday: 8:30 AM – 8:30 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8762226, 37.6577539] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "1155 Santa Rita Rd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 461-6100",
      name: "Amador Valley High School",
      rating: "4.1",
      website: "http://amador.pleasantonusd.net/",
      opening_hours: {
        open_now: false,
        periods: [
          {
            close: { day: 1, time: "1500" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1500" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1500" },
            open: { day: 3, time: "0845" },
          },
          {
            close: { day: 4, time: "1500" },
            open: { day: 4, time: "0845" },
          },
          {
            close: { day: 5, time: "1500" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 3:00 PM",
          "Tuesday: 8:00 AM – 3:00 PM",
          "Wednesday: 8:45 AM – 3:00 PM",
          "Thursday: 8:45 AM – 3:00 PM",
          "Friday: 8:00 AM – 3:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8750383, 37.6681661] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "Band Room",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9185922, 37.6717029] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "FHS Large Gym",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9178881, 37.6730294] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "The Talon Times",
      rating: "missing",
      website: "http://talontimes.org/",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.919361, 37.6717472] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4375 Foothill Rd, Pleasanton, CA 94588, USA",
      formatted_phone_number: "missing",
      name: "J12",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.9194216, 37.6720623] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4301 Hacienda Dr #120, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 623-5433",
      name: "Fusion Academy Pleasanton",
      rating: "4.8",
      website:
        "https://www.fusionacademy.com/campuses/pleasanton/?crnt=pleasanton&utm_source=GMB&utm_medium=organic&utm_campaign=campuses&piCId=80042",
      opening_hours: {
        open_now: true,
        periods: [
          {
            close: { day: 1, time: "1700" },
            open: { day: 1, time: "0800" },
          },
          {
            close: { day: 2, time: "1700" },
            open: { day: 2, time: "0800" },
          },
          {
            close: { day: 3, time: "1700" },
            open: { day: 3, time: "0800" },
          },
          {
            close: { day: 4, time: "1700" },
            open: { day: 4, time: "0800" },
          },
          {
            close: { day: 5, time: "1700" },
            open: { day: 5, time: "0800" },
          },
        ],
        weekday_text: [
          "Monday: 8:00 AM – 5:00 PM",
          "Tuesday: 8:00 AM – 5:00 PM",
          "Wednesday: 8:00 AM – 5:00 PM",
          "Thursday: 8:00 AM – 5:00 PM",
          "Friday: 8:00 AM – 5:00 PM",
          "Saturday: Closed",
          "Sunday: Closed",
        ],
      },
      first_type: "secondary_school",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.893005, 37.6925132] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "5506 Sunol Blvd, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 828-8695",
      name: "School of Practical Philosophy",
      rating: "missing",
      website: "http://practicalphilosopher.org/",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8799659, 37.6497527] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "400 Old Bernal Ave, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 551-1280",
      name: "P2P College Prep",
      rating: "missing",
      website: "https://p2pcollegeprep.org/contact-info",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.8806314, 37.6575314] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "6833 Corte Munras, Pleasanton, CA 94566, USA",
      formatted_phone_number: "(925) 998-5075",
      name: "Phoenix Biosystems",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94566",
    },
    geometry: { type: "Point", coordinates: [-121.9025985, 37.674384] },
  },
  {
    type: "Feature",
    properties: {
      formatted_address: "4234 Hacienda Dr #110, Pleasanton, CA 94588, USA",
      formatted_phone_number: "(925) 463-0120",
      name: "University of the Pacific",
      rating: "missing",
      website: "missing",
      opening_hours: "missing",
      first_type: "university",
      zip_code: "94588",
    },
    geometry: { type: "Point", coordinates: [-121.8957448, 37.6879659] },
  },
];