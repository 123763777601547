import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import backgroundImage from '../../assets/Investors/loginbackgrnd.png'; 

const LoginInv = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    if (credentials.email === 'test@gmail.com' && credentials.password === 'ps*Home/*98-#2$') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate('/HomeDashboard');
      }, 2000); // 2-second delay
    } else {
      setError('Invalid email or password');
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          width: 400,
          padding: 4,
          borderRadius: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          boxShadow: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ color: '#000', fontWeight: 'bold', mb: 2 }}>
          USER LOGIN
        </Typography>
        <TextField
          label="User email"
          name="email"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          sx={{
            mb: 3,
            '& .MuiInputBase-root': { color: '#000' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#888' },
          }}
        />
        <TextField
          label="Password"
          name="password"
          variant="outlined"
          type="password"
          fullWidth
          onChange={handleChange}
          sx={{
            mb: 3,
            '& .MuiInputBase-root': { color: '#000' },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#888' },
          }}
        />
        {error && (
          <Typography variant="body2" sx={{ color: 'red', mb: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          fullWidth
          onClick={handleLogin}
          sx={{
            mb: 2,
            padding: 1.5,
            fontSize: '1rem',
            background: '#ED5272',
            '&:hover': {
              background: 'linear-gradient(90deg, #ff6a6a, #ff0080)',
            },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'LOGIN'}
        </Button>
      </Box>
    </Box>
  );
};

export default LoginInv;
