import React from "react";

const HeroSection = () => {
  return (
    <div className="w-full h-[800px] sm:h-[940px] bg-analyze-hero-mobile sm:bg-analyze-hero bg-no-repeat bg-cover bg-center text-white">
      <div className="h-full sm:bg-hero-section-overlay bg-cover flex justify-center">
      <div className="z-10 sm:px-6 lg:px-8 my-[90px] sm:mt-[120px] w-full">
        <h1 className="text-[30px] leading-[40px] w-[314px] sm:w-auto mx-auto sm:mx-0 mt-[150px] text-center sm:text-5xl md:text-[64px] sm:leading-[82.4px] font-light capitalize mb-[187px]">
        Consult with top home analysts&nbsp;<br /><span className="text-[#ED5272] font-bold">from $25/HR</span>
          </h1>
          

          <div className="flex flex-col gap-4 sm:gap-6 sm:flex-row justify-center items-center">
          <div className="w-[328px] sm:h-[60px]">
            <a href="#schedule-call">
            <button className="border border-white rounded-[50px] h-full w-full sm:w-[310px]
             text-white py-3 sm:py-0 px-[10px] text-center sm:text-[22px] sm:leading-[32px] hover:bg-[#6A6DFF]
             hover:border-none">Schedule a call</button>
            </a>
            </div>
            <div className="w-[328px] sm:h-[60px]">
            <a href="/ai">
            <button className="border border-white rounded-[50px] h-full w-full sm:w-[310px]
             text-white py-3 sm:py-0 px-[10px] text-center sm:text-[22px] sm:leading-[32px] hover:bg-[#6A6DFF]
             hover:border-none">Home.AI</button>
            </a>
            </div>
            </div>
    </div>
      </div>
    </div>
  );
};



export default HeroSection;

