import { createContext, useEffect, useState } from "react";
import { HomeAiData, IndustryData, OcupationData } from "../data/HomeAi";
import { transistData } from "../data/Transist";
import { schoolData } from "../data/School";
import { aminitiesData } from "../data/Aminities";
import { boundry } from "../data/boundryData";

const CounterContext = createContext();

const CounterProvider = ({ children }) => {
  const [pinCode, setPinCode] = useState("94043");
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const transist = transistData.filter(
      (item) => item.properties.zip_code === pinCode
    );
    const schools = schoolData.filter(
      (item) => item.properties.zip_code === pinCode
    );
    const aminities = aminitiesData.filter(
      (item) => item.properties.zip_code === pinCode
    );
    const boundrydata = boundry.find(
      (item) => item.properties.Name === pinCode
    );
    const industry = IndustryData.find((item) => item.zip_code === pinCode);
    const occu = OcupationData.find((item) => item.zip_code === pinCode);
    setPageData({
      ...HomeAiData[pinCode],
      transist: transist,
      schools: schools,
      aminities: aminities,
      boundrydata: { type: boundrydata.type, geometry: boundrydata.geometry },
      industry,
      occu,
    });
  }, [pinCode]);
  return (
    <CounterContext.Provider value={{ pinCode, setPinCode, ...pageData }}>
      {children}
    </CounterContext.Provider>
  );
};

export { CounterContext, CounterProvider };
