import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import CTAbutton from "../CTAbutton";
import { useSelector } from "react-redux";

const Disqualified = ({ onClose, isVisible }) => {
  const ownerData = useSelector((state) => state.cashout.owner);
  const { owner } = ownerData;

  const [formData, setFormData] = useState({
    firstName: owner?.FirstName ? owner?.FirstName : "",
    lastName: owner?.LastName ? owner?.LastName : "",
    email: owner?.Email
      ? owner?.Email[0]["linktext"]
        ? owner?.Email[0]["linktext"]
        : ""
      : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      key={"Disqualified popup"}
    >
      <div
        className="max-w-[750px] w-full bg-custom-gray10 
      h-[700px] flex flex-col py-14 relative rounded-[30px] px-20"
      >
        <div className="text-white w-full">
          {/* Close Icon */}
          <Close
            fontSize="medium"
            className="absolute top-16 right-16 cursor-pointer text-custom-primaryPink"
            onClick={onClose}
          />

          <h3 className="text-6xl font-bold text-custom-primaryPink text-center mb-5">
            Sorry!
          </h3>

          <p className="text-lg font-normal text-center mb-2">
            Your property doesn’t qualify for a Home.llc HEI at this time.
          </p>
          <p className="text-lg font-normal text-center mb-6">
            Home.llc typically invests in single-family detached homes,
            single-family attached homes (townhomes), 2-4 unit properties or
            condominiums.
          </p>
          <p className="text-lg font-normal text-center mt-2">
            Would you like us to let you know when other
          </p>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col">
            <label className="text-xl text-[#ACB5BD] mb-2 font-normal">
              First Name*
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full bg-transparent text-white border-b border-b-[#F8F9FA]
       outline-none items-center py-2 text-2xl placeholder:text-[#ACB5BD] h-[30px]"
            />
          </div>
          <div className="flex flex-col gap-4">
            <label className="text-xl text-[#ACB5BD] mb-2 font-normal">
              Last Name*
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full bg-transparent text-white border-b border-b-[#F8F9FA]
       outline-none items-center py-2 text-2xl placeholder:text-[#ACB5BD] h-[30px]"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-xl text-[#ACB5BD] mb-2 font-normal">
              Email Address*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full bg-transparent text-white border-b border-b-[#F8F9FA]
       outline-none items-center py-2 text-2xl placeholder:text-[#ACB5BD] h-[30px]"
            />
          </div>
        </div>

        <CTAbutton extraStyles={"w-[200px] mx-auto mt-5"}>Submit</CTAbutton>
      </div>
    </div>
  );
};

export default Disqualified;
