export const populationData = [
 { year: 2010, population: 20000 },
 { year: 2011, population: 21000 },
 { year: 2012, population: 20500 },
 { year: 2013, population: 21500 },
 { year: 2014, population: 21000 },
 { year: 2015, population: 22000 },
 { year: 2016, population: 21500 },
 { year: 2017, population: 22500 },
 { year: 2018, population: 22000 },
 { year: 2019, population: 23000 },
 { year: 2020, population: 22500 },
 { year: 2021, population: 23500 },
 { year: 2022, population: 23000 },
 { year: 2023, population: 24000 },
 { year: 2024, population: 23500 }
];

export const usersData = [
 { username: 'Alice', userAge: 25, married: false, gender: 'female' },
 { username: 'Bob', userAge: 34, married: true, gender: 'male' },
 { username: 'Charlie', userAge: 22, married: false, gender: 'male' },
 { username: 'Dana', userAge: 29, married: true, gender: 'female' },
 { username: 'Eve', userAge: 42, married: false, gender: 'female' },
 { username: 'nisha', userAge: 42, married: false, gender: 'female' },
 { username: 'Frank', userAge: 31, married: true, gender: 'male' },
 { username: 'Grace', userAge: 28, married: false, gender: 'female' },
 { username: 'Hank', userAge: 38, married: true, gender: 'male' },
 { username: 'Ivy', userAge: 21, married: false, gender: 'female' },
 { username: 'Jack', userAge: 45, married: true, gender: 'male' },
 { username: 'Kara', userAge: 37, married: false, gender: 'female' },
 { username: 'Leo', userAge: 50, married: true, gender: 'male' },
 { username: 'Mona', userAge: 26, married: false, gender: 'female' },
 { username: 'Nate', userAge: 33, married: true, gender: 'male' },
 { username: 'Olivia', userAge: 27, married: false, gender: 'female' },
 { username: 'Pete', userAge: 39, married: true, gender: 'male' },
 { username: 'Quinn', userAge: 23, married: false, gender: 'female' },
 { username: 'Rick', userAge: 41, married: true, gender: 'male' },
 { username: 'Sara', userAge: 24, married: false, gender: 'female' },
 { username: 'Tom', userAge: 48, married: true, gender: 'male' },
 { username: 'Uma', userAge: 30, married: false, gender: 'female' },
 { username: 'Vic', userAge: 35, married: true, gender: 'male' },
 { username: 'Wendy', userAge: 40, married: false, gender: 'female' },
 { username: 'Xander', userAge: 32, married: true, gender: 'male' },
 { username: 'Yara', userAge: 28, married: false, gender: 'female' },
 { username: 'Zane', userAge: 36, married: true, gender: 'male' },
 { username: 'Ava', userAge: 43, married: false, gender: 'female' },
 { username: 'Blake', userAge: 46, married: true, gender: 'male' },
 { username: 'Cleo', userAge: 20, married: false, gender: 'female' },
 { username: 'Dylan', userAge: 49, married: true, gender: 'male' },
 { username: 'Jordan', userAge: 12, married: false, gender: 'other' },
 { username: 'Ris', userAge: 49, married: true, gender: 'male' },
 { username: 'Taylor', userAge: 15, married: false, gender: 'other' },
 { username: 'Rey', userAge: 16, married: false, gender: 'male' },
 { username: 'Toeny', userAge: 16, married: false, gender: 'male' },
 { username: 'Morgan', userAge: 17, married: false, gender: 'other' },
 { username: 'Alex', userAge: 13, married: false, gender: 'other' },
 { username: 'Riley', userAge: 16, married: false, gender: 'other' }
];

export const demoArray = [1, 2, 3, 4, 5, 6,7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

export const peopleTypes = [
 { type: 'White', count: 500 },
 { type: 'Black', count: 400 },
 { type: 'Native', count: 400 },
 { type: 'Asian', count: 400 },
 { type: 'Islander', count: 400 },
 { type: 'Other', count: 400 },
 { type: 'Two', count: 400 },
 { type: 'Hispanic', count: 108 }
];

export const unEmploymentData = [
 { name: 2010, employment: 2452 },
 { name: 2011, employment: 1525 },
 { name: 2012, employment: 1369 },
 { name: 2013, employment: 2604 },
 { name: 2014, employment: 4241 },
 { name: 2015, employment: 1187 },
 { name: 2016, employment: 3871 },
 { name: 2017, employment: 3306 },
 { name: 2018, employment: 2904 },
 { name: 2019, employment: 4440 },
 { name: 2020, employment: 1541 },
 { name: 2021, employment: 2636 },
 { name: 2022, employment: 4273 },
 { name: 2023, employment: 1558 },
 { name: 2024, employment: 2815 }
];

export const topIndustries = [
     { y:  '$ 500000', label: "Industry 1" },
					{ y:  '$ 400000', label: "Industry 2" },
					{ y:  '$ 300000', label: "Industry 3" },
					{ y:  '$ 200000', label: "Industry 4" },
					{ y:  '$ 100000', label: "Industry 5" },
]