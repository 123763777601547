import React from 'react'

const TeamHero = () => {
  return (
   <div className='w-full h-screen bg-team-hero bg-no-repeat bg-cover bg-center'>
    <div className="h-full w-full bg-hero-section-overlay bg-cover">
      <div className='flex flex-col justify-end items-center h-full py-[30px]'>
       <h2 className='text-[#FFFFFF] text-2xl sm:text-[64px] sm:leading-[89.6px] font-bold'>OUR TEAM</h2>
      </div>
       </div>
    </div>
  )
}

export default TeamHero