import React, { useContext } from "react";
import MapBoxComponent from "../../MapBoxComponent";
import TransitCard from "../Cards/TransitCard";
import { CounterContext } from "../../../../hooks/HomeAi";

const Transit=() => {
  const { transist }=useContext(CounterContext);
  return (
    <div className="flex flex-col md:flex-row w-full gap-5">
      <div className="w-full md:w-3/5 rounded-xl overflow-hidden">
        <MapBoxComponent
          styleUrl={"mapbox://styles/jayambalani/cly946tpj00ig01nw1uop9383"}
          height={"450px"}
        />
      </div>
      <div className="w-full md:w-2/5 flex flex-col max-h-[450px] scrollnon overflow-y-auto">
        {transist?.map((item) => (
          <TransitCard key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Transit;
