import React from 'react'

const Heading=({ children }) => {
    return (
        <h2 className='text-6xl mb-10 font-normal'>
            {children}
        </h2>
    )
}

export default Heading