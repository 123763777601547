import React, { useContext, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { CounterContext } from "../../hooks/HomeAi";

// Set your Mapbox access token (replace "YOUR_ACCESS_TOKEN" with your actual Mapbox access token)
mapboxgl.accessToken=
  "pk.eyJ1IjoiamF5YW1iYWxhbmkiLCJhIjoiY2x0M29sdWwwMDQxZjJxcDkwc2lpYWlueSJ9.9ZrrunddV5NRdIV8VeRNdw";

const MapBoxComponent=({ height, styleUrl }) => {
  const { cordinats }=useContext(CounterContext);

  const mapContainer=useRef(null);
  const map=useRef(null);
  // Initialize map only once
  useEffect(() => {
    if (map.current) return;
    map.current=new mapboxgl.Map({
      container: mapContainer.current,
      style: styleUrl,
      // "mapbox://styles/jayambalani/cly975vft00j701qv64m46d8p",
      //  "mapbox://styles/jayambalani/cly4kckyi00aa01pfby7o6mad",
      // This should be the style URL that includes your zip code data layers
      center: cordinats??[-122.0919524, 37.4168363], // Example center coordinates (longitude, latitude)
      zoom: 12,
    });

    map.current.on("load", function () {
      // Assuming 'zip-code-layer' is the ID of your layer with zip code data
      map.current.on("mouseenter", "zip-code-layer", function (e) {
        // Change the cursor style as a UI indicator.
        map.current.getCanvas().style.cursor="pointer";

        // Display the tooltip or label with zip code information
        var coordinates=e.features[0].geometry.coordinates.slice();
        var description=e.features[0].properties.description; // Ensure 'description' is defined in your data

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng-coordinates[0])>180) {
          coordinates[0]+=e.lngLat.lng>coordinates[0]? 360:-360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map.current);
      });

      map.current.on("mouseleave", "zip-code-layer", function () {
        map.current.getCanvas().style.cursor="";
        map.current.getPopup().remove();
      });
    });
  }, [styleUrl]);

  // Update map center when coordinates change
  useEffect(() => {
    if (map.current&&cordinats) {
      map.current.setCenter(cordinats);
    }
  }, [cordinats]);

  return (
    <div
      ref={mapContainer}
      style={{ width: "100%", height: height??"400px" }}
    />
  );
};

export default MapBoxComponent;
