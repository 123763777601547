import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cashoutValue: 0,
  futureHomeValue: 0,
  homeLlcShare: 0,
  homeValue: 1000000,
  loanValue: 200000,
  maximumCashout: 0,
  minimumCashout: 0,
  userShare: 0,
};

const cashoutCalculateSlice = createSlice({
  name: "cashoutCalculate",
  initialState,
  reducers: {
    updateCashoutState: (state, action) => {
      // Update the state based on the payload
      Object.keys(action.payload).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      });
    },
  },
});

export const { updateCashoutState } = cashoutCalculateSlice.actions;

export default cashoutCalculateSlice.reducer;
