import React, { useState, useEffect } from "react";
import backgroundImage from "../../assets/Investors/dashboard_inv.png";
import logo from "../../assets/Investors/logo.png";
import profInv from "../../assets/Investors/profinv.jpeg";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const HomeReportDashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ years: 0, months: 0, days: 0 });

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const calculateTimeLeft = () => {
    const startDate = new Date("2024-09-24");
    const targetDate = new Date(startDate);
    targetDate.setFullYear(targetDate.getFullYear() + 4);

    const currentDate = new Date();

    let years = targetDate.getFullYear() - currentDate.getFullYear();
    let months = targetDate.getMonth() - currentDate.getMonth();
    let days = targetDate.getDate() - currentDate.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    setTimeLeft({ years, months, days });
  };

  useEffect(() => {
    calculateTimeLeft();

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 86400000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen bg-gray-800 text-white font-poppins relative overflow-hidden">
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(8px)",
        }}
      >
        <div
          className="absolute inset-0 z-0"
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 0.8) 100%)",
            zIndex: -1,
          }}
        ></div>
      </div>

      {/* Navbar */}
      <nav className="bg-gray-900 p-4 sticky top-0 z-10">
        <div className="container mx-auto flex justify-between items-center px-4 max-w-screen-lg">
          <div className="flex items-center">
            <img
              src={logo}
              className="w-36 h-12 md:w-44 md:h-16 lg:w-48 lg:h-20"
              alt="Logo"
              style={{ width: "180px", height: "60px" }}
            />
          </div>
          <div className="relative">
            <img
              onClick={toggleDropdown}
              src={profInv}
              className="w-10 h-10 rounded-full cursor-pointer"
              alt="Profile"
            />
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 text-gray-800 z-[100]">
                <a href="#" className="block px-4 py-2 hover:bg-gray-100">
                  Refer
                </a>
                <a href="https://calendly.com/renu-homellc/30min?month=2024-09"
                  target="_blank"
                  rel="noreferrer"
                  className="block px-4 py-2 hover:bg-gray-100">
                  Contact Us
                </a>
                <a href="/" className="block px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Logout
                </a>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="relative flex flex-col justify-center min-h-screen p-8 md:p- max-w-screen-xxl mx-auto">

        <h2 className="mb-2 text-left font-medium text-xl md:text-3xl 2xl:text-4xl w-[50%]">
          Hi, Richard & Angela
        </h2>
        <p className="mb-8 text-left font-normal text-lg md:text-2xl 2xl:text-3xl w-[50%]">
          Here's Your Home Report for September '24
        </p>

        <p className="mb-4 text-center text-2xl md:text-3xl 2xl:text-5xl">
          3989 Crownhaven Ct, Newbury Park, CA 91320
        </p>
        <div className="flex items-center mb-0 justify-center">
          <span className="font-bold text-4xl md:text-6xl lg:text-7xl">
            $1,005,182
          </span>
          <span className="ml-2 text-green-400 text-lg md:text-xl lg:text-2xl">
            ▲12%
          </span>
        </div>
        <p className="mb-12 text-center text-sm md:text-base lg:text-lg">
          Estimated values as of September 1, 2024. Actual appraised values may
          vary. Do not rely exclusively on these calculations.
        </p>


<div className="flex items-center justify-evenly gap-4 my-8">
    {[
        { label: "Initial Home Value", value: "$900,000" },
        { label: "Initial Investment Amount", value: "$135,000" },
        {
            label: "Home.LLC's Share",
            value: "$144,634",
            tooltip: "Current settlement amount"
        },
        {
            label: "Your Share",
            value: "$860,548",
            tooltip: "Current Home value minus Home.LLC share"
        },
    ].map((item, index) => (
        <div key={index} className="text-center">
            <p className="font-semibold text-3xl md:text-4xl lg:text-5xl">
                {item.value}
                {item.tooltip && (
                    <Tooltip title={item.tooltip} placement="top" arrow>
                        <InfoIcon style={{ marginLeft: 4, fontSize: ".5em", cursor: 'pointer',backgroundColor: "none",
                            borderRadius: '50%',
                            padding: '2px',
                            boxSizing: 'content-box' }}/>
                    </Tooltip>
                )}
            </p>
            <p className="text-sm md:text-base lg:text-lg">{item.label}</p>
        </div>
    ))}
</div>


        <p className="mb-4 text-center text-lg md:text-xl lg:text-2xl">
          Date of Settlement is April 15, 2029.
        </p>

        <div className="flex justify-center mb-0">
          {[
            { value: timeLeft.years, label: "Years" },
            { value: timeLeft.months, label: "Months" },
            { value: timeLeft.days, label: "Days" },
          ].map((item, index) => (
            <div key={index} className="text-center mx-2">
              <div className="border-2 border-white p-2 md:p-4 lg:p-6 rounded text-xl md:text-2xl lg:text-3xl font-bold">
                {item.value}
              </div>
              <p className="text-sm md:text-base lg:text-lg">{item.label}</p>
            </div>
          ))}
        </div>
{/* 
        <p className="text-center mb-0 mt-[-4] text-sm md:text-lg lg:text-lg">
          left to settle
        </p> */}

        <p className="text-center mb-4 text-base md:text-lg lg:text-xl">
          Ready to settle?
        </p>

        <a href="mailto:support@home.llc" target="_blank" rel="noreferrer">
        <button className="bg-[#ED5272] text-white py-2 px-4 rounded-lg block mx-auto text-sm md:text-base lg:text-lg">
          Settle with us now
          </button>
        </a>

        <div>
          <h2 className="mb-2 mt-8 text-2xl md:text-4xl lg:text-5xl">About</h2>
          <p className="mb-4 text-sm md:text-base lg:text-lg">
            Constructed in 1966, this single-family home in Newbury Park,
            Ventura County. With 1,628 square feet, your property's value has
            risen by 47% since 2019. Perfect for families, offering excellent
            weather and top-rated public schools, further enhancing its value.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeReportDashboard;
