import React from "react";
import "./Legal.css";

const TermsOfUse = () => {
  return (
    <div className="w-richtext text-white w-full" id="terms-of-use">
      <p>
        <em>These Terms of Use were last updated March 13, 2023.</em>
      </p>
      <p>
        ‍Welcome to Hoom Inc.! Please carefully review the following rules that
        govern your use of our website, home.llc.
      </p>
      <p>
        This Terms and Conditions of Use Agreement (the “
        <strong>Terms of Use</strong>”) is a legal agreement between you and
        Hoom Inc. (hereafter, “<strong>Hoom</strong>”), a Delaware corporation
        (together with its related, affiliated, or subsidiary companies, “
        <strong>Hoom R&amp;D Private Limited</strong>”, “<strong>we</strong>”,
        or “<strong>us</strong>”). These Terms of Use apply to home.llc (and any
        subdomains thereof) owned, operated, licensed, and controlled by Hoom
        (the “<strong>Site</strong>”). This Site is the property of Hoom. By
        using this Site, you signify that you have read and understand, and
        agree to be bound by, these Terms of Use, regardless of whether you are
        a registered user of our Site.
      </p>
      <p>
        In addition to these Terms of Use, you may be subject to additional
        policies, such as our{" "}
        <a href="https://point.com/privacy_policy">Privacy Policy</a>,{" "}
        <a href="https://point.com/credit_report_policy">
          Credit Report Policy
        </a>
        , and{" "}
        <a href="https://point.com/eces_consents">
          Consent to Electronic Communications and Electronic Signatures
        </a>{" "}
        (collectively, the “<strong>Hoom Policies</strong>”), that may be posted
        on the Site from time to time. All terms from such Hoom Policies are
        hereby incorporated by reference. You also have certain rights under the
        Fair Credit Reporting Act.
      </p>
      <p>
        IF YOU DO NOT AGREE TO THESE TERMS OF USE AND THE OTHER HOOM POLICIES,
        DO NOT USE THE SITE.
      </p>
      <p>
        If you have any questions, complaints or suggestions regarding our Terms
        of Use or practices, please contact us at support@home.llc.
      </p>
      <h2 className="my-4">
        <strong className="bold-text-3">CHANGES TO TERMS OF USE OR SITE</strong>
      </h2>
      <p>
        Hoom reserves the right in its sole discretion, to change, modify, add,
        or remove portions of these Terms of Use and the other Hoom Policies at
        any time, without notice. It is your responsibility to check these Terms
        of Use and the other Hoom Policies periodically for changes. Your
        continued use of the Site following the posting of changes will mean
        that you accept and agree to the changes.
      </p>
      <p>
        Hoom may, at any time and without notice, modify or discontinue the
        Site. You acknowledge and agree that Hoom will have no obligation of any
        sort to you in connection with any such modification or discontinuance.
      </p>
      <h2 className="my-8">
        <strong className="bold-text-4">OUR AGREEMENT</strong>
      </h2>
      <p>
        The information contained on the Site is of a general nature and is not
        tailored to your individual circumstances. Information on the Site is
        not intended as an offer or commitment to enter into an agreement with
        you. Hoom reserves the right to refuse to provide our products and
        services to anyone at anytime. In addition to these Terms of Use, you
        may enter into other agreements with us that will govern your use of
        Hoom products or related services offered by us. If there is any
        conflict between these Terms of Use and such other agreements, the other
        agreement will control with respect to the specific aspects of the Hoom
        product to which it applies.
      </p>
      <h2>
        <strong className="bold-text-11">AUTHORIZED USERS</strong>
      </h2>
      <p>
        This Site is intended solely for users who are 18 years of age or older,
        and any registration by, use of, or access to the Site by anyone under
        18 is unauthorized, unlicensed, and in violation of these Terms of Use.
        Hoom does not knowingly collect information from persons under the age
        of 18.
      </p>
      <p>
        By using the Site, you represent and warrant (i) that you are 18 or
        older, (ii) that you are legally qualified to enter into contracts under
        applicable law, and (iii) that you agree to, and to abide by, all of the
        terms and conditions of these Terms of Use. If you violate any of these
        Terms of Use, or otherwise violate an agreement between you and us, or
        for any other reason, Hoom may at any time in its sole discretion, with
        or without notice, (i) delete your account and any content or
        information that you have posted on the Site and/or (ii) prohibit you
        from using or accessing the Site. You agree that Hoom will not be liable
        to you or any third party for any termination of your account.
      </p>
      <h2>
        <strong className="bold-text-12">APPLYING FOR HOOM PRODUCTS</strong>
      </h2>
      <p>
        Your acceptance of the{" "}
        <a href="https://point.com/eces_consents">
          Consent to Electronic Communications and Electronic Signatures
        </a>{" "}
        is required to apply for and use any Hoom products and related services.
        You may be required to register with Hoom in order to access and use
        certain features of the Site, such as applying for a Hoom product. To
        complete a Hoom application, you will be required to submit information
        about yourself (“<strong>Application Data</strong>”), either directly or
        indirectly by authorizing Hoom to retrieve your information maintained
        online by third party financial institutions and social media platforms
        with which you have customer relationships, maintain accounts, engage in
        financial transactions, or use their online services (the “
        <strong>Provider Services</strong>”). This Application Data may include
        personal information such as addresses and your Social Security number,
        as well as information from the Provider Services such as account
        balances, transaction history, length of membership with such third
        party institutions, educational background, and professional history,
        and Hoom uses this information in connection with our review of your
        application for Hoom products. During this stage of the application
        process, you may be directly or indirectly connected to the website for
        the Provider Services you have identified. Hoom may coordinate with one
        or more online financial service providers to access Application Data
        maintained on the third party site. Hoom may use and store the content
        to evaluate your Hoom application and to provide services to you, but
        Hoom does not store any user account credentials. The online financial
        service providers store your login credentials securely in a separate
        database using multi-layered hardware and software encryption.
      </p>
      <p>
        By submitting information, data, passwords, usernames, PINs, other
        sign-in information, materials, and other content from third party sites
        and platforms to Hoom through the Site, you (i) hereby authorize and
        permit Hoom to use that content, and hereby license that content to
        Hoom, solely for the purpose of evaluating your Hoom application and
        providing related services to you and (ii) represent that you are
        entitled to submit such information to Hoom for use for this purpose,
        without any obligation by Hoom to pay any fees or other limitations.
        When you connect your third party accounts with the Site during your
        application process, you expressly authorize Hoom to access Application
        Data maintained by identified third parties, on your behalf as your
        agent. For purposes of these Terms of Use and solely to provide you with
        services in connection with your application for Hoom’s products, you
        grant Hoom a limited power of attorney, and appoint Hoom as your
        attorney-in-fact and agent, to access third party sites and retrieve and
        use your information with the full power and authority to do and perform
        each thing necessary in connection with such activities, as you could do
        in person.{" "}
      </p>
      <h2>
        <strong className="bold-text-13">
          YOU ACKNOWLEDGE AND AGREE THAT WHEN HOOM IS ACCESSING AND RETRIEVING
          ACCOUNT INFORMATION FROM THIRD PARTY SITES, HOOM IS ACTING AS YOUR
          AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY.
        </strong>
      </h2>
      <p>
        You understand and agree that the Site is not sponsored or endorsed by
        any third parties accessible through the Site. Hoom is not responsible
        for the accuracy of the information maintained by or on third party
        sites, nor for products or services offered by or on such sites. You
        further acknowledge and agree that we have no control over the provision
        of Provider Services or provision of access to the Provider Services by
        your provider(s), do not guarantee that you will be able to use the Site
        features with the Provider Services, and will have no liability
        whatsoever for any actions or inactions on the part of the third party
        sites resulting in your inability to use the Site to access your
        accounts, obtain data, download transactions, or otherwise use or access
        the Provider Services.
      </p>
      <p>
        Application Data and certain other information about you is subject to
        our <a href="https://point.com/privacy_policy">Privacy Policy</a>,{" "}
        <a href="https://point.com/credit_report_policy">
          Credit Report Policy
        </a>
        , and{" "}
        <a href="https://point.com/eces_consents">
          Consent to Electronic Communications and Electronic Signatures
        </a>
        .
      </p>
      <h2>
        <strong className="bold-text-14">
          ACCURACY OF INFORMATION PROVIDED
        </strong>
      </h2>
      <p>
        In consideration of your use of the Site, you agree to (i) provide, or
        cause to be provided, including through third party sites, accurate,
        current, and complete Application Data and (ii) maintain and promptly
        update your Application Data (and any other information you provide to
        Hoom) so that it is accurate, current, and complete at all times.
      </p>
      <h2>
        <strong className="bold-text-15">RESEARCH DATA</strong>
      </h2>
      <p>
        Anonymous, aggregate information (consisting of financial account
        balances, other financial account data, or other available data that is
        provided by you) may be used by us or our third party vendors to conduct
        certain analytical research, performance tracking and benchmarking.  Our
        third party vendors may publish summary or aggregate results relating to
        metrics comprised of research data, from time to time, and distribute or
        license such anonymous, aggregated research data for any purpose,
        including but not limited to, helping to improve products and services
        and assisting in troubleshooting and technical support. Your personally
        identifiable information will not be shared with or sold to third
        parties.
      </p>
      <h2>
        <strong className="bold-text-16">AUTHORIZATION TO CONTACT</strong>
      </h2>
      <p>
        By submitting information to Hoom through our Site or otherwise, you are
        making an inquiry as to the products offered by Hoom and give Hoom
        permission to contact you through email, fax, or telephone or any other
        means, even if your phone number is on a “Do Not Call” registry. When
        you submit information through our Site, we may send your information to
        our affiliates or service providers.
      </p>
      <h2>
        <strong className="bold-text-17">CELLULAR PHONE CONTACT POLICY</strong>
      </h2>
      <p>
        By providing us with a telephone number for a mobile device, including a
        number that you later convert to a mobile device number, you are
        expressly consenting to receiving communications—including but not
        limited to prerecorded or artificial voice message calls, text messages,
        and calls made by an automatic telephone dialing system—from us and our
        affiliates and agents at that number. This express consent applies to
        each such telephone number that you provide to us now or in the future.
        Calls and messages may incur access fees from your mobile services
        provider.
      </p>
      <h2>
        <strong className="bold-text-18">
          CONSENT TO SHARE INFORMATION WITH CONTRACTORS
        </strong>
      </h2>
      <p>
        I expressly authorize Hoom to share my information including my name,
        address, anticipated funding amount, and anticipated funding date with
        general contractors that I may be working with for the sole purpose of
        providing the contractor with the status of my transaction. I understand
        that I may withdraw my consent at any time by contacting Hoom by phone
        at 415-707-0976 or by email at support@home.llc.
      </p>
      <h2>
        <strong className="bold-text-19">ACCOUNT SECURITY</strong>
      </h2>
      <p>
        Hoom may use passwords to prevent unauthorized access to certain areas
        of our Site. You are solely responsible for maintaining the
        confidentiality of your user name, password and other security-related
        identifiers. You are solely and fully responsible for all use of your
        account and for any actions that take place using your account or under
        your user name and password. You agree to immediately notify us of any
        loss, theft, or unauthorized use of your password or account and of any
        other security breach relating to you. Hoom will not be responsible for
        any losses or damages arising out of the unauthorized use of your
        account, and you agree to indemnify and hold harmless Hoom, its
        affiliates, successors and assigns, and each of their officers,
        directors, employees, representatives and agents (the “
        <strong>Hoom Parties</strong>”), for any improper, unauthorized or
        illegal uses of your account.
      </p>
      <h2>
        <strong className="bold-text-20">LICENSE</strong>
      </h2>
      <p>
        Hoom grants to you a personal, non-exclusive, non-transferable, limited
        license to access and use the Site in accordance with these Terms of
        Use. Hoom reserves all right, title, and interest not expressly granted
        under this license to the fullest extent possible under applicable laws.
        Any use of this Site not specifically permitted under these Terms of Use
        is expressly prohibited. This license is revocable by Hoom at any time
        without notice and with or without cause.
      </p>
      <h2>
        <strong className="bold-text-21">INTELLECTUAL PROPERTY</strong>
      </h2>
      <p>
        All content on this Site, including but not limited to web pages, source
        codes, calculations, products, materials, data, information, text,
        screens, functionality, services, design, layout, screen interfaces,
        text, graphics, images, artwork, logos, icons, audio clips, and video
        clips (collectively, the “<strong>Content</strong>”), are owned by Hoom
        or its affiliates, or by third party content providers, merchants,
        sponsors and licensors, and all right, title and interest therein shall
        remain the property of Hoom and/or such third parties. All Content is
        protected by trade dress, copyright, patent and trademark laws, and
        various other intellectual property rights and unfair competition laws.
        You are authorized solely to view and retain a copy of the pages of the
        Site for your own personal, non-commercial use. You may also view and
        make copies of relevant documents, pages, images or other materials on
        this Site for the purpose of transacting business with Hoom. You agree
        that you will not duplicate, publish, frame, modify, edit, reproduce,
        sell, display, transmit, create derivative works from, participate in
        the transfer of, post on the World Wide Web, or in any way distribute or
        exploit the Site, or any portion of the Site, for any public or
        commercial use without the express written consent of Hoom.
        Additionally, you agree that you will not (i) remove or alter any
        author, trademark or other proprietary notice or legend displayed on the
        Site (or printed pages produced from the Site); or (ii) make any other
        modifications to any documents obtained from the Site other than in
        connection with completing information required to transact business
        with Hoom. As a condition to your use of this Site, you warrant to Hoom
        that you will not use our Site for any purpose that is unlawful or
        prohibited by these Terms of Use.
      </p>
      <h2>
        <strong className="bold-text-22">USER REPRESENTATIONS</strong>
      </h2>
      <p>
        You are solely responsible for all code, video, images, information,
        data, text, software, music, sound, photographs, graphics, messages or
        other materials that you upload, post, transmit, share, store, publish,
        display or otherwise make available (hereinafter, “
        <strong>upload</strong>”) or email or otherwise use via the Site. You
        represent, warrant, and agree that no materials of any kind submitted
        through your account or otherwise uploaded by you through the Site (i)
        violate or infringe upon the rights of any third party, including
        copyright, trademark, privacy, publicity or other personal proprietary
        rights, (ii) violate any contractual, fiduciary or judicially imposed
        nondisclosure obligations, or (iii) contain libelous, defamatory or
        otherwise unlawful material. You agree that you may not use the Site in
        any unlawful manner or in any other manner that could damage, disable,
        overburden or impair the Site. Violations of system or network security
        may result in civil or criminal liability.
      </p>
      <p>You further agree not to use the Site to:</p>
      <ul>
        <li>
          probe, scan or test the vulnerability of this Site or the Hoom network
          or breach security or authentication measures without proper
          authorization;
        </li>
        <li>
          interfere with or disrupt the Site or servers or networks connected to
          the Site (including, without limitation, via means of submitting a
          virus to this Site, overloading, “flooding,” “spamming,” “mail
          bombing” or “crashing”), or disobey any requirements, procedures,
          policies or regulations of networks connected to the
        </li>
        <li>
          upload any material that contains software viruses or any other
          computer code, files or programs designed to interrupt, destroy or
          limit the functionality of any computer software or hardware or
          telecommunications equipment;
        </li>
        <li>access data that is not intended for you;</li>
        <li>
          invade the privacy of, obtain the identity of, or obtain or access
          personally identifying information of other users of this Site;
        </li>
        <li>
          obtain or attempt to access or otherwise obtain any materials or
          information through any means not intentionally made available or
          provided for through the
        </li>
        <li>
          upload or make publicly available on the Site any private information
          of any third party without authorization, including, without
          limitation, addresses, phone numbers, email addresses, Social Security
          numbers and credit card numbers;
        </li>
        <li>
          solicit personal information from anyone under 18 or solicit passwords
          or personally identifying information for commercial or unlawful
          purposes;
        </li>
        <li>
          register for more than one user account or register for a user account
          on behalf of an individual other than yourself;
        </li>
        <li>
          use or attempt to use another&#x27;s account, service or system
          without authorization from Hoom, or create a false identity on the
          Site;
        </li>
        <li>
          impersonate any person or entity, or falsely state or otherwise
          misrepresent yourself, your age or your affiliation with any person or
          entity;
        </li>
        <li>
          upload any unsolicited or unauthorized advertising, solicitations,
          promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid
          schemes,” “contests,” “sweepstakes,” or any other form of
          solicitation;
        </li>
        <li>
          harvest or collect email addresses or other contact information of
          other users from the Site by electronic or other means for the
          purposes of sending unsolicited emails or other unsolicited
          communications;
        </li>
        <li>
          advertise or offer to sell or buy any goods or services for any
          business purpose that is not specifically authorized;
        </li>
        <li>intimidate or harass other users;</li>
        <li>
          upload any content that we deem to be harmful, threatening, unlawful,
          defamatory, infringing, abusive, inflammatory, harassing, vulgar,
          obscene, fraudulent, invasive of privacy or publicity rights, hateful,
          or racially, ethnically or otherwise objectionable;
        </li>
        <li>
          upload content that, in our sole judgment, is objectionable or which
          restricts or inhibits any other person from using or enjoying the
          Site, or which may expose Hoom or its users to any harm or liability
          of any type;
        </li>
        <li>
          upload content that would constitute, encourage or provide
          instructions for a criminal offense, violate the rights of any party,
          or that would otherwise create liability or violate any local, state,
          national or international law, or any regulations having the force of
          law; or
        </li>
        <li>
          further or promote any criminal activity or enterprise or provide
          instructional information about illegal activities. You shall not and
          shall not permit any third party to, directly or indirectly: (i)
          reverse engineer, decrypt, decompile, decode, disassemble, or
          otherwise attempt to obtain the source code to the applications
          developed or owned by Hoom (the “
          <strong>Developer Applications</strong>”) that are contained in the
          Site; (ii) rent or time-share the Developer Application or host the
          Developer Application in a multi-tenant environment; (iii) remove or
          destroy any copyright notices, proprietary markings or confidential
          legends placed upon or contained within the Developer Application or
          any copies thereof; (iv) engage in any activity with the Developer
          Application that interferes with, disrupts, damages, or accesses in an
          unauthorized manner the servers, networks, or other properties or
          services of any third party; and (v) use the Developer Application or
          data provided via the Developer Application in any way in furtherance
          of criminal, fraudulent, or other unlawful activity.  You must comply
          with applicable laws and regulations in using, accessing or
          distributing the Developer Application, including any data provided
          via the Developer Application.
        </li>
      </ul>
      <h2>
        <strong className="bold-text-23">THIRD PARTY WEBSITES</strong>
      </h2>
      <p>
        This Site may contain links to outside services and resources, the
        availability and content of which Hoom does not control. Such links are
        provided for your reference and convenience only. We are not responsible
        for examining or evaluating, and we do not warrant or endorse, the
        offering of these businesses or individuals or the content of their web
        sites. Hoom does not assume any responsibility or liability for the
        actions, products, and content of these or any other third parties. Any
        concerns regarding any such service or resource, or any link thereto,
        should be directed to the particular outside service or resource.
      </p>
      <p>
        Some of the services implement the Google Maps web mapping service. Your
        use of Google Maps is subject to Google&#x27;s terms of use, available
        at{" "}
        <a href="https://www.google.com/intl/en_us/help/terms_maps.html">
          https://www.google.com/intl/en_us/help/terms_maps.html
        </a>
        .
      </p>
      <h2>
        <strong className="bold-text-24">ELECTRONIC COMMUNICATIONS</strong>
      </h2>
      <p>
        When you visit the Site or send emails to us, you are communicating with
        us electronically and you consent to receive communications from us
        electronically to the extent permissible by law. We will communicate
        with you by email or by posting notices on this Site. You agree that all
        agreements, notices, disclosures and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing to the extent permissible by law. You agree
        that we may send emails to you for the purpose of advising you of
        changes or additions to this Site, about any of our products or
        services, or for such other purposes as we deem appropriate and as
        permissible by law. You may unsubscribe from future promotional emails
        by clicking on the opt-out/unsubscribe link and following the
        instructions contained in any of the automated emails that you receive
        from us. You are deemed to have received electronic messages sent to you
        when they are sent to the most recent email address that you provided to
        us. Your electronic communications with us are further subject to our{" "}
        <a href="https://point.com/eces_consents">
          Electronic Communications and Electronic Signatures Policy
        </a>
        .
      </p>
      <h2>
        <strong className="bold-text-25">
          MONITORING AND RECORDING COMMUNICATIONS
        </strong>
      </h2>
      <p>
        You understand and agree that we may monitor and/or record any
        communications between you and us for quality control and other
        permitted business purposes. You also understand and agree that this
        monitoring or recording may be done without any further notice to you or
        anyone acting on your behalf.
      </p>
      <h2>
        <strong className="bold-text-26">DISCLAIMER OF WARRANTIES</strong>
      </h2>
      <p>
        HOOM DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, DOCUMENT OR FEATURE
        OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS
        WILL BE CORRECTED OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC
        RESULTS. THE MATERIAL IN THIS SITE COULD INCLUDE TECHNICAL INACCURACIES
        OR TYPOGRAPHICAL ERRORS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN
        &quot;AS-IS&quot; AND &quot;AS- AVAILABLE&quot; BASIS. ALL INFORMATION
        PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. HOOM CANNOT
        ENSURE THAT ANY FILES, DOCUMENTS OR OTHER DATA YOU DOWNLOAD FROM THE
        SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.
        HOOM DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE OR
        THE INFORMATION PUBLISHED HEREIN, INCLUDING WITHOUT LIMITATION ANY
        WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
        FOR A PARTICULAR PURPOSE, OR ANY WARRANTY RELATING TO THE COMPLETENESS,
        ADEQUACY, TIMELINESS, OR ACCURACY OF ANY INFORMATION ON THE SITE. HOOM
        DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF
        ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE.
        YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED
        SITES. YOUR SOLE REMEDY AGAINST HOOM FOR DISSATISFACTION WITH THE SITE
        OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS
        LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
      </p>
      <p>
        The Site may be temporarily unavailable from time to time for
        maintenance, system upgrades or other reasons. Hoom assumes no
        responsibility for any error, omission, interruption, deletion, defect,
        delay in operation or transmission, communications line failure, theft
        or destruction or unauthorized access to, or alteration of, user
        communications. Hoom will not be liable for any loss that results from
        (i) the failure of electronic or mechanical equipment or communication
        lines, (ii) telephone network issues, (iii) viruses, bugs, errors,
        configuration problems or the incompatibility of computer hardware or
        software, (iv) the failure or unavailability of Internet access, (v)
        problems with Internet service providers or other equipment or services
        relating to your computer or network, (vi) problems with computer or
        communications networks or facilities, (vii) problems with data
        transmission facilities or your telephone, cable or wireless service, or
        (viii) unauthorized access, theft, operator error, severe weather,
        earthquakes, or other natural disasters or labor disputes. Hoom is also
        not responsible for any damage to your computer, software, modem,
        telephone, wireless device or other property related in any way from
        your use of the Site.
      </p>
      <h2>
        <strong className="bold-text-27">LIMITATION OF LIABILITY</strong>
      </h2>
      <p>
        HOOM’S LIABILITY IS LIMITED. EXCEPT WHERE PROHIBITED BY LAW, NONE OF THE
        HOOM PARTIES SHALL BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFITS OR LOST DATA, EVEN IF HOOM HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES. UNLESS OTHERWISE EXPRESSLY SET FORTH IN A WRITTEN
        AGREEMENT WITH YOU, HOOM&#x27;S MAXIMUM LIABILITY FOR ALL CLAIMS ARISING
        OUT OF OR RELATING TO THIS SITE, OR ITS CONTENT, WHETHER IN CONTRACT,
        TORT (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED TO $500. YOU
        AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE
        ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE
        BARGAIN BETWEEN HOOM AND YOU, AND THAT THIS SITE WOULD NOT BE PROVIDED
        WITHOUT SUCH LIMITATIONS.
      </p>
      <p>
        Some jurisdictions do not allow limitations of liability, so the
        foregoing limitation may not apply to you.
      </p>
      <h2>
        <strong className="bold-text-28">INDEMNITY</strong>
      </h2>
      <p>
        By using the Site, you agree to defend, indemnify, and hold harmless the{" "}
        <strong>Hoom Parties</strong> from and against any and all losses,
        claims, damages, costs and expenses (including reasonable legal and
        accounting fees and costs) arising out of or relating to (i) your use of
        this Site or the Content, (ii) your breach of these Terms of Use or
        (iii) your submission to Hoom of incomplete, inaccurate or untimely
        Application Data or other information. The Hoom Parties reserve the
        right to assume or participate through the counsel of their choice, at
        your expense, in the investigation, settlement and defense of any such
        action or claim.
      </p>
      <h2>
        <strong className="bold-text-29">EXPORT RESTRICTIONS</strong>
      </h2>
      <p>
        You acknowledge that the Site features and any software underlying such
        Site are subject to the U.S. Export Administration Regulations (15 CFR,
        Chapter VII) and that you will comply with these regulations. You will
        not export or re-export the software or Site features, directly or
        indirectly, to: (i) any countries that are subject to U.S. export
        restrictions; (ii) any end user who has been prohibited from
        participating in U.S. export transactions by any federal agency of the
        U.S. government; or (iii) any end user who you know or have reason to
        know will utilize them in the design, development or production of
        nuclear, chemical or biological weapons. You further acknowledge that
        this product may include technical data subject to export and re-export
        restrictions imposed by U.S. law.
      </p>
      <h2>
        <strong className="bold-text-30">SEVERABILITY; ENTIRE AGREEMENT</strong>
      </h2>
      <p>
        Any failure by Hoom to exercise any rights or enforce any of these Terms
        of Use shall not constitute a waiver of such rights or terms. If any
        provision of these Terms of Use or their application in a particular
        circumstance is held to be invalid or unenforceable to any extent, the
        remainder of these Terms of Use, or the application of such provision in
        other circumstances, shall not be affected thereby, and each provision
        hereof shall be valid and enforced to the fullest extent permitted by
        law. These Terms of Use constitute the entire agreement between you and
        Hoom with regard to your use of the Site, and any and all other written
        or oral agreements or understandings previously existing between you and
        Hoom with respect to such use are hereby superseded and cancelled.
      </p>
      <h2>
        <strong className="bold-text-31">LAW AND VENUE</strong>
      </h2>
      <p>
        This Site is located in the State of California. These Terms of Use and
        the relationship between you and Hoom shall be governed by the laws of
        the State of California without regard to its conflicts of law
        provisions. You agree to the personal jurisdiction by and venue in the
        state and federal courts in Santa Clara County, California, and agree
        and consent that such courts are the exclusive forum for litigation of
        any claim or cause of action arising out of or relating to your use of
        this Site or the Content. You agree that regardless of any statute or
        law to the contrary, any claim or cause of action arising out of or
        relating to your use of this Site or the Content must be filed within
        one year after such claim or cause of action arose or be forever barred.
      </p>
      <h2>
        <strong className="bold-text-32">RELATIONSHIP</strong>
      </h2>
      <p>
        Without limiting any other provision of these Terms of Use and except as
        otherwise provided herein, these Terms of Use create no agency,
        partnership, joint venture, or employee-employer relationship between
        you and Hoom.
      </p>
      <h2>
        <strong className="bold-text-33">CUSTOMER SERVICE</strong>
      </h2>
      <p>
        If you need assistance with the Site or with any service or information
        provided through it, or if you need to communicate with Hoom, call us at
        888-764-6823 or email us at support@home.llc.
      </p>
      <h2>
        <strong className="bold-text-34">PRIVACY</strong>
      </h2>
      <p>
        At Hoom, we respect the privacy of our users. Please review our{" "}
        <a href="https://point.com/privacy_policy">Privacy Policy</a> (which
        also governs your visit to our Site) to understand our practices. By
        using the Site, you consent to our collection and use of personal data
        as outlined therein. Additionally, by using the Site, you acknowledge
        and agree that Internet transmissions are never completely private or
        secure. You understand that any message or information you send to the
        Site may be read or intercepted by others, notwithstanding Hoom&#x27;s
        efforts to protect such transmissions.
      </p>
      <h1>‍</h1>
    </div>
  );
};

export default TermsOfUse;
