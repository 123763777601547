import React from 'react'
import CalendyContact from '../components/EquityLanding/Calendy'
import FAQSection from '../components/EquityLanding/FAQSection'
import Footer from '../components/Footer'


const ScheduleCallEquity = () => {
  return (
   <div>
    <CalendyContact />
    <FAQSection />
    <Footer />
    </div>
  )
}

export default ScheduleCallEquity