import React from 'react';

const Popup = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg">
        <h2 className="text-2xl font-medium mb-4">Subscription Successful!</h2>
        <p className='text-[16px] leading-[20px] font-normal'>You just subscribed to HOME.LLC, Thanks For Subscribing.</p>
        <button
          className="bg-custom-ed5272 text-white px-4 py-2 mt-4 rounded-lg"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
