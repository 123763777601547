import React from 'react'
import idrisAndMaria from '../../assets/buyer-landing/idris-maria.png'

const Testimonial = () => {
  return (
    <div className='w-full h-full pb-10 sm:pb-20 sm:pt-[74px]'>
    <h2 className='text-3xl sm:text-6xl font-light text-center mb-[80px] sm:mb-[174px]'>Love letters <span className='text-[#ED5272] font-bold'>to us</span></h2>
    
    <div className='w-full sm:w-3/4 sm:mx-auto sm:h-[430px] bg-[#F1F3F5] text-[#212529] rounded-[20px] relative py-4 sm:py-0'>
     <div className='flex justify-center'>
     <img src={idrisAndMaria} alt="idrisAndMaria" className='absolute top-[-40px] sm:top-[-80px] h-[100px] w-[100px] sm:h-auto sm:w-auto' />
     </div>
     <div className='mt-[80px] sm:mt-[150px] flex flex-col justify-center'>
      <div>
        <h3 className='text-2xl sm:text-4xl font-semibold mb-2 text-center'>Idris & Maria</h3>
        <p className='text-sm sm:text-lg text-[#ED5272] font-semibold text-center font-quickSand'>First Time Home buyer</p>
      </div>
      <div className='mt-5'>
       <h3 className='text-3xl sm:text-4xl font-semibold mb-4 text-center'>“We want to thank Home.LLC for..</h3>
       <p className='text-lg sm:text-xl font-normal text-center font-quickSand'>helping us buy this beautiful home. This process is supposed to be very long and complicated <br /> but, Home.LLC made this as easy as possible.</p>
      </div>
     </div>
    </div> 
    </div>
  )
}

export default Testimonial