import React, { useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import { useSearchPropertiesUsingAddressMutation } from "../../store/apis/cashoutApi";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { updateStateProperty } from "../../store/slices/cashoutSlice";
import { setAddress } from "../../store/slices/stringSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const HeroSection = () => {
  const autocompleteRef = useRef();
  const dispatch = useDispatch();
  const address = useSelector((state) => state.address);
  const errorState = useSelector((state) => state.cashout.errorState);

  const [
    searchPropertiesUsingAddress,
    { isLoading, isError, error, isSuccess, data },
  ] = useSearchPropertiesUsingAddressMutation();

  const handlePlaceSelect = async () => {
    const place = autocompleteRef.current.getPlace();
    const formattedAddress = place.formatted_address;
    setAddress(formattedAddress);
    dispatch(setAddress(formattedAddress));
  };

  const handleSearchProperties = async () => {
    if (address === "") {
      dispatch(
        updateStateProperty({
          propertyName: "errorState",
          value: "Please enter your address",
        })
      );
      dispatch(updateStateProperty({ propertyName: "radarID", value: "" }));
      return;
    }

    const addressForPropertyRadar = address.split(",")[0];
    const payload = {
      Criteria: [
        {
          name: "Address",
          value: [addressForPropertyRadar],
        },
      ],
    };

    const res = await searchPropertiesUsingAddress(payload);

    if (res.data.results.length > 1) {
      dispatch(
        updateStateProperty({
          propertyName: "errorState",
          value:
            "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976.",
        })
      );
      dispatch(updateStateProperty({ propertyName: "radarID", value: "" }));
      return;
    } else {
      if (res.data.results.length === 0) {
        dispatch(
          updateStateProperty({
            propertyName: "errorState",
            value:
              "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976.",
          })
        );
        dispatch(updateStateProperty({ propertyName: "radarID", value: "" }));
        return;
      } else {
        if (res.data.results[0]["PType"] !== "SFR") {
          dispatch(
            updateStateProperty({
              propertyName: "errorState",
              value:
                "Uh-oh! We couldn’t locate your address. For assistance, please reach out to us at support@home.llc or call +1 415-707-0976.",
            })
          );
          dispatch(updateStateProperty({ propertyName: "radarID", value: "" }));
          return;
        }
      }
    }

    // if (!isLoading && data && errorState !== "") {
    dispatch(
      updateStateProperty({
        propertyName: "radarID",
        value: res.data?.results[0]?.RadarID,
      })
    );

    dispatch(
      updateStateProperty({
        propertyName: "errorState",
        value: "",
      })
    );
  };

  return (
    <div className=" bg-cashout-hero h-[95vh] pt-[150px] 2xl:pt-[200px] 3xl:pt-[300px] w-full bg-center bg-cover bg-no-repeat">
      <div className="max-w-[1117px] h-[264px] mx-auto w-full text-white">
        <h1 className="font-poppins text-[55px] leading-[80px] text-center">
          Cashout your <span className="text-custom-primaryPink">Home</span>{" "}
          equity with ease
        </h1>
        <p className="text-center mt-3 font-poppins font-medium leading-5">
          A home equity investment (HEI) was designed with you in mind. Access
          your equity easily without monthly payments.
        </p>

        <div className="flex flex-col max-w-[820px] h-[56px] w-full mx-auto relative mt-14">
          <div className="relative w-full flex">
            <Autocomplete
              className="w-full"
              onLoad={(autocomplete) => {
                autocompleteRef.current = autocomplete;
              }}
              onPlaceChanged={handlePlaceSelect}
            >
              <input
                type="text"
                value={address}
                onChange={(e) => dispatch(setAddress(e.target.value))}
                placeholder="Enter home address"
                className="w-full h-full placeholder:text-custom-gray10
               border-none outline-none rounded-[30px] p-4 text-custom-gray10 bg-custom-mediumWhiteBG"
              />
            </Autocomplete>
            {isLoading && (
              <CircularProgress
                sx={{ position: "absolute", right: 150, top: 12, zIndex: 100 }}
                size={30}
                color="info"
              />
            )}
            <button
              className="absolute  text-center right-0 bg-custom-primaryPink
             rounded-[30px] flex bottom-0 h-full w-[140px] justify-center items-center gap-2"
              onClick={() => handleSearchProperties(address.split(",")[0])}
            >
              <Search />
              Search
            </button>
          </div>
          {errorState ? (
            <p className="text-red-500 text-sm ml-2">{errorState}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
