import React, { useState, useEffect, useRef } from "react";
import card1 from "../../assets/analyze/analyze-card-1.png";
import card2 from "../../assets/analyze/analyze-card-2.png";
import card3 from "../../assets/analyze/analyze-card-3.png";
import icon1 from "../../assets/analyze/card-1-icon.svg";
import icon2 from "../../assets/analyze/card-2-icon.svg";
import icon3 from "../../assets/analyze/card-3-icon.svg";

const ConsultBanner = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const cardRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && isMobile) {
          entry.target.classList.add("card-hovered", "hover");
        } else {
          entry.target.classList.remove("card-hovered", "hover");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    cardRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      cardRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [isMobile]);

  return (
    <div className="w-full h-full pb-10 pt-10 sm:pt-[74px]">
      <h2 className="text-4xl xl:w-[878px] w-[390px] mx-auto text-center xl:text-[64px] xl:leading-[82.4px] capitalize mb-16 xl:mb-[94px] text-[#18191F] font-light">
        Unlock{" "}
        <span className="font-semibold text-[#6A6DFF]">top home analytics</span>{" "}
        for your investments
      </h2>
      <div className="flex flex-col sm:flex-row gap-[40px] justify-center items-center w-full sm:gap-5 2xl:gap-14 2xl:mr-8 px-4 2xl:px-0 mt-10">
        <div
          ref={cardRefs[0]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 opacity-0 sm:opacity-100 icon-div">
            <img src={icon1} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[271px] h-[202px] absolute top-[100px] left-4 sm:top-[350px] sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-4xl text-white max-w-[225px] mb-6">
              Find the best homes
            </h4>
            <p className="text-lg sm:text-xl font-normal text-white">
              Get accurate home value & rent projections
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card1} alt="card-hovered" className="w-full" />
          </div>
        </div>
        <div
          ref={cardRefs[1]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden z-10 analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 icon-div opacity-0 sm:opacity-100">
            <img src={icon2} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[271px] h-[202px] absolute top-[100px] sm:top-[350px] left-4 sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-4xl text-white max-w-[225px] mb-6">
              Invest in the right places
            </h4>
            <p className="text-lg sm:text-xl font-normal text-white">
              Use our neighborhood scores
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card2} alt="card-hovered" className="w-full" />
          </div>
        </div>
        <div
          ref={cardRefs[2]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 icon-div opacity-0 sm:opacity-100">
            <img src={icon3} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[289px] h-[202px] absolute top-[100px] sm:top-[350px] left-4 sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-4xl text-white max-w-[289px] mb-6">
              Accelerate your outcomes
            </h4>
            <p className="text-lg sm:text-xl font-normal text-white">
              Consult with the best housing analysts
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card3} alt="card-hovered" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultBanner;
