import React from 'react';

const DropdownMenu = ({ dropdownItems, onItemClick, activeItem }) => {
  return (
    <ul className="hidden group-hover:block absolute left-0 w-48 bg-white shadow-md mt-0.5 rounded-md overflow-hidden z-10">
      {dropdownItems.map((item, index) => (
        <li
          key={index}
          className={`px-4 py-2 cursor-pointer ${
            activeItem === item.name ? 'bg-custom-ed5272 text-white' : 'hover:bg-gray-100'
            }`}
            onClick={() => onItemClick(item)}
        >
            {item.name}
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;
