import React from "react";
import nik1 from "../../assets/invest/Nik-1.png";
import nik2 from "../../assets/invest/Nik-2.png";
import team from "../../assets/invest/Team.png";

const HowCanWeHelp = () => {
  return (
    <div className="bg-[#FEF9F0] h-full py-[50px] sm:py-[90px]">
      <h2 className="text-2xl sm:text-5xl sm:leading-[72px] font-semibold text-center capitalize mb-10 sm:mb-[84px]">
        How can <span className="text-[#ED5272]">We Help?</span>
      </h2>

      <div className="flex flex-col sm:flex-row px-4 sm:px-10 2xl:justify-center gap-[40px] 2xl:gap-[100px] mb-[100px]">
        <div className="relative h-full flex flex-wrap items-center justify-center w-full 2xl:w-[750px] gap-2 sm:gap-10">
          <div className="w-[100px] h-[97px] sm:w-[316px] sm:h-[208px] bg-[#ED5272] text-white rounded-xl py-[22px] px-[14px] sm:pl-[26px] sm:pr-[66px] sm:pt-[60px] sm:pb-[16px] mb-10">
            <h4 className="text-[14px] leading-[24.69px] sm:text-[42px] sm:leading-[66px] font-semibold">
              Nik Shah
            </h4>
            <p className="text-sm font-[275] sm:text-[36px] sm:leading-[66px]">CEO</p>
          </div>
          <img src={nik1} alt="" className="w-[180px] h-[275px] sm:h-auto sm:w-auto" />
          <img src={nik2} alt="" className="mt-[-100px] sm:mt-[-150px] w-[180px] h-[158px] sm:w-auto sm:h-auto" />
        </div>
        <div className="w-full sm:w-[578px] flex flex-col justify-center items-center sm:items-start">
          <h3
            className="text-2xl sm:text-[36px] leading-[52px] 2xl:text-[48px] 2xl:leading-[66px] font-semibold w-[308px] mb-4 text-center sm:text-left
       ">
            The Man Behind it all
          </h3>
          <p className="text-lg text-center sm:text-left 2xl:text-[22px] 2xl:leading-[35.8px] font-quickSand">
            We are on a mission to solve the income inequality crisis in the US.
            We will empower Americans to leverage the fruits of homeownership,
            and generate wealth for themselves and their loved ones.
          </p>
        </div>
      </div>

      <div className="flex justify-center px-4">
        <div className="relative">
          <img src={team} alt="team" className="w-full" />
          <div className="absolute inset-0 bg-gradient-to-b from-[#00000017] to-[#000000]"></div>
          <div className="absolute bottom-2 left-0 w-full  text-white p-4">
            <h3 className="text-sm sm:text-5xl sm:leading-[72px] font-semibold text-center capitalize mb-[6px]">
              Origination, Analytics, & Servicing Team
            </h3>
            <p className="text-[12px] font-normal text-center">Based in India</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowCanWeHelp;
