import React, { useEffect, useRef, useState } from "react";
// import youtube from "../../assets/youtube-image.png";
import Videos from "./Video";
import card1 from "../../assets/home-equity/card-1.png";
import card2 from "../../assets/home-equity/card-2.png";
import card3 from "../../assets/analyze/analyze-card-3.png";
import icon1 from "../../assets/analyze/card-1-icon.svg";
import icon2 from "../../assets/analyze/card-2-icon.svg";
import icon3 from "../../assets/analyze/card-3-icon.svg";


const HowItWorks = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const cardRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && isMobile) {
          entry.target.classList.add('card-hovered', 'hover');
        } else {
          entry.target.classList.remove('card-hovered', 'hover');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    cardRefs.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      cardRefs.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [isMobile]);

  return (
    <div className="w-full h-full pb-10 pt-10 sm:pt-[74px] sm:pb-0">
        <h2 className="text-3xl sm:text-6xl font-light text-center mb-16 xl:mb-[94px]">How It <span className="text-[#ED5272] font-bold">Works</span></h2>

        <div className="flex flex-col sm:flex-row gap-[40px] justify-center items-center w-full sm:gap-8 2xl:mr-8 px-4 2xl:px-0 mt-10">
        <div
          ref={cardRefs[0]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 opacity-0 sm:opacity-100 icon-div">
            <img src={icon1} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[310px] h-[202px] absolute top-[100px] left-4 sm:top-[350px] sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-3xl text-white w-full mb-6">
              Get upto $500,000 at 0% interest
            </h4>
            <p className="text-lg sm:text-lg font-normal text-white">
              In exchange, sell a part of your home to us
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card1} alt="card-hovered" className="w-full" />
          </div>
        </div>
        <div
          ref={cardRefs[1]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden z-10 analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 icon-div opacity-0 sm:opacity-100">
            <img src={icon2} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[310px] h-[202px] absolute top-[100px] sm:top-[350px] left-4 sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-3xl text-white w-full mb-6">
              It’s an investment, not a loan
            </h4>
            <p className="text-lg sm:text-lg font-normal text-white">
              Enjoy financial flexibility. No monthly payments up to 30 years
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card2} alt="card-hovered" className="w-full" />
          </div>
        </div>
        <div
          ref={cardRefs[2]}
          className="h-[450px] sm:h-[600px] flex flex-col w-[350px] xl:w-[400px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] rounded-[20px] cursor-pointer relative overflow-hidden analyze-card">
          <div className="absolute top-[-66px] left-[270px] h-[213px] w-[213px] rounded-full bg-[#000000] bg-opacity-15 icon-div opacity-0 sm:opacity-100">
            <img src={icon3} alt="icon" className="pt-[100px] pl-[30px]" />
          </div>
          <div className="w-[310px] h-[202px] absolute top-[100px] sm:top-[350px] left-4 sm:left-[36px] card-content">
            <h4 className="font-medium text-2xl sm:text-3xl text-white w-full mb-6">
             Settle whenever you're ready
            </h4>
            <p className="text-lg sm:text-lg font-normal text-white">
              No prepayment penalties, no rush. Buy us out anytime with savings, a refinance, or sale of your home
            </p>
          </div>
          <div className="absolute w-full h-[250px] sm:h-[300px] top-[250px] sm:top-[400px] lg:top-[370px] opacity-0 card-img z-10">
            <img src={card3} alt="card-hovered" className="w-full" />
          </div>
        </div>
      </div>

      <div className="sm:mt-10 h-[300px] w-[350px] sm:h-[600px] rounded-sm lg:w-[900px] mx-auto object-cover overflow-hidden">
            <Videos/>
      </div>
      
      <div className="h-[200px] w-full bg-[#212529] mt-[-150px]">
      </div>
      
      {/* <div className="flex justify-start 2xl:justify-center items-end gap-8 2xl:gap-[82px]">
        <h2 className="w-[363px] h-[207px] text-[#000000] text-3xl 2xl:text-5xl leading-[49px] 2xl:leading-[69px] font-semibold mb-[26px]">That’s why the CUSTOMERS LOVE US</h2>
        
        <img src={placeholder} alt="video-placeholder" />
      </div> */}
    </div>
  )
};

export default HowItWorks;
