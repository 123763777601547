import React from "react";
import CultureHero from "../components/CultureLanding/CultureHero";
import CultureSummary from "../components/CultureLanding/CultureSummary";
import CulturePrinciples from "../components/CultureLanding/CulturePrinciples";
import Header from "../components/HomePage/Navbar";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";

const CulturePage = () => {
  return (
    <div className="overflow-x-hidden sm:overflow-x-auto">
      <Header />
      <CultureHero />
      <CultureSummary />
      <CulturePrinciples />
      <NeedFromUs />
      <Footer />
    </div>
  );
};

export default CulturePage;
