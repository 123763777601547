import { useState, useEffect } from 'react';

const EligibilityModal = ({
  isOpen,
  onClose,
  userResponses,
  updateUserResponses,
}) => {
  const [isFormComplete, setIsFormComplete] = useState(false);

  const handleChange = (field, value) => {
    updateUserResponses(field, value);
  };

  useEffect(() => {
    const { name, email, phone } = userResponses;
    const isComplete = name.trim() !== '' && email.trim() !== '' && phone.trim() !== '';
    setIsFormComplete(isComplete);
  }, [userResponses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormComplete) {
      onClose(); // Close modal after submission
    }
  };

  if (!isOpen) return null;

  return (
    <div className="w-full fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-4/5 md:w-[1005px] h-[600px] p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font- mt-12 text-black font-semibold mb-4 text-center">
          Enter Your Details To Check Your Offer
        </h2>
        <p className="text-sm text-black text-center mb-20">
          Let's finalize your details first. Don't worry about spam, we hate
          it too!
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Your name"
            value={userResponses.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <input
            type="email"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Email"
            value={userResponses.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <input
            type="text"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Phone number"
            value={userResponses.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
          />
          <div className="flex justify-center">
          <button
  type="submit"
  className={`text-black bg-transparent border-2 border-black py-2 px-4 sm:px-12 mt-0 sm:mt-12 text-lg rounded-full transition duration-300 ease-in-out ${
    isFormComplete
      ? 'hover:bg-pink-400 hover:text-white hover:border-white cursor-pointer'
      : 'cursor-not-allowed opacity-50'
  }`}
  disabled={!isFormComplete}
  onClick={handleSubmit}
>
  Check if you qualify
</button>


          </div>
        </form>
      </div>
    </div>
  );
};

export default EligibilityModal;
