import React from "react";

import logo1 from "../../assets/logosection/bloomberg.svg";
import logo2 from "../../assets/logosection/new-york-times.svg";
import logo3 from "../../assets/logosection/fortune.svg";
import logo4 from "../../assets/logosection/wall-street.svg";
import logo5 from "../../assets/logosection/epoch-times.svg";
import logo6 from "../../assets/logosection/axios.svg";
import logo7 from "../../assets/logosection/yahoo.svg";
import logo8 from "../../assets/logosection/forbes.svg";
import fastCompany from "../../assets/logosection/fast-company.svg";
import logo9 from "../../assets/logosection/usa-today.svg";
import logo10 from "../../assets/logosection/insider.svg";
import logo11 from "../../assets/logosection/better-homes.svg";
import logo12 from "../../assets/logosection/realtor.svg";
import logo13 from "../../assets/logosection/sfgate.svg";
import logo14 from "../../assets/logosection/rbn.svg";
import logo15 from "../../assets/logosection/glasses.svg";
import logo16 from "../../assets/logosection/go-banking.svg";
import logo17 from "../../assets/logosection/mortage-reports.svg";
import logo18 from "../../assets/logosection/hw.svg";
import logo19 from "../../assets/logosection/mpa.svg";

const PressSection = () => {
  return (
    <div className="w-full h-full pb-10 sm:pb-20 bg-[#212529]">
      <div className="w-full h-[355px] text-white pt-10 sm:pt-[74px]">
        <h2 className="text-[32px] sm:text-6xl font-[275] text-center text-white">
          As seen <span className="font-bold text-[#6A6DFF]">in</span>
        </h2>
      </div>
      <div
        className="w-[90%] sm:w-[90%] h-full xl:h-[420px] rounded-[20px] bg-[#F1F3F5] mx-auto mt-[-190px] sm:mt-[-107px] py-[35px] sm:py-[62px] px-4 gap-y-[40px]
       sm:gap-y-[40px] flex flex-wrap gap-[34px] sm:gap-[64px] sm:items-center justify-center">
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo1}
            alt=""
            className="cursor-pointer h-[17px] w-[95px] sm:w-40 sm:h-8"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo2}
            alt=""
            className="cursor-pointer h-[17px] w-[132px] sm:w-[226px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo3}
            alt=""
            className="cursor-pointer h-[17px] w-[76px] sm:w-[129px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo4}
            alt=""
            className="cursor-pointer h-[17px] w-[150px] sm:w-[354px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo5}
            alt=""
            className="cursor-pointer h-[17px] w-[204px] sm:w-[349px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo6}
            alt=""
            className="cursor-pointer h-[17px] w-[69px] sm:w-[118px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo7}
            alt=""
            className="cursor-pointer h-[17px] w-[48px] sm:w-[82px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo8}
            alt=""
            className="cursor-pointer h-[17px] w-[66px] sm:w-[113px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={fastCompany}
            alt=""
            className="cursor-pointer h-[17px] w-[123px] sm:w-[113px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo9}
            alt=""
            className="cursor-pointer h-[18px] w-[116px] sm:w-[198px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo10}
            alt=""
            className="cursor-pointer h-[18px] w-[117px] sm:w-[200px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo11}
            alt=""
            className="cursor-pointer h-[18px] w-[75px] sm:w-[75px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo12}
            alt=""
            className="cursor-pointer h-[18px] w-[15px] sm:w-[25px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo13}
            alt=""
            className="cursor-pointer h-[18px] w-[42px] sm:w-[73px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo14}
            alt=""
            className="cursor-pointer h-[18px] w-[67px] sm:w-[115px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo15}
            alt=""
            className="cursor-pointer h-[18px] w-[54px] sm:w-[92px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo16}
            alt=""
            className="cursor-pointer h-[18px] w-[109px] sm:w-[185px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo17}
            alt=""
            className="cursor-pointer h-[17px] w-[125px] sm:w-[204px] sm:h-[28px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo18}
            alt=""
            className="cursor-pointer h-[17px] w-[18px] sm:w-[30px] sm:h-[30px]"
          />
        </a>
        <a href="/press" target="_blank" rel="noreferrer">
          <img
            src={logo19}
            alt=""
            className="cursor-pointer h-[18px] w-[57px] sm:w-[97px] sm:h-[30px]"
          />
        </a>
      </div>
    </div>
  );
};

export default PressSection;
