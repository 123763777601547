import React, { useContext } from "react";
import groups from "../../../assets/groups.svg";
import { CounterContext } from "../../../hooks/HomeAi";

const TopIndustryChart=() => {
  const { industry }=useContext(CounterContext);

  const industries=[
    { name: "Industry 1", earnings: 500000, employed: 250000 },
    { name: "Industry 2", earnings: 400000, employed: 200000 },
    { name: "Industry 3", earnings: 300000, employed: 150000 },
    { name: "Industry 4", earnings: 200000, employed: 150000 },
    { name: "Industry 5", earnings: 100000, employed: 150000 },
  ];

  const money=[
    "$ 500,000",
    "$ 400,000",
    "$ 300,000",
    "$ 200,000",
    "$ 100,000"
  ]

  const getWidth=(index) => {
    const widths=["70%", "60%", "50%", "40%", "30%"];
    return widths[index]||"30%"; // Default to 30% if index is out of range
  };

  return (
    <div className="w-full mx-auto border-r pr-8 border-black">
    <div className="flex flex-col space-y-2">
      {industry?.industry
        .sort((a, b) => b.value - a.value)
        .map((industry, index) => (
          <div key={index} className="flex items-center flex-wrap md:flex-nowrap">
            <div className="px-2 text-xs capitalize w-28">
              {industry.label}
            </div>
  
            <div className="flex-grow relative min-w-[200px] md:min-w-[400px] h-8">
              <div
                className="absolute top-0 left-0 bg-[#379DFC] h-full flex items-center justify-end text-white text-sm"
                style={{ width: getWidth(index) }}
              >
                <span className="pr-2 relative text-white text-sm flex items-center gap-1">
                  <img src={groups} alt="groups" />{" "}
                  {industry.value.toLocaleString()}
                </span>
                <span className="text-xs absolute -right-20 text-black hidden md:block">
                  {"$ " + industry.earnings.toLocaleString()}
                </span>
              </div>
            </div>
            <div className="text-xs absolute right-0 text-black md:hidden mt-2">
              {"$ " + industry.earnings.toLocaleString()}
            </div>
          </div>
        ))}
    </div>
  </div>
  
  );
};

export default TopIndustryChart;
