import React from 'react'
import heroMap from '../../assets/hero-map.png'

const Hero = () => {
  return (
    <div className='bg-home-hero bg-no-repeat bg-right sm:bg-center bg-cover h-[750px] sm:h-screen w-full flex flex-col justify-center items-center'>
      <div 
        className='w-full h-full flex flex-col justify-center items-center bg-no-repeat bg-center bg-contain'
        style={{ backgroundImage: `url(${heroMap})` }}
      >
        <h1 className='text-white font-light text-2xl 2xl:text-6xl text-center mb-4'>Home.AI</h1>
        <p className='text-[#ED5272] text-2xl 2xl:text-6xl font-bold text-center'>2024 MSA Projections</p>
      </div>
    </div>
  )
}

export default Hero
