import React from "react";
import Temp from "../../../../assets/HomeAi/Temp.svg";
import SeaLevel from "../../../../assets/HomeAi/SeaLevel.svg";
import Agriculture from "../../../../assets/HomeAi/Agri.svg";
import GreenCover from "../../../../assets/HomeAi/GeenCover.svg";
import Line from "../../../../assets/HomeAi/Line.svg";
import LongLine from "../../../../assets/HomeAi/LongLine.svg";
const data=[
  {
    icon: Temp,
    name: "Temperature",
    conditions: "Days over 100oC to Double",

    comfortScore: 8,
  },
  {
    icon: SeaLevel,
    name: "Sea Level",
    conditions: "Likely to rise 1 to 4 feet",
    comfortScore: 6,
  },
  {
    icon: Agriculture,
    name: "Agriculture",
    conditions: "Fall in Livestock and Agriculture Productivity",
    comfortScore: 6,
  },
  {
    icon: GreenCover,
    name: "Green Cover",
    conditions: "Losing at a rate 3.7, fastest iin Georgie ",
    comfortScore: 6,
  },
];
const Forecast=() => {
  return (
    <div className="flex flex-col gap-5">
      <div className="text-xl font-medium">Seasons Comfort Rating</div>
      <div className="flex items-center relative justify-center">
        <img src={LongLine} alt={"line"} className=" absolute top-[49%]" />
        <div className="h-[381px] flex items-center justify-evenly">
          {data.map((item, i) => (
            <div
              className={`flex  w-[164px] items-center  relative ${i%2===0? "flex-col":"flex-col-reverse"
                }`}
            >
              <img src={item.icon} alt={item.name} />
              <div
                className={`flex items-center absolute  gap-1  ${i%2===0? "top-24 flex-col":"bottom-24 flex-col-reverse"
                  } `}
              >
                <img
                  className={`${i%2!==0? "rotate-180":""}`}
                  src={Line}
                  alt={item.name}
                />

                <div className="flex items-center gap-1 flex-col">
                  <div className="text-md font-semibold">{item.name}</div>
                  <div className="text-sm text-center">{item.conditions}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Forecast;
