import React from "react";
import Amenities from "../../../../assets/HomeAi/Amenities.svg";
import Star from "../../../../assets/HomeAi/Star.svg";

const AmenitiesCard=({ properties }) => {
  const num=properties.rating==="missing"? 0:Number(properties.rating);
  return (
    <div className="flex items-center gap-5 p-3 border-dashed border-b border-black">
      <img src={Amenities} alt="Amenities" className="w-10 h-10" />
      <span className="h-full border-black border"></span>
      <div className="flex gap-3 justify-between items-start w-full max-w-[420px]">
        <div className="flex w-full flex-col text-md">
          <div className=" flex items-start justify-between ">
            <div>
              <b>{properties.name}</b>
              <br />
              <div className="text-base">{properties.first_type}</div>
            </div>
            <div className="flex gap-2">
              <img src={Star} alt="Star" className="h-6 w-6" />
              {num}
            </div>
          </div>
          <div className="text-base truncate">
            {properties.formatted_address}
          </div>
        </div>
      </div>
    </div>
  );
};


export default AmenitiesCard;
