import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorState: "",
  addressForPropertyRadarApi: "",
  addressForZestimateApi: "",
  radarID: "",
  fetchedHomeValues: {
    avm: 0,
    cltv: 0,
    equityPercent: 0,
    availableEquity: 0,
    totalLoanBalance: 0,
    lastTransferValue: 0,
    lastTransferRecDate: "",
    myValuePerSqFt: 0,
    lotSize: 0,
    sqft: 0,
    beds: 0,
    baths: 0,
    longitude: 0,
    latitude: 0,
  },
  fetchedOwnerValues: {
    owners: [],
    ownedSince: "",
  },
  fetchedZestimateValues: {
    zestimate: 0,
  },
  calculatedFields: {
    cashoutValue: 0,
    minimumCashout: 0,
    maximumCashout: 0,
    homeValue: 0,
    homeLlcShare: 0,
    loanValue: 0,
    userShare: 0,
    futureHomeValue: 0,
  },
  owner: null,
  userEmail: "",
  userPhone: "",
};

const cashoutSlice = createSlice({
  name: "cashout",
  initialState,
  reducers: {
    updateStateProperty: (state, action) => {
      const { propertyName, value } = action.payload;
      if (propertyName in initialState) {
        state[propertyName] = value;
      } else {
        console.error(`Property ${propertyName} does not exist in the state`);
      }
    },

    setOwner: (state, action) => {
      state.owner = action.payload;
    },
  },
});

export const { updateStateProperty, setOwner } = cashoutSlice.actions;
export default cashoutSlice.reducer;
