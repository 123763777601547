import school from '../../assets/school.svg'
import arrest from '../../assets/highlights/arrest.svg'
import money from '../../assets/highlights/money.svg'
import paid from '../../assets/highlights/paid.svg'
import bus from '../../assets/highlights/bus.svg'
import stocks from '../../assets/highlights/stocks.svg'
import weather from '../../assets/highlights/weather.svg'
import handshake from '../../assets/highlights/handshake.svg'
import Heading from './Ui/Heading'
import { useContext } from 'react'
import { CounterContext } from '../../hooks/HomeAi'
const icons=[
  stocks,
  paid,
  arrest,
  weather,
  handshake,
  school,
]
const HighLights = () => {
  const { highlights } =
    useContext(CounterContext);
  return (
    <div className='w-full h-full relative border-none shadow-none mt-[-5px]'>
      <div className='relative w-[90%] 2xl:w-[90%] py-[72px] mx-auto text-black h-full'>
        <Heading className='text-4xl 2xl:text-[80px] 2xl:leading-[120px] mb-10 font-normal'>
          Highlights
        </Heading>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-14'>
          {highlights?.map((highlight, index) => (
            <div key={index} className='flex justify-center'>
              <div className='w-full max-w-[340px] mx-4 my-2 rounded-lg p-3 border-black border'>
                <div className='flex justify-between items-center mb-5'>
                  <img src={icons[index]} alt={highlight.heading} className='w-12 h-12' />
                  {(index === 0 || index === 4) && (
                    <div className='bg-red-400 rounded-lg px-2 py-1 '>
                      <span className='text-white text-sm'>
                        Label
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <h3 className='text-xl mb-1'>{highlight.heading}</h3>
                  <p className='text-sm font-light max-h-[100px] h-full overflow-y-scroll scrollnon'>
                    {highlight.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HighLights;