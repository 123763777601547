import React from 'react';
import { motion } from 'framer-motion';
import investors from '../../../assets/invest/investors-img.png';

const images = [
  'https://www.bankrate.com/2022/07/14140203/Individual-investors-vs-institutional-investors.jpeg?auto=webp&optimize=high&crop=16:9',
  investors,
  'https://www.theforage.com/blog/wp-content/uploads/2022/09/what-is-an-investor.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdurZQhSY2FHnS5-J2PTIeiblmFc4ygCxScraSQUyoF9ZE9heIXSvITsoZsHxxRbVA0Sk&usqp=CAU',
  'https://navi.com/blog/wp-content/uploads/2022/10/Heres-everything-to-know-about-an-Investment-Company.jpg'
];

const Caraousel = () => {
  return (
    <div className='w-full pb-20 px-5 md:p-10 sm:px-0 relative border-t'>
      <h2 className='text-2xl leading-[36px] sm:text-5xl sm:leading-[72px] font-semibold text-center mb-14 md:mb-[78px]'>
        Invest with Confidence: <span className='text-[#ED5272]'> Steve's Journey</span>
      </h2>
      <div className='flex flex-col gap-10'>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
          <div className='w-full md:w-3/5'>
            <div className='h-full w-full md:w-[95%] p-5 hover:bg-[#f1f1f1] cursor-pointer transition-all duration-1000 rounded-lg'>
              <h3 className='text-[16px] leading-[23.2px] sm:text-[22px] sm:leading-[34px] font-medium mb-6 text-center sm:text-left md:w-[80%]'>
                Discover a New Investment Opportunity
                <br />Instead Of Buying More Homes, Buy a Part Of an Existing Home
              </h3>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand'>
                Meet Steve, a successful homeowner who finds himself house rich but cash poor. Despite owning a valuable property, Steve needs liquidity to cut his debt. Enter Home.LLC, offering an easy solution: instead of purchasing an entire property, you can invest in a share of an existing home.
              </p>
            </div>
          </div>
          <motion.div
            className='w-full md:w-2/5 flex items-center justify-center'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={images[0]} alt="investors" className='h-[90%] w-[90%] rounded-lg' />
          </motion.div>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
          <div className='w-full md:w-3/5'>
            <div className='h-full w-full md:w-[95%] p-5 hover:bg-[#f1f1f1] cursor-pointer transition-all duration-1000 rounded-lg'>
              <h3 className='text-[16px] leading-[23.2px] sm:text-[22px] sm:leading-[34px] font-medium mb-6 text-center sm:text-left md:w-[80%]'>We Give $150K to Steve at 20% APR</h3>
              <ul className="list-disc list-inside">
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">$0 Monthly Payment:</span> Provides immediate financial relief without the burden of monthly repayments.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Equal to 2% of Home Value:</span> A cost-effective solution compared to other financial products.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Cheaper than Credit Cards:</span> Lower than the typical 25% APR on credit cards, making it a more affordable option.
                </li>
              </ul>
            </div>
          </div>
          <motion.div
            className='w-full md:w-2/5 flex items-center justify-center'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={images[1]} alt="investors" className='h-[90%] w-[90%] rounded-lg' />
          </motion.div>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
          <div className='md:w-3/5'>
            <div className='h-full w-full md:w-[95%] p-5 hover:bg-[#f1f1f1] cursor-pointer transition-all duration-1000 rounded-lg'>
              <h3 className='text-[16px] leading-[23.2px] sm:text-[22px] sm:leading-[34px] font-medium mb-6 text-center sm:text-left md:w-[80%]'>
                Invest with Confidence in Any Economy
                <br />Generate High Returns Regardless Of Home Prices
              </h3>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                We ensure your investments are protected and profitable, no matter the market conditions.
              </p>
              <ul className='list-disc list-inside'>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">No Appreciation:</span> Achieve a 2x return, delivering a solid 15% annual profit.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Market Crash:</span> Your investment is safeguarded, ensuring you recover 100% of your principal.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Slow Appreciation:</span> Benefit from a 2.5x return, translating to a 20% annual profit.
                </li>
              </ul>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                By providing high returns in various economic scenarios, Home.LLC solidifies your trust and secures your financial future.
              </p>
            </div>
          </div>
          <motion.div
            className='md:w-2/5 flex items-center justify-center'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={images[2]} alt="investors" className='h-[90%] w-[90%] rounded-lg' />
          </motion.div>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
          <div className='w-full md:w-3/5'>
            <div className='h-full w-full md:w-[95%] p-5 hover:bg-[#f1f1f1] cursor-pointer transition-all duration-1000 rounded-lg'>
              <h3 className='text-[16px] leading-[23.2px] sm:text-[22px] sm:leading-[34px] font-medium mb-6 text-center sm:text-left md:w-[80%]'>
                Flexible Investment Recovery
                <br />Steve Returns Your Investment in 1 of 3 Ways
              </h3>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                Steve appreciates the flexibility Home.LLC offers when it comes to repaying his investment. You have three convenient options to choose from:
              </p>
              <ul className='list-disc list-inside'>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Refinancing:</span> Steve can refinance his home or get a HELOC (Home Equity Line of Credit) due to his improved credit.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Selling the Home:</span> Steve can cash out from the appreciation when he sells the property.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">Buying Us Out:</span> Steve can buy out Home.LLC’s shares using his savings, other investments, or inheritance.
                </li>
              </ul>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                By providing high returns in various economic scenarios, Home.LLC solidifies your trust and secures your financial future.
              </p>
            </div>
          </div>
          <motion.div
            className='w-full md:w-2/5 flex items-center justify-center'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={images[3]} alt="investors" className='h-[90%] w-[90%] rounded-lg' />
          </motion.div>
        </div>
        <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
          <div className='w-full md:w-3/5'>
            <div className='h-full w-full md:w-[95%] p-5 hover:bg-[#f1f1f1] cursor-pointer transition-all duration-1000 rounded-lg'>
              <h3 className='text-[16px] leading-[23.2px] sm:text-[22px] sm:leading-[34px] font-medium mb-6 text-center sm:text-left md:w-[80%]'>
                Why Choose Home.LLC?
                <br />Steve Prefers Us Because of $0 Coupon & 20% APR Cap
              </h3>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                Steve, like many homeowners, needs money for various reasons—whether it’s to pay debt, invest in new opportunities, or make significant purchases. Here’s why Steve and countless others prefer Home.LLC:
              </p>
              <ul className='list-disc list-inside'>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">$0 Coupon:</span> No monthly payments required, providing immediate financial relief.
                </li>
                <li className="mb-4 font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand">
                  <span className="font-bold">20% APR Cap:</span> Benefit from high returns with a capped APR, ensuring you maximize your investment’s profitability.
                </li>
              </ul>
              <p className='font-normal text-sm sm:text-[18px] sm:leading-[32.8px] text-center sm:text-left font-quickSand mb-2'>
                By choosing Home.LLC, Steve gains the financial flexibility he needs without the stress of traditional loan repayments.
              </p>
            </div>
          </div>
          <motion.div
            className='w-full md:w-2/5 flex items-center justify-center'
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={images[4]} alt="investors" className='h-[90%] w-[90%] rounded-lg' />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Caraousel;