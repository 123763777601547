import React from 'react'
import pathRight from '../../assets/team/pathRight.svg'
import pathLeft from '../../assets/team/PathLeft.svg'
import companyLogo from '../../assets/team/company-logo.png'
import missionSvg from '../../assets/team/mission.svg'
import visionSvg from '../../assets/team/vision.svg'

const MissionAndVission = () => {
  return (
   <div className='w-full bg-[#212529] text-white h-full sm:h-[649px] border-b py-10 sm:pt-[100px]'>
    <div className='hidden sm:flex w-full justify-center items-center relative'>
    
     <div className='flex items-start sm:mr-[-20px]'>
       <h2 className='text-xl xl:text-5xl font-semibold text-center'>Our <span className='text-[#ED5272]'>Vision</span></h2>
        <img src={pathLeft} alt="path" className='mt-10 mr-6 ml-2 mb-40' />
     </div>
    
     <div className='sm:h-[248px] w-[248px] border rounded-full flex items-center justify-center'>
      <img src={companyLogo} alt='company' />
     </div>
     <div className='flex items-start sm:ml-[-20px]'>
      <img src={pathRight} alt="path" className='mt-10 ml-6 mr-2 mb-40' />
      <h2 className='text-xl xl:text-5xl font-semibold text-center'>Our <span className='text-[#ED5272]'>Mission</span></h2>
     </div>
    </div>
    
      <div className='flex flex-col sm:flex-row gap-10 sm:gap-0 justify-between items-center py-[10px] sm:py-0 sm:mt-[-150px] px-4 2xl:px-20'>
      <div className='flex items-center flex-col'>
        <h2 className='text-2xl xl:text-5xl font-semibold text-center mb-4 sm:hidden'>Our <span className='text-[#ED5272]'>Vision</span></h2>
     <div className='h-[149px] w-[149px] border rounded-full flex items-center justify-center mb-4' >
     <img src={visionSvg} alt="" />
      </div>
      {/* <h4 className='text-white text-xl font-semibold'>Home of Homeownership</h4> */}
      <p className='w-full sm:w-[452px] text-lg font-[275] text-white text-center'>To help you build wealth from home ownership</p>
        </div>
        
        <div className='flex items-center flex-col'>
        <h2 className='text-2xl xl:text-5xl font-semibold text-center mb-4 sm:hidden'>Our <span className='text-[#ED5272]'>Mission</span></h2>
     <div className='h-[149px] w-[149px] border rounded-full flex items-center justify-center mb-4' >
       <img src={missionSvg} alt="" />
      </div>
      {/* <h4 className='text-white text-xl font-semibold text-center'>To help you build wealth through homeownership</h4> */}
      <p className='w-full sm:w-[452px] text-lg font-[275] text-white text-center'>To be the home of home-ownership.</p>
    </div>
    </div>
    </div>
  )
}

export default MissionAndVission