import React from "react";
import { cultures } from "../../utils";

const CultureSummary = () => {
  return (
    <div className="h-full w-full bg-[#212529] py-10 sm:pt-10 sm:pb-20">
      <h3 className="text-[32px] leading-[42px] text-center lg:text-[64px] lg:leading-[88px] font-bold text-[#FFFFFF] py-10 uppercase">
        Culture Summary
      </h3>
      <div className="container mx-auto p-4">
        <div className="flex flex-col gap-20">
          <div className="flex flex-col sm:flex-row gap-10 justify-center items-center">
            {cultures.slice(0, 3).map((culture) => (
              <div
                className="bg-white h-[450px] w-[350px] rounded-lg shadow-xl p-6"
                key={culture.id}>
                <h2 className="text-xl font-semibold mb-10 font-quickSand">
                  {culture.cultureTitle}
                </h2>
                <p className="text-gray-700 leading-6 font-quickSand">
                  {culture.cultureText}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col sm:flex-row gap-10 justify-center items-center">
            {cultures.slice(3, 5).map((culture) => (
              <div
                className="bg-white h-[450px] w-[350px] rounded-lg shadow-xl p-6"
                key={culture.id}>
                <h2 className="text-xl font-semibold mb-10 font-quickSand">
                  {culture.cultureTitle}
                </h2>
                <p className="text-gray-700 leading-6 font-quickSand">
                  {culture.cultureText}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CultureSummary;
