import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const IneligibilityModal = ({
  isOpen,
  onClose,
  userResponses,
  updateUserResponses,
}) => {
  const [isFormComplete, setIsFormComplete] = useState(false);

  const handleChange = (field, value) => {
    updateUserResponses(field, value);
  };

  useEffect(() => {
    const { name, email, phone } = userResponses;
    const isComplete = name.trim() !== '' && email.trim() !== '' && phone.trim() !== '';
    setIsFormComplete(isComplete);
  }, [userResponses]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormComplete) {
      onClose(); // Close modal after submission
    }
  };

  if (!isOpen) return null;

  return (
    <div className="w-full fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-4/5 md:w-[1005px] h-[600px] p-6 rounded-lg shadow-lg">
        <button onClick={onClose} className="hidden absolute top-0 right-0 p-2 text-white">
          &#10005; {/* This is a Unicode character for the cross mark */}
        </button>
        <h2 className="text-3xl font- mt-12 text-black font-semibold mb-4 text-center">
          Sorry, You Are Not Eligible Yet!
        </h2>
        <p className="text-sm text-black text-center mb-20">
          But here's what you can do for now, join our community so that we
          can get in touch. No spams, we promise!
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Your name"
            value={userResponses.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <input
            type="email"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Email"
            value={userResponses.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <input
            type="text"
            className="block w-full mb-8 py-2 p-2 placeholder-gray-500 border-b bg-transparent"
            placeholder="Phone number"
            value={userResponses.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
          />
          <div className="flex justify-center">
          <Link
            to="/cashout#schedule-call"
              className={`text-black bg-transparent border-2 item-center border-black py-2 px-12 mt-4 sm:mt-12 text-lg rounded-full transition duration-300 ease-in-out ${
                isFormComplete
                  ? 'hover:bg-pink-400 hover:text-white hover:border-white cursor-pointer'
                  : 'cursor-not-allowed opacity-50'
              }`}
              disabled={!isFormComplete}
              onClick={handleSubmit}
            >
              Reach out to us
              </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IneligibilityModal;
