import React from "react";
import BuyerHero from "../components/BuyerLanding/BuyerHero";
import OptimizeExperience from "../components/BuyerLanding/OptimizeExperience";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
import Header from "../components/HomePage/Navbar";
import Footer from "../components/Footer";
import FAQSection from "../components/BuyerLanding/FaqSection";
import TidyCalContact from "../components/BuyerLanding/TidyCal";
// import CommisionCalculator from "../components/SellerLanding/CommisionCalculator";
import Calculator from "../components/BuyerLanding/Calculator";
import LogoSection from '../components/HomePage/LogoSection';
import Testimonial from "../components/BuyerLanding/Testimonial";


const BuyerLandingPage = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <BuyerHero />
      <LogoSection />
      <OptimizeExperience />
      <Calculator />
      <Testimonial />
      {/* <TestimonialSlider testimonials={testimonialsBuy} /> */}
      <TidyCalContact operation={"Buy"} />
      <FAQSection />
      <NeedFromUs />
      {/* <FAQSection/>
      <ToolsResources/> */}
      <Footer />
    </div>
  );
};

export default BuyerLandingPage;
