import React from "react";
import Cap from "../../../../assets/HomeAi/cap.svg";

const SchoolCard=({ properties }) => {
  return (
    <div className="flex items-center gap-5 p-3 border-dashed border-b border-black">
      <img src={Cap} alt="cap" className="w-10 h-10" />
      <span className="h-full border-black border"></span>
      <div className="flex gap-1 justify-between items-center w-full max-w-[420px]">
        <div className="flex flex-col text-md w-[310px]">
          <b>{properties.name}</b>
          <div className="text-base">{properties.first_type}</div>
          <div className="text-base truncate">
            {properties.formatted_address}
          </div>
        </div>
        <div className=" ">
          <span className="text-5xl font-semibold">
            {properties.rating==="missing"? 0:properties.rating}
          </span>
          /<span className="text-lg">5</span>
        </div>
      </div>
    </div>
  );
};

export default SchoolCard;
