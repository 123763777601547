import React from 'react'
import map from '../../assets/invest/map.png'

const MapSection = () => {
  return (
   <div className='w-full h-full py-[50px] sm:py-[90px] bg-white'>
    <h2 className='text-2xl sm:text-5xl leading-[72px] font-semibold text-center mb-3'>THIS INDUSTRY IS <span className='text-[#ED5272]'>HUGE</span></h2>
    {/* <p className='text-sm sm:text-lg text-center font-semibold'>30,000 Steve’s financed so far by HomeTap, Unlock, Point, Unison, etc.</p> */}

    <div className='sm:px-[113px] flex justify-center mt-[47px] mb-[100px]'>
     <img src={map} alt="map" />
    </div>

    <div className='px-4 sm:px-[113px] flex flex-col sm:flex-row gap-12 sm:gap-[103px] justify-center'>
     <div className='sm:w-[562px] sm:h-[144px]'>
     <h3 className='text-lg text-center sm:text-left sm:text-3xl sm:leading-[50px] 2xl:text-5xl 2xl:leading-[72px] font-semibold'>BUT TINY WHEN <span className='text-[#ED5272]'>COMPARED TO HELOCs</span></h3>
     </div>

     <div className='w-full sm:w-[780px] h-[120px] sm:h-[242px] rounded-[14px] border border-[#9D9D9D] py-2 px-4 sm:px-[55px] sm:py-[40px]'>
      <p className='text-sm sm:text-xl 2xl:text-3xl sm:leading-[54px] border-b border-[#9D9D9D] mb-[25px] py-2'>$3B HEIs originations from 2014-2024</p>
      <p className='text-sm sm:text-xl 2xl:text-3xl sm:leading-[54px] py-2'>$250B HELOC originations in just 2022</p>
     </div>
    </div>
    </div>
  )
}

export default MapSection