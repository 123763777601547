import React, { useState } from "react";
import Transit from "./Tabs/Transit";
import Amenities from "./Tabs/Amenities";
import School from "./Tabs/School";
import WeatherAndClimate from "./Tabs/WeatherAndClimate";
import Heading from "../Ui/Heading";
const TabData=[
  "Transit",
  "Schools",
  "Amenities",
  // "Crime",
  "Weather & Climate",
];
const Desirability=() => {
  const [tab, setTab]=useState(TabData[1]);
  return (
    <div className="w-full h-full bg-[#E9ECEF] relative border-none shadow-none mt-[-5px] flex flex-col items-center justify-center px-4 md:px-6">
  <div className="relative w-[95%] 2xl:w-[90%] py-[48px] md:py-[72px] mx-[20px] md:mx-[40px] text-black h-full">
    <Heading>
      Desirability
    </Heading>

    <div className="flex flex-wrap md:justify-start gap-4 md:gap-20 md:gap-x-40 mt-4 md:mt-0">
      {TabData?.map((item) => (
        <div
          key={item} // Add key to each item for better performance
          onClick={() => setTab(item)}
          className={`text-lg md:text-2xl ${tab === item && "font-medium underline underline-offset-8"} cursor-pointer`}
        >
          {item}
        </div>
      ))}
    </div>
    <div className="w-full mt-8 md:mt-10">
      {tab === TabData[0] ? (
        <Transit />
      ) : tab === TabData[1] ? (
        <School />
      ) : tab === TabData[2] ? (
        <Amenities />
      ) : tab === TabData[3] ? (
        <WeatherAndClimate />
      ) : null}
    </div>
  </div>
</div>

  );
};

export default Desirability;
