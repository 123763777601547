import React, { useState, useEffect } from "react";
import ProgressIndicator from "./ProgressIndicator";
import Navigation from "./Navigation";
import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from "../../store/slices/stringSlice";

const CalculatorForSeller = () => {
  const steps = [
    "When are you planning to sell",
    "Location Check",
    "Your Home Preferences",
    "Property Details",
  ];

  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const address = useSelector(state => state.address); // Get address from Redux store
  const dispatch = useDispatch();
  const [userResponses, setUserResponses] = useState({
    sellingTime: "", // Time frame for buying the home
    preapproved: "",
    address: "", // Location where they plan to buy
    important: [], // Array to store multiple selections
    otherImportant: "",
    homeValue: 100000, // Square footage
    qualifiedAmount: 7500, // Hardcoded cashback value for demonstration
    consultation:"",
    showConditionalSteps: false, // State to manage conditional steps
    hesitations: [], // State for hesitations
    otherHesitations: "", // State for other hesitations
    what: "",
  });

  useEffect(() => {
    setUserResponses(prevResponses => ({
      ...prevResponses,
      address: address
    }));
  }, [address]);

  // Validation functions for each step
  const validations = {
    0: () => userResponses.sellingTime && userResponses.preapproved,
    1: () => userResponses.address || userResponses.showConditionalSteps,
    2: () => userResponses.important.length > 0 || userResponses.consultation,
    3: () => true || userResponses.what,
   
  };

  // Function to check if the current step is valid
  const isValidStep = (step) => {
    return validations[step]();
  };

  useEffect(() => {
    setIsNextEnabled(isValidStep(currentStep));
  }, [userResponses, currentStep]);

  const stepImages = [
    "https://cdn.pixabay.com/photo/2016/06/24/10/47/house-1477041_640.jpg",
    "https://st2.depositphotos.com/1041088/5192/i/450/depositphotos_51925277-stock-photo-american-house-with-beautiful-landscape.jpg",
    "https://eplan.house/application/files/6314/6702/0525/vid-speredy-JD-23274.jpg",
    "https://hips.hearstapps.com/hmg-prod/images/edc100123egan-002-6500742f5feb7.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://eplan.house/application/files/6314/6702/0525/vid-speredy-JD-23274.jpg",
    "https://hips.hearstapps.com/hmg-prod/images/edc100123egan-002-6500742f5feb7.jpg",
  ];

  const handleSelect = (field, value) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [field]: value,
    }));

    // If the address is being updated, also update the Redux store
    if (field === 'address') {
      dispatch(setAddress(value));
    }
  };

  const goNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleMultiSelect = (field, value) => {
    setUserResponses((prev) => ({
      ...prev,
      [field]: prev[field].includes(value)
        ? prev[field].filter((item) => item !== value)
        : [...prev[field], value],
    }));
  };

  const handleSelectSellingTiming = (value) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      sellingTime: value,
      showConditionalSteps: value === "within_a_year" || value === "someday",
    }));
  };

  return (
    <div className="flex w-full min-h-screen">
      <div className="w-full md:w-2/3 p-4 overflow-auto">
        <ProgressIndicator steps={steps} currentStep={currentStep} />

        {currentStep === 0 && (
          <div className="bg-white mb-20 p-6 mt-8">
            <div className="flex items-center gap-2 mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                01
              </span>
              <h2 className="text-3xl text-gray-900">
                When are you planning to Sell your home?
              </h2>
            </div>
            <form>
              <fieldset>
                <legend className="sr-only">Home Purchase Timing</legend>
                {[
                  { label: "Right Away", value: "right_away" },
                  { label: "Within 3 Months", value: "within_3_months" },
                  { label: "Within a Year", value: "within_a_year" },
                  { label: "Someday", value: "someday" },
                ].map((option, index) => (
                  <label
                    key={index}
                    className="block mt-4 text-lg text-gray-700"
                  >
                    <input
                      type="radio"
                      name="sellingTime"
                      value={option.value}
                      className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                      onChange={(e) =>
                        handleSelectSellingTiming(e.target.value)
                      }
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
              <fieldset className="grid grid-cols-2 gap-4 mt-8">
                <legend className="text-lg text-gray-900">
                  Are you preapproved?
                </legend>
                {[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ].map((option, index) => (
                  <label
                    key={index}
                    className="block mt-4 text-lg text-gray-700"
                  >
                    <input
                      type="radio"
                      name="preapproved"
                      value={option.value}
                      className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                      onChange={(e) =>
                        handleSelect("preapproved", e.target.value)
                      }
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
            </form>
          </div>
        )}
        {currentStep === 1 && !userResponses.showConditionalSteps && (
          <div className="bg-white mb-56  p-6 rounded-lg mt-8 ml-4">
            <div className="flex items-center mb-8 gap-2">
              <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                02
              </span>
              <h2 className="text-3xl text-gray-900">
                What Is The Address Of The Property You Wish To Sell
              </h2>
            </div>
            <p className="text-black">
              Your purpose is understandable, Could you tell us your address?
              This helps us make sure we're available in your area.
            </p>
            <input
              type="text"
              placeholder="Search your property's city, ZIP code, or address"
              value={userResponses.address}
              onChange={(e) => handleSelect("address", e.target.value)}
              className="w-full px-4 py-2 mt-4 leading-tight text-black bg-transparent shadow-none focus:outline-none focus:bg-transparent placeholder-gray-500"
              style={{ borderBottom: "3px solid black" }}
            />
          </div>
        )}
        {currentStep === 1 && userResponses.showConditionalSteps && (
          <div className="bg-white mb-36 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                02
              </span>
              <h2 className="text-3xl text-gray-900">
                What Hesitations Do You Have?
              </h2>
            </div>
            <form>
              {[
                "Stuck in a Long Term Lease",
                "Betting on a Home Price Crash",
                "Waiting For Lower Rates",
                "Still Saving For Down-payment",
              ].map((option, index) => (
                <label key={index} className="block text-lg text-gray-700 mt-3">
                  <input
                    type="checkbox"
                    name="hesitations"
                    value={option}
                    onChange={(e) =>
                      handleMultiSelect("hesitations", e.target.value)
                    }
                    className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                  />
                  <span className="ml-4">{option}</span>
                </label>
              ))}
              <div className="mt-4">
                <label className="block text-lg text-gray-900">Others</label>
                <input
                  type="text"
                  className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  onChange={(e) =>
                    handleSelect("otherHesitations", e.target.value)
                  }
                />
              </div>
            </form>
          </div>
        )}
        {currentStep === 2 && !userResponses.showConditionalSteps && (
          <div className="bg-white mb-40 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                03
              </span>
              <h2 className="text-3xl text-gray-900">
                What Do You Value The Most?
              </h2>
            </div>
            <form>
              <fieldset className="preferences-grid ">
                <legend className="sr-only">Preferences in Selling</legend>
                {[
                  {
                    label:
                      "I Want To Sell My Home At The Highest Possible Price",
                    value: "highest_price",
                  },
                  {
                    label: "I Want To Sell My Home At The Earliest",
                    value: "sell_earliest",
                  },
                  {
                    label: "I Want To Find The Right Buyer For My Home",
                    value: "right_buyer",
                  },
                  { label: "Others", value: "others" },
                ].map((option, index) => (
                  <label key={index} className="block text-lg text-gray-700">
                    <input
                      type="checkbox"
                      name="important"
                      value={option.value}
                      checked={userResponses.important.includes(option.value)}
                      onChange={(e) => {
                        const newSelection = e.target.value;
                        setUserResponses((prevResponses) => ({
                          ...prevResponses,
                          important: prevResponses.important.includes(
                            newSelection
                          )
                            ? prevResponses.important.filter(
                                (item) => item !== newSelection
                              )
                            : [...prevResponses.important, newSelection],
                        }));
                      }}
                      className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
              {userResponses.important.includes("others") && (
                <div className="mt-4">
                  <label className="block text-lg text-gray-900">
                    Please Specify
                  </label>
                  <input
                    type="text"
                    value={userResponses.otherImportant}
                    onChange={(e) =>
                      setUserResponses({
                        ...userResponses,
                        otherImportant: e.target.value,
                      })
                    }
                    className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  />
                </div>
              )}
            </form>
          </div>
        )}
        {currentStep === 2 && userResponses.showConditionalSteps && (
          <div className="bg-white mb-20 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                03
              </span>
              <h2 className="text-3xl text-gray-900">
                Need Advice On / Get A Free 1-1 Consultation On
              </h2>
            </div>
            <form>
              {[
                "What's The Best Time For Me",
                "What's The Right Value For My Home",
                "What Kind Of Rates Can I Get With My Profile",
                "What Are My Alternatives Given My Hesitations",
                "Others",
              ].map((option, index) => (
                <label key={index} className="block text-lg text-gray-700 mt-3">
                  <input
                    type="checkbox"
                    name="consultation"
                    value={option}
                    onChange={(e) =>
                      handleMultiSelect("consultation", e.target.value)
                    }
                    className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                  />
                  <span className="ml-4">{option}</span>
                </label>
              ))}
              {userResponses.consultation &&
                userResponses.consultation.includes("Others") && (
                  <div className="mt-4">
                    <label className="block text-lg text-gray-900">
                      Others
                    </label>
                    <input
                      type="text"
                      className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                      onChange={(e) =>
                        handleSelect("otherConsultation", e.target.value)
                      }
                    />
                  </div>
                )}
            </form>
          </div>
        )}
        {currentStep === 3 && !userResponses.showConditionalSteps && (
          <div className="bg-white ml-4 p-6 mt-4">
            <div className="flex items-center gap-2">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                04
              </span>
              <h2 className="text-3xl text-gray-900">
                You're Qualified For $7,500 In Cashback!
              </h2>
            </div>
            <div className=" text-black ml-4 p-4">
              <h1 className="text-2xl font-bold">
                Commission <span className=" text-pink-500">Comparison</span>{" "}
                Calculator
              </h1>
              <p className="text-sm mt-2">
                Slashed to just 1% - Triple Savings Guaranteed!
              </p>
            </div>

            <form>
              <div className=" flex flex-row items-center">
                <label className="block font-bol mr-2 ml-8 text-lg">
                  My Home Value :
                </label>
                <input
                  type="number"
                  value={userResponses.homeValue}
                  onChange={(e) =>
                    setUserResponses({
                      ...userResponses,
                      homeValue: parseInt(e.target.value) || 0,
                    })
                  }
                  className="w-1/3 p-2 pl-4 border-b border-black text-pink-500 font-bold text-2xl"
                />
              </div>

              <div className="text-black p-6">
                <h2 className="text-2xl font-bold mb-4">
                  You'll be saving:{" "}
                  <span className="text-pink-500">
                    ${userResponses.homeValue * 0.02}
                  </span>
                </h2>

                <div className="mb-4">
                  <p className="font-semibold">HOME agents</p>
                  <div className="bg-green-500 w-2/3 flex justify-between items-center px-4 py-2 rounded">
                    <span>Lower 1% commissions</span>
                    <span className="font-bold">
                      ${(userResponses.homeValue * 0.01).toLocaleString()}
                    </span>
                  </div>
                </div>

                <div className="">
                  <p className="font-semibold">Other agents</p>
                  <div className="bg-red-500 w-full flex justify-between items-center px-4 py-2 rounded">
                    <span>Higher 3% commissions</span>
                    <span className="font-bold">
                      ${(userResponses.homeValue * 0.03).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        {currentStep === 3 && userResponses.showConditionalSteps && (
          <div className="bg-white mb-20 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                04
              </span>
              <h2 className="text-3xl text-gray-900">What</h2>
            </div>
            <form>
              {[
                "What's The Best Time For Me?",
                "What's The Best House For Me?",
                "What Kind Of Rates Can I Get With My Profile?",
                "What Are My Alternatives Given My Hesitations?",
                "Others",
              ].map((option, index) => (
                <label key={index} className="block text-lg text-gray-700 mt-3">
                  <input
                    type="checkbox"
                    name="what"
                    value={option}
                    onChange={(e) => handleMultiSelect("what", e.target.value)}
                    className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                  />
                  <span className="ml-4">{option}</span>
                </label>
              ))}
              {userResponses.what && userResponses.what.includes("Others") && (
                <div className="mt-4">
                  <label className="block text-lg text-gray-900">Others</label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                    onChange={(e) => handleSelect("otherWhat", e.target.value)}
                  />
                </div>
              )}
            </form>
          </div>
        )}

       

        <Navigation
          onBack={goBack}
          onNext={goNext}
          canGoBack={currentStep > 0}
          finalStep={currentStep === 4}
          isSelectionMade={isNextEnabled}
          currentStep={currentStep}
          isNextEnabled={isNextEnabled} // Ensure this is calculated based on form validation logic
        />
      </div>

      <div className="w-1/3 hidden md:block">
        <img
          src={stepImages[currentStep]}
          alt={`Step ${currentStep + 1}`}
          className="object-cover h-full w-full"
        />
      </div>
    </div>
  );
};

export default CalculatorForSeller;
