import React, { useContext } from "react";
import groups from "../../../assets/groups.svg";
import { CounterContext } from "../../../hooks/HomeAi";

const OccupationChart=() => {
  const { occu }=useContext(CounterContext);

  const occupations=[
    { name: "Occupation 1", earnings: 500000, employed: 250000 },
    { name: "Occupation 2", earnings: 400000, employed: 200000 },
    { name: "Occupation 3", earnings: 300000, employed: 150000 },
    { name: "Occupation 4", earnings: 200000, employed: 150000 },
    { name: "Occupation 5", earnings: 100000, employed: 150000 },
  ];
  const money=[
    "$ 500,000",
    "$ 400,000",
    "$ 300,000",
    "$ 200,000",
    "$ 100,000"
  ]

  const getWidth=(index) => {
    const widths=["70%", "60%", "50%", "40%", "30%"];
    return widths[index]||"30%"; // Default to 30% if index is out of range
  };

  return (
   <div className="w-full mx-auto py-4">
  <div className="flex flex-col space-y-2">
    {occu?.occupation
      ?.slice(0, 5)
      .sort((a, b) => b.value - a.value)
      .map((occupation, index) => (
        <div key={index} className="flex items-center flex-wrap md:flex-nowrap">
          <div className="flex-grow relative h-8 min-w-[200px] md:min-w-[400px]">
            <div
              className="absolute top-0 right-0 bg-[#FF5B7D] h-full flex items-center text-white text-sm"
              style={{ width: getWidth(index) }}
            >
              <span className="pl-2 relative text-white text-sm flex items-center gap-1">
                {occupation.value.toLocaleString()}
                <img src={groups} alt="groups" />{" "}
              </span>
              <span className="text-xs absolute -left-20 text-black hidden md:block">
                {"$ " + occupation.earnings.toLocaleString()}
              </span>
            </div>
          </div>
          <div className="text-xs absolute text-black md:hidden">
            {"$ " + occupation.earnings.toLocaleString()}
          </div>
          <div className="px-2 text-xs capitalize w-28 break-words">
            {occupation.label}
          </div>
        </div>
      ))}
  </div>
</div>

  );
};

export default OccupationChart;
