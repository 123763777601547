import React from 'react';
import Dashboard from '../components/Investors/Dashboard';
import MainComponent from '../components/Investors/ImpInfo';

export default function Investors_Dashboard() {
  return (
    <div>
      <Dashboard />
      <MainComponent />
    </div>
  );
}