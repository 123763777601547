import React from "react";

const Navigation = ({
  onBack,
  onNext,
  canGoBack,
  finalStep,
  isNextEnabled,
  isSelectionMade,
  currentStep,
}) => {
  return (
    <div className="px-4 py-2 flex justify-between items-center w-full">
      <div className="flex justify-start">
        {canGoBack && (
          <button
            onClick={onBack}
            className="bg-transparent   hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full"
          >
            ← Go Back
          </button>
        )}
      </div>
      <div className="flex justify-end">
        {currentStep < 7 && (
          <button
            onClick={onNext}
            className="bg-transparent   hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full"
            disabled={!isNextEnabled}
          >
            Move to the next →
          </button>
        )}
        {currentStep === 7 && (
          <button
            onClick={onNext}
            disabled={!isSelectionMade}
            className={`${
              isSelectionMade
                ? "bg-transparent   hover:bg-pink-300 text-black border border-black py-2 px-4 rounded-full"
                : "bg-gray-200 text-gray-500 cursor-not-allowed"
            } text-black font-bold border border-black py-2 px-4 rounded-full transition-colors duration-300 ease-in-out`}
          >
            Calculate Estimate
          </button>
        )}
      </div>
    </div>
  );
};
export default Navigation;
