import React, { useState, useEffect } from "react";
import ElectronicsAndCommunication from "./ElectronicsAndComunication";
import PrivacyPolicy from "./PrivacyPolicy";
import CreditAuthorizationPolicy from "./CreditAuthorization";
import TermsOfUse from "./TermsOfUse";

const legals = [
  {
    name: "privacy-policy",
    displayName: "Privacy Policy",
    component: PrivacyPolicy,
  },
  { name: "terms-of-use", displayName: "Terms Of Use", component: TermsOfUse },
  {
    name: "consent-to-electronic-communications-and-disclosures",
    displayName: "Consent to Electronic Communications and Disclosures",
    component: ElectronicsAndCommunication,
  },
  {
    name: "credit-authorization",
    displayName: "Credit Authorization",
    component: CreditAuthorizationPolicy,
  },
];

const LegalSection = () => {
  const [selectedLegal, setSelectedLegal] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    if (tab) {
      setSelectedLegal(tab);
    }
  }, []);

  const LegalComponent = legals.find(
    (legal) => legal.name.toLowerCase().replace(/\s/g, "-") === selectedLegal
  )?.component;

  return (
    <div
      id="legal-section"
      className="w-full bg-[#15325F] h-full py-10 lg:pt-[100px]"
    >
      <div className="w-[400px] lg:w-full overflow-x-auto pb-[20px] hide-scrollbar px-4 xl:px-[40px]">
        <div className="w-full flex gap-[34px] items-center justify-start sm:px-2 mb-[10px] sm:mb-[30px] md:mb-[50px]">
          {legals.map((legal) => (
            <button
              onClick={() =>
                setSelectedLegal(
                  legal.displayName.toLowerCase().replace(/\s/g, "-")
                )
              }
              className={`py-[10px] px-[44px] rounded-[50px] border whitespace-nowrap text-white border-white font-medium ${
                selectedLegal ===
                legal.displayName.toLowerCase().replace(/\s/g, "-")
                  ? "bg-[#ED5272] text-white border-0"
                  : ""
              }`}
              key={legal.displayName}
            >
              {legal.displayName}
            </button>
          ))}
        </div>
      </div>

      <div className="px-2 xl:px-[40px] flex flex-col lg:flex-row items-start gap-[20px] sm:gap-40 h-full">
        {LegalComponent && <LegalComponent />}
      </div>
    </div>
  );
};

export default LegalSection;
