import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = ({ onBack, onNext, canGoBack, finalStep, isSelectionMade, currentStep, isNextEnabled }) => {
  return (
    <div className="bottom-0 right-0 px-4 py-2 flex justify-between items-center w-full">
      <div className="flex justify-start">
        {canGoBack && (
          <button onClick={onBack} className="bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded-md">
            ← Go Back
          </button>
        )}
      </div>
      <div className="flex justify-end">
        {currentStep < 3 && (
          <button 
            onClick={onNext} 
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md"
            disabled={!isNextEnabled} // Disable this button if conditions aren't met
          >
            Move to the next →
          </button>
        )}
        {currentStep === 3 && (
          <Link
          to="/sell#schedule-call"
          className={`${
            isSelectionMade ? "bg-pink-500 hover:bg-pink-700" : "bg-gray-300 text-gray-500 cursor-not-allowed"
          } text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 ease-in-out block text-center`}
          style={{ pointerEvents: isSelectionMade ? 'auto' : 'none' }} // This disables the link functionality when isSelectionMade is false
        >
          Schedule a Call
        </Link>
        
        )}
      </div>
    </div>
  );
};

export default Navigation;