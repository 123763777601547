import React from "react";

const LegalHero = () => {
  return (
    <div className="w-full h-[600px] sm:h-[773px] bg-faq-landing bg-no-repeat bg-cover bg-center">
      <div className="h-full bg-hero-section-overlay bg-cover">
        <div className="flex flex-col h-full justify-center lg:justify-end lg:items-center md:h-[80%]">
          <h2 className="text-3xl lg:text-[64px] lg:leading-[82.4px] text-center text-white font-[275] pt-[-200px] mb-[60px]">
            LEGAL
          </h2>
        </div>
      </div>
    </div>
  );
};

export default LegalHero;
