import React, { useState, useEffect } from 'react';
import logoUpscaled from '../../assets/logoNav.png'
import { FaChevronDown } from "react-icons/fa6";

const formatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
});  

const Calculator = () => {
  const [buyPrice, setBuyPrice] = useState(10000);
  const [showExtraCosts, setShowExtraCosts] = useState(false)

  const [costs, setCosts] = useState({
    otherAgentsCommission: { pct: 3, amt: 0 },
    maintenance: { pct: 1, amt: 0 },
    escrowFees: { pct: 1, amt: 0 },
    titleInsurance: { pct: 0.5, amt: 0 },
    legalFees: { pct: 0.2, amt: 0 },
    inspectionCosts: { pct: 0.1, amt: 0 },
    otherCosts: { pct: 0.3, amt: 0 },
  });

  const lowerCommissionRate = 1; // 1% commission rate
  const higherCommissionRate = 3; // 3% commission rate
  const savingsWithLowerCommission = (buyPrice * lowerCommissionRate / 100).toFixed(2);
  const savingsWithHigherCommission = (buyPrice * higherCommissionRate / 100).toFixed(2);
  const savingsDifference = (savingsWithHigherCommission - savingsWithLowerCommission).toFixed(2);
  


  useEffect(() => {
    const updateAmounts = (price) => {
      setCosts(currentCosts => {
        const updatedCosts = {};
        for (const key of Object.keys(currentCosts)) {
          updatedCosts[key] = {
            pct: currentCosts[key].pct,
            amt: ((price * currentCosts[key].pct) / 100).toFixed(2),
          };
        }
        return updatedCosts;
      });
    };

    // Initial calculation with the default buyPrice
    updateAmounts(buyPrice);
  }, [buyPrice]);

  const handlePercentageChange = (costKey, pctValue) => {
    setCosts(prevCosts => ({
      ...prevCosts,
      [costKey]: {
        ...prevCosts[costKey],
        pct: pctValue,
        amt: ((buyPrice * pctValue) / 100).toFixed(2),
      },
    }));
  };

  const handleAmountChange = (costKey, amtValue) => {
    setCosts(prevCosts => ({
      ...prevCosts, 
      [costKey]: {
        ...prevCosts[costKey], 
        amt: amtValue, 
        pct: ((amtValue / buyPrice) * 100)
      }
    }))
  }

 const calculateTotalCost = () => {
  return Object.values(costs).reduce((acc, cost) => acc + parseFloat(cost.amt), 0).toFixed(2);
};

const calculateTotalPercent = () => {
  return Object.values(costs).reduce((acc, cost) => acc + parseFloat(cost.pct), 0).toFixed(2);
};


  return (
    <div className="w-full flex justify-center items-start overflow-hidden">
    <div className="w-full bg-[#212529] h-full text-white py-10 sm:py-0 sm:pt-[74px] sm:pb-16">
      <h2 className="text-2xl sm:text-6xl font-semibold mb-10 text-center">Calculator</h2>
      <div className="flex sm:flex-row flex-col gap-8 xl:grid grid-cols-1 md:grid-cols-4 xl:gap-4"> {/* Changed to 3 cols for example */}
          {/* First Section - Taking up one third */}
          <div className="sm:col-span-2 md:col-span-2">
            <div className="px-2 ml-3 xl:ml-8">
              <label htmlFor="buyPrice" className="block text-2xl mb-1">Buy Price</label>
              <input
                type="text"
                id="buyPrice"
                value={`$${formatter.format(buyPrice)}`}
                onChange={(e) => setBuyPrice(e.target.value.replace(/[^\d.]/g, ''))}
                className="w-[300px] sm:w-full p-2 mb-4 bg-transparent text-3xl border-b text-white font-bold focus:outline-none"
              />
              <div className="flex flex-col space-y-4">
                {Object.keys(costs).slice(0, 1).map((costKey) => (
                  <CostInput
                    key={costKey}
                    label={costKey.split(/(?=[A-Z])/).join(" ")}
                    percentage={costs[costKey].pct}
                    amount={costs[costKey].amt}
                    onPercentageChange={(value) => handlePercentageChange(costKey, value)}
                    onAmountChange={(value) => handleAmountChange(costKey, value)}
                  />
                ))}
                <div className='flex items-center gap-2'>
                  <p className='cursor-pointer flex-shrink-0 text-white mb-2 capitalize'>View other costs</p>
                  <FaChevronDown size={20} className='text-white cursor-pointer' onClick={() => setShowExtraCosts(!showExtraCosts)} />
                </div>
                <div className='border-b flex flex-col gap-2 py-2'>
                {showExtraCosts ? Object.keys(costs).slice(1, 7).map((costKey) => (
                  <CostInput
                    key={costKey}
                    label={costKey.split(/(?=[A-Z])/).join(" ")}
                    percentage={costs[costKey].pct}
                    amount={costs[costKey].amt}
                    onPercentageChange={(value) => handlePercentageChange(costKey, value)}
                    onAmountChange={(value) => handleAmountChange(costKey, value)}
                  />
                )) : null}
                </div>
                <div className="flex flex-col md:flex-row items-start sm:items-center justify-between w-full pt-2 sm:w-[50%] 2xl:w-[70%]">
                <label className="flex-shrink-0 text-white mb-2 md:mb-0 sm:w-[135px] 2xl:w-[250px] 3xl:w-[350px] text-sm 2xl:text-xl">
                    Total Additional Cost
                  </label>
                    <div className="flex space-x-2 flex-grow">
                      <div className='relative'>
                        <input
                        type="number"
                        value={`${calculateTotalPercent()}`}
                        className="p-2 rounded-l bg-[#495057] text-white"
                      />
                      <span className="text-white px-2 absolute right-6 bottom-2">%</span>
                      </div>
                    
                      <input
                        type="text"
                        value={`$${formatter.format(calculateTotalCost())}`}
                        readOnly
                        className="p-2 rounded-r bg-[#ACB5BD] text-[#212529]"
                      />
                    </div>
                  </div>
              </div>
            </div>
          </div>
  
          {/* Savings Section - Taking up two thirds */}
          <div className="col-span-2 md:col-span-2 xl:ml-16 w-full"> {/* Adjusted to take full width within its grid column */}
          <div className="flex flex-col justify-start items-start w-full">
            <SavingsSection buyPrice={buyPrice} />
          </div>
        </div>
        </div>

        <h2 className='text-center text-xl sm:text-3xl font-normal mt-10 text-[#212529] bg-[#D0D1FF] py-4'>Work with us, and save
          <span className='font-extrabold text-black text-3xl '>{" "}${formatter.format(savingsDifference)}</span> in Commissions</h2>
      </div>
    </div>
  );
  

  // return (
  //   <div className="w-full flex justify-center items-start pt-8">
  //     <div className="w-full  bg-blue-600 text-white p-6 shadow-lg">
  //       <h2 className="text-3xl font-bold mb-6 text-center">Calculator</h2>
  //       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  //         {/* First Section */}
  //         <div className="mb-4 ml-8">
  //           <label htmlFor="buyPrice" className="block text-xl  mb-1">Buy Price</label>
  //           <input
  //             type="number"
  //             id="buyPrice"
  //             value={buyPrice}
  //             onChange={(e) => setBuyPrice(Number(e.target.value))}
  //             className="w-full p-2 mb-4 bg-blue-600 text-3xl border-b text-white font-bold"
  //           />
  
  //           <div className="flex flex-col space-y-4">
  //             {Object.keys(costs).map((costKey) => (
  //               <CostInput
  //                 key={costKey}
  //                 label={costKey.split(/(?=[A-Z])/).join(" ")}
  //                 percentage={costs[costKey].pct}
  //                 amount={costs[costKey].amt}
  //                 onPercentageChange={(value) => handlePercentageChange(costKey, value)}
  //               />
  //             ))}
  //           </div>
  
  //           <div className="text-2xl mt-6">
  //             Total Cost: ${calculateTotalCost()}
  //           </div>
  //         </div>
  
  //         {/* Savings Section */}
  //         <div className=" mt-24 ml-72 w-4/5">
  //         <div className="flex flex-col justify-start items-center">
  //         <SavingsSection buyPrice={buyPrice} />
  //       </div>
            
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  
};

// const CostInput = ({ label, percentage, amount, onPercentageChange }) => (
//   <div className="flex items-center justify-between w-full">
//     <label className="flex-shrink-0 text-white" style={{ width: 'auto', minWidth: '50%' }}>{label.charAt(0).toUpperCase() + label.slice(1)} (%):</label>
//     <div className="flex space-x-2 flex-grow">
//       <input
//         type="number"
//         value={percentage}
//         onChange={(e) => onPercentageChange(Number(e.target.value))}
//         className=" p-2 rounded-l bg-blue-900 text-white"
//       />
//       <input
//         type="text"
//         value={`$${amount}`}
//         readOnly
//         className=" p-2 rounded-r bg-blue-700 text-white"
//       />
//     </div>
//   </div>
// );
const CostInput = ({ label, percentage, amount, onPercentageChange, onAmountChange }) => (
  <div className="flex flex-col md:flex-row items-start sm:items-center justify-between w-full sm:w-[50%] 2xl:w-[70%]">
    <label className="flex-shrink-0 text-white mb-2 md:mb-0 mr-2 sm:w-[135px] 2xl:w-[250px] 3xl:w-[350px] whitespace-normal text-[15px] 2xl:text-xl">
      {label.charAt(0).toUpperCase() + label.slice(1)}
    </label>
    <div className="flex space-x-2 flex-grow">
      <div className='relative'>
         <input
        type="number"
        value={percentage}
        onChange={(e) => onPercentageChange(Number(e.target.value))}
        className="p-2 rounded-l bg-[#495057] text-white"
      />
      <span className="text-white px-2 absolute right-6 bottom-2">%</span>
      </div>
    
      <input
        type="text"
        value={formatter.format(amount)}
        onChange={(e) => onAmountChange(Number(e.target.value))}
        className="p-2 rounded-r bg-[#ACB5BD] text-[#212529]"
      />
    </div>
  </div>
);

const SavingsSection = ({ buyPrice }) => {
  const lowerCommissionRate = 1; // 1% commission rate
  const higherCommissionRate = 3; // 3% commission rate
  const savingsWithLowerCommission = (buyPrice * lowerCommissionRate / 100).toFixed(2);
  const savingsWithHigherCommission = (buyPrice * higherCommissionRate / 100).toFixed(2);
  const savingsDifference = (savingsWithHigherCommission - savingsWithLowerCommission).toFixed(2);

  return (
    <div className="border-t pt-10 sm:pt-0 my-6 sm:mt-0 sm:my-0 sm:border-t-0 w-full px-4 sm:px-0 bg-transparent sm:w-4/5 sm:rounded-lg text-white">
      {/* Title */}
      <div className="flex flex-col items-start mb-10">
        <h3 className="text-2xl sm:text-4xl sm:leading-[50px] text-[#D0D1FF] font-light hidden sm:block">You'll be saving: <br /> <span className='font-semibold'>${formatter.format(savingsDifference)}</span></h3>
        <h3 className="text-2xl sm:text-4xl sm:leading-[50px] text-[#D0D1FF] font-light block sm:hidden">You'll be saving: <span className='font-semibold'>${formatter.format(savingsDifference)}</span></h3>
      </div>

      {/* HOME agents */}
      <div className="mb-8">
        <h4 className="text-xl font-semibold mb-2 flex items-center gap-2"><img src={logoUpscaled} alt='logo' height={50} width={100} /> agents</h4>
        <div className="text-sm mb-2 text-[#D0D1FF]">Lower 1% commissions</div>
        <div className="relative bg-white h-8 w-full">
  <div className="bg-[#D0D1FF] h-full  leading-none py-2 text-center text-black" style={{ width: '25%' }}>
    1%
  </div>
  <span className="absolute right-0 transform translate-x-[-100%] mr-2 text-[#ED5272] font-semibold" style={{ top: '50%', transform: 'translateY(-50%)' }}>
    ${formatter.format(savingsWithLowerCommission)}
  </span>
</div>
      </div>
      
      {/* Other home agents */}
      <div className="mb-8">
        <h4 className="text-xl font-semibold mb-2">Other agents</h4>
        <div className="text-sm mb-2">Higher 3% commissions</div>
        <div className="relative bg-white  h-8  w-full">
  <div className="bg-[#495057] h-full  leading-none py-2 text-center text-white" style={{ width: '75%' }}>
    3%
  </div>
  <span className="absolute right-1 transform translate-x-[-100%] text-[#212529]" style={{ top: '50%', transform: 'translateY(-50%)' }}>
    ${formatter.format(savingsWithHigherCommission)}
  </span>
</div>
</div>
</div>
  );
};

// Include this component in your main Calculator layout


// Import this component into your main Calculator layout as before.






export default Calculator;


// import React, { useState, useEffect } from 'react';

// const Calculator = () => {
//   const [buyPrice, setBuyPrice] = useState(1970000);
//   const [agentCommissionPct, setAgentCommissionPct] = useState(0);
//   const [maintenancePct, setMaintenancePct] = useState(0);
//   const [escrowFeesPct, setEscrowFeesPct] = useState(0);
//   const [titleInsurancePct, setTitleInsurancePct] = useState(0);
//   const [legalFeesPct, setLegalFeesPct] = useState(0);
//   const [inspectionCostsPct, setInspectionCostsPct] = useState(0);
//   const [otherCostsPct, setOtherCostsPct] = useState(0);

//   // Calculated cost amounts based on the percentage of the buy price
//   const [agentCommissionAmt, setAgentCommissionAmt] = useState(0);
//   const [maintenanceAmt, setMaintenanceAmt] = useState(0);
//   const [escrowFeesAmt, setEscrowFeesAmt] = useState(0);
//   const [titleInsuranceAmt, setTitleInsuranceAmt] = useState(0);
//   const [legalFeesAmt, setLegalFeesAmt] = useState(0);
//   const [inspectionCostsAmt, setInspectionCostsAmt] = useState(0);
//   const [otherCostsAmt, setOtherCostsAmt] = useState(0);

  
//   const savingsAt1Percent = () => buyPrice * 0.01;
//   const savingsAt3Percent = () => buyPrice * 0.03;

//   useEffect(() => {
//     setAgentCommissionAmt((buyPrice * agentCommissionPct) / 100);
//     setMaintenanceAmt((buyPrice * maintenancePct) / 100);
//     setEscrowFeesAmt((buyPrice * escrowFeesPct) / 100);
//     setTitleInsuranceAmt((buyPrice * titleInsurancePct) / 100);
//     setLegalFeesAmt((buyPrice * legalFeesPct) / 100);
//     setInspectionCostsAmt((buyPrice * inspectionCostsPct) / 100);
//     setOtherCostsAmt((buyPrice * otherCostsPct) / 100);
//   }, [buyPrice, agentCommissionPct, maintenancePct, escrowFeesPct, titleInsurancePct, legalFeesPct, inspectionCostsPct, otherCostsPct]);

//   const calculateTotalCost = () => {
//     return (
//       buyPrice +
//       agentCommissionAmt +
//       maintenanceAmt +
//       escrowFeesAmt +
//       titleInsuranceAmt +
//       legalFeesAmt +
//       inspectionCostsAmt +
//       otherCostsAmt
//     ).toFixed(2);
//   };

//   return (
//     <div className="min-h-screen w-full flex justify-center items-start pt-8">
//       <div className="w-full max-w-3xl bg-blue-600 text-white p-6 rounded-lg shadow-lg">
//         <h2 className="text-3xl font-bold mb-6 text-center">Calculator</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//           <div>
//             {/* Input field for Buy Price */}
//             <div className="mb-4">
//               <label htmlFor="buyPrice" className="block mb-1">Buy Price</label>
//               <input
//                 type="number"
//                 id="buyPrice"
//                 value={buyPrice}
//                 onChange={(e) => setBuyPrice(Number(e.target.value))}
//                 className="w-full p-2 mb-4 rounded bg-white text-black"
//               />
//             </div>

//             {/* Dynamic input fields for costs */}
//             <CostInput
//               label="Agent Commission"
//               percent={agentCommissionPct}
//               setPercent={setAgentCommissionPct}
//               amount={agentCommissionAmt}
//             />
//             <CostInput
//               label="Maintenance"
//               percent={maintenancePct}
//               setPercent={setMaintenancePct}
//               amount={maintenanceAmt}
//             />
//             {/* Repeat structure for other costs... */}

//             <div className="text-2xl mt-6">
//               Total Cost: ${calculateTotalCost()}
//             </div>
//           </div>

//           <div>
//             {/* Savings section */}
//             <div className="text-2xl font-bold mb-6">You'll be saving:</div>
//             <div className="mb-4 flex items-center justify-between">
//               <div>HOME agents</div>
//               <div>Lower 1% commissions</div>
//               <div>${savingsAt1Percent().toLocaleString()}</div>
//             </div>
//             <div className="mb-4 flex items-center justify-between">
//               <div>Other home agents</div>
//               <div>Higher 3% commissions</div>
//               <div>${savingsAt3Percent().toLocaleString()}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const CostInput = ({ label, percent, setPercent, amount }) => (
//   <div className="mb-4">
//     <label className="block mb-1">{label} (%)</label>
//     <div className="flex space-x-2">
//       <input
//         type="number"
//         value={percent}
//         onChange={(e) => setPercent(Number(e.target.value))}
//         className="w-1/2 p-2 rounded bg-white text-black"
//       />
//       <input
//         type="text"
//         value={`$${amount.toLocaleString()}`}
//         readOnly
//         className="w-1/2 p-2 rounded bg-white text-black"
//       />
//     </div>
//   </div>
// );

// export default Calculator;


// const Calculator = () => {
//   const [buyPrice, setBuyPrice] = useState(1970000);
//   const [agentCommission, setAgentCommission] = useState(0);
//   const [maintenance, setMaintenance] = useState(0);
//   const [escrowFees, setEscrowFees] = useState(0);
//   const [titleInsurance, setTitleInsurance] = useState(0);
//   const [legalFees, setLegalFees] = useState(0);
//   const [inspectionCosts, setInspectionCosts] = useState(0);
//   const [otherCosts, setOtherCosts] = useState(0);

//   const calculateTotalCost = () => {
//     let totalCost = buyPrice;
//     totalCost += buyPrice * (agentCommission / 100);
//     totalCost += buyPrice * (maintenance / 100);
//     totalCost += buyPrice * (escrowFees / 100);
//     totalCost += buyPrice * (titleInsurance / 100);
//     totalCost += buyPrice * (legalFees / 100);
//     totalCost += buyPrice * (inspectionCosts / 100);
//     totalCost += buyPrice * (otherCosts / 100);
//     return totalCost;
//   };

//   const savingsAt1Percent = () => buyPrice * 0.01;
//   const savingsAt3Percent = () => buyPrice * 0.03;

//   return (
//     <div className="min-h-screen flex justify-center items-start pt-8">
//       <div className="w-full max-w-4xl bg-blue-600 text-white p-6 rounded-lg shadow-lg">
//         <h2 className="text-3xl font-bold mb-6 text-center">Calculator</h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//           <div>
//             <div className="mb-4">
//               <label htmlFor="buyPrice" className="block mb-1">Buy Price</label>
//               <input
//                 type="number"
//                 id="buyPrice"
//                 value={buyPrice}
//                 onChange={(e) => setBuyPrice(Number(e.target.value))}
//                 className="w-full p-2 mb-4 rounded bg-white text-black"
//               />
//             </div>

//             {/* Add additional input fields here... */}
//             <div className="mb-4">
//               <label htmlFor="agentCommission" className="block mb-1">Agent Commission (%)</label>
//               <input
//                 type="number"
//                 id="agentCommission"
//                 value={agentCommission}
//                 onChange={(e) => setAgentCommission(Number(e.target.value))}
//                 className="w-full p-2 mb-4 rounded bg-white text-black"
//               />
//             </div>

//             {/* Repeat this input structure for other percentage-based costs */}
//             {/* ... */}

//             <div className="text-2xl mt-6">
//               Total Cost: ${calculateTotalCost().toLocaleString()}
//             </div>
//           </div>

//           <div>
//             {/* Savings section */}
//             <div className="text-2xl font-bold mb-6">You'll be saving:</div>
//             <div className="mb-4 flex items-center justify-between">
//               <div>HOME agents</div>
//               <div>Lower 1% commissions</div>
//               <div>${savingsAt1Percent().toLocaleString()}</div>
//             </div>
//             <div className="mb-4 flex items-center justify-between">
//               <div>Other home agents</div>
//               <div>Higher 3% commissions</div>
//               <div>${savingsAt3Percent().toLocaleString()}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Calculator;



// const CostInput = ({ label, percentage, amount, onPercentageChange }) => (
//   <div className="flex justify-between space-x-16 mr-8 items-center">
//     <label className="mb-2 flex h-8 flex-row text-white">{label.charAt(0).toUpperCase() + label.slice(1)} (%) :</label>
//     <div className="flex space-x-2">
//       <input
//         type="number"
//         value={percentage}
//         onChange={(e) => onPercentageChange(e.target.value)}
//         className=" p-2 rounded-l bg-blue-900 text-white"
//       />
//       <input
//         type="text"
//         value={`$${amount}`}
//         readOnly
//         className=" p-2 rounded-r bg-blue-700 text-white"
//       />
//     </div>
//   </div>
// );



// const Calculator = () => {
//   const [buyPrice, setBuyPrice] = useState(100000);
//   const [agentCommissionPercent, setAgentCommissionPercent] = useState(3);
//   const [maintenancePercent, setMaintenancePercent] = useState(1);
//   const [escrowFeesPercent, setEscrowFeesPercent] = useState(1);
//   const [titleInsurancePercent, setTitleInsurancePercent] = useState(0.5);
//   const [legalFeesPercent, setLegalFeesPercent] = useState(0.2);
//   const [inspectionCostsPercent, setInspectionCostsPercent] = useState(0.1);
//   const [otherCostsPercent, setOtherCostsPercent] = useState(0.3);

//   const [totalCost, setTotalCost] = useState(0);
//   const [homeAgentFees, setHomeAgentFees] = useState(0);
//   const [traditionalAgentFees, setTraditionalAgentFees] = useState(0);

//   useEffect(() => {
//     const agentCommissionDollars = (buyPrice * agentCommissionPercent) / 100;
//     const maintenanceDollars = (buyPrice * maintenancePercent) / 100;
//     const escrowFeesDollars = (buyPrice * escrowFeesPercent) / 100;
//     const titleInsuranceDollars = (buyPrice * titleInsurancePercent) / 100;
//     const legalFeesDollars = (buyPrice * legalFeesPercent) / 100;
//     const inspectionCostsDollars = (buyPrice * inspectionCostsPercent) / 100;
//     const otherCostsDollars = (buyPrice * otherCostsPercent) / 100;

//     const newTotalCost = agentCommissionDollars + maintenanceDollars + escrowFeesDollars +
//                          titleInsuranceDollars + legalFeesDollars + inspectionCostsDollars +
//                          otherCostsDollars;
//     setTotalCost(newTotalCost);

//     const newHomeAgentFees = buyPrice * 0.01;
//     setHomeAgentFees(newHomeAgentFees);
//     const newTraditionalAgentFees = buyPrice * 0.03;
//     setTraditionalAgentFees(newTraditionalAgentFees);
//   }, [
//     buyPrice,
//     agentCommissionPercent,
//     maintenancePercent,
//     escrowFeesPercent,
//     titleInsurancePercent,
//     legalFeesPercent,
//     inspectionCostsPercent,
//     otherCostsPercent,
//   ]);

//   const DynamicInputField = ({ label, value, onChange }) => (
//     <div className="field flex justify-between items-center mb-2">
//       <label className="text-white text-md">{label}:</label>
//       <input
//         type="number"
//         className="w-24 text-black p-2 rounded"
//         value={value}
//         onChange={(e) => onChange(Number(e.target.value))}
//       />
//       <span className="text-white">%</span>
//     </div>
//   );



//   return (
//     <div className="calculator flex w-full">
//       <div className="input-section flex-1 bg-blue-900 p-5">
//   <h2 className="text-white text-2xl mb-5">Calculator</h2>
//   <DynamicInputField label="Buy Price" value={buyPrice} onChange={setBuyPrice} />
//   <DynamicInputField label="Agent Commission" value={agentCommissionPercent} onChange={setAgentCommissionPercent} />
//   <DynamicInputField label="Maintenance" value={maintenancePercent} onChange={setMaintenancePercent} />
//   <DynamicInputField label="Escrow Fees" value={escrowFeesPercent} onChange={setEscrowFeesPercent} />
//   <DynamicInputField label="Title Insurance" value={titleInsurancePercent} onChange={setTitleInsurancePercent} />
//   <DynamicInputField label="Legal Fees" value={legalFeesPercent} onChange={setLegalFeesPercent} />
//   <DynamicInputField label="Inspection Costs" value={inspectionCostsPercent} onChange={setInspectionCostsPercent} />
//   <DynamicInputField label="Other Costs" value={otherCostsPercent} onChange={setOtherCostsPercent} />
// </div>

//       <div className="output-section flex-1 bg-blue-700 p-5 text-white">
//         <h2 className="text-2xl mb-5">Total Cost:</h2>
//         {/* ... output fields and bar charts here */}
//         <div className="text-white font-bold">
//           <div className="mb-4">Traditional Agent: $0</div>
//           <div className="mb-4">Working With Us: +$19,700</div>
//           <div className="flex items-center mb-4">
//             <div className="text-blue-500 text-sm mr-2">HOME agents</div>
//             <div className="w-full bg-gray-200 h-4 rounded">
//               <div className="bg-blue-500 h-4 rounded" style={{ width: '33%' }}></div>
//             </div>
//             <div className="text-blue-500 text-sm ml-2">$10,000</div>
//           </div>
//           <div className="flex items-center">
//             <div className="text-pink-500 text-sm mr-2">Other home agents</div>
//             <div className="w-full bg-gray-200 h-4 rounded">
//               <div className="bg-pink-500 h-4 rounded" style={{ width: '100%' }}></div>
//             </div>
//             <div className="text-pink-500 text-sm ml-2">$30,000</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };



// export default Calculator;
