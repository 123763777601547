import React from 'react'

const GetStarted = () => {
  return (
   <div className='w-full bg-getStarted-section bg-no-repeat bg-cover py-10 sm:py-[80px] sm:pt-[74px] text-white border-b'>
    <div className='w-full sm:px-0 mb-[56px]'>
     <h2 className='text-[32px] sm:text-6xl font-[275] text-center'>What are you looking <span className='font-bold text-[#ED5272]'>to do? </span></h2>
    <p className='text-[16px] leading-[20px] sm:text-[20px] sm:leading-[64px] text-[#858E96] text-center'>
    {/* Choose one to see how you qualify */}
    </p>
    </div>

    <div className='w-full flex justify-center items-center gap-y-8 flex-wrap px-5'>
     <a href="/analyze" className='text-white text-[16px] sm:text-[22px] font-medium border-r pr-[40px] sm:px-[86px] h-[44px] sm:h-[80px] flex items-center'>Analyze</a>
     <a href="/buyer" className='text-white text-[16px] sm:text-[22px] font-medium border-r px-[40px]  sm:px-[86px] h-[44px] sm:h-[80px] flex items-center'>Buy</a>
     <a href="/cashout" className='text-white text-[16px] sm:text-[22px] font-medium sm:border-r pl-[40px]  sm:px-[86px] h-[44px] sm:h-[80px] flex items-center'>Finance</a>
     <a href="/invest" className='text-white text-[16px] sm:text-[22px] font-medium border-r pr-[40px]  sm:px-[86px] h-[44px] sm:h-[80px] flex items-center'>Invest</a>
     <a href="/sell" className='text-white text-[16px] sm:text-[22px] font-medium pl-[40px] sm:pr-[86px] h-[44px] sm:h-[80px] flex items-center'>Sell</a>
    </div>
    </div>
  )
}

export default GetStarted