import React, { useEffect, useRef, useState } from "react";
import { cardsData } from "../../utils";

const OptimizeExperience = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const cardRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && isMobile) {
          entry.target.classList.add('card-hovered', 'hover');
        } else {
          entry.target.classList.remove('card-hovered', 'hover');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    cardRefs.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      cardRefs.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [isMobile]);

  return (
    <div className="h-full w-full bg-white py-10 sm:py-0 sm:pt-[74px] sm:pb-20 px-4 2xl:px-8">
        <h2 className="text-[32px] sm:text-6xl text-center font-[275] mb-16 xl:mb-[94px]">Your Journey To <span className="text-[#ED5272] font-bold">HOME OWNERSHIP</span></h2>

      <div className="flex flex-col sm:flex-row w-full flex-wrap xl:flex-nowrap gap-5 2xl:gap-10 items-center justify-center lg:justify-center xl:justify-start">
        {
          cardsData.map((card, idx) => (
              <div className='w-[300px] xl:w-[350px] sm:w-[253px] h-[450px] sm:h-[330px] rounded-[14px] bg-gradient-to-b from-[#212529] to-[#8D8E8F] cursor-pointer relative overflow-hidden analyze-card' key={card.id} ref={cardRefs[idx]}>
                    <div className='absolute top-[-58px] right-[-50px] h-[147px] w-[147px] rounded-full bg-[#000000] bg-opacity-15 opacity-0 sm:opacity-100 icon-div'>
                            <h5 className='text-white pt-20 pl-12 text-4xl opacity-50'>{card.id}</h5>
                      </div>

                      <div className='w-[90%] sm:w-[206px] h-[167px] card-content-buy absolute top-[130px] left-[20px]'>
                            <h3 className='text-xl font-semibold text-white mb-4'>{card.title}</h3>
                            <p className='text-sm sm:text-[13px] font-medium sm:leading-[19px] text-white font-quickSand'>{card.desc}</p>
                          </div>
                      <div className='absolute w-full h-[210px] sm:h-[147px] bottom-0 opacity-0 card-img'>
                        <img src={card.img} alt="card-hovered" className='w-full h-full opacity-60' />
                      </div>
                  </div>
          ))
        }
      </div>
     
      
      {/* <div className="flex justify-start 2xl:justify-center items-end gap-8 2xl:gap-[82px]">
        <h2 className="w-[363px] h-[207px] text-[#000000] text-3xl 2xl:text-5xl leading-[49px] 2xl:leading-[69px] font-semibold mb-[26px]">That’s why the CUSTOMERS LOVE US</h2>
        
        <img src={placeholder} alt="video-placeholder" />
      </div> */}
     </div>
  )
}


export default OptimizeExperience;
